import { PureComponent } from "react";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { AmostraSubFormPart } from "../components/AmostraSubFormPart";
import { VelocidadeCorrenteField } from "../components/VelocidadeCorrenteField";
import { NivelAguaField } from "../components/NivelAguaField";
import { AreaDrenagemField } from "../components/AreaDrenagemField";
import { TurbidezAguaField } from "../components/TurbidezAguaField";
import { PosicaoLeitoField } from "../components/PosicaoLeitoField";
import { CorSampleField } from "../components/CorSampleField";
import { NaturezaAmostraField } from "../components/NaturezaAmostraField";
import { ImagensAmostraSubForm } from "../components/ImagensAmostraSubForm";
import {
  amostraDataFieldsCommon,
  AmostraSymbol,
  amostrasLabelsMap,
} from "../constants/amostras.constant";
import { WaterSampleGeoquimicaModelLike } from "../../../../../models/activity-geoquimica.model";
import { LarguraRioField } from "../components/LarguraRioField";
import { ProfundidadeRioField } from "../components/ProfundidadeRioField";
import { PreservacaoAmostraField } from "../components/PreservacaoAmostraField";
import { AmostraGridSubForm } from "../components/AmostraGridSubForm";

export interface AguaSectionFormProps {
  prefixoNome: string;
  items?: WaterSampleGeoquimicaModelLike[];
  onItemsChanged?: (items: WaterSampleGeoquimicaModelLike[]) => void;
}

export interface AguaSectionFormState {}

export class AguaSectionForm extends PureComponent<
  AguaSectionFormProps,
  AguaSectionFormState
> {
  datafieldsAgua = [
    ...amostraDataFieldsCommon,
    {
      name: "larguraRio",
      type: "number",
      map: "larguraRio",
    },
    {
      name: "profundidadeRio",
      type: "number",
      map: "profundidadeRio",
    },
    {
      name: "id_velocidadeCorrente",
      type: "number",
      map: "id_velocidadeCorrente",
    },
    {
      name: "id_nivelAgua",
      type: "number",
      map: "id_nivelAgua",
    },
    {
      name: "id_areaDrenagem",
      type: "number",
      map: "id_areaDrenagem",
    },
    {
      name: "id_turbidezAgua",
      type: "number",
      map: "id_turbidezAgua",
    },
    {
      name: "id_posicaoLeito",
      type: "string",
      map: "id_posicaoLeito",
    },
    {
      name: "id_corAgua",
      type: "number",
      map: "id_corAgua",
    },
    {
      name: "id_corSedimento",
      type: "number",
      map: "id_corSedimento",
    },
    {
      name: "id_fonteAmostra",
      type: "string",
      map: "id_fonteAmostra",
    },
    {
      name: "preservacaoAmostra",
      type: "string",
      map: "preservacaoAmostra",
    },
  ];

  amostraSymbol = AmostraSymbol.Agua;

  constructor(props) {
    super(props);
    this.state = {};
  }

  renderForm = ({ formProps, editando, prefixoNome }) => {
    return (
      <>
        <AmostraSubFormPart
          prefixoNome={prefixoNome}
          amostraSymbol={this.amostraSymbol}
          pesoAmostraLabel="Volume da amostra (l)"
          profundidadeMinRequired={true}
          profundidadeMaxRequired={true}
        />
        <Row className="my-3">
          <Col>
            <LarguraRioField
              prefixoNome={prefixoNome}
              name="larguraRio"
              required={true}
            />
          </Col>
          <Col>
            <ProfundidadeRioField
              prefixoNome={prefixoNome}
              name="profundidadeRio"
              required={true}
            />
          </Col>
          <Col>
            <VelocidadeCorrenteField
              prefixoNome={prefixoNome}
              name="id_velocidadeCorrente"
              required={true}
            />
          </Col>
        </Row>
        <Row className="my-3">
          <Col>
            <NivelAguaField
              prefixoNome={prefixoNome}
              name="id_nivelAgua"
              required={true}
            />
          </Col>
          <Col>
            <AreaDrenagemField
              prefixoNome={prefixoNome}
              name="id_areaDrenagem"
              required={true}
            />
          </Col>
          <Col>
            <TurbidezAguaField
              prefixoNome={prefixoNome}
              name="id_turbidezAgua"
              required={true}
            />
          </Col>
        </Row>
        <Row className="my-3">
          <Col>
            <PosicaoLeitoField
              prefixoNome={prefixoNome}
              name="id_posicaoLeito"
              required={true}
            />
          </Col>
          <Col>
            <CorSampleField
              prefixoNome={prefixoNome}
              name="id_corAgua"
              label="Cor água"
              required={true}
            />
          </Col>
          <Col>
            <CorSampleField
              prefixoNome={prefixoNome}
              name="id_corSedimento"
              label="Cor sedimento"
              required={true}
            />
          </Col>
        </Row>
        <Row className="my-3">
          <Col>
            <NaturezaAmostraField
              prefixoNome={prefixoNome}
              name="id_fonteAmostra"
              required={true}
            />
          </Col>
          <Col>
            <PreservacaoAmostraField
              prefixoNome={prefixoNome}
              name="id_preservacaoAmostra"
              required={true}
            />
          </Col>
        </Row>
        <Row className="my-3">
          <Col>
            <ImagensAmostraSubForm prefixoNome={prefixoNome} />
          </Col>
        </Row>
      </>
    );
  };

  render() {
    return (
      <>
        <Card className="my-3">
          <Card.Body>
            <Card.Subtitle>
              {amostrasLabelsMap.get(this.amostraSymbol)}
            </Card.Subtitle>
            <AmostraGridSubForm
              name="amostraAgua"
              datafields={this.datafieldsAgua}
              items={this.props.items}
              onItemsChanged={this.props.onItemsChanged}
              renderForm={this.renderForm}
            />
          </Card.Body>
        </Card>
      </>
    );
  }
}
