import React, { Component } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { Field } from "react-final-form";
import { DateTimeInputField } from "../../../../../components/field/DateTimeInputField";
import { DropdownListField } from "../../../../../components/field/DropdownListField";
import { SubForm } from "../../../../../components/SubForm";
import {
  campoObrigatorioComMsgGenerica,
  validacoesOcorrenciaMineral,
  validarSe
} from "../../../../../util/validadores";
import FonteReferencia from "../FonteReferencia/FonteReferencia";
import { setValue } from "../../../../../util/mutadores";
import { resolverExibidos } from "../../../../../components/SubForm/SubForm";
import Substancia from "../substancia/Substancia";
import { resolverMultiRequestRecmin, resolverRequestRecmin } from "../../../../../util/ResolverRequestRecmin";
import { resolverRequestRecminAflora } from "../../../../../util/ResolverRequestRecmin";
import { NumberInputField } from "../../../../../components/field/NumberInputField";
import { TextAreaField } from "../../../../../components/field/TextAreaField"
import {
  OCORRENCIA_MINERAL
} from "../../../../../util/constantes";
import { RadioField } from "../../../../../components/field/RadioField";
class OcorrenciaMineral extends Component {
  constructor(props) {
    super(props);
    this.state = {
      substanciasList: [],
      classeUtilitaria: [],
      expressaoGeografica: [],
      formaGeometrica: [],
      permitirInsercao: true,
    };

    this.colunasOcorrenciaMineral = [
      {
        text: 'Substâncias primárias', datafield: "substanciasPrimarias", width: "35%", cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {

          let nome = [];
          let substancias = undefined;

          let ocorrencialMineralTemp = resolverExibidos(this.props.referenciaForm.getState().values.ocorrenciaMineral ?? []);

          if (ocorrencialMineralTemp.length && ocorrencialMineralTemp[0].substancias) {
            substancias = resolverExibidos(ocorrencialMineralTemp[0].substancias);
            substancias = substancias.filter((item) => item.importancia === 'PRINCIPAL');

            substancias.forEach((item) => {
              if (this.state.substanciasList.find((subs) => subs.id === item.idTipoSubstancia)) {
                nome.push(this.state.substanciasList.find((subs) => subs.id === item.idTipoSubstancia).nome);
              }
            });
          }

          return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${nome.toString().split(',').join(', ')}</div>`;
        }
      },
      {
        text: 'Substâncias secundárias', datafield: "substanciasSecundarias", width: "35%", cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {

          let nome = [];
          let substancias = undefined;

          let ocorrencialMineralTemp = resolverExibidos(this.props.referenciaForm.getState().values.ocorrenciaMineral ?? []);

          if (ocorrencialMineralTemp.length && ocorrencialMineralTemp[0].substancias) {
            substancias = resolverExibidos(ocorrencialMineralTemp[0].substancias);
            substancias = substancias.filter((item) => item.importancia !== 'PRINCIPAL');

            substancias.forEach((item) => {
              if (this.state.substanciasList.find((subs) => subs.id === item.idTipoSubstancia)) {
                nome.push(this.state.substanciasList.find((subs) => subs.id === item.idTipoSubstancia).nome);
              }
            });
          }

          return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${nome.toString().split(',').join(', ')}</div>`;
        }
      },
      {
        text: "Afloramento", datafield: "idAfloramento", width: "24%",
        cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
          let afloramentos = this.state.afloramento;
          let nome = "";
          if (afloramentos) {
            let listaFiltrada = [];
            afloramentos?.map(dado => listaFiltrada?.push({ id: dado?.id, nome: dado?.numeroCampo }))

            listaFiltrada?.map(afloramento => {
              if (afloramento.id?.toString() === rowdata.idAfloramento?.toString()) {
                nome = afloramento.nome;
              }
            });
            return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${nome}</div>`;
          }
        }
      },
    ];

    this.datafieldsOcorrenciaMineral = [
      { name: "toponimia", type: "string", map: "toponimia" },
      { name: "comprimento", type: "number", map: "comprimento" },
      { name: "largura", type: "number", map: "largura" },
      { name: "idAfloramento", type: "string", map: "idAfloramento" },
    ];

    /** @type {React.RefObject<import("../../../../../components/Jqx/Table").Table>} */
    this.tableRef = React.createRef();
  }

  async componentDidMount() {
    let dadosRecurso = this.props.referenciaForm.getState().values;
    if (dadosRecurso.ocorrenciaMineral) {
      let ocorrencias = resolverExibidos(dadosRecurso.ocorrenciaMineral);
      if ((ocorrencias && ocorrencias.length > 0) || this.props.permitirEdicao === false) {
        this.setState({
          permitirInsercao: false
        })
      }
    }

    const responses = await resolverMultiRequestRecmin({
      afloramento: {
        uri: "/afloramentos",
        parameters: { idEstacao: this.props.idEstacao },
        moduleName: "aflora",
      },
      substanciasList: { uri: "/tipoSubstancia" },
      expressaoGeografica: { uri: "/expressao" },
      formaGeometrica: { uri: "/formaGeometrica" },
    });

    // Se a lista de substâncias estava vazia antes, redesenhamos a tabela.
    const needsTableRedraw = this.state.substanciasList.length === 0 && responses.substanciasList.length > 0;

    this.setState(responses);

    if (needsTableRedraw) {
      this.tableRef.current?.refreshItems();
    }
  }


  onSubmit({ alteracoesForm }) {
    this.props.referenciaForm.batch(() => {
      for (const [key, val] of Object.entries(alteracoesForm)) {
        this.props.referenciaForm.mutators.setValue(`${key}`, val);
      }
    });

    if (resolverExibidos(this.props.referenciaForm.getState().values.ocorrenciaMineral).length === 1) {
      this.setState({
        permitirInsercao: false
      })
    } else {
      this.setState({
        permitirInsercao: true
      })
    }

    if (this.props.referenciaForm.getState().values.ocorrenciaMineral) {
      let arrayOcorrenciaMineral = resolverExibidos(this.props.referenciaForm.getState().values.ocorrenciaMineral);
      let arrayFonteReferencia = [];
      arrayOcorrenciaMineral?.map(ocorrenciaMineral => {
        if (ocorrenciaMineral.fontesReferencia) {
          let exibidos = resolverExibidos(ocorrenciaMineral.fontesReferencia);
          exibidos?.map(item => {
            if (item.modoCadastro === "SIMPLIFICADO") {
              item.referencia = item.descricao
            }
            if (item.modoCadastro === "DETALHADO") {
              const autores = item.autores.split(",");
              if (autores.length === 1) item.referencia = `${autores[0].split(" ").pop()}. (${item.ano}). ${item.titulo}.`;
              if (autores.length === 2) item.referencia = `${autores[0].split(" ").pop()} e ${autores[1].split(" ").pop()}. (${item.ano}). ${item.titulo}.`;
              if (autores.length >= 3) item.referencia = `${autores[0].split(" ").pop()} et al. (${item.ano}). ${item.titulo}.`
            }
            if (item.modoCadastro === "BASE_PROJETOS" && item.idProjeto !== "") item.referencia = item.idProjeto;
            if (item.modoCadastro === "BASE_PROJETOS" && item.idProjeto === "") item.referencia = item.link;
          }
          );
          arrayFonteReferencia.push(exibidos);
        }
        return 0;
      }
      );
      this.props?.atualizarTabelaSubstancias();
      this.props.atualizarTabelaFonteReferenciaRecursoMineral(arrayFonteReferencia.flat(), "OCORRENCIA_MINERAL");
    }
  }

  onSubmitSubForm({ alteracoesForm }, formProps) {
    formProps?.batch(() => {
      for (const [key, val] of Object.entries(alteracoesForm)) {
        formProps?.mutators?.setValue(`${key}`, val);
      }
    });
  }

  componentDidUpdate() { }

  render() {
    return (
      <Field name={"ocorrenciaMineral"} subscription={{ value: true }}>
        {({ input: { value: ocorrenciaMineral = {} } }) => {
          return <SubForm
            tableRef={this.tableRef}
            keepDirtyOnReinitialize
            permitirInsercao={true}
            exibirBotaoInsercao={this.props.permitirEdicao && this.state.permitirInsercao}
            permitirEdicao={this.props.permitirEdicao}
            permitirExclusao={this.props.permitirEdicao}
            permitirVisualizacao={!this.props.permitirEdicao}
            exibirAsterisco={this.props.obrigatorio}
            tituloForm="Ocorrência mineral"
            nome="ocorrenciaMineral"
            onSubmit={this.onSubmit.bind(this)}
            colunas={this.colunasOcorrenciaMineral}
            campos={this.datafieldsOcorrenciaMineral}
            validar={(valores) => this.props.modoDefinitivo ? validacoesOcorrenciaMineral(valores, this.props.referenciaForm) : undefined}
            elementos={
              ocorrenciaMineral
            }
            formSubscription={{}}
            renderForm={({ formProps }) => {
              return (
                <>
                  <Row>
                    <Col md={12}>
                      <Card.Title>
                        Ocorrência mineral
                        {this.state.obrigatorio && <span style={{ color: "red" }}>*</span>}
                      </Card.Title>
                    </Col>
                  </Row>
                  <>
                    <Row className="mt-3">
                      <Col md={6}>
                        <Field name="idAfloramento" subscription={{ value: true }}>
                          {({ input: { value: idAfloramento } }) => {
                            let listaFiltrada = [];
                            this.state.afloramento?.map(dado => listaFiltrada.push({ id: dado.id, nome: dado.numeroCampo }))
                            return <Field
                              name="idAfloramento"
                              component={DropdownListField}
                              label="Afloramento"
                              dica="Informar o nome do Afloramento associado, previamente cadastrado no módulo AFLORA"
                              elementos={listaFiltrada}
                              disabled={!this.props.permitirEdicao}
                            />
                          }}
                        </Field>
                      </Col>
                      <Col md={6}>
                      <Field
                        disabled={!this.props.permitirEdicao}
                        component={RadioField}
                        name="data"
                        campo="data"
                        colunas={2}
                        label="Data da publicação da informação"
                        modoDefinitivo={this.props.modoDefinitivo}
                        tipoCampo={DateTimeInputField}
                        max={this.props.dataCriacao}
                        dica="Informe a data da publicação da informação, se aplicável"
                      />
                      </Col>
                    </Row>
                  </>
                  <Row className="mt-3">
                    <Col md={3}>
                      <Field
                        name="comprimento"
                        component={NumberInputField}
                        permitirNegativos={false}
                        label="Comprimento (m)"
                        dica="Informar o comprimento do corpo mineralizado"
                        required={this.props.modoDefinitivo}
                        casasInteiros={4}
                        validate={validarSe(this.props.modoDefinitivo, campoObrigatorioComMsgGenerica("Comprimento"))}
                        disabled={!this.props.permitirEdicao}
                      />
                    </Col>
                    <Col md={3}>
                      <Field
                        name="largura"
                        component={NumberInputField}
                        permitirNegativos={false}
                        label="Largura (m)"
                        dica="Informar a largura ou altura do corpo mineralizado"
                        required={this.props.modoDefinitivo}
                        casasInteiros={4}
                        validate={validarSe(this.props.modoDefinitivo, campoObrigatorioComMsgGenerica("Largura"))}
                        disabled={!this.props.permitirEdicao}
                      />
                    </Col>
                    <Col md={3}>
                      <Field
                        name="idExpressaoGeografica"
                        component={DropdownListField}
                        label="Expressão do corpo mineralizado"
                        dica="Selecionar a característica ou feição que melhor se aplica para descrever o corpo mineralizado"
                        elementos={this.state.expressaoGeografica}
                        initialValue=""
                        required={this.props.modoDefinitivo}
                        validate={validarSe(this.props.modoDefinitivo, campoObrigatorioComMsgGenerica(
                          "Expressão do corpo mineralizado"
                        ))}
                        disabled={!this.props.permitirEdicao}
                      />
                    </Col>
                    <Col md={3}>
                      <Field
                        name="idFormaGeometrica"
                        component={DropdownListField}
                        label="Forma geométrica"
                        dica="Selecione o termo que melhor defina a morfologia e atitude do corpo mineralizado"
                        elementos={this.state.formaGeometrica}
                        initialValue=""
                        required={this.props.modoDefinitivo}
                        validate={validarSe(this.props.modoDefinitivo, campoObrigatorioComMsgGenerica(
                          "Forma geométrica"
                        ))}
                        disabled={!this.props.permitirEdicao}
                      />
                    </Col>
                    <Col md={12}>
                      <Field
                        component={TextAreaField}
                        name={`informacoesRelevantes`}
                        label="Informações relevantes"
                        dica="Caso necessário, detalhe a ocorrência mineral observada"
                        maxLength={250}
                        disabled={!this.props.permitirEdicao}
                      />
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <Col md={12}>
                      <Substancia
                        modoDefinitivo={this.props.modoDefinitivo}
                        permitirEdicao={this.props.permitirEdicao}
                        referenciaForm={formProps.form}
                        substanciasPrincipais={this.props.substanciasPrincipais}
                        substanciasSecundarias={this.props.substanciasSecundarias}
                      />
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <Col md={12}>
                      <FonteReferencia
                        modoDefinitivo={this.props.modoDefinitivo}
                        permitirEdicao={this.props.permitirEdicao}
                        formPai={formProps}
                        mutators={{ setValue }}
                        origem={OCORRENCIA_MINERAL}
                      />
                    </Col>
                  </Row>
                </>
              );
            }}
          />
        }}
      </Field>
    );
  }
}

export default OcorrenciaMineral;
