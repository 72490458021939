import React, { useEffect, useRef, useState } from "react";
import Card from "../../../../../components/card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { SubForm, LegacySubForm } from "../../../../../components/SubForm";
import { Field, useForm } from "react-final-form";
import { DropdownListField } from "../../../../../components/field/DropdownListField";
import { campoObrigatorioComMsgGenerica } from "../../../../../util/validadores";
import { aoEnviarSubForm } from "../../../../../util/mutadores";
import { getPropsPermissao, resolverExibidos } from "../../../../../components/SubForm/SubForm";

const campos = [{ name: "idsMinerais", type: "array", map: "idsMinerais" }]

const Graos = ({
  aoAbrirSubForm,
  aoFecharSubForm,
  valoresIniciais,
  permitirEdicao,
  minerais,
  mineraisPorId,
}) => {
  const form = useForm();

  // These refs are for remembering the previous values of each array that's a
  // dependency of the columns array. We only recalculate the columns array when
  // there's an actual change in the arrays.
  const mineraisPorIdRef = useRef(mineraisPorId);
  const [colunasGraos, setColunasGraos] = useState(gerarColunasGraos(mineraisPorId));

  useEffect(() => {
    // Only update the columns when at least one of the dependencies has really changed.
    // Otherwise, avoid changing the columns because that causes a table rerender.
    // (The *correct* thing to do would be including the names in the objects themselves (add extra fields)
    // and always recalculate those names when dependencies change, completely removing the need for
    // a cellsrenderer.)
    // Compare the dependencies by value.
    if (JSON.stringify(mineraisPorIdRef.current) !== JSON.stringify(mineraisPorId)) {
      setColunasGraos(gerarColunasGraos(mineraisPorId));
      mineraisPorIdRef.current = mineraisPorId;
    }
  }, [mineraisPorId])

  const onSubmit = aoEnviarSubForm(form);
  let mineralEditado=[];
  return (
    <div>
      <Card className="mt-5">
        <Card.Body>
          <Card.Subtitle>Grãos</Card.Subtitle>
          <Field name={`graos`} subscription={{ value: true }}>
            {({ input: { value: graos = {} } }) => (
              <SubForm
                nome="graos"
                onSubmit={onSubmit}
                elementos={graos} // form.getState().values.graos
                campos={campos}
                colunas={colunasGraos}
                formSubscription={{}}
                onOpen={aoAbrirSubForm}
                onClose={aoFecharSubForm}
                valoresIniciais={valoresIniciais}
                {...getPropsPermissao(permitirEdicao)}
                alternarBotoesPai={true}
                validarVoltar={true}
                exibirTabelaSemRegistro={true}
                onBtnEditClick={(value)=>(mineralEditado = value)}
                renderForm={({ prefixoNome }) => {
                  let mineraisCadastrados = [];
                  if (form.getState().values?.graos) {
                    var graosCadastrados = resolverExibidos(form.getState().values?.graos);
                    for (let i of graosCadastrados) {
                        for (let ids of i.idsMinerais) {
                          mineraisCadastrados.push(ids)
                        }
                    }
                  }
                  var mineraisFiltrados = minerais;
                  for (let i of mineraisCadastrados) {
                    if (!mineralEditado?.rowdata?.rowData?.idsMinerais?.includes(i)){
                      mineraisFiltrados = mineraisFiltrados.filter(item => item.id !== i);
                    } 
                  }
                  mineralEditado=[]
                  return (
                    <>
                      <Row className="my-3">
                        <Col>
                          <Field
                            component={DropdownListField}
                            name={`${prefixoNome}idsMinerais`}
                            label="Minerais"
                            dica="Selecione todo(s) o(s) mineral(is) que compõe o arcabouço da rocha/sedimento descrito"
                            elementos={mineraisFiltrados}
                            required
                            disabled={!permitirEdicao}
                            validate={campoObrigatorioComMsgGenerica("Minerais")}
                            checkboxes={true}
                            multiple
                          />
                        </Col>
                      </Row>
                    </>
                  )
                }}
              />
            )}
          </Field>
        </Card.Body>
      </Card>
    </div>
  );
}

function gerarColunasGraos(mineraisPorId) {
  return [
    {
      text: "Minerais", datafield: "idsMinerais", width: "94%",

      cellsrenderer: function (row, columnfield, value, defaulthtml, columnproperties, rowdata) {
        const idsMinerais = (rowdata[columnfield] || '').split(',')
        const nome = idsMinerais
          .filter(it => !!it)
          .map(it => mineraisPorId[Number(it)].nome)
          .join(' ')

        return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${nome}</div>`;
      }
    },
  ];
}

export default Graos;
