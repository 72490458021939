import React, { useState, useEffect } from "react";
import { Field, FormSpy } from "react-final-form";
import { Col, Container, Row } from "react-bootstrap";
import "../../views/estacoes/components/RecursoMineral/geral/recmin-style.css";
import AtividadeExploracao from "./components/RecursoMineral/AtividadeExploracao/AtividadeExploracao";
import AtividadeMineracao from "./components/RecursoMineral/AtividadeMineracao/AtividadeMineracao";
import { setValue } from "../../util/mutadores";
import OcorrenciaMineral from "./components/RecursoMineral/OcorrenciaMineral/OcorrenciaMineral";
import HistoricoAlteracoes from "./components/RecursoMineral/HistoricoAlteracoes/HistoricoAlteracoes";
import Deposito from "./components/RecursoMineral/DepositoPrototipo/Deposito";
import Form from "react-bootstrap/Form";
import Hint from "../../components/hint/Hint";
import { DropdownListField } from "../../components/field/DropdownListField";
import {
  GRAU_IMPORTANCIA_SUBSTANCIA_PRINCIPAL,
  GRAU_IMPORTANCIA_SUBSTANCIA_SECUNDARIA,
  ATIVIDADE_EXPLORACAO,
  OCORRENCIA_MINERAL,
  DEPOSITO,
  MINA_GARIMPO,
  ATIVIDADE_MINERACAO,
  ESTUDO_METALOGENETICO,
} from "../../util/constantes";
import { RadioButtonSetField, RadioButtonWithFieldSetField } from "../../components/field/RadioButtonSetField";
import EstudosMetalogeneticos from "./components/RecursoMineral/EstudosMetalogeneticos/EstudosMetalogeneticos";
import SubForm, { getPropsPermissao, resolverExibidos } from "../../components/SubForm/SubForm";
import { campoObrigatorioComMsgGenerica, campoObrigatorioDefinitivoComMsgGenerica, validarSe } from "../../util/validadores";
import InformacoesCoordenadas from "./components/mapa/InformacoesCoordenadas";
import PontosGPSAfloramento from "../estacoes/components/afloramento/PontosGPSAfloramento";
import { configMapaRecursoMineral } from '../../config/mapa';
import MinaGarimpo from "./components/RecursoMineral/minaGarimpo/MinaGarimpo";
import { NumberInputField } from "../../components/field/NumberInputField";
import { resolverRequestRecmin } from "../../util/ResolverRequestRecmin";
import { FORM_ERROR } from "final-form";
import { Table } from "../../components/Jqx/Table";
import BlockUi from "react-block-ui";
import { HiddenField } from "../../components/field/HiddenField";
import Card from "../../components/card";
import { useDispatch } from "react-redux";
import { mostrarNotificacao } from "../../reducers/notificacaoReducer"
import Service from "../../service/Service";
import { RadioField } from "../../components/field/RadioField";
import TextMaskField from "../../components/field/TextMaskField";

const tabelaReferenciaDatafields = [
  { name: "referencia", type: "string", map: "referencia" },
  { name: "modoCadastro", type: "string", map: "modoCadastro" },
  { name: "autores", type: "string", map: "autores" },
  { name: "ano", type: "string", map: "ano" },
  { name: "volume", type: "string", map: "volume" },
  { name: "titulo", type: "string", map: "titulo" },
  { name: "paginaInicial", type: "string", map: "paginaInicial" },
  { name: "link", type: "string", map: "link" },
  { name: "descricao", type: "string", map: "descricao" },
  { name: "idProjeto", type: "string", map: "idProjeto" },
  { name: "nomeProjeto", type: "string", map: "nomeProjeto" },
  { name: "origem", type: "string", map: "origem" },
  { name: "origemDescricao", type: "string", map: "origemDescricao" },
];

let subsPrincipais = [];
let subsSecundarias = [];
let subsFiltradas = []

const tabelaReferenciaColunas = [
  {
    text: "Referência", datafield: "referencia", width: "70%",
    cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
      if (rowdata.modoCadastro === "SIMPLIFICADO") {
        if (rowdata?.link?.toString().includes("http://") || rowdata?.link?.toString().includes("https://")) {
          return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;"><a href="${rowdata.link}" target="_blank">${rowdata.descricao}</a></div>`;
        } else {
          return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;"><a href="http://${rowdata.link}" target="_blank">${rowdata.descricao}</a></div>`;
        };
      }

      if (rowdata.modoCadastro === "DETALHADO") {
        let tempLink = ""
        if (rowdata.link && rowdata.link !== "") {
          tempLink = rowdata.link;
          if (!tempLink?.toString().includes("http://") && !tempLink?.toString().includes("https://")) {
            tempLink = `http://${tempLink}`;
          }
        }

        const autores = rowdata.autores.split(',');
        if (autores.length === 1) {
          if (tempLink !== "") {
            return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">
            <a href="${tempLink}" target="_blank">
            ${autores[0].split(" ").pop()}. (${rowdata.ano}). ${rowdata.titulo}.
            </a>
            </div>`;
          } else {
            return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">
            ${autores[0].split(" ").pop()}. (${rowdata.ano}). ${rowdata.titulo}.
            </div>`;
          }

        };
        if (autores.length === 2) {

          if (tempLink !== "") {
            return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">
            <a href="${tempLink}" target="_blank">
            ${autores[0].split(" ").pop()} e ${autores[1].split(" ").pop()}. (${rowdata.ano}). ${rowdata.titulo}.
            </a>
            </div>`;
          } else {
            return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${autores[0].split(" ").pop()} e ${autores[1].split(" ").pop()}. (${rowdata.ano}). ${rowdata.titulo}.</div>`;
          }
        };
        if (autores.length >= 3) {

          if (tempLink !== "") {
            return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">
            <a href="${tempLink}" target="_blank">
            ${autores[0].split(" ").pop()} et al. (${rowdata.ano}). ${rowdata.titulo}.
            </a>
            </div>`;
          } else {
            return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${autores[0].split(" ").pop()} et al. (${rowdata.ano}). ${rowdata.titulo}.`;
          }
        }
        return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${rowdata.titulo}</div>`;
      };


      if (rowdata.modoCadastro === "BASE_PROJETOS" &&
        (rowdata.idProjeto === "" || rowdata.idProjeto === undefined || rowdata.idProjeto === null)
        && rowdata.link
      ) {
        let tempLink = ""
        if (rowdata.link && rowdata.link !== "") {
          tempLink = rowdata.link;
          if (!tempLink?.toString().includes("http://") && !tempLink?.toString().includes("https://")) {
            tempLink = `http://${tempLink}`;
          }
        }
        if (tempLink !== "") {
          return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">
          <a href="${tempLink}" target="_blank">
          ${tempLink}
          </a>
          </div>`;

        }

      }
      else if (rowdata.modoCadastro === "BASE_PROJETOS" &&
        rowdata.idProjeto
        && rowdata.link) {
        let tempLink = ""
        if (rowdata.link && rowdata.link !== "") {
          tempLink = rowdata.link;
          if (!tempLink?.toString().includes("http://") && !tempLink?.toString().includes("https://")) {
            tempLink = `http://${tempLink}`;
          }
        }
        // A "nomeProjeto" property is added to rowdata before updating the table,
        // so that we don't have to capture the "projetos" state variable.
        // That means this entire array of columns doesn't need to change, which
        // is good because JqxGrid is *very* slow to rerender.
        return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">
          <a href="${tempLink}" target="_blank">
          ${rowdata.nomeProjeto}
          </a>
          </div>`;
      }
      else
        if (rowdata.modoCadastro === "BASE_PROJETOS" && rowdata.idProjeto) {
          return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${rowdata.nomeProjeto}</div>`;
        };
    },
  },
  {
    text: "Origem", datafield: "origemDescricao", width: "30%", cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
      if (rowdata.origemDescricao) {
        return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${rowdata.origemDescricao}</div>`;
      } else if (rowdata.origem) {
        return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${rowdata.origem}</div>`;
      }
    }
  }
];

const RecursoMineral = (props) => {
  /**
   * @type {React.RefObject<Table>}
   */
  const referenciasTableRef = React.createRef();

  /**
   * @type {React.RefObject<Table>}
   */
  const substanciasTableRef = React.createRef();

  const [bloquearModoCadastro, setBloquearModoCadastro] = useState(false)
  const [situacaoMineracao, setSituacaoMineracao] = useState([])
  const [statusEconomico, setStatusEconomico] = useState([])
  const [grauImportancia, setGrauImportancia] = useState([])
  const [statusEconomicoDeposito, setStatusEconomicoDeposito] = useState([]);
  const [statusEconomicoOcorrencia, setStatusEconomicoOcorrencia] = useState([]);
  const [importancia, setImportancia] = useState([
    GRAU_IMPORTANCIA_SUBSTANCIA_PRINCIPAL,
    GRAU_IMPORTANCIA_SUBSTANCIA_SECUNDARIA,
  ])
  const [tipoSubstancia, setTipoSubstancia] = useState([])
  const [tipoSubstanciaFiltrada, setTipoSubstanciaFiltrada] = useState([])
  const [fonteReferenciaAtividadeExploracao, setFonteReferenciaAtividadeExploracao] = useState([])
  const [projetos, setProjetos] = useState([])
  const [permitirEdicaoCoordenadas, setPermitirEdicaoCoordenadas] = useState(true)
  const [modoCadastro, setModoCadastro] = useState([
    {
      id: "RASCUNHO",
      nome: "Rascunho"
    },
    {
      id: "DEFINITIVO",
      nome: "Completo"
    }
  ])
  const [registrosHistorico, setRegistrosHistorico] = useState([])
  const [feicaoGeologica, setFeicaoGeologica] = useState([])
  const [carregamentoGlobal, setCarregamentoGlobal] = useState(true)
  const [fonteReferenciaOcorrenciaMineral, setFonteReferenciaOcorrenciaMineral] = useState([])
  const [fonteReferenciaDeposito, setFonteReferenciaDeposito] = useState([])
  const [fonteMinaGarimpo, setFonteMinaGarimpo] = useState([])
  const [fonteAtividadeMineracao, setFonteAtividadeMineracao] = useState([])
  const [fonteEstudosMetalogeneticos, setFonteEstudosMetalogeneticos] = useState([])
  const [colunasSubstancias, setColunasSubstancias] = useState([])
  const [ocorrenciaObrigatoria, setOcorrenciaObrigatoria] = useState(false);
  const [depositoObrigatorio, setDepositoObrigatorio] = useState(false);
  const [minaGarimpoObrigatorio, setMinaGarimpoObrigatorio] = useState(false);
  const [dataCriacao, setDataCriacao] = useState('');

  const [datafieldsSubstancias, setDatafieldsSubstancias] = useState([
    { name: "importancia", type: "string", map: "importancia" },
    { name: "idTipoSubstancia", type: "string", map: "idTipoSubstancia" },
    { name: "ordemNomenclatura", type: "number", map: "ordemNomenclatura" },
  ])

  /**
   * Calling this function will cause the many reference arrays to change. A
   * change in any of them will cause a change in the main array of all references.
   */
  async function atualizarTabelaFonteReferenciaRecursoMineral(fonteReferencia, origem) {
    if (origem === ATIVIDADE_EXPLORACAO.id) {
      let fonteReferenciaAtividadeExploracaoTmp = [];
      fonteReferencia?.map(referencia => {
        referencia.origemDescricao = "Atividade de exploração";
        fonteReferenciaAtividadeExploracaoTmp.push(referencia);
        return 0;
      });
      setFonteReferenciaAtividadeExploracao(fonteReferenciaAtividadeExploracaoTmp);
    }

    if (origem === OCORRENCIA_MINERAL.id) {
      let fonteReferenciaOcorrenciaMineralTemp = [];
      fonteReferencia?.map(referencia => {
        referencia.origemDescricao = "Ocorrência mineral";
        fonteReferenciaOcorrenciaMineralTemp.push(referencia);
        return 0;
      });
      setFonteReferenciaOcorrenciaMineral(fonteReferenciaOcorrenciaMineralTemp);
    }

    if (origem === DEPOSITO.id) {
      let fonteReferenciaDepositoTmp = [];
      fonteReferencia?.map(referencia => {
        referencia.origemDescricao = "Depósito";
        fonteReferenciaDepositoTmp.push(referencia);
        return 0;
      });
      setFonteReferenciaDeposito(fonteReferenciaDepositoTmp);
    }

    if (origem === MINA_GARIMPO.id) {
      let fonteMinaGarimpoTmp = [];
      fonteReferencia?.map(referencia => {
        referencia.origemDescricao = "Mina ou garimpo";
        fonteMinaGarimpoTmp.push(referencia);
        return 0;
      });
      setFonteMinaGarimpo(fonteMinaGarimpoTmp);
    }

    if (origem === ATIVIDADE_MINERACAO.id) {
      let fonteAtividadeMineracaoTmp = [];
      fonteReferencia?.map(referencia => {
        referencia.origemDescricao = "Atividade de mineração";
        fonteAtividadeMineracaoTmp.push(referencia);
        return 0;
      });
      setFonteAtividadeMineracao(fonteAtividadeMineracaoTmp);
    }

    if (origem === ESTUDO_METALOGENETICO.id) {
      let fonteEstudosMetalogeneticosTmp = [];
      fonteReferencia?.map(referencia => {
        referencia.origemDescricao = "Metalogenia";
        fonteEstudosMetalogeneticosTmp.push(referencia);
        return 0;
      });
      setFonteEstudosMetalogeneticos(fonteEstudosMetalogeneticosTmp);
    }

  }

  const AUTOSAVE_DELAY = process?.env?.REACT_APP_AUTOSAVE_DELAY ? process.env.REACT_APP_AUTOSAVE_DELAY : 300000;
  const dispatch = useDispatch();

  useEffect(() => {
    const interval = setInterval(() => {
      if (props.permitirEdicao) {
        const formValues = props?.referenciaForm?.form?.getState()?.values

        const body = {
          idColetor: formValues?.idUsuarioColetor,
          idVisita: formValues?.idVisita,
          idEstacao: props.idEstacao,
          toponimia: formValues?.toponimia,
          numeroCampo: formValues?.numeroCampo,
          atividade: formValues,
          tipoAtividade: formValues?.tipo,
          permitirExclusao: true,
          permitirEdicao: true,
          modoCadastro: 'TEMPORARIO',
          modoEdicao: 'BASEGEO'
        }

        Service('/estacoes/atividadeTemporaria', 'basegeo').post(body)
          .then(() => dispatch(mostrarNotificacao('Por segurança seus dados foram salvos de forma temporária', { tipo: 'info' }, true)))
          .catch((erro) => console.log("Erro no salvamento automatico", erro))
      }
    }, AUTOSAVE_DELAY);

    return () => clearInterval(interval);
  }, []);

  /**
   * This will run when any of the many reference arrays change. It'll cause the
   * main table to be updated with the combined contents of each array. Note that
   * the Table's contents should be changed using ref methods, never by changing
   * the source, because source changes cause a databind and are slow.
   */
  useEffect(() => {
    const data = [
      fonteReferenciaAtividadeExploracao,
      fonteReferenciaOcorrenciaMineral,
      fonteReferenciaDeposito,
      fonteMinaGarimpo,
      fonteAtividadeMineracao,
      fonteEstudosMetalogeneticos
    ].flat();

    // If an element has a nomeProjeto but not a nomeProjeto, then it gets a
    // nomeProjeto. That's to avoid having to capture a variable inside
    // cellsrenderer.
    data.forEach((item) => {
      if (typeof item.idProjeto !== "undefined" && typeof item.nomeProjeto === "undefined") {
        const project = projetos.find((p) => p.id?.toString() === item.idProjeto?.toString());
        if (project) {
          item.nomeProjeto = project.nome;
        }
      }
    });

    referenciasTableRef.current?.resetrows(data, data.filter((a) => a.id));

    // Also, we should also force a rerender on the substances table, to recalculate
    // the enable/disable state of its edit and delete actions.
    // Before the SubForm refactor, this used to happen "automatically" because all
    // the tables everywhere in the page rerendered multiple times without being
    // told to.
    substanciasTableRef.current?.refreshItems();
  }, [fonteReferenciaAtividadeExploracao, fonteReferenciaOcorrenciaMineral, fonteReferenciaDeposito, fonteMinaGarimpo, fonteAtividadeMineracao, fonteEstudosMetalogeneticos])


  useEffect(() => {
    setColunasSubstancias([
      {
        text: "Importância", datafield: "importancia", width: "20%"
      },
      {
        text: "Substância", datafield: "idTipoSubstancia", width: "54%",
        cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
          let substancias = tipoSubstancia;
          let nome = "";
          substancias?.map(substancia => {
            if (substancia?.id?.toString() === rowdata?.idTipoSubstancia?.toString()) {
              nome = substancia.nome;
            }
          });
          return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${nome}</div>`;
        }
      },
      {
        text: "Ordem de nomenclatura", datafield: "ordemNomenclatura", width: "20%"
      }
    ])
  }, [tipoSubstancia])

  useEffect(() => {
    async function componentInit() {
      let dadosRecurso = await props.referenciaForm?.form?.getState()?.values;
      if (dadosRecurso.id > 0 && dadosRecurso.modoCadastro === "DEFINITIVO" || props.permitirEdicao === false) {
        setBloquearModoCadastro(true)
      }

      let tempData = dadosRecurso.id && await resolverRequestRecmin("/dadosAuditoria/buscarPorAtividade/" + dadosRecurso.id);

      let statusEconomicoAll = await resolverRequestRecmin("/statusEconomico");

      setStatusEconomicoDeposito(statusEconomicoAll);

      setStatusEconomicoOcorrencia(statusEconomicoAll.filter((item) => item.id !== 1));

      setStatusEconomico(statusEconomicoAll)
      setGrauImportancia(await resolverRequestRecmin("/grauImportancia"))
      setTipoSubstancia(await resolverRequestRecmin("/tipoSubstancia"))
      let tempProjetos = await resolverRequestRecmin("/projetos");
      setProjetos(tempProjetos);
      setRegistrosHistorico(tempData)
      setFeicaoGeologica(await resolverRequestRecmin("/feicaoGeologica"))
      setSituacaoMineracao(await resolverRequestRecmin("/situacao"))

      if (dadosRecurso?.id && dadosRecurso.id > 0) {
        iniciarArrayFontesReferencia(dadosRecurso);
      }

      let data = dadosRecurso.dataCriacao ? new Date(dadosRecurso.dataCriacao) : new Date();
      let dia = data.getDate() < 10 ? `0${data.getDate()}` : data.getDate();
      let mes = data.getMonth() + 1 < 10 ? `0${data.getMonth() + 1}` : data.getMonth();
      let dateString = `${dia}/${mes}/${data.getFullYear()}`;
      setDataCriacao(dateString);

      setCarregamentoGlobal(false);
    }
    componentInit()
  }, [])

  useEffect(() => {
    subsFiltradas = tipoSubstancia;

    let idsExistentes = [];
    let dadosRecurso = props.referenciaForm?.form?.getState()?.values;
    if (dadosRecurso && dadosRecurso.substancias) {
      let substanciaTemp = resolverExibidos(dadosRecurso.substancias);
      substanciaTemp?.map(substancia => {
        idsExistentes.push(substancia.idTipoSubstancia);
        return 0;
      })
      if (idsExistentes.length > 0) {
        let listaFiltrada = tipoSubstancia.filter(tipoSubstancia => !idsExistentes.includes(tipoSubstancia.id));
        subsFiltradas = listaFiltrada;
      }

      iniciarArraySubstancias(dadosRecurso);
    }

  }, [tipoSubstancia])

  function permitirEdicaoSubstancia(elemento) {
    if (!elemento || (elemento.importancia === "SECUNDARIA" && !aoCarregarDependentesSubstancias(elemento)))
      return false;
    return true;
  }
  function aoCarregarDependentesSubstancias(elemento) {
    if (!elemento)
      return false;
    let referenciaForm = props.referenciaForm.form.getState().values;

    let dependentesSubstancias = [];

    if (referenciaForm?.minasGarimpos) {
      let minasGarimpos = resolverExibidos(referenciaForm?.minasGarimpos);
      if (minasGarimpos)
        dependentesSubstancias.push(minasGarimpos?.map(x => {
          if (x?.substancias)
            return resolverExibidos(x.substancias);
          return [];
        }));
    }

    if (referenciaForm?.ocorrenciaMineral) {
      let ocorrenciasMinerais = resolverExibidos(referenciaForm?.ocorrenciaMineral);
      if (ocorrenciasMinerais)
        dependentesSubstancias.push(ocorrenciasMinerais?.map(x => {
          if (x?.substancias)
            return resolverExibidos(x.substancias);
          return [];
        }));
    }

    if (referenciaForm?.depositos) {
      let depositos = resolverExibidos(referenciaForm?.depositos);
      if (depositos)
        dependentesSubstancias.push(depositos.map(x => {
          if (x?.substancias)
            return resolverExibidos(x.substancias);
          return [];
        }));
    }

    if (dependentesSubstancias) {
      dependentesSubstancias = dependentesSubstancias.flat(2);


      if (dependentesSubstancias.some(x => x && x.idTipoSubstancia == elemento.idTipoSubstancia))
        return false;
    }

    if (referenciaForm.atividadesMineracao) {
      let atividadesMineracao = resolverExibidos(referenciaForm?.atividadesMineracao);
      if (atividadesMineracao) {
        let produtosDependentes = atividadesMineracao?.map(a => {
          if (a?.dadosEconomicos) {
            let dadosEconomicos = resolverExibidos(a.dadosEconomicos);
            let produtos = dadosEconomicos?.map(d => d.produtos);
            return produtos?.map(x => {
              if (x?.substancias)
                return resolverExibidos(x.substancias);
              return [];
            });
          }
          return null;
        });
        if (produtosDependentes) {
          produtosDependentes = produtosDependentes.flat(2);
          let hasSome = produtosDependentes.some(p => p?.idTipoSubstancia === elemento?.idTipoSubstancia);
          if (hasSome)
            return false;
        }
      }
    }
    return true;
  };

  function atualizarTabelaSubstancias() {
    let substancias = props?.referenciaForm?.form?.getState()?.values.substancias;
    console.log(substancias);
  }

  function iniciarArrayFontesReferencia(recursoMineral) {
    if (recursoMineral && recursoMineral.id && recursoMineral.fontesReferencia) {
      let tempFontes = resolverExibidos(recursoMineral.fontesReferencia);
      let arrayAtividadeExploracao = [];
      let arrayOcorrenciaMineral = [];
      let arrayDeposito = [];
      let arrayMinaGarimpo = [];
      let arrayAtividadeMineracao = [];
      let arrayEstudosMetalogeneticos = [];

      tempFontes?.map(referencia => {
        if (referencia.origem === ATIVIDADE_EXPLORACAO.id) {
          referencia.origemDescricao = "Atividade de exploração";
          arrayAtividadeExploracao.push(referencia);
        };
        if (referencia.origem === OCORRENCIA_MINERAL.id) {
          referencia.origemDescricao = "Ocorrência mineral";
          arrayOcorrenciaMineral.push(referencia);
        }
        if (referencia.origem === DEPOSITO.id) {
          referencia.origemDescricao = "Depósito";
          arrayDeposito.push(referencia);
        }
        if (referencia.origem === MINA_GARIMPO.id) {
          referencia.origemDescricao = "Mina ou garimpo";
          arrayMinaGarimpo.push(referencia);
        }
        if (referencia.origem === ATIVIDADE_MINERACAO.id) {
          referencia.ororigemDescricaoigem = "Atividade de mineração";
          arrayAtividadeMineracao.push(referencia);
        }
        if (referencia.origem === ESTUDO_METALOGENETICO.id) {
          referencia.origemDescricao = "Estudos metalogenéticos";
          arrayEstudosMetalogeneticos.push(referencia);
        }

      })

      let arrayFinal = [arrayAtividadeExploracao, arrayOcorrenciaMineral,
        arrayDeposito, arrayMinaGarimpo, arrayAtividadeMineracao, arrayEstudosMetalogeneticos];

      arrayFinal.map(array => {
        atualizarTabelaFonteReferenciaRecursoMineral(array, array[0]?.origem)
      })
    }
  }

  function iniciarArraySubstancias(recursoMineral) {
    if (recursoMineral) {
      let substanciaTemp = resolverExibidos(recursoMineral.substancias);

      let arraySubstanciasPrincipaisSelect = [];
      let arraySubstanciasSecundariasSelect = [];

      let tiposSubstancia = tipoSubstancia;

      let idsExistentes = [];
      substanciaTemp?.map(substancia => {
        let tipoSubstanciaTmp = tiposSubstancia?.find(t => t.id === substancia?.idTipoSubstancia);
        idsExistentes.push(substancia.idTipoSubstancia);
        let nomeSubstancia = tipoSubstanciaTmp?.nome;

        if (substancia.importancia === "PRINCIPAL") {
          arraySubstanciasPrincipaisSelect.push({ id: substancia.idTipoSubstancia, nome: nomeSubstancia })
        }
        if (substancia.importancia === "SECUNDARIA") {
          arraySubstanciasSecundariasSelect.push({ id: substancia.idTipoSubstancia, nome: nomeSubstancia })
        }
      });

      subsPrincipais = arraySubstanciasPrincipaisSelect;
      subsSecundarias = arraySubstanciasSecundariasSelect;

      localStorage.setItem('SUBSTANCIAS_RECMIN', JSON.stringify({ primarias: arraySubstanciasPrincipaisSelect, secundarias: arraySubstanciasSecundariasSelect }));
      window.dispatchEvent(new Event('SUBSTANCIAS_RECMIN', { primarias: arraySubstanciasPrincipaisSelect, secundarias: arraySubstanciasSecundariasSelect }));
    }
  }

  function onSubmitSubForm({ alteracoesForm, novosElementos, prefixoAtual }, formProps) {
    formProps?.batch(() => {
      for (const [key, val] of Object.entries(alteracoesForm)) {
        formProps?.mutators?.setValue(`${key}`, val);
      }
    });
    if (formProps.getState().values.substancias !== undefined) {
      let substanciaTemp = resolverExibidos(formProps.getState().values.substancias);
      let arraySubstanciasPrincipaisSelect = [];
      let arraySubstanciasSecundariasSelect = [];
      subsFiltradas = tipoSubstancia;

      let idsExistentes = [];
      substanciaTemp?.map(substancia => {
        let nomeSubstancia = "";
        idsExistentes.push(substancia.idTipoSubstancia);
        tipoSubstancia?.map(substanciaState => {
          if (substanciaState.id === substancia.idTipoSubstancia) {
            nomeSubstancia = substanciaState.nome;
          }
        })
        if (substancia.importancia === "PRINCIPAL") {
          arraySubstanciasPrincipaisSelect.push({ id: substancia.idTipoSubstancia, nome: nomeSubstancia })
        }
        if (substancia.importancia === "SECUNDARIA") {
          arraySubstanciasSecundariasSelect.push({ id: substancia.idTipoSubstancia, nome: nomeSubstancia })
        }
        return 0;
      }

      )
      if (idsExistentes.length > 0) {
        let listaFiltrada = tipoSubstancia.filter(tipoSubstancia => !idsExistentes.includes(tipoSubstancia.id));
        subsFiltradas = listaFiltrada;
      }

      subsPrincipais = arraySubstanciasPrincipaisSelect;
      subsSecundarias = arraySubstanciasSecundariasSelect;
      localStorage.setItem('SUBSTANCIAS_RECMIN', JSON.stringify({ primarias: arraySubstanciasPrincipaisSelect, secundarias: arraySubstanciasSecundariasSelect }));
      window.dispatchEvent(new Event('SUBSTANCIAS_RECMIN'));
    }
  }

  function aoEditarSubstancias(event, tableRef, formProps) {
    if (formProps.getState().values.substancias !== undefined) {
      let substanciaTemp = resolverExibidos(formProps.getState().values.substancias);

      subsFiltradas = tipoSubstancia;

      let idSubstanciaEditada = event.rowdata?.rowData?.idTipoSubstancia;
      if (idSubstanciaEditada)
        idSubstanciaEditada = parseInt(idSubstanciaEditada);

      let idsExistentes = [];
      substanciaTemp?.map(substancia => {
        idsExistentes.push(substancia.idTipoSubstancia);
        return 0;
      }

      )
      if (idsExistentes.length > 0) {
        let listaFiltrada = tipoSubstancia.filter(tipoSubstancia => !idsExistentes.includes(tipoSubstancia.id) || tipoSubstancia.id == idSubstanciaEditada);
        subsFiltradas = listaFiltrada;
      }

    }
    window.dispatchEvent(new Event('SUBSTANCIAS_RECMIN'));
  }

  function aoVoltarSubstancias(formProps) {
    if (formProps.getState().values.substancias !== undefined) {
      let substanciaTemp = resolverExibidos(formProps.getState().values.substancias);
      subsFiltradas = tipoSubstancia;

      let idsExistentes = [];
      substanciaTemp?.map(substancia => {
        idsExistentes.push(substancia.idTipoSubstancia);
        return 0;
      });


      if (idsExistentes.length > 0) {
        let listaFiltrada = tipoSubstancia.filter(tipoSubstancia => !idsExistentes.includes(tipoSubstancia.id));
        subsFiltradas = listaFiltrada;
      }
    }
  }

  function trocarPermitirEdicaoCoordenadas(valor) {
    setPermitirEdicaoCoordenadas(valor)
  }

  async function ordenarSubstancias(elementos) {
    let tipoSubstancias = tipoSubstancia

    if (!tipoSubstancias || tipoSubstancias.length < 1) {
      tipoSubstancias = await resolverRequestRecmin("/tipoSubstancia");
    }

    const secundarios = elementos.filter(elemento => elemento.importancia === 'SECUNDARIA')
    const principais = elementos.filter(elemento => elemento.importancia === 'PRINCIPAL')

    secundarios.sort((a, b) => a.ordemNomenclatura - b.ordemNomenclatura).sort((a, b) => tipoSubstancias.find(tipo => tipo.id === a.idTipoSubstancia)?.nome.toLowerCase().localeCompare(tipoSubstancias.find(tipo => tipo.id === b.idTipoSubstancia)?.nome.toLowerCase()))

    return [...principais, ...secundarios];
  }

  //TODO verificar implementação 

  async function validacoesSubstancias(valores, form) {
    let isErrorOrdemZero = false;
    let isErrorOrdemRepetida = false;

    if (parseInt(valores.ordemNomenclatura) <= 0) {
      isErrorOrdemZero = true;
    }

    if (form.getState().values.substancias) {
      let substancias = resolverExibidos(form.getState().values.substancias);

      substancias.forEach(sb => {
        if (sb.id === valores.id && sb.ordemNomenclatura === valores.ordemNomenclatura) {
          isErrorOrdemRepetida = false
        } else
          if (sb.ordemNomenclatura === valores.ordemNomenclatura && valores.importancia === 'SECUNDARIA' && sb.importancia === 'SECUNDARIA') {
            isErrorOrdemRepetida = true;
          }
      });
    }

    if (isErrorOrdemRepetida || isErrorOrdemZero) {
      return {
        [FORM_ERROR]: `${isErrorOrdemRepetida ? 'Substâncias não podem ter ordens de nomenclatura repetidas. ' : ''}${isErrorOrdemZero ? 'Ordem de nomenclatura não pode ser igual ou inferior a zero.' : ''}`
      }
    }

  }
  function validaSubstancias(valores, form) {
    return 'Ao menos uma substância deve ser cadastrada no Recurso mineral.';
  }

  function validarFormsStatusMinaGarimpo(valores, form) {
    return 'É obrigatório o cadastro de um Depósito ou Ocorrência mineral e uma Mina ou Garimpo.';
  }
  function validarFormsStatusNaoExplotado(valores, form) {
    return 'É obrigatório o cadastro de um Depósito ou Ocorrência mineral.'
  }

  return (
    <BlockUi
      blocking={false}>
      <Row className="mb-4">
        <Col md={5}>
          <Field
            name="modoCadastro"
            disabled={bloquearModoCadastro}
            component={RadioButtonWithFieldSetField}
            label="Modo de cadastro"
            dica="O Modo Rascunho permite salvar informações parciais; o Modo Completo exige informações completas conforme presença de (*)"
            elementos={modoCadastro}
            horizontal
            validate={campoObrigatorioComMsgGenerica(
              "Modo de cadastro"
            )}
          />
        </Col>
      </Row>
      <Card className="card-mb">

        <Card.Body>
          <Field name={'idsPontosAssociados'} subscription={{ value: true }}>
            {({ input: { value: idsPontosAssociados = {} } }) => {
              return (<PontosGPSAfloramento
                idEstacao={props.idEstacao}
                pontosAtribuidosAEstacao={props.pontosAtribuidosAEstacao}
                permitirEdicao={props.permitirEdicao}
              />);
            }}
          </Field>
        </Card.Body>

        <InformacoesCoordenadas
          customPaddingAndMargin="p-0 m-0"
          configMapa={configMapaRecursoMineral}
          mapaAtividade={true}
          datums={props.datums}
          permitirEdicao={props.permitirEdicao ?? permitirEdicaoCoordenadas}
          pontosAtribuidosAEstacao={props.pontosAtribuidosAEstacao}
          visitaSelecionada={props.visitaSelecionada}
          area={props.area}
          subArea={props.subArea}
          metodosGeoposicionamento={props.metodosGeoposicionamento}
          categoriasLimiteArea={props.categoriasLimiteArea}
          altitude={true}
          fusos={props.fusos}
          coordenadasAtuais={this?.props?.referenciaForm?.form?.getState()?.values?.coordenadas}
          exibirLegendaEstacao={true}
          exibirLegendaEstacaoHistorica={false}
          exibirLegendaEstacaoEditada={false}
          exibirLegendaAtividade={true}
          exibirLegendaPontoGPS={false}
          exibirLegendaGeometriaProjeto={true}
          naoExibirEmCol={true}
        />
      </Card>
      <Card className="card-mb">
        <Card.Body>
          <FormSpy subscription={{ values: true }}>
            {({ values }) => {

              let existeSubstancia = false;

              if (values && values.substancias) {
                existeSubstancia = resolverExibidos(values.substancias).length > 0;
              }

              const validar = values.modoCadastro === 'DEFINITIVO';

              let validateType = undefined;
              if (values.idStatusEconomico === null || values.idStatusEconomico === undefined)
                validateType = undefined;
              else {
                if (values.idStatusEconomico === 3) {
                  if ((!values.depositos || !resolverExibidos(values.depositos).length) &&
                    (!values.ocorrenciaMineral || !resolverExibidos(values.ocorrenciaMineral).length)) {
                    validateType = 1;
                  }
                }
                else {
                  if ((!values.depositos || !resolverExibidos(values.depositos).length) && (!values.ocorrenciaMineral || !resolverExibidos(values.ocorrenciaMineral).length))
                    validateType = 2;

                  if (!values.minasGarimpos || !resolverExibidos(values.minasGarimpos).length)
                    validateType = 2;
                }
              }

              return (
                <Row className="mb-4">
                  <Col>
                    {
                      (!existeSubstancia) && <Field
                        name="validarSubstancia"
                        component={HiddenField}
                        validate={validaSubstancias}
                      />
                    }
                  </Col>
                  {
                    validar ?
                      <Col md={6}>
                        {
                          validateType === 1 && <Field
                            name="validarFormsObrigatorios"
                            component={HiddenField}
                            validate={validarFormsStatusNaoExplotado}
                          />
                        }
                        {
                          validateType === 2 && <Field
                            name="validarFormsObrigatorios"
                            component={HiddenField}
                            validate={validarFormsStatusMinaGarimpo}
                          />
                        }
                        {
                          !validateType && <Field
                            name="validarFormsObrigatorios"
                            component={HiddenField}
                            validate={undefined}
                          />
                        }
                      </Col> : <></>
                  }
                </Row>
              )
            }}
          </FormSpy>

          <Card.Title>Dados do Recurso mineral</Card.Title>
          <Row className="mb-5 mt-5">
            <Col md={6}>
              <Field name={'modoCadastro'} subscription={{ value: true }}>
                {({ input: { value: modoCadastro } }) => {
                  const validar = modoCadastro === 'DEFINITIVO';

                  return <Field
                    name="idFeicoesGeologicas"
                    component={DropdownListField}
                    label="Fonte da Informação/Feição Geológica"
                    dica="Informe a Fonte da Informação e/ou Feição Geológica cadastrada"
                    initialValue=""
                    elementos={feicaoGeologica}
                    required={validar}
                    multiple
                    checkboxes={true}
                    disabled={!props.permitirEdicao}
                    validate={campoObrigatorioDefinitivoComMsgGenerica("Fonte da Informação/Feição Geológica")}
                  />
                }}
              </Field>
            </Col>
          </Row>

          <Card className="mt-4">
            <Card.Body>
              <Row>
                <Col md={12}>
                  <Field name={"substancias"} subscription={{ value: true }}>
                    {({ input: { value: substancias = {} } }) => {
                      let valorInicialImportancia = 'PRINCIPAL';
                      let idPrincipal = undefined;

                      if (substancias) {
                        let subsList = resolverExibidos(substancias);
                        let principalSub = subsList.find(sub => sub.importancia === 'PRINCIPAL');

                        if (principalSub) {
                          idPrincipal = principalSub.id;
                          valorInicialImportancia = 'SECUNDARIA';
                        }
                      }

                      return <SubForm
                        ordenacaoPersonalizada={async (elementos) => await ordenarSubstancias(elementos)}
                        {...getPropsPermissao(props.permitirEdicao)}
                        podeEditarEntidade={props.permitirEdicao ? permitirEdicaoSubstancia : undefined}
                        keepDirtyOnReinitialize
                        tableRef={substanciasTableRef}
                        podeExcluirEntidade={(el) => !!aoCarregarDependentesSubstancias(el)}
                        tituloForm="Substâncias"
                        nome="substancias"
                        onSubmit={(alteracoesForm) => onSubmitSubForm(alteracoesForm, props.referenciaForm.form)}
                        onBtnEditClick={(event, tableRef) => aoEditarSubstancias(event, tableRef, props.referenciaForm.form)}
                        aoConfirmarVoltar={() => aoVoltarSubstancias(props.referenciaForm.form)}
                        colunas={colunasSubstancias}
                        campos={datafieldsSubstancias}
                        mutators={{ setValue }}
                        elementos={substancias}
                        validar={(valores) => validacoesSubstancias(valores, props.referenciaForm.form)}
                        formSubscription={{}}
                        valoresIniciais={{
                          importancia: valorInicialImportancia
                        }}
                        exibirAsterisco={true}
                        renderForm={({ formProps }) => {
                          let permitirAlterarSubstancia = !aoCarregarDependentesSubstancias(formProps.form.getState().values);
                          return <>
                            <Row>
                              <Col md={12}>
                                <Card.Title>
                                  Substâncias
                                </Card.Title>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={4}>
                                <Row>
                                  {/* <Col md={12}>
                                        <h3 className="h6">Importância</h3>
                                      </Col> */}
                                </Row>
                                <Row>
                                  <Field name={"importancia"} subscription={{ value: true }}>
                                    {({ input: { value } }) => {
                                      let idSub = formProps?.form?.getState()?.values?.id;
                                      let desabilitado = valorInicialImportancia === 'SECUNDARIA' && idPrincipal != idSub;

                                      return <Col md={12}>
                                        <Form.Label className="small">
                                          Importância
                                          <Hint textoAjuda="Informe se a substância é principal ou secundária. Substância principal é aquela que define a ocorrência ou depósito - produto, coproduto. Substância(s) secundária(s) é(são) o(s) subproduto(s)." />
                                        </Form.Label>
                                        <Container
                                          fluid
                                          className="vertical-line-right p-0 m-0"
                                        >
                                          <Field
                                            component={RadioButtonSetField}
                                            name="importancia"
                                            elementos={importancia}
                                            horizontal
                                            disabled={desabilitado ? true : (!props.permitirEdicao || permitirAlterarSubstancia)}
                                          />
                                        </Container>
                                        {
                                            desabilitado && <div><br></br><small>{
                                              "(Desabilitado por já existir uma substância principal cadastrada)"}
                                            </small>
                                            </div>
                                          }
                                      </Col>
                                    }}
                                  </Field>
                                </Row>
                              </Col>

                              <Col md={4}>
                                <Field
                                  name="idTipoSubstancia"
                                  component={DropdownListField}
                                  label="Substância"
                                  dica="Informar o nome da substância a partir da biblioteca"
                                  elementos={props.permitirEdicao ?subsFiltradas: tipoSubstancia}
                                  required
                                  initialValue=""
                                  validate={campoObrigatorioComMsgGenerica(
                                    "Substância"
                                  )}
                                  disabled={!props.permitirEdicao || permitirAlterarSubstancia}
                                />
                              </Col>
                              <Field name={"importancia"} subscription={{ value: true }}>
                                {({ input: { value: importancia = {} } }) => {
                                  let ordemObrigatoria = false;
                                  let id = formProps?.form?.getState()?.values?.id;

                                  if (substancias) {
                                    let subsList = resolverExibidos(substancias);

                                    if (subsList
                                      .find(sub => sub.importancia === 'SECUNDARIA' && sub.ordemNomenclatura && sub.id != id))
                                      ordemObrigatoria = true;
                                  }

                                  return <>
                                    {importancia === "SECUNDARIA" && <Col md={4}>
                                      <Field
                                        name="ordemNomenclatura"
                                        component={NumberInputField}
                                        label="Ordem de nomenclatura"
                                        dica="Ordenar as substâncias (de 1 em diante) na ordem de importância (p.ex., num depósito de cobre e ouro, 1-cobre, 2-ouro)"
                                        permitirNegativos={false}
                                        casasInteiros={4}
                                        casasDecimais={0}
                                        valorMaximo={9999999}
                                        required={ordemObrigatoria}
                                        validate={validarSe(ordemObrigatoria, campoObrigatorioComMsgGenerica(
                                          "Ordem de nomenclatura"
                                        ))}
                                        disabled={!props.permitirEdicao}
                                      />
                                    </Col>}
                                  </>

                                }}</Field>

                            </Row>
                          </>
                        }}
                      />
                    }

                    }
                  </Field>
                </Col>
              </Row>
            </Card.Body>
          </Card>

          <FormSpy subscription={{ values: true }}>
            {({ values }) => {

              const validar = values.modoCadastro === 'DEFINITIVO';

              return (
                <Row className="mt-4">
                  <Col md={4}>
                    <Field
                      name="idGrauImportancia"
                      component={DropdownListField}
                      label="Grau de importância"
                      dica="Definir se é ocorrência ou depósito"
                      elementos={grauImportancia}
                      required={validar}
                      validate={campoObrigatorioDefinitivoComMsgGenerica(
                        "Grau de importância"
                      )}
                      disabled={!props.permitirEdicao}
                    />
                  </Col>
                  {
                    values.idGrauImportancia === null || values.idGrauImportancia === undefined ?
                      <Col md={4}>
                        <Field
                          name="idStatusEconomico"
                          component={DropdownListField}
                          label="Status econômico"
                          dica="Definir se é mina, garimpo ou não explotado"
                          elementos={statusEconomico}
                          required={validar}
                          validate={campoObrigatorioDefinitivoComMsgGenerica(
                            "Status econômico"
                          )}
                          disabled={true}
                        />
                      </Col> : <></>
                  }
                  {
                    values.idGrauImportancia === 2 ?
                      <Col md={4}>
                        <Field
                          name="idStatusEconomico"
                          component={DropdownListField}
                          label="Status econômico"
                          dica="Definir se é mina, garimpo ou não explotado"
                          elementos={statusEconomicoDeposito}
                          required={validar}
                          validate={campoObrigatorioDefinitivoComMsgGenerica(
                            "Status econômico"
                          )}
                          disabled={!props.permitirEdicao}
                          onChange={() => {
                            if (values.idStatusEconomico === null || values.idStatusEconomico === undefined) {
                              setOcorrenciaObrigatoria(false);
                              setDepositoObrigatorio(false);
                              setMinaGarimpoObrigatorio(false);
                            }
                            else if (values.idStatusEconomico === 3) {
                              setOcorrenciaObrigatoria(true);
                              setDepositoObrigatorio(true);
                              setMinaGarimpoObrigatorio(false);
                            }
                            else {
                              setOcorrenciaObrigatoria(true);
                              setDepositoObrigatorio(true);
                              setMinaGarimpoObrigatorio(true);
                            }
                          }}
                        />
                      </Col> : <></>
                  }
                  {
                    values.idGrauImportancia === 0 ?
                      <Col md={4}>
                        <Field
                          name="idStatusEconomico"
                          component={DropdownListField}
                          label="Status econômico"
                          dica="Definir se é mina, garimpo ou não explotado"
                          elementos={statusEconomicoOcorrencia}
                          required={validar}
                          validate={campoObrigatorioDefinitivoComMsgGenerica(
                            "Status econômico"
                          )}
                          disabled={!props.permitirEdicao}
                          onChange={() => {
                            if (values.idStatusEconomico === null || values.idStatusEconomico === undefined) {
                              setOcorrenciaObrigatoria(false);
                              setDepositoObrigatorio(false);
                              setMinaGarimpoObrigatorio(false);
                            }
                            else if (values.idStatusEconomico === 3) {
                              setOcorrenciaObrigatoria(true);
                              setDepositoObrigatorio(true);
                              setMinaGarimpoObrigatorio(false);
                            }
                            else {
                              setOcorrenciaObrigatoria(true);
                              setDepositoObrigatorio(true);
                              setMinaGarimpoObrigatorio(true);
                            }
                          }}
                        />
                      </Col> : <></>
                  }

                  <Col md={4}>
                    <Field
                      name="idSituacao"
                      component={DropdownListField}
                      label="Situação da mineração"
                      disabled={!props.permitirEdicao}
                      dica="Selecionar a situação da atividade mineira no local e época da visita"
                      elementos={situacaoMineracao}
                    />
                  </Col>
                </Row>
              )
            }}
          </FormSpy>

          <Field name={'modoCadastro'} subscription={{ value: true }}>
            {({ input: { value: modoCadastro } }) => {
              const modoDefinitivo = modoCadastro === 'DEFINITIVO';

              return <>
                <Row>
                  <Col md={4}>
                    <Field
                      component={RadioField}
                      disabled={!props.permitirEdicao}
                      name="processoAnmAndp"
                      campo="processoAnmAndp"
                      label="Processo ANM/DNPM relacionado"
                      tipoCampo={TextMaskField}
                      dica="Informar se há processo relacionado na ANM/DNPM"
                      modoDefinitivo={modoDefinitivo}
                      colunas={3}
                    />
                  </Col>
                </Row></>
            }}</Field>

          <Row className="mt-4 mb-4">
            <Col md={12}>
              <Card.Title>Fontes de referência</Card.Title>
            </Col>
          </Row>
          <Row className="mt-4 mb-4">
            <Col md={12}>
              <Table
                ref={referenciasTableRef}
                editable={false}
                columns={tabelaReferenciaColunas}
                datafields={tabelaReferenciaDatafields}
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Field name={'modoCadastro'} subscription={{ value: true }}>
        {({ input: { value: modoCadastro } }) => {

          const modoDefinitivo = modoCadastro === 'DEFINITIVO';

          return <>
            <Card className="card-mb">
              <Card.Body>
                <OcorrenciaMineral
                  substanciasPrincipais={subsPrincipais}
                  substanciasSecundarias={subsSecundarias}
                  referenciaForm={props.referenciaForm.form}
                  mutators={{ setValue }}
                  atualizarTabelaSubstancias={atualizarTabelaSubstancias.bind(this)}
                  atualizarTabelaFonteReferenciaRecursoMineral={atualizarTabelaFonteReferenciaRecursoMineral}
                  idEstacao={props.idEstacao}
                  permitirEdicao={props.permitirEdicao}
                  obrigatorio={ocorrenciaObrigatoria}
                  modoDefinitivo={modoDefinitivo}
                  dataCriacao={dataCriacao}
                />
              </Card.Body>
            </Card>
            <Card className="card-mb">
              <Card.Body>
                <Deposito
                  referenciaForm={props.referenciaForm.form}
                  substanciasPrincipais={subsPrincipais}
                  substanciasSecundarias={subsSecundarias}
                  mutators={{ setValue }}
                  atualizarTabelaFonteReferenciaRecursoMineral={atualizarTabelaFonteReferenciaRecursoMineral}
                  permitirEdicao={props.permitirEdicao}
                  obrigatorio={depositoObrigatorio}
                  modoDefinitivo={modoDefinitivo}
                  dataCriacao={dataCriacao}
                />
              </Card.Body>
            </Card>
            <Card className="card-mb">
              <Card.Body>
                <MinaGarimpo
                  referenciaForm={props.referenciaForm.form}
                  mutators={{ setValue }}
                  substanciasPrincipais={subsPrincipais}
                  substanciasSecundarias={subsSecundarias}
                  atualizarTabelaFonteReferenciaRecursoMineral={atualizarTabelaFonteReferenciaRecursoMineral}
                  permitirEdicao={props.permitirEdicao}
                  obrigatorio={minaGarimpoObrigatorio}
                  modoDefinitivo={modoDefinitivo}
                  dataCriacao={dataCriacao}
                />
              </Card.Body>
            </Card>
            <Card className="card-mb">
              <Card.Body>
                <AtividadeExploracao
                  referenciaForm={props.referenciaForm.form}
                  mutators={{ setValue }}
                  atualizarTabelaFonteReferenciaRecursoMineral={atualizarTabelaFonteReferenciaRecursoMineral}
                  permitirEdicao={props.permitirEdicao}
                  modoDefinitivo={modoDefinitivo}
                />
              </Card.Body>
            </Card>
            <Card className="card-mb">
              <Card.Body>
                <AtividadeMineracao
                  referenciaForm={props.referenciaForm.form}
                  mutators={{ setValue }}
                  atualizarTabelaFonteReferenciaRecursoMineral={atualizarTabelaFonteReferenciaRecursoMineral}
                  substancias={[subsPrincipais, subsSecundarias].flat()}
                  permitirEdicao={props.permitirEdicao}
                  modoDefinitivo={modoDefinitivo}
                  dataCriacao={dataCriacao}
                />
              </Card.Body>
            </Card>
            <Card className="card-mb">
              <Card.Body>
                <EstudosMetalogeneticos referenciaForm={props.referenciaForm.form} mutators={{ setValue }}
                  minerais={props.minerais}
                  mineraisPorId={props.mineraisPorId}
                  complementosPorId={props.complementosPorId}
                  prefixosPorId={props.prefixosPorId}
                  atualizarTabelaFonteReferenciaRecursoMineral={atualizarTabelaFonteReferenciaRecursoMineral}
                  idEstacao={props.idEstacao}
                  trocarPermitirEdicaoCoordenadas={trocarPermitirEdicaoCoordenadas}
                  permitirEdicao={props.permitirEdicao}
                  modoDefinitivo={modoDefinitivo}
                />
              </Card.Body>
            </Card>
          </>
        }}
      </Field>

      <FormSpy subscription={{ values: true }}>
        {({ values }) => {
          if (props.idAtividade) {
            return <Card className="mt-4">
              <Card.Body>
                <HistoricoAlteracoes
                  alterarExibirBotoesPadrao={props.alterarExibirBotoesPadrao}
                  registros={registrosHistorico}
                />
              </Card.Body>
            </Card>
          } else {
            return <></>
          }
        }}
      </FormSpy>
    </BlockUi>
  )
}

export default RecursoMineral;
