import { concat } from "lodash";
import swal from "sweetalert";

const camposComuns = ["FINAL_FORM/form-error"];

const filtrarCampos = (modified = {}, campos = []) => {
  const novosCampos = concat(camposComuns, campos);
  novosCampos.forEach(key => delete modified[key])
  return modified;
}

export const camposForamEditados = async (form, naoValidarCampos = [], customSwal) => {
  const modified = { ...form.getState().modified, ...form.getState().dirtyFields }
  const camposFiltrados = filtrarCampos(modified, naoValidarCampos)
  const isModified = Object.values(camposFiltrados).includes(true);

  if (isModified) {
    console.log('Campos modificados:', Object.keys(camposFiltrados || {}).filter(k => camposFiltrados[k]));


    let result = null;

    if (customSwal) {
      result = await customSwal
    } else {
      result = await swal({
        title: `Os dados preenchidos não foram salvos, deseja prosseguir mesmo assim?`,
        icon: "warning",
        buttons: { cancel: "Não, cancelar", confirm: { text: "Sim, desejo prosseguir!", className: "btn-danger" }, },
      })
    }
    return result;
  }
  return true;
}
