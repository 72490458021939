import Service, { GEOCRON } from "../../../../../service/Service";
import { swalErro } from "../../../../../util/validadores";
import { AmostrasProximas } from "../types/amostra";

export const consultarAmostrasProximasPorIdAmostra = async (
  idAmostra: number
): Promise<AmostrasProximas[] | []> => {
  if (!idAmostra) return [];
  const response = await Service(`/amostras/amostras-proximas`, GEOCRON)
    .get(idAmostra)
    .catch((_error) => {
      swalErro({ title: "Erro ao consultar as amostras próximas." });
    });

  return response?.data?.dados ? response?.data?.dados : [];
};
