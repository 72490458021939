import React, { Component } from "react";
import Card from "../../../../components/card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Field, Form as FForm } from "react-final-form";
import {
  TIPO_ENTRADA_COORDS_DECIMAL,
} from "../../../../util/constantes";

import SeletorCoordenadasGeograficas from "./SeletorCoordenadasGeograficas";
import AppMapComponent from "../../../../components/mapa/map_component/AppMapComponent";
import { decoradorMapa } from "../../../../util/decoradores/decoradorMapa";
import { camposForamEditados } from "../../../../util/formFoiEditado";
import { converterCoordenadas } from "../../../../util/converterCoordenadas";
import { setValue } from "../../../../util/mutadores";
import Service from "../../../../service/Service";
import swal from "sweetalert";
import { FocusableButton } from "../../../../components/Jqx/Button";

import { configMapaCoordenadas } from "../../../../config/mapa";
import Hint from "../../../../components/hint/Hint";
import { resetarLatLongInvalidaAoTrocarHemisferio } from "../../../../util/decoradores/decoradorSeletorCoordenadas";

import LegendaMapa from "../../../estacoes/components/mapa/LegendaMapa";

const ID_DATUL_WSG84 = 3;
let formRef = undefined;
let estacoesLista = [];

export default class InformacoesCoordenadasMapa extends Component {
  constructor(props) {
    super(props);

    let formValues = localStorage.getItem('FORM_DEFINICAO_ESTACAO');

    this.valoresIniciais = formValues ? JSON.parse(formValues) :  {
      tipoEntrada: TIPO_ENTRADA_COORDS_DECIMAL.id,
      hemisferio: this.props.hemisferio,
      hemisferioHorizontal: this.props.hemisferioHorizontal,
    };
    this.history = this.props.history
    this.mapaRefCallback = this.mapaRefCallback.bind(this);
    this.gerarPontoGPS = this.gerarPontoGPS.bind(this);
    this.aoSelecionarEstacaoMapa = this.aoSelecionarEstacaoMapa.bind(this);
    this.enviarFormulario = this.enviarFormulario.bind(this);
    this.aoCriarNovaEstacao = this.aoCriarNovaEstacao.bind(this);
    this.onMapaCarregado = this.onMapaCarregado.bind(this);
    this.mapaRenderizado = false;

    this.exibirHistoricas = this.props.exibirHistoricas;

    this.exibirLegendaEstacao = this.props.exibirLegendaEstacao;
    this.exibirLegendaEstacaoHistorica = this.props.exibirLegendaEstacaoHistorica;
    this.exibirLegendaEstacaoEditada = this.props.exibirLegendaEstacaoEditada;
    this.exibirLegendaAtividade = this.props.exibirLegendaAtividade;
    this.exibirLegendaPontoGPS = this.props.exibirLegendaPontoGPS;
    this.exibirLegendaPontoGerado = this.props.exibirLegendaPontoGerado;
    this.exibirLegendaGeometriaProjeto = this.props.exibirLegendaGeometriaProjeto;

    this.decoradores = [
      decoradorMapa({
        prefixoCoordenadas: "",
        incluirDatum: this.props.incluirDatum != null && this.props.incluirDatum != undefined ? this.props.incluirDatum : true,
      }),
      resetarLatLongInvalidaAoTrocarHemisferio({ prefixo: '' })
    ];
  }

  async componentDidMount() {
    if(this.props.forcarLatitudeLogitudeEstacaoSelecionada){
      try {
        //const estacoes = await Service(`/estacoes?idArea=${this.props.idArea}&idSubArea=${this.props.idSubArea}`).query();
        //const estacoesHistoricas = await Service(`/estacoesHistoricas?idArea=${this.props.idArea}&idSubArea=${this.props.idSubArea}`).query();

        const estacoes = await Service(`/estacoes?idArea=${this.props.idArea}`).query();
        const estacoesHistoricas = await Service(`/estacoesHistoricas?idArea=${this.props.idArea}`).query();

        const lista = [...estacoes.data.dados, ...estacoesHistoricas.data.dados];
        estacoesLista = lista;
      }
      catch(err){}
    }
  }

  onSelecaoEstacaoMudar(idEstacao) {
    setTimeout(() => {
      try{
        const iframe = document.getElementsByClassName('mapa')[0];
        const pointDetailDiv = iframe.contentWindow.document.getElementById('pointDetailDiv');
        const estacao = estacoesLista.find(item => item.id === idEstacao);
        pointDetailDiv.innerHTML = `Latitude: ${estacao.latitudeSirgas2000.toFixed(6)} <br>Longitude: ${estacao.longitudeSirgas2000.toFixed(6)}`;
      }
      catch(err){
        console.log('Erro ao atualizar pop-up de estação', err)
      }
    }, 200);
  }

  voltar = async (form) => {
    window.scrollTo(0,0);

    localStorage.removeItem('FORM_DEFINICAO_ESTACAO');

    if(localStorage.getItem('coodernadasVoltar'))
      this.props.history.goBack();
    else
      this.props.history.push(`/estacoes`);
  };

  enviarFormulario(valores) {

    const locationState = {
      coordenadas: {
        hemisferio: valores.hemisferio,
        hemisferioHorizontal: valores.hemisferioHorizontal,
        tipoEntrada: valores.tipoEntrada,
        latitude: valores.latitude,
        longitude: valores.longitude,
        idDatum: valores.idDatum,
        fuso: valores.fuso,
        x: valores.x,
        y: valores.y,

        grauLatitude: valores.grauLatitude,
        minutoLatitude: valores.minutoLatitude,
        segundoLatitude: valores.segundoLatitude,
        grauLongitude: valores.grauLongitude,
        minutoLongitude: valores.minutoLongitude,
        segundoLongitude: valores.segundoLongitude,
      },
      altitude: valores.altitude,
      nomeProjeto: this.props.nomeProjeto,
      nomeArea: this.props.nomeArea,
      nomeSubArea: this.props.nomeSubArea,
      idProjeto: this.props.idProjeto,
      idArea: this.props.idArea,
      idSubArea: this.props.idSubArea,
      pontosGPSParaNovaEstacao: this.props.pontosGPSpendentes,
      geometryFilter: this.props.geometryFilter
    };

    this.props.history.push({
      pathname: "/estacao",
      state: locationState,
    });
  }

  mapaRefCallback(ref) {
    this.mapaRef = ref;
  }

  // Ao selecionar uma estação no mapa e clicar em "Usar estação selecioanda"
  async aoSelecionarEstacaoMapa(params, form) {
    // Ao clicar em "Usar estação selecionada", deve-se editar a estação.
    // Se houver pontos GPS selecionados, deve-se automaticamente associar esses pontos
    // e ir para a tela de edição

    // Recuperar id da estação selecionada no mapa
    let { idEstacao } = params;
    // Como as estações no mapa não refletem o banco dev local, para teste local deve-se
    // fazer a requisição manualmente com o id de uma estação válida na URL.
    // idEstacao = 39
    console.log("Utilizar (editar) estação selecionada id:", idEstacao);

    // Associar pontos GPS a estação existente selecionada
    if (
      this.props.pontosGPSpendentes &&
      this.props.pontosGPSpendentes.length > 0
    ) {
      const err = await Service(`gps/associarPontoEstacao/${idEstacao}`)
        .post(this.props.pontosGPSpendentes.map((p) => p.id)) // request body
        .then(() => { })
        .catch((err) => err);

      if (err) {
        console.error("Erro ao associar pontos:", err);
        swal({
          title:
            "Não foi possível associar os pontos GPS à estação selecionada",
          icon: "error",
        });
        return; // Não redirecionar
      }
      console.log(
        this.props.pontosGPSpendentes.length,
        "pontos GPS associados à estação"
      );
    }

    // Redirecionar edição de estação com props necessárias
    this.props.history.push({
      pathname: "/estacao",
      state: {
        nomeProjeto: this.props.nomeProjeto,
        nomeArea: this.props.nomeArea,
        nomeSubArea: this.props.nomeSubArea,
        idProjeto: this.props.idProjeto,
        idArea: this.props.idArea,
        idSubArea: this.props.idSubArea,
        geometryFilter: this.props.geometryFilter,
        idEstacaoSelecionada: idEstacao,
        permitirEdicao: true,
      },
    });

    // form.batch(() => {
    //   form.mutators.setValue('tipoEntrada', TIPO_ENTRADA_COORDS_DECIMAL.id);
    //   form.mutators.setValue('idDatum', ID_DATUL_WSG84);
    //   form.mutators.setValue('latitude', latitude);
    //   form.mutators.setValue('longitude', longitude);
    // })
  }

  // Ao selecionar um ponto gerado pelo botão "Gerar" e clicar em "Criar nova estação"
  // Ao selecionar um ponto GPS e clicar em "Criar nova estação"
  async aoCriarNovaEstacao(params, form) {
    const { latitude, longitude, altitude } = params;

    // Deve-se transportar os pontos GPS (se houverem) para a próxima tela e utilizar
    // as coordenadas do ponto selecionado como coordenada inicial (campo) da estação

    let hemisferio = latitude ? parseFloat(latitude.toString()) > 0 ? 'NORTE' : 'SUL' : undefined;
    let hemisferioHorizontal = longitude ? parseFloat(longitude.toString()) > 0 ? 'LESTE' : 'OESTE' : undefined;

    console.log('args', params, form.getState().values)

    const formValues = {
      latitude: latitude,
      longitude: longitude,
      altitude: altitude,
      idDatum: form.getState().values.idDatum,
      hemisferio: hemisferio,
      hemisferioHorizontal: hemisferioHorizontal,
      tipoEntrada: TIPO_ENTRADA_COORDS_DECIMAL.id,
    }

    localStorage.setItem('FORM_DEFINICAO_ESTACAO', JSON.stringify(formValues));

    const servicoEstacao =  Service("/estacoes");
    const res = await servicoEstacao.customURL("POST", "/verificarPonto", formValues);

    const criarNovaFunc = () => {
      form.mutators.setValue("latitude", 0);
      form.mutators.setValue("longitude", 0);
      form.mutators.setValue("altitude", undefined);
      form.mutators.setValue("hemisferio", hemisferio);
      form.mutators.setValue("hemisferioHorizontal", hemisferioHorizontal);
      form.mutators.setValue("latitude", latitude);
      form.mutators.setValue("longitude", longitude);
      form.mutators.setValue("altitude", altitude);

      form.submit();
    }

    console.log('RES', res)

    if(res.data.dados) {
      const pontoVerificado = res.data.dados;
      const estacaoExistente = pontoVerificado.estacao;

      if(pontoVerificado.pontoIdentico) {
        swal({
          title: 'Estação na mesma coordenada',
          icon: 'info',
          text: `Já existe uma estação cadastrada nesta mesma coordenada - ${
            estacaoExistente.nome
          }${
            estacaoExistente?.apelido ? ` (${estacaoExistente?.apelido})` : ''
          }, deseja usar a estação existente ?`,
          closeOnClickOutside: false,
          buttons: { confirm: 'Utilizar estacão existente', cancel: 'Cancelar'},
        })
        .then(value => {
          if(value)
            this.aoSelecionarEstacaoMapa({ idEstacao: estacaoExistente.id }, undefined);
        });
      }
      else {
        const FATOR_CONVERSAO_METROS = 100000;

        let distanciaConvertida = pontoVerificado.distancia * FATOR_CONVERSAO_METROS;

        let unidadeMedidaDistancia = null;

        if (distanciaConvertida < 1000) {
          unidadeMedidaDistancia = 'm';
        } else {
          distanciaConvertida = distanciaConvertida / 1000;
          unidadeMedidaDistancia = 'km';
        }

        const distanciaArredondada = Math.round(distanciaConvertida);

        swal({
          title: 'Estação próxima',
          icon: 'info',
          text: (
              `Já existe uma estação cadastrada a ${
                distanciaArredondada
            } ${unidadeMedidaDistancia} desta coordenada - ${
              estacaoExistente.nome
            }${
              estacaoExistente?.apelido ? ` (${estacaoExistente?.apelido})` : ''
            }, deseja criar uma nova estação ou usar a estação existente`
          ),
          closeOnClickOutside: false,
          buttons: { confirm: 'Utilizar estacão existente', cancel: 'Criar nova estação'},
        })
        .then(value => {
          if(value)
            this.aoSelecionarEstacaoMapa({ idEstacao: estacaoExistente.id }, undefined);
          else
            criarNovaFunc();
        });
      }
    }
    else
      criarNovaFunc();
  }

  aoGerarPontoEstacao(objectid){
    // Após adicionar o ponto selecionar no mapa
    this.mapaRef.limparSelecaoMapa();
    this.mapaRef.selecionarPontos([
      {
        id:objectid,
        selecionado: true
      }
    ])

  }

  gerarPontoGPS(form) {
    const valores = form.getState().values;

    if (
      TIPO_ENTRADA_COORDS_DECIMAL.id !== valores.tipoEntrada ||
      valores.idDatum !== ID_DATUL_WSG84
    ) {
      converterCoordenadas(
        valores,
        TIPO_ENTRADA_COORDS_DECIMAL.id,
        ID_DATUL_WSG84
      ).then((res) => {
        if (res.sucesso) {
          if (this.mapaRef) {
            this.mapaRef.adicionarPontoEstacao(
              res.dados.resultado.latitude,
              res.dados.resultado.longitude,
              this.props.raioParaPontos,
              "station"
            );
          }
        }
      });
    } else if (this.mapaRef) {
      this.mapaRef.adicionarPontoEstacao(
        valores.latitude ?? 0,
        valores.longitude ?? 0,
        this.props.raioParaPontos,
        "station"
      );
    }
  }

  /*
  gerarPontoGPS(form) {
    const valores = form.getState().values;

    if (this.mapaRef) {
      this.mapaRef.adicionarPontoEstacao(
        valores.latitude ?? 0,
        valores.longitude ?? 0,
        this.props.raioParaPontos,
        "station"
      );
    }
  }
  */

  verificarHistory(form) {
    const { state } = this.history.location
    if (state.pontosGPS) {
      form.mutators.setValue("latitude", state.pontosGPS[0].latitude);
      form.mutators.setValue("longitude", state.pontosGPS[0].longitude);
    }
  }

  onMapaCarregado() {
    this.mapaRenderizado = true;
    // Carregar pontos GPS caso usuário tenha vindo da tela de pontos GPS
    if (
      this.props.pontosGPSpendentes &&
      this.props.pontosGPSpendentes.length > 0
    ) {
      console.log(this.props.pontosGPSpendentes.length, "pontos GPS recebidos");
      this.mapaRef.adicionarPontoEstacaoGeologica(
        this.props.pontosGPSpendentes
      );
    }

    // Filtrar mapa com geometria da área/subárea selecionada na tela inicial
    this.mapaRef.filtrar(
      this.props.idProjeto,
      this.props.geometryFilter,
      this.exibirHistoricas ? true : false,
    );
  }

  render() {
    const { datums } = this.props;

    // Necessário disparar evento novamente no setState de estações pois caso o mapa tenha sido renderizado inicialmente não executa novamente
    // fazendo com que os pontos de GPS não sejam renderizados.
    if(this.mapaRenderizado)
      this.onMapaCarregado();

    return (
      <FForm
        initialValues={this.valoresIniciais}
        onSubmit={this.enviarFormulario}
        subscription={{}}
        decorators={this.decoradores}
        mutators={{ setValue }}
        keepDirtyOnReinitialize={true}
        render={({ form }) => {
          formRef = form;
          return (
            <>
              <Card className="card-mb">
                <Card.Body>
                  <Card.Title>
                    Informe com uma coordenada ou selecione um ponto no mapa
                    <Hint
                      textoAjuda={
                        'Há três modalidades de cadastro: Ponto GPS - ponto criado a partir de um ponto GPS previamente carregado via arquivo; Estação existente - estação criada a partir do ponto de uma estação existente no mapa; ou Coordenada digitada - ponto criado a partir do botão "Gerar ponto" após a escolha de um sistema de coordenada e digitação da mesma.'
                      }
                    />
                  </Card.Title>
                  {/* <Field name={"latitude"} subscription={{ value: true }}>
                    {({ input: { value: latitude } }) => {
                      let tempHemisferio;
                      if (latitude < 0) {
                        tempHemisferio = "SUL"
                      } else {
                        tempHemisferio = "NORTE"
                      }
                      form.mutators.setValue('hemisferio', tempHemisferio)
                      return <></>
                    }}
                  </Field>
                  <Field name={"longitude"} subscription={{ value: true }}>
                    {({ input: { value: longitude } }) => {
                      let tempHemisferioHorizontal;
                      if (longitude < 0) {
                        tempHemisferioHorizontal = "OESTE"
                      } else {
                        tempHemisferioHorizontal = "LESTE"
                      }
                      form.mutators.setValue('hemisferioHorizontal', tempHemisferioHorizontal)
                      return <></>
                    }}
                  </Field> */}
                  <SeletorCoordenadasGeograficas
                    {...this.props}
                    datums={datums}
                    eDefinicaoEstcao={true}
                  />
                  <Row className="my-3">
                    <Col>
                      <FocusableButton
                        className="float-right m-1"
                        onClick={() => this.gerarPontoGPS(form)}
                      >
                        Gerar ponto
                      </FocusableButton>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>

              <Card>
                <Card.Body>
                  <LegendaMapa
                      exibirEstacao={this.exibirLegendaEstacao}
                      exibirEstacaoHistorica={this.exibirLegendaEstacaoHistorica}
                      exibirEstacaoEditada={this.exibirLegendaEstacaoEditada}
                      exibirAtividade={this.exibirLegendaAtividade}
                      exibirPontoGPS={this.exibirLegendaPontoGPS}
                      exibirPontoGerado={this.exibirLegendaPontoGerado}
                      exibirGeometriaProjeto={this.exibirLegendaGeometriaProjeto}
                  />

                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <AppMapComponent
                      ref={this.mapaRefCallback}
                      config={configMapaCoordenadas}
                      onSelecionarEstacao={(params) =>
                        this.aoSelecionarEstacaoMapa(params, form)
                      }
                      onCriarNovaEstacao={(params) =>
                        this.aoCriarNovaEstacao(params, form)
                      }
                      onCriarNovaEstacaoPontoGPS={(params) =>
                        this.aoCriarNovaEstacao(params, form)
                      }
                      onCriarUnicoPonto={(objectid) => this.aoGerarPontoEstacao(objectid)}
                      onCarregarMapa={this.onMapaCarregado}
                      debugMensagens={!!process.env.REACT_APP_MAP_DEBUG}
                      onSelecaoEstacaoMudar={this.onSelecaoEstacaoMudar}
                    />
                  </div>
                  <Row className="my-3">
                    <Col>
                      <FocusableButton
                        className="float-right m-1"
                        onClick={() => this.voltar(form)}
                      >
                        Voltar
                      </FocusableButton>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </>
          );
        }}
      />
    );
  }
}


