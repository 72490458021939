import { PrimaryButton } from "../../../../../../components/Jqx/Button";
import PropTypes from "prop-types";

function BotaoAprovacao({ aoAprovar }) {
    return <PrimaryButton
        className="float-right m-1 ml-2"
        onClick={() => aoAprovar()}
        titulo="Aprovado"
    />
}

BotaoAprovacao.propTypes = {
    aoAprovar: PropTypes.func,
}

export { BotaoAprovacao };
