import React from "react";
import PropTypes from "prop-types";
import ModalBS from "react-bootstrap/Modal"
import { BotaoEnviarReavaliacao } from "../Botoes/BotaoEnviarReavaliacao";
import { Field, Form } from "react-final-form";
import { TextAreaField } from "../../../../../components/field/TextAreaField";
import { setValue } from "../../../../../util/mutadores";
import { Col, Row } from "react-bootstrap";
import "./index.css";
import { comporValidadores } from "../../../../../util/validadores";
import { validaMotivoReavaliacaoVazio } from "../validators/ValidadoresMotivoReavaliacao";
import BlockUi from "react-block-ui";

const MotivoReavaliacaoModal = ({
    aberto,
    aoFechar,
    aoEnviar,
    tamanho = "md" }) => {

    return <BlockUi>
        <ModalBS size={tamanho}
            show={aberto}
            onHide={aoFechar}
            scrollable={true}
            centered
            
        >
        <ModalBS.Header className="modal-motivo-reavaliacao-header-container" closeButton={true}/>
        <ModalBS.Body className="modal-motivo-reavaliacao-body-container">
            <div className="modal-motivo-reavaliacao-body">
                <Form
                    onSubmit={aoEnviar}
                    subscription={{}}
                    mutators={{ setValue }}
                    render={(form) => <>
                        <Row>
                            <Col>
                                <Field
                                    component={TextAreaField}
                                    name="motivoReavaliacao"
                                    label="Motivo de Reavaliação da Atividade"
                                    dica="Preencher informações que impedem a revisão/validação do documento, seja campos faltando ou campos incorretos."
                                    disabled={false}
                                    maxLength={2000}
                                    validate={comporValidadores(validaMotivoReavaliacaoVazio)}
                                />
                            </Col>
                        </Row>
                        <Row className="modal-motivo-reavaliacao-toolbar">
                            <Col>
                                <BotaoEnviarReavaliacao aoEnviar={() => form.form.submit()} />
                            </Col>
                        </Row>
                    </>}
                />
            </div>
        </ModalBS.Body>
    </ModalBS>
    </BlockUi>
}

MotivoReavaliacaoModal.propTypes = {
    aberto: PropTypes.bool,
    aoFechar: PropTypes.func,
    tamanho: PropTypes.oneOf(['sm', 'lg', 'xl']),
}

export { MotivoReavaliacaoModal };
