import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Field, useForm } from "react-final-form";
import { aoEnviarSubForm } from "../../../../util/mutadores";
import { Card } from "react-bootstrap";
import { SubForm } from "../../../../components/SubForm";
import { DropdownListField } from "../../../../components/field/DropdownListField";
import { forcarLimparComboBox, forcarValorCampo } from "../../utils/gerais";
import { useRef } from "react";
import GeraImagemDiagrama from "./GeraImagemDiagrama";
import diaframas_Classificacao_Versao_CPRM_Nov23_1 from '../../../../assets/img/Diagramas/Diaframas_Classificacao_Versao_CPRM_Nov23-a.png';
import diaframas_Classificacao_Versao_CPRM_Nov23_2 from '../../../../assets/img/Diagramas/Diaframas_Classificacao_Versao_CPRM_Nov23-b.png';
import diaframas_Classificacao_Versao_CPRM_Nov23_3 from '../../../../assets/img/Diagramas/Diaframas_Classificacao_Versao_CPRM_Nov23-c.png';
import diaframas_Classificacao_Versao_CPRM_Nov23_4 from '../../../../assets/img/Diagramas/Diaframas_Classificacao_Versao_CPRM_Nov23-d.png';
import diaframas_Classificacao_Versao_CPRM_Nov23_5 from '../../../../assets/img/Diagramas/Diaframas_Classificacao_Versao_CPRM_Nov23-e.png';
import diaframas_Classificacao_Versao_CPRM_Nov23_6 from '../../../../assets/img/Diagramas/Diaframas_Classificacao_Versao_CPRM_Nov23-f.png';
import logo from '../../../../assets/img/Logo_SGB_Cabecalho.png';
import JqxButton from "jqwidgets-scripts/jqwidgets-react-tsx/jqxbuttons";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { campoObrigatorioComMsgGenerica } from "../../../../util/validadores";
import { FORM_ERROR } from "final-form";


const DiagramaClassificacaoPetrografica = ({
    permitirEdicao,
    bbSaturacaoSilica,
    bbTipoDiagrama,
    bbMinerais,
    composicoesMineralogicas,
    permitirInsercao,
    numeroLamina,
    exibirBotaoInsercao,
    valorProjetoAreaSubarea,
    nomeAmostra,
    dataAnalise,
    petrografo,
    excluirDiagrama = () => null,
    esconderDiagrama = () => null,
    disabled
}) => {
    const valorFormulario = useForm()
    const onSubmit = aoEnviarSubForm(valorFormulario);

    const limparCampoTipoDiagrama = () => {
        forcarLimparComboBox('idTipoDiagrama');
    };
    const colunasDiagramaClassificacaoPetrografica = [
        {
            text: 'Saturação em sílica',
            datafield: 'idTipoSaturacaoSilica',
            width: '40%',
            cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
                const idTipoSaturacaoSilica = parseInt(rowdata?.idTipoSaturacaoSilica, 10);
                const objetoFiltrado = bbSaturacaoSilica.find(elemento => elemento.id === idTipoSaturacaoSilica);
                const valor = objetoFiltrado ? objetoFiltrado.nome : '';
                return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${valor}</div>`;
            }
        },
        {
            text: 'Tipo de diagrama',
            datafield: 'idTipoDiagrama',
            width: '40%',
            cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
                const idTipoDiagrama = parseInt(rowdata?.idTipoDiagrama, 10);
                const objetoFiltrado = bbTipoDiagrama.find(elemento => elemento.id === idTipoDiagrama);
                const valor = objetoFiltrado ? objetoFiltrado.nome : '';
                return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${valor}</div>`;
            }
        }
    ]
    const camposDiagramaClassificacaoPetrografica = [
        { name: "idTipoDiagrama", type: "number", map: "idTipoDiagrama" },
        { name: "idTipoSaturacaoSilica", type: "number", map: "idTipoSaturacaoSilica" },
    ];


    function obterNomeMineral(idMineral) {
        const mineral = bbMinerais.find(mineral => mineral.id === idMineral);
        return mineral ? mineral.nome : "Desconhecido";
    }

    const botaoRef1 = useRef(null);
    const botaoRef2 = useRef(null);
    const diagramaRef = useRef(null);

    const toggleDiagrama = () => {

        if (diagramaRef.current) {
            const isHidden = diagramaRef.current.classList.contains('hidden');
            if (isHidden) {
                diagramaRef.current.classList.remove('hidden');
                botaoRef1.current.classList.add('hidden');
                botaoRef2.current.classList.remove('hidden');
            } else {
                diagramaRef.current.classList.add('hidden');
                botaoRef1.current.classList.remove('hidden');
                botaoRef2.current.classList.add('hidden');
            }
        }
    };

    const gerarDiagramaPDF = (
        divDiagramaRef,
        nomeDiagrama,
        valorProjetoAreaSubarea,
        nomeAmostra,
        dataAnalise,
        petrografo,
        lamina,
        primeiroSelect,
        segundoSelect,
        terceiroSelect,
        valorPrimeiroSelect,
        valorSegundoSelect,
        valorTerceiroSelect,
    ) => {
        html2canvas(divDiagramaRef.current).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF();

            // Adicionar a imagem do cabeçalho
            pdf.addImage(logo, 'PNG', 10, 10, 160, 25);

            const projetoAreaSubarea = "Projeto/área/subárea:";
            const titulo1 = "Nome da amostra:";
            const titulo3 = "Data da análise:";
            const titulo2 = "Petrógrafo:";
            const titulo4 = "Lâmina:";

            let yPosition = 40; // Posição Y inicial
            const maxWidth = 140; // Largura máxima permitida para o texto normal
            const lineHeight = 5; // Altura da linha
            const fontSize = 12; // Fonte inicial do texto

            pdf.setFont("helvetica", "bold"); // Definindo a fonte como negrito
            pdf.setFontSize(fontSize); // Defini fonte do texto
            const textNegritoWidth = pdf.getStringUnitWidth(projetoAreaSubarea) * fontSize / pdf.internal.scaleFactor; // Calculando a largura do texto em negrito
            pdf.text(projetoAreaSubarea, 10, yPosition); // Adicionando o texto em negrito

            pdf.setFont("helvetica", "normal"); // Definindo a fonte como normal
            let textLines = pdf.splitTextToSize(valorProjetoAreaSubarea, maxWidth); // Quebrando o texto em várias linhas
            const textNormalX = 10 + textNegritoWidth + 5; // Calculando a posição X para o texto normal

            let currentY = yPosition; // Inicializar a posição Y atual

            textLines.forEach(line => {
                pdf.text(line, textNormalX, currentY);
                currentY += lineHeight; // Ajustar a posição Y para a próxima linha
            });

            yPosition = yPosition + 10; // Ajusta posição do y para proxima linha
            pdf.setFont("helvetica", "bold"); // Definindo a fonte como negrito

            // Adicionar os títulos e textos na primeira coluna
            pdf.text(titulo1, 10, yPosition);
            pdf.setFont("helvetica", "normal");
            pdf.text(nomeAmostra, 49, yPosition);
            yPosition += 5; // Ajustar a posição Y para o próximo título e texto
            pdf.setFont("helvetica", "bold");
            pdf.text(titulo2, 10, yPosition);


            pdf.setFont("helvetica", "normal");
            const textLines2 = pdf.splitTextToSize(petrografo, 90);
            textLines2.forEach((line, index) => {
                pdf.text(line, 35, yPosition + (index * 5));
            });

            yPosition = 50; // Ajusta pocição do y para proxima linha
            const xPositionSecondColumn = 128; // Definir a posição X para a segunda coluna
            pdf.setFont("helvetica", "bold");
            pdf.text(titulo3, xPositionSecondColumn, yPosition);
            pdf.setFont("helvetica", "normal");
            pdf.text(dataAnalise, xPositionSecondColumn + 35, yPosition);
            yPosition += 5; // Ajustar a posição Y para o próximo título e texto
            pdf.setFont("helvetica", "bold");
            pdf.text(titulo4, xPositionSecondColumn, yPosition);
            pdf.setFont("helvetica", "normal");
            pdf.text(lamina, xPositionSecondColumn + 19, yPosition);

            // Obter as dimensões da div que contem o diagrama com o ponto plotado
            const divWidth = divDiagramaRef.current.offsetWidth;
            const divHeight = divDiagramaRef.current.offsetHeight;

            // Definir as dimensões do PDF
            const pdfWidth = pdf.internal.pageSize.getWidth();

            // Calcular a altura da imagem mantendo a proporção
            const imgHeight = divHeight * (pdfWidth / divWidth);

            // Adicionar a imagem ao PDF com a largura máxima e altura proporcional
            pdf.addImage(imgData, 'PNG', 20, 60, pdfWidth - 38, imgHeight - 38);

            // Calcular a posição vertical onde a imagem termina
            const imgEndPosition = 24 + imgHeight;
            pdf.setFont("helvetica", "bold"); // Altera a fonte para negrito em titulos
            pdf.setFontSize(8);

            const textY = imgEndPosition + 1; // Posição Y: logo abaixo da imagem
            const textLinesY = imgEndPosition + 5; // Posição Y: logo abaixo da imagem
            pdf.text(primeiroSelect, 10, textY);
            pdf.text(segundoSelect, 70, textY);
            pdf.text(terceiroSelect, 140, textY);

            // Quebrar os textos em várias linhas caso ultrapassem o tamanho de 55 caracteres
            const maxWidthTabela = 60; // Tamanho máximo de caracteres por linha

            const textLines5 = pdf.splitTextToSize(valorPrimeiroSelect, maxWidthTabela);
            const textLines6 = pdf.splitTextToSize(valorSegundoSelect, maxWidthTabela);
            const textLines7 = pdf.splitTextToSize(valorTerceiroSelect, maxWidthTabela);

            // Adicionar os textos quebrados ao PDF na mesma posição X
            pdf.setFont("helvetica", "normal");
            textLines5.forEach((line, index) => {
                pdf.text(line, 10, textLinesY + (index * 5));
            });
            textLines6.forEach((line, index) => {
                pdf.text(line, 70, textLinesY + (index * 5));
            });
            textLines7.forEach((line, index) => {
                pdf.text(line, 140, textLinesY + (index * 5));
            });

            pdf.save(`${nomeDiagrama}.pdf`);
        });
    };

    const validaAoMenosUmPreenchido = (valores) => {
        if (!valores?.idsComposicoesMineraligicasMineralTres && !valores?.idsComposicoesMineraligicasMineralDois && !valores?.idsComposicoesMineraligicasMineralUm) {
            return { [FORM_ERROR]: 'Ao menos uma % deve ser selecionada.' };
        }

        if ((!valores?.idsComposicoesMineraligicasMineralUm || valores.idsComposicoesMineraligicasMineralUm.length === 0) &&
            (!valores?.idsComposicoesMineraligicasMineralDois || valores.idsComposicoesMineraligicasMineralDois.length === 0) &&
            (!valores?.idsComposicoesMineraligicasMineralTres || valores.idsComposicoesMineraligicasMineralTres.length === 0)) {
            return { [FORM_ERROR]: 'Ao menos uma % deve ser selecionada.' };
        }

        return null;
    };

    return (
        <>
            <div style={{ marginTop: '2.5rem' }}>
                <Card className="mt-3">
                    <Card.Body>
                        <Card.Title>Diagrama de classificação petrográfica</Card.Title>
                        <Field name={'diagramasClassificacaoPetrografica'} subscription={{ value: true }}>
                            {({ input: { value: diagramasClassificacaoPetrografica = [] } }) => (
                                <SubForm
                                    nome={'diagramasClassificacaoPetrografica'}
                                    onSubmit={onSubmit}
                                    colunas={colunasDiagramaClassificacaoPetrografica}
                                    elementos={diagramasClassificacaoPetrografica}
                                    campos={camposDiagramaClassificacaoPetrografica}
                                    podeExcluirEntidade={!disabled}
                                    permitirInsercao={permitirInsercao}
                                    exibirBotaoInsercao={!(disabled || exibirBotaoInsercao)}
                                    permitirEdicao={!disabled}
                                    permitirExclusao={!disabled}
                                    permitirVisualizacao={disabled}
                                    validar={(valores) => validaAoMenosUmPreenchido(valores)}
                                    onOpen={() => toggleDiagrama()}
                                    renderForm={({ formProps: { form } }) => {

                                        let bbTipoDiagramaFiltrado = [];
                                        // Inverte a biblioteca para maior comodidade
                                        let bbSaturacaoSilicaInvertida = bbSaturacaoSilica.reverse();

                                        const idSaturacaoSilicaSelecionado = (form.getState().values?.idTipoSaturacaoSilica);

                                        let lista1 = [];
                                        let lista2 = [];
                                        let lista3 = [];

                                        let primeiraCombinacao = false;
                                        let segundaCombinacao = false;
                                        let terceiraCombinacao = false;
                                        let quartaCombinacao = false;
                                        let sextaCombinacao = false;
                                        let setimaCombinacao = false;

                                        let podeGerarDiagrama = false;

                                        let valorXDiagrama;
                                        let valorYDiagrama;

                                        let configuracaoDiagrama = {};

                                        let nomeDiagrama = `Diagrama_Petrografia_${numeroLamina}`;

                                        let primeiroSelect = "";
                                        let segundoSelect = "";
                                        let terceiroSelect = "";
                                        let valorPrimeiroSelect = "";
                                        let valorSegundoSelect = "";
                                        let valorTerceiroSelect = "";

                                        if (idSaturacaoSilicaSelecionado) {
                                            const idTipoDiagramaSelecionado = (form.getState().values?.idTipoDiagrama);

                                            switch (idSaturacaoSilicaSelecionado) {
                                                case 1:
                                                case 2:
                                                    bbTipoDiagramaFiltrado = bbTipoDiagrama.filter(objeto => objeto.id === 1 || objeto.id === 2);
                                                    break;
                                                case 3:
                                                    bbTipoDiagramaFiltrado = bbTipoDiagrama.filter(objeto => objeto.id === 3 || objeto.id === 4 || objeto.id === 5);
                                                    break;
                                                case 4:
                                                    bbTipoDiagramaFiltrado = bbTipoDiagrama.filter(objeto => objeto.id === 6 || objeto.id === 7);
                                                    break;
                                                default:
                                                    bbTipoDiagramaFiltrado = []
                                            }

                                            let lista = [];
                                            if (composicoesMineralogicas && composicoesMineralogicas.length > 0) {
                                                lista = composicoesMineralogicas
                                                    .filter(item => item.numeroPercentual && item.numeroPercentual !== 0)
                                                    .map(composicao => {
                                                        const idMineral = composicao.idMineral;

                                                        const nomeMineral = obterNomeMineral(idMineral);
                                                        const nome = `${nomeMineral} (${composicao.numeroPercentual}%)`;
                                                        return {
                                                            id: idMineral,
                                                            nome: nome,
                                                            numeroPercentual: composicao.numeroPercentual
                                                        };
                                                    })
                                                    .sort((a, b) => b.numeroPercentual - a.numeroPercentual);
                                            }

                                            let idsComposicoesMineraligicasMineralUm = [];
                                            let idsComposicoesMineraligicasMineralDois = [];
                                            let idsComposicoesMineraligicasMineralTres = [];

                                            if (form.getState().values?.idsComposicoesMineraligicasMineralUm !== undefined) {
                                                idsComposicoesMineraligicasMineralUm = form.getState().values?.idsComposicoesMineraligicasMineralUm;
                                            }
                                            if (form.getState().values?.idsComposicoesMineraligicasMineralDois !== undefined) {
                                                idsComposicoesMineraligicasMineralDois = form.getState().values?.idsComposicoesMineraligicasMineralDois;
                                            }
                                            if (form.getState().values?.idsComposicoesMineraligicasMineralTres !== undefined) {
                                                idsComposicoesMineraligicasMineralTres = form.getState().values?.idsComposicoesMineraligicasMineralTres;
                                            }

                                            let removerMineraisLista1 = idsComposicoesMineraligicasMineralDois.concat(idsComposicoesMineraligicasMineralTres);
                                            let removerMineraisLista2 = idsComposicoesMineraligicasMineralUm.concat(idsComposicoesMineraligicasMineralTres);
                                            let removerMineraisLista3 = idsComposicoesMineraligicasMineralUm.concat(idsComposicoesMineraligicasMineralDois);

                                            lista1 = lista.filter(item => !removerMineraisLista1.includes(item.id))
                                            lista2 = lista.filter(item => !removerMineraisLista2.includes(item.id))
                                            lista3 = lista.filter(item => !removerMineraisLista3.includes(item.id))

                                            primeiraCombinacao = false;
                                            segundaCombinacao = false;
                                            terceiraCombinacao = false;
                                            quartaCombinacao = false;
                                            sextaCombinacao = false;
                                            setimaCombinacao = false;

                                            let tornaYNegativo = 1;
                                            if (idSaturacaoSilicaSelecionado === 1 && (idTipoDiagramaSelecionado === 1 || idTipoDiagramaSelecionado === 2)) {
                                                primeiraCombinacao = true;
                                                primeiroSelect = "% de Quartzo na amostra";
                                                segundoSelect = "% de Feldspato Alcalino na amostra";
                                                terceiroSelect = "% de Plagioclásio na amostra";

                                            }

                                            if (idSaturacaoSilicaSelecionado === 2 && (idTipoDiagramaSelecionado === 1 || idTipoDiagramaSelecionado === 2)) {
                                                segundaCombinacao = true;
                                                primeiroSelect = "% de Feldspatóides na amostra";
                                                segundoSelect = "% de Feldspato Alcalino na amostra";
                                                terceiroSelect = "% de Plagioclásio na amostra";
                                                tornaYNegativo = -1;
                                            }

                                            if (idSaturacaoSilicaSelecionado === 3 && idTipoDiagramaSelecionado === 3) {
                                                terceiraCombinacao = true;
                                                primeiroSelect = "% de Plagioclásio na amostra";
                                                segundoSelect = "% de Piroxênio na amostra";
                                                terceiroSelect = "% de Olivina na amostra";
                                            }

                                            if (idSaturacaoSilicaSelecionado === 3 && idTipoDiagramaSelecionado === 4) {
                                                quartaCombinacao = true;
                                                primeiroSelect = "% de Plagioclásio na amostra";
                                                segundoSelect = "% de Piroxênio na amostra";
                                                terceiroSelect = "% de Hornblenda na amostra";
                                            }

                                            if (idSaturacaoSilicaSelecionado === 4 && idTipoDiagramaSelecionado === 6) {
                                                sextaCombinacao = true;
                                                primeiroSelect = "% de Olivina na amostra";
                                                segundoSelect = "% de Ortopiroxênio na amostra";
                                                terceiroSelect = "% de Clinopiroxênio na amostra";
                                            }

                                            if (idSaturacaoSilicaSelecionado === 4 && idTipoDiagramaSelecionado === 7) {
                                                setimaCombinacao = true;
                                                primeiroSelect = "% de Olivina na amostra";
                                                segundoSelect = "% de Piroxênio na amostra";
                                                terceiroSelect = "% de Hornblenda na amostra";
                                            }

                                            // Pega valores das composicoes mineralogicas com porcentagem e separa por virgula
                                            // para apresentar no pdf do diagrama
                                            valorPrimeiroSelect = lista
                                                .filter(item => idsComposicoesMineraligicasMineralUm.includes(item.id))
                                                .map(item => item.nome);

                                            valorPrimeiroSelect = valorPrimeiroSelect.join(', ');

                                            valorSegundoSelect = lista
                                                .filter(item => idsComposicoesMineraligicasMineralDois.includes(item.id))
                                                .map(item => item.nome);

                                            valorSegundoSelect = valorSegundoSelect.join(', ');

                                            valorTerceiroSelect = lista
                                                .filter(item => idsComposicoesMineraligicasMineralTres.includes(item.id))
                                                .map(item => item.nome);

                                            valorTerceiroSelect = valorTerceiroSelect.join(', ');

                                            let somaMineralA = 0;
                                            let somaMineralP = 0;
                                            let somaMineralQ = 0;

                                            if (composicoesMineralogicas && composicoesMineralogicas.length > 0) {
                                                const composicoesMinerais1 = composicoesMineralogicas?.filter(composicao => idsComposicoesMineraligicasMineralUm.includes(composicao.idMineral));
                                                somaMineralQ = composicoesMinerais1.reduce((total, composicao) => {
                                                    if (composicao.numeroPercentual && composicao.numeroPercentual !== 0) {
                                                        return total + composicao.numeroPercentual;
                                                    }
                                                    return total;
                                                }, 0);

                                                const composicoesMinerais2 = composicoesMineralogicas?.filter(composicao => idsComposicoesMineraligicasMineralDois.includes(composicao.idMineral));
                                                somaMineralA = composicoesMinerais2.reduce((total, composicao) => {
                                                    if (composicao.numeroPercentual && composicao.numeroPercentual !== 0) {
                                                        return total + composicao.numeroPercentual;
                                                    }
                                                    return total;
                                                }, 0);

                                                const composicoesMinerais3 = composicoesMineralogicas?.filter(composicao => idsComposicoesMineraligicasMineralTres.includes(composicao.idMineral));
                                                somaMineralP = composicoesMinerais3.reduce((total, composicao) => {
                                                    if (composicao.numeroPercentual && composicao.numeroPercentual !== 0) {
                                                        return total + composicao.numeroPercentual;
                                                    }
                                                    return total;
                                                }, 0);
                                            }

                                            let somaTotal = somaMineralA + somaMineralP + somaMineralQ;

                                            let vnP = (somaMineralP * 100) / somaTotal;
                                            let vnQ = (somaMineralQ * 100) / somaTotal;

                                            valorXDiagrama = 0.5 * vnQ + vnP;
                                            valorYDiagrama = vnQ * 0.87;

                                            podeGerarDiagrama = somaTotal !== 0;

                                            configuracaoDiagrama = {
                                                imagePath: diaframas_Classificacao_Versao_CPRM_Nov23_5,
                                                widthImagem: 900,
                                                heightImagem: 518,
                                                marginTopGrafico: 85,
                                                marginLeftGrafico: 216,
                                                widthGrafico: 461,
                                                heightGrafico: 400,
                                                yMinimo: -2,
                                                yMaximo: 89,
                                            };

                                            switch (idTipoDiagramaSelecionado) {
                                                case 1:
                                                    configuracaoDiagrama = {
                                                        imagePath: diaframas_Classificacao_Versao_CPRM_Nov23_2,
                                                        widthImagem: 748,
                                                        heightImagem: 869,
                                                        marginTopGrafico: 85,
                                                        marginLeftGrafico: 72,
                                                        widthGrafico: 459,
                                                        heightGrafico: 775,
                                                        yMinimo: -89,
                                                        yMaximo: 89
                                                    };
                                                    valorYDiagrama = valorYDiagrama * tornaYNegativo;
                                                    break;
                                                case 2:
                                                    configuracaoDiagrama = {
                                                        imagePath: diaframas_Classificacao_Versao_CPRM_Nov23_1,
                                                        widthImagem: 748,
                                                        heightImagem: 869,
                                                        marginTopGrafico: 85,
                                                        marginLeftGrafico: 72,
                                                        widthGrafico: 459,
                                                        heightGrafico: 775,
                                                        yMinimo: -89,
                                                        yMaximo: 89
                                                    };
                                                    valorYDiagrama = valorYDiagrama * tornaYNegativo;
                                                    break;
                                                case 3:
                                                    configuracaoDiagrama = {
                                                        imagePath: diaframas_Classificacao_Versao_CPRM_Nov23_3,
                                                        widthImagem: 783,
                                                        heightImagem: 693,
                                                        marginTopGrafico: 90,
                                                        marginLeftGrafico: 90,
                                                        widthGrafico: 458,
                                                        heightGrafico: 401,
                                                        yMinimo: -2,
                                                        yMaximo: 89
                                                    };
                                                    break;
                                                case 4:
                                                    configuracaoDiagrama = {
                                                        imagePath: diaframas_Classificacao_Versao_CPRM_Nov23_4,
                                                        widthImagem: 783,
                                                        heightImagem: 693,
                                                        marginTopGrafico: 90,
                                                        marginLeftGrafico: 229,
                                                        widthGrafico: 458,
                                                        heightGrafico: 401,
                                                        yMinimo: -2,
                                                        yMaximo: 89
                                                    };
                                                    break;
                                                case 6:
                                                    configuracaoDiagrama = {
                                                        imagePath: diaframas_Classificacao_Versao_CPRM_Nov23_5,
                                                        widthImagem: 900,
                                                        heightImagem: 518,
                                                        marginTopGrafico: 85,
                                                        marginLeftGrafico: 216,
                                                        widthGrafico: 461,
                                                        heightGrafico: 400,
                                                        yMinimo: -2,
                                                        yMaximo: 89,
                                                    };
                                                    break;
                                                case 7:
                                                    configuracaoDiagrama = {
                                                        imagePath: diaframas_Classificacao_Versao_CPRM_Nov23_6,
                                                        widthImagem: 900,
                                                        heightImagem: 518,
                                                        marginTopGrafico: 85,
                                                        marginLeftGrafico: 216,
                                                        widthGrafico: 461,
                                                        heightGrafico: 400,
                                                        yMinimo: -2,
                                                        yMaximo: 89,
                                                    };
                                                    break;
                                                default:
                                                    podeGerarDiagrama = false;
                                            }
                                        } else {
                                            form.mutators.setValue('idTipoDiagrama', undefined);
                                            form.mutators.setValue('idsComposicoesMineraligicasMineralUm', undefined);
                                            form.mutators.setValue('idsComposicoesMineraligicasMineralDois', undefined);
                                            form.mutators.setValue('idsComposicoesMineraligicasMineralTres', undefined);
                                        }

                                        return (
                                            <>
                                                <Row className="my-3">
                                                    <Col md={4}>
                                                        <Field name={'idTipoSaturacaoSilica'} subscription={{ value: true }}>
                                                            {({ input: { value: idTipoSaturacaoSilica = [] } }) => {

                                                                if (idTipoSaturacaoSilica === "" || idTipoSaturacaoSilica === undefined || idTipoSaturacaoSilica === null) {
                                                                    forcarValorCampo('idTipoSaturacaoSilica', "Selecione...")
                                                                } else {
                                                                    let saturacao = bbSaturacaoSilicaInvertida.find(item => item.id === idTipoSaturacaoSilica);
                                                                    forcarValorCampo('idTipoSaturacaoSilica', saturacao?.nome)
                                                                }
                                                                return (
                                                                    <Field
                                                                        component={DropdownListField}
                                                                        name={"idTipoSaturacaoSilica"}
                                                                        label="Saturação em sílica"
                                                                        dica="Definir o grau de saturação em sílica correspondente com a assembléia mineral observada."
                                                                        elementos={bbSaturacaoSilicaInvertida}
                                                                        displayMember={'nome'}
                                                                        disabled={disabled}
                                                                        onChange={limparCampoTipoDiagrama}
                                                                        customClass={'idTipoSaturacaoSilica'}
                                                                        required
                                                                        validate={campoObrigatorioComMsgGenerica("Saturação em sílica")}
                                                                    />
                                                                )
                                                            }}</Field>

                                                    </Col>
                                                    <Col md={4}>
                                                        <Field name={'idTipoDiagrama'} subscription={{ value: true }}>
                                                            {({ input: { value: idTipoDiagrama = [] } }) => {

                                                                if (idTipoDiagrama === "" || idTipoDiagrama === undefined || idTipoDiagrama === null) {
                                                                    forcarValorCampo('idTipoDiagrama', "Selecione...")
                                                                } else {
                                                                    let diagrama = bbTipoDiagramaFiltrado.find(item => item.id === idTipoDiagrama);
                                                                    forcarValorCampo('idTipoDiagrama', diagrama?.nome)
                                                                }
                                                                return (
                                                                    <Field
                                                                        component={DropdownListField}
                                                                        name={"idTipoDiagrama"}
                                                                        label="Tipo de diagrama"
                                                                        dica="Definir o diagrama a ser utilizado de acordo com a origem da rocha (vulcânica ou plutônica e da assembléia mineral observada ."
                                                                        elementos={bbTipoDiagramaFiltrado}
                                                                        displayMember={'nome'}
                                                                        disabled={disabled}
                                                                        customClass={'idTipoDiagrama'}
                                                                        required
                                                                        validate={campoObrigatorioComMsgGenerica("Tipo de diagrama")}
                                                                    />
                                                                )
                                                            }}
                                                        </Field>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    {primeiraCombinacao && (
                                                        <>
                                                            <Col md={4}>
                                                                <Field
                                                                    component={DropdownListField}
                                                                    name={"idsComposicoesMineraligicasMineralUm"}
                                                                    label="% de Quartzo na amostra"
                                                                    elementos={lista1}
                                                                    displayMember={'nome'}
                                                                    disabled={disabled}
                                                                    checkboxes={true}
                                                                    multiple
                                                                />
                                                            </Col>
                                                            <Col md={4}>
                                                                <Field
                                                                    component={DropdownListField}
                                                                    name={"idsComposicoesMineraligicasMineralDois"}
                                                                    label="% de Feldspato Alcalino na amostra"
                                                                    elementos={lista2}
                                                                    displayMember={'nome'}
                                                                    disabled={disabled}
                                                                    checkboxes={true}
                                                                    multiple
                                                                />
                                                            </Col>
                                                            <Col md={4}>
                                                                <Field
                                                                    component={DropdownListField}
                                                                    name={"idsComposicoesMineraligicasMineralTres"}
                                                                    label="% de Plagioclásio na amostra"
                                                                    elementos={lista3}
                                                                    displayMember={'nome'}
                                                                    disabled={disabled}
                                                                    checkboxes={true}
                                                                    multiple
                                                                />
                                                            </Col>
                                                        </>
                                                    )}
                                                    {segundaCombinacao && (
                                                        <>
                                                            <Col md={4}>
                                                                <Field
                                                                    component={DropdownListField}
                                                                    name={"idsComposicoesMineraligicasMineralUm"}
                                                                    label="% de Feldspatóides na amostra"
                                                                    elementos={lista1}
                                                                    displayMember={'nome'}
                                                                    disabled={disabled}
                                                                    checkboxes={true}
                                                                    multiple
                                                                />
                                                            </Col>
                                                            <Col md={4}>
                                                                <Field
                                                                    component={DropdownListField}
                                                                    name={"idsComposicoesMineraligicasMineralDois"}
                                                                    label="% de Feldspato Alcalino na amostra"
                                                                    elementos={lista2}
                                                                    displayMember={'nome'}
                                                                    disabled={disabled}
                                                                    checkboxes={true}
                                                                    multiple
                                                                />
                                                            </Col>
                                                            <Col md={4}>
                                                                <Field
                                                                    component={DropdownListField}
                                                                    name={"idsComposicoesMineraligicasMineralTres"}
                                                                    label="% de Plagioclásio na amostra"
                                                                    elementos={lista3}
                                                                    displayMember={'nome'}
                                                                    disabled={disabled}
                                                                    checkboxes={true}
                                                                    multiple
                                                                />
                                                            </Col>
                                                        </>
                                                    )}
                                                    {terceiraCombinacao && (
                                                        <>
                                                            <Col md={4}>
                                                                <Field
                                                                    component={DropdownListField}
                                                                    name={"idsComposicoesMineraligicasMineralUm"}
                                                                    label="% de Plagioclásio na amostra"
                                                                    elementos={lista1}
                                                                    displayMember={'nome'}
                                                                    disabled={disabled}
                                                                    checkboxes={true}
                                                                    multiple
                                                                />
                                                            </Col>
                                                            <Col md={4}>
                                                                <Field
                                                                    component={DropdownListField}
                                                                    name={"idsComposicoesMineraligicasMineralDois"}
                                                                    label="% de Piroxênio na amostra"
                                                                    elementos={lista2}
                                                                    displayMember={'nome'}
                                                                    disabled={disabled}
                                                                    checkboxes={true}
                                                                    multiple
                                                                />
                                                            </Col>
                                                            <Col md={4}>
                                                                <Field
                                                                    component={DropdownListField}
                                                                    name={"idsComposicoesMineraligicasMineralTres"}
                                                                    label="% de Olivina na amostra"
                                                                    elementos={lista3}
                                                                    displayMember={'nome'}
                                                                    disabled={disabled}
                                                                    checkboxes={true}
                                                                    multiple
                                                                />
                                                            </Col>
                                                        </>
                                                    )}
                                                    {quartaCombinacao && (
                                                        <>
                                                            <Col md={4}>
                                                                <Field
                                                                    component={DropdownListField}
                                                                    name={"idsComposicoesMineraligicasMineralUm"}
                                                                    label="% de Plagioclásio na amostra"
                                                                    elementos={lista1}
                                                                    displayMember={'nome'}
                                                                    disabled={disabled}
                                                                    checkboxes={true}
                                                                    multiple
                                                                />
                                                            </Col>
                                                            <Col md={4}>
                                                                <Field
                                                                    component={DropdownListField}
                                                                    name={"idsComposicoesMineraligicasMineralDois"}
                                                                    label="% de Piroxênio na amostra"
                                                                    elementos={lista2}
                                                                    displayMember={'nome'}
                                                                    disabled={disabled}
                                                                    checkboxes={true}
                                                                    multiple
                                                                />
                                                            </Col>
                                                            <Col md={4}>
                                                                <Field
                                                                    component={DropdownListField}
                                                                    name={"idsComposicoesMineraligicasMineralTres"}
                                                                    label="% de Hornblenda na amostra"
                                                                    elementos={lista3}
                                                                    displayMember={'nome'}
                                                                    disabled={disabled}
                                                                    checkboxes={true}
                                                                    multiple
                                                                />
                                                            </Col>
                                                        </>
                                                    )}
                                                    {sextaCombinacao && (
                                                        <>
                                                            <Col md={4}>
                                                                <Field
                                                                    component={DropdownListField}
                                                                    name={"idsComposicoesMineraligicasMineralUm"}
                                                                    label="% de Olivina na amostra"
                                                                    elementos={lista1}
                                                                    displayMember={'nome'}
                                                                    disabled={disabled}
                                                                    checkboxes={true}
                                                                    multiple
                                                                />
                                                            </Col>
                                                            <Col md={4}>
                                                                <Field
                                                                    component={DropdownListField}
                                                                    name={"idsComposicoesMineraligicasMineralDois"}
                                                                    label="% de Ortopiroxênio na amostra"
                                                                    elementos={lista2}
                                                                    displayMember={'nome'}
                                                                    disabled={disabled}
                                                                    checkboxes={true}
                                                                    multiple
                                                                />
                                                            </Col>
                                                            <Col md={4}>
                                                                <Field
                                                                    component={DropdownListField}
                                                                    name={"idsComposicoesMineraligicasMineralTres"}
                                                                    label="% de Clinopiroxênio na amostra"
                                                                    elementos={lista3}
                                                                    displayMember={'nome'}
                                                                    disabled={disabled}
                                                                    checkboxes={true}
                                                                    multiple
                                                                />
                                                            </Col>
                                                        </>
                                                    )}
                                                    {setimaCombinacao && (
                                                        <>
                                                            <Col md={4}>
                                                                <Field
                                                                    component={DropdownListField}
                                                                    name={"idsComposicoesMineraligicasMineralUm"}
                                                                    label="% de Olivina na amostra"
                                                                    elementos={lista1}
                                                                    displayMember={'nome'}
                                                                    disabled={disabled}
                                                                    checkboxes={true}
                                                                    multiple
                                                                />
                                                            </Col>
                                                            <Col md={4}>
                                                                <Field
                                                                    component={DropdownListField}
                                                                    name={"idsComposicoesMineraligicasMineralDois"}
                                                                    label="% de Piroxênio na amostra"
                                                                    elementos={lista2}
                                                                    displayMember={'nome'}
                                                                    disabled={disabled}
                                                                    checkboxes={true}
                                                                    multiple
                                                                />
                                                            </Col>
                                                            <Col md={4}>
                                                                <Field
                                                                    component={DropdownListField}
                                                                    name={"idsComposicoesMineraligicasMineralTres"}
                                                                    label="% de Hornblenda na amostra"
                                                                    elementos={lista3}
                                                                    displayMember={'nome'}
                                                                    disabled={disabled}
                                                                    checkboxes={true}
                                                                    multiple
                                                                />
                                                            </Col>
                                                        </>
                                                    )}
                                                </Row>
                                                <div>
                                                    {podeGerarDiagrama && (
                                                        <>
                                                            <div className="container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                <div ref={diagramaRef} className="diagrama hidden">
                                                                    <GeraImagemDiagrama
                                                                        configuracaoDiagrama={configuracaoDiagrama}
                                                                        ponto={[{ x: valorXDiagrama, y: valorYDiagrama }]}
                                                                        mostrarGrid={false}
                                                                        nomeDiagrama={nomeDiagrama}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div style={{
                                                                display: 'flex',
                                                                justifyContent: 'flex-end',
                                                                marginTop: '1rem',
                                                                marginRight: '7px'
                                                            }}>
                                                                <div ref={botaoRef2} className="diagrama hidden" style={{ marginLeft: '5px' }}>
                                                                    <JqxButton className={'br-button primary small'} onClick={() =>
                                                                        gerarDiagramaPDF(
                                                                            diagramaRef,
                                                                            nomeDiagrama,
                                                                            valorProjetoAreaSubarea,
                                                                            nomeAmostra,
                                                                            dataAnalise,
                                                                            petrografo,
                                                                            numeroLamina,
                                                                            primeiroSelect,
                                                                            segundoSelect,
                                                                            terceiroSelect,
                                                                            valorPrimeiroSelect,
                                                                            valorSegundoSelect,
                                                                            valorTerceiroSelect
                                                                        )} style={{ fontSize: 13, marginRight: '15px' }}>Gerar PDF do diagrama</JqxButton>
                                                                    <JqxButton
                                                                        className={'br-button primary small'}
                                                                        disabled={disabled}
                                                                        onClick={() => {
                                                                            esconderDiagrama();
                                                                            excluirDiagrama();
                                                                        }}
                                                                    >
                                                                        Excluir diagrama
                                                                    </JqxButton>
                                                                </div>
                                                            </div>

                                                            <div ref={botaoRef1} className="diagrama" style={{
                                                                textAlign: 'right',
                                                                justifyContent: 'flex-end',
                                                                marginTop: '1rem',
                                                                marginRight: '5px'
                                                            }}>
                                                                <JqxButton className={'br-button primary small'} onClick={toggleDiagrama}>Gerar diagrama</JqxButton>
                                                            </div>

                                                        </>
                                                    )}
                                                </div>
                                            </>
                                        )
                                    }
                                    }
                                />
                            )}
                        </Field>
                    </Card.Body >
                </Card>
            </div>
        </>
    );
};

export default DiagramaClassificacaoPetrografica;