import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Field, useForm } from "react-final-form";
import { aoEnviarSubForm } from "../../../../../util/mutadores";
import Card from "../../../../../components/card";
import { SubForm } from "../../../../../components/SubForm";
import { DropdownListField } from "../../../../../components/field/DropdownListField";
import { getPropsPermissao, resolverExibidos } from "../../../../../components/SubForm/SubForm";
import { campoObrigatorioComMsgGenerica } from "../../../../../util/validadores";
import { NumberInputField } from "../../../../../components/field/NumberInputField";
import { FORM_ERROR } from "final-form";

const FasesMinerais = ({
    permitirEdicao,
    bbMinerais,
    disabled,
    somaTotalPercentuais,
    form,    
}) => {

    const onSubmit = aoEnviarSubForm(form);

    const colunasFragmentosMinerais = [
        {
            text: 'Fragmentos minerais',
            datafield: 'idMineral',
            width: '40%',
            cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
                const objetoFiltrado = bbMinerais.find(elemento => elemento.id === value);
                const valor = objetoFiltrado ? objetoFiltrado.nome : '';
                return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${valor}</div>`;
            }
        },
        {
            text: '% na fração',
            datafield: 'porcentagemCimento',
            width: '19%',
            cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {

                let valor = "";
                if (value === 0 || value === "0") {
                    valor = "Acessório";
                } else {
                    valor = value;
                }
                return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${valor}</div>`;
            }
        },
    ]
    const camposFragmentosMinerais = [
        { name: "idMineral", type: "number", map: "idMineral" },
        { name: "porcentagemCimento", type: "number", map: "porcentagemCimento" },
    ];

    const validarFasesMinerais = (valores) => {

        const fragmentosMinerais = form.getState().values?.fasesMinerais;
        const itens = fragmentosMinerais ? resolverExibidos(fragmentosMinerais) : [];
        const idParaSubstituir = valores.id;
        const indiceParaSubstituir = itens.findIndex(item => item.id === idParaSubstituir);

        if (indiceParaSubstituir !== -1) {
            itens.splice(indiceParaSubstituir, 1, valores);
        } else {
            itens.push(valores);
        }

        let totalPorcentagem = 0;

        if (itens.length > 0) {
            totalPorcentagem = itens.reduce((total, objeto) => {
                return total + Number(objeto.porcentagemCimento);
            }, 0);
        }

        if (totalPorcentagem > 100) {
            return { [FORM_ERROR]: 'A soma da % na fração não deve ultrapassar 100%.' };
        }       

        return null
    }

    const valorFormulario = useForm()
    return (
        <>
            <div style={{ marginTop: '2.5rem', marginBottom: "1rem" }}>
                <Card className="mt-3">
                    <Card.Body>

                        {(somaTotalPercentuais != null && somaTotalPercentuais < 100) && (
                            <span className="erro-campo" style={{ color: 'blue', fontSize: '10px' }}>
                                A soma total dos percentuais das Fases Minerais é de: {somaTotalPercentuais}%, mas deve ser 100%.
                            </span>
                        )}
                        <Field name={`fasesMinerais`} subscription={{ value: true }}>
                            {({ input: { value: fasesMinerais = [] } }) => {
                                return (
                                    <SubForm
                                        nome={`fasesMinerais`}
                                        tituloForm="Fases Minerais"
                                        onSubmit={onSubmit}
                                        exibirTabelaSemRegistro={true}
                                        modoSubFormComGrid
                                        colunas={colunasFragmentosMinerais}
                                        elementos={fasesMinerais}
                                        campos={camposFragmentosMinerais}
                                        validar={(valores) => validarFasesMinerais(valores)}
                                        {...getPropsPermissao(!disabled)}
                                        renderForm={({ formProps: { form } }) => {                                         

                                            var valorMineralSelecionado = form.getState().values?.idMineral
                                            var fasesMinerais = valorFormulario?.getState().values?.fasesMinerais
                                            var fasesMineraisTela = []
                                            if (fasesMinerais) {
                                                fasesMineraisTela = resolverExibidos(fasesMinerais)
                                            }
                                            var bbMineraisFiltrados = bbMinerais.filter(value => {
                                                return !fasesMineraisTela.some(testeItem => testeItem.idMineral === value.id && valorMineralSelecionado !== value.id);
                                            });

                                            return (
                                                <>
                                                    <Row className="my-4">                                                        
                                                        <Col md={3}>
                                                            <Field
                                                                component={DropdownListField}
                                                                name={"idMineral"}
                                                                label="Mineral"
                                                                dica="Se possível selecione um ou mais minerais que compõem o cimento."
                                                                elementos={bbMineraisFiltrados}
                                                                displayMember={'nome'}
                                                                disabled={disabled}     
                                                                required
                                                                validate={campoObrigatorioComMsgGenerica("Mineral")}                                                           
                                                            />
                                                        </Col>
                                                        {valorMineralSelecionado &&
                                                            <Col md={3}>
                                                                <Field
                                                                    name="porcentagemCimento"
                                                                    component={NumberInputField}
                                                                    label="% na fração"
                                                                    dica="Estime em porcentagem a proporção deste mineral no cimento."
                                                                    permitirNegativos={false}
                                                                    casasInteiros={3}
                                                                    casasDecimais={0}
                                                                    valorMaximo={100}
                                                                    required={true}
                                                                    validate={campoObrigatorioComMsgGenerica("% na fração")}
                                                                    disabled={disabled}
                                                                />
                                                            </Col>
                                                        }
                                                        {!valorMineralSelecionado &&
                                                            <Col md={3}>
                                                                <Field
                                                                    name="porcentagemCimento"
                                                                    component={NumberInputField}
                                                                    label="% na fração"
                                                                    dica="Estime em porcentagem a proporção deste mineral no cimento."
                                                                    permitirNegativos={false}
                                                                    casasInteiros={3}
                                                                    casasDecimais={0}                                                                    
                                                                    valorMaximo={100}
                                                                    disabled={true}
                                                                />
                                                            </Col>
                                                        }
                                                    </Row>
                                                </>
                                            )
                                        }
                                        }
                                    />
                                )
                            }}
                        </Field>
                    </Card.Body>
                </Card>
            </div>
        </>
    );
};

export default FasesMinerais;