import { COLUNA_CHECKBOX_PUBLICADO } from "./ColunasAtividadesPublicacao";

export const datafieldsAtividadesPublicacao = [
    { name: "id", type: "number" },
    { name: COLUNA_CHECKBOX_PUBLICADO, type: "bool" },
    { name: "tipo", type: "string" },
    { name: "totalAtividades", type: "number" },
    { name: "totalValidadas", type: "number" },
    { name: "totalPublicadas", type: "number" },
    { name: "dataPublicacao", type: "string" },
    { name: "subArea", type: "string" },
    { name: "teste", type: "string" },
  ];