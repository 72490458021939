/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { createRef, useCallback, useEffect, useState } from "react";
import DropdownListWithValue2 from "../../Jqx/DropdownList/DropdownListWithValue2";
import Select, { createFilter } from "react-select";
import Erro from "../../Erro";
import Form from "react-bootstrap/Form";
import Hint from "../../hint/Hint";

function useForceUpdate() {
  const [, setValue] = useState(0); // integer state
  return () => setValue(value => value + 1); // update the state to force render
}

const DropDownListField = ({ input, meta, customOnChange, isClearable = true, sizelimitForSelection = 0, ...props }) => {
  const [controlShouldRenderValue, setControlShouldRenderValue] = useState(true);
  const [elementosFormatados, setElementosFormatados] = useState([]);
  const [valorSelecionado, setValorSelecionado] = useState(null);
  const mensagemErro = meta.touched && !meta.valid && meta.error;
  const fieldRef = createRef();
  const forceUpdate = useForceUpdate();

  const styles = {
    menu: provided => ({ ...provided, zIndex: 9999 }),

    control: (base) => ({
      ...base,
      minHeight: 25,
      height: 25,
      fontFamily: "Helvetica",
      backgroundColor: 'white',
      color: props.disabled ? '#dbdbdb' : 'black'
    }),
    dropdownIndicator: (base) => ({
      ...base,
      padding: 0,
      margin: 0
    }),
    clearIndicator: (base) => ({
      ...base,
      padding: 0,
      margin: 0
    }),
    indicatorSeparator: (base) => ({
      ...base,
      height: 23,
      minHeight: 0,
      padding: 0,
      margin: 0
    }),
    placeholder: (base) => ({
      ...base,
      fontSize: 13,
      padding: 0,
      margin: 0,
      paddingLeft: 5,
      fontFamily: "Helvetica",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      maxWidth: "90%",
    }),
    container: (base) => ({
      ...base,
      padding: 0,
      margin: 0,
    }),

    singleValue: (base) => ({
      fontSize: 13,
      padding: 0,
      paddingTop: 0,
      paddingLeft: 10,
      margin: 0,
      fontFamily: "Helvetica",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      position: 'static',
      maxWidth: "90%",
    }),
    input: (base) => ({
      ...base,
      padding: 0,
      margin: 0,
      marginLeft: 5,
      position: 'static',
      overflow: 'none',
      fontSize: 13,
      fontFamily: "Helvetica"
    }),
    indicatorsContainer: (base) => ({
      ...base,
      minHeight: 23,
      maxHeight: 23,
    }),
    option: (base) => ({
      ...base,
      fontSize: 13,
      fontFamily: "Helvetica"
    }),
    valueContainer: props.checkboxes ? (provided, state) => ({
      ...provided,
      maxWidth: "90%",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      display: "initial",
      position: 'static',
      padding: 0,
      margin: 0,
      marginLeft: 5,
      fontSize: 13,
      fontFamily: "Helvetica"
    }) : (base) => ({
      ...base,
      overflow: 'hidden',
      position: 'static',
      padding: 0,
      margin: 0,
      maxWidth: "80%",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      fontSize: 13,
      fontFamily: "Helvetica"
    }),
  };

  const multSelectStyles = {
    menu: provided => ({ ...provided, zIndex: 9999 }),
    control: (base) => ({
      ...base,
      minHeight: 25,
      height: 25,
      fontFamily: "Helvetica",
      backgroundColor: 'white',
      color: props.disabled ? '#dbdbdb' : 'black'
    }),
    dropdownIndicator: (base) => ({
      ...base,
      padding: 0,
      margin: 0
    }),
    clearIndicator: (base) => ({
      ...base,
      padding: 0,
      margin: 0
    }),
    indicatorSeparator: (base) => ({
      ...base,
      height: 23,
      minHeight: 0,
      padding: 0,
      margin: 0
    }),

    placeholder: (base) => ({
      ...base,
      fontSize: 13,
      padding: 0,
      margin: 0,
      paddingLeft: 5,
      fontFamily: "Helvetica",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      maxWidth: "90%",
      display: "initial",
    }),
    container: (base) => ({
      ...base,
      padding: 0,
    }),

    indicatorsContainer: (base) => ({
      ...base,
      minHeight: 23,
      maxHeight: 23,
    }),
    option: (base) => ({
      ...base,
      fontSize: 13,
      fontFamily: "Helvetica"
    }),
    valueContainer: props.checkboxes ? (provided, state) => ({
      ...provided,
      textOverflow: "ellipsis",
      maxWidth: "90%",
      whiteSpace: "nowrap",
      overflow: "hidden",
      fontSize: 13,
      fontFamily: "Helvetica",
      marginLeft: 2,
      display: "initial",
      padding: '0px, 5px, 0px, 0px',
      paddingBottom: "5px !important",
      marginTop: '-2px !important'
    }) : (base) => ({
      ...base,
      maxWidth: "80%",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      fontSize: 13,
      fontFamily: "Helvetica",
      padding: '0px, 5px, 0px, 0px',
      paddingBottom: "5px !important",
      marginTop: '-2px !important'
    }),

    input: (base) => ({
      ...base,
      padding: 0,
      margin: 0,
      marginLeft: '5px',
      overflow: 'none',
      fontSize: 13,
      fontFamily: "Helvetica",
      display: "initial",
      padding: '0px, 5px, 0px, 0px'
    }),
  };

  const multiValueContainer = ({ selectProps, data }) => {
    const label = data.label;
    const allSelected = selectProps.value;
    const index = allSelected.findIndex(selected => selected.label === label);
    const isLastSelected = index === allSelected.length - 1;
    const labelSuffix = isLastSelected ? ` (${allSelected.length})` : ", ";

    const val = `${label}${labelSuffix}`;
    return val;

  };

  useEffect(() => {
    let tempElementosFormatados = [];
    let defaultDisplay = 'nome';
    if (props.displayMember !== '') {
      defaultDisplay = props.displayMember;
    }
    if (!props.elementos || props?.elementos?.length === 0) {
      setElementosFormatados([])
    } else {
      if (props.elementos && Array.isArray(props.elementos)) {
        props.elementos?.forEach(item => {
          tempElementosFormatados.push({ value: item.id, label: item[defaultDisplay] })
          setElementosFormatados(tempElementosFormatados)
        })
      }
    }

    let tempValorSelecionado = elementosFormatados.filter(elemento => elemento.value.toString() === input.value.toString())
    
    if (tempValorSelecionado.length === 0) {
      setValorSelecionado(null);
    }

  }, [props?.elementos])

  useEffect(() => {
    setControlShouldRenderValue(true);
    if ((valorSelecionado === null || valorSelecionado === undefined)
      && props.onClear != undefined && props.onClear != null) {
      props.onClear();
    }

  }, [valorSelecionado]);

  useEffect(() => {
    if (!props.checkboxes) {
      let tempValorSelecionado = null;
      tempValorSelecionado = elementosFormatados.filter(elemento => elemento.value.toString() === input.value.toString())
      
      setValorSelecionado(tempValorSelecionado[0]);
    } else {
      let tempArray = [];
      if (input.value) {
        input.value.forEach(valor => {
          elementosFormatados.forEach(elementoFormatado => {
            if (elementoFormatado.value === valor) {
              tempArray.push(elementoFormatado);
            }
          })
        });
        
        if (sizelimitForSelection > 0) {
          if (tempArray.length <= sizelimitForSelection) {
            setValorSelecionado(tempArray);
          }
        } else {
          setValorSelecionado(tempArray);
        }
      }
    }
  }, [elementosFormatados, input.value])

  const onChange = useCallback(
    (onChangeProps) => {
      
      if (onChangeProps === null) {
        customOnChange?.(null)
        input?.onChange?.(null);
      } else {
        if (props.checkboxes) {
          let tempArray = [];
          onChangeProps.forEach(item => { tempArray.push(item.value) })
          input?.onChange?.(tempArray);
          if (sizelimitForSelection > 0) {
            if (onChangeProps?.length <= sizelimitForSelection) {
              setValorSelecionado(onChangeProps);
            }
          } else {
            setValorSelecionado(onChangeProps);
          }
        } else {
          customOnChange?.(onChangeProps.value)
          input?.onChange?.(onChangeProps.value);
        }

      }
    },
    [input]
  );

  const onChangeCallback = useCallback((evt) => {
    props.onChange()
    onChange?.(evt)
  }, [onChange, props])

  const filterConfig = {
    ignoreCase: true,
    ignoreAccents: true,
    trim: true,
    matchFrom: 'any'
  };

  const keyDown = (e) => {
    if (e.key != 'ArrowUp' && e.key != 'ArrowDown' && e.key != 'ArrowRight' && e.key != 'ArrowLeft')
      setControlShouldRenderValue(false);
  }

  useEffect(() => {
  }, [])

  return (
    <>
      {
        <>
          {props.label && (
            <Form.Label className="small">
              {props.label}
              {props.required && <span style={{ color: "red" }}>*</span>}
              {props.label && props.dica && <Hint textoAjuda={props.dica} />}
            </Form.Label>
          )}

          {
            props.checkboxes ?
              <Select
                key={`dropDownKey-${props.label ?? ''}`}
                className='select-personalizado'
                ref={fieldRef}
                filterOption={createFilter(filterConfig)}
                hideSelectedOptions={false}
                closeMenuOnSelect={!props.checkboxes}
                components={{ MultiValueContainer: multiValueContainer }}
                noOptionsMessage={() => "Nenhuma opção encontrada."}
                placeholder={props.placeholder ?? 'Selecione..'}
                isMulti={props.checkboxes}
                styles={multSelectStyles}
                isDisabled={props.disabled}
                value={valorSelecionado}
                isClearable={isClearable}
                isSearchable={true}
                onChange={onChange}
                onBlur={(props) => {
                  if (props.onBlur)
                    props.onBlur(props);

                  setControlShouldRenderValue(true);
                }}
                blur={(props) => {
                  if (props.onBlur)
                    props.onBlur(props);

                  setControlShouldRenderValue(true);
                }}
                options={elementosFormatados}
                onKeyDown={keyDown}
                controlShouldRenderValue={controlShouldRenderValue}
              />
              :
              <Select
                key={`dropDownKey-${props.label ?? ''}`}
                className={props.customClass ? `select-personalizado ${props.customClass}` : 'select-personalizado'}
                ref={fieldRef}
                filterOption={createFilter(filterConfig)}
                hideSelectedOptions={false}
                closeMenuOnSelect={!props.checkboxes}
                components={{ MultiValueContainer: multiValueContainer }}
                noOptionsMessage={() => "Nenhuma opção encontrada."}
                placeholder={props.placeholder ?? 'Selecione..'}
                isMulti={props.checkboxes}
                styles={styles}
                isDisabled={props.disabled}
                value={valorSelecionado}
                isClearable={isClearable}
                isSearchable={true}
                onChange={props?.onChange ? onChangeCallback : onChange}
                onBlur={props.onBlur}
                blur={props.onBlur}
                options={elementosFormatados}
              />
          }
          <Erro mensagem={mensagemErro} />
        </>}
    </>
  );
};

DropDownListField.propTypes = {
  ...DropdownListWithValue2.propTypes,
};

DropDownListField.defaultProps = {
  ...DropdownListWithValue2.defaultProps,
};

export default DropDownListField;

