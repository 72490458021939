import React, { useEffect, useReducer, useState } from 'react'
import { useSelector } from 'react-redux';
import PropTypes from "prop-types";
import './style.css';
import { Breadcrumb, BreadcrumbItem, Col, Container, Row } from 'react-bootstrap';
import { resolverExibidos } from '../SubForm/SubForm';
import { isEmpty } from 'lodash';
import { useForm } from 'react-final-form';

const InfoPanelGPS = (props) => {

  let visita = useSelector((state) => state?.visita?.visita);
  let visitas = props.visitas;
  let descricao = null;
  let caminhoInicial = props?.location?.state;
  let caminhoDescricao = null;

  if (visitas && props.form.getState().values.idVisitaSelecionada) {
    visitas = resolverExibidos(visitas);
    visitas.map(item => {
      if (item.id.toString() === props.form.getState().values.idVisitaSelecionada.toString()) {
        descricao = item.nome;
      }
    })
  }

  useEffect(() => {
  }, [props.form])


  let caminho = [];
  const chaveNomeProjeto = 1;
  const chaveNomeArea = 2;
  const chaveNomeSubarea = 3;
  const chaveDescricao = 4;


  if (isEmpty(visita)) {
    caminho = [caminhoInicial.nomeProjeto && <BreadcrumbItem key={chaveNomeProjeto} active>{caminhoInicial.nomeProjeto}</BreadcrumbItem>, caminhoInicial.nomeArea && <BreadcrumbItem key={chaveNomeArea} active>{caminhoInicial.nomeArea}</BreadcrumbItem>, caminhoInicial.nomeSubArea && <BreadcrumbItem key={chaveNomeSubarea} active>{caminhoInicial.nomeSubArea}</BreadcrumbItem>];
  } else {
    caminho = [visita.nomeProjeto && <BreadcrumbItem key={chaveNomeProjeto} active>{visita.nomeProjeto}</BreadcrumbItem>, visita.area && <BreadcrumbItem key={chaveNomeArea} active>{visita.area}</BreadcrumbItem>, visita.subArea && <BreadcrumbItem key={chaveNomeSubarea} active>{visita.subArea}</BreadcrumbItem>];
  }
  if (props.hideSubArea) {
    caminho = [visita.nomeProjeto && <BreadcrumbItem key={chaveNomeProjeto} active>{visita.nomeProjeto}</BreadcrumbItem>, visita.area && <BreadcrumbItem key={chaveNomeArea} active>{visita.area}</BreadcrumbItem>];
  }

  if ("id" in visita && visita.id !== null) {
    caminhoDescricao = <BreadcrumbItem key={chaveDescricao} active>{visita.descricao}</BreadcrumbItem>;
  } else if (descricao) {
    caminhoDescricao = <BreadcrumbItem key={chaveDescricao} active>{descricao}</BreadcrumbItem>;
  }

  let breadcrumb = document.getElementsByClassName('breadcrumb');

  breadcrumb[0]?.classList.add('m-0');


  return <>
    <Row className="mb-4">
      <Col md={12} className="d-flex justify-content-center align-items-center">
        <Container fluid className="p-1" style={{ "backgroundColor": 'rgba(108, 119, 129, 0.3)', "borderRadius": "6px" }}>
          <Breadcrumb className="m-0">
            {caminho}
            {caminhoDescricao && caminhoDescricao}
          </Breadcrumb>
        </Container>
      </Col>
    </Row>
  </>

  // return (
  //   <div className="info-container">
  //     {visita.nomeProjeto && (<h4 className={"info-item"}>{visita.nomeProjeto.toUpperCase()}</h4>)}
  //     <div className="info-divider" />
  //     {visita.area && (<h4 className={"info-item"}>{visita.area.toUpperCase()}</h4>)}
  //     <div className="info-divider" />
  //     {visita.subArea && (<h4 className={"info-item"}>{visita.subArea.toUpperCase()}</h4>)}
  //     {visita.descricao && (<div className="info-divider" />)}
  //     {visita.descricao && (<h4 className={"info-item"}>{visita.descricao.toUpperCase()}</h4>)}
  //   </div>
  // )
}

InfoPanelGPS.propTypes = {
  disableInfo: PropTypes.bool,
};

export default InfoPanelGPS;
