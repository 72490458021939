import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import { connect } from "react-redux";
import Form from "react-bootstrap/Form";
import PageScaffold from "../../components/scaffold/PageScaffold";
import converterVetorParaObjetoIndexado from "../../util/converterVetorParaObjetoIndexado";
import Service from "../../service/Service";
import { Col, Row } from "react-bootstrap";
import Hint from "../../components/hint/Hint";
import DropdownListWithValue from "../../components/Jqx/DropdownList/DropdownListWithValue";

import Card from "../../components/card"
import { selecionarVisita } from "../../reducers/visitaReducer";
import BlockUi from "react-block-ui";
import atividades_campo from '../../assets/img/atividades_campo2.png';
import cadastrar_dados from '../../assets/img/cadastrar_dados2.png';
import consultar_geral from '../../assets/img/consulta_geral2.png';
import validacao_publicacao from '../../assets/img/icone_validacao_publicacao.png';
import swal from "sweetalert";

const mapDispatchToProps = (dispatch) => {
    return {
        selecionarVisita(visita) {
            dispatch(selecionarVisita(visita))
        },
    };
};
class TelaInicial extends Component {


    constructor(props) {
        super(props);

        this.servicoProjetos = Service("/projetos");
        this.servicoAreas = Service("/areas");
        this.servicoEstacoes = Service("/estacoes");

        /** @type {React.RefObject<JqxGrid>} */
        this.estacoesGrid1 = React.createRef();
        /** @type {React.RefObject<JqxGrid>} */
        this.estacoesGrid2 = React.createRef();

        this.opcoesSessao = JSON.parse(sessionStorage.getItem("localidade"));

        this.state = {
            carregamentoGlobal: false,
            carregamentoMapa: true,
            projetos: null,
            idProjetoSelecionado: null,
            areas: null,
            idAreaSelecionada: null,
            subAreas: null,
            idSubAreaSelecionada: null,
            nomeProjeto: null,
            nomeArea: null,
            nomeSubArea: null,
            estacoes: null,
            idEstacaoSelecionada: null,
            mostrarEstacoesHistoricas: false,
            mostrarTabelaEstacoes: false,
            mapaCarregado: false,
            dadosCarregados: true,
            validadorProjeto: false,
            revisorProjeto: false,
            publicadorProjeto: false,
        };

    }

    async componentDidMount() {
        localStorage.setItem('LAST_PAGE', 'estacoes');
        this.carregarProjetos();
    }

    isProjetosCarregados = () => {
        return null != this.state.projetos;
    };

    mostrarAreas = () => {
        return null != this.state.areas && this.state.areas.length > 0;
    };

    mostrarSubAreas = () => {
        return null != this.state.subAreas && this.state.subAreas.length > 0;
    };

    mostrarTabelasDeEstacao = () => {
        // console.debug('Mostrar?', this.state.mostrarTabelaEstacoes);
        return this.state.mostrarTabelaEstacoes;
    };

    carregarProjetos = async (_) => {
        const projetosCarregados = await this.servicoProjetos.listar();
        this.setState(
            {
                projetos: projetosCarregados.data.dados,
                projetosPorId: converterVetorParaObjetoIndexado(
                    projetosCarregados.data.dados
                ),
            },
            () => {
                if (this.opcoesSessao) {
                    this.selecionarProjeto({
                        idProjetoSelecionado: this.opcoesSessao.idProjeto,
                    });
                }
            }
        );
    };

    selecionarProjeto = async (evt) => {
        this.setState({
            carregamentoGlobal: true
        });
        this.setState({
            dadosCarregados: false,
        });
        const that = this;
        if (evt.idProjetoSelecionado !== this.state.idProjetoSelecionado) {
            const idProjetoSelecionado = evt.idProjetoSelecionado;


            if (null != idProjetoSelecionado && !isNaN(idProjetoSelecionado)) {
                this.setState({
                    carregamentoGlobal: true
                });
                const respostaAreas = await this.servicoProjetos.customURL(
                    "GET",
                    `/${idProjetoSelecionado}/areas`
                );
                const areas = respostaAreas.data.dados || [];
                
                if (areas) {
                    this.setState({
                        carregamentoGlobal: true,
                    });
                    this.setState(
                        {
                            areas,
                            areasPorId: converterVetorParaObjetoIndexado(areas),

                            subAreas: null,
                            subAreasPorId: null,
                            mostrarTabelaEstacoes: false,

                            idProjetoSelecionado: evt.idProjetoSelecionado,
                            idAreaSelecionada: null,
                            idSubAreaSelecionada: null,
                            dadosCarregados: false,
                        },
                        () => {
                            if (areas.length >= 1) {
                                const idAreaInicial =
                                    this.opcoesSessao &&
                                        this.state.idProjetoSelecionado ===
                                        this.opcoesSessao.idProjeto
                                        ? this.opcoesSessao.idArea
                                        : areas[0].id; // Sessão ou primeira da lista
                                that.selecionarArea({
                                    idAreaSelecionada: idAreaInicial,
                                });
                            } else {
                                this.setState({
                                    dadosCarregados: true,
                                });
                            }
                        }
                    );
                }
            }
        }

        this.setState({
            carregamentoGlobal: false
        });
    };

    selecionarArea = async (evt) => {
        this.setState({
            dadosCarregados: false,
        });
        const that = this;
        if (evt.idAreaSelecionada !== this.state.idAreaSelecionada) {
            const idAreaSelecionada = evt.idAreaSelecionada;

            if (null != idAreaSelecionada && !isNaN(idAreaSelecionada)) {
                const respostaSubAreas = await this.servicoProjetos.customURL(
                    "GET",
                    `/${this.state.idProjetoSelecionado}/subAreas`
                );
                let listaOrdenada = respostaSubAreas.data.dados.sort((a, b) => {
                    if (a.nome < b.nome) {
                        return -1;
                    }
                    if (a.nome > b.nome) {
                        return 1;
                    }
                    return 0;
                });
                const subAreas = listaOrdenada;

                if (subAreas) {                    
                    this.setState(
                        {
                            mostrarTabelaEstacoes: false,
                            subAreas,
                            subAreasPorId: converterVetorParaObjetoIndexado(subAreas),
                            idAreaSelecionada: idAreaSelecionada,
                            idSubAreaSelecionada: null,
                        },
                        () => {
                            if (subAreas.length === 0) {
                                that.setState({
                                    mostrarTabelaEstacoes: true,
                                    dadosCarregados: true,
                                    mostrarEstacoesHistoricas:
                                        this.opcoesSessao?.mostrarEstacoesHistoricas || false,
                                });
                            } else if (subAreas.length >= 1) {
                                const idSubAreaInicial =
                                    this.opcoesSessao &&
                                        this.state.idProjetoSelecionado ===
                                        this.opcoesSessao.idProjeto
                                        ? this.opcoesSessao.idSubArea
                                        : subAreas[0].id; // Sessão ou primeira da lista
                                that.selecionarSubArea({
                                    idSubAreaSelecionada: idSubAreaInicial,
                                });
                            }
                        }
                    );
                }
            }
        }
    };

    selecionarSubArea = async (evt) => {
        // const that = this;
        if (evt.idSubAreaSelecionada !== this.idSubAreaSelecionada) {
            const idSubAreaSelecionada = evt.idSubAreaSelecionada;
            this.setState(
                {
                    mostrarTabelaEstacoes: false,
                    idSubAreaSelecionada,
                },
                () => {
                    this.setState({
                        mostrarTabelaEstacoes: true,
                        dadosCarregados: true,
                        mostrarEstacoesHistoricas:
                            this.opcoesSessao?.mostrarEstacoesHistoricas || false,
                    });
                }
            );
        }
    };

    montarEstadoDeLocalidade() {
        const nomeSubArea =
            null != this.state.idSubAreaSelecionada
                ? this.state.subAreasPorId[this.state.idSubAreaSelecionada].nome
                : "";
        const geometriaProjeto = this.state.idSubAreaSelecionada
            ? this.state.subAreasPorId[this.state.idSubAreaSelecionada]
            : this.state.areasPorId[this.state.idAreaSelecionada];

        const localidade = {
            nomeProjeto:
                this.state.projetosPorId[this.state.idProjetoSelecionado].nome,
            nomeArea: this.state.areasPorId[this.state.idAreaSelecionada].nome,
            nomeSubArea,
            idProjeto: this.state.idProjetoSelecionado,
            idArea: this.state.idAreaSelecionada,
            idSubArea: this.state.idSubAreaSelecionada,
            hemisferio: geometriaProjeto.hemisferio, // Norte/Sul
            hemisferioHorizontal: geometriaProjeto.hemisferioHorizontal, // Leste/Oeste
            geometryFilter: this.geometryFilter,
            validadorProjeto: this.state.projetosPorId[this.state.idProjetoSelecionado].validador,
            revisorProjeto: this.state.projetosPorId[this.state.idProjetoSelecionado].revisor,
            publicadorProjeto: this.state.projetosPorId[this.state.idProjetoSelecionado].publicador,
        };

        // Armazenar opções selecionadas em sessão para serem lidas ao voltar nessa página
        sessionStorage.setItem(
            "localidade",
            JSON.stringify({
                idProjeto: localidade.idProjeto,
                idArea: localidade.idArea,
                idSubArea: localidade.idSubArea,
                nomeProjeto: localidade.nomeProjeto,
                nomeArea: localidade.nomeArea,
                nomeSubArea: localidade.nomeSubArea,
                mostrarEstacoesHistoricas: this.state.mostrarEstacoesHistoricas,
                validadorProjeto: localidade.validadorProjeto,
                revisorProjeto: localidade.revisorProjeto,
                publicadorProjeto: localidade.publicadorProjeto,
            })
        );


        this.props.selecionarVisita({
            nomeProjeto: localidade.nomeProjeto,
            area: localidade.nomeArea,
            subArea: localidade.nomeSubArea,
        })


        return localidade;
    }
    redirecionar = (caminho) => {
        this.props.history.push({
            pathname: caminho,
            state: this.montarEstadoDeLocalidade(),
        });
    };

    async selecionarProjetoViaDropdown(valor) {
        this.setState({
            carregamentoGlobal: true
        });
        if (valor === null) {
            this.setState({
                idProjetoSelecionado: null,
                idAreaSelecionada: null,
                idSubAreaSelecionada: null,
                areas: null,
                subAreas: null,
                mostrarTabelaEstacoes: null,
                validadorProjeto: false,
                revisorProjeto: false,
                publicadorProjeto: false,
            })
        } else {
            if (
                this.state.idProjetoSelecionado !== valor &&
                this.state.dadosCarregados
            ) {
                const validadorProjeto = this.state.projetosPorId[valor].validador;
                const revisorProjeto = this.state.projetosPorId[valor].revisor;
                const publicadorProjeto = this.state.projetosPorId[valor].publicador;
                this.setState({
                    validadorProjeto,
                    revisorProjeto,
                    publicadorProjeto,
                })
                await this.selecionarProjeto({
                    idProjetoSelecionado: valor,
                });
            }
        }

        this.setState({
            carregamentoGlobal: false
        });
    }

    selecionarAreaViaDropdown(valor) {
        if (this.state.idAreaSelecionada !== valor && this.state.dadosCarregados) {
            this.selecionarArea({
                idAreaSelecionada: valor,
            });
        }
    }

    selecionarSubAreaViaDropdown(valor) {
        if (
            this.state.idSubAreaSelecionada !== valor &&
            this.state.dadosCarregados
        ) {
            this.selecionarSubArea({
                idSubAreaSelecionada: valor,
            });
        }
    }



    render() {
        return (
            <>
                <BlockUi blocking={this.state.carregamentoGlobal}>
                    <PageScaffold disableInfo titulo="">
                        <Container style={{ paddingLeft: "0px", paddingRight: "0p" }}>
                            <Container style={{ padding: "0px", borderRadius: "8px" }}><h1 className="bemVindo">Bem Vindo!</h1>
                                <div className="texto">
                                    <div className="textoOla">
                                        <div className="paragrafo">
                                            Olá!
                                        </div>
                                        <div className="paragrafo">
                                            Nossa proposta é entregar a sociedade geocientífica um ponto único de abastecimento de dados estruturados e relacionais, abrangendo dados geocientíficos e sobre recursos minerais em todo Brasil. No BaseGEO será possível, por exemplo, integrar dados geocientíficos de campo com análises de laboratório, amostras, laudos, relatórios e informações de armazenamento.
                                        </div>
                                        <div className="textoNegrito">
                                            Atualmente estão disponíveis o cadastro de descrições detalhadas de afloramento (Módulo AFLORA), de amostras coletadas (Módulo AMOSTRA), e de cadastro de recursos minerais (Módulo RECMIN) de maneira integrada.
                                        </div>
                                    </div>
                                </div>
                            </Container>

                            <Card>
                                <Card.Body>
                                    <Row>
                                        {this.isProjetosCarregados() && (
                                            <Col md={4}>
                                                <Form.Label className="small">
                                                    Projeto
                                                    <Hint textoAjuda="Informe o Projeto de trabalho" />
                                                </Form.Label>
                                                <DropdownListWithValue
                                                    opcaoNula={false}
                                                    isClearable={true}
                                                    elementos={this.state.projetos}
                                                    onSelect={this.selecionarProjetoViaDropdown.bind(this)}
                                                    value={this.state.idProjetoSelecionado}
                                                    required
                                                    autoFocus
                                                />
                                            </Col>
                                        )}
                                        {this.mostrarAreas() && (
                                            <Col md={4}>
                                                <Form.Label className="small">
                                                    Área
                                                    <Hint textoAjuda="Informe a área do projeto" />
                                                </Form.Label>
                                                <DropdownListWithValue
                                                    opcaoNula={false}
                                                    elementos={this.state.areas}
                                                    readonly={true}
                                                    onSelect={this.selecionarAreaViaDropdown.bind(this)}
                                                    value={this.state.idAreaSelecionada}
                                                    disabled={true}
                                                    readOnly={true}
                                                    required
                                                />
                                            </Col>
                                        )}
                                        {this.mostrarSubAreas() && (
                                            <Col md={4}>
                                                <Form.Label className="small">
                                                    Subárea
                                                    <Hint textoAjuda="Informe a subárea do projeto" />
                                                </Form.Label>
                                                <DropdownListWithValue
                                                    opcaoNula={false}
                                                    elementos={this.state.subAreas}
                                                    onSelect={this.selecionarSubAreaViaDropdown.bind(this)}
                                                    value={this.state.idSubAreaSelecionada}
                                                />
                                            </Col>
                                        )}
                                    </Row>
                                    <div style={{ marginTop: '30px', position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <div className="botoes">
                                            <button
                                                type="button"
                                                className="botoesIconeTexto"
                                                onClick={() => {
                                                    if ((this.state?.idProjetoSelecionado) && (this.state?.idAreaSelecionada) && (this.state?.idSubAreaSelecionada)) {
                                                        this.redirecionar("/estacoes")
                                                    }
                                                }}
                                            >
                                                <img className="botoesIcones" src={atividades_campo} />
                                                <div
                                                    className="botoesTexto">
                                                    {!((this.state?.idProjetoSelecionado) && (this.state?.idAreaSelecionada) && (this.state?.idSubAreaSelecionada)) &&
                                                        <>
                                                            <div
                                                                id="mostrarDesenvolvimento">Selecionar Projeto/Area/Subárea
                                                            </div>
                                                            <div
                                                                id="mostrarTexto">Cadastrar Atividades de Campo
                                                            </div>
                                                        </>
                                                    }
                                                    {((this.state?.idProjetoSelecionado) && (this.state?.idAreaSelecionada) && (this.state?.idSubAreaSelecionada)) &&
                                                        <>

                                                            <div
                                                                id="texto">Cadastrar Atividades de Campo
                                                            </div>
                                                        </>
                                                    }

                                                </div>
                                            </button>

                                            <button
                                                type="button"
                                                className="botoesIconeTexto"
                                                onClick={() => {
                                                    if ((this.state?.idProjetoSelecionado) && (this.state?.idAreaSelecionada) && (this.state?.idSubAreaSelecionada)) {
                                                        this.redirecionar("/dados-analiticos")
                                                    }
                                                }}
                                            >
                                                <img className="botoesIcones" src={cadastrar_dados} />
                                                <div
                                                    className="botoesTexto">
                                                    {!((this.state?.idProjetoSelecionado) && (this.state?.idAreaSelecionada) && (this.state?.idSubAreaSelecionada)) &&
                                                        <>
                                                            <div
                                                                id="mostrarDesenvolvimento">Selecionar Projeto/Area/Subárea
                                                            </div>
                                                            <div
                                                                id="mostrarTexto">Cadastrar Dados Analitícos
                                                            </div>
                                                        </>
                                                    }
                                                    {((this.state?.idProjetoSelecionado) && (this.state?.idAreaSelecionada) && (this.state?.idSubAreaSelecionada)) &&
                                                        <>

                                                            <div
                                                                id="texto">Cadastrar Dados Analitícos
                                                            </div>
                                                        </>
                                                    }
                                                </div>
                                            </button>
                                            {(<button
                                                type="button"
                                                className="botoesIconeTexto"
                                                onClick={() => {
                                                    const {
                                                        idProjetoSelecionado,
                                                        idAreaSelecionada,
                                                        idSubAreaSelecionada,
                                                        projetosPorId,
                                                    } = this.state;

                                                    const projetoSelecionado = projetosPorId?.[idProjetoSelecionado];

                                                    if (idProjetoSelecionado && idAreaSelecionada && idSubAreaSelecionada) {
                                                        if (projetoSelecionado?.validador || projetoSelecionado?.revisor) {
                                                            this.redirecionar("/validacao");
                                                        } else if (projetoSelecionado?.publicador) {
                                                            this.redirecionar("/publicacao");
                                                        } else {
                                                            swal({
                                                                title: "Funcionalidade não disponível para seu perfil de usuário",
                                                                icon: 'error',
                                                                buttons: { cancel: "Ok, Cancelar!" },
                                                            });
                                                        }
                                                    }
                                                }}
                                            >
                                                <img className="botoesIcones" alt="Ícone de validação/publicação" src={validacao_publicacao} />
                                                <div className="botoesTexto">
                                                    {!((this.state?.idProjetoSelecionado) && (this.state?.idAreaSelecionada) && (this.state?.idSubAreaSelecionada)) &&
                                                        <>
                                                            <div id="mostrarDesenvolvimento">Selecionar Projeto/Area/Subárea</div>
                                                            <div id="mostrarTexto">Validação/Publicação</div>
                                                        </>
                                                    }
                                                    {((this.state?.idProjetoSelecionado) && (this.state?.idAreaSelecionada) && (this.state?.idSubAreaSelecionada) && !(this.state?.projetosPorId?.[this.state.idProjetoSelecionado]?.validador
                                                        || this.state.projetosPorId?.[this.state.idProjetoSelecionado]?.revisor
                                                        || this.state.projetosPorId?.[this.state.idProjetoSelecionado]?.publicador)) &&
                                                        <>
                                                            <div id="mostrarDesenvolvimento">Sem perfil de acesso</div>
                                                            <div id="mostrarTexto">Validação/Publicação</div>
                                                        </>
                                                    }
                                                    {((this.state?.idProjetoSelecionado) && (this.state?.idAreaSelecionada) && (this.state?.idSubAreaSelecionada) && (this.state?.projetosPorId?.[this.state.idProjetoSelecionado]?.validador
                                                        || this.state.projetosPorId?.[this.state.idProjetoSelecionado]?.revisor
                                                        || this.state.projetosPorId?.[this.state.idProjetoSelecionado]?.publicador)) &&
                                                        <>
                                                            <div id="texto">Validação/Publicação</div>
                                                        </>
                                                    }
                                                </div>
                                            </button>
                                            )}
                                            <button type="button" className="botoesIconeTexto">
                                                <img className="botoesIcones" src={consultar_geral} />
                                                <div
                                                    className="botoesTexto">
                                                    <div
                                                        id="mostrarDesenvolvimento">Em Desenvolvimento
                                                    </div>
                                                    <div
                                                        id="mostrarTexto">Consulta Geral
                                                    </div>
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                            <hr />
                        </Container>
                    </PageScaffold>
                </BlockUi >
            </>
        );
    }
}

export default connect(null, mapDispatchToProps)(TelaInicial)
function verificaListaUnica(lista) {
    if (lista) {
        if (lista.length === 1) {
            return lista[0].id;
        }
    }
}