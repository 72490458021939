import { PureComponent } from "react";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { AmostraSubFormPart } from "../components/AmostraSubFormPart";
import {
  amostraDataFieldsCommon,
  AmostraSymbol,
  amostrasLabelsMap,
} from "../constants/amostras.constant";
import { NivelAguaField } from "../components/NivelAguaField";
import { AreaDrenagemField } from "../components/AreaDrenagemField";
import { TurbidezAguaField } from "../components/TurbidezAguaField";
import { CorSampleField } from "../components/CorSampleField";
import { VelocidadeCorrenteField } from "../components/VelocidadeCorrenteField";
import { LarguraRioField } from "../components/LarguraRioField";
import { ProfundidadeRioField } from "../components/ProfundidadeRioField";
import { OverbankSampleGeoquimicaModelLike } from "../../../../../models/activity-geoquimica.model";
import { ImagensAmostraSubForm } from "../components/ImagensAmostraSubForm";
import { AmostraGridSubForm } from "../components/AmostraGridSubForm";

export interface OverbankSectionFormProps {
  prefixoNome: string;
  items?: OverbankSampleGeoquimicaModelLike[];
  onItemsChanged?: (items: OverbankSampleGeoquimicaModelLike[]) => void;
}

export interface OverbankSectionFormState {}

export class OverbankSectionForm extends PureComponent<
  OverbankSectionFormProps,
  OverbankSectionFormState
> {
  datafieldsOverbank = [
    ...amostraDataFieldsCommon,
    {
      name: "nu_largura_rio",
      type: "number",
      map: "nu_largura_rio",
    },
    {
      name: "nu_profundidade_rio",
      type: "number",
      map: "nu_profundidade_rio",
    },
    {
      name: "id_velocidade_corrente",
      type: "number",
      map: "id_velocidade_corrente",
    },
    {
      name: "id_nivel_agua",
      type: "string",
      map: "id_nivel_agua",
    },
    {
      name: "id_area_drenagem",
      type: "number",
      map: "id_area_drenagem",
    },
    {
      name: "id_turbidez_agua",
      type: "number",
      map: "id_turbidez_agua",
    },
    {
      name: "id_cor_agua",
      type: "string",
      map: "id_cor_agua",
    },
    {
      name: "id_cor_material_coletado",
      type: "string",
      map: "id_cor_material_coletado",
    },
  ];

  amostraSymbol = AmostraSymbol.Overbank;

  constructor(props) {
    super(props);
    this.state = {};
  }

  renderForm = ({ editando, prefixoNome }) => {
    return (
      <>
        <AmostraSubFormPart
          prefixoNome={prefixoNome}
          amostraSymbol={this.amostraSymbol}
          profundidadeMinRequired={true}
          profundidadeMaxRequired={true}
        />
        <Row className="my-3">
          <Col>
            <LarguraRioField prefixoNome={prefixoNome} required={true} />
          </Col>
          <Col>
            <ProfundidadeRioField prefixoNome={prefixoNome} required={true} />
          </Col>
          <Col>
            <VelocidadeCorrenteField
              prefixoNome={prefixoNome}
              required={true}
            />
          </Col>
        </Row>
        <Row className="my-3">
          <Col>
            <NivelAguaField prefixoNome={prefixoNome} required={true} />
          </Col>
          <Col>
            <AreaDrenagemField prefixoNome={prefixoNome} required={true} />
          </Col>
          <Col>
            <TurbidezAguaField prefixoNome={prefixoNome} required={true} />
          </Col>
        </Row>
        <Row className="my-3">
          <Col>
            <CorSampleField
              prefixoNome={prefixoNome}
              name="id_cor_agua"
              label="Cor da água"
              required={true}
            />
          </Col>
          <Col>
            <CorSampleField
              prefixoNome={prefixoNome}
              name="id_cor_material_coletado"
              label="Cor do material coletado"
              required={true}
            />
          </Col>
        </Row>
        <Row className="my-3">
          <Col>
            <ImagensAmostraSubForm prefixoNome={prefixoNome} />
          </Col>
        </Row>
      </>
    );
  };

  render() {
    return (
      <>
        <Card className="my-3">
          <Card.Body>
            <Card.Subtitle>
              {amostrasLabelsMap.get(this.amostraSymbol)}
            </Card.Subtitle>
            <AmostraGridSubForm
              name="amostraOverBank"
              datafields={this.datafieldsOverbank}
              items={this.props.items}
              onItemsChanged={this.props.onItemsChanged}
              renderForm={this.renderForm}
            />
          </Card.Body>
        </Card>
      </>
    );
  }
}
