import { useCallback } from "react";
import Service from "../../../service/Service";

export function useAtualizarPontosAtribuidosEstacao({ pontosAtribuidosAEstacao, idEstacao }) {

    const atualizarPontosAtribuidosAEstacao = useCallback(async () => {
        if (pontosAtribuidosAEstacao.current.length > 0)
            return;
        return Service("/gps/pontosGPS/paraEstacao")
            .query({ idEstacao })
            .then(({ data: { dados: pontos } }) => {
                pontosAtribuidosAEstacao.current = pontos || [];
            })
            .catch((err) =>
                console.error(
                    "Erro ao buscar pontos não vinculados associados a estação:",
                    err
                )
            );
    }, [pontosAtribuidosAEstacao, idEstacao]);

    return atualizarPontosAtribuidosAEstacao;
}
// Após uma edição/criação de atividade, pontos associados a estação podem ter deixado
// de ser não vinculados. Por isso precisamos atualizar essa array para o próximo formulário