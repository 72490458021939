import React from "react";
import Form from "react-bootstrap/Form";
import Hint from "../../hint/Hint";
import Erro from "../../Erro";
import { IMaskMixin } from 'react-imask';
import './MaskedInput.css'

const MaskedInput = IMaskMixin(({inputRef, mensagemErro, dica, placeholder, defaultValue, label, required, ...props}) => {
  return (
    <>
      {label && <Form.Label className="small">
        {label}
        {required && <span style={{color: "red"}}>*</span>}
        {dica && <Hint textoAjuda={dica}/>}
      </Form.Label>}
      <input
        ref={inputRef}
        placeholder={placeholder ?? ''}
        defaultValue={defaultValue}
        className={'masked-input'}
        {...props}
      />
      <Erro mensagem={mensagemErro} />
    </>
  )
})

export default MaskedInput
