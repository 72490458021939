import Card from "./index";

const CardWrapper = ({ wrap, children }) => {
  if (!wrap) return (
    <Card className="card-mt">
      <Card.Body>
        {children}
      </Card.Body>
    </Card>
  );

  return (<>{children}</>);
};

export default CardWrapper;
