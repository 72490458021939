import { PureComponent } from "react";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import geoquimicaService from "../../../../../service/GeoquimicaService";
import { Field } from "react-final-form";
import { DropdownListField } from "../../../../../components/field/DropdownListField";
import { ComboItemModelLike } from "../../../../../models/combo-item.model";
import { campoObrigatorioComMsgGenerica, comporValidadores } from "../../../../../util/validadores";

export interface MatrizPredominanteFieldProps {
    prefixoNome?: string;
    name?: string;
    required?: boolean;
    disabled?: boolean;
}

export interface MatrizPredominanteFieldState {
    matrizPredominanteOptions: ComboItemModelLike[];
}

export class MatrizPredominanteField extends PureComponent<MatrizPredominanteFieldProps, MatrizPredominanteFieldState> {
    label = 'Matriz predominante';
    validateField = comporValidadores(campoObrigatorioComMsgGenerica(this.label));

    private lifecycle = new Subject();

    constructor(props) {
        super(props);
        this.state = {
            matrizPredominanteOptions: [],
        };
    }

    componentDidMount() {
        geoquimicaService.listMatrizPredominanteOptions().pipe(takeUntil(this.lifecycle)).subscribe(data => {
          this.setState({matrizPredominanteOptions: data});
        });
    }

    componentWillUnmount() {
        this.lifecycle.next();
        this.lifecycle.complete();
    }

    render() {
        const disabled = !!this.props.disabled;
        const required = !!this.props.required;
        return (
            <>
                <Field
                    component={DropdownListField}
                    name={`${this.props.prefixoNome ?? ''}${this.props.name ?? 'id_matriz_predominante'}`}
                    label={this.label}
                    disabled={disabled}
                    required={required}
                    validate={required ? this.validateField : undefined}
                    elementos={this.state.matrizPredominanteOptions}
                />
            </>
        );
    }
}
