import { FormApi } from "final-form";
import { memo } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { Field } from "react-final-form";
import { NumberInputField } from "../../../../../../components/field/NumberInputField";
import { RadioButtonSetField } from "../../../../../../components/field/RadioButtonSetField";
import { SubForm } from "../../../../../../components/SubForm";
import { aoEnviarSubForm } from "../../../../../../util/mutadores";
import { campoObrigatorioComMsgGenerica } from "../../../../../../util/validadores";

const OPTIONS = [
  { nome: "Sim", id: "S" },
  { nome: "Não", id: "N" },
];

const SubFormDetalhesEtapasAquecimento = ({
  formRef,
  disabled = false,
  permitirInsercao,
}: {
  formRef: { form: FormApi; initialValues: any };
  disabled?: boolean;
  permitirInsercao: boolean;
}) => {
  const onSubmit = aoEnviarSubForm(formRef.form);

  const colunasGrid = [
    {
      text: "Temperatura(°C)",
      datafield: `temperatura`,
      width: "10%",
      cellsrenderer: (
        row,
        columnfield,
        value,
        defaulthtml,
        columnproperties,
        rowdata
      ) => {
        const formattedValue = value.toString().replace('.', ',');
        return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${formattedValue} °C</div>`;
      },
    },
    {
      text: "Idade(Ma)",
      datafield: `idadeMilhoesDeAnos`,
      width: "10%",
      cellsrenderer: (
        row,
        columnfield,
        value,
        defaulthtml,
        columnproperties,
        rowdata
      ) => {
        const formattedValue = value.toString().replace('.', ',');
        return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${formattedValue}</div>`;
      },
    },
    {
      text: "Erro(+/-)",
      datafield: `erroCalculoIdade`,
      width: "20%",
      cellsrenderer: (
        row,
        columnfield,
        value,
        defaulthtml,
        columnproperties,
        rowdata
      ) => {
        const formattedValue = value.toString().replace('.', ',');
        return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${formattedValue}</div>`;
      },
    },
    {
      text: "Temperatura de fusão",
      datafield: "temperaturaDeFusao",
      width: "20%",
      cellsrenderer: (
        row,
        columnfield,
        value,
        defaulthtml,
        columnproperties,
        rowdata
      ) => {
        return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${
          value === "S" ? "Sim" : "Não"
        } </div>`;
      },
    },
  ];
  const camposGrid = [
    {
      name: `temperatura`,
      type: "number",
      map: `temperatura`,
    },
    { name: `idadeMilhoesDeAnos`, type: "number", map: `idadeMilhoesDeAnos` },
    {
      name: `erroCalculoIdade`,
      type: "number",
      map: `erroCalculoIdade`,
    },
    {
      name: `temperaturaDeFusao`,
      type: "string",
      map: `temperaturaDeFusao`,
    },
  ];

  return (
    <div style={{ marginTop: "2.5rem" }}>
      <Card className="mt-3">
        <Card.Body>
          <Card.Title>Detalhes das etapas de aquecimento</Card.Title>
          <Field
            name={`detalhesEtapasAquecimento`}
            subscription={{ value: true }}
          >
            {({ input: { value: detalhesEtapasAquecimento = [] } }) => (
              <>
                <SubForm
                  nome={`detalhesEtapasAquecimento`}
                  onSubmit={onSubmit}
                  modoSubFormComGrid
                  exibirTabelaSemRegistro={false}
                  colunas={colunasGrid}
                  elementos={detalhesEtapasAquecimento}
                  campos={camposGrid}
                  podeExcluirEntidade={() => !disabled}
                  permitirInsercao={permitirInsercao}
                  exibirBotaoInsercao={permitirInsercao}
                  permitirEdicao={!disabled}
                  permitirExclusao={!disabled}
                  valoresIniciais={{ temperaturaDeFusao: "N" }}
                  permitirVisualizacao={disabled}
                  renderForm={({ formProps }) => (
                    <>
                      <Row className="my-3">
                        <Col md={2}>
                          <Field
                            component={NumberInputField}
                            casasInteiros={5}
                            casasDecimais={2}
                            permitirNegativos={false}
                            name={`temperatura`}
                            label="Temperatura(°C)"
                            required
                            validate={campoObrigatorioComMsgGenerica(
                              "temperatura"
                            )}
                            dica="Inserir a temperatura em graus centígrados da etapa de aquecimento relacionado ao cálculo da idade. "
                            disabled={disabled}
                          />
                        </Col>
                        <Col md={2}>
                          <Field
                            component={NumberInputField}
                            casasInteiros={5}
                            casasDecimais={1}
                            name={`idadeMilhoesDeAnos`}
                            label="Idade(Ma)"
                            dica="Inserir a idade calculada em milhões de anos para a etapa de aquecimento"
                            disabled={disabled}
                          />
                        </Col>
                        <Col md={2}>
                          <Field
                            component={NumberInputField}
                            casasInteiros={5}
                            casasDecimais={1}
                            name={`erroCalculoIdade`}
                            label="Erro(+/-)"
                            dica="Inserir o erro (em milhões de anos) associado ao cálculo da idade. "
                            disabled={disabled}
                          />
                        </Col>
                        <Col md={2}>
                          <Field
                            component={RadioButtonSetField}
                            name={`temperaturaDeFusao`}
                            label="Temperatura de fusão"
                            disabled={disabled}
                            elementos={OPTIONS}
                            horizontal
                          />
                        </Col>
                      </Row>
                    </>
                  )}
                />
              </>
            )}
          </Field>
        </Card.Body>
      </Card>
    </div>
  );
};

export default memo(SubFormDetalhesEtapasAquecimento, () => true);
