const resolverNomeRocha = ({
  mineraisPorId = [],
  rochasNomeadasPorId = [],
  prefixosPorId = [],
  complementosPorId = [],
  usarMinerais = true,
  idsMinerais = [],
  idPrefixo = -1,
  idRochaNomeada = -1,
  idComplemento = -1,
}) => {
  const rocha = idRochaNomeada > 0 ? rochasNomeadasPorId[idRochaNomeada].nome : ''
  const complemento = idComplemento > 0 ? complementosPorId[idComplemento].nome : ''
  const prefixo = idPrefixo > 0 ? prefixosPorId[idPrefixo].nome : ''
  let minerais = '';

  if (usarMinerais && idsMinerais.length > 0) {
    let tempIdsMinerais = idsMinerais.reverse();
    minerais = tempIdsMinerais
    .filter(id => id !== null && id !== undefined)
    .map(id => mineraisPorId[id].nome)
    .join(' ')

  }

  return [minerais, prefixo, rocha, complemento].join(' ').trim()
};

export default resolverNomeRocha;
