import Service from "../../../../../service/Service";
import { swalErro } from "../../../../../util/validadores";
import { Visita } from "../types/geocronologiaTypes";

export const consultarVisitasPorIdEstacao = async (
  idEstacao: number
): Promise<Visita[]> => {
  const response = await Service(`/visitas/${idEstacao}`)
    .query()
    .catch((_error) => {
      swalErro({ title: "Erro ao consultar o afloramento." });
    });

  return response?.data?.dados ? response?.data?.dados : [];
};
