import { PureComponent } from "react";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import geoquimicaService from "../../../../../service/GeoquimicaService";
import { Field } from "react-final-form";
import { DropdownListField } from "../../../../../components/field/DropdownListField";
import { ComboItemModelLike } from "../../../../../models/combo-item.model";
import { campoObrigatorioComMsgGenerica, comporValidadores } from "../../../../../util/validadores";

export interface NivelAguaFieldProps {
    prefixoNome?: string;
    name?: string;
    required?: boolean;
}

export interface NivelAguaFieldState {
    nivelAguaOptions: ComboItemModelLike[];
}

export class NivelAguaField extends PureComponent<NivelAguaFieldProps, NivelAguaFieldState> {
    label = 'Nível água';
    validateField = comporValidadores(campoObrigatorioComMsgGenerica(this.label));

    private lifecycle = new Subject();

    constructor(props) {
        super(props);
        this.state = {
            nivelAguaOptions: [],
        };
    }

    componentDidMount() {
        geoquimicaService.listNivelAguaOptions().pipe(takeUntil(this.lifecycle)).subscribe(data => {
          this.setState({nivelAguaOptions: data});
        });
    }

    componentWillUnmount() {
        this.lifecycle.next();
        this.lifecycle.complete();
    }

    render() {
        const required = !!this.props.required;
        return (
            <>
                <Field
                    component={DropdownListField}
                    name={`${this.props.prefixoNome ?? ''}${this.props.name ?? 'id_id_nivel_agua'}`}
                    label={this.label}
                    required={required}
                    validate={required ? this.validateField : undefined}
                    elementos={this.state.nivelAguaOptions}
                />
            </>
        );
    }
}
