import { useCallback, useEffect, useState } from "react";
import BlockUi from "react-block-ui";
import { Card, Col, Row } from "react-bootstrap";
import { Field } from "react-final-form";
import logo_pdf from "../../../../assets/img/adobe_logo.png";
import {
  resolverExibidos,
} from "../../../../components/SubForm/SubForm";
import { HiddenField } from "../../../../components/field/HiddenField";
import { RadioButtonWithFieldSetField } from "../../../../components/field/RadioButtonSetField";
import { TextAreaField } from "../../../../components/field/TextAreaField";
import Service, { AFLORA, BASEGEO, PETRO } from "../../../../service/Service";
import {
  campoObrigatorioComMsgGenerica,
} from "../../../../util/validadores";
import gerarPdfRedirecionarNovaAba from "../../../estacoes/utils/gerarPdfRedirecionarNovaAba";
import AlteracaoHidrotermal from "../../sharedComponents/AlteracaoHidrotermal";
import ComposicaoMineralogica from "../../sharedComponents/ComposicaoMineralogica";
import ConteudoMultimidiaPetro from "../../sharedComponents/ConteudoMultmidiaPetro";
import EstimativaModalComponentes from "../../sharedComponents/EstimativaModalComponentes";
import Fotomicrografia from "../../sharedComponents/Fotomicrografia";
import InformacoesAnaliseCard from "../../sharedComponents/InformacoesAnaliseCard";
import Microestruturas from "../../sharedComponents/Microestruturas";
import MineraisAlteracao from "../../sharedComponents/MineraisAlteracao";
import Petrogenese from "../../sharedComponents/Petrogenese";
import Porosidade from "../../sharedComponents/Porosidade";
import ProcessosDiageneticos from "../../sharedComponents/ProcessosDiageneticos";
import Arcabouco from "../../sharedComponents/componentesDeposicionais/arcabouco/Arcabouco";
import Cimento from "../../sharedComponents/componentesDeposicionais/cimento/Cimento";
import Matriz from "../../sharedComponents/componentesDeposicionais/matriz/Matriz";
import DiagramaClassificacaoPetrografica from "../../sharedComponents/componentesDiagrama/DiagramaClassificacaoPetrografica";
import {
  CLASSIFICACAO_INTERPRETACAO_IGNEA_METAMORFICA,
  CLASSIFICACAO_INTERPRETACAO_SEDIMENTAR,
  IGNEAS_METAMORFICAS,
  SEDIMENTARES,
} from "../../utils/contantes/constantesComuns";
import {
  buscaPercentuais,
  buscaPercentuaisParaPorosidade,
  calcularSomaPercentuais,
} from "../../utils/gerais";
import { consultarVisitasPorIdEstacao } from "../geocronologia/services/visitasService";
import { PrimaryButton } from "../../../../components/Jqx/Button";
import ClassificacaoInterpretacaoPetrograficaValidacao from "./ClassificacaoInterpretacaoPetrograficaValidacao";

const AtividadePetro = ({
  abrirFormPetrografias,
  idAmostra,
  amostras,
  onMount,
  valoresIniciais,
  mostraErroFichaPetrografica,
  coletores,
  projetos,
  areas,
  subAreas,
  isChefeProjeto,
  bloquearFichaPetrografica,
  amostraSelecionada,
  rochaAfloramento,
  permitirVisualizacao,
  permitirGerarPdf,
  permitirEdicao,
  permitirInsercao,
  amostraRelacionadaComAfloramento,
  afloramentoAssociadoAmostra,
  permitirEnviarParaRevisao,
  formProps
}) => {
  const [fichasPetrografica, setFichasPetrografica] = useState();
  const [tipoRochaElemento] = useState([IGNEAS_METAMORFICAS, SEDIMENTARES]);
  const [carregamentoGlobal, setCarregamentoGlobal] = useState(true);

  const [bbMicroestruturas, setBbMicroestruturas] = useState([]);
  const [bbUnidadeMedida, setBbUnidadeMedida] = useState([]);
  const [bbMinerais, setBbMinerais] = useState([]);
  const [bbTiposIlustracao, setBbTiposIlustracao] = useState([]);
  const [bbExtencoesIlustracao, setBbExtencoesIlustracao] = useState([]);
  const [bbClasseRocha, setBbClasseRocha] = useState([]);
  const [bbSubClasseRocha, setBbSubClasseRocha] = useState([]);
  const [bbPrefixo, setBbPrefixo] = useState([]);
  const [bbRocha, setBbRocha] = useState([]);
  const [bbComplemento, setBbComplemento] = useState([]);
  const [bbTiposMetamorfismo, setBbTiposMetamorfismo] = useState([]);
  const [bbFaciesMetamorfismo, setBbFaciesMetamorfismo] = useState([]);
  const [bbPolarizacao, setBbPolarizacao] = useState([]);
  const [bbObjetiva, setBbObjetiva] = useState([]);
  const [bbAnaliseLuz, setBbAnaliseLuz] = useState([]);
  const [bbMaturidade, setBbMaturidade] = useState([]);
  const [bbNaturezaMatriz, setBbNaturezaMatriz] = useState([]);
  const [bbCompactacao, setBbCompactacao] = useState([]);
  const [bbNaturezaCimento, setBbNaturezaCimento] = useState([]);
  const [bbEventoDiagenetico, setBbEventoDiagenetico] = useState([]);
  const [bbTipoPorosidade, setBbTipoPorosidade] = useState([]);
  const [bbProcesso, setBbProcesso] = useState([]);
  const [bbSaturacaoSilica, setBbSaturacaoSilica] = useState([]);
  const [bbTipoDiagrama, setBbTipoDiagrama] = useState([]);
  const [
    bbIntensidadeAlteracaoHidrotermal,
    setBbIntensidadeAltecaoHidrotermal,
  ] = useState([]);
  const [bbTipoAlteracaoHidrotermal, setBbTipoAltecaoHidrotermal] = useState(
    []
  );
  const [valoresIniciaisEdicao, setValoresIniciaisEdicao] = useState({});
  const [valoresIniciaisCriacao, setValoresIniciaisCriacao] = useState(valoresIniciais);
  const [exibirComponentesDeposicionais, setExibirComponentesDeposicionais] =
    useState(false);
  const [disabled,] = useState(true);
  const [subFormDiagramaVisivel, setSubFormDiagramaVisivel] = useState(true);

  const esconderDiagrama = () => {
    setSubFormDiagramaVisivel(!subFormDiagramaVisivel);
  };
  const excluirDiagrama = (formProps) => {
    const diagramas =
      formProps.form.getState().values.diagramasClassificacaoPetrografica;
    const diagramasTela = resolverExibidos(diagramas);
    const diagramaId = diagramasTela[0].id;
    if (diagramaId < 0) {
      formProps.form.mutators.setValue(
        "diagramasClassificacaoPetrografica",
        undefined
      );
    } else {
      formProps.form.mutators.setValue(
        "diagramasClassificacaoPetrografica",
        undefined
      );
      diagramas.existentes = [];
      diagramas.idsExcluidos = [diagramaId];

      formProps.form.mutators.setValue(
        "diagramasClassificacaoPetrografica",
        diagramas
      );
    }
    esconderDiagrama();
  };

  const pegarValoresIniciais = useCallback(async () => {
    const { idMetodoGeoposicionamento, idVisita } =
    amostraSelecionada;
    
    const visitasResponse = await consultarVisitasPorIdEstacao(
      amostraSelecionada.idEstacao
    );
    
      const visita = visitasResponse.find(
        (visita) => +visita.id === idVisita
      )
    const toponimia = visita?.toponimia
     
    setValoresIniciaisCriacao({
      ...valoresIniciais,
      idMetodoGeoposicionamento: idMetodoGeoposicionamento,
      toponimia: toponimia,
      idVisita: idVisita
    });

  },[amostraSelecionada, valoresIniciais]);

  useEffect(() => {
    pegarValoresIniciais()
    setValoresIniciaisEdicao({
      ...valoresIniciaisCriacao,
      idClasseRocha: rochaAfloramento?.idTipoRocha,
      idSubClasseRocha: rochaAfloramento?.idSubClasseRocha,
    });
    setCarregamentoGlobal(true);
    compatibilizarCampos();
  }, []);

  async function compatibilizarCampos() {
    try {
      const [
        bbClasseRocha,
        bbRocha,
        bbMicroestruturas,
        bbUnidadeMedida,
        bbMinerais,
        bbCompactacao,
        bbNaturezaCimento,
        bbProcesso,
        bbEventoDiagenetico,
        bbTiposIlustracao,
        bbExtencoesIlustracao,
        bbSubClasseRocha,
        bbPrefixo,
        bbComplemento,
        bbtiposMetamorfismo,
        bbFaciesMetamorfismo,
        bbPolarizacao,
        bbObjetiva,
        bbAnaliseLuz,
        bbMaturidade,
        bbNaturezaMatriz,
        bbTipoPorosidade,
        bbSaturacaoSilica,
        bbTipoDiagrama,
        bbIntensidadeAlteracaoHidrotermal,
        bbTipoAlteracaoHidrotermal
      ] = await Promise.all([
        Service("/tipoRocha", AFLORA).query(),
        Service("/rochas/nomeadas", AFLORA).query(),
        Service("/bibliotecas/microestruturas", PETRO).query(),
        Service("/bibliotecas/unidades-comprimento", BASEGEO).query(),
        Service("/minerais", BASEGEO).query(),
        Service("bibliotecas/compactacao", BASEGEO).query(),
        Service("/bibliotecas/natureza-cimento", BASEGEO).query(),
        Service("/bibliotecas/processo", BASEGEO).query(),
        Service("bibliotecas/evento-diagenetico", BASEGEO).query(),
        Service("/tiposIlustracao").query(),
        Service("/extencoesIlustracao").query(),
        Service("/subclasserocha", AFLORA).query(),
        Service("/prefixos").query(),
        Service("/complementos").query(),
        Service("/tiposMetamorfismo", AFLORA).query(),
        Service("/faciesMetamorfismo", AFLORA).query(),
        Service("/bibliotecas/polarizacao", PETRO).query(),
        Service("/bibliotecas/objetiva", PETRO).query(),
        Service("/bibliotecas/analise-luz", PETRO).query(),
        Service("/bibliotecas/maturidades").query(),
        Service("/rochas/naturezasMatriz", AFLORA).query(),
        Service("bibliotecas/tipo-porosidade", BASEGEO).query(),
        Service("bibliotecas/tipo-saturacao-silica", PETRO).query(),
        Service("bibliotecas/tipo-diagrama", PETRO).query(),
        Service("/bibliotecas/intensidade-alteracao").query(),
        Service("/bibliotecas/tipo-alteracao-hidrotermal").query()
      ]);


      setBbClasseRocha(bbClasseRocha.data.dados || []);
      setBbRocha(bbRocha.data.dados || []);

      setBbMicroestruturas(bbMicroestruturas.data.dados || []);

      const unidadeMedida = bbUnidadeMedida.data.dados || [];
      const novosObjetos = unidadeMedida.map((objeto) => ({
        id: objeto.id,
        nome: `${objeto.nome} (${objeto.sigla})`,
        sigla: objeto.sigla,
      }));
      setBbUnidadeMedida(novosObjetos);

      setBbMinerais(bbMinerais.data.dados || []);
      setBbCompactacao(bbCompactacao.data.dados || []);
      setBbNaturezaCimento(bbNaturezaCimento.data.dados || []);
      setBbProcesso(bbProcesso.data.dados || []);
      setBbEventoDiagenetico(bbEventoDiagenetico.data.dados || []);
      setBbTiposIlustracao(bbTiposIlustracao.data.dados || []);
      setBbExtencoesIlustracao(bbExtencoesIlustracao.data.dados || []);
      setBbSubClasseRocha(bbSubClasseRocha.data.dados || []);
      setBbPrefixo(bbPrefixo.data.dados || []);
      setBbComplemento(bbComplemento.data.dados || []);
      setBbTiposMetamorfismo(bbtiposMetamorfismo.data.dados || []);
      setBbFaciesMetamorfismo(bbFaciesMetamorfismo.data.dados || []);
      setBbPolarizacao(bbPolarizacao.data.dados || []);
      setBbObjetiva(bbObjetiva.data.dados || []);
      setBbAnaliseLuz(bbAnaliseLuz.data.dados || []);
      setBbMaturidade(bbMaturidade.data.dados || []);
      setBbNaturezaMatriz(bbNaturezaMatriz.data.dados || []);
      setBbTipoPorosidade(bbTipoPorosidade.data.dados || []);
      setBbSaturacaoSilica(bbSaturacaoSilica.data.dados || []);
      setBbTipoDiagrama(bbTipoDiagrama.data.dados || []);

      const intensidade = bbIntensidadeAlteracaoHidrotermal.data.dados || [];
      const novosObjetoIntensidade = intensidade.map((objeto) => ({
        id: objeto.id,
        nome: objeto.descricao,
      }));
      setBbIntensidadeAltecaoHidrotermal(novosObjetoIntensidade);

      setBbTipoAltecaoHidrotermal(bbTipoAlteracaoHidrotermal.data.dados || []);

    } catch (err) {
      console.log("Erro ao consultar bibliotecas: ", err);
    }
    setCarregamentoGlobal(false);
  }

  const resultadoFiltradoFotomicrografia =
    filtrarTiposIlustracaoParaFotomicrigrafia(bbExtencoesIlustracao);
  

  function filtrarTiposIlustracaoParaFotomicrigrafia(listaObjetos) {
    const tiposPermitidos = ["JPEG", "JPG", "PNG", "BMP"];

    const resultadoFiltrado = Array.from(
      new Set(
        listaObjetos
          .filter((objeto) => tiposPermitidos.includes(objeto.nome))
          .map((objeto) => "." + objeto.nome.toLowerCase())
      )
    );

    return resultadoFiltrado;
  }

  const geraPdfAmostra = (pdfId) => {
    const base = process.env.REACT_APP_AMOSTRAS_API_URL;
    gerarPdfRedirecionarNovaAba(pdfId, base);
  };

  const geraPdfAflora = (pdfId) => {
    const base = process.env.REACT_APP_API_URL_AFLORA;
    gerarPdfRedirecionarNovaAba(pdfId, base);
  };

  return (
    <BlockUi blocking={carregamentoGlobal}>
      <>
          <Field
            name={"petrografias"}
            subscription={{ value: true, values: true }}
          >
            {({ input: { value: petrografias = {} } }) => {
            let composicoesMineralogicas;
            if (
              formProps.form.getState().values
                ?.composicoesMineralogicas !== undefined
            ) {
              composicoesMineralogicas = resolverExibidos(
                formProps.form.getState().values
                  ?.composicoesMineralogicas
              );
            }

            let podeExcluirEstimativaModal = true;

            let mineraisAlteracao;
            if (
              formProps.form.getState().values?.mineraisAlteracao !==
              undefined
            ) {
              mineraisAlteracao = resolverExibidos(
                formProps.form.getState().values?.mineraisAlteracao
              );
              if (mineraisAlteracao.length > 0) {
                podeExcluirEstimativaModal = false;
              }
            }

            let estimativasModal;
            if (
              formProps.form.getState().values?.estimativasModal !==
              undefined
            ) {
              estimativasModal = resolverExibidos(
                formProps.form.getState().values?.estimativasModal
              );
            }
            let estimativaTemArcabouco = false;
            let estimativaTemMatriz = false;
            let estimativaPorosidade = false;
            let estimativaMineralAlteracao = false;

            if (estimativasModal && estimativasModal.length > 0) {
              estimativaTemArcabouco =
                estimativasModal[0]?.arcabouco > 0;
              estimativaTemMatriz = estimativasModal[0]?.matriz > 0;
              estimativaPorosidade = estimativasModal[0]?.poros > 0;
              estimativaMineralAlteracao =
                estimativasModal[0]?.mineraisAlteracao > 0;
            }

            let estimativaModalEmTela = false;
            if (estimativasModal) {
              estimativaModalEmTela = estimativasModal.length > 0;
            }

            const somaTotalPercentuais = calcularSomaPercentuais(
              buscaPercentuais(composicoesMineralogicas)
            );
            const somaTotalPercentuaisMineraisAlteracao =
              calcularSomaPercentuais(
                buscaPercentuais(mineraisAlteracao)
              );
            const somaTotalPercentuaisEstimativaModal =
              calcularSomaPercentuais(
                buscaPercentuais(estimativasModal)
              );

            let porosidadeTela;
            if (formProps.form.getState().values?.porosidades) {
              porosidadeTela = resolverExibidos(
                formProps.form.getState().values?.porosidades
              );
              if (porosidadeTela.length > 0) {
                podeExcluirEstimativaModal = false;
              }
            }
            const somaTotalPercentuaisPorosidade =
              calcularSomaPercentuais(
                buscaPercentuaisParaPorosidade(porosidadeTela)
              );

            let arcaboucosTela;
            let exibeArcabouco = false;
            if (formProps.form.getState().values?.arcaboucos) {
              arcaboucosTela = resolverExibidos(
                formProps.form.getState().values?.arcaboucos
              );
              if (arcaboucosTela.length > 0) {
                exibeArcabouco = true;
                podeExcluirEstimativaModal = false;
              }
            }
            let displayArcabouco = "none";
            if (exibeArcabouco || exibirComponentesDeposicionais) {
              displayArcabouco = "block";
            }

            let matrizesTela;
            let exibeMatriz = false;
            if (formProps.form.getState().values?.matrizes) {
              matrizesTela = resolverExibidos(
                formProps.form.getState().values?.matrizes
              );
              if (matrizesTela.length > 0) {
                exibeMatriz = true;
                podeExcluirEstimativaModal = false;
              }
            }
            let displayMatriz = "none";
            if (exibeMatriz || exibirComponentesDeposicionais) {
              displayMatriz = "block";
            }

            let estimativaTemCimento = false;
            if (estimativasModal) {
              estimativaTemCimento = estimativasModal[0]?.cimento > 0;
            }

            let cimentoTela;
            let exibeCimentos = false;
            if (formProps.form.getState().values?.cimentos) {
              cimentoTela = resolverExibidos(
                formProps.form.getState().values?.cimentos
              );
              if (cimentoTela.length > 0) {
                exibeCimentos = true;
                podeExcluirEstimativaModal = false;
              }
            }

            let displayCimento = "none";
            if (exibeCimentos || exibirComponentesDeposicionais) {
              displayCimento = "block";
            }

            let numeroLamina = formProps.form.getState().values?.lamina;

            let coletor = coletores.filter(
              (coletor) => coletor.id === valoresIniciaisCriacao?.idResponsavel
            );
            let petrografo = coletor[0]?.nome;

            let dataAnalise =
              formProps.form.getState().values?.dataAnalise;

            let exibeDiagramas = false;
            if (
              formProps.form.getState().values
                ?.diagramasClassificacaoPetrografica
            ) {
              let diagramasTela = resolverExibidos(
                formProps.form.getState().values
                  ?.diagramasClassificacaoPetrografica
              );
              if (diagramasTela.length > 0) {
                exibeDiagramas = true;
              }
            }
            const idProjetoCorrente =
              formProps.form.getState().values?.idProjetoCorrente;
            const idArea = formProps.form.getState().values?.idArea;
            const idSubArea =
              formProps.form.getState().values?.idSubArea;
            const projeto = projetos.find(
              (p) => p.id === idProjetoCorrente
            );
            const area = areas.find((p) => p.id === idArea);
            const subArea = subAreas.find((p) => p.id === idSubArea);
            const nomeProjeto = projeto?.nome ? projeto?.nome : "";
            const nomeArea = area?.nome ? area?.nome : "";
            const nomeSubArea = subArea?.nome ? subArea?.nome : "";

            const projetoAreaSubArea =
              nomeProjeto + "/" + nomeArea + "/" + nomeSubArea;

            const afloraId = afloramentoAssociadoAmostra?.idAtividade;
            const temFloramento = !!afloraId;

            let podeInserirDiagrama =
              formProps.form.getState().values
                ?.classificacaoInterpretacaoPetrograficaIgneaMetamorfica
                ?.idClasseRocha == 1;
            if (!podeInserirDiagrama) {
              try {
                const diagramas =
                  formProps.form.getState().values
                    .diagramasClassificacaoPetrografica;
                if (diagramas) {
                  const diagramasTela = resolverExibidos(diagramas);
                  const diagramaId = diagramasTela[0]?.id;
                  if (diagramaId) {
                    if (diagramaId < 0) {
                      formProps.form.mutators.setValue(
                        "diagramasClassificacaoPetrografica",
                        undefined
                      );
                    } else {
                      formProps.form.mutators.setValue(
                        "diagramasClassificacaoPetrografica",
                        undefined
                      );
                      diagramas.existentes = [];
                      diagramas.idsExcluidos = [diagramaId];
                      formProps.form.mutators.setValue(
                        "diagramasClassificacaoPetrografica",
                        diagramas
                      );
                    }
                  }
                }
              } catch (error) {
                console.log("Erro ao excluir diagrama", error);
              }
            }
            return (
              <>
                {mostraErroFichaPetrografica && (
                  <>
                    <span
                      className="erro-campo"
                      style={{ color: "red", fontSize: "10px" }}
                    >
                      Este afloramento contem cadastro de rocha
                      sedimentar com ígnea/metamórfica. Neste caso o
                      usuário deve escolher a ficha petrográfica.
                    </span>
                  </>
                )}
                <Row>
                  <Field
                    name="fichaPetrografica"
                    subscription={{ value: true }}
                  >
                    {({ input: { value: fichaPetrografica = {} } }) => {

                      setFichasPetrografica(fichaPetrografica);
                      return (
                        <Col md={6}>
                          <Field
                            name="fichaPetrografica"
                            disabled={
                              bloquearFichaPetrografica || disabled
                            }
                            component={RadioButtonWithFieldSetField}
                            label="Ficha Petrográfica"
                            elementos={tipoRochaElemento}
                            horizontal
                            validate={campoObrigatorioComMsgGenerica(
                              "Ficha Petrográfica"
                            )}
                          />
                        </Col>
                      );
                    }}
                  </Field>
                  <Col md={3}>
                    <button
                      className="pdf-button"
                      onClick={() => geraPdfAmostra(idAmostra)}
                    >
                      <img
                        src={logo_pdf}
                        alt="PDF Icon"
                        className="pdf-icon"
                      />
                      <span className="amostra-text">Amostra</span>
                    </button>
                  </Col>
                  <Col md={3}>
                    <button
                      className="pdf-button"
                      onClick={() => geraPdfAflora(afloraId)}
                      disabled={!temFloramento}
                    >
                      <img
                        src={logo_pdf}
                        alt="PDF Icon"
                        className="pdf-icon"
                      />
                      <span className="amostra-text">Afloramento</span>
                    </button>
                  </Col>
                </Row>
                <Field
                  component={HiddenField}
                  name="idProjetoCorrente"
                />
                <Field
                  component={HiddenField}
                  name="idProjetoHistorico"
                />
                <Field component={HiddenField} name="idArea" />
                <Field component={HiddenField} name="idSubArea" />
                <InformacoesAnaliseCard
                  setCarregamentoGlobal={setCarregamentoGlobal}
                  form={formProps.form}
                  coletores={coletores}
                  isChefeProjeto={isChefeProjeto}
                  idAmostra={idAmostra}
                  valoresIniciais={valoresIniciaisEdicao}
                  disabled={disabled}
                  projetoAreaSubArea={projetoAreaSubArea}
                />
                <Card className="mt-3">
                  <Card.Body>
                    <Row className="mb-3">
                      <Col md={12}>
                        <Card.Title>
                          Características mesoscópicas
                        </Card.Title>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col md={12}>
                        <Field
                          component={TextAreaField}
                          name={`descricaoCaracteristicasMesoscopicas`}
                          label="Descrição das características mesoscópicas"
                          dica="Descrever a amostra macroscópica (amostra de mão). Complementar com informações relevantes que foram  levantadas no afloramento."
                          maxLength={2000}
                          disabled={disabled}
                        />
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
                {fichasPetrografica === IGNEAS_METAMORFICAS.id && (
                  <>
                    <ComposicaoMineralogica
                      form={formProps.form}
                      permitirEdicao={false}
                      bbMinerais={bbMinerais}
                      bbUnidadeMedida={bbUnidadeMedida}
                      somaTotalPercentuais={somaTotalPercentuais}
                      disabled={disabled}
                      podeExcluir={() => {return false}}
                    />
                    <Microestruturas
                      form={formProps.form}
                      permitirEdicao={false}
                      bbMicroestruturas={bbMicroestruturas}
                      disabled={disabled}
                    />
                    <AlteracaoHidrotermal
                      tiposAlteracaoHidrotermal={
                        bbTipoAlteracaoHidrotermal
                      }
                      intensidadesAlteracaoHidrotermal={
                        bbIntensidadeAlteracaoHidrotermal
                      }
                      form={formProps.form}
                      disabled={disabled}
                    />
                    <MineraisAlteracao
                      form={formProps.form}
                      somaTotalPercentuais={
                        somaTotalPercentuaisMineraisAlteracao
                      }
                      bbMineraisAlteracao={bbMinerais}
                      disabled={disabled}
                    />
                    <ClassificacaoInterpretacaoPetrograficaValidacao
                      formProps={formProps}
                      permitirEdicao={false}
                      bbClasseRocha={bbClasseRocha}
                      bbSubclasseRocha={bbSubClasseRocha}
                      bbPrefixo={bbPrefixo}
                      bbRocha={bbRocha}
                      bbComplemento={bbComplemento}
                      bbTiposMetamorfismo={bbTiposMetamorfismo}
                      bbFaciesMetamorfismo={bbFaciesMetamorfismo}
                      bbMicroestruturas={bbMicroestruturas}
                      bbMaturidade={bbMaturidade}
                      bbMinerais={bbMinerais}
                      rochaAfloramento={rochaAfloramento}
                      prefixoNome={
                        CLASSIFICACAO_INTERPRETACAO_IGNEA_METAMORFICA
                      }
                      isIgneaMetamorfica={true}
                      disabled={disabled}
                      amostraRelacionadaComAfloramento={
                        amostraRelacionadaComAfloramento
                      }
                    />

                    {subFormDiagramaVisivel && podeInserirDiagrama && (
                      <DiagramaClassificacaoPetrografica
                        bbSaturacaoSilica={bbSaturacaoSilica}
                        bbTipoDiagrama={bbTipoDiagrama}
                        bbMinerais={bbMinerais}
                        composicoesMineralogicas={
                          composicoesMineralogicas
                        }
                        disabled={disabled}
                        numeroLamina={numeroLamina}
                        valorProjetoAreaSubarea={
                          valoresIniciaisEdicao?.projetoAreaSubarea
                        }
                        nomeAmostra={valoresIniciaisEdicao?.nomeAmostra}
                        dataAnalise={dataAnalise}
                        petrografo={petrografo}
                        exibirBotaoInsercao={exibeDiagramas}
                        esconderDiagrama={esconderDiagrama}
                        excluirDiagrama={() => {
                          excluirDiagrama(formProps);
                        }}
                      />
                    )}
                    {!subFormDiagramaVisivel && podeInserirDiagrama && (
                      <DiagramaClassificacaoPetrografica
                        bbSaturacaoSilica={bbSaturacaoSilica}
                        bbTipoDiagrama={bbTipoDiagrama}
                        bbMinerais={bbMinerais}
                        composicoesMineralogicas={
                          composicoesMineralogicas
                        }
                        disabled={disabled}
                        numeroLamina={numeroLamina}
                        valorProjetoAreaSubarea={
                          valoresIniciaisEdicao?.projetoAreaSubarea
                        }
                        nomeAmostra={valoresIniciaisEdicao?.nomeAmostra}
                        dataAnalise={dataAnalise}
                        petrografo={petrografo}
                        exibirBotaoInsercao={exibeDiagramas}
                        esconderDiagrama={esconderDiagrama}
                        excluirDiagrama={() => {
                          excluirDiagrama(formProps);
                        }}
                      />
                    )}
                    <Fotomicrografia
                      amostraSelecionada={amostraSelecionada}
                      tiposIlustracao={bbTiposIlustracao}
                      extencoesIlustracao={
                        resultadoFiltradoFotomicrografia
                      }
                      bbPolarizacao={bbPolarizacao}
                      bbAnaliseLuz={bbAnaliseLuz}
                      bbObjetiva={bbObjetiva}
                      disabled={disabled}
                    />
                    <ConteudoMultimidiaPetro
                      extencoesIlustracao={bbExtencoesIlustracao}
                      tiposIlustracao={bbTiposIlustracao}
                      amostraSelecionada={amostraSelecionada}
                      disabled={disabled}
                    />
                  </>
                )}
                {fichasPetrografica === SEDIMENTARES.id && (
                  <>
                    {estimativaModalEmTela && (
                      <EstimativaModalComponentes
                        form={formProps.form}
                        somaTotalPercentuais={
                          somaTotalPercentuaisEstimativaModal
                        }
                        disabled={disabled}
                        permitirInsercao={false}
                        podeExcluir={() => {return false}}
                      />
                    )}

                    {!estimativaModalEmTela && (
                      <EstimativaModalComponentes
                        form={formProps.form}
                        somaTotalPercentuais={
                          somaTotalPercentuaisEstimativaModal
                        }
                        disabled={disabled}
                        permitirInsercao={true}
                        podeExcluir={() => {return false}}
                      />
                    )}

                    <Card className="mt-3">
                      <Card.Body>
                        <Row className="mb-3">
                          <Col md={12}>
                            <Card.Title>
                              Componentes deposicionais
                            </Card.Title>
                          </Col>
                        </Row>
                        {!exibirComponentesDeposicionais &&
                          !disabled && (
                            <Row>
                              <Col md={12}>
                                <PrimaryButton
                                  className={`float-right mb-2 subform-btn-adicionar subform-btn-adicionar-deposicionais`}
                                  onClick={() =>
                                    setExibirComponentesDeposicionais(
                                      true
                                    )
                                  }
                                  titulo="Adicionar"
                                  disabled={disabled}
                                />
                              </Col>
                            </Row>
                          )}

                        {estimativaTemArcabouco && (
                          <Arcabouco
                            form={formProps.form}
                            bbComplemento={bbComplemento}
                            bbMinerais={bbMinerais}
                            bbPrefixo={bbPrefixo}
                            bbRocha={bbRocha}
                            bbUnidadeMedida={bbUnidadeMedida}
                            disabled={disabled}
                            exibeArcabouco={displayArcabouco}
                            permitirInsercao={true}
                            jaCadastrouArcabouco={exibeArcabouco}
                          />
                        )}
                        {!estimativaTemArcabouco && (
                          <Arcabouco
                            form={formProps.form}
                            bbComplemento={bbComplemento}
                            bbMinerais={bbMinerais}
                            bbPrefixo={bbPrefixo}
                            bbRocha={bbRocha}
                            bbUnidadeMedida={bbUnidadeMedida}
                            disabled={disabled}
                            exibeArcabouco={displayArcabouco}
                            permitirInsercao={false}
                            jaCadastrouArcabouco={exibeArcabouco}
                          />
                        )}
                        {estimativaTemMatriz && (
                          <Matriz
                            form={formProps.form}
                            bbMinerais={bbMinerais}
                            bbUnidadeMedida={bbUnidadeMedida}
                            bbNaturezaMatriz={bbNaturezaMatriz}
                            disabled={disabled}
                            exibeMatriz={displayMatriz}
                            permitirInsercao={true}
                            jacadastrouMatriz={exibeMatriz}
                          />
                        )}
                        {!estimativaTemMatriz && (
                          <Matriz
                            form={formProps.form}
                            bbMinerais={bbMinerais}
                            bbUnidadeMedida={bbUnidadeMedida}
                            bbNaturezaMatriz={bbNaturezaMatriz}
                            disabled={disabled}
                            exibeMatriz={displayMatriz}
                            permitirInsercao={false}
                            jacadastrouMatriz={exibeMatriz}
                          />
                        )}

                        {estimativaTemCimento && (
                          <Cimento
                            form={formProps.form}
                            bbNaturezaCimento={bbNaturezaCimento}
                            disabled={disabled}
                            exibirComponentesDeposicionais={
                              exibirComponentesDeposicionais
                            }
                            permitirEdicao={permitirEdicao}
                            exibirCimento={displayCimento}
                            permitirInsercao={true}
                            jaCadastrouCimento={exibeCimentos}
                          />
                        )}
                        {!estimativaTemCimento && (
                          <Cimento
                            form={formProps.form}
                            bbNaturezaCimento={bbMinerais}
                            disabled={disabled}
                            exibirComponentesDeposicionais={
                              exibirComponentesDeposicionais
                            }
                            permitirEdicao={permitirEdicao}
                            exibirCimento={displayCimento}
                            permitirInsercao={false}
                            jaCadastrouCimento={exibeCimentos}
                          />
                        )}

                        {exibirComponentesDeposicionais && (
                          <Row>
                            <Col md={12}>
                              <PrimaryButton
                                className={`float-right mb-2 subform-btn-adicionar subform-btn-adicionar-deposicionais`}
                                onClick={() =>
                                  setExibirComponentesDeposicionais(
                                    false
                                  )
                                }
                                titulo="Voltar"
                              />
                            </Col>
                          </Row>
                        )}
                      </Card.Body>
                    </Card>

                    {estimativaPorosidade && (
                      <Porosidade
                        form={formProps.form}
                        bbTipoPorosidade={bbTipoPorosidade}
                        disabled={disabled}
                        somaTotalPercentuaisPorosidade={
                          somaTotalPercentuaisPorosidade
                        }
                        permitirInsercao={true}
                      />
                    )}
                    {!estimativaPorosidade && (
                      <Porosidade
                        form={formProps.form}
                        bbTipoPorosidade={bbTipoPorosidade}
                        disabled={disabled}
                        permitirInsercao={false}
                      />
                    )}

                    {estimativaMineralAlteracao && (
                      <MineraisAlteracao
                        form={formProps.form}
                        somaTotalPercentuais={
                          somaTotalPercentuaisMineraisAlteracao
                        }
                        bbMineraisAlteracao={bbMinerais}
                        permitirInsercao={true}
                        disabled={disabled}
                      />
                    )}

                    {!estimativaMineralAlteracao && (
                      <MineraisAlteracao
                        form={formProps.form}
                        somaTotalPercentuais={
                          somaTotalPercentuaisMineraisAlteracao
                        }
                        bbMineraisAlteracao={bbMinerais}
                        permitirInsercao={false}
                        disabled={disabled}
                      />
                    )}
                    <AlteracaoHidrotermal
                      tiposAlteracaoHidrotermal={
                        bbTipoAlteracaoHidrotermal
                      }
                      intensidadesAlteracaoHidrotermal={
                        bbIntensidadeAlteracaoHidrotermal
                      }
                      form={formProps.form}
                      disabled={disabled}
                    />
                    <ProcessosDiageneticos
                      form={formProps.form}
                      bbCompactacao={bbCompactacao}
                      bbEstagioDiagenetico={bbEventoDiagenetico}
                      disabled={disabled}
                    />
                    <Petrogenese
                      formProps={formProps}
                      bbProcesso={bbProcesso}
                      disabled={disabled}
                    />
                    <ClassificacaoInterpretacaoPetrograficaValidacao
                      formProps={formProps}
                      permitirEdicao={false}
                      bbClasseRocha={bbClasseRocha}
                      bbSubclasseRocha={bbSubClasseRocha}
                      bbPrefixo={bbPrefixo}
                      bbRocha={bbRocha}
                      bbComplemento={bbComplemento}
                      bbTiposMetamorfismo={bbTiposMetamorfismo}
                      bbFaciesMetamorfismo={bbFaciesMetamorfismo}
                      bbMicroestruturas={bbMicroestruturas}
                      bbMaturidade={bbMaturidade}
                      rochaAfloramento={rochaAfloramento}
                      prefixoNome={
                        CLASSIFICACAO_INTERPRETACAO_SEDIMENTAR
                      }
                      isIgneaMetamorfica={false}
                      disabled={disabled}
                      amostraRelacionadaComAfloramento={
                        amostraRelacionadaComAfloramento
                      }
                    />
                    <Fotomicrografia
                      amostraSelecionada={amostraSelecionada}
                      tiposIlustracao={bbTiposIlustracao}
                      extencoesIlustracao={
                        resultadoFiltradoFotomicrografia
                      }
                      bbPolarizacao={bbPolarizacao}
                      bbAnaliseLuz={bbAnaliseLuz}
                      bbObjetiva={bbObjetiva}
                      disabled={disabled}
                    />
                    <ConteudoMultimidiaPetro
                      extencoesIlustracao={bbExtencoesIlustracao}
                      tiposIlustracao={bbTiposIlustracao}
                      amostraSelecionada={amostraSelecionada}
                      disabled={disabled}
                    />
                  </>
                )}
              </>
                  );
            }}
                
              
            
          </Field>  
      </>
    </BlockUi>
  );
};

export default AtividadePetro;
