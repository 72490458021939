import isString from "lodash/isString"
import isNumber from "lodash/isNumber"
import isBoolean from "lodash/isBoolean"
import isNull from "lodash/isNull"
import isArray from "lodash/isArray"
import isPlainObject from "lodash/isPlainObject"
import isEmpty from "lodash/isEmpty"
import isUndefined from "lodash/isUndefined"

// https://stackoverflow.com/a/58314822/7988516
function flattenObject(o, prefix = '', result = {}, keepNull = true, keepUndefined = true) {
  if (isString(o) || isNumber(o) || isBoolean(o) || (keepNull && isNull(o)) || (keepUndefined && isUndefined(o))) {
    result[prefix] = o;
    return result;
  }

  if (isArray(o) || isPlainObject(o)) {
    for (let i in o) {
      let pref = prefix;
      if (isArray(o)) {
        pref = pref + `[${i}]`;
      } else {
        if (isEmpty(prefix)) {
          pref = i;
        } else {
          pref = prefix + '.' + i;
        }
      }
      flattenObject(o[i], pref, result, keepNull);
    }
    return result;
  }
  return result;
}

export {flattenObject}
