import Service from "../service/Service";

type RecminParameters = {
  parametros?: Object,
};

type MultiRecminParameters = {
  uri: string;
  /**
   * Opcional, objeto que será enviado como parâmetros nesta request.
   */
  parameters?: Object;
  /**
   * O resultado desta request estará presente com este nome no objeto retornado.
   */
  propertyName: string;
  /**
   * Se este campo estiver presente, a request será feita para este módulo,
   * ignorando o nome da função que foi chamada originalmente. Por exemplo, se
   * este objeto contém moduleName: "recmin", a request será feita para o recmin
   * mesmo se o método chamado for o resolverMultiRequestReminAflora.
   */
  moduleName?: "recmin" | "aflora" | "litoestatigrafia" | "basegeo" | "amostras";
};

type MultiRecminParametersObject = {
  [key: string]: {
    uri: string;
    /**
     * Opcional, objeto que será enviado como parâmetros nesta request.
     */
    parameters?: Object;
    /**
     * Se este campo estiver presente, a request será feita para este módulo,
     * ignorando o nome da função que foi chamada originalmente. Por exemplo, se
     * este objeto contém moduleName: "recmin", a request será feita para o recmin
     * mesmo se o método chamado for o resolverMultiRequestReminAflora.
     */
    moduleName?: "recmin" | "aflora" | "litoestatigrafia" | "basegeo" | "amostras";
  };
};

type MultiRecminResponse = {
  [key: string]: any,
}

export const resolverRequestRecmin = async (uri: string, params: RecminParameters = {}) => {
  let request = await Service(uri, "recmin").query(params.parametros);
  let dados = request.data.dados;
  return dados;
}

export const resolverRequestRecminAflora = async (uri, params: RecminParameters = {}) => {
  let request = await Service(uri, "aflora").query(params.parametros);
  let dados = request.data.dados;
  return dados;
}

export const resolverRequestRecminLitoestratigrafia = async (uri, params: RecminParameters = {}) => {
  let request = await Service(uri, "litoestatigrafia").query(params.parametros);
  let dados = request.data.dados;
  return dados;
}


export const criarServicoRecmin = async (uri, params: RecminParameters = {}) => {
  return Service(uri, "recmin");
}

/// Versões que permitem múltiplas requests.

/**
 * Realiza múltiplas requests ao recmin em paralelo e retorna um objeto com todas as responses.
 * 
 * @param parameters Os parâmetros podem ser informados na forma de um objeto ou um array.
 * Se for um objeto, cada valor deve conter {uri, parameters?, moduleName?}, e o objeto retornado terá
 * os mesmos nomes de propriedades, com os resultados das requests em cada campo.
 * Se for um array, cada item deve conter {uri, parâmetros?, propertyName, moduleName}, onde
 * propertyName decide qual será o nome da propriedade no objeto retornado.
 * @returns Objeto contendo todas as responses. O objeto só existirá se todas as requests
 * completarem com sucesso, caso contrário a promise irá rejeitar. Os nomes das propriedades
 * desde objeto retornado será de acordo com os parâmetros.
 */
export const resolverMultiRequestRecmin = async (parameters: MultiRecminParametersObject | MultiRecminParameters[]): Promise<MultiRecminResponse> => {
  return _resolverMultiRequest("recmin", parameters);
}

/**
 * Realiza múltiplas requests ao recmin, módulo aflora, e retorna um objeto com todas as responses.
 * @param parameters Os parâmetros podem ser informados na forma de um objeto ou um array.
 * Se for um objeto, cada valor deve conter {uri, parameters?, moduleName?}, e o objeto retornado terá
 * os mesmos nomes de propriedades, com os resultados das requests em cada campo.
 * Se for um array, cada item deve conter {uri, parâmetros?, propertyName, moduleName}, onde
 * propertyName decide qual será o nome da propriedade no objeto retornado.
 * @returns Objeto contendo todas as responses. O objeto só existirá se todas as requests
 * completarem com sucesso, caso contrário a promise irá rejeitar. Os nomes das propriedades
 * desde objeto retornado será de acordo com os parâmetros.
 */
export const resolverMultiRequestReminAflora = async (parameters: MultiRecminParametersObject | MultiRecminParameters[]): Promise<MultiRecminResponse> => {
  return _resolverMultiRequest("aflora", parameters);
}

/**
 * Realiza múltiplas requests ao recmin, módulo litoestratigrafia, e retorna um objeto com todas as responses.
 * @param parameters Os parâmetros podem ser informados na forma de um objeto ou um array.
 * Se for um objeto, cada valor deve conter {uri, parameters?, moduleName?}, e o objeto retornado terá
 * os mesmos nomes de propriedades, com os resultados das requests em cada campo.
 * Se for um array, cada item deve conter {uri, parâmetros?, propertyName, moduleName}, onde
 * propertyName decide qual será o nome da propriedade no objeto retornado.
 * @returns Objeto contendo todas as responses. O objeto só existirá se todas as requests
 * completarem com sucesso, caso contrário a promise irá rejeitar. Os nomes das propriedades
 * desde objeto retornado será de acordo com os parâmetros.
 */
export const resolverMultiRequestRecminLitoestratigrafia = async (parameters: MultiRecminParametersObject | MultiRecminParameters[]): Promise<MultiRecminResponse> => {
  return _resolverMultiRequest("litoestatigrafia", parameters);
}

const _resolverMultiRequest = async (
  moduleName: string,
  parameters: MultiRecminParametersObject | MultiRecminParameters[]
): Promise<MultiRecminResponse> => {
  const parametersArray: MultiRecminParameters[] = [];
  if (Array.isArray(parameters)) {
    // If the input is already an array, just use the values in it.
    parametersArray.push(...parameters);
  } else {
    // Otherwise, the input needs to be converted to an array.
    for (var pName of Object.keys(parameters)) {
      // We're relying on 'parameters' not being a "bigger" object with other properties.
      // That shouldn't be the case, though, since the calling code is expected to create
      // the object just for the function calls in this module.
      parametersArray.push({
        propertyName: pName,
        uri: parameters[pName].uri,
        parameters: parameters[pName].parameters,
        moduleName: parameters[pName].moduleName,
      });
    }
  }

  // Now we have a list of requests to make. The responses
  const result: MultiRecminResponse = {};

  // This function will await until all of the requests finish, since we want to
  // extract data from them, but we'll await all requests in parallel.
  // If any of the requests fail, everything will fail. That's the behavior of
  // the Promise.all function.
  try {
    // We'll start the requests using our own function, which has caching and
    // knows how to handle repeated requests.
    const allResponses = await Promise.all(
      parametersArray.map((reqParam) =>
        _startRecminRequest(reqParam.moduleName ?? moduleName, reqParam.uri, reqParam.parameters ?? null),
      )
    );
    // Now, we'll make an object out of these responses, using the property names in
    // each item in the input.
    for (var i = 0; i < parametersArray.length; i++) {
      result[parametersArray[i].propertyName] =
        allResponses[i];
    }
  } catch (e) {
    // On any error, just throw the thing that failed.
    console.warn("Error in multi request:")
    console.warn(e);
    throw e;
  }

  return result;
};


/// Funções que permitem requests simultâneas


/**
 * Inicia uma request e retorna uma promise que resolverá com a response. O valor
 * retornado pode ter sido lido do cache, e neste caso a response já estará resolvida.
 */
async function _startRecminRequest(moduleName: string, uri: string, requestParams: Object | null): Promise<any> {
  // Primeiro, verificamos se o objeto que queremos já está presente no cache.
  const key = `${moduleName}|${uri}|${requestParams ? JSON.stringify(requestParams) : "null"}`;
  const cacheValue = _requestCache[key];

  // Se está no cache, retornamos esse valor.
  if (cacheValue) {
    return cacheValue;
  }
  // Mesmo se não tem valor no cache, pode haver uma request que já está em
  // progresso. Não faça chamadas com await aqui, para não "perder" uma request
  // que está terminando.
  const promiseInProgress = _findRequestInProgress(moduleName, uri, requestParams);

  // Quando tem uma request em progresso, retornamos uma Promise que resolva
  // com o resultado dessa request.
  if (promiseInProgress !== null) {
    return promiseInProgress.then((result) => {
      return result.data.dados;
    });
  }

  // Se não tem cache nem request em progresso, vamos iniciar uma request e
  // colocar sua promise na lista de requests em progresso.
  return _startRequest(moduleName, uri, requestParams);
}

/**
 * Inicia uma request. Diferente do _startRecminRequest, esta função sempre inicia
 * uma request nova, sem verificar o cache.
 */
async function _startRequest(moduleName: string, uri: string, requestParams: Object | null): Promise<any> {
  // Esta é a promise que corresponde à request.
  const promise = Service(uri, moduleName).query(requestParams ?? undefined);
  // Primeiro, vamos indicar que esta request está em progresso.
  // (Aqui também removemos qualquer request duplicada que já esteja nesta lista,
  // mas isso não deve acontecer).
  _deleteRequestInProgress(moduleName, uri, requestParams);
  _requestsInProgress.push({
    moduleName,
    uri,
    parameters: requestParams ?? undefined,
    promisedValue: promise,
  });

  // E agora vamos dar um await na request, e no futuro quando essa request
  // finalizar, tiramos a request da lista de requests em progresso e colocamos
  // no cache.
  // Enquanto este await não terminar, essa função fica pausada e deixa o
  // processo livre para executar código em outros lugares.
  try {
    const result = await promise;
    _deleteRequestInProgress(moduleName, uri, requestParams);


      const key = `${moduleName}|${uri}|${requestParams ? JSON.stringify(requestParams) : "null"}`;
    _requestCache[key] = result.data.dados;

    return result.data.dados;
  } catch (e) {
    _deleteRequestInProgress(moduleName, uri, requestParams);
    throw e;
  }

}


/// Lógica de cache

// Guardamos aqui as requests que estão em progresso, para não acabarmos
// iniciando requests idênticas a alguma que já está acontecendo.
const _requestsInProgress: {
  moduleName: string,
  uri: string,
  parameters?: Object,
  promisedValue: Promise<any>,
}[] = [];

function _findRequestInProgress(moduleName: string, uri: string, requestParams: Object | null): Promise<any> | null {
  for (var i = 0; i < _requestsInProgress.length; i++) {
    if (_requestsInProgress[i].moduleName === moduleName && _requestsInProgress[i].uri && JSON.stringify(_requestsInProgress[i]) === JSON.stringify(requestParams)) {
      return _requestsInProgress[i].promisedValue;
    }
  }
  return null
}

/**
 * Remove um item da lista de requests em progresso, retornando true se um item foi deletado e false se não foi.
 * Esta função não cancela a promise que for removida.
 */
function _deleteRequestInProgress(moduleName: string, uri: string, requestParams: Object | null): boolean {
  for (var i = 0; i < _requestsInProgress.length; i++) {
    if (_requestsInProgress[i].moduleName === moduleName && _requestsInProgress[i].uri && JSON.stringify(_requestsInProgress[i]) === JSON.stringify(requestParams)) {
      _requestsInProgress.splice(i, 1);
      return true;
    }
  }
  return false;
}

// Objeto que contém as responses, mas somente as responses que não rejeitaram.
// A chave está no formato "módulo|uri|parametros", onde os parâmetros passam
// por um JSON.stringify ou é a string "null" se não houver parâmetros.
// O formato da chave não é muito importante, só precisa ser criado da mesma
// maneira entre chamadas diferentes. Este objeto morre ao fechar uma sessão.
const _requestCache: { 
  [key: string]: any,
} = {};


