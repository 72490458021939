import React, { PureComponent } from "react";
import { decoradorSirgas2000 } from "../../../../util/decoradores/decoradorMapa";
import { Field, Form as FForm } from "react-final-form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { HiddenField } from "../../../../components/field/HiddenField";
import { Observable, of, Subject, Subscription } from "rxjs";
import { CaracterizacaoAmbienteSectionForm } from "./sections/CaracterizacaoAmbienteSectionForm";
import { AguaSectionForm } from "./sections/AguaSectionForm";
import { ConcentradoBateiaSectionForm } from "./sections/ConcentradoBateiaSectionForm";
import { CrostaSectionForm } from "./sections/CrostaSectionForm";
import { MinerioSectionForm } from "./sections/MinerioSectionForm";
import { OutrosSectionForm } from "./sections/OutrosSectionForm";
import { OverbankSectionForm } from "./sections/OverbankSectionForm";
import { RochaSectionForm } from "./sections/RochaSectionForm";
import { SedimentoCorrenteSectionForm } from "./sections/SedimentoCorrenteSectionForm";
import { SoloSectionForm } from "./sections/SoloSectionForm";
import { VegetacaoSectionForm } from "./sections/VegetacaoSectionForm";
import InformacoesCoordenadas from "../mapa/InformacoesCoordenadas";
import { PrimaryButton } from "../../../../components/Jqx/Button";
import geoquimicaService from "../../../../service/GeoquimicaService";
import { mostrarNotificacao } from "../../../../reducers/notificacaoReducer";
import { connect } from "react-redux";

import { configMapaGeoquimica } from "../../../../config/mapa";

import {
  AbstractSampleGeoquimicaModelLike,
  ActivityGeoquimicaModelLike,
  BateiaSampleGeoquimicaModelLike,
  MinerioSampleGeoquimicaModelLike,
  OutrosSampleGeoquimicaModelLike,
  OverbankSampleGeoquimicaModelLike,
  RochaSampleGeoquimicaModelLike,
  SedimentoCorrenteSampleGeoquimicaModelLike,
  SoloSampleGeoquimicaModelLike,
  VegetacaoSampleGeoquimicaModelLike,
  WaterSampleGeoquimicaModelLike,
} from "../../../../models/activity-geoquimica.model";
import { takeUntil } from "rxjs/operators";
import * as _ from "lodash";

interface GeoquimicaFormProps {
  idProjeto: number;
  idVisita: number;
  idAtividade?: number;
  datums?: any[];
  fusos?: any[];
  pontosAtribuidosAEstacao?: any[];
  onBtnGoBackClick?: () => void;
  enviarNotificacaoError?: (msg: string) => void;
}

interface GeoquimicaFormState {
  initialModel: ActivityGeoquimicaModelLike;
  clonedModel: ActivityGeoquimicaModelLike;
}

const amostraKeys: Array<keyof ActivityGeoquimicaModelLike> = [
  "amostraAgua",
  "amostraBateia",
  "amostraCrosta",
  "amostraSolo",
  "amostraOutros",
  "amostraOverBank",
  "amostraRocha",
  "amostraSedimentoCorrente",
  "amostraSolo",
  "amostraVegetacao",
  "amostraMinerios",
];

let GeoquimicaFormInternal = class GeoquimicaForm extends PureComponent<
  GeoquimicaFormProps,
  GeoquimicaFormState
> {
  private atividadeSubscription!: Subscription;

  private lifecycle = new Subject();

  constructor(props) {
    super(props);

    const initialModel = this.defaultInitialModelFactory();
    const clonedModel = this.handleInitialModel(initialModel);
    this.state = {
      initialModel,
      clonedModel,
    };
  }

  componentDidMount() {
    geoquimicaService.idProjeto = this.props.idProjeto;

    this.loadAtividadeIfNeeded(true);
  }

  componentDidUpdate(
    prevProps: Readonly<GeoquimicaFormProps>,
    prevState: Readonly<GeoquimicaFormState>
  ) {
    geoquimicaService.idProjeto = this.props.idProjeto;

    if (this.props.idAtividade !== prevProps.idAtividade) {
      this.atividadeSubscription?.unsubscribe();
      this.loadAtividadeIfNeeded();
    }
  }

  componentWillUnmount() {
    this.lifecycle.next();
    this.lifecycle.complete();
  }

  private loadAtividadeIfNeeded(firstLoad?: boolean) {
    let observable!: Observable<ActivityGeoquimicaModelLike>;
    if (this.props.idAtividade) {
      observable = geoquimicaService.getActivityById(this.props.idAtividade!);
    } else if (!firstLoad) {
      observable = of<ActivityGeoquimicaModelLike>(
        this.defaultInitialModelFactory()
      );
    }

    this.atividadeSubscription = observable
      ?.pipe(takeUntil(this.lifecycle))
      ?.subscribe((initialModel) => {
        const clonedModel = this.handleInitialModel(initialModel);
        this.setState({ initialModel, clonedModel });
      });
  }

  private defaultInitialModelFactory(): ActivityGeoquimicaModelLike {
    return {
      id_visita: Number(this.props.idVisita),
      descricaoAmbiente: {},
      pontoColeta: {},
      amostraAgua: [],
      amostraBateia: [],
      amostraCrosta: [],
      amostraSolo: [],
      amostraOutros: [],
      amostraVegetacao: [],
    };
  }

  private handleInitialModel(
    initialModel: ActivityGeoquimicaModelLike
  ): ActivityGeoquimicaModelLike {
    if (!initialModel.pontoColeta) {
      initialModel.pontoColeta = {};
    }
    if (!initialModel.pontoColeta?.hemisferio) {
      initialModel.pontoColeta.hemisferio = "SUL";
    }
    if (!initialModel.pontoColeta?.tipoEntrada) {
      initialModel.pontoColeta.tipoEntrada = "DECIMAL";
    }
    (initialModel as any).coordenadas = initialModel.pontoColeta;
    amostraKeys.forEach((key) => {
      (initialModel as any)[key] = (initialModel[key] as any[]) ?? [];
    });
    const clonedModel = _.cloneDeep(initialModel);
    return clonedModel;
  }

  onSubmit = (values) => {
    values = _.cloneDeep(values);
    const model: ActivityGeoquimicaModelLike = values;
    model.pontoColeta = values.coordenadas;
    delete values.coordenadas;

    amostraKeys.forEach((key) => {
      model[key] = ((this.state.clonedModel[key] as any[])?.map(
        (item: AbstractSampleGeoquimicaModelLike) => {
          item = _.clone(item);

          item.imagens =
            item.imagens?.map((x) => {
              if (typeof x === "string") {
                return x;
              } else {
                return (x as any).hash as string;
              }
            }) ?? [];
          if ((item as any).id <= 0) {
            delete (item as any).id;
          }
          return item;
        }
      ) ?? []) as any;
    });

    model.amostraSedimentoCorrente?.forEach((x) => {
      (x as any).id_grau_arredondamento_legado = 3;
    });
    model.amostraRocha?.forEach((x) => {
      (x as any).id_situacao_estrutural_legado = 2;
    });
    model.amostraOverBank?.forEach((x) => {
      (x as any).id_cor_material_coletado = 4;
    });
    model.amostraMinerios?.forEach((x) => {
      (x as any).id_forma_deposito_legado = 4;
    });

    let observable: Observable<any>;
    if (model.id_atividade) {
      observable = geoquimicaService.updateActivity(model.id_atividade, model);
    } else {
      observable = geoquimicaService.createActivity(model);
    }

    observable.pipe(takeUntil(this.lifecycle)).subscribe({
      next: (e) => {
        window.location.reload();
      },
      error: (e) => {
        this.props.enviarNotificacaoError!(
          "Não foi possível salvar a atividade!"
        );
      },
    });
  };

  onBtnGoBackClick = () => {
    this.props.onBtnGoBackClick?.call(this.props.onBtnGoBackClick);
  };

  onAmostraAguaChanged = (items: WaterSampleGeoquimicaModelLike[]) => {
    const clonedModel = _.clone(this.state.clonedModel);
    clonedModel.amostraAgua = items;
    this.setState({ clonedModel });
  };

  onAmostraBateiaChanged = (items: BateiaSampleGeoquimicaModelLike[]) => {
    const clonedModel = _.clone(this.state.clonedModel);
    clonedModel.amostraBateia = items;
    this.setState({ clonedModel });
  };

  onAmostraCrostaChanged = (items: BateiaSampleGeoquimicaModelLike[]) => {
    const clonedModel = _.clone(this.state.clonedModel);
    clonedModel.amostraCrosta = items;
    this.setState({ clonedModel });
  };

  onAmostraSoloChanged = (items: SoloSampleGeoquimicaModelLike[]) => {
    const clonedModel = _.clone(this.state.clonedModel);
    clonedModel.amostraSolo = items;
    this.setState({ clonedModel });
  };

  onAmostraOutrosChanged = (items: OutrosSampleGeoquimicaModelLike[]) => {
    const clonedModel = _.clone(this.state.clonedModel);
    clonedModel.amostraOutros = items;
    this.setState({ clonedModel });
  };

  onAmostraVegetacaoChanged = (items: VegetacaoSampleGeoquimicaModelLike[]) => {
    const clonedModel = _.clone(this.state.clonedModel);
    clonedModel.amostraVegetacao = items;
    this.setState({ clonedModel });
  };

  onAmostraRochaChanged = (items: RochaSampleGeoquimicaModelLike[]) => {
    const clonedModel = _.clone(this.state.clonedModel);
    clonedModel.amostraRocha = items;
    this.setState({ clonedModel });
  };

  onAmostraMinerioChanged = (items: MinerioSampleGeoquimicaModelLike[]) => {
    const clonedModel = _.clone(this.state.clonedModel);
    clonedModel.amostraMinerios = items;
    this.setState({ clonedModel });
  };

  onAmostraOverbankChanged = (items: OverbankSampleGeoquimicaModelLike[]) => {
    const clonedModel = _.clone(this.state.clonedModel);
    clonedModel.amostraOverBank = items;
    this.setState({ clonedModel });
  };

  onAmostraSedimentoCorrenteChanged = (
    items: SedimentoCorrenteSampleGeoquimicaModelLike[]
  ) => {
    const clonedModel = _.clone(this.state.clonedModel);
    clonedModel.amostraSedimentoCorrente = items;
    this.setState({ clonedModel });
  };

  criarDecoradores: () => never[] = () => [decoradorSirgas2000()] as any;

  renderForm = ({ form, handleSubmit }) => {
    const prefixoNome = "";

    return (
      <>
        <form onSubmit={handleSubmit}>
          <Field component={HiddenField} name={`${prefixoNome}id_atividade`} />

          <Field component={HiddenField} name={`${prefixoNome}id_visita`} />

          <CaracterizacaoAmbienteSectionForm prefixoNome={prefixoNome} />

          <Row>
            <InformacoesCoordenadas
              configMapa={configMapaGeoquimica}
              mapaAtividade={true}
              prefixoCampos={prefixoNome}
              datums={this.props.datums}
              fusos={this.props.fusos}
              permitirEdicao={true}
              pontosAtribuidosAEstacao={this.props.pontosAtribuidosAEstacao}
            />
          </Row>

          <AguaSectionForm
            prefixoNome={prefixoNome}
            items={this.state.clonedModel.amostraAgua}
            onItemsChanged={this.onAmostraAguaChanged}
          />

          <ConcentradoBateiaSectionForm
            prefixoNome={prefixoNome}
            items={this.state.clonedModel.amostraBateia}
            onItemsChanged={this.onAmostraBateiaChanged}
          />

          <CrostaSectionForm
            prefixoNome={prefixoNome}
            items={this.state.clonedModel.amostraCrosta}
            onItemsChanged={this.onAmostraCrostaChanged}
          />

          <MinerioSectionForm
            prefixoNome={prefixoNome}
            items={this.state.clonedModel.amostraMinerios}
            onItemsChanged={this.onAmostraMinerioChanged}
          />

          <OutrosSectionForm
            prefixoNome={prefixoNome}
            items={this.state.clonedModel.amostraOutros}
            onItemsChanged={this.onAmostraOutrosChanged}
          />

          <OverbankSectionForm
            prefixoNome={prefixoNome}
            items={this.state.clonedModel.amostraOverBank}
            onItemsChanged={this.onAmostraOverbankChanged}
          />

          <RochaSectionForm
            prefixoNome={prefixoNome}
            items={this.state.clonedModel.amostraRocha}
            onItemsChanged={this.onAmostraRochaChanged}
          />

          <SedimentoCorrenteSectionForm
            prefixoNome={prefixoNome}
            items={this.state.clonedModel.amostraSedimentoCorrente}
            onItemsChanged={this.onAmostraSedimentoCorrenteChanged}
          />

          <SoloSectionForm
            prefixoNome={prefixoNome}
            items={this.state.clonedModel.amostraSolo}
            onItemsChanged={this.onAmostraSoloChanged}
          />

          <VegetacaoSectionForm
            prefixoNome={prefixoNome}
            items={this.state.clonedModel.amostraVegetacao}
            onItemsChanged={this.onAmostraVegetacaoChanged}
          />

          <Row>
            <Col>
              <PrimaryButton
                className="float-right m-2"
                onClick={form.submit}
                titulo="Confirmar"
                disabled={false}
              />
              <PrimaryButton
                className="float-right m-2"
                onClick={this.onBtnGoBackClick}
                titulo="Voltar"
                disabled={false}
              />
            </Col>
          </Row>
        </form>
      </>
    );
  };

  render() {
    return (
      <>
        <FForm
          onSubmit={this.onSubmit}
          initialValues={this.state.initialModel}
          render={this.renderForm}
        />
      </>
    );
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    enviarNotificacaoError(mensagem) {
      dispatch(
        mostrarNotificacao(mensagem, {
          tipo: "error",
        })
      );
    },
  };
};

GeoquimicaFormInternal = connect(
  null,
  mapDispatchToProps
)(GeoquimicaFormInternal) as any;

export const GeoquimicaForm = GeoquimicaFormInternal;
