import { PureComponent } from "react";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { AmostraSubFormPart } from "../components/AmostraSubFormPart";
import {
  amostraDataFieldsCommon,
  AmostraSymbol,
  amostrasLabelsMap,
} from "../constants/amostras.constant";
import { CorSampleField } from "../components/CorSampleField";
import { TipoSoloField } from "../components/TipoSoloField";
import { HorizonteSoloField } from "../components/HorizonteSoloField";
import { SoloSampleGeoquimicaModelLike } from "../../../../../models/activity-geoquimica.model";
import { ImagensAmostraSubForm } from "../components/ImagensAmostraSubForm";
import { AmostraGridSubForm } from "../components/AmostraGridSubForm";

export interface SoloSectionFormProps {
  prefixoNome: string;
  items?: SoloSampleGeoquimicaModelLike[];
  onItemsChanged?: (items: SoloSampleGeoquimicaModelLike[]) => void;
}

export interface SoloSectionFormState {}

export class SoloSectionForm extends PureComponent<
  SoloSectionFormProps,
  SoloSectionFormState
> {
  datafieldsSolo = [
    ...amostraDataFieldsCommon,
    {
      name: "id_cor_sedimento_solo",
      type: "number",
      map: "id_cor_sedimento_solo",
    },
    {
      name: "id_horizonte_solo",
      type: "number",
      map: "id_horizonte_solo",
    },
    {
      name: "id_tipo_solo",
      type: "number",
      map: "id_tipo_solo",
    },
  ];

  amostraSymbol = AmostraSymbol.Solo;

  constructor(props) {
    super(props);
    this.state = {};
  }

  renderForm = ({ editando, prefixoNome }) => {
    return (
      <>
        <AmostraSubFormPart
          prefixoNome={prefixoNome}
          amostraSymbol={this.amostraSymbol}
          profundidadeMinRequired={true}
          profundidadeMaxRequired={true}
        />
        <Row className="my-3">
          <Col>
            <CorSampleField
              prefixoNome={prefixoNome}
              name="id_cor_sedimento_solo"
              label="Cor de sedimento do solo"
              required={true}
            />
          </Col>
          <Col>
            <HorizonteSoloField
              prefixoNome={prefixoNome}
              name="id_horizonte_solo"
              required={true}
            />
          </Col>
          <Col>
            <TipoSoloField
              prefixoNome={prefixoNome}
              name="id_tipo_solo"
              required={true}
            />
          </Col>
        </Row>
        <Row className="my-3">
          <Col>
            <ImagensAmostraSubForm prefixoNome={prefixoNome} />
          </Col>
        </Row>
      </>
    );
  };

  render() {
    return (
      <>
        <Card className="my-3">
          <Card.Body>
            <Card.Subtitle>
              {amostrasLabelsMap.get(this.amostraSymbol)}
            </Card.Subtitle>
            <AmostraGridSubForm
              name="amostraSolo"
              datafields={this.datafieldsSolo}
              items={this.props.items}
              onItemsChanged={this.props.onItemsChanged}
              renderForm={this.renderForm}
            />
          </Card.Body>
        </Card>
      </>
    );
  }
}
