import React from "react";
import { Col, Row } from "react-bootstrap";
import { Field } from "react-final-form";
import { DropdownListField } from "../../../../../components/field/DropdownListField";
import { campoObrigatorioDefinitivoComMsgGenerica } from "../../../../../util/validadores";
import { resolverRequestAmostras } from "../AmostrasUtils/AmostraRequestUtils";
import CamposComuns from "./CamposComuns";

class Solo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      corSedimentoSolo: [],
      horizonteSolo: [],
      tipoSolo: [],
      modoCadastro: undefined,
    }
  };

  async componentDidMount() {
    this.setState({
      corSedimentoSolo: await resolverRequestAmostras("/biblioteca/amostras/cor"),
      horizonteSolo: await resolverRequestAmostras("/biblioteca/horizontesolo"),
      tipoSolo: await resolverRequestAmostras("/biblioteca/tiposolo"),
    })
  }

  render() {
    return <>
      <CamposComuns
        duplicata={this.props.duplicata}
        materialColetadoPreenchido={true}
        permitirEdicao={this.props.permitirEdicao}
        labelCampo="Peso da amostra (g)"
        dicaCampo="Informe o peso da amostra, em gramas"
        profundidadeObrigatoria={true}
        informacoesRelevantesObrigatorias={true}
        prefixo={'solo'}
        visualizar={this.props.visualizar}
      />

    <Field subscription={{ value: true }} name={'modoCadastro'}>
      {({ input: { value: modoCadastro } }) => {

        const validar = modoCadastro === 'DEFINITIVO';

        return <>
              <Row className="mt-3 mb-3">
        <Col md={4}>
          <Field
            disabled={!this.props.permitirEdicao}
            name="solo.idCorSedimentoSolo"
            component={DropdownListField}
            label="Cor de sedimento do solo"
            dica="Selecione a cor do solo"
            required={validar}
            elementos={this.state.corSedimentoSolo}
            validate={campoObrigatorioDefinitivoComMsgGenerica (
              "Cor de sedimento do solo"
            )}
          />
        </Col>
        <Col md={4}>
          <Field
            disabled={!this.props.permitirEdicao}
            name="solo.idHorizonteSolo"
            component={DropdownListField}
            label="Horizonte do solo"
            dica="Selecione o horizonte de solo amostrado"
            required={validar}
            elementos={this.state.horizonteSolo}
            validate={campoObrigatorioDefinitivoComMsgGenerica (
              "Horizonte do solo"
            )}
          />
        </Col>
        <Col md={4}>
          <Field
            disabled={!this.props.permitirEdicao}
            name="solo.idTipoSolo"
            component={DropdownListField}
            label="Tipo do solo"
            dica="Selecione o tipo de solo amostrado"
            required={validar}
            elementos={this.state.tipoSolo}
            validate={campoObrigatorioDefinitivoComMsgGenerica (
              "Tipo do solo"
            )}
          />
        </Col>
      </Row>

        </>
      }}
      </Field>
    </>
  }

}

export default Solo;
