import React from 'react'
import ReactDOM from 'react-dom'
import * as serviceWorker from './serviceWorker'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'jqwidgets-scripts/jqwidgets/styles/jqx.base.css'
import 'jqwidgets-scripts/jqwidgets/styles/jqx.bootstrap.css'
import 'jqwidgets-scripts/jqwidgets/styles/jqx.material.css'
import './assets/css/jqx.aflora.css'
import './assets/css/style.css'
import 'react-block-ui/style.css'
import './assets/css/react-block-ui.css'
import '@govbr/dsgov/dist/dsgov.css'
import 'semantic-ui-css/semantic.min.css'

import dayjs from "dayjs";
import App from "./components/app/App";
import { refreshToken } from './util/refreshToken'

const customParseFormat = require('dayjs/plugin/customParseFormat')
dayjs.extend(customParseFormat)

//Removida a função, esta causando comportamento indesejado na tela inicial
// refreshToken();

ReactDOM.render(
    <App />,
    document.getElementById('root'),
);

serviceWorker.unregister()
