import { useState, useEffect, useMemo } from "react";
import Service, { BASEGEO, AMOSTRAS } from "../../../../../service/Service";
import { decoradorSirgas2000, decoradorMapa } from "../../../../../util/decoradores/decoradorMapa";
import { resetarLatLongInvalidaAoTrocarHemisferio } from "../../../../../util/decoradores/decoradorSeletorCoordenadas";
import { Form } from "react-final-form";
import { setValue } from "../../../../../util/mutadores";
import AtividadePetro from "../../../../dadosAnaliticos/components/petrografia/AtividadePetro";

const PetrografiasAprovacao = ({ atividade }) => {

    const [listaAtividades, setListaAtividades] = useState([]);
    const [areas, setAreas] = useState([]);
    const [subAreas, setSubareas] = useState([]);
    const [projetos, setProjetos] = useState([]);
    const [amostraSelecionada, setAmostraSelecionada] = useState(null);
    const [coletores, setColetores] = useState([]);

    const {
        idAmostra,
        idProjetoCorrente,
        idVisita,
        idArea,
        idSubArea,
        nomeProjeto,
        idEstacao
    } = atividade || {};

    const decoradores = useMemo(() => [
        decoradorSirgas2000(idVisita),
        decoradorMapa(),
        resetarLatLongInvalidaAoTrocarHemisferio()
    ], [idVisita]);

    useEffect(() => {
        if (!idProjetoCorrente) return;

        const fetchData = async () => {
            const [
                coletoresProjetoResponse,
                atividadesAmostraResponse,
                areasResponse,
                subareasResponse,
                projetosResponse,
                amostraPetrografiaResponse,
            ] = await Promise.all([
                Service(`/coletores/paraProjeto/${idProjetoCorrente}`).query(),
                Service(`/dados-analiticos-amostras/${idAmostra}/atividades`, BASEGEO).query(),
                Service(`/projetos/${idProjetoCorrente}/areas`, BASEGEO).query(),
                Service(`/projetos/${idProjetoCorrente}/subAreas`, BASEGEO).query(),
                Service('/projetos', BASEGEO).query(),
                Service('/cadastroAmostra/amostra', AMOSTRAS).get(idAmostra)
            ]);

            setColetores(coletoresProjetoResponse.data.dados);
            setListaAtividades(atividadesAmostraResponse.data);
            setAreas(areasResponse.data.dados);
            setSubareas(subareasResponse.data.dados);
            setProjetos(projetosResponse.data.dados);
            setAmostraSelecionada(amostraPetrografiaResponse.data.dados);
        };

        fetchData();
    }, [idProjetoCorrente, idAmostra]);

    if (!atividade || !amostraSelecionada) {
        return <div>Carregando...</div>;
    }

    return (
        <Form
            id="formAtividadePetrografias"
            onSubmit={() => { }}
            decorators={decoradores}
            initialValues={atividade}
            mutators={{ setValue }}
            render={(formProps) => (
                <AtividadePetro
                formProps={formProps}
                referenciaForm={formProps}
                idAmostra={idAmostra}
                idEstacao={idEstacao}
                area={idArea}
                subArea={idSubArea}
                nomeProjeto={nomeProjeto}
                atividades={listaAtividades}
                coletores={coletores}
                projetos={projetos}
                areas={areas}
                subAreas={subAreas}
                amostraSelecionada={amostraSelecionada}
                isChefeProjeto={true}
                bloquearFichaPetrografica={true}
                permitirVisualizacao={true}
                permitirGerarPdf={false}
                permitirEdicao={false}
                permitirInsercao={false}
            />
            )}
        />
    );
};

export { PetrografiasAprovacao };
