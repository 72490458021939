import React, { useEffect, useRef, useState } from "react";
import Card from "../../../../components/card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { SubForm } from "../../../../components/SubForm";
import { Field } from "react-final-form";
import { DropdownListField } from "../../../../components/field/DropdownListField";
import { campoObrigatorioComMsgGenerica, validarSe } from "../../../../util/validadores";
import { TextAreaField } from "../../../../components/field/TextAreaField";
import { getPropsPermissao } from "../../../../components/SubForm/SubForm";
import { CounterField } from "../../../../components/field/CounterField";

const campos = [
  { name: "idTipo", type: "string", map: "idTipo" },
  { name: "idsRochas", type: "array", map: "idsRochas" },
  { name: "idFacies", type: "string", map: "idFacies" }
]

const Metamorfismos = ({
  aoAbrirSubForm,
  aoFecharSubForm,
  nome,
  onSubmit,
  // elementos,
  valoresIniciais,
  permitirEdicao,
  tiposMetamorfismo = [],
  faciesMetamorfismo = [],
  rochasPorId,
  rochas,
  modoDefinitivoSelecionado,
  hierarquias,
  formAtividade,
  metamorfismosComErro
}) => {
  // These refs are for remembering the previous values of each array that's a
  // dependency of the columns array. We only recalculate the columns array when
  // there's an actual change in the arrays.
  const rochasPorIdRef = useRef(rochasPorId);
  const tiposMetamorfismoRef = useRef(tiposMetamorfismo);
  const faciesMetamorfismoRef = useRef(faciesMetamorfismo);
  const [colunasMetamorfismos, setColunasMetamorfismos] = useState(gerarColunasMetamorfismo(rochasPorId, tiposMetamorfismo, faciesMetamorfismo));

  useEffect(() => {
    // Only update the columns when at least one of the dependencies has really changed.
    // Otherwise, avoid changing the columns because that causes a table rerender.
    // (The *correct* thing to do would be including the names in the objects themselves (add extra fields)
    // and always recalculate those names when dependencies change, completely removing the need for
    // a cellsrenderer.)
    // Compare the dependencies by value.
    if (JSON.stringify(rochasPorIdRef.current) !== JSON.stringify(rochasPorId)
      || JSON.stringify(tiposMetamorfismoRef.current) !== JSON.stringify(tiposMetamorfismo)
      || JSON.stringify(faciesMetamorfismoRef.current) !== JSON.stringify(faciesMetamorfismo)) {
      setColunasMetamorfismos(gerarColunasMetamorfismo(rochasPorId, tiposMetamorfismo, faciesMetamorfismo));
      rochasPorIdRef.current = rochasPorId;
      tiposMetamorfismoRef.current = tiposMetamorfismo;
      faciesMetamorfismoRef.current = faciesMetamorfismo;
    }
  }, [rochasPorId, tiposMetamorfismo, faciesMetamorfismo])

  const valoresIniciaisRef = useRef(valoresIniciais);

  return (
    <div style={{ marginTop: '2.5rem' }}>
      <Card className="mt-3">
        <Card.Body>
          {metamorfismosComErro  && <span style={{ color: "red" }}>Existem metamorfismos com campos obrigatórios não preenchidos</span>}
          <Card.Title>Metamorfismo</Card.Title>
          <Field name={nome} subscription={{ value: true }}>
            {({ input: { value: metamorfismos = {} } }) => (
              <SubForm
                nome={nome}
                onSubmit={(params) => {
                  // Reset the initial values so that it doesn't get automatically
                  // recovered on the next render.
                  // It would be better to check if the submit was successful before
                  // resetting this ref, but it doesn't seem like that's possible.
                  valoresIniciaisRef.current = null;
                  onSubmit(params);
                }}
                elementos={metamorfismos} // elementos
                campos={campos}
                colunas={colunasMetamorfismos}
                onOpen={aoAbrirSubForm}
                onClose={aoFecharSubForm}
                valoresIniciais={valoresIniciaisRef.current || valoresIniciais}
                {...getPropsPermissao(permitirEdicao)}
                alternarBotoesPai={true}
                validarVoltar={true}
                renderForm={({ formProps: { form, values }, prefixoNome }) => {

                  // Update the ref of initial values. This code needs to run
                  // when any field changes.
                  if (valoresIniciaisRef.current !== values) {
                    valoresIniciaisRef.current = values;
                  }
                  return (
                    <>
                      <Row className="my-3">
                        <Col md={6}>
                          <Field
                            component={DropdownListField}
                            name={`${prefixoNome}idsRochas`}
                            label="Rochas"
                            dica="Selecione uma ou mais rochas associadas ao metamorfismo descrito"
                            elementos={rochas}
                            required={modoDefinitivoSelecionado}
                            disabled={!permitirEdicao}
                            displayMember={'nomeExibicao'}
                            validate={validarSe(modoDefinitivoSelecionado, campoObrigatorioComMsgGenerica("Rochas"))}
                            checkboxes={true}
                            multiple
                          />
                        </Col>
                        <Col md={6}>
                          <Field
                            component={DropdownListField}
                            name={`${prefixoNome}idTipo`}
                            label="Tipo de metamorfismo"
                            dica="Selecione o tipo de metamorfismo observado na(s) rocha(s)"
                            elementos={tiposMetamorfismo}
                            required={modoDefinitivoSelecionado}
                            disabled={!permitirEdicao}
                            validate={validarSe(modoDefinitivoSelecionado, campoObrigatorioComMsgGenerica("Tipo de metamorfismo"))}
                          />
                        </Col>
                      </Row>
                      <Row className="my-3">
                        <Col md={6}>
                          <Field
                            component={CounterField}
                            name={`${prefixoNome}hierarquia`}
                            label="Hierarquia"
                            dica="Selecione o grau hierárquico do metamorfismo observado nesta(s) rocha(s) perante o contexto geológico deste afloramento"
                            elementos={hierarquias}
                            disabled={!permitirEdicao}
                            value= { formAtividade.getState().values[`${prefixoNome}hierarquia`] }
                            onChange={(value) => {
                              formAtividade.mutators.setValue(`${prefixoNome}hierarquia`, value)
                            }}
                          />
                        </Col>
                        <Col md={6}>
                          <Field name={`${prefixoNome}idFacies`} subscription={{ value: true }}>
                            {({ input: { value: idFacies } }) => {
                              let tempArray = [];
                              faciesMetamorfismo?.forEach(item => {
                                if (item?.facieAtual === 'S' || item.id.toString() === idFacies.toString()) {
                                  tempArray.push(item);
                                }
                              })
                              return <Field
                                component={DropdownListField}
                                name={`${prefixoNome}idFacies`}
                                label="Fácies"
                                dica="Selecione o Fácies metamórfico observado nesta(s) rocha(s)"
                                elementos={tempArray}
                                required={modoDefinitivoSelecionado}
                                disabled={!permitirEdicao}
                                validate={validarSe(modoDefinitivoSelecionado, campoObrigatorioComMsgGenerica("Fácies"))}
                              />
                            }}</Field>
                        </Col>
                      </Row>
                      <Row className="my-3">
                        <Col md={12}>
                          <Field
                            component={TextAreaField}
                            name={`${prefixoNome}informacoesRelevantes`}
                            label="Informações relevantes"
                            dica="Caso necessário, descreva detalhadamente o fácies metamórfico observado"
                            maxLength={250}
                            disabled={!permitirEdicao}
                          />
                        </Col>
                      </Row>
                    </>
                  )
                }}
              />
            )}
          </Field>
        </Card.Body>
      </Card>
    </div>
  );
}

// Função que gera as colunas da tabela de metamorfismos, extraída para que
// possamos instanciar as colunas já inicializadas da primeira vez, ao invés de
// usar um estado [] padrão. Isso evita um rerender da tabela.
function gerarColunasMetamorfismo(rochasPorId, tiposMetamorfismo, faciesMetamorfismo) {
  return [
    {
      text: 'Tipo de metamorfismo', datafield: 'idTipo', width: "44%",
      cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
        const nome = value ? tiposMetamorfismo.find(g => g?.id === parseInt(value))?.nome : '';
        return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${nome}</div>`;
      }
    },
    {
      text: "Rochas", datafield: "idsRochas", width: "25%",

      cellsrenderer: function (row, columnfield, value, defaulthtml, columnproperties, rowdata) {
        let nome = '';

        try {
          const idsRochas = (rowdata[columnfield] || '').split(',')
          nome = idsRochas
            .filter(it => !!it)
            .map(it => rochasPorId && rochasPorId[Number(it)] ? rochasPorId[Number(it)].nomeExibicao : '')
            .join(', ')
        }
        catch(err){}

        return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${nome}</div>`;
      }
    },
    {
      text: "Fácies", datafield: "idFacies", width: "25%",
      cellsrenderer: (row, columnfield, value) => {
        const nome = value ? faciesMetamorfismo.find(g => g?.id === parseInt(value))?.nome : '';
        return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${nome}</div>`;
      }
    },
  ]
}

export default Metamorfismos;
