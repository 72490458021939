import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import { jqx } from "jqwidgets-scripts/jqwidgets-react-tsx/jqxgrid";
import Localizacao from "../../util/Localizacao";
import Row from "react-bootstrap/Row";
import { Col } from "react-bootstrap";
import AppMapComponent from "../../components/mapa/map_component/AppMapComponent";
import swal from "sweetalert";
import Service from "../../service/Service";
import { connect } from "react-redux";
import { mostrarNotificacao } from "../../reducers/notificacaoReducer";
import PageScaffold from "../../components/scaffold/PageScaffold";
import { FocusableButton } from "../../components/Jqx/Button";

import { configMapaPontosGPS } from '../../config/mapa';
import { LegacyTable, Table } from "../../components/Jqx/Table";
import { adicionarCabecalhoAuthAoJqXHR } from "../../util/jqxUtils";

import Card from "../../components/card";
import InfoPanel from "../../components/infoPanel";

import LegendaMapa from "../estacoes/components/mapa/LegendaMapa";
import InfoPanelGPS from "../../components/infoPanelGPS";
import BlockUi from 'react-block-ui'

let idsLinhasTabela = [];
let indexLinhasTabela = [];

let gridCarregado = false, mapaCarregado = false, intervalo = null
class ArquivosDetalhes extends Component {
  constructor(props) {
    super(props);

    if (
      props.history.location.state &&
      props.history.location.state.idSelecionado
    ) {
      // this.url = `${process.env.REACT_APP_API_URL}/itensArquivos?arquivoId=${props.history.location.state.idSelecionado}`;
      this.url = `${process.env.REACT_APP_API_URL}/gps/porId/${props.history.location.state.idSelecionado}`;
    } else {
      this.url = `${process.env.REACT_APP_API_URL}/itensArquivos`;
    }

    this.columns = [
      { text: "Longitude", datafield: "longitude" },
      { text: "Latitude", datafield: "latitude" },
      { text: "Altitude (m)", datafield: "altitude" },
      { text: "Nome", datafield: "nome" },
      { text: "Descrição", datafield: "descricao" },
      {
        text: "Status", datafield: "status", filtertype: 'checkedlist', filteritems:
          [
            { label: "Vinculado", value: "VINCULADO" },
            { label: "Não Vinculado", value: "Pendente" },
            { label: "Pendente", value: "NAO_VINCULADO" },
          ],
        cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
          let hint = "";
          switch (value) {
            case "PENDENTE": {
              value = "Não Vinculado";
              hint = "Não está vinculado a uma estação";
              break;
            }
            case "NAO_VINCULADO": {
              value = "Pendente";
              hint = "Pendente de vinculo com a atividade, mas associado a uma estação";
              break;
            }
            case "VINCULADO": {
              value = "Vinculado";
              hint = "Vinculado a uma atividade da estação";
              break;
            }
            default: {
              value = "";
              hint = "";
            };
          }
          return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;" title="${hint}">${value}</div>`;;
        },

      },
      { text: "idStatus", datafield: "idStatus", hidden: true },
    ];

    /**
     * Use a json file as datasource instead of an array.
     */
    this.extraSourceParameters = {
      id: "id",
      url: this.url,
      datatype: "json",
      beforeSend: adicionarCabecalhoAuthAoJqXHR,
    };

    this.datafields = [
      { name: "id", type: "number" },
      { name: "latitude", type: "number" },
      { name: "longitude", type: "number" },
      { name: "altitude", type: "number" },
      { name: "latitudeWGS84", type: "number" },
      { name: "longitudeWGS84", type: "number" },
      { name: "nome", type: "string" },
      { name: "descricao", type: "string", map: "descricao" },
      { name: "status", type: "string", map: "status" },
      { name: "idStatus", type: "string", map: "status>id" },
      { name: "idDatum", type: "number" }
    ];

    this.state = {
      erroExcluir: null,
      pontosCarregados: false
    };

    /** @type {React.RefObject<HTMLDivElement>} */
    this.erroExcluirTextoRef = React.createRef();

    this.onDeselecionarLinhaGrid = this.onDeselecionarLinhaGrid.bind(this);
    this.onSelecionarLinhaGrid = this.onSelecionarLinhaGrid.bind(this);
    this.onGridCarregado = this.onGridCarregado.bind(this);
    this.onMapaCarregado = this.onMapaCarregado.bind(this);
    this.onSelecionarPontos = this.onSelecionarPontos.bind(this);
    this.pronto = this.pronto.bind(this);
    this.verificarMapaEGridCarregados = this.verificarMapaEGridCarregados.bind(this);
    this.excluirPontos = this.excluirPontos.bind(this);
    this.onLimparMapa = this.onLimparMapa.bind(this);
    this.limparPontos = this.limparPontos.bind(this);
    this.onAlterarPagina = this.onAlterarPagina.bind(this);
    this.onAssociarPontosEstacao = this.onAssociarPontosEstacao.bind(this);
    this.atualizarPontosVisiveis = this.atualizarPontosVisiveis.bind(this);
    this.redirecionarCadastroEstacao = this.redirecionarCadastroEstacao.bind(this);
  }


  async componentDidMount() {
    if (intervalo) clearInterval(intervalo)
    mapaCarregado = false;
    gridCarregado = false;

    localStorage.setItem('coodernadasVoltar', true);

    intervalo = setInterval(await this.verificarMapaEGridCarregados, 100)
  }

  componentWillUnmount() {
    if (intervalo) clearInterval(intervalo)
  }

  redirecionarCadastroEstacao() {
    const linhas = this.jqxgrid.getrows();
    const linhasSelecionadas = [];

    idsLinhasTabela.forEach((i) => linhasSelecionadas.push(linhas.find((r) => r.id === i)));

    console.info('Enviando', linhasSelecionadas.length, 'pontos GPS para a tela coordenadas', linhasSelecionadas)

    if (linhasSelecionadas.length < 1) {
      swal({
        title: `Selecione ao menos um ponto à ser cadastrado`,
        icon: "info"
      })
    } else {

      if (linhasSelecionadas.find((i) => !i || i.status != 'PENDENTE')) {
        this.props.dispararNotificacao("Pontos com status diferente de Pendente não podem ser cadastrados",
          { tipo: "error" }, true);
        return;
      }

      this.props.history.push({
        pathname: '/coordenadas',
        state: {
          ...this.props.location.state,
          pontosGPS: linhasSelecionadas.filter(i => i && i.id).map(r => ({
            id: r.id,
            latitude: r.latitude,
            longitude: r.longitude,
            altitude: r.altitude,
            latitudeWGS84: r.latitudeWGS84,
            longitudeWGS84: r.longitudeWGS84,
            nome: r.nome,
            idDatum: r.idDatum
            // status
          })),
        }
      });
      idsLinhasTabela = [];
    }
  }

  pronto() {
    return mapaCarregado && gridCarregado && this.jqxgrid
  }

  async verificarMapaEGridCarregados() {
    if (this.pronto()) {
      const { idProjeto, geometryFilter } = this.props.location.state;
      this.mapa.adicionarPontosGPS(this.jqxgrid.getrows())
      this.mapa.filtrar(idProjeto, geometryFilter)
      clearInterval(intervalo)
      
      let lista = localStorage.getItem("LISTA_PONTOS");

      if(lista) {
        let pontos = JSON.parse('[' + lista + ']');
        pontos = pontos.map((i) => { return { id: i, selecionado: true } });
        this.onSelecionarPontos(pontos);
      }

      this.setState({
        pontosCarregados: true
      });
    }
  }

  atualizarPontosVisiveis() {
    if (this.pronto()) {
      this.mapa.limparPontos()
      this.mapa.adicionarPontosGPS(this.jqxgrid.getrows())
    }
  }

  onMapaCarregado() {
    mapaCarregado = true
  }

  onGridCarregado() {
    gridCarregado = true
  }

  onAlterarPagina() {
    this.limparPontos();
  }

  // FIXME: Comportamento "recursivo" ao utilizar seleção múltipla com muitos pontos (difícil de reproduzir)
  onSelecionarLinhaGrid(args) {
    if (!args.args.row) return;

    const id = args.args.row.id;

    idsLinhasTabela.push(id);

    idsLinhasTabela = [...new Set(idsLinhasTabela)];

    localStorage.setItem("LISTA_PONTOS", idsLinhasTabela);

    this.mapa.selecionarPontos([{
      id,      selecionado: true,
    }])
  }

  onDeselecionarLinhaGrid(args) {
    if (!args.args.row) return;

    const id = args.args.row.id;

    idsLinhasTabela = idsLinhasTabela.filter((i) => i != id);

    localStorage.setItem("LISTA_PONTOS", idsLinhasTabela);

    this.mapa.selecionarPontos([{
      id,
      selecionado: false,
    }])
  }

  // FIXME: É melhor refatorar isso para guardar os pontos selecionados em um objeto separado.
  // Seleção não funciona direito se houver mais pontos do que se poder exibir no grid (por página)
  onSelecionarPontos(pontos = []) {
    for (const ponto of pontos) {
      const { id, selecionado } = ponto;
      if (this.jqxgrid) {
        const row = this.jqxgrid.getrows().find(r => r.id === id)
        if (row) {
          const boundIndex = row.boundindex
          if (selecionado) {
            this.jqxgrid.selectrow(boundIndex)
          } else {
            this.jqxgrid.unselectrow(boundIndex)
          }
        }
      }
    }
  }

  onLimparMapa() {
    // Aguardar jqxgrid para recriar pontos
    intervalo = setInterval(this.verificarMapaEGridCarregados, 100)
  }

  limparPontos() {
    if (!this.pronto()) return;

    this.jqxgrid.clearselection();
    this.jqxgrid.updatebounddata();
    this.mapa.limparPontos(); // Limpar pontos irá fazer com que o mapa eventualmente chame this.onLimparMapa
  }

  // Excluir pontos GPS selecionados
  excluirPontos() {
    if (!this.pronto()) {
      return
    };

    if (this.jqxgrid.getselectedrowindexes().length > 0) {
      const linhasSelecionadas = this.jqxgrid.getselectedrowindexes().map(i => this.jqxgrid.getrows()[i])

      // Regra: Somente pontos pendentes podem ser excluídos, então se houver ao
      // menos um ponto com status diferente de "PENDENTE", não deixamos excluir.
      if (linhasSelecionadas.some((point) => point.status !== "PENDENTE")) {
        this.setState({
          erroExcluir: "Só é possível excluir pontos com status pendente.",
        });
        this.props.dispararNotificacao(this.appendSpaces("Só é possível excluir pontos com status pendente."), { tipo: "error" })
        // Esta linha é para dar um scroll para a mensagem de erro, mas acabou não
        // sendo necessário porque o card é pequeno o bastante para sempre caber na tela.
        // this.erroExcluirTextoRef.current?.scrollIntoView();
        return;
      } else {
        this.setState({
          erroExcluir: null,
        });
      }

      const idsASeremExcluidos = linhasSelecionadas.map(l => l.id);

      swal({
        title: `Tem certeza que deseja excluir o(s) (${idsASeremExcluidos.length}) ponto(s) selecionado(s)?`,
        icon: "warning",
        buttons: { cancel: "Não, cancelar", confirm: { text: "Sim, desejo excluir!", className: "btn-danger" }, },
      }).then((result) => {
        if (result) {
          Service(`/gps/pontosGPS`).get(`excluir?idsPontos=${idsASeremExcluidos.join(',')}`)
            .then(() => {
              this.limparPontos();
              this.props.dispararNotificacao(this.appendSpaces("Pontos GPS excluídos com sucesso!"), { tipo: "success" })
            })
            .catch((error) => {
              this.props.dispararNotificacao(this.appendSpaces("Ocorreu um erro ao excluir o(s) ponto(s) GPS"), { tipo: "error" })
            })
        }
      });
    } else {
      swal({
        title: `Selecione ao menos um ponto à ser excluído`,
        icon: "info"
      })
    }
  }

  onAssociarPontosEstacao(idEstacao) {
    if (!this.pronto()) return;
    if (this.jqxgrid.getselectedrowindexes() && this.jqxgrid.getselectedrowindexes().length > 0) {
      const linhasSelecionadas = this.jqxgrid.getselectedrowindexes().map(i => this.jqxgrid.getrows()[i])
      const idsPontosGPS = linhasSelecionadas.map(l => l.id);
      swal({
        title: `Tem certeza que deseja vincular o(s) (${idsPontosGPS.length}) ponto(s) selecionado(s) com esta estação?`,
        icon: "warning",
        buttons: { cancel: { text: "Não, cancelar", className: "btn-danger", visible: true }, confirm: { text: "Sim, desejo vincular!", className: "btn-success" }, },
      }).then((result) => {
        if (result) {
          Service("gps").customURL("POST", `/associarPontoEstacao/${idEstacao}`, idsPontosGPS)
            .then((res) => {
              this.props.dispararNotificacao("Os pontos foram associados com sucesso!", { tipo: "success" })
              this.limparPontos();
            })
            .catch(res => {
              console.error(`erro ao associar pontos e estacao`, res)
              this.props.dispararNotificacao("Algo deu errado", { tipo: "warning" })
            })
        }
      });
    }
  }

  // Este é um workaround/gambiarra para poder mostrar janelinhas de erro com
  // a mesma mensagem repetida, que normalmente não é possível. A cada duas
  // mensagens, uma delas ganha um espaço sem largura, para que compare diferente
  // à mensagem anterior mesmo que seja igual.
  appendSpaces(message) {
    const res = `${message}${this.zwsp}`;
    if (this.zwsp.length > 0) {
      this.zwsp = ""; // 0 chars
    } else {
      this.zwsp = "​";
    }
    return res;
  }
  // A zero-width breaking space (U+200B ZERO WIDTH SPACE), or nothing.
  zwsp = "​";

  render() {
    return (
      <BlockUi blocking={!this.state.pontosCarregados}>
        <PageScaffold titulo="Pontos do GPS">
          <InfoPanelGPS />
          <Container>
            <Card>
              <Card.Body>
                <div ref={this.erroExcluirTextoRef}>
                  {this.state.erroExcluir && <p className="erro-campo" style={{ color: "red", fontSize: "10px" }}>{this.state.erroExcluir}</p>}
                </div>
                <Card.Title>Selecionar pontos para cadastro</Card.Title>
                <Row>
                  <Col md={12}>
                    <Table
                      // The Table component has in it a field named tableRef,
                      // which is a ref to JqxTable.
                      ref={instance => {
                        if (instance && instance.tableRef) {
                          this.jqxgrid = instance.tableRef.current;
                        }
                      }}
                      width={"100%"}
                      altrows={true}
                      pageable={true}
                      sortable={true}
                      filterable={true}
                      autoheight={true}
                      columnsresize={true}
                      enabletooltips={true}
                      selectionmode={"multiplerows"}
                      datafields={this.datafields}
                      extraSourceParameters={this.extraSourceParameters}
                      columns={this.columns}
                      localization={Localizacao}
                      onBindingcomplete={this.onGridCarregado}
                      onRowselect={this.onSelecionarLinhaGrid}
                      onRowunselect={this.onDeselecionarLinhaGrid}
                      // onPagechanged={this.onAlterarPagina}
                      onFilter={this.atualizarPontosVisiveis}
                    />
                  </Col>
                </Row>

                <Row className="mt-3 mb-3">
                  <Col>
                    <FocusableButton
                      ref={instance => { this.botaoExcluir = instance; }}
                      className="float-right m-1"
                      onClick={this.excluirPontos}
                    >
                      Excluir pontos
                    </FocusableButton>
                  </Col>
                </Row>

                <LegendaMapa
                  exibirEstacao={true}
                  exibirEstacaoHistorica={false}
                  exibirEstacaoEditada={false}
                  exibirAtividade={false}
                  exibirPontoGPS={true}
                  exibirGeometriaProjeto={true}
                />

                <AppMapComponent
                  ref={instance => { this.mapa = instance; }}
                  config={configMapaPontosGPS}
                  onCarregarMapa={this.onMapaCarregado}
                  onSelecionarPontos={this.onSelecionarPontos}
                  onLimparMapa={this.onLimparMapa}
                  onAssociarPontosEstacao={this.onAssociarPontosEstacao}
                  debugMensagens={!!process.env.REACT_APP_MAP_DEBUG}
                />
                <Row className="my-1">
                  <Col>
                    <FocusableButton
                      className="float-right m-1"
                      onClick={() => {
                        localStorage.removeItem("LISTA_PONTOS");
                        this.props.history.goBack();
                      }}>
                      Voltar
                    </FocusableButton>
                    <FocusableButton
                      className="float-right m-1"
                      onClick={this.redirecionarCadastroEstacao}
                    >
                      Cadastrar pontos
                    </FocusableButton>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Container>
        </PageScaffold>
      </BlockUi>
    );
  }
}

export default connect(
  null,
  (dispatch) => ({
    dispararNotificacao(mensagem, config, forcarExibicao = false) {
      dispatch(mostrarNotificacao(mensagem, config, forcarExibicao))
    }
  })
)(ArquivosDetalhes);
