import { useCallback, useMemo } from "react";
import NumberInput from "../../input/NumberInput/NumberInput";
import NumberInputOld from "../../input/NumberInput/NumberInputOld";
import CustomNumberInput from "../../../views/dadosAnaliticos/sharedComponents/CustomNumberInput";

const NumberInputField = ({
  usarOnchange = false,
  nome,
  name,
  input,
  meta,
  ...props
}) => {
  const { componentstate, onChange, onValueChange, useOldInput, useCustomInput = false, ...finalProps } = props;

  const onChangeInternal = useCallback(
    (evt) => {
      onChange?.(evt);

      input.onChange?.(evt);

      if (componentstate) {
        componentstate(evt);
      }

      if (!evt || (typeof evt === 'number')) {
        onValueChange?.(evt);
      }
    },
    [input, onChange, onValueChange, componentstate]
  );

  const mensagemErro = meta.touched && !meta.valid && meta.error;
  const inputComponent = useMemo(() => {
    if (useOldInput) {
      return (
        <NumberInputOld
          {...finalProps}
          valor={input.value}
          onChange={onChangeInternal}
          mensagemErro={mensagemErro}
          usarOnchange={usarOnchange}
          name={name}
        />
      );
    }

    if (useCustomInput) {
      return (
        <CustomNumberInput
          {...finalProps}
          valor={input.value}
          onChange={onChangeInternal}
          mensagemErro={mensagemErro}
          usarOnchange={usarOnchange}
          name={name}
        />
      );
    }

    return (
      <NumberInput
        {...finalProps}
        valor={input.value}
        onChange={onChangeInternal}
        mensagemErro={mensagemErro}
        usarOnchange={usarOnchange}
        name={name}
      />
    );
  }, [
    finalProps,
    input,
    mensagemErro,
    name,
    onChangeInternal,
    usarOnchange,
    useCustomInput,
    useOldInput
  ]);
  return (
    <>
      {inputComponent}
    </>
  );
};

NumberInputField.propTypes = {
  ...NumberInput.propTypes,
};

export default NumberInputField;
