import React, { PureComponent } from "react";
import Modal from "react-bootstrap/Modal";

export interface PopupProps {
  opened?: boolean;
  size?: "sm" | "lg" | "xl";
  showCloseButton?: boolean;
  title?: string;
  content?: string;
  children?: any;
  onOpen?: (e?: Event) => void;
  onClose?: (e?: Event) => void;
}

export interface PopupState {
  firstOpened?: boolean;
}

export class Popup extends PureComponent<PopupProps, PopupState> {
  constructor(props) {
    super(props);

    const opened = !!this.props.opened;
    this.state = {
      firstOpened: opened,
    };
  }

  componentDidUpdate(prevProps: Readonly<PopupProps>) {
    if (!!prevProps.opened !== !!this.props.opened) {
      if (this.props.opened && !this.state.firstOpened) {
        this.setState({ firstOpened: true });
      }
    }
  }

  onOpen = () => {
    this.props.onOpen?.();
  };

  onClose = () => {
    this.props.onClose?.();
  };

  render() {
    const restProps = {};
    const propsKeysRest: Array<keyof PopupProps> = ["size"];
    for (let key of propsKeysRest) {
      if (key in this.props) {
        restProps[key] = this.props[key];
      }
    }

    const showCloseButton = !!(this.props.showCloseButton ?? true);

    return (
      <>
        {!this.state.firstOpened ? null : (
          <>
            <Modal
              show={this.props.opened}
              onShow={this.onOpen}
              onHide={this.onClose}
              centered={true}
              animation={true}
              {...restProps}
            >
              {this.props.title || showCloseButton ? (
                <Modal.Header closeButton={showCloseButton}>
                  {this.props.title ?? ""}
                </Modal.Header>
              ) : null}
              <Modal.Body>{this.props.children}</Modal.Body>
            </Modal>
          </>
        )}
      </>
    );
  }
}
