import React, { useCallback, useEffect } from "react";
import JqxGrid from "jqwidgets-scripts/jqwidgets-react-tsx/jqxgrid";
// import { ACCESS_TOKEN } from '../../constants';
import Localizacao from "../../../util/Localizacao";

/**
 * IMPORTANT
 * 
 * This component should no longer be used in any new pages or components. Use the newer Table
 * instead. This was kept only for components where the new Table doesn't work yet, but that
 * is currently being worked on, and soon all instances of this LegacyTable should be replaced
 * by the new Table.
 */

// https://www.jqwidgets.com/react-components-documentation/documentation/jqxgrid/reactjs-grid-api.htm?search=
// https://www.jqwidgets.com/community/topic/always-show-filter-icon/

export interface TableProps {
  source: any;
  columns: any[];
  jqxTableRef?: any;

  autoshowloadelement?: boolean;
  altrows?: boolean;
  pageable?: boolean;
  filterable?: boolean;
  sortable?: boolean;
  autoheight?: boolean;
  columnsresize?: boolean;
  enabletooltips?: boolean;
  selectionmode?: "none" | "singlerow" | "checkbox";

  onRowSelectChanged?: (e?: Event) => void;
}

export interface Table extends React.FunctionComponent<TableProps> {
  getselectedrowindexes(): number[];
  unselectrow(rowBoundIndex: number): void;
}

const TableFn = ({
  jqxTableRef,
  autoshowloadelement = false,
  selectionmode = "singlerow",
  onRowSelectChanged,
  ...props
}: TableProps) => {
  // const jqxSource = new jqx.dataAdapter(props.source, {
  //   beforeSend: function (jqXHR, settings) {
  //     // jqXHR.setRequestHeader('Authorization', `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`);
  //     jqXHR.setRequestHeader('Access-Control-Allow-Origin', process.env.REACT_APP_API_URL);
  //   }
  // });

  const addTabindex = useCallback((elementArray: any[]) => {
    if (elementArray) {
      elementArray.forEach((element, index) => {
        element.setAttribute("tabindex", "0");
        return element;
      });
    }
  }, []);

  const setElementsTabIndex = useCallback(() => {
    const _id = jqxTableRef.current?._componentSelector || null;
    const grid = document.querySelector(_id) || null;

    if (grid) {
      addTabindex(grid.querySelectorAll(".jqx-button"));
      addTabindex(grid.querySelectorAll(".jqx-input"));
      addTabindex(grid.querySelectorAll(".jqx-dropdownlist"));
    }
  }, [jqxTableRef, addTabindex]);

  useEffect(() => {
    setElementsTabIndex();
  }, [setElementsTabIndex]);

  const onRowselectChangedInternal = useCallback(
    (e) => {
      onRowSelectChanged?.(e);
    },
    [onRowSelectChanged]
  );

  return (
    <>
      <div style={{ width: "100%" }}>
        <JqxGrid
          width={"100%"}
          selectionmode={selectionmode}
          localization={Localizacao}
          ref={jqxTableRef}
          autoshowloadelement={autoshowloadelement}
          onRowselect={onRowselectChangedInternal}
          onRowunselect={onRowselectChangedInternal}
          {...props}
        />
      </div>
    </>
  );
};

TableFn.defaultProps = {
  altrows: true,
  pageable: true,
  filterable: true,
  sortable: true,
  autoheight: true,
  columnsresize: true,
  enabletooltips: true,
};

// const MemoizedJqxTable = React.memo(Table);

const ForwardedJqxTable = React.forwardRef<Table, TableProps>((props, ref) => (
  <TableFn jqxTableRef={ref} {...props} />
));

/**
 * This is the Table component before it was refactored. The reason why I kept this
 * is because the new Table hasn't been tested in all pages yet, so those pages will
 * continue using this original Table component.
 */
export default ForwardedJqxTable;
