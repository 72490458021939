import PropTypes from "prop-types";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { BotaoAprovacao } from "../Botoes/BotaoAprovacao"
import { BotaoReavaliacao } from "../Botoes/BotaoReavaliacao"
import { BotalVoltarAprovacao } from "../Botoes/BotalVoltarAprovacao";
import "./index.css";

function ToolbarAprovacao({
    aoAprovar, 
    aoReavaliar, 
    habilitarToolbarAprovacao,
    aoVoltar }){
    return (
    <Row className="pr-3 tbr-aprovacao-validacao">
        <Col className="mr-2 tbr-aprovacao-validacao-container">            
            <BotalVoltarAprovacao aoVoltar={() => aoVoltar()}/>
        </Col>
        {habilitarToolbarAprovacao === true ? (
            <Col className="mr-2 tbr-aprovacao-validacao-container">
                <BotaoReavaliacao aoReavaliar={() => aoReavaliar()} />
                <BotaoAprovacao aoAprovar={() => aoAprovar()} />
            </Col>) : <Col></Col>
        }
    </Row>)
}

ToolbarAprovacao.propTypes = {
    aoAprovar: PropTypes.func,
    aoReavaliar: PropTypes.func,
    aoVoltar: PropTypes.func
}

export { ToolbarAprovacao };
