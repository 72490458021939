import { jqx } from "jqwidgets-scripts/jqwidgets-react-tsx/jqxbuttons";
import React, { Component } from "react";
import { Breadcrumb, Card, Col, Row } from "react-bootstrap";
import { PrimaryButton } from "../../../../../components/Jqx/Button";
import { createControlColumn, Table } from "../../../../../components/Jqx/Table";
import { COLUNA_EXIBIR } from "../../../../../util/constantes";
import { resolverRequestRecmin } from "../../../../../util/ResolverRequestRecmin";

class HistoricoAlteracoes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      historico: [],
      historicoEntidadeSelecionada: [],
      historicoEntidadeSelecionadaRelacionamentosUnicos: [],
      historicoEntidadeSelecionadaRelacionamentosLista: [],
      registroSelecionado: [],
      caminhoAtualBreadCrumb: [],
      caminhoAtual: [],
      nomeEntidadeTabela: "",
      exibirTabelaCamposEntidade: false,
      exibirTabelaRelacionamentosUnicos: false,
      exibirTabelaRelacionamentosLista: false,
    };

    this.tableHistoricoRef = React.createRef();

    this.columnsHistorico = [
      createControlColumn({
        iconClasses: ["fas", "fa-eye", "text-primary"],
        dataField: COLUNA_EXIBIR,
        ref: this.tableHistoricoRef,
        onClick: this.onRowClick.bind(this),
      }),
      { text: "Autor", datafield: "usuarioAlteracao", width: "70%'" },
      { text: "Data", datafield: "dataAlteracao", width: "27%" },
    ];

    this.dataFieldsHistorico = [
      { name: "id", type: "string", map: "id" },
      { name: "dataAlteracao", type: "string", map: "dataAlteracao" },
      { name: "usuarioAlteracao", type: "string", map: "usuarioAlteracao" },
    ];

    this.tableHistoricoEntidadeSelecionadaRef = React.createRef();

    this.columnsHistoricoEntidadeSelecionada = [
      { text: "Campo", datafield: "apelido", width: "50%" },
      { text: "Valor anterior", datafield: "antigoValor", width: "25%" },
      { text: "Valor atribuido", datafield: "novoValor", width: "25%" },
    ];

    this.dataFieldsHistoricoEntidadeSelecionada = [
      { name: "id", type: "string", map: "id" },
      { name: "apelido", type: "string", map: "apelido" },
      { name: "antigoValor", type: "string", map: "antigoValor" },
      { name: "novoValor", type: "string", map: "novoValor" },
    ];

    // TODO: 2. Replace this with just the data.
    this.historicoEntidadeSelecionadaSource = {
      dataType: 'array',
      localdata: [],
      datafields: this.dataFieldsHistoricoEntidadeSelecionada
    };

    this.adaptadorTabelaHistoricoEntidadeSelecionada = new jqx.dataAdapter(this.historicoEntidadeSelecionadaSource);

    this.tableHistoricoEntidadeSelecionadaRelacionamentosUnicosRef = React.createRef();

    this.columnsHistoricoEntidadeSelecionadaRelacionamentosUnicos = [
      createControlColumn({
        iconClasses: ["fas", "fa-eye", "text-primary"],
        dataField: COLUNA_EXIBIR,
        ref: this.tableHistoricoEntidadeSelecionadaRelacionamentosUnicosRef,
        onClick: this.onRowClickEntidadeUnica.bind(this),
      }),
      { text: "Nome da entidade", datafield: "apelidoRelacionamento", width: "97%" },
    ];

    this.dataFieldsHistoricoEntidadeSelecionadaRelacionamentosUnicos = [
      { name: "id", type: "string", map: "id" },
      { name: "apelidoRelacionamento", type: "string", map: "apelidoRelacionamento" },
    ];

    // TODO: 3. Replace this with just the data.
    this.historicoEntidadeSelecionadaRelacionamentosUnicosSource = {
      dataType: 'array',
      localdata: [],
      datafields: this.dataFieldsHistoricoEntidadeSelecionadaRelacionamentosUnicos
    };

    this.adaptadorTabelaHistoricoEntidadeSelecionadaRelacionamentosUnicos = new jqx.dataAdapter(this.historicoEntidadeSelecionadaRelacionamentosUnicosSource);


    // TODO: 4. Replace this with just the data
    this.tableHistoricoEntidadeSelecionadaRelacionamentosListaRef = React.createRef();

    this.columnsHistoricoEntidadeSelecionadaRelacionamentosLista = [
      createControlColumn({
        iconClasses: ["fas", "fa-eye", "text-primary"],
        dataField: COLUNA_EXIBIR,
        ref: this.tableHistoricoEntidadeSelecionadaRelacionamentosListaRef,
        onClick: this.onRowClickEntidadeMultipla.bind(this),
        botaoAtivo: this.podeVisualizarEntidadePadrao.bind(this)
      }),
      { text: "Nome da entidade", datafield: "apelidoEntidade", width: "47%" },
      { text: "Chave da entidade", datafield: "chaveEntidade", width: "25%" },
      { text: "Operação", datafield: "operacao", width: "25%" },
    ];

    this.dataFieldsHistoricoEntidadeSelecionadaRelacionamentosLista = [
      { name: "id", type: "string", map: "id" },
      { name: "apelidoEntidade", type: "string", map: "apelidoEntidade" },
      { name: "chaveEntidade", type: "string", map: "chaveEntidade" },
      { name: "operacao", type: "string", map: "operacao" },
    ];

    this.historicoEntidadeSelecionadaRelacionamentosListaSource = {
      dataType: 'array',
      localdata: [],
      datafields: this.dataFieldsHistoricoEntidadeSelecionadaRelacionamentosLista
    };
    this.adaptadorTabelaHistoricoEntidadeSelecionadaRelacionamentosLista = new jqx.dataAdapter(this.historicoEntidadeSelecionadaRelacionamentosListaSource);
  }

  podeVisualizarEntidadePadrao(entidade) {
    if (entidade.operacao === "Atualizado") return true;
    if (entidade.operacao === "Adicionado") return true;
    if (entidade.operacao === "Removido") return false;
  };

  resetForm() {
    this.props.alterarExibirBotoesPadrao(true);

    this.setState({
      registroSelecionado: [],
      camposPrincipaisRegistroSelecionado: [],
      relacionamentosUnicosRegistroSelecionado: [],
      relacionamentosListaRegistroSelecionado: [],
      caminhoAtualBreadCrumb: [],
      caminhoAtual: [],
      historicoEntidadeSelecionada: [],
      historicoEntidadeSelecionadaRelacionamentosUnicos: [],
      historicoEntidadeSelecionadaRelacionamentosLista: [],
    })

  }

  async onRowClick(event) {
    const registroClicado = await event?.args?.row?.bounddata;
    if (!registroClicado?.id) {
      return;
    }
    let registroCompleto = await resolverRequestRecmin("/dadosAuditoria/buscarPorId/" + registroClicado?.id);

    if (registroCompleto && registroCompleto.alteracoes && registroCompleto.alteracoes.relacionamentosUnicos) {
      registroCompleto.alteracoes.relacionamentosUnicos.forEach(elemento => {
        elemento.apelidoEntidade = this.capitalize(elemento.apelidoEntidade);
        elemento.apelidoRelacionamento = this.capitalize(elemento.apelidoRelacionamento);
      });
    }

    let camposAlterados = registroCompleto.alteracoes.campos ?? [];
    let bibliotecasAlteradas = registroCompleto.alteracoes.bibliotecas;

    if (bibliotecasAlteradas) {
      bibliotecasAlteradas.map(biblioteca => {
        biblioteca.antigoValor = biblioteca.antigaDescricao;
        biblioteca.novoValor = biblioteca.novaDescricao;
        biblioteca.apelido = biblioteca.apelido ? biblioteca.apelido : biblioteca.nome;
        camposAlterados.push(biblioteca);
        return biblioteca;
      })
    }

    if (registroCompleto?.id?.toString() === registroClicado?.id?.toString()) this.setState({
      registroSelecionado: [registroCompleto],
      camposPrincipaisRegistroSelecionado: camposAlterados ?? [],
      relacionamentosUnicosRegistroSelecionado: registroCompleto.alteracoes.relacionamentosUnicos ?? [],
      nomeEntidadeTabela: `${registroCompleto.alteracoes.apelidoEntidade}`,
      exibirTabelaCamposEntidade: registroCompleto.alteracoes.campos ? registroCompleto.alteracoes.campos.length > 0 ?? false : false,
      exibirTabelaRelacionamentosUnicos: registroCompleto.alteracoes.relacionamentosUnicos ? registroCompleto.alteracoes.relacionamentosUnicos.length > 0 ?? false : false,
      caminhoAtualBreadCrumb: [...this.state.caminhoAtualBreadCrumb, <Breadcrumb.Item active>{registroCompleto.alteracoes.apelidoEntidade}</Breadcrumb.Item>],
      caminhoAtual: [...this.state.caminhoAtual, registroCompleto]

    })


    let atualizados = [];
    let removidos = [];
    let adicionados = [];
    let arrayFinal = [];

    this.state.registroSelecionado[0].alteracoes?.relacionamentosLista?.map(registro => {
      if (registro.atualizados && registro.atualizados.length > 0) {
        for (let i = 0; i < registro.atualizados.length; i++) {
          if(i >= 0){

            let campos = registro.atualizados[i].campos ?? [];

            if(registro.atualizados[i].bibliotecas && registro.atualizados[i].bibliotecas.length){
              registro.atualizados[i].bibliotecas.forEach((biblioteca) => {
                campos.push({
                  antigoValor: biblioteca.antigaDescricao,
                  apelido: biblioteca.apelido,
                  nome: biblioteca.nome,
                  novoValor: biblioteca.novaDescricao
                })
              });
            }

            atualizados.push(
              {
                id: registro.atualizados[i].id, chaveEntidade: registro.atualizados[i].chaveEntidade,
                apelidoEntidade: registro.atualizados[i].apelidoEntidade, operacao: "Atualizado",
                campos: campos,// registro.atualizados[i].campos ?? [],
                relacionamentosUnicos: registro.atualizados[i].relacionamentosUnicos ?? [],
                relacionamentosLista: registro.atualizados[i].relacionamentosLista ?? [],
              }); 
          }
        }
      }

      if (registro.removidos && registro.removidos.length > 0) {
        for (let i = 0; i < registro.removidos.length; i++) {
          i >= 0 &&
            removidos.push(
              {
                id: registro.removidos[i].id, chaveEntidade: registro.removidos[i].chaveEntidade,
                apelidoEntidade: registro.removidos[i].apelidoEntidade, operacao: "Removido",
                campos: registro.removidos[i].campos ?? [],
                relacionamentosUnicos: registro.removidos[i].relacionamentosUnicos ?? [],
                relacionamentosLista: registro.removidos[i].relacionamentosLista ?? [],
              })
        }
      }

      if (registro.adicionados && registro.adicionados.length > 0) {
        for (let i = 0; i < registro.adicionados.length; i++) {
          i >= 0 &&
            adicionados.push(
              {
                id: registro.adicionados[i].id, chaveEntidade: registro.adicionados[i].chaveEntidade,
                apelidoEntidade: registro.adicionados[i].apelidoEntidade, operacao: "Adicionado",
                campos: registro.adicionados[i].campos ?? [],
                relacionamentosUnicos: registro.adicionados[i].relacionamentosUnicos ?? [],
                relacionamentosLista: registro.adicionados[i].relacionamentosLista ?? [],
              })
        }
      }
    })

    arrayFinal = [atualizados, removidos, adicionados].flat()

    this.setState({
      relacionamentosListaRegistroSelecionado: arrayFinal ?? [],
      exibirTabelaRelacionamentosLista: arrayFinal.length > 0 ?? false,
      historicoEntidadeSelecionada: this.state.camposPrincipaisRegistroSelecionado,
      historicoEntidadeSelecionadaRelacionamentosUnicos: this.state.relacionamentosUnicosRegistroSelecionado,
      historicoEntidadeSelecionadaRelacionamentosLista: this.state.relacionamentosListaRegistroSelecionado,
    })

  }

  onRowClickEntidadeUnica(event) {
    let tempRegistro = [];
    if (event?.args?.row?.bounddata) {
      const registroClicado = event?.args?.row?.bounddata;
      this.state.relacionamentosUnicosRegistroSelecionado.map(registro => {
        if (registro.id.toString() === registroClicado.id.toString()) {
          tempRegistro = registro
        }
        return true;
      })
      this.setState({
        camposPrincipaisRegistroSelecionado: tempRegistro.campos ?? [],
        relacionamentosUnicosRegistroSelecionado: tempRegistro.relacionamentosUnicos ?? [],
        nomeEntidadeTabela: `${this.capitalize(tempRegistro.apelidoRelacionamento)}`,
        exibirTabelaCamposEntidade: tempRegistro.campos ? tempRegistro.campos.length > 0 ?? false : false,
        exibirTabelaRelacionamentosUnicos: tempRegistro.relacionamentosUnicos ? tempRegistro.relacionamentosUnicos.length > 0 ?? false : false,
        caminhoAtual: [...this.state.caminhoAtual, tempRegistro],
        caminhoAtualBreadCrumb: [...this.state.caminhoAtualBreadCrumb, <Breadcrumb.Item active>{this.capitalize(tempRegistro.apelidoRelacionamento)}</Breadcrumb.Item>]
      })
      let atualizados = [];
      let removidos = [];
      let adicionados = [];
      let arrayFinal = [];

      if (tempRegistro.relacionamentosLista) {

        tempRegistro.relacionamentosLista.map(registro => {
          if (registro.atualizados && registro.atualizados.length > 0) {
            for (let i = 0; i < registro.atualizados.length; i++) {
              i >= 0 &&
                atualizados.push(
                  {
                    id: registro.atualizados[i].id, chaveEntidade: registro.atualizados[i].chaveEntidade,
                    apelidoEntidade: registro.atualizados[i].apelidoEntidade, operacao: "Atualizado",
                    campos: registro.atualizados[i].campos ?? [],
                    relacionamentosUnicos: registro.atualizados[i].relacionamentosUnicos ?? [],
                    relacionamentosLista: registro.atualizados[i].relacionamentosLista ?? [],
                  })
            }
          }

          if (registro.removidos && registro.removidos.length > 0) {
            for (let i = 0; i < registro.removidos.length; i++) {
              i >= 0 &&
                removidos.push(
                  {
                    id: registro.removidos[i].id, chaveEntidade: registro.removidos[i].chaveEntidade,
                    apelidoEntidade: registro.removidos[i].apelidoEntidade, operacao: "Removido",
                    campos: registro.removidos[i].campos ?? [],
                    relacionamentosUnicos: registro.removidos[i].relacionamentosUnicos ?? [],
                    relacionamentosLista: registro.removidos[i].relacionamentosLista ?? [],
                  })
            }
          }

          if (registro.adicionados && registro.adicionados.length > 0) {
            for (let i = 0; i < registro.adicionados.length; i++) {
              i >= 0 &&
                adicionados.push(
                  {
                    id: registro.adicionados[i].id, chaveEntidade: registro.adicionados[i].chaveEntidade,
                    apelidoEntidade: registro.adicionados[i].apelidoEntidade, operacao: "Adicionado",
                    campos: registro.adicionados[i].campos ?? [],
                    relacionamentosUnicos: registro.adicionados[i].relacionamentosUnicos ?? [],
                    relacionamentosLista: registro.adicionados[i].relacionamentosLista ?? [],
                  })
            }
          }
          return true;
        })

      }

      arrayFinal = [atualizados, removidos, adicionados].flat()

      this.setState({
        relacionamentosListaRegistroSelecionado: arrayFinal ?? [],
        exibirTabelaRelacionamentosLista: arrayFinal.length > 0 ?? false,
        historicoEntidadeSelecionada: tempRegistro.campos ?? [],
        historicoEntidadeSelecionadaRelacionamentosUnicos: tempRegistro.relacionamentosUnicos ?? [],
        historicoEntidadeSelecionadaRelacionamentosLista: arrayFinal ?? [],
      })

    }
  }

  capitalize(word) {
    if (!word) return '';

    return word[0].toUpperCase() + word.slice(1).toLowerCase();
  }

  onRowClickEntidadeMultipla(event) {
    let tempRegistro = [];
    if (event?.args?.row?.bounddata) {
      const registroClicado = event?.args?.row?.bounddata;
      this.state.relacionamentosListaRegistroSelecionado.map(registro => {
        if (registro.id.toString() === registroClicado.id.toString()) {
          tempRegistro = registro
        }
        return true;
      })
    }

    this.setState({
      camposPrincipaisRegistroSelecionado: tempRegistro.campos ?? [],
      relacionamentosUnicosRegistroSelecionado: tempRegistro.relacionamentosUnicos ?? [],
      nomeEntidadeTabela: `${tempRegistro.apelidoEntidade}`,
      exibirTabelaCamposEntidade: tempRegistro.campos ? tempRegistro.campos.length > 0 ?? false : false,
      exibirTabelaRelacionamentosUnicos: tempRegistro.relacionamentosUnicos ? tempRegistro.relacionamentosUnicos.length > 0 ?? false : false,
      caminhoAtual: [...this.state.caminhoAtual, tempRegistro],
      caminhoAtualBreadCrumb: [...this.state.caminhoAtualBreadCrumb, <Breadcrumb.Item active>{tempRegistro.apelidoEntidade}</Breadcrumb.Item>]
    })

    let atualizados = [];
    let removidos = [];
    let adicionados = [];
    let arrayFinal = [];

    if (tempRegistro.relacionamentosLista) {

      tempRegistro.relacionamentosLista.map(registro => {
        if (registro.atualizados && registro.atualizados.length > 0) {
          for (let i = 0; i < registro.atualizados.length; i++) {
            i >= 0 &&
              atualizados.push(
                {
                  id: registro.atualizados[i].id, chaveEntidade: registro.atualizados[i].chaveEntidade,
                  apelidoEntidade: registro.atualizados[i].apelidoEntidade, operacao: "Atualizado",
                  campos: registro.atualizados[i].campos ?? [],
                  relacionamentosUnicos: registro.atualizados[i].relacionamentosUnicos ?? [],
                  relacionamentosLista: registro.atualizados[i].relacionamentosLista ?? [],
                })
          }
        }

        if (registro.removidos && registro.removidos.length > 0) {
          for (let i = 0; i < registro.removidos.length; i++) {
            i >= 0 &&
              removidos.push(
                {
                  id: registro.removidos[i].id, chaveEntidade: registro.removidos[i].chaveEntidade,
                  apelidoEntidade: registro.removidos[i].apelidoEntidade, operacao: "Removido",
                  campos: registro.removidos[i].campos ?? [],
                  relacionamentosUnicos: registro.removidos[i].relacionamentosUnicos ?? [],
                  relacionamentosLista: registro.removidos[i].relacionamentosLista ?? [],
                })
          }
        }

        if (registro.adicionados && registro.adicionados.length > 0) {
          for (let i = 0; i < registro.adicionados.length; i++) {
            i >= 0 &&
              adicionados.push(
                {
                  id: registro.adicionados[i].id, chaveEntidade: registro.adicionados[i].chaveEntidade,
                  apelidoEntidade: registro.adicionados[i].apelidoEntidade, operacao: "Adicionado",
                  campos: registro.adicionados[i].campos ?? [],
                  relacionamentosUnicos: registro.adicionados[i].relacionamentosUnicos ?? [],
                  relacionamentosLista: registro.adicionados[i].relacionamentosLista ?? [],
                })
          }
        }
        return true;
      })

    }

    arrayFinal = [atualizados, removidos, adicionados].flat()

    this.setState({
      relacionamentosListaRegistroSelecionado: arrayFinal ?? [],
      exibirTabelaRelacionamentosLista: arrayFinal.length > 0 ?? false,
      historicoEntidadeSelecionada: tempRegistro.campos ?? [],
      historicoEntidadeSelecionadaRelacionamentosUnicos: tempRegistro.relacionamentosUnicos ?? [],
      historicoEntidadeSelecionadaRelacionamentosLista: arrayFinal ?? [],
    })

  }




  voltarCaminho() {
    let tempArray = this.state.caminhoAtual;

    let atualizados = [];
    let removidos = [];
    let adicionados = [];
    let arrayFinal = [];

    tempArray.pop();
    if (tempArray.length === 0) {
      this.resetForm();
    } else {

      let tempCaminhoAtualBreadCrumb = this.state.caminhoAtualBreadCrumb;
      tempCaminhoAtualBreadCrumb.pop()
      this.setState({
        caminhoAtualBreadCrumb: tempCaminhoAtualBreadCrumb,
        caminhoAtual: tempArray
      })

      let tempRegistro = tempArray[tempArray.length - 1];

      if (tempRegistro.alteracoes) {
        this.setState({
          camposPrincipaisRegistroSelecionado: tempRegistro.alteracoes.campos ?? [],
          relacionamentosUnicosRegistroSelecionado: tempRegistro.alteracoes.relacionamentosUnicos ?? [],
          exibirTabelaCamposEntidade: tempRegistro.alteracoes.campos ? tempRegistro.alteracoes.campos.length > 0 ?? false : false,
          exibirTabelaRelacionamentosUnicos: tempRegistro.alteracoes.relacionamentosUnicos ? tempRegistro.alteracoes.relacionamentosUnicos.length > 0 ?? false : false,
          exibirTabelaRelacionamentosLista: tempRegistro.alteracoes.relacionamentosLista ? tempRegistro.alteracoes.relacionamentosLista.length > 0 ?? false : false,
          historicoEntidadeSelecionada: this.state.camposPrincipaisRegistroSelecionado,
          historicoEntidadeSelecionadaRelacionamentosUnicos: this.state.relacionamentosUnicosRegistroSelecionado,
        })

      } else {
        this.setState({
          camposPrincipaisRegistroSelecionado: tempRegistro.campos ?? [],
          relacionamentosUnicosRegistroSelecionado: tempRegistro.relacionamentosUnicos ?? [],
          exibirTabelaCamposEntidade: tempRegistro.campos ? tempRegistro.campos.length > 0 ?? false : false,
          exibirTabelaRelacionamentosUnicos: tempRegistro.relacionamentosUnicos ? tempRegistro.relacionamentosUnicos.length > 0 ?? false : false,
          exibirTabelaRelacionamentosLista: tempRegistro.relacionamentosLista ? tempRegistro.relacionamentosLista.length > 0 ?? false : false,
          historicoEntidadeSelecionada: this.state.camposPrincipaisRegistroSelecionado,
          historicoEntidadeSelecionadaRelacionamentosUnicos: this.state.relacionamentosUnicosRegistroSelecionado,
        })
      }

      if (tempRegistro.apelidoRelacionamento) {
        this.setState({
          nomeEntidadeTabela: this.capitalize(tempRegistro.apelidoRelacionamento)
        })
      } else if (tempRegistro.apelidoEntidade) {
        this.setState({
          nomeEntidadeTabela: tempRegistro.apelidoEntidade
        })
      } else {
        this.setState({
          nomeEntidadeTabela: tempRegistro.alteracoes.apelidoEntidade
        })
      }

      if (tempRegistro?.relacionamentosLista) {
        tempRegistro.relacionamentosLista.map(registro => {
          if (registro.atualizados && registro.atualizados.length > 0) {
            for (let i = 0; i < registro.atualizados.length; i++) {
              i >= 0 &&
                atualizados.push(
                  {
                    id: registro.atualizados[i].id, chaveEntidade: registro.atualizados[i].chaveEntidade,
                    apelidoEntidade: registro.atualizados[i].apelidoEntidade, operacao: "Atualizado",
                    campos: registro.atualizados[i].campos ?? [],
                    relacionamentosUnicos: registro.atualizados[i].relacionamentosUnicos ?? [],
                    relacionamentosLista: registro.atualizados[i].relacionamentosLista ?? [],
                  })
            }
          }

          if (registro.removidos && registro.removidos.length > 0) {
            for (let i = 0; i < registro.removidos.length; i++) {
              i >= 0 &&
                removidos.push(
                  {
                    id: registro.removidos[i].id, chaveEntidade: registro.removidos[i].chaveEntidade,
                    apelidoEntidade: registro.removidos[i].apelidoEntidade, operacao: "Removido",
                    campos: registro.removidos[i].campos ?? [],
                    relacionamentosUnicos: registro.removidos[i].relacionamentosUnicos ?? [],
                    relacionamentosLista: registro.removidos[i].relacionamentosLista ?? [],
                  })
            }
          }

          if (registro.adicionados && registro.adicionados.length > 0) {
            for (let i = 0; i < registro.adicionados.length; i++) {
              i >= 0 &&
                adicionados.push(
                  {
                    id: registro.adicionados[i].id, chaveEntidade: registro.adicionados[i].chaveEntidade,
                    apelidoEntidade: registro.adicionados[i].apelidoEntidade, operacao: "Adicionado",
                    campos: registro.adicionados[i].campos ?? [],
                    relacionamentosUnicos: registro.adicionados[i].relacionamentosUnicos ?? [],
                    relacionamentosLista: registro.adicionados[i].relacionamentosLista ?? [],
                  })
            }
          }
          return true;
        })

        arrayFinal = [atualizados, removidos, adicionados].flat()

        this.setState({
          relacionamentosListaRegistroSelecionado: arrayFinal,
          exibirTabelaRelacionamentosLista: arrayFinal.length > 0 ?? false,
          historicoEntidadeSelecionadaRelacionamentosLista: arrayFinal,
        })
      } else


        if (tempRegistro?.alteracoes?.relacionamentosLista) {
          tempRegistro.alteracoes.relacionamentosLista.map(registro => {
            if (registro.atualizados && registro.atualizados.length > 0) {
              for (let i = 0; i < registro.atualizados.length; i++) {
                i >= 0 &&
                  atualizados.push(
                    {
                      id: registro.atualizados[i].id, chaveEntidade: registro.atualizados[i].chaveEntidade,
                      apelidoEntidade: registro.atualizados[i].apelidoEntidade, operacao: "Atualizado",
                      campos: registro.atualizados[i].campos ?? [],
                      relacionamentosUnicos: registro.atualizados[i].relacionamentosUnicos ?? [],
                      relacionamentosLista: registro.atualizados[i].relacionamentosLista ?? [],
                    })
              }
            }

            if (registro.removidos && registro.removidos.length > 0) {
              for (let i = 0; i < registro.removidos.length; i++) {
                i >= 0 &&
                  removidos.push(
                    {
                      id: registro.removidos[i].id, chaveEntidade: registro.removidos[i].chaveEntidade,
                      apelidoEntidade: registro.removidos[i].apelidoEntidade, operacao: "Removido",
                      campos: registro.removidos[i].campos ?? [],
                      relacionamentosUnicos: registro.removidos[i].relacionamentosUnicos ?? [],
                      relacionamentosLista: registro.removidos[i].relacionamentosLista ?? [],
                    })
              }
            }

            if (registro.adicionados && registro.adicionados.length > 0) {
              for (let i = 0; i < registro.adicionados.length; i++) {
                i >= 0 &&
                  adicionados.push(
                    {
                      id: registro.adicionados[i].id, chaveEntidade: registro.adicionados[i].chaveEntidade,
                      apelidoEntidade: registro.adicionados[i].apelidoEntidade, operacao: "Adicionado",
                      campos: registro.adicionados[i].campos ?? [],
                      relacionamentosUnicos: registro.adicionados[i].relacionamentosUnicos ?? [],
                      relacionamentosLista: registro.adicionados[i].relacionamentosLista ?? [],
                    })
              }
            }
            return true;
          })

          arrayFinal = [atualizados, removidos, adicionados].flat()

          this.setState({
            relacionamentosListaRegistroSelecionado: arrayFinal,
            exibirTabelaRelacionamentosLista: arrayFinal.length > 0 ?? false,
            historicoEntidadeSelecionadaRelacionamentosLista: arrayFinal,
          })
        } else {
          this.setState({
            relacionamentosListaRegistroSelecionado: arrayFinal,
            exibirTabelaRelacionamentosLista: arrayFinal.length > 0 ?? false,
            historicoEntidadeSelecionadaRelacionamentosLista: arrayFinal,
          })
        }
    };
  }

  async componentDidMount() {
    this.setState({
      historico: this.props.registros.reverse()
    })
  }



  async componentDidUpdate(previousProps, previousState) {
    const historicoAntigo = previousProps?.registros.reverse();
    const historicoAtual = this.props?.registros.reverse();
    if (historicoAntigo.length !== historicoAtual.length && historicoAtual) {
      this.setState({
        historico: historicoAtual,
      });
    }
  }

  render() {
    return (
      <>
        <Row>
          <Col md={12}>
            <Card.Title>{`Histórico de alterações`}</Card.Title>
          </Col>
        </Row>
        {this.state.registroSelecionado.length > 0 &&
          <>
            <hr className="mt-0" />
            <Row className="mt-3">
              <Col md={12}>
                <Card.Subtitle>{`Alterado por ${this.state.registroSelecionado[0]?.usuarioAlteracao} em ${this.state.registroSelecionado[0]?.dataAlteracao}`}</Card.Subtitle>
              </Col>
            </Row>
          </>

        }
        {this.state.registroSelecionado.length === 0 && <Row className="mt-4">
          <Col md={12}>
            <Table
              ref={this.tableHistoricoRef}
              columns={this.columnsHistorico}
              datafields={this.datafieldsHistorico}
              items={this.state.historico}
              onRowclick={(event) => { this.onRowClick(event); this.props.alterarExibirBotoesPadrao(false) }}
            />
          </Col>
        </Row>}

        {this.state.registroSelecionado.length > 0 && <>
          <Row className="mt-4">
            <Col md={12} >
              {this.state.caminhoAtualBreadCrumb.length > 0 &&
                <Breadcrumb>
                  {this.state.caminhoAtualBreadCrumb}
                </Breadcrumb>
              }
            </Col>
          </Row>

          {this.state.exibirTabelaCamposEntidade && <>
            <Row className="mt-4">
              <Col md={12} >
                <Card.Subtitle>{`Campos da entidade ${this.state.nomeEntidadeTabela} alterados`}</Card.Subtitle>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col md={12}>
                <Table
                  ref={this.tableHistoricoEntidadeSelecionadaRef}
                  columns={this.columnsHistoricoEntidadeSelecionada}
                  datafields={this.dataFieldsHistoricoEntidadeSelecionada}
                  items={this.state.historicoEntidadeSelecionada}
                  onRowclick={(event) => this.onRowClick(event)}
                />
              </Col>
            </Row>
          </>}
          {this.state.exibirTabelaRelacionamentosUnicos && <>
            <Row className="mt-4">
              <Col md={12} >
                <Card.Subtitle>{`Entidades de ${this.state.nomeEntidadeTabela} alterados`}</Card.Subtitle>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col md={12}>
                <Table
                  ref={this.tableHistoricoEntidadeSelecionadaRelacionamentosUnicosRef}
                  columns={this.columnsHistoricoEntidadeSelecionadaRelacionamentosUnicos}
                  datafields={this.dataFieldsHistoricoEntidadeSelecionadaRelacionamentosUnicos}
                  items={this.state.historicoEntidadeSelecionadaRelacionamentosUnicos}
                  onRowclick={(event) => this.onRowClickEntidadeUnica(event)}
                />
              </Col>
            </Row>
          </>}
          {this.state.exibirTabelaRelacionamentosLista && <>
            <Row className="mt-4">
              <Col md={12}>
                <Card.Subtitle>{`Listas de ${this.state.nomeEntidadeTabela} alterados`}</Card.Subtitle>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col md={12}>
                <Table
                  ref={this.tableHistoricoEntidadeSelecionadaRelacionamentosListaRef}
                  columns={this.columnsHistoricoEntidadeSelecionadaRelacionamentosLista}
                  datafields={this.dataFieldsHistoricoEntidadeSelecionadaRelacionamentosLista}
                  items={this.state.historicoEntidadeSelecionadaRelacionamentosLista}
                  onRowclick={(event) => this.onRowClickEntidadeMultipla(event)}
                />
              </Col>
            </Row>
          </>}
          <Row className="mt-4">
            <Col md={12}>
              <PrimaryButton
                className={`float-right mb-2 ml-2`}
                onClick={this.resetForm.bind(this)}
                titulo="Fechar"
              />
              <PrimaryButton
                className={`float-right mb-2`}
                onClick={this.voltarCaminho.bind(this)}
                titulo="Voltar"
              />
            </Col>
          </Row>
        </>
        }
      </>
    );
  }
}
export default HistoricoAlteracoes;
