import React, { Component } from "react";
import Service, { PETRO } from "../../service/Service";
import { Field, Form as FForm } from "react-final-form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import BlockUi from "react-block-ui";
import PageScaffold from "../../components/scaffold/PageScaffold";
import { setValue } from "../../util/mutadores";
import PrimaryButton from "../../components/Jqx/Button/PrimaryButton";
import { mostrarNotificacao } from "../../reducers/notificacaoReducer";
import { connect } from "react-redux";
import { scrollAoErro } from "../../util/validadores";
import { selecionarVisita } from "../../reducers/visitaReducer";
import TabelasDadosAnaliticos from "./TabelasDadosAnaliticos";
import InfoPanelGPS from "../../components/infoPanelGPS";

const mapDispatchToProps = (dispatch) => {
  return {
    enviarNotificacaoSucesso(mensagem) {
      dispatch(
        mostrarNotificacao(mensagem, {
          tipo: "success",
        })
      );
    },
    selecionarVisita(visita) {
      dispatch(selecionarVisita(visita))
    },
    enviarAlertaErro(erro) {
      dispatch(mostrarNotificacao(erro))
    }
  };
};


class RegistrarDadosAnaliticos extends Component {
  constructor(props) {
    super(props);
    this.servicoAmostra = Service("/amostras");
    this.servicoAmostraHistorica = Service("/amostras-historicas");

    this.state = {
      nomeProjeto: this.props.location.state.nomeProjeto,
      nomeArea: this.props.location.state.nomeArea,
      nomeSubArea: this.props.location.state.nomeSubArea,
      idArea: this.props.location.state.idArea,
      idSubArea: this.props.location.state.idSubArea,
      idAmostraSelecionada: this.props.location.state.idAmostraSelecionada,
      isAmostraHistorica: this.props.location.state.isAmostraHistorica,
      idProjetoHistorico: this.props.location.state.idProjetoHistorico,
      idProjeto: this.props.location.state.idProjeto,
      amostras: null,
      projetos: []
    };
    this.voltar = this.voltar.bind(this);
    this.enviarFormulario = this.enviarFormulario.bind(this);
    this.subformAberto = false;
  }

  async componentDidMount() {
    if (localStorage.getItem("LAST_PAGE") == 'estacoes')
      localStorage.removeItem('coodernadasVoltar');

    var respostaAmostras = null
    var isAmostraHistorica = this.props.location.state.isAmostraHistorica
    var idArea = this.props.location.state.idArea
    var idSubArea = this.props.location.state.idSubArea


    if (isAmostraHistorica) {
      respostaAmostras = await this.servicoAmostraHistorica.customURL(
        "GET",
        `?idArea=${idArea}&idSubArea=${idSubArea}`
      );
    } else {
      respostaAmostras = await this.servicoAmostra.customURL(
        "GET",
        `?idArea=${idArea}&idSubArea=${idSubArea}`
      );
    }

    const amostras = respostaAmostras.data.dados;
    const res = await Service('/projetos', 'basegeo').listar();
    const projetos = res.data.dados;

    this.setState({ amostras: amostras, projetos: projetos, carregamentoGlobal: false });
  }

  async enviarFormulario(valores) {
    this.setState({ carregamentoGlobal: true });
    console.log('enviarFormulario', valores)
  }

  async voltar(form) {
    window.scrollTo(0, 0);
    let lastPage = localStorage.getItem("LAST_PAGE");

    if (!lastPage || lastPage === 'analiticos')
      this.props.history.push(`/dados-analiticos`);
    else {
      this.props.history.goBack();
    }
  }

  renderForm = ({ form }) => {
    const { submit } = form;

    const BotoesVoltarSalvar = ({ exibirBotaoSalvar = true }) => (
      <Row className="pr-3 subform-btns-padrao subform-btns-padrao-estacao">
        <Col className="mr-2">
          {this.permitirEdicao && exibirBotaoSalvar && (
            <PrimaryButton
              className="float-right m-1 ml-2 subform-btn-confirmar subform-btn-confirmar-estacao"
              onClick={() => {
                console.log('Salvar')
                scrollAoErro(document.querySelector('.subform-nome-estacao'));
              }}
              titulo="Salvar"
            />
          )}
          <PrimaryButton
            className="float-right m-1 mr-2 subform-btn-voltar subform-btn-voltar-estacao"
            onClick={() => this.voltar(form)}
            titulo="Voltar"
          />
        </Col>
      </Row>
    );
    return (
      <>

        <Field name="analiticos" subscription={{ value: true }}>
          {({ input: { value: analiticos = {} } }) => {
            return (
              <TabelasDadosAnaliticos
                permitirEdicao={true}
                valoresIniciais={[]}
                visitas={analiticos}
                idProjetoCorrente={this.state.idProjeto}
                idProjetoHistorico={this.state.idProjetoHistorico}
                projetos={this.state.projetos}
                idEstacao={this.idEstacao}
                nomeProjeto={this.state.nomeProjeto}
                // JSX
                BotoesVoltarSalvar={BotoesVoltarSalvar}
                area={form.getState().values.idArea}
                subArea={form.getState().values.idSubArea}
                history={this.props.history}
                amostras={this.state.amostras}
                isAmostraHistorica={this.state.isAmostraHistorica}
                idAmostraSelecionada={this.state.idAmostraSelecionada}
              />
            );
          }}
        </Field>
      </>
    );
  };
  render() {
    return (
      <BlockUi blocking={this.state.carregamentoGlobal}>
        <PageScaffold titulo="Cadastrar dados analíticos">

          <Container className="subform subform-nome-estacao subform-aberto">
            <InfoPanelGPS />
            <FForm
              onSubmit={this.enviarFormulario}
              initialValues={this.valoresIniciais}
              subscription={{}}
              mutators={{ setValue }}
              render={this.renderForm}
            />
          </Container>
        </PageScaffold>
      </BlockUi>
    );
  }
}

export default connect(null, mapDispatchToProps)(RegistrarDadosAnaliticos);
