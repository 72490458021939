import React from "react";
import {Card as BCard} from "react-bootstrap";
import "./style.css"

export default class Card extends React.Component {

    static Title = ({children}) => <BCard.Title>{children}</BCard.Title>;
    static Subtitle = ({children}) => <BCard.Subtitle>{children}</BCard.Subtitle>;
    static Body = ({children}) => <BCard.Body className="card-content">{children}</BCard.Body>;
    static LinkBody = ({children}) => <BCard.LinkBody>{children}</BCard.LinkBody>;
    static Text = ({children}) => <BCard.Text>{children}</BCard.Text>;
    static Header = ({children}) => <BCard.Header>{children}</BCard.Header>;
    static Footer = ({children}) => <BCard.Footer>{children}</BCard.Footer>;
    static ImgOverlay = ({children}) => <BCard.ImgOverlay>{children}</BCard.ImgOverlay>;
    
    render(){return <BCard className={`br-card ${this.props.className || ""}`}>{this.props.children}</BCard>}
}