import { PureComponent } from "react";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { AmostraSubFormPart } from "../components/AmostraSubFormPart";
import {
  amostraDataFieldsCommon,
  AmostraSymbol,
  amostrasLabelsMap,
} from "../constants/amostras.constant";
import { NivelAguaField } from "../components/NivelAguaField";
import { PosicaoLeitoField } from "../components/PosicaoLeitoField";
import { AreaDrenagemField } from "../components/AreaDrenagemField";
import { TurbidezAguaField } from "../components/TurbidezAguaField";
import { CorSampleField } from "../components/CorSampleField";
import { SituacaoAmostraField } from "../components/SituacaoAmostraField";
import { MatrizPredominanteField } from "../components/MatrizPredominanteField";
import { GrauIntemperismoField } from "../components/GrauIntemperismoField";
import { VelocidadeCorrenteField } from "../components/VelocidadeCorrenteField";
import { TipoMineralField } from "../components/TipoMineralField";
import { ModoDepositoField } from "../components/ModoDepositoField";
import { LarguraRioField } from "../components/LarguraRioField";
import { ProfundidadeRioField } from "../components/ProfundidadeRioField";
import { AmbienteBioticoField } from "../components/AmbienteBioticoField";
import { ArredondamentoField } from "../components/ArredondamentoField";
import { TipoAlteracaoField } from "../components/TipoAlteracaoField";
import { FormaDepositoField } from "../components/FormaDepositoField";
import { ImagensAmostraSubForm } from "../components/ImagensAmostraSubForm";
import { OutrosSampleGeoquimicaModelLike } from "../../../../../models/activity-geoquimica.model";
import { AmostraGridSubForm } from "../components/AmostraGridSubForm";

export interface OutrosSectionFormProps {
  prefixoNome: string;
  items?: OutrosSampleGeoquimicaModelLike[];
  onItemsChanged?: (items: OutrosSampleGeoquimicaModelLike[]) => void;
}

export interface OutrosSectionFormState {}

export class OutrosSectionForm extends PureComponent<
  OutrosSectionFormProps,
  OutrosSectionFormState
> {
  datafieldsOutros = [
    ...amostraDataFieldsCommon,
    {
      name: "id_situacao_amostra",
      type: "number",
      map: "id_situacao_amostra",
    },
    {
      name: "id_matriz_predominante",
      type: "number",
      map: "id_matriz_predominante",
    },
    {
      name: "id_grau_intemperismo",
      type: "number",
      map: "id_grau_intemperismo",
    },
    {
      name: "id_tipo_mineral",
      type: "number",
      map: "id_tipo_mineral",
    },
    {
      name: "id_modo_deposito",
      type: "number",
      map: "id_modo_deposito",
    },
    {
      name: "nu_largura_rio",
      type: "number",
      map: "nu_largura_rio",
    },
    {
      name: "nu_profundidade_rio",
      type: "number",
      map: "nu_profundidade_rio",
    },
    {
      name: "id_velocidade_corrente",
      type: "number",
      map: "id_velocidade_corrente",
    },
    {
      name: "id_nivel_agua",
      type: "string",
      map: "id_nivel_agua",
    },
    {
      name: "id_area_drenagem",
      type: "number",
      map: "id_area_drenagem",
    },
    {
      name: "id_turbidez_agua",
      type: "number",
      map: "id_turbidez_agua",
    },
    {
      name: "id_posicao_leito",
      type: "string",
      map: "id_posicao_leito",
    },
    {
      name: "id_cor_agua",
      type: "string",
      map: "id_cor_agua",
    },
    {
      name: "id_cor_material_coletado",
      type: "string",
      map: "id_cor_material_coletado",
    },
    {
      name: "id_ambiente_biotico",
      type: "string",
      map: "id_ambiente_biotico",
    },
    {
      name: "id_arredondamento",
      type: "string",
      map: "id_arredondamento",
    },
    {
      name: "id_tipo_alteracao",
      type: "string",
      map: "id_tipo_alteracao",
    },
    {
      name: "id_forma_deposito",
      type: "string",
      map: "id_forma_deposito",
    },
  ];

  amostraSymbol = AmostraSymbol.Outros;

  constructor(props) {
    super(props);
    this.state = {};
  }

  renderForm = ({ editando, prefixoNome }) => {
    return (
      <>
        <AmostraSubFormPart
          prefixoNome={prefixoNome}
          amostraSymbol={this.amostraSymbol}
          profundidadeMinRequired={true}
          profundidadeMaxRequired={true}
        />
        <Row className="my-3">
          <Col>
            <SituacaoAmostraField prefixoNome={prefixoNome} required={true} />
          </Col>
          <Col>
            <MatrizPredominanteField
              prefixoNome={prefixoNome}
              required={true}
            />
          </Col>
          <Col>
            <GrauIntemperismoField prefixoNome={prefixoNome} required={true} />
          </Col>
        </Row>
        <Row className="my-3">
          <Col>
            <TipoMineralField prefixoNome={prefixoNome} required={true} />
          </Col>
          <Col>
            <ModoDepositoField prefixoNome={prefixoNome} required={true} />
          </Col>
          <Col>
            <LarguraRioField prefixoNome={prefixoNome} required={true} />
          </Col>
        </Row>
        <Row className="my-3">
          <Col>
            <ProfundidadeRioField prefixoNome={prefixoNome} required={true} />
          </Col>
          <Col>
            <VelocidadeCorrenteField
              prefixoNome={prefixoNome}
              required={true}
            />
          </Col>
          <Col>
            <NivelAguaField prefixoNome={prefixoNome} required={true} />
          </Col>
        </Row>
        <Row className="my-3">
          <Col>
            <AreaDrenagemField prefixoNome={prefixoNome} required={true} />
          </Col>
          <Col>
            <TurbidezAguaField prefixoNome={prefixoNome} required={true} />
          </Col>
          <Col>
            <PosicaoLeitoField prefixoNome={prefixoNome} required={true} />
          </Col>
        </Row>
        <Row className="my-3">
          <Col>
            <CorSampleField
              prefixoNome={prefixoNome}
              name="id_cor_agua"
              label="Cor da água"
              required={true}
            />
          </Col>
          <Col>
            <CorSampleField
              prefixoNome={prefixoNome}
              name="id_cor_material_coletado"
              label="Cor do material coletado"
              required={true}
            />
          </Col>
          <Col>
            <AmbienteBioticoField prefixoNome={prefixoNome} required={true} />
          </Col>
        </Row>
        <Row className="my-3">
          <Col>
            <ArredondamentoField prefixoNome={prefixoNome} required={true} />
          </Col>
          <Col>
            <TipoAlteracaoField prefixoNome={prefixoNome} required={true} />
          </Col>
          <Col>
            <FormaDepositoField prefixoNome={prefixoNome} required={true} />
          </Col>
        </Row>
        <Row className="my-3">
          <Col>
            <ImagensAmostraSubForm prefixoNome={prefixoNome} />
          </Col>
        </Row>
      </>
    );
  };

  render() {
    return (
      <>
        <Card className="my-3">
          <Card.Body>
            <Card.Subtitle>
              {amostrasLabelsMap.get(this.amostraSymbol)}
            </Card.Subtitle>
            <AmostraGridSubForm
              name="amostraOutros"
              datafields={this.datafieldsOutros}
              items={this.props.items}
              onItemsChanged={this.props.onItemsChanged}
              renderForm={this.renderForm}
            />
          </Card.Body>
        </Card>
      </>
    );
  }
}
