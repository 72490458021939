import { connect } from "react-redux";
import JqxNotification from "jqwidgets-scripts/jqwidgets-react-tsx/jqxnotification";
import React from "react";
import "./Notificacao.css"

class Notification extends React.Component {
  constructor(props) {
    super(props);

    this.notificationRef = React.createRef();
    this.notificationMessage = '';
  }

  render() {
    const notificacao = this.props.notification;

    if (notificacao && notificacao.forcarExibicao){
      const key = notificacao.mensagem;

      return <JqxNotification 
        ref={this.notificationRef} 
        key={key}
        width={250} 
        position={'top-right'} 
        opacity={0.9} 
        autoOpen={false}
        autoClose={true} 
        animationOpenDelay={800} 
        autoCloseDelay={8000}
        template={notificacao.tipo}
      >
        <div id='message-container'>{notificacao.mensagem}</div>
      </JqxNotification>
    }

    if (notificacao && notificacao.mensagem !== this.notificationMessage) {
      this.notificationMessage = notificacao.mensagem;

      /* atualizar o key quando a mensagem muda força a reconstrução dos filhos do componente, nesse caso a mensagem */
      const key = notificacao.mensagem;

      return <JqxNotification 
        ref={this.notificationRef} 
        key={key}
        width={250} 
        position={'top-right'} 
        opacity={0.9} 
        autoOpen={false}
        autoClose={true} 
        animationOpenDelay={800} 
        autoCloseDelay={8000}
        template={notificacao.tipo}
      >
        <div id='message-container'>{notificacao.mensagem}</div>
      </JqxNotification>
    }

    return <></>
  }

  componentDidUpdate() {
    if (this.notificationRef.current) {
      this.notificationRef.current.open();
    }
  }
}

const mapStateToProps = (state) => ({
  notification: state.notificacoes.notificacaoAtual,
});

export default connect(
  mapStateToProps,
  null
)(Notification);
