import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Field, useForm } from "react-final-form";
import { aoEnviarSubForm } from "../../../util/mutadores";
import Card from "../../../components/card";
import { SubForm } from "../../../components/SubForm";
import { DropdownListField } from "../../../components/field/DropdownListField";
import { NumberInputField } from "../../../components/field/NumberInputField";
import { resolverExibidos } from "../../../components/SubForm/SubForm";
import { toInteger } from "lodash";
import { FORM_ERROR } from "final-form";
import { campoObrigatorioComMsgGenerica } from "../../../util/validadores";

const MineraisAlteracao = ({
    form,
    bbMineraisAlteracao,
    somaTotalPercentuais,
    disabled,
    permitirVizualizacao,
    permitirInsercao
}) => {

    const onSubmit = aoEnviarSubForm(form);

    const colunasMineraisAlteracao = [
        {
            text: 'Minerais de Alteração',
            datafield: 'idMineraisAlteracao',
            align: 'center',
            width: '50%',
            cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
                const mineraisAlteracaoId = parseInt(rowdata?.idMineraisAlteracao, 10);
                const objetoFiltrado = bbMineraisAlteracao.find(elemento => elemento.id === mineraisAlteracaoId);
                const valor = objetoFiltrado ? objetoFiltrado.nome : '';
                return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;text-align:center">${valor}</div>`;
            }
        },
        {
            text: "% na fração", datafield: "numeroPercentual", width: '50%', align: "center",
            cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
                let valor = "";
                if (value === 0 || value === "0") {
                    valor = "Acessório";
                } else {
                    valor = value;
                }
                return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;text-align:center">${valor}</div>`;
            }
        },
    ]
    const camposMicroestruturas = [
        { name: "idMineraisAlteracao", type: "number", map: "idMineraisAlteracao" },
        { name: "numeroPercentual", type: "number", map: "numeroPercentual" },
    ];

    const validarMineralRocha = (valores) => {
        const itens = form.getState().values?.mineraisAlteracao;

        if (itens) {
            const composicoes = resolverExibidos(itens);

            const indexExistente = composicoes.findIndex(item => item.id === valores.id);

            if (indexExistente !== -1) {

                composicoes[indexExistente] = valores;
            } else {

                composicoes.push(valores);
            }

            if (composicoes && composicoes.length > 0) {
                const somaPercentuais = composicoes.reduce((total, item) => {

                    if (item.numeroPercentual) {
                        return total + toInteger(item.numeroPercentual);
                    }
                    return total;
                }, 0);

                if (somaPercentuais > 100) {
                    return { [FORM_ERROR]: 'A soma dos percentuais de minerais de alteração está ultrapassando os 100%.' };
                }
            }
            return null;
        }
    };

    const valorFormulario = useForm()
    return (
        <>
            <div style={{ marginTop: '2.5rem' }}>
                <Card className="mt-3">
                    <Card.Body>
                        <Card.Title>Minerais de alteração</Card.Title>
                        {(somaTotalPercentuais != null && somaTotalPercentuais < 100) && (
                            <span className="erro-campo" style={{ color: 'blue', fontSize: '10px' }}>
                                A soma total dos percentuais de Minerais de alteração é de: {somaTotalPercentuais}%, mas deve ser 100%.
                            </span>
                        )}
                        <Field name={'mineraisAlteracao'} subscription={{ value: true }}>
                            {({ input: { value: mineraisAlteracao = [] } }) => {

                                return (<>
                                    <SubForm
                                        nome='mineraisAlteracao'
                                        onSubmit={onSubmit}
                                        exibirTabelaSemRegistro={false}
                                        modoSubFormComGrid
                                        colunas={colunasMineraisAlteracao}
                                        elementos={mineraisAlteracao}
                                        campos={camposMicroestruturas}
                                        validar={(valores) => validarMineralRocha(valores)}
                                        permitirVisualizacao={permitirVizualizacao}
                                        podeExcluirEntidade={!disabled}
                                        permitirInsercao={permitirInsercao}
                                        exibirBotaoInsercao={!disabled}
                                        permitirEdicao={!disabled}
                                        permitirExclusao={!disabled}                                      
                                        renderForm={({ formProps: { form } }) => {
                                            var valorAtual = form.getState().values?.idMineraisAlteracao
                                            var composicoes = valorFormulario?.getState().values?.mineraisAlteracao
                                            var composicoesTela = []
                                            if (composicoes) {
                                                composicoesTela = resolverExibidos(composicoes)
                                            }
                                            var mineraisAlteracaoFiltrados = bbMineraisAlteracao.filter(value => {
                                                return !composicoesTela.some(testeItem => testeItem.idMineraisAlteracao === value.id && valorAtual !== value.id);
                                            });
                                            return (
                                                <div style={{ display: "flex", flexDirection: "column" }}>
                                                    <Row className="my-3">
                                                        <Col md={6}  >
                                                            <Field
                                                                component={DropdownListField}
                                                                name="idMineraisAlteracao"
                                                                label="Fases Minerais"
                                                                dica="Selecione um ou mais minerais que compõem a assembleia mineral da alteração hidrotermal."
                                                                elementos={mineraisAlteracaoFiltrados}
                                                                displayMember='nome'
                                                                validate={campoObrigatorioComMsgGenerica("Fases Minerais")}
                                                                required
                                                                disabled={disabled}
                                                            />
                                                        </Col>
                                                        <Col md={2}>
                                                            <Field
                                                                name="numeroPercentual"
                                                                component={NumberInputField}
                                                                label="% na fração"
                                                                dica="Estime em porcentagem a proporção deste mineral de alteração hidrotermal..."
                                                                validate={campoObrigatorioComMsgGenerica("% na fração")}
                                                                permitirNegativos={false}
                                                                casasInteiros={3}
                                                                casasDecimais={0}
                                                                valorMaximo={100}
                                                                disabled={disabled}
                                                                required
                                                            />
                                                        </Col>
                                                    </Row>
                                                </div>
                                            )
                                        }}
                                    />
                                </>)
                            }}
                        </Field>
                    </Card.Body>
                </Card>
            </div>
        </>
    );
};

export default MineraisAlteracao;