// Arquivo utilizado para definir validadores do final-form, swals e outras funções
// utilitárias relacionadas a validação de Amostras

import dayjs from "dayjs"
import { FORM_ERROR } from "final-form"
import swal from "sweetalert"

export const validaProfundidadeMinima = ((value, allValues, fieldState) => {
    if(!allValues || !fieldState) return;

    let clAmostra = fieldState.name.split('.')[0];
    clAmostra = allValues[clAmostra];

    if(!clAmostra) return;

    let profundidadeMinima = clAmostra.profundidadeMinima;
    let profundidadeMaxima = clAmostra.profundidadeMaxima;

    if(profundidadeMinima 
        && profundidadeMaxima
        && profundidadeMinima > profundidadeMaxima){
            return "A profundidade mínima deve ser inferior à profundidade máxima";
        }
  }  
);

export const validaProfundidadeMaxima = ((value, allValues, fieldState) => {
    if(!allValues || !fieldState) return;

    let clAmostra = fieldState.name.split('.')[0];
    clAmostra = allValues[clAmostra];

    if(!clAmostra) return;

    let profundidadeMinima = clAmostra.profundidadeMinima;
    let profundidadeMaxima = clAmostra.profundidadeMaxima;

    if(profundidadeMinima 
        && profundidadeMaxima
        && profundidadeMinima > profundidadeMaxima){
            return "A profundidade máxima deve ser superior à profundidade mínima";
        }
  }  
);