import createDecorator from "final-form-calculate";
import { get } from "lodash";
import { HEMISFERIO_LESTE, HEMISFERIO_SUL } from "../constantes";

export const getPropsPorHemisferio = (hemisferio) => {
  if (!hemisferio) return { min: -90, max: 90, disabled: true };

  if (hemisferio === HEMISFERIO_SUL.id) return { min: -90, max: 0, defaultNegativo: true };
  else return { min: 0, max: 90 }; // NORTE
}

export const getPropsPorHemisferioHorizontal = (hemisferioHorizontal) => {
  if (!hemisferioHorizontal) return { min: -180, max: 180, disabled: true };

  if (hemisferioHorizontal === HEMISFERIO_LESTE.id) return { min: 0, max: 180 };
  else return { min: -180, max: 0, defaultNegativo: true }; // OESTE
}

export const resetarLatLongInvalidaAoTrocarHemisferio = ({ prefixo = 'coordenadas', } = {}) => {
  const prefixoComPonto = prefixo ? prefixo + '.' : '';

  return createDecorator({
    field: [`${prefixoComPonto}hemisferio`, `${prefixoComPonto}hemisferioHorizontal`],
    updates: (value, name, allValues) => {
      if (!value) return {};

      const latitude = get(allValues, `${prefixoComPonto}latitude`);
      const longitude = get(allValues, `${prefixoComPonto}longitude`);

      // Verifica se a lat/lon passou do min/max permitido pelo hemisfério selecionado e reseta o valor caso fora do intervalo
      const novasCoordenadas = {};
      if (name === `${prefixoComPonto}hemisferio`) {
        const minMax = getPropsPorHemisferio(value);
        if (latitude < minMax.min || latitude > minMax.max) {
          novasCoordenadas[`${prefixoComPonto}latitude`] = latitude * -1;
        }
      } else if (name === `${prefixoComPonto}hemisferioHorizontal`) {
        const minMax = getPropsPorHemisferioHorizontal(value);
        if (longitude < minMax.min || longitude > minMax.max) {
          novasCoordenadas[`${prefixoComPonto}longitude`] = longitude * -1;
        }
      }

      // Se lat ou lon foi resetado, devemos resetar também os campos que dependem de uma coordenada válida
      if (Object.keys(novasCoordenadas).length > 0) {
        const latitudeSIRGAS2000 = get(allValues, `${prefixoComPonto}latitudeSIRGAS2000`);
        const longitudeSIRGAS2000 = get(allValues, `${prefixoComPonto}longitudeSIRGAS2000`);
        if (latitudeSIRGAS2000) novasCoordenadas[`${prefixoComPonto}latitudeSIRGAS2000`] = null;
        if (longitudeSIRGAS2000) novasCoordenadas[`${prefixoComPonto}longitudeSIRGAS2000`] = null;
      }
      return novasCoordenadas;
    }
  });
};
