import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Form from "react-bootstrap/Form";
import Hint from "../../hint/Hint";
import JqxRadioButton from "jqwidgets-scripts/jqwidgets-react-tsx/jqxradiobutton";
import Erro from "../../Erro";

const RadioButton = ({titulo, elementos, checked, valor, label, dica, mensagemErro, onClick, required, ...props}) => {
  const [autoFocus, setAutoFocus] = useState(props?.autoFocus)

  useEffect(() => {
    if(autoFocus && props?.ref?.current) props?.current.focus();
  }, [props, autoFocus])

  const handleBlur = (radioElement) => {
    setAutoFocus(false)
    radioElement?.target?.children[0]?.classList.remove("jqx-fill-state-focus-bootstrap")
  }

  return (
    <>
      {label && <Form.Label className="small">
        {label}
        {required && <span style={{ color: "red" }}>*</span>}
        {dica && <Hint textoAjuda={dica} />}
      </Form.Label>}
      <JqxRadioButton
        height="25px"
        theme="bootstrap"
        value={valor}
        checked={checked}
        onChecked={onClick}
        {...props}
        ref={radioElement => {
          if(radioElement && autoFocus) {
            radioElement.focus()
          }
        }}
        onBlur={handleBlur}
        onUnchecked={(radioElement) => {radioElement?.target?.children[0]?.classList.remove("jqx-fill-state-focus-bootstrap")}}
      >
        {titulo}
      </JqxRadioButton>
      <Erro mensagem={mensagemErro} />
    </>
  )
}

RadioButton.propTypes = {
  valor: PropTypes.any,
  label: PropTypes.string,
  dica: PropTypes.string,
  mensagemErro: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onClick: PropTypes.func,

  checked: PropTypes.bool,
  required: PropTypes.bool,
  titulo: PropTypes.string,
}

RadioButton.defaultProps = {
}

export default React.memo(RadioButton);
