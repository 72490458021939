import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Field } from "react-final-form";
import { aoEnviarSubForm } from "../../../util/mutadores";
import Card from "../../../components/card";
import { SubForm } from "../../../components/SubForm";
import { NumberInputField } from "../../../components/field/NumberInputField";
import { resolverExibidos } from "../../../components/SubForm/SubForm";
import { FORM_ERROR } from "final-form";
import { campoObrigatorioComMsgGenerica } from "../../../util/validadores";
import { buscaPercentuaisParaEstimativaModal, calcularSomaPercentuais } from "../utils/gerais";

const EstimativaModalComponentes = ({
    form,
    disabled,
    podeExcluir,
    permitirInsercao
}) => {

    const onSubmit = aoEnviarSubForm(form);
  

    const colunasEstimativaModalComponentes = [
        {
            text: 'Arcabouço (%)',
            datafield: 'arcabouco',
            width: '20%',
            cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
                const valor = value;
                return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${valor}</div>`;
            }
        },
        {
            text: 'Matriz (%)',
            datafield: 'matriz',
            width: '20%',
            cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
                const valor = value;
                return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${valor}</div>`;
            }
        },
        {
            text: 'Cimento (%)',
            datafield: 'cimento',
            width: '20%',
            cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
                const valor = value;
                return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${valor}</div>`;
            }
        },
        {
            text: 'Poros (%)',
            datafield: 'poros',
            width: '10%',
            cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
                const valor = value;
                return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${valor}</div>`;
            }
        },
        {
            text: 'Minerais de alteração (%)',
            datafield: 'mineraisAlteracao',
            width: '30%',
            cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
                const valor = value;
                return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${valor}</div>`;
            }
        },

    ]
  
    const camposEstimativasModalComponentes = [
        { name: "arcabouco", type: "number", map: "arcabouco" },
        { name: "matriz", type: "number", map: "matriz" },
        { name: "cimento", type: "number", map: "cimento" },
        { name: "poros", type: "number", map: "poros" },
        { name: "mineraisAlteracao", type: "number", map: "mineraisAlteracao" },
    ];

    const validarMineralRocha = (valores) => {
        const itens = form.getState().values;      

        if (itens) {
            const estimativas = resolverExibidos(itens);

            const indexExistente = estimativas.findIndex(item => item.id === valores.id);

            if (indexExistente !== -1) {

                estimativas[indexExistente] = valores;
            } else {

                estimativas.push(valores);
            }

            if (estimativas && estimativas.length > 0) {
                const somaPercentuais = calcularSomaPercentuais(buscaPercentuaisParaEstimativaModal(estimativas))
                if (somaPercentuais > 100) {
                    return { [FORM_ERROR]: 'A soma dos percentuais de minerais de alteração está ultrapassando os 100%.' };
                }
                if (somaPercentuais < 100) {
                    return { [FORM_ERROR]: 'A soma dos percentuais de Estimativa modal dos componentes deve somar 100%.' };
                }

            }
            return null;
        }
    };

    return (
        <>
            <div style={{ marginTop: '2.5rem' }}>
                <Card className="mt-3">
                    <Card.Body>
                        <Card.Title>Estimativa modal dos componentes</Card.Title>
                        {!podeExcluir && <span style={{ color: "red", fontSize: '10px'}}>Não é possível excluir uma Estimativa modal quando há Componentes deposicionais cadastrados.</span>}
                        <Field name={'estimativasModal'} subscription={{ value: true }}>
                            {({ input: { value: estimativas = [] } }) => {

                                const estimativasModal = resolverExibidos(estimativas);
                                const buttonActivationState = estimativasModal.length === 1                              
                                
                                return (<>
                                    <SubForm
                                        nome='estimativasModal'
                                        onSubmit={onSubmit}
                                        exibirBotaoInsercao={!buttonActivationState && !disabled}
                                        exibirTabelaSemRegistro={false}
                                        modoSubFormComGrid
                                        colunas={colunasEstimativaModalComponentes}
                                        elementos={estimativas}
                                        campos={camposEstimativasModalComponentes}
                                        validar={(valores) => validarMineralRocha(valores)}
                                        permitirInsercao={permitirInsercao}                                          
                                        permitirEdicao={!disabled}
                                        permitirExclusao={!disabled}
                                        permitirVisualizacao={disabled}
                                        podeExcluirEntidade={!disabled && podeExcluir}
                                        podeEditarEntidade={!disabled}                       
                                        renderForm={() => {
                                            return (
                                                <div style={{ display: "flex", flexDirection: "column" }}>
                                                    <Row className="my-3">
                                                        <Col md={2}  >
                                                            <Field
                                                                component={NumberInputField}
                                                                name="arcabouco"
                                                                label="Arcabouço (%)"
                                                                dica="Estime a porcentagem correspondente ao arcabouço."
                                                                permitirNegativos={false}                                                                
                                                                casasInteiros={3}
                                                                casasDecimais={0}
                                                                valorMaximo={100}    
                                                                valorMinimo={1}                                                            
                                                                disabled={disabled}
                                                                validate={campoObrigatorioComMsgGenerica("Arcabouço (%)")}
                                                                required
                                                            />
                                                        </Col>
                                                        <Col md={2}>
                                                            <Field
                                                                name="matriz"
                                                                component={NumberInputField}
                                                                label="Matriz (%)"
                                                                dica="Estime a porcentagem corresponde a matriz."
                                                                permitirNegativos={false}
                                                                casasInteiros={3}                                                                
                                                                casasDecimais={0}
                                                                valorMinimo={1}
                                                                valorMaximo={100}
                                                                disabled={disabled}
                                                            />
                                                        </Col>
                                                        <Col md={2}>
                                                            <Field
                                                                name="cimento"
                                                                component={NumberInputField}
                                                                label="Cimento (%)"
                                                                dica="Estime a porcentagem correspondente ao cimento."
                                                                permitirNegativos={false}
                                                                casasInteiros={3}
                                                                casasDecimais={0}
                                                                valorMaximo={100}
                                                                valorMinimo={1}
                                                                disabled={disabled}
                                                            />
                                                        </Col>
                                                        <Col md={2}>
                                                            <Field
                                                                name="poros"
                                                                component={NumberInputField}
                                                                label="Poros (%)"
                                                                dica="Estime a porcentagem correspondente aos poros."
                                                                permitirNegativos={false}
                                                                casasInteiros={3}
                                                                casasDecimais={0}
                                                                valorMinimo={1}
                                                                valorMaximo={100}
                                                                disabled={disabled}
                                                            />
                                                        </Col>
                                                        <Col md={3}>
                                                            <Field
                                                                name="mineraisAlteracao"
                                                                component={NumberInputField}
                                                                label="Minerais de alteração (%)"
                                                                dica="Estime a porcentagem correspondente aos poros."
                                                                permitirNegativos={false}
                                                                casasInteiros={3}
                                                                casasDecimais={0}
                                                                valorMinimo={1}
                                                                valorMaximo={100}
                                                                disabled={disabled}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </div>
                                            )
                                        }}
                                    />
                                </>)
                            }}
                        </Field>
                    </Card.Body>
                </Card>
            </div>
        </>
    );
};

export default EstimativaModalComponentes;