import { Component } from "react"
import { Col, Row } from "react-bootstrap";
import Versao from "./Versao";

export default class SobreVersao extends Component {

  render() {
      return (
        <>
        <Row className="mt-3 mb-3">
        <Col>
          <Versao></Versao>
        </Col>
      </Row>
      </>
      )                    
  }
}