import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Field, useForm } from "react-final-form";
import DropdownListWithValue from "../../../components/Jqx/DropdownList/DropdownListWithValue";
import { SubForm } from "../../../components/SubForm";
import Card from "../../../components/card";
import { HiddenField } from "../../../components/field/HiddenField";
import {
  TIPO_ATIVIDADE_AFLORAMENTO,
  TIPO_ATIVIDADE_AMOSTRAS,
  TIPO_ATIVIDADE_GEOQUIMICA,
  TIPO_ATIVIDADE_OUTRAS_ATIVIDADES,
  TIPO_ATIVIDADE_RECURSO_MINERAL,
  TIPO_ATIVIDADE_GEOCRONOLOGIA
} from "../../../util/constantes";
import converterVetorParaObjetoIndexado from "../../../util/converterVetorParaObjetoIndexado";
import {
  decoradorMapa,
  decoradorSirgas2000,
} from "../../../util/decoradores/decoradorMapa";
import {
  comporValidadores,
  swalConfirmarExclusao,
  swalErro,
  validacoesGeraisAfloramento,
  validacoesGeraisRecmin
} from "../../../util/validadores";
import AtividadeAfloramento from "./afloramento/AtividadeAfloramento";
import AtividadeOutrasColetas from "./outrascoletas/AtividadeOutrasColetas";
import Geocronologias from "../../dadosAnaliticos/components/geocronologia/Geocronologias";

import { getPropsPermissao, resolverExibidos } from "../../../components/SubForm/SubForm";
import { getBibliotecasAfloramento } from "../../../service/BibliotecasService";
import Service, { AFLORA, AMOSTRAS, GEOCRON, OUTRAS_COLETAS, RECMIN, VALIDACAO } from "../../../service/Service";
import { resetarLatLongInvalidaAoTrocarHemisferio } from "../../../util/decoradores/decoradorSeletorCoordenadas";
import RecursoMineral from "../RecursoMineral";
import Amostras from './amostras/Amostras';
import { GeoquimicaForm } from "./geoquimica/GeoquimicaForm";

import { get, isEmpty } from "lodash";
import { useDispatch } from "react-redux";
import swal from "sweetalert";
import CardWrapper from "../../../components/card/CardWrapper";
import { limparArquivos } from "../../../reducers/fileReducers";
import { mostrarNotificacao } from "../../../reducers/notificacaoReducer";
import { selecionarVisita } from "../../../reducers/visitaReducer";
import { buscarIdUsuario } from "../../../util/authenticacaoUtils";
import { FocusableButton } from "../../../components/Jqx/Button";
import gerarPdfRedirecionarNovaAba from "../utils/gerarPdfRedirecionarNovaAba";
import { createValidationColumn } from "../../validacao/utils/validationGridUtils";

const tiposAtividades = [
  TIPO_ATIVIDADE_AMOSTRAS,
  TIPO_ATIVIDADE_RECURSO_MINERAL,
  TIPO_ATIVIDADE_AFLORAMENTO,
  TIPO_ATIVIDADE_GEOCRONOLOGIA
];

const tiposAtividadesFiltro = [
  TIPO_ATIVIDADE_AMOSTRAS,
  TIPO_ATIVIDADE_RECURSO_MINERAL,
  TIPO_ATIVIDADE_AFLORAMENTO
];
const datafieldsAtividades = [
  { name: "id", type: "number", map: "id" },
  { name: "idVisita", type: "number", map: "idVisita" },
  { name: "tipo", type: "string", map: "tipo" },
  { name: "modoCadastro", type: "string", map: "modoCadastro" },
  { name: "nomeTipo", type: "string", map: "nomeTipo" },
  { name: "numeroCampo", type: "string", map: "numeroCampo" },
  { name: "idClasseAmostra", type: "string", map: "idClasseAmostra" },
  { name: "descricao", type: "string", map: "toponimia" },
  { name: "modoEdicao", type: "string", map: "modoEdicao" },
  { name: "statusValidacao", type: "string", map: "statusValidacao" },
  { name: "idStatusValidacao", type: "number", map: "idStatusValidacao" },
  { name: "ultimaAtualizacao", type: "string", map: "ultimaAtualizacao" },
  { name: "comentarioValidacao", type: "string", map: "comentarioValidacao" },
  { name: "comentarioRevisao", type: "string", map: "comentarioRevisao" },
];

const exibirTabelaESwalErroIniciarEdicao = (setExibirTabela, swalOptions) => {
  return () => {
    setExibirTabela(true);
    swalErro({ title: 'Não foi possível carregar a atividade', ...swalOptions });
  };
};

const exibirTabelaESwalErroBibliotecas = (
  setExibirTabela,
  setIdTipoAtividadeSelecionada
) => {
  return (err) => {
    console.error("Erro ao recuperar bibliotecas:", err);
    setExibirTabela(true);
    setIdTipoAtividadeSelecionada(null);
    swalErro({
      title: "Não foi possível carregar os dados do módulo selecionado",
    });
  };
};

const exibirSwalErroSalvarELancarErro = async (err) => {
  await swalErro({ title: "Não foi possível salvar a atividade" });
  throw err; // Lançar o erro durante onSubmit do SubForm irá fazer com que o form não seja fechado
};

const exibirSwalErroExcluir = async () => {
  return swalErro({ title: "Não foi possível excluir a atividade" });
};



const RegistrarObjetoFunc = ({
  idEstacao,
  idProjeto,
  nomeProjeto,
  permitirEdicao,
  visitas = {},
  valoresIniciais = {},

  // Bibliotecas
  datums = [],
  coletores = [],
  metodosGeoposicionamento = [],
  categoriasLimiteArea = [],
  minerais = [],
  prefixos = [],
  complementos = [],
  cores = [],
  tiposIlustracao = [],
  extencoesIlustracao = [],
  classesAmostra = [],
  mineraisPorId = {},
  prefixosPorId = {},
  complementosPorId = {},
  alterarAtividadeAberta = () => { },
  preencherVisitaSelecionada = () => { },
  alterarAtividadeSelecionadaBreadcrumb = () => { },
  BotoesVoltarSalvar = () => null,
  area = null,
  subArea = null,
  visitaSelecionada,
  history,
}) => {
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  let form = useForm();
  const dispatch = useDispatch()
  const formEstacao = useForm();
  const [atividadesVisita, setAtividadesVisita] = useState({});
  const [idTipoAtividadeSelecionada, setIdTipoAtividadeSelecionada] =
    useState(null);
  const [idVisitaSelecionada, setIdVisitaSelecionada] = useState(form.getState().values.idVisitaSelecionada ?? null);
  const decoradoresAtividades = [decoradorSirgas2000(idVisitaSelecionada), decoradorMapa(), resetarLatLongInvalidaAoTrocarHemisferio()];
  // const [atividadesPorIdVisita, setAtividadesPorIdVisita] = useState({});
  const [exibirBotoesPadrao, setExibirBotoesPadrao] = useState(true);
  const [gerandoPdf, setGerandoPdf] = useState(false);
  /**
   * Ref to the Jqx Grid directly. This is for convenience, since the same
   * ref is available as the "tableRef" property of the Table's ref. Calling
   * "tableRef.current?.tableRef" also gives this object.
   * @type {React.MutableRefObject<JqxGrid>}
   */
  const jqxRef = useRef(null);


  function validarProcessoANM(form) {
    const valores = form?.getState()?.values;

    if (valores && valores.modoCadastro === 'DEFINITIVO' && !valores.processoAnmAndp)
      dispatch(mostrarNotificacao('O campo "Processo ANM/DNPM relacionado" é obrigatório.', { tipo: 'error' }));
  }
  const colunasAtividades = useMemo(() => {
    let colunas = [ 
      {
        text: "Modo de cadastro", datafield: "modoCadastro", width: "14%", editable: false, cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
          switch (value) {
            case "RASCUNHO": value = "Rascunho"; break;
            case "DEFINITIVO": value = "Completo"; break;
            case "TEMPORARIO": value = "Temporario"; break;
            default: value = "";
          }
          return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${value}</div>`;;
        }
      },
      { text: "Atividade", datafield: "nomeTipo", width: "14%", editable: false },
      { text: "Número de Campo", datafield: "numeroCampo", width: "14%", editable: false },
      { text: "Descrição", datafield: "descricao", width: "14%", editable: false },
      {
        text: "Status da revisão", datafield: "statusValidacao", width: "14%", editable: false, createwidget: createValidationColumn({ ref: jqxRef, method: "createwidget", hint: "Visualizar detalhes para correção"}),
        initwidget: createValidationColumn({ ref: jqxRef, method: "initwidget", hint: "Visualizar detalhes para correção"}),
      }
  ]



  return colunas;
}, []);


  const [listaAtividades, setListaAtividades] = useState([]);
  const [tiposAtividadesFiltrada, seTiposAtividadesFiltrada] = useState([]);

  const [exibirTabela, setExibirTabela] = useState(true);
  const [exibirBotoes, setExibirBotoes] = useState(true);
  const [visitasVisiveis, setVisitasVisiveis] = useState({
    todas: [],
    porId: {},
  });

  const [projetos, setProjetos] = useState([]);
  const [atividadeIdEdit, setAtividadeIdEdit] = useState();
  const [atividadeEmEdicao, setAtividadeEmEdicao] = useState();
  const [visualizando, setVisualizando] = useState(false);
  const pontosAtribuidosAEstacao = useRef([]); // Pontos atribuidos a estação porém não vinculados
  const [podeAlterarTipoAtividade, setPodeAlterarTipoAtividade] = useState(false);
  const [abrirFormAmostras, setAbrirFormAmostras] = useState(false);
  const [atividadesTemporarias, setAtividadesTemporarias] = useState([]);
  const [formularioAberto, setFormularioAberto] = useState(false);
  const [podeInserirAmostras, setPodeInserirAmostras] = useState(false);
  const [rowsSelecionadas, setRowsSelecionadas] = useState([]);

  useEffect(() => {
    const flag = !exibirTabela && null != idTipoAtividadeSelecionada && null != idVisitaSelecionada;

    if (flag) {
      alterarAtividadeAberta(true);
      const atividadeTemporaria = atividadesTemporarias.find((c) => c.idVisita.toString() === idVisitaSelecionada && c.tipo === idTipoAtividadeSelecionada && c.modoCadastro === "TEMPORARIO" && c.permitirEdicao);

      if (atividadeTemporaria) {
        let conteudoAtividade = atividadeTemporaria.atividade;
        let idVisita = parseInt(idVisitaSelecionada);

        swal({
          title: 'Há um arquivo de recuperação para esta atividade, deseja utilizar ?',
          icon: 'info',
          text: `${formatarData(atividadeTemporaria)} - Temporário`,
          closeOnClickOutside: false,
          buttons: { confirm: 'Sim', cancel: 'Não' },
        })
          .then(async (value) => {
            if (value) {
              form.mutators.setValue('idVisitaSelecionada', parseInt(idVisitaSelecionada));

              switch (idTipoAtividadeSelecionada) {
                case TIPO_ATIVIDADE_AFLORAMENTO.id: {
                  try {
                    if (!bibliotecasAfloramento) {
                      const bibliotecas = await getBibliotecasAfloramento().catch(exibirTabelaESwalErroBibliotecas(setExibirTabela, setIdTipoAtividadeSelecionada));

                      if (!bibliotecas)
                        return;

                      setBibliotecasAfloramento(bibliotecas);
                    }

                    setAtividadeEmEdicao(conteudoAtividade);
                    setAtividadeIdEdit(conteudoAtividade?.id);
                    setIdTipoAtividadeSelecionada(idTipoAtividadeSelecionada);
                    setIdVisitaSelecionada(idVisita);
                  }
                  catch (err) {
                    console.log("Erro ao carregar atividade temporaria aflora", err);
                    exibirTabelaESwalErroIniciarEdicao(setExibirTabela);
                  }
                  break;
                }
                case TIPO_ATIVIDADE_RECURSO_MINERAL.id: {
                  localStorage.removeItem('SUBSTANCIAS_RECMIN');

                  try {
                    setAtividadeEmEdicao(conteudoAtividade);
                    setAtividadeIdEdit(conteudoAtividade.id);
                    setIdTipoAtividadeSelecionada(idTipoAtividadeSelecionada);
                    setIdVisitaSelecionada(idVisita);
                  }
                  catch (erro) {
                    console.log("Erro ao carregar atividade temporaria recmin", erro);
                    exibirTabelaESwalErroIniciarEdicao(setExibirTabela);
                  }
                  break;
                }
                case TIPO_ATIVIDADE_AMOSTRAS.id: {
                  const visitaSelecionada = visitasVisiveis.porId[idVisita] || {};

                  setValoresIniciaisAtividades({
                    ...valoresIniciaisAtividades,
                    idUsuarioColetor: visitaSelecionada.idUsuarioColetor,
                    idMetodoGeoposicionamento: formEstacao.getState().values?.idMetodoGeoposicionamento,
                    altitude: formEstacao.getState().values?.altitude
                  });

                  try {
                    if (!conteudoAtividade)
                      conteudoAtividade = {};

                    conteudoAtividade.tipo = 'CADASTRO_AMOSTRA';
                    conteudoAtividade.nomeTipo = 'Cadastrar amostras'
                    let existentes = [];
                    if (conteudoAtividade.amostras && conteudoAtividade.amostras.length) {
                      conteudoAtividade?.amostras?.map(amostra => {
                        existentes.push(amostra);
                      })
                      conteudoAtividade.amostras = {
                        existentes: existentes ?? []
                      }
                    } else {
                      conteudoAtividade.amostras = {
                        existentes: []
                      }
                    }

                    setAbrirFormAmostras(true);
                    setAtividadeEmEdicao(conteudoAtividade);
                    setAtividadeIdEdit(conteudoAtividade.id);
                    setIdTipoAtividadeSelecionada(idTipoAtividadeSelecionada);
                    setIdVisitaSelecionada(idVisita);
                  }
                  catch (erro) {
                    console.log("Erro ao carregar atividade temporaria amostras", erro);
                    exibirTabelaESwalErroIniciarEdicao(setExibirTabela);
                  }

                  break;
                }
                default: {
                  break;
                }
              }

            }
            setFormularioAberto(flag);
          })
          .catch((err) => {
            console.log('Erro ao exibir modal para escolher entre nova atividade e temporaria', err);
            setFormularioAberto(flag);
          });
      }
      else
        setFormularioAberto(flag);
    }
    else {
      alterarAtividadeAberta(false);
      setFormularioAberto(flag);
    }
  }, [exibirTabela, idTipoAtividadeSelecionada, idVisitaSelecionada]);

  useEffect(() => {
    const carregaProjetos = async () => {
      try {
        const res = await Service('/projetos', 'basegeo').listar();
        setProjetos(get(res, 'data.dados', []));
      }
      catch (err) {
        console.log('\n Erro ao carregar os projetos', err);
      }
    }

    carregaProjetos();
  }, []);

  // Recupera as visitas existentes e editadas, filtrando excluídas
  useEffect(() => {
    // Visitas novas são ignoradas pois a visita no banco é necessária para algumas
    // funcionalidades / lógica durante o cadastro de atividades
    let visitasResolvidas =
      visitas?.existentes
        ?.filter((v) => !(visitas.idsExcluidos || []).includes(Number(v.id))) // Filtra visitas que serão excluídas
        .map((v) => {
          // Utiliza visita editada caso existir, caso contrário utiliza visita existente
          const visitaAtualizada = visitas?.editadosPorId?.["id_" + v.id] || v;

          return {
            ...visitaAtualizada,
            get descricao() {
              let desc = "Visita ";
              if (visitaAtualizada.dataInicio && visitaAtualizada.dataTermino) {
                desc += visitaAtualizada.nome;
              } else {
                desc += visitaAtualizada.id;
              }
              return desc;
            },
          };
        }) ?? [];

    setVisitasVisiveis({
      todas: visitasResolvidas,
      porId: converterVetorParaObjetoIndexado(visitasResolvidas),
    });
  }, [visitas, setVisitasVisiveis]);

  async function atualizaAtividades(tempIdVisitaSelecionada) {
    setRowsSelecionadas([]);
    const servicoMobile = await Service(
      `/estacoes/${idEstacao}/atividades`,
      "basegeo"
    );

    if (form.getState().values.idVisitaSelecionada) {
      const atividades = await servicoMobile.get(
        `${form.getState().values.idVisitaSelecionada ? `?idVisita=${form.getState().values.idVisitaSelecionada}` : ""}`
      );

      let lista = atividades.data && atividades.data.length ? atividades.data.filter(atividade => atividade.modoCadastro != 'TEMPORARIO') : []

      const tiposAtividadesFiltrada = tiposAtividadesFiltro.filter(
        (tipoAtividade) =>
          lista.findIndex(
            (atividade) => atividade.tipo === tipoAtividade.id
          ) === -1
      );

      setAtividadesTemporarias(atividades.data && atividades.data.length ? atividades.data.filter(atividade => atividade.modoCadastro === 'TEMPORARIO') : []);
      setListaAtividades(lista);
      seTiposAtividadesFiltrada(tiposAtividadesFiltrada);

      let idUsuarioAtual = buscarIdUsuario();
      let podeInserirInformacoesOutros = true;

      let visitaSelecionada = visitasVisiveis.porId[form.getState().values.idVisitaSelecionada] || {};

      let projeto = projetos.find((p) => p.id === visitaSelecionada.idProjeto);

      if (projeto) {
        podeInserirInformacoesOutros = projeto.podeInserirInformacoesOutros;
      }

      if (visitaSelecionada.idUsuarioColetor != idUsuarioAtual && !podeInserirInformacoesOutros) {
        setPodeAlterarTipoAtividade(false);
        setIdTipoAtividadeSelecionada(null);
      }
      else {
        setPodeAlterarTipoAtividade(true);
      }

    } else {
      setPodeAlterarTipoAtividade(false);
      const atividades = await servicoMobile.get(
        `${idVisitaSelecionada ? `?idVisita=${idVisitaSelecionada}` : ""}`
      );

      let lista = atividades.data && atividades.data.length ? atividades.data.filter(atividade => atividade.modoCadastro != 'TEMPORARIO') : [];

      const tiposAtividadesFiltrada = tiposAtividades.filter(
        (tipoAtividade) =>
          lista.findIndex(
            (atividade) => atividade.tipo === tipoAtividade.id
          ) === -1
      );

      setAtividadesTemporarias(atividades.data && atividades.data.length ? atividades.data.filter(atividade => atividade.modoCadastro === 'TEMPORARIO') : []);
      setListaAtividades(lista);
      seTiposAtividadesFiltrada(tiposAtividadesFiltrada);
    }
  }

  useEffect(() => {
    atualizaAtividades();
    const visitaSelecionada = visitasVisiveis?.porId[idVisitaSelecionada]?.descricao

    if (visitaSelecionada) {
      const visita = {
        area: visitasVisiveis.porId[idVisitaSelecionada].area,
        subArea: visitasVisiveis.porId[idVisitaSelecionada].subArea,
        nomeProjeto: visitasVisiveis.porId[idVisitaSelecionada].nomeProjeto,
        descricao: visitasVisiveis.porId[idVisitaSelecionada].descricao
      }

      dispatch(selecionarVisita(visita))
    }
  }, [idVisitaSelecionada]);

  const [valoresIniciaisAtividades, setValoresIniciaisAtividades] = useState({
    nomeTipo: null,
    tipo: null,
    rochaDeEnclave: "0",
    ...valoresIniciais,
    modoCadastro: "DEFINITIVO" // "Rocha de enclave?"="Não"
  });

  // Bibliotecas específicas de cada módulo
  const [bibliotecasAfloramento, setBibliotecasAfloramento] = useState();

  // ***************************************************************************
  // TODO: transformar em função assíncrona para realizar requisições que necessitam do idVisita
  const alterarVisitaSelecionada = useCallback(
    (idVisita) => {
      let genericIdVisita = null;
      if (idVisita >= 0) {
        genericIdVisita = idVisita;
        form.mutators.setValue('idVisitaSelecionada', genericIdVisita);
        alterarAtividadeSelecionadaBreadcrumb(genericIdVisita);
      } else if (form.getState().values.idVisitaSelecionada !== null && form.getState().values.idVisitaSelecionada !== undefined) {
        genericIdVisita = form.getState().values.idVisitaSelecionada
        alterarAtividadeSelecionadaBreadcrumb(genericIdVisita);
      }
      // idVisita pode estar em string
      // eslint-disable-next-line
      setExibirTabela(false); // Esconde jqxGrid durante atualizações de hook para evitar erro "jqxGrid: The data is still loading."

      let visitaSelecionada = visitasVisiveis.porId[genericIdVisita] || {};

      sessionStorage.setItem('visita', JSON.stringify(visitaSelecionada));

      if (isEmpty(visitaSelecionada)) {
        visitas.existentes.forEach(visita => {
          if (visita.id?.toString() === form.getState().values.idVisitaSelecionada?.toString()) {
            visitaSelecionada = visita
          }
        })
      }

      setIdVisitaSelecionada(genericIdVisita);
      preencherVisitaSelecionada(genericIdVisita);

      if (visitaSelecionada.id !== genericIdVisita) {
        setValoresIniciaisAtividades({
          ...valoresIniciaisAtividades,
          idVisita: genericIdVisita,
          // Valores de campos de visita que podem ser reaproveitados em atividade
          idUsuarioColetor: visitaSelecionada.idUsuarioColetor,
          toponimia: visitaSelecionada.toponimia,
          coordenadas: formEstacao.getState().values?.coordenadas,
        });
      }

      let idUsuarioAtual = buscarIdUsuario();
      let podeInserirInformacoesOutros = true;

      let projeto = projetos.find((p) => p.id === visitaSelecionada.idProjeto);

      if (projeto) {
        podeInserirInformacoesOutros = projeto.podeInserirInformacoesOutros;
      }

      if (visitaSelecionada.idUsuarioColetor === idUsuarioAtual || podeInserirInformacoesOutros) {

        setPodeAlterarTipoAtividade(true);

        dispatch(selecionarVisita(visitaSelecionada));

        // Esconder os botões voltar/confirmar caso o form seja aberto na próxima rerenderização
        if (genericIdVisita !== null && idTipoAtividadeSelecionada != null) {
          setExibirBotoes(false);
        } else {
          setExibirTabela(true); // O form não será aberto, então exibimos a tabela novamente
        }
      }
      else {
        setPodeAlterarTipoAtividade(false);
        setIdTipoAtividadeSelecionada(null);

        if (genericIdVisita !== null && idTipoAtividadeSelecionada != null) {
          setExibirBotoes(false);
        } else {
          setExibirTabela(true); // O form não será aberto, então exibimos a tabela novamente
        }
      }
    },
    [
      idVisitaSelecionada,
      valoresIniciaisAtividades,
      idTipoAtividadeSelecionada,
      visitasVisiveis,
      formEstacao,
    ]
  );


  const alterarTipoAtividadeSelecionada = useCallback(
    async (idTipoAtividade) => {
      localStorage.removeItem('SUBSTANCIAS_RECMIN');
      if (idTipoAtividade !== idTipoAtividadeSelecionada) {
        setExibirTabela(false); // Esconde jqxGrid durante atualizações de hook para evitar erro "jqxGrid: The data is still loading."

        const nomeTipoAtividade = idTipoAtividade
          ? tiposAtividades.filter((tipo) => tipo.id === idTipoAtividade)[0]
            .nome
          : null;

        // ***************************************************************************

        // Recuperar bibliotecas e outros dados para o módulo atual (Talvez necessário ser feito também em onBtnEditTableClick)
        switch (idTipoAtividade) {
          case TIPO_ATIVIDADE_AFLORAMENTO.id:
            if (!bibliotecasAfloramento) {
              // Cache, realizar requisições apenas uma vez
              const bibliotecas = await getBibliotecasAfloramento().catch(
                exibirTabelaESwalErroBibliotecas(
                  setExibirTabela,
                  setIdTipoAtividadeSelecionada
                )
              );
              if (!bibliotecas) return; // Caso ocorra um erro ao carregar as bibliotecas, não podemos abrir o formulário
              setBibliotecasAfloramento(bibliotecas);
            }
            break;

          default:
            console.debug(
              "Nenhuma biblioteca adicional necessária para o tipo",
              idTipoAtividade,
              "necessária"
            );
            break;
        }

        // ***************************************************************************

        const visitaSelecionada = visitasVisiveis.porId[idVisitaSelecionada] || {};

        setIdVisitaSelecionada(idVisitaSelecionada);
        preencherVisitaSelecionada(idVisitaSelecionada);

        setIdTipoAtividadeSelecionada(idTipoAtividade);
        setValoresIniciaisAtividades({
          ...valoresIniciaisAtividades,
          nomeTipo: nomeTipoAtividade,
          tipo: idTipoAtividade,
          toponimia: visitaSelecionada.toponimia,
          idUsuarioColetor: visitaSelecionada.idUsuarioColetor,
          idMetodoGeoposicionamento: formEstacao.getState().values?.idMetodoGeoposicionamento,
          idVisita: idVisitaSelecionada,
          altitude: formEstacao.getState().values?.altitude,
          coordenadas: formEstacao.getState().values?.coordenadas
        });

        // Esconder os botões voltar/confirmar caso o form seja aberto na próxima rerenderização
        if (idTipoAtividade !== null && idVisitaSelecionada != null) {
          setExibirBotoes(false);
        } else {
          setExibirTabela(true); // O form não será aberto, então exibimos a tabela novamente
        }
      }
    },
    [
      idTipoAtividadeSelecionada,
      valoresIniciaisAtividades,
      idVisitaSelecionada,
      bibliotecasAfloramento,
      visitasVisiveis,
      setBibliotecasAfloramento,
      setIdTipoAtividadeSelecionada,
    ]
  );

  // ***************************************************************************

  // o SubForm aplica await na prop onSubmit, para fechar o form (e o jqxGrid realizar a requisição)
  // somente depois. Então devemos utilizar async/await nessa função enquanto a atividade é salva
  // caso contrário a tabela será preenchida antes da atividade ser persistida
  const enviarFormularioAtividades = useCallback(async ({ exibidos }) => {
    // Como não estamos atualizando a prop elementos passada ao SubForm,
    // o componente pensa que o único registro em elementos é o que o form acabou
    // de enviar. Por isso, podemos recuperá-lo do exibidos, que possui o único registro "novo"
    const atividade = exibidos[0];

    try {
      await Service(`/estacoes/atividadeTemporaria/${idEstacao}/${atividade.idVisita}/${atividade.tipo}`, 'basegeo').delete();
    }
    catch (err) {
      console.log("Erro ao remover temporario", err);
    }

    switch (idTipoAtividadeSelecionada) {
      case TIPO_ATIVIDADE_AFLORAMENTO.id:

        if (atividade.ilustracoes && atividade.ilustracoes.existentes) {
          atividade.ilustracoes.existentes.forEach(ilustracao => {
            dispatch(limparArquivos({ store: `arquivosIlustracoes_${ilustracao.id}` }));
          });
        }

        const res = await Service('/afloramentos', AFLORA).post(atividade)
          .catch(exibirSwalErroSalvarELancarErro);
        // if (res?.data?.dados?.id && atividade?.modoCadastro === TIPO_CADASTRO_ATIVIDADE_DEFINITIVO.id) {
        //   const idAfloramento = res.data.dados.id;
        //   Service(`/afloramentos/${idAfloramento}/gerarAuditoria`, AFLORA)
        //     .post(atividadeEmEdicao || { id: null }) // Não precisamos aguardar a geração de auditoria
        //     .catch(err => console.error('Erro ao gerar auditoria do afloramento:', err));
        // }
        break;

      case TIPO_ATIVIDADE_OUTRAS_ATIVIDADES.id:
        await Service('/outrascoletas', OUTRAS_COLETAS).post(atividade)
          .catch(exibirSwalErroSalvarELancarErro);
        break;

      case TIPO_ATIVIDADE_RECURSO_MINERAL.id:
        await Service('/recursoMineral', RECMIN).post(atividade)
          .catch(exibirSwalErroSalvarELancarErro);
        break;

      default:
        console.warn('Formulário de atividade enviado mas sem comportamento definido para o tipo ' + idTipoAtividadeSelecionada);
        break;
    }

    if (idTipoAtividadeSelecionada != TIPO_ATIVIDADE_AMOSTRAS.id)
      dispatch(mostrarNotificacao('Atividade salva com sucesso', { tipo: 'success' }, true));

    atualizaAtividades(form.getState().values.idVisitaSelecionada);
  }, [idTipoAtividadeSelecionada, atividadeEmEdicao]);

  const validarExclusaoAtividade = async (id, rowdata, tableRef) => {
    let tipo = rowdata.rowData.tipo;

    if (rowdata.rowData.modoCadastro != 'DEFINITIVO')
      return await swalConfirmarExclusao();

    switch (tipo) {
      case TIPO_ATIVIDADE_AFLORAMENTO.id: {
        try {
          const res = await Service('/afloramentos', AFLORA).get(id);
          const aflora = res.data.dados;
          const amostras = [];
          let associacaoRemin = false;
          let amostrasAssociadas = [];

          let amostrasRow = tableRef.current.tableData.localdata.find((i) => i.idVisita === rowdata.rowData.idVisita && i.tipo === TIPO_ATIVIDADE_AMOSTRAS.id);

          if (amostrasRow && amostrasRow.id) {
            let idCadastroAmostra = amostrasRow.id;
            const resCadastroAmostra = await Service('/cadastroAmostra', AMOSTRAS).get(idCadastroAmostra);
            let cadastroAmostra = resCadastroAmostra.data.dados;

            for (let i = 0; i < cadastroAmostra.amostras.length; i++) {
              const amostraRes = await Service('/cadastroAmostra/amostra', AMOSTRAS)
                .get(cadastroAmostra.amostras[i].id);
              amostras.push(amostraRes.data.dados);
            }

            for (let i = 0; i < amostras.length; i++) {
              for (let j = 0; j < amostras[i].associacoes.length; j++) {
                if (amostras[i].associacoes[j].tipoAtividade === TIPO_ATIVIDADE_AFLORAMENTO.id) {
                  amostrasAssociadas.push(amostras[i].numeroAmostra);
                }
              }
            }
          }

          let recminRow = tableRef.current.tableData.localdata.find((i) => i.idVisita === rowdata.rowData.idVisita && i.tipo === TIPO_ATIVIDADE_RECURSO_MINERAL.id);

          if (recminRow && recminRow.id) {
            let idrecmin = recminRow.id;

            const resRecmin = await Service('/recursoMineral', RECMIN).get(idrecmin);
            let recmin = resRecmin.data.dados;

            // Ocorrencia mineral 
            if (recmin.ocorrenciaMineral) {
              const ocorrencias = resolverExibidos(recmin.ocorrenciaMineral);

              ocorrencias.forEach(ocorrencia => {
                if (ocorrencia.idAfloramento === id)
                  associacaoRemin = true;
              });
            }

            // Metalogenia
            if (recmin.estudoMetalogenetico) {
              const estudos = resolverExibidos(recmin.estudoMetalogenetico);

              estudos.forEach(estudo => {
                const encaixantes = resolverExibidos(estudo.metalogenia.dadosDescritivos.rochasEncaixantes);

                encaixantes.forEach(encaixante => {
                  if (encaixante.idAfloramento === id)
                    associacaoRemin = true;
                });

                const hospedeiras = resolverExibidos(estudo.metalogenia.dadosDescritivos.rochasHospedeiras);

                hospedeiras.forEach(hospedeira => {
                  if (hospedeira.idAfloramento === id)
                    associacaoRemin = true;
                });

              });
            }
          }

          if (amostrasAssociadas.length || associacaoRemin) {
            let texto = '';

            if (amostrasAssociadas.length && associacaoRemin) {
              let multiplasAmostras = amostrasAssociadas.length > 1;

              texto = multiplasAmostras ? `Para o afloramento ${rowdata.rowData.numeroCampo} estão associadas ás amostras ${amostrasAssociadas.toString().replace(/,/g, ', ')} e um Recurso Mineral. Caso o afloramento seja excluído as amostras e o Recurso Mineral serão desassociados dele.` :
                `Para o afloramento ${rowdata.rowData.numeroCampo} está associada à amostra ${amostrasAssociadas[0]} e um Recurso Mineral. Caso o afloramento seja excluido à amostra e o Recurso mineral serão desassociados dele.`;
            }

            else if (amostrasAssociadas.length) {
              let multiplasAmostras = amostrasAssociadas.length > 1;

              texto = multiplasAmostras ? `Para o afloramento ${rowdata.rowData.numeroCampo} estão associadas ás amostras ${amostrasAssociadas.toString().replace(/,/g, ', ')}. Caso o afloramento seja excluído as amostras serão desassociadas dele.` :
                `Para o afloramento ${rowdata.rowData.numeroCampo} está associada à amostra ${amostrasAssociadas[0]}. Caso o afloramento seja excluido à amostra será desassociada dele.`;
            }

            else if (associacaoRemin) {
              texto = `O afloramento ${rowdata.rowData.numeroCampo} está associado a um Recurso Mineral. Caso o afloramento seja excluído o Recurso Mineral será desassociado dele.`;
            }

            texto += ' Deseja realmente excluir esse registro ?';

            return swal({
              title: texto,
              icon: 'warning',
              buttons: { cancel: "Não, cancelar", confirm: { text: "Sim, desejo excluir!", className: "btn-danger" } },
            });
          }

          return await swalConfirmarExclusao();
        }
        catch (err) {
          exibirSwalErroExcluir();
        }
        break;
      }
      case TIPO_ATIVIDADE_AMOSTRAS.id: {
        try {
          const res = await Service('/cadastroAmostra', AMOSTRAS).get(id);
          let cadastroAmostra = res.data.dados;
          const amostras = [];
          const rochasAssociadas = [];
          let rochas = [];
          let associacaoRemin = false;
          let associacaoAflora = false;

          if (cadastroAmostra.amostras && cadastroAmostra.amostras.length) {
            let afloramentoRow = tableRef.current.tableData.localdata.find((i) => i.idVisita === rowdata.rowData.idVisita && i.tipo === TIPO_ATIVIDADE_AFLORAMENTO.id);

            for (let i = 0; i < cadastroAmostra.amostras.length; i++) {
              const amostraRes = await Service('/cadastroAmostra/amostra', AMOSTRAS)
                .get(cadastroAmostra.amostras[i].id);
              amostras.push(amostraRes.data.dados);
            }

            for (let i = 0; i < amostras.length; i++) {
              for (let j = 0; j < amostras[i].associacoes.length; j++) {
                if (amostras[i].associacoes[j].tipoAtividade === TIPO_ATIVIDADE_RECURSO_MINERAL.id)
                  associacaoRemin = true;
                else if (amostras[i].associacoes[j].tipoAtividade === TIPO_ATIVIDADE_AFLORAMENTO.id) {
                  associacaoAflora = true;
                  if (amostras[i].associacoes[j].idMaterialGeologico) {
                    if (!rochas.length) {
                      let idAfloramento = afloramentoRow.id;
                      const resAflora = await Service('/afloramentos', AFLORA).get(idAfloramento);
                      let aflora = resAflora.data.dados;
                      rochas = resolverExibidos(aflora.rochas);
                    }
                    let rocha = rochas.find(r => r.id === amostras[i].associacoes[j].idMaterialGeologico);
                    rochasAssociadas.push(rocha.numeroMaterialGeologico);
                  }
                }
              }
            }

            if (associacaoAflora || associacaoRemin) {
              let texto = '';

              if (associacaoAflora && !rochasAssociadas.length && associacaoRemin) {
                texto = `Para o cadastro de amostras ${rowdata.rowData.numeroCampo} estão associados um afloramento e um recurso mineral. Caso o cadastro seja excluído serão desassociados dele.`;
              }
              else if (associacaoAflora && rochasAssociadas.length && associacaoRemin) {
                let multiplosMateriais = rochasAssociadas.length > 1;

                texto = multiplosMateriais ? `Para o cadastro de amostras ${rowdata.rowData.numeroCampo} estão associados os materiais geológicos ${rochasAssociadas.toString().replace(/,/g, ', ')} e um recurso mineral, caso o cadastro seja excluído serão desassociadas dele.` :
                  `Para o cadastro de amostras ${rowdata.rowData.numeroCampo} está associado o material geológico ${rochasAssociadas[0]} e um recurso mineral. Caso o cadastro seja excluído serão desassociadas dele.`;
              }
              else if (associacaoAflora) {
                texto = `Para o cadastro de amostras ${rowdata.rowData.numeroCampo} está associado um afloramento. Caso o cadastro seja excluido será desassociado dele.`;
              }
              else if (associacaoRemin) {
                texto = `Para o cadastro de amostras ${rowdata.rowData.numeroCampo} está associado um recurso mineral. Caso o cadastro seja excluido será desassociado dele.`
              }

              texto += ' Deseja realmente excluir esse registro ?';

              return swal({
                title: texto,
                icon: 'warning',
                buttons: { cancel: "Não, cancelar", confirm: { text: "Sim, desejo excluir!", className: "btn-danger" } },
              });
            }
          }

          return await swalConfirmarExclusao();
        }
        catch (err) {
          exibirSwalErroExcluir();
        }
        break;
      }
      case TIPO_ATIVIDADE_RECURSO_MINERAL.id: {
        try {
          let amostrasRow = tableRef.current.tableData.localdata.find((i) => i.idVisita === rowdata.rowData.idVisita && i.tipo === TIPO_ATIVIDADE_AMOSTRAS.id);
          const amostras = [];
          let amostrasAssociadas = [];

          if (amostrasRow && amostrasRow.id) {
            let idCadastroAmostra = amostrasRow.id;
            const resCadastroAmostra = await Service('/cadastroAmostra', AMOSTRAS).get(idCadastroAmostra);
            let cadastroAmostra = resCadastroAmostra.data.dados;

            for (let i = 0; i < cadastroAmostra.amostras.length; i++) {
              const amostraRes = await Service('/cadastroAmostra/amostra', AMOSTRAS)
                .get(cadastroAmostra.amostras[i].id);
              amostras.push(amostraRes.data.dados);
            }

            for (let i = 0; i < amostras.length; i++) {
              for (let j = 0; j < amostras[i].associacoes.length; j++) {
                if (amostras[i].associacoes[j].tipoAtividade === TIPO_ATIVIDADE_RECURSO_MINERAL.id) {
                  amostrasAssociadas.push(amostras[i].numeroAmostra);
                }
              }
            }

            if (amostrasAssociadas.length) {
              amostrasAssociadas = [...new Set(amostrasAssociadas)];
              let texto = '';

              let multiplasAmostras = amostrasAssociadas.length > 1;

              texto = multiplasAmostras ? `Para o recurso mineral ${rowdata.rowData.numeroCampo} estão associadas ás amostras ${amostrasAssociadas.toString().replace(/,/g, ', ')}, caso o recurso mineral seja excluído as amostras serão desassociadas dele` :
                `Para o recurso mineral ${rowdata.rowData.numeroCampo} está associada à amostra ${amostrasAssociadas[0]}, caso o recurso mineral seja excluido à amostra será desassociada dele`;

              texto += ', deseja realmente excluir esse registro ?';

              return swal({
                title: texto,
                icon: 'warning',
                buttons: { cancel: "Não, cancelar", confirm: { text: "Sim, desejo excluir!", className: "btn-danger" } },
              });
            }
          }

          return await swalConfirmarExclusao();
        }
        catch (err) {
          exibirSwalErroExcluir();
        }
        break;
      }
      default: {
        return swalConfirmarExclusao();
      }

    }
  }

  const formatarData = (obj) => {
    const data = new Date(obj.ultimaAtualizacao);
    let ano = data.getFullYear();
    let mes = data.getMonth() + 1 < 10 ? `0${data.getMonth() + 1}` : data.getMonth() + 1;
    let dia = data.getDate() < 10 ? `0${data.getDate()}` : data.getDate();
    let hora = data.getHours() < 10 ? `0${data.getHours()}` : data.getHours();
    let minutos = data.getMinutes() < 10 ? `0${data.getMinutes()}` : data.getMinutes();
    return `(${dia}/${mes}/${ano} - ${hora}:${minutos})`;
  }

  const onBtnEditTableClick = useCallback(async (e, tableRef, rowdata, visualizando = false) => {
    e.preventDefault(); // SubForm não irá atualizar o estado de edição interno e não irá abrir o formulário sozinho

    let idAtividade = parseInt(e.value);
    let tipo = rowdata.rowData.tipo;
    let idVisita = rowdata.rowData.idVisita;
    let atividadeTemporaria = atividadesTemporarias.find((c) => c.idVisita === idVisita && c.tipo === tipo && c.modoCadastro === "TEMPORARIO" && c.permitirEdicao);
    let conteudoAtividade = atividadeTemporaria && atividadeTemporaria.atividade ? atividadeTemporaria.atividade : {};

    const rows = tableRef?.current?.props?.source?.loadedData || tableRef?.current?.props?.items;

    let afloramentoVisita = rows.find(r => r.idVisita == idVisita && r.tipo == TIPO_ATIVIDADE_AFLORAMENTO.id);
    let cadastroAmostraVisita = rows.find(r => r.idVisita == idVisita && r.tipo == TIPO_ATIVIDADE_AMOSTRAS.id);
    let recminVisita = rows.find(r => r.idVisita == idVisita && r.tipo == TIPO_ATIVIDADE_RECURSO_MINERAL.id);
    let geocronVisita = rows.find(r => r.idVisita == idVisita && r.tipo == TIPO_ATIVIDADE_GEOCRONOLOGIA.id);

    setAtividadesVisita({
      idAfloramento: afloramentoVisita ? afloramentoVisita.id : undefined,
      idCadastroAmostra: cadastroAmostraVisita ? cadastroAmostraVisita.id : undefined,
      idRecmin: recminVisita ? recminVisita.id : undefined,
      idGeocron: geocronVisita ? geocronVisita.id : undefined,
    });

    form.mutators.setValue('idVisitaSelecionada', idVisita);

    const exibirModalAtividadeTemporaria = (abrirTemp, abrirDef) => {
      swal({
        title: 'Há um arquivo de recuperação para esta atividade, deseja utilizar ?',
        icon: 'info',
        text: `${formatarData(rowdata.rowData)} - ${rowdata.rowData.modoCadastro === 'RASCUNHO' ? 'Rascunho' : 'Completo'}\n${formatarData(atividadeTemporaria)} - Temporário`,
        closeOnClickOutside: false,
        buttons: { confirm: 'Sim', cancel: 'Não' },
      })
        .then((value) => {
          if (value)
            abrirTemp();
          else
            abrirDef();
        })
        .catch((err) => {
          console.log('Erro ao exibir modal para escolher entre atividade e temporaria', err);
          abrirDef()
        });
    }

    // Após recuperarmos o registroAEditar, escondemos o jqxGrid
    // Como iremos abrir o formulário para edição, não precisamos atualizar esse hook
    // (para voltar a exibir a tabela) nesta função
    setExibirTabela(false);

    if (visualizando) {
      setVisualizando(true);
    }

    switch (tipo) { // idTipoAtividadeSelecionada
      case TIPO_ATIVIDADE_AFLORAMENTO.id: {

        const abrirAfloraTemp = async () => {
          try {
            if (!bibliotecasAfloramento) {
              const bibliotecas = await getBibliotecasAfloramento().catch(exibirTabelaESwalErroBibliotecas(setExibirTabela, setIdTipoAtividadeSelecionada));

              if (!bibliotecas)
                return;

              setBibliotecasAfloramento(bibliotecas);
            }

            setAtividadeEmEdicao(conteudoAtividade);
            setAtividadeIdEdit(conteudoAtividade?.id);
            setIdTipoAtividadeSelecionada(tipo);
            setIdVisitaSelecionada(idVisita);
          }
          catch (err) {
            console.log("Erro ao carregar atividade temporaria aflora", err);
            exibirTabelaESwalErroIniciarEdicao(setExibirTabela);
          }
        }

        const abrirAfloraDefinitiva = () => {
          Service('/afloramentos', AFLORA).get(idAtividade)
            .then(async ({ data: { dados: atividade } }) => {
              if (!bibliotecasAfloramento) {
                const bibliotecas = await getBibliotecasAfloramento()
                  .catch(exibirTabelaESwalErroBibliotecas(setExibirTabela, setIdTipoAtividadeSelecionada));
                if (!bibliotecas) return;
                setBibliotecasAfloramento(bibliotecas);
              }

              setAtividadeEmEdicao(atividade);
              setAtividadeIdEdit(idAtividade);
              setIdTipoAtividadeSelecionada(tipo);
              setIdVisitaSelecionada(atividade.idVisita);
            })
            .catch(exibirTabelaESwalErroIniciarEdicao(setExibirTabela));
        }

        if (atividadeTemporaria)
          exibirModalAtividadeTemporaria(abrirAfloraTemp, abrirAfloraDefinitiva);
        else
          abrirAfloraDefinitiva();
        break;
      }

      case TIPO_ATIVIDADE_GEOQUIMICA.id:
        setAtividadeIdEdit(idAtividade);
        setIdTipoAtividadeSelecionada(tipo);
        setIdVisitaSelecionada(idVisita);
        break; // Atividade é recuperada dentro do componente

      case TIPO_ATIVIDADE_OUTRAS_ATIVIDADES.id:
        Service('/outrascoletas', OUTRAS_COLETAS).get(idAtividade)
          .then(({ data: { dados: atividade } }) => {
            setAtividadeEmEdicao(atividade);
            setAtividadeIdEdit(idAtividade);
            setIdTipoAtividadeSelecionada(tipo);
            setIdVisitaSelecionada(atividade.idVisita);
          })
          .catch(exibirTabelaESwalErroIniciarEdicao(setExibirTabela));
        break;

      case TIPO_ATIVIDADE_RECURSO_MINERAL.id: {
        localStorage.removeItem('SUBSTANCIAS_RECMIN');

        const abrirRecminTemp = () => {
          try {
            setAtividadeEmEdicao(conteudoAtividade);
            setAtividadeIdEdit(conteudoAtividade.id);
            setIdTipoAtividadeSelecionada(tipo);
            setIdVisitaSelecionada(idVisita);
          }
          catch (erro) {
            console.log("Erro ao carregar atividade temporaria recmin", erro);
            exibirTabelaESwalErroIniciarEdicao(setExibirTabela);
          }
        }

        const abrirRecminDefinitivo = () => {
          Service('/recursoMineral', RECMIN).get(idAtividade)
            .then(({ data: { dados: atividade } }) => {
              setAtividadeEmEdicao(atividade);
              setAtividadeIdEdit(idAtividade);
              setIdTipoAtividadeSelecionada(tipo);
              setIdVisitaSelecionada(atividade.idVisita);
            })
            .catch(exibirTabelaESwalErroIniciarEdicao(setExibirTabela));
        }

        if (atividadeTemporaria)
          exibirModalAtividadeTemporaria(abrirRecminTemp, abrirRecminDefinitivo);
        else
          abrirRecminDefinitivo();
        break;
      }

      case TIPO_ATIVIDADE_AMOSTRAS.id: {
        const visitaSelecionada = visitasVisiveis.porId[idVisita] || {};

        setValoresIniciaisAtividades({
          ...valoresIniciaisAtividades,
          idUsuarioColetor: visitaSelecionada.idUsuarioColetor,
          idMetodoGeoposicionamento: formEstacao.getState().values?.idMetodoGeoposicionamento,
          altitude: formEstacao.getState().values?.altitude
        });

        const abrirAmostrasTemp = () => {
          try {
            if (!conteudoAtividade)
              conteudoAtividade = {};

            conteudoAtividade.tipo = 'CADASTRO_AMOSTRA';
            conteudoAtividade.nomeTipo = 'Cadastrar amostras'
            let existentes = [];
            if (conteudoAtividade.amostras && conteudoAtividade.amostras.length) {
              conteudoAtividade?.amostras?.map(amostra => {
                existentes.push(amostra);
              })
              conteudoAtividade.amostras = {
                existentes: existentes ?? []
              }
            } else {
              conteudoAtividade.amostras = {
                existentes: []
              }
            }

            setAbrirFormAmostras(true);
            setAtividadeEmEdicao(conteudoAtividade);
            setAtividadeIdEdit(conteudoAtividade.id);
            setIdTipoAtividadeSelecionada(tipo);
            setIdVisitaSelecionada(idVisita);
          }
          catch (erro) {
            console.log("Erro ao carregar atividade temporaria amostras", erro);
            exibirTabelaESwalErroIniciarEdicao(setExibirTabela);
          }
        }

        const abrirAmostrasDefinitiva = () => {
          Service('/cadastroAmostra', AMOSTRAS).get(idAtividade)
            .then(({ data: { dados: atividade } }) => {
              atividade.tipo = 'CADASTRO_AMOSTRA';
              atividade.nomeTipo = 'Cadastrar amostras';
              let existentes = [];
              if (atividade.amostras) {
                atividade.amostras.map(amostra => {
                  existentes.push(amostra);
                })
                atividade.amostras = {
                  existentes: existentes ?? []
                }
              } else {
                atividade.amostras = {
                  existentes: []
                }
              }

              setPodeInserirAmostras(atividade.permitirInsercao);
              setAbrirFormAmostras(false);
              setAtividadeEmEdicao(atividade);
              setAtividadeIdEdit(idAtividade);
              setIdTipoAtividadeSelecionada(tipo);
              setIdVisitaSelecionada(atividade.idVisita);
            })
            .catch(exibirTabelaESwalErroIniciarEdicao(setExibirTabela));
        }

        if (atividadeTemporaria)
          exibirModalAtividadeTemporaria(abrirAmostrasTemp, abrirAmostrasDefinitiva);
        else
          abrirAmostrasDefinitiva();

        break;
      }

      default:
        console.warn('Tentando editar atividade ' + idAtividade + ' sem comportamento definido para o tipo ' + tipo);
        setExibirTabela(true);
        break;
    }
  }, [
    setAtividadeIdEdit,
    setIdTipoAtividadeSelecionada,
    setIdVisitaSelecionada,
    setAtividadeEmEdicao,
    setValoresIniciaisAtividades,
    setExibirTabela,
    bibliotecasAfloramento,
    visitasVisiveis,
    atividadesTemporarias
  ]);

  const atualizaAtividadesRevisao = useCallback(async () => {
    setListaAtividades([]);
    atualizaAtividades();
  },[]);

  const onBtnChangeEditModeClick = useCallback(async (e, tableRef) => {
    e.preventDefault(); // SubForm não irá chamar onSubmit e também não tentará atualizar os elementos locais internos
    const idAlterado = e.value;
    let servicoMobile = await Service(`/atividades/${idAlterado}/modoEdicao`, "basegeo");
    await servicoMobile.post();

    atualizaAtividades();
  }, []);

  const onBtnDeleteTableClick = useCallback(async (e, tableRef, rowdata) => {
    e.preventDefault(); // SubForm não irá chamar onSubmit e também não tentará atualizar os elementos locais internos

    const data = rowdata?.rowData;

    if (data?.modoCadastro === 'TEMPORARIO') {
      Service(`/estacoes/atividadeTemporaria/${idEstacao}/${data.idVisita}/${data.tipo}`, 'basegeo').delete()
        .then(async () => {
          await tableRef.current?.updatebounddata();
          await atualizaAtividades();
        })
        .catch((erro) => {
          console.log("Não foi possivel excluir a atividade temporaria", erro);
          exibirSwalErroExcluir()
        });

    } else {
      const idExcluido = parseInt(e.value);
      const registroAExcluir = tableRef.current
        ?.getrows()
        .find((r) => r.id === idExcluido);
      const { tipo } = registroAExcluir || {};

      switch (
      tipo // idTipoAtividadeSelecionada
      ) {
        case TIPO_ATIVIDADE_AFLORAMENTO.id:
          await Service("/afloramentos", AFLORA)
            .delete(idExcluido)
            .catch(exibirSwalErroExcluir);
          break;

        case TIPO_ATIVIDADE_GEOQUIMICA.id:
          // TODO: Excluir geoquímica
          break;

        case TIPO_ATIVIDADE_OUTRAS_ATIVIDADES.id:
          await Service("/outrascoletas", OUTRAS_COLETAS)
            .delete(idExcluido)
            .catch(exibirSwalErroExcluir);
          break;

        case TIPO_ATIVIDADE_RECURSO_MINERAL.id:
          await Service("/recursoMineral", RECMIN)
            .delete(idExcluido)
            .catch(exibirSwalErroExcluir);
          break;

        case TIPO_ATIVIDADE_GEOCRONOLOGIA.id:
        await Service("/geocronologias", GEOCRON)
          .delete(idExcluido)
          .catch(exibirSwalErroExcluir);
        break;

        case TIPO_ATIVIDADE_AMOSTRAS.id:
          await Service("/cadastroAmostra", AMOSTRAS)
            .delete(idExcluido)
            .catch(exibirSwalErroExcluir);
          break;

        default:
          console.warn(
            "Tentando excluir atividade " +
            idExcluido +
            " sem comportamento definido para o tipo " +
            tipo
          );
          break;
      }

      // Realizar a requisição que preenche o grid atualizado, agora que o registro foi excluído
      // Como estamos atualizando o jqxGrid, devemos evitar atualizar um hook nesse escopo
      await tableRef.current?.updatebounddata();
      await atualizaAtividades();
    }

  }, []);


  const onBtnViewTableClick = useCallback((e, tableRef, rowdata) => {
    alterarAtividadeAberta(false);
    onBtnEditTableClick(e, tableRef, rowdata, true);
  }, [onBtnEditTableClick]);

  const gerarPdfAtividades = useCallback((e, tableRef, rowdata) => {
    const tipoAtividade = rowdata?.rowData?.tipo;
    const idAtividade = rowdata?.rowData?.id

    let base;
    switch (tipoAtividade) {
      case TIPO_ATIVIDADE_AMOSTRAS.id:
        alterarAtividadeAberta(false);
        const objetoE = {
          value: rowdata?.rowData?.id,
          preventDefault: e?.preventDefault
        };
        setGerandoPdf(true)
        onBtnEditTableClick(objetoE, tableRef, rowdata, true);
        break;
      case TIPO_ATIVIDADE_AFLORAMENTO.id:
        base = process.env.REACT_APP_API_URL_AFLORA;
        gerarPdfRedirecionarNovaAba(idAtividade, base);
        break;
      default:
        break;
    }

  }, [onBtnEditTableClick]);

  const gerarpdfAmostra = (e, tableRef, rowdata) =>{
    const idAmostra = rowdata?.rowData?.idVisualizar;
    const base = process.env.REACT_APP_AMOSTRAS_API_URL;
    gerarPdfRedirecionarNovaAba(idAmostra, base);
  }
  const aoFecharSubFormAtividades = useCallback(() => {
    setIdTipoAtividadeSelecionada(null);
    setAtividadeIdEdit(null);
    setAtividadeEmEdicao(null);
    setVisualizando(false);
    setGerandoPdf(false);
    setExibirBotoes(true);
    setExibirTabela(true);
    atualizaAtividades(form.getState().values.idVisitaSelecionada);
  }, [setAtividadeIdEdit, setIdTipoAtividadeSelecionada, setAtividadeEmEdicao, setExibirBotoes, setExibirTabela, setVisualizando]);

  const disponivelParaSelecao = (row)=>{
    if(row.modoCadastro=="RASCUNHO"||row.modoCadastro=="TEMPORARIO"){
      return false;
    }
    switch (row?.statusValidacao) {
      //marrom
      case "Aguardando revisão": return false;
      case "Aguardando validação": return false;
      //vermelho
      case "Correção solicitada": return false;
      //azul
      case "Corrigido": return true;
      
      case "Validado": return false;
      case "Atividade modificada": return true;
      case null:
      default: return true;
    }
  }
  
  const podeEditarOuExcluir = (row)=>{

    switch (row?.statusValidacao) {
      //marrom
      case "Aguardando revisão": return false;
      case "Aguardando validação": return false;
      //vermelho
      case "Correção solicitada": return true;
      //azul
      case "Corrigido": return true;
      
      case "Validado": return true;
      case "Atividade modificada": return true;
      case null:
      default: return true;
    }
  }

  // ***************************************************************************

  // Após uma edição/criação de atividade, pontos associados a estação podem ter deixado
  // de ser não vinculados. Por isso precisamos atualizar essa array para o próximo formulário
  const atualizarPontosAtribuidosAEstacao = useCallback(async () => {
    return Service("/gps/pontosGPS/paraEstacao")
      .query({ idEstacao })
      .then(({ data: { dados: pontos } }) => {
        pontosAtribuidosAEstacao.current = pontos || [];
      })
      .catch((err) =>
        console.error(
          "Erro ao buscar pontos não vinculados associados a estação:",
          err
        )
      );
  }, [idEstacao]);

  // https://codesandbox.io/s/32r824vxy1
  // https://final-form.org/docs/react-final-form/types/FormProps#subscription
  // https://final-form.org/docs/final-form/types/FormState#values
  let formSubscription;
  switch (idTipoAtividadeSelecionada) {
    case TIPO_ATIVIDADE_AFLORAMENTO.id:
    case TIPO_ATIVIDADE_OUTRAS_ATIVIDADES.id:
      formSubscription = {};
      break;
    case TIPO_ATIVIDADE_RECURSO_MINERAL.id:
      formSubscription = {};
      break;
    case TIPO_ATIVIDADE_AMOSTRAS.id:
      formSubscription = {};
      break;
    case TIPO_ATIVIDADE_GEOCRONOLOGIA.id:
      formSubscription = {};
      break;
    default:
      formSubscription = { values: true };
  }
  // Atualmente não existe visualização de estação, apenas edição. Portanto permitirEdicao é sempre truthy
  const permitirEdicaoDuranteAtividade = permitirEdicao && !visualizando;

  return (
    <>
      {/* <Card className="mb-3">
        <Card.Body> */}
      <Card className="card-mb">
        <Card.Body>
          <Card.Title>Registrar nova atividade</Card.Title>
          <p style={{ fontSize: 11 }}>
            Apenas visitas já salvas podem ser associadas a atividades
          </p>
          {(exibirTabela || formularioAberto) && (
            <Row className="my-3">
              <Col>

                <DropdownListWithValue
                  isClearable={true}
                  opcaoNula={false}
                  elementos={visitasVisiveis.todas}
                  displayMember={"descricao"}
                  placeholder="Selecione uma visita..."
                  onSelect={alterarVisitaSelecionada}
                  value={idVisitaSelecionada}
                  autoFocus
                  disabled={formularioAberto}
                />
              </Col>
              <Col>
                <DropdownListWithValue
                  opcaoNula={false}
                  readOnly={!permitirEdicao}
                  elementos={tiposAtividadesFiltrada}
                  placeholder="Qual módulo de registro de dados deseja acessar?"
                  onSelect={alterarTipoAtividadeSelecionada}
                  value={idTipoAtividadeSelecionada}
                  disabled={!podeAlterarTipoAtividade ? true : formularioAberto}
                  forcePlaceholderColor={true}
                />
              </Col>
            </Row>
          )}
        </Card.Body>
      </Card>

      {TIPO_ATIVIDADE_GEOQUIMICA.id === idTipoAtividadeSelecionada &&
        idVisitaSelecionada ? (
        <GeoquimicaForm
          idAtividade={atividadeIdEdit}
          idProjeto={idProjeto}
          idVisita={idVisitaSelecionada}
          datums={datums}
          pontosAtribuidosAEstacao={pontosAtribuidosAEstacao.current}
          onBtnGoBackClick={aoFecharSubFormAtividades}

        // TODO(?): Visualização geoquímica
        // permitirEdicao={permitirEdicaoDuranteAtividade}
        />
      ) : (
        <CardWrapper wrap={formularioAberto}>
          <SubForm
            nome="atividades"
            onSubmit={enviarFormularioAtividades}
            onBtnEditClick={onBtnEditTableClick}
            onBtnDeleteClick={async (e, tableRef, rowdata) => { await onBtnDeleteTableClick(e, tableRef, rowdata) }}
            onBtnChangeEditModeClick={onBtnChangeEditModeClick}
            onBtnViewClick={onBtnViewTableClick}
            onClose={aoFecharSubFormAtividades}
            permitirAlterarModoEdicao
            // elementos={undefined} // Não estamos utilizando dados locais (tableSource usa url)
            campos={datafieldsAtividades}
            colunas={colunasAtividades}
            formularioAberto={formularioAberto}
            formSubscription={formSubscription} // { values: true }
            valoresIniciais={atividadeIdEdit ? atividadeEmEdicao : valoresIniciaisAtividades}
            validar={TIPO_ATIVIDADE_RECURSO_MINERAL.id === idTipoAtividadeSelecionada ? comporValidadores(validacoesGeraisRecmin) : validacoesGeraisAfloramento}
            criarDecoradores={prefixoSubForm => decoradoresAtividades}

            {...getPropsPermissao(permitirEdicao)}
            permitirVisualizacao={true} // Qualquer usuário com acesso a estação pode visualizar qualquer atividade
            exibirBotaoInsercao={false} // Se utiliza formularioAberto para controlar abertura do formulário
            permitirGerarPdf
            onBtnGerarPdfClick={gerarPdfAtividades}
            exibi
            validarExclusaoAtividade={validarExclusaoAtividade}
            validarVoltar={true}
            naoValidarCamposVoltar={[
              "amostras",
              "coordenadas",
              "coordenada.dentroArea",
              "coordenadas.categoriaLocalizacao",
              "coordenadas.latitudeSIRGAS2000",
              "coordenadas.longitudeSIRGAS2000",
            ]}
            exibirBotoesPadrao={exibirBotoesPadrao}
            labelBotaoConfirmar="Salvar"
            onBtnConfirm={TIPO_ATIVIDADE_RECURSO_MINERAL.id === idTipoAtividadeSelecionada ? validarProcessoANM : undefined}
            exibirBotaoConfirmar={TIPO_ATIVIDADE_AMOSTRAS.id === idTipoAtividadeSelecionada ? false : permitirEdicaoDuranteAtividade}
            // Não estamos passando a prop elementos, portanto podemos utilizar essa
            // prop para exibir ou não a tabela pois o componente pensa que está sem registros
            exibirTabelaSemRegistro={exibirTabela}
            confirmarExclusao={true}
            itensTabela={listaAtividades}
            tableProps={{
              onbindingcomplete: (e) => {
                // Podemos utilizar a ref do jqxGrid aqui, mas não podemos atualizar hooks
                // ou podemos entrar em loop infinito.
                // const jqxGrid = e.owner;

                // Recupera os pontos GPS associados a estação atual que ainda não estão vinculados
                atualizarPontosAtribuidosAEstacao();
              },
              onCellvaluechanged:(e)=>{ 
                const jqxGrid = e.owner;
                const rows =  jqxGrid.getdisplayrows();
                setRowsSelecionadas(rows);
            }
            }}
            colunaCheckboxGrid={true}
            rowDisponivelParaSelecao={disponivelParaSelecao}
            nomeColunaCheckbox={"Disponibilizar para revisão"}
            aoSelecionarTodosCheckbox={setRowsSelecionadas}
            rowDisponivelParaEdicaoOuExclusao={podeEditarOuExcluir}
            tableRef={jqxRef}
            renderForm={({ formProps, prefixoNome, editando }) => {
              // const form = formProps.form;
              return <>
                <Field
                  component={HiddenField}
                  name={`${prefixoNome}id`}
                />

                <Field
                  component={HiddenField}
                  name={`${prefixoNome}idVisita`}
                />

                <Field
                  component={HiddenField}
                  name={`${prefixoNome}nomeTipo`}
                />

                <Field
                  component={HiddenField}
                  name={`${prefixoNome}tipo`}
                />


                <Field name={`${prefixoNome}tipo`} subscription={{ value: true }}>
                  {({ input: { value: tipo = {} } }) => {
                    return (<>
                      {TIPO_ATIVIDADE_AFLORAMENTO.id === tipo && (
                        <AtividadeAfloramento
                          prefixoNome={prefixoNome}
                          permitirEdicao={permitirEdicaoDuranteAtividade}
                          editando={editando}
                          idEstacao={idEstacao}
                          pontosAtribuidosAEstacao={pontosAtribuidosAEstacao.current}
                          visitas={visitasVisiveis.todas}
                          visitaSelecionada={idVisitaSelecionada}
                          area={area}
                          subArea={subArea}

                          // Bibliotecas
                          {...(bibliotecasAfloramento || {})}
                          coletores={coletores}
                          metodosGeoposicionamento={metodosGeoposicionamento}
                          categoriasLimiteArea={categoriasLimiteArea}
                          datums={datums}
                          referenciaForm={formProps}
                          minerais={minerais}
                          prefixos={prefixos}
                          complementos={complementos}
                          cores={cores}
                          tiposIlustracao={tiposIlustracao}
                          extencoesIlustracao={extencoesIlustracao}
                          mineraisPorId={mineraisPorId}
                          prefixosPorId={prefixosPorId}
                          complementosPorId={complementosPorId}
                          atividadesVisita={atividadesVisita}
                        />
                      )}
                       {TIPO_ATIVIDADE_GEOCRONOLOGIA.id === tipo && (
                        <Geocronologias
                          prefixoNome={prefixoNome}
                          permitirEdicao={permitirEdicaoDuranteAtividade}
                          editando={editando}
                          idEstacao={idEstacao}
                          pontosAtribuidosAEstacao={pontosAtribuidosAEstacao.current}
                          visitas={visitasVisiveis.todas}
                          visitaSelecionada={idVisitaSelecionada}
                          area={area}
                          subArea={subArea}
                        />
                      )}
                      {TIPO_ATIVIDADE_OUTRAS_ATIVIDADES.id === tipo && (
                        <AtividadeOutrasColetas
                          prefixoNome={prefixoNome}
                          permitirEdicao={permitirEdicaoDuranteAtividade}
                          editando={editando}
                          coletores={coletores}
                          idEstacao={idEstacao}
                          pontosAtribuidosAEstacao={pontosAtribuidosAEstacao.current}
                          metodosGeoposicionamento={metodosGeoposicionamento}
                          categoriasLimiteArea={categoriasLimiteArea}
                          datums={datums}
                          tiposIlustracao={tiposIlustracao}
                          extencoesIlustracao={extencoesIlustracao}
                          classesAmostra={classesAmostra}
                        />
                      )}
                      {TIPO_ATIVIDADE_RECURSO_MINERAL.id === tipo && (
                        <RecursoMineral
                          alterarExibirBotoesPadrao={setExibirBotoesPadrao}
                          idAtividade={atividadeIdEdit}
                          prefixoNome={prefixoNome}
                          permitirEdicao={permitirEdicaoDuranteAtividade}
                          editando={editando}
                          referenciaForm={formProps}
                          idEstacao={idEstacao}
                          pontosAtribuidosAEstacao={pontosAtribuidosAEstacao.current}
                          metodosGeoposicionamento={metodosGeoposicionamento}
                          datums={datums}
                          tiposIlustracao={tiposIlustracao}
                          extencoesIlustracao={extencoesIlustracao}
                          minerais={minerais}
                          mineraisPorId={mineraisPorId}
                          complementosPorId={complementosPorId}
                          categoriasLimiteArea={categoriasLimiteArea}
                          prefixosPorId={prefixosPorId}
                          area={area}
                          subArea={subArea}
                          visitaSelecionada={idVisitaSelecionada}
                          atividadesVisita={atividadesVisita}
                        />
                      )}
                      {TIPO_ATIVIDADE_AMOSTRAS.id === tipo && (
                        <Amostras
                          exibirBotaoInsercao={true}
                          atualizarPontosGps={atualizarPontosAtribuidosAEstacao}
                          idAtividade={atividadeIdEdit}
                          prefixoNome={prefixoNome}
                          permitirEdicao={permitirEdicaoDuranteAtividade}
                          editando={editando}
                          referenciaForm={formProps}
                          idEstacao={idEstacao}
                          pontosAtribuidosAEstacao={pontosAtribuidosAEstacao.current}
                          metodosGeoposicionamento={metodosGeoposicionamento}
                          datums={datums}
                          tiposIlustracao={tiposIlustracao}
                          extencoesIlustracao={extencoesIlustracao}
                          minerais={minerais}
                          mineraisPorId={mineraisPorId}
                          complementosPorId={complementosPorId}
                          prefixosPorId={prefixosPorId}
                          area={area}
                          subArea={subArea}
                          visitaSelecionada={idVisitaSelecionada}
                          visitas={visitasVisiveis.todas}
                          categoriasLimiteArea={categoriasLimiteArea}
                          nomeProjeto={nomeProjeto}
                          idProjeto={idProjeto}
                          coletores={coletores}
                          idColetor={valoresIniciaisAtividades.idUsuarioColetor}
                          idMetodoGeoposicionamento={valoresIniciaisAtividades.idMetodoGeoposicionamento}
                          altitude={valoresIniciaisAtividades.altitude}
                          atualizaAtividades={atualizaAtividades}
                          coordenadas={formEstacao.getState().values?.coordenadas}
                          atividades={listaAtividades}
                          classesAmostra={classesAmostra}
                          abrirFormAmostras={abrirFormAmostras}
                          atividadeEmEdicao={atividadeEmEdicao}
                          aoFecharSubFormAtividades={aoFecharSubFormAtividades}
                          atividadesVisita={atividadesVisita}
                          permitirInsercao={podeInserirAmostras}
                          permitirGerarPdf={gerandoPdf}
                          gerarPdfAmostra={gerarpdfAmostra}
                        />
                      )}
                    </>);
                  }}
                </Field>
              </>
            }}
          />
         {!formularioAberto &&( <Row className="mt-2">
            <Col md={12}>
                <FocusableButton
                  className="float-right m-1 mt-2 "
                  style={{ fontSize: "smaller" }}
                  onClick={async () => {

                    const rowsMarcadasParaEnvio = rowsSelecionadas.filter((item) => item.checkboxMultiplaSelecao && disponivelParaSelecao(item));
                    const qtdItensSelecionados = rowsMarcadasParaEnvio.length;
                    if (qtdItensSelecionados <= 0) {
                      await swal({
                        title: `É preciso selecionar ao menos uma atividade para revisão`,
                        icon: "info",
                    });
                    return
                  }
                  const result = await swal({
                    title: `Enviar ${qtdItensSelecionados} atividade(s) para revisão? Edição e exclusão ficarão indisponíveis após o envio`,
                    icon: "info",
                    buttons: {
                      cancel: "Não, cancelar",
                      confirm: { text: "Sim, desejo prosseguir!", className: "btn-success" },
                    },
                  });

                  if (!result) return;

                  const payloadRevisao = {idsSolicitados:rowsMarcadasParaEnvio.map(item=>item.id)};
                  try {
                    await Service('/atividades/enviaParaRevisao', VALIDACAO).post(payloadRevisao).then(() =>{
                      atualizaAtividadesRevisao();                      
                    });                    
                  } catch (error) {
                    const errorResponse = error?.response?.data;
                    await swal({
                      title: `Erro`,
                      text: errorResponse?.erros?.[0],
                      icon: "error",
                    });
                  }
                }}>
                Enviar para revisão
              </FocusableButton>
            </Col>
          </Row>)}
        </CardWrapper>
      )}

      {exibirBotoes && <BotoesVoltarSalvar exibirBotaoSalvar={false} />}
      {/* </Card.Body>
      </Card> */}
    </>
  );
};

export default RegistrarObjetoFunc;
