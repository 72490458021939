import React from "react";
import PropTypes from "prop-types";

export default function Hint({ textoAjuda }) {
  return (
    <span title={textoAjuda}>
      <i className="fas fa-question-circle ajuda-campo" />
    </span>
  );
}

Hint.propTypes = {
  textoAjuda: PropTypes.string.isRequired,
};
