import { Field, useForm } from "react-final-form";
import Card from "../../../components/card";
import IlustracoesMaterialSuplementar from "./IlustracoesMaterialSuplementar";

const MaterialSuplementar = ({
    extencoesIlustracao,
    amostraSelecionada,
    tiposIlustracao,
    disabled,
    permitirInsercao
}) => {
    const form = useForm()

    return (
        <div style={{ marginTop: '2.5rem' }}>
            <Card>
                <Card.Body>
                    <Field name={"materialSuplementar"} subscription={{ value: true }}>
                        {() => {
                            let tempNumeroAmostra = amostraSelecionada?.numeroAmostra ?? "";
                            return <IlustracoesMaterialSuplementar
                                tituloForm={"Material suplementar"}
                                nome={"materialSuplementar"}
                                elementos={form.getState().values.materialSuplementar}
                                permitirEdicao={!disabled}
                                permitirExclusao={!disabled}
                                permitirInsercao={permitirInsercao}
                                exibirBotaoInsercao={permitirInsercao}
                                permitirVisualizacao={disabled}
                                tipoAtividadeIlustracao={"estacao"}
                                nomeIlustracaoEstacao={tempNumeroAmostra}
                                tiposIlustracao={tiposIlustracao}
                                extencoesIlustracao={extencoesIlustracao}
                                legendaObrigatoria={true}
                            />
                        }}
                    </Field>
                </Card.Body>
            </Card>
        </div>
    );
};

export default MaterialSuplementar;