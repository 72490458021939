import React, { useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import Form from "react-bootstrap/Form";
import Hint from "../../hint/Hint";
import JqxDateTimeInput from "jqwidgets-scripts/jqwidgets-react-tsx/jqxdatetimeinput";
import Erro from "../../Erro";

import './globalize.culture.pt-BR'
import './DateTimeInput.css'

const DateTimeInput = ({valor, label, dica, onChange, required, mensagemErro, mostrarCalendario, ...props}) => {
  const onChangeCallback = useCallback((evt) => {
    // FIXME: evt.args is undefined caso seja inserido uma caractere inválido. Verificar se objeto existe quebra atualização do valor
    const valor = evt.args.date
    onChange && onChange(valor)
  }, [onChange])
  
  useEffect(()=>{
    const elements = [...document.getElementsByClassName(`jqx-calendar jqx-calendar-bootstrap`)];
    elements.map((element)=>{
      element.setAttribute("tabindex","")
      return element;
    })
  },[])

  return (
    <>
      {label && <Form.Label className="small">
        {label}
        {required && <span style={{color: "red"}}>*</span>}
        {dica && <Hint textoAjuda={dica}/>}
      </Form.Label>}
      <JqxDateTimeInput
        width={"98%"}
        theme="bootstrap"
        culture="pt-BR"
        onChange={onChangeCallback}
        showCalendarButton={mostrarCalendario}
        value={valor}
        allowNullDate={true}
        {...props}
      />
      <Erro mensagem={mensagemErro} />
    </>
  )
}

DateTimeInput.propTypes = {
  valor: PropTypes.object,
  label: PropTypes.string,
  dica: PropTypes.string,
  mensagemErro: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  required: PropTypes.bool,

  onChange: PropTypes.func,
  mostrarCalendario: PropTypes.bool,
  min: PropTypes.object,
  max: PropTypes.object,
}

DateTimeInput.defaultProps = {
  mostrarCalendario: true,
}

export default DateTimeInput;
