import { asapScheduler, Observable, of, Subject, Subscription } from "rxjs";
import {
  debounceTime,
  finalize,
  first,
  observeOn,
  shareReplay,
  tap,
} from "rxjs/operators";
import { onSubscribe } from "@rxjs-lifecycle/core";
import eventBus from "./EventBus";

export interface CacheRequest<I = any, O = I> {
  path: string;
  source: (path: string) => Observable<I>;
  intercept?: (observable: Observable<I>) => Observable<O>;
  saveToStorage?: boolean;
  lifetimeSeconds?: number;
}

export interface ICacheService {
  getCached<T = any>(cacheRequest: CacheRequest<T>): Observable<T>;
  removeCache(...paths: string[]);
  clearCaches();
}

interface CacheItem {
  observable: Observable<any>;
  expiresSubscription: Subscription;
}

export class CacheService implements ICacheService {
  cacheMap = new Map<string, CacheItem>();

  constructor(private prefix: string, private storage?: Storage) {}

  getCached<T = any>(cacheRequest: CacheRequest<T>): Observable<T> {
    return new Observable<T>((subscriber) => {
      const saveToStorage =
        typeof cacheRequest.saveToStorage === "boolean"
          ? cacheRequest.saveToStorage
          : true;

      const path = cacheRequest.path;
      const key = this.prefix + "." + path;

      const lifetime =
        Math.floor(cacheRequest.lifetimeSeconds ?? 60 * 60) * 1000;

      let observable: Observable<T>;
      if (this.cacheMap.has(key)) {
        observable = this.cacheMap.get(key)?.observable!;
      } else {
        if (this.storage && this.storage.getItem(key)) {
          let aux: any = this.storage.getItem(key);
          if (aux) {
            aux = JSON.parse(aux);
          }
          observable = of(aux);
          if (cacheRequest.intercept) {
            observable = cacheRequest.intercept(observable);
          }
        } else {
          observable = cacheRequest.source(path);
          if (cacheRequest.intercept) {
            observable = cacheRequest.intercept(observable);
          }
          if (saveToStorage && this.storage) {
            observable = observable.pipe(
              tap((x) => {
                if (this.storage) {
                  let aux: any = x;
                  if (typeof aux === "object") {
                    aux = JSON.stringify(aux);
                  }
                  this.storage.setItem(key, aux);
                }
              })
            );
          }
        }

        const isLifetimeInfinity =
          lifetime <= -1 || lifetime === Number.POSITIVE_INFINITY;

        const expiresSubscription = new Subscription();
        let expiresSubject: Subject<any>;
        let expiresObservable: Observable<any>;
        let expiresSubscriptionInternal: Subscription;

        if (!isLifetimeInfinity) {
          expiresSubject = new Subject();
          expiresObservable = expiresSubject.pipe(
            debounceTime(lifetime),
            first(),
            tap(() => {
              expiresSubscription.unsubscribe();
            })
          );
        }

        expiresSubscription.add(() => {
          this.cacheMap.delete(key);
          expiresSubject?.complete();
          expiresSubscriptionInternal?.unsubscribe();
        });

        observable = observable.pipe(
          tap({
            next: () => {
              if (!isLifetimeInfinity) {
                expiresSubscriptionInternal?.unsubscribe();
                expiresSubscriptionInternal = expiresObservable?.subscribe();
                expiresSubject?.next();
              }
            },
            error: (err) => {
              expiresSubscription.unsubscribe();
              if (this.storage) {
                this.storage.removeItem(key);
              }
            },
          })
        );

        if (!isLifetimeInfinity) {
          observable = observable.pipe(
            finalize(() => {
              expiresSubscription.unsubscribe();
            })
          );
        }

        observable = observable.pipe(shareReplay(1), observeOn(asapScheduler));

        if (!isLifetimeInfinity) {
          observable = observable.pipe(
            onSubscribe(() => {
              expiresSubject?.next();
            })
          );
        }

        this.cacheMap.set(key, {
          observable,
          expiresSubscription,
        });
      }
      return observable.subscribe(subscriber);
    });
  }

  removeCache(...paths: string[]) {
    let key: string;
    for (const path of paths) {
      key = this.prefix + "." + path;
      if (this.cacheMap.has(key)) {
        const aux = this.cacheMap.get(key);
        aux?.expiresSubscription?.unsubscribe();
        this.cacheMap.delete(key);
      }
    }
  }

  clearCaches() {
    for (const aux of this.cacheMap.values()) {
      aux?.expiresSubscription?.unsubscribe();
    }

    this.cacheMap.clear();
  }

  dispose() {
    this.clearCaches();
  }
}

const cacheService = new CacheService("global");

eventBus.on("logout").subscribe(() => {
  cacheService.clearCaches();
});

export default cacheService;
