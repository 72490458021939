import React, { Component } from "react";
import Service from "../../service/Service";
import { Field, Form as FForm } from "react-final-form";
import Container from "react-bootstrap/Container";
import Card from "../../components/card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import BlockUi from "react-block-ui";
import InformacoesGerais from "./components/InformacoesGerais";
import InformacoesCoordenadas from "./components/mapa/InformacoesCoordenadas";
import JqxNotification from "jqwidgets-scripts/jqwidgets-react-tsx/jqxnotification";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import VisitasEstacao from "./components/VisitasEstacao";
import PageScaffold from "../../components/scaffold/PageScaffold";
import dayjs from "dayjs";
import { setValue } from "../../util/mutadores";
import PrimaryButton from "../../components/Jqx/Button/PrimaryButton";
import converterVetorParaObjetoIndexado from "../../util/converterVetorParaObjetoIndexado";
import RegistrarObjetoFunc from "./components/RegistrarObjetoFunc";
import Ilustracoes2 from "./components/Ilustracoes2";
import { mostrarNotificacao } from "../../reducers/notificacaoReducer";
import { connect, useSelector } from "react-redux";
import swal from "sweetalert";
import {
  decoradorMapa,
  decoradorSirgas2000,
} from "../../util/decoradores/decoradorMapa";
import { buscarUtilizados } from "../../util/buscarUtilizados";
import { camposForamEditados } from "../../util/formFoiEditado"
import NotificadorErrosForm from "../../components/NotificadorErrosForm";
// eslint-disable-next-line no-unused-vars
import isEqual from "lodash/isEqual";
import omit from "lodash/omit";

import { configMapaEstacao } from "../../config/mapa";
import { scrollAoErro } from "../../util/validadores";
import { resetarLatLongInvalidaAoTrocarHemisferio } from "../../util/decoradores/decoradorSeletorCoordenadas";
import InfoPanel from '../../components/infoPanel';
import { selecionarVisita } from "../../reducers/visitaReducer";
import { resolverExibidos } from "../../components/SubForm/SubForm";
import { object } from "prop-types";

const mapDispatchToProps = (dispatch) => {
  return {
    enviarNotificacaoSucesso(mensagem) {
      dispatch(
        mostrarNotificacao(mensagem, {
          tipo: "success",
        })
      );
    },
    selecionarVisita(visita) {
      dispatch(selecionarVisita(visita))
    },
    enviarAlertaErro(erro) {
      dispatch(mostrarNotificacao(erro))
    }
  };
};

// Titulos da aba, ordenados por índice da aba
const titulos = [
  'Detalhes da Estação',
  'Cadastro de Atividade',
  'Envio ao Laboratório',
  'Importação de resultado'
]

class Estacao extends Component {
  constructor(props) {
    super(props);

    this.state = {
      exibirBreadCrumb: false,
      visitaSelecionada: null,
      atividadeAberta: false,
      carregamentoGlobal: true,
      tabSelected: 0,
      datums: [],
      // Dados do projeto/area/subárea selecionados na tela inicial de Estações
      nomeProjeto: this.props.location.state.nomeProjeto,
      // nomeProjeto: this.props.location.state.idProjeto, //this.props.location.state.nomeProjeto,
      nomeArea: this.props.location.state.nomeArea,
      nomeSubArea: this.props.location.state.nomeSubArea,
      idArea: this.props.location.state.idArea,
      idSubArea: this.props.location.state.idSubArea,
      breadcrumbVisita: null,
      exibirValidacaoVisita: false
    };
    this.voltar = this.voltar.bind(this);
    this.enviarFormulario = this.enviarFormulario.bind(this);
    this.breadcrumbVisita = null;

    // this.aoAlterarVisitas = this.aoAlterarVisitas.bind(this)
    this.atividadeAberta = false;
    this.subformAberto = false;
    this.servicoEstacao = Service("/estacoes");
    this.decoradores = [decoradorMapa(), decoradorSirgas2000(), resetarLatLongInvalidaAoTrocarHemisferio()];
  }

  async componentDidMount() {
    if(localStorage.getItem("LAST_PAGE") == 'estacoes')
      localStorage.removeItem('coodernadasVoltar');

    console.log(this.props.location);
    console.info(
      "Iniciando form de estação para id:",
      this.props.location.state.idEstacaoSelecionada
    );

    this.idProjeto = this.props.location.state.idProjeto;
    const idProjeto = this.idProjeto;

    // Listagens usadas em algum lugar (preencher select, etc)
    const [
      datums,
      coletores,
      metodosGeoposicionamento,
      instituicoes,
      minerais,
      prefixos,
      complementos,
      cores,
      tiposIlustracao,
      classesAmostra,
      extencoesIlustracao,
      categoriasLimiteArea,
    ] = await Promise.all([
      Service("/gps/datums").query(),
      Service(`/coletores/paraProjeto/${idProjeto}`).query(),
      Service("/metodosGeoPosicionamento").query(),
      Service(`/instituicoes/paraProjeto/${idProjeto}`).query(),
      Service("/minerais").query(),
      Service("/prefixos").query(),
      Service("/complementos").query(),
      Service("/cores").query(),
      Service("/tiposIlustracao").query(),
      Service("/classesAmostraIndependente").query(),
      Service("/extencoesIlustracao").query(),
      Service("/categoriasLimiteArea").query(),
    ]);

    // Valores iniciais do final-form de estação e do Subform de atividades<<< HEAD

    let datumInicial = null;

    this.valoresIniciais = {
      ...this.props.location.state,

      nome: "Nome da estação gerado automaticamente",
      dataPrimeiroCadastro: dayjs().format("DD/MM/YYYY"),
      coordenadas: {
        ...this.props.location.state.coordenadas,
      },

      idDatum: datumInicial,
      nomeProjeto: this.state.idProjeto, //this.state.nomeProjeto,
      nomeArea: this.state.nomeArea,
      nomeSubArea: this.state.nomeSubArea,
    };

    this.valoresIniciaisAtividades = {
      altitude: this.props.location.state.altitude,
      coordenadas: {
        ...this.props.location.state.coordenadas,
      },
    };


    // Recupera e armazena o id da estação editada, pode ser utilizado para descobrir se está criando ou editando
    const idEstacaoSelecionada = this.props.location.state.idEstacaoSelecionada;
    this.idEstacao = idEstacaoSelecionada;

    // Habilitar campos e botões de salvar se não há id (nova estação)
    // ou se há id e location.state.permitirEdicao=true (editando)
    this.permitirEdicao =
      isNaN(parseInt(idEstacaoSelecionada)) ||
      (!isNaN(parseInt(idEstacaoSelecionada)) &&
        this.props.location.state.permitirEdicao);
    console.log("Permitir edição:", this.permitirEdicao);

    let nomeEstacao = undefined;

    // Recuperar e atribuir dados iniciais caso esteja editando uma estação existente
    let modificacoes = {};
    let visitasIniciais = null;
    let instituicoesProjetos, coletoresProjetos;
    if (idEstacaoSelecionada) {
      const dadosEstacaoRes = await this.servicoEstacao.get(
        idEstacaoSelecionada
      );

      nomeEstacao = dadosEstacaoRes.data.dados.nome;

      this.valoresIniciais = {
        ...this.valoresIniciais,
        ...dadosEstacaoRes.data.dados,
      };

      visitasIniciais = dadosEstacaoRes.data.dados.visitas;

      if (visitasIniciais) {
        // Se a estação possuir visitas existentes, deve-se recuperar as instituições e coletores
        // dessas visitas também para que os selects sejam devidamente preenchidos

        // IDs dos projetos de todas as visitas
        const ids = buscarUtilizados(
          visitasIniciais,
          (e) => e.idProjeto
        ).filter((id) => id !== undefined);
        const promisesInstituicoes = Service(
          `/instituicoes/paraProjetos/${[idProjeto, ...ids].join(",")}`
        ).query();
        const promisesColetores = Service(
          `/coletores/paraProjetos/${[idProjeto, ...ids].join(",")}`
        ).query();
        await Promise.all([promisesInstituicoes, promisesColetores]).then(
          ([instituicoes, coletores]) => {
            instituicoesProjetos = instituicoes.data.dados;
            coletoresProjetos = coletores.data.dados;
            // console.log('coletoresProjetos', coletoresProjetos)
          }
        );
      }
    } else {
      // Criando uma nova estação
      // Há pontos GPS vindos da tela anterior
      try {
        const res = await this.servicoEstacao.customURL("POST", "/nomeProximaEstacao", this.props.location.state.coordenadas);
        nomeEstacao = res.data ? res.data : undefined;
        this.valoresIniciais.nome = nomeEstacao;

      }
      catch(err){}

      if (
        this.props.location.state.pontosGPSParaNovaEstacao &&
        this.props.location.state.pontosGPSParaNovaEstacao.length > 0
      ) {
        console.log(
          this.props.location.state.pontosGPSParaNovaEstacao.length,
          "pontos GPS vindos da tela de coordenadas para serem associados com a estação a ser criada"
        );
      }
    }

    visitasIniciais = visitasIniciais || {
      existentes: [],
      novos: [],
      editadosPorId: {},
      idsExcluidos: [],
    };

    modificacoes = {
      ...modificacoes,
      datums: datums.data.dados,
      metodosGeoposicionamento: metodosGeoposicionamento.data.dados,
      categoriasLimiteArea: categoriasLimiteArea.data.dados,
      minerais: minerais.data.dados,
      prefixos: prefixos.data.dados,
      complementos: complementos.data.dados,
      cores: cores.data.dados,
      tiposIlustracao: tiposIlustracao.data.dados,
      extencoesIlustracao: extencoesIlustracao.data.dados,
      classesAmostra: classesAmostra.data.dados,

      coletores: coletoresProjetos || coletores.data.dados,
      instituicoes: instituicoesProjetos || instituicoes.data.dados,

      mineraisPorId: converterVetorParaObjetoIndexado(minerais.data.dados),
      prefixosPorId: converterVetorParaObjetoIndexado(prefixos.data.dados),
      complementosPorId: converterVetorParaObjetoIndexado(complementos.data.dados),
    };

    this.setState(
      {
        ...modificacoes,
        visitasIniciais,
        nomeEstacao,
        carregamentoGlobal: false,
        exibirBreadCrumb: true
      },
      () => {
        // Exibe notificação de sucesso caso esteja carregando uma estação recém salva
        if (this.props.location.state.salvoSucesso) {
          const enviarNotificacao = this.props.enviarNotificacaoSucesso;
          enviarNotificacao("Estação salva com sucesso");
        }
      }
    );

    this.handleVisita()
  }

  alterarAtividadeAberta(value) {
    this.atividadeAberta = value
  }

  async enviarFormulario(valores) {
    this.setState({ carregamentoGlobal: true });

    localStorage.setItem('LAST_PAGE', 'estacoes');

    const servicoEstacao =  Service("/estacoes");

    if(!valores.id){
      const resVerificarPonto = await servicoEstacao.customURL("POST", "/verificarPonto", valores.coordenadas);

      if(resVerificarPonto.data.dados) {
        const pontoIdentico = resVerificarPonto.data.dados.pontoIdentico;
        const estacaoExistente = resVerificarPonto.data.dados.estacao;

        if(pontoIdentico) {
          this.setState({ carregamentoGlobal: false });
          swal({
            title: 'Estação na mesma coordenada',
            icon: 'info',
            text: `Já existe uma estação cadastrada nesta mesma coordenada - ${
              estacaoExistente.nome
            }${
              estacaoExistente?.apelido ? ` (${estacaoExistente?.apelido})` : ''
            }, altere as coordenadas para cadastrar uma nova estação ou abra a estação existente para edição`,
            closeOnClickOutside: false,
            buttons: { confirm: 'Confirmar'},
          })
          return;
        }
      }
    }

    // Filtrar hashs de arquivos excluidos antes de serem salvos
    if(valores.ilustracoes && valores.ilustracoes.editadosPorId){
      const list = Array.from(Object.entries(valores.ilustracoes.editadosPorId));

      list.forEach(e => {
        let key = e[0];

        if(valores.ilustracoes.editadosPorId[key]?.hashesArquivosExcluidos && valores.ilustracoes.editadosPorId[key]?.hashesNovosArquivos)
          valores.ilustracoes.editadosPorId[key].hashesNovosArquivos = valores.ilustracoes.editadosPorId[key].hashesNovosArquivos.filter(( el ) => !valores.ilustracoes.editadosPorId[key].hashesArquivosExcluidos.includes( el ))

        if(valores.ilustracoes.editadosPorId[key]?.hashesArquivosExcluidos){
          valores.ilustracoes.editadosPorId[key].hashesArquivosExcluidos = valores.ilustracoes.editadosPorId[key].hashesArquivosExcluidos
          .filter(id => !isNaN(id));
        }
      });
    }

    if(valores.ilustracoes && valores.ilustracoes.novos){
      valores.ilustracoes.novos.forEach(item => {
        if(item?.hashesArquivosExcluidos && item?.hashesNovosArquivos)
          item.hashesNovosArquivos = item.hashesNovosArquivos.filter(( el ) => !item.hashesArquivosExcluidos.includes( el ));

        if(item?.hashesArquivosExcluidos)
          item.hashesArquivosExcluidos = item.hashesArquivosExcluidos.filter(id => !isNaN(id));
      });
    }

    await this.servicoEstacao
      .post(valores)
      .then(async (res) => {
        const idEstacaoSalva = res.data.dados; // id da estação criada ou editada
        console.info("Estação salva, id:", idEstacaoSalva);

        // Associar automaticamente pontos vindos da tela de coordenadas, caso existam
        // São associados com uma estação recem criada
        const pontosGPS = this.props.location.state.pontosGPSParaNovaEstacao;
        if (pontosGPS && pontosGPS.length > 0) {
          const err = await Service(
            `gps/associarPontoEstacao/${idEstacaoSalva}`
          )
            .post(pontosGPS.map((p) => p.id)) // request body
            .then(() => { })
            .catch((err) => err);

          if (err) {
            console.error("Erro ao associar pontos:", err);
          } else
            console.log(pontosGPS.length, "pontos GPS associados à estação");
        }

        // Atualiza history state com id (para novas estações, que não possuiam a propriedade)
        this.props.history.replace(this.props.path, {
          ...this.props.location.state,
          idEstacaoSelecionada: idEstacaoSalva,
          pontosGPSParaNovaEstacao: null, // Pontos já associados e estação já foi criada
          permitirEdicao: true,
          salvoSucesso: true, // mostrar notificação sucesso, etc
        });

        // Reinicia a página no navegador mantendo/atualizando(?) o this.props.location.state
        this.props.history.go(0);

        // this.voltar();
      })
      .catch((err) => {
        console.error("Erro ao enviar formulário:", err);
        swal({
          title: "Não foi possível enviar o formulário",
          icon: "error",
          buttons: {
            confirm: { text: "Ok", className: "btn-primary" },
          },
        });
        this.setState({ carregamentoGlobal: false });
      });
  }

  async voltar(form) {
    const result = await camposForamEditados(form, [
      "amostras",
      "coordenadas.latitudeSIRGAS2000",
      "coordenadas.longitudeSIRGAS2000",
      "coordenadas",
      "idsVisitasComAtividadesExistentes"
    ]);

    if (result === null) return false;

    window.scrollTo(0,0);

    let lastPage = localStorage.getItem("LAST_PAGE");

    console.log(' this.props.history',  this.props.history)

    if(!lastPage || lastPage === 'estacoes')
      this.props.history.push(`/estacoes`);
    else{
      this.props.history.goBack();
    }
  }

  aoSelecionarAba = async (i, last, event, form) => {
    let ignorarValidacao = [];
    ignorarValidacao = ['coordenada', 'coordenadas.longitudeSIRGAS2000', 'coordenadas.latitudeSIRGAS2000', 'coordenadas.categoriaLocalizacao', 'idVisitaSelecionada', 'geometryFilter'];
    let coordenadas = form.getState().values.coordenadas;
    let coordenadasIniciais = this.valoresIniciais.coordenadas;
    coordenadasIniciais.latitudeSIRGAS2000 = coordenadas.latitudeSIRGAS2000;
    coordenadasIniciais.longitudeSIRGAS2000 = coordenadas.longitudeSIRGAS2000;
    if (isEqual(coordenadas, coordenadasIniciais)) {
      ignorarValidacao.push('coordenadas');
    }
    event.preventDefault();
    event.stopPropagation();

    let valoresIniciais = this.valoresIniciais;
    if (last === 0 && i === 1) {
      valoresIniciais.coordenada = form.getState().values.coordenada

      let valorFormComparar = omit(form.getState().values, ignorarValidacao);
      let valoresIniciaisComparar = omit(valoresIniciais, ignorarValidacao);

      if (!isEqual(valorFormComparar, valoresIniciaisComparar)) {
        let response = await camposForamEditados(form, ignorarValidacao, swal({
          title: 'Deseja continuar?',
          text: "Dados alterados não salvos serão perdidos",
          icon: 'warning',
          buttons: { cancel: "Não, permanecer nessa aba", confirm: { text: "Sim, trocar de aba", value: true, className: 'btn-primary' } },
        }).then(async (value) => {

          if (value) {
            form.batch(() => {
              for (const [key, val] of Object.entries(valoresIniciais)) {
                form.mutators.setValue(`${key}`, val);
              }
            });

            this.setState({
              caminhoAtual: this.state.caminhoBreadcrumbAbaAtividade,
              tabSelected: i
            })
          }
        }));
        console.log(response);
      } else if (this.subformAberto) {
        let response = await camposForamEditados(form, ignorarValidacao, swal({
          title: 'Deseja continuar?',
          text: "Dados alterados não salvos serão perdidos",
          icon: 'warning',
          buttons: { cancel: "Não, permanecer nessa aba", confirm: { text: "Sim, trocar de aba", value: true, className: 'btn-primary' } },
        }).then(async (value) => {
          if (value) {
            form.batch(() => {
              for (const [key, val] of Object.entries(valoresIniciais)) {
                form.mutators.setValue(`${key}`, val);
              }
            });
            this.setState({
              caminhoAtual: this.state.caminhoBreadcrumbAbaAtividade,
              tabSelected: i
            })
          }
        }));
        console.log(response);
      }
      else {
        this.setState({
          tabSelected: i
        })
      }
    }
    if (last === 1 && i === 0 && !this.atividadeAberta) {
      this.setState({
        caminhoAtual: this.state.caminhoBreadCrumbAbaAtividadesProjeto,
        tabSelected: i
      })
    }
    if (last === 1 && i === 0 && this.atividadeAberta) {
      return swal({
        title: 'Deseja continuar?',
        text: "Dados alterados não salvos serão perdidos",
        icon: 'warning',
        buttons: { cancel: "Não, permanecer nessa aba", confirm: { text: "Sim, trocar de aba", value: true, className: 'btn-primary' } },
      }).then(async (value) => {
        if (value) {
          form.batch(() => {
            for (const [key, val] of Object.entries(valoresIniciais)) {
              form.mutators.setValue(`${key}`, val);
            }
          });
          this.setState({
            caminhoAtual: this.state.caminhoBreadCrumbAbaAtividadesProjeto,
            tabSelected: i
          })
        }
      })
    }
    console.log("tab atual: " + last)
    const novoTitulo = titulos[i] || 'Estação';
    const elem = document.querySelector(".navbar-titulo");
    this.handleVisita();
    if (elem) elem.innerText = novoTitulo;
  };


  validarVisitasAoFecharForm(form) {
    if(this.state.exibirValidacaoVisita){
      const visitasForm = form?.getState()?.values?.visitas;

      if(!visitasForm)
        return;

      let visitas = resolverExibidos(visitasForm);

      if(!visitas)
        return;

      visitas = visitas.filter((v) => v != null);

      if(visitas.length < 1)
        return;
      else
        this.setState({ exibirValidacaoVisita: false });
    }
  }

  handleVisita() {
    const visita = {
      nomeProjeto: this.props.location.state.nomeProjeto,
      area: this.props.location.state.nomeArea,
      subArea: this.props.location.state.nomeSubArea,
    }

    this.props.selecionarVisita(visita);
  }

  renderForm = ({ form }) => {
    const { submit } = form;
    const valoresIniciaisAtividades = this.valoresIniciaisAtividades;
    const metodo = form.getState().values[`coordenadas.idMetodoGeoposicionamento`];
    let valoresIniciaisCoordenadas = valoresIniciaisAtividades?.coordenadas;

    if (valoresIniciaisAtividades && valoresIniciaisAtividades.coordenadas) {
      if ('idMetodoGeoposicionamento' in valoresIniciaisAtividades?.coordenadas) {

      } else {
        if (!valoresIniciaisCoordenadas || !valoresIniciaisCoordenadas.idMetodoGeoposicionamento) {
          valoresIniciaisCoordenadas = { coordenadas: { ...valoresIniciaisCoordenadas }, idMetodoGeoposicionamento: 3 };
        }


      }
    }

    const BotoesVoltarSalvar = ({ exibirBotaoSalvar = true }) => (
      <Row className="pr-3 subform-btns-padrao subform-btns-padrao-estacao">
        <Col className="mr-2">
          {this.permitirEdicao && exibirBotaoSalvar && (
            <PrimaryButton
              className="float-right m-1 ml-2 subform-btn-confirmar subform-btn-confirmar-estacao"
              onClick={() => {
                let mensagemValidacaoVisitas = 'Ao menos uma visita deve ser cadastrada para salvar a estação';
                const visitasForm = form?.getState()?.values?.visitas;

                if(!visitasForm){
                  this.props.enviarAlertaErro(mensagemValidacaoVisitas);
                  this.setState({exibirValidacaoVisita: true});
                  return;
                }

                let visitas = resolverExibidos(visitasForm);

                if(!visitas){
                  this.props.enviarAlertaErro(mensagemValidacaoVisitas);
                  this.setState({exibirValidacaoVisita: true});
                  return;
                }

                visitas = visitas.filter((v) => v != null);

                if(visitas.length < 1){
                  this.props.enviarAlertaErro(mensagemValidacaoVisitas);
                  this.setState({exibirValidacaoVisita: true});
                  return;
                }

                submit();
                scrollAoErro(document.querySelector('.subform-nome-estacao'));
              }}
              titulo="Salvar"
            />
          )}
          <PrimaryButton
            className="float-right m-1 mr-2 subform-btn-voltar subform-btn-voltar-estacao"
            onClick={() => this.voltar(form)}
            titulo="Voltar"
          />
        </Col>
      </Row>
    );



    return (
      <>
        <NotificadorErrosForm />
        {/* Utilizar forceRender impacta a performance e
        pode causar condições de corrida em valores do final-form */}
        {this.state.exibirBreadCrumb && <InfoPanel breadcrumbVisita={this.breadcrumbVisita} location={this.props.location} form={form} nomeEstacao={form.getState().values.nome} valoresIniciais={this.valoresIniciais} visitas={this.valoresIniciais.visitas} />
        }
        <Tabs onSelect={(i, last, event) => this.aoSelecionarAba(i, last, event, form)} selectedIndex={this.state.tabSelected}>
          <TabList>
            <Tab>Informações da estação</Tab>
            <Tab>Atividades</Tab>
          </TabList>
          <TabPanel>
            <InformacoesGerais
              metodosGeoposicionamento={this.state.metodosGeoposicionamento}
              eEdicao={
                this.props.location &&
                this.props.location.state &&
                this.props.location.state.idEstacaoSelecionada !== null &&
                this.props.location.state.idEstacaoSelecionada > 0
              }
              permitirEdicao={this.permitirEdicao}
            />
          <Card className="mt-4 w-100">
            <Row>
              <InformacoesCoordenadas
                // refs
                eEdicao={
                  this.props.location &&
                  this.props.location.state &&
                  this.props.location.state.idEstacaoSelecionada !== null &&
                  this.props.location.state.idEstacaoSelecionada > 0
                }
                exibirMotivo={false}
                motivoTextArea={this.state.motivoTextArea}
                // objetos
                configMapa={configMapaEstacao}
                mapaEstacao={true}
                estacao={true}
                datums={this.state.datums}
                permitirEdicao={!this.idEstacao} // Não permite alterar coordenadas em uma estação existente
                metodosGeoposicionamento={this.state.metodosGeoposicionamento}
                categoriasLimiteArea={this.state.categoriasLimiteArea}
                habilitarMetodosGeoposicionamento={true}
                geometryFilter={this.props.location.state.geometryFilter}
                idProjeto={this.props.location.state.idProjeto}
                altitude={true}
                habilitarAltitude={true}
                metodoGeoposicionamentoPreenchido={true}
                coordenadasAtuais={valoresIniciaisCoordenadas}
                form={form}
                exibirLegendaEstacao={true}
                exibirLegendaEstacaoHistorica={false}
                exibirLegendaEstacaoEditada={true}
                exibirLegendaAtividade={false}
                exibirLegendaPontoGPS={false}
                exibirLegendaGeometriaProjeto={true}
              />
            </Row>
          </Card>

            <Card className="mt-4">
              <Card.Body>
                <Ilustracoes2
                  aoAbrirSubForm={() => this.subformAberto = true}
                  aoFecharSubForm={() => this.subformAberto = false}
                  tituloForm={"Conteúdo Multimídia da estação"}
                  nome={"ilustracoes"}
                  // onSubmit={aoEnviarSubForm}
                  elementos={form.getState().values.ilustracoes}
                  // valoresIniciais={this.valoresIniciais}
                  // permitirEdicao={this.permitirEdicao}
                  tipoAtividadeIlustracao={"estacao"}
                  nomeIlustracaoEstacao={this.state.nomeEstacao}
                  tiposIlustracao={this.state.tiposIlustracao}
                  extencoesIlustracao={this.state.extencoesIlustracao}
                  legendaObrigatoria={true}
                  permitirVisualizacao={true}
                />
              </Card.Body>
            </Card>

            <VisitasEstacao
              exibirMensagemDeErro={this.state.exibirValidacaoVisita}
              permitirVisualizacao={true}
              permitirEdicao={this.permitirEdicao}
              instituicoes={this.state.instituicoes}
              coletores={this.state.coletores}
              // Dados selecionadas na tela inicial de Estações. Devem ser utilizados apenas em novas visitas
              // pois visitas existentes já possuem sua própria area/subarea
              nomeProjeto={this.state.nomeProjeto}
              nomeArea={this.state.nomeArea}
              nomeSubArea={this.state.nomeSubArea}
              idArea={this.state.idArea}
              idSubArea={this.state.idSubArea}
              idEstacao={this.idEstacao}
              aoAbrirSubForm={() => this.subformAberto = true}
              aoFecharSubForm={() => { this.subformAberto = false; this.validarVisitasAoFecharForm(form) }}
            // visitasIniciais={this.state.visitasIniciais}
            // aoAlterarVisitas={this.aoAlterarVisitas}
            />
            <BotoesVoltarSalvar />
          </TabPanel>
          <TabPanel>
            <Field name="visitas" subscription={{ value: true }}>
              {({ input: { value: visitas = {} } }) => {
                return (
                  <RegistrarObjetoFunc
                    // alterarAtividadeSelecionadaBreadcrumb={this.alterarAtividadeSelecionadaBreadcrumb.bind(this)}
                    alterarAtividadeAberta={this.alterarAtividadeAberta.bind(this)}
                    permitirEdicao={this.permitirEdicao}
                    valoresIniciais={valoresIniciaisAtividades}
                    visitas={visitas}
                    idProjeto={this.idProjeto}
                    idEstacao={this.idEstacao}
                    nomeProjeto={this.state.nomeProjeto}
                    // Bibliotecas
                    datums={this.state.datums}
                    metodosGeoposicionamento={
                      this.state.metodosGeoposicionamento
                    }
                    categoriasLimiteArea={this.state.categoriasLimiteArea}
                    minerais={this.state.minerais}
                    prefixos={this.state.prefixos}
                    complementos={this.state.complementos}
                    cores={this.state.cores}
                    tiposIlustracao={this.state.tiposIlustracao}
                    extencoesIlustracao={this.state.extencoesIlustracao}
                    classesAmostra={this.state.classesAmostra}
                    coletores={this.state.coletores}
                    mineraisPorId={this.state.mineraisPorId}
                    prefixosPorId={this.state.prefixosPorId}
                    complementosPorId={this.state.complementosPorId}
                    // JSX
                    BotoesVoltarSalvar={BotoesVoltarSalvar}
                    area={form.getState().values.idArea}
                    subArea={form.getState().values.idSubArea}
                    history={this.props.history}
                  />
                );
              }}
            </Field>
          </TabPanel>
        </Tabs>
        <JqxNotification
          ref={this.state.mensagem}
          width={350}
          position={"top-right"}
          opacity={0.9}
          autoOpen={false}
          autoClose={true}
          animationOpenDelay={800}
          autoCloseDelay={8000}
          template={"error"}
        >
          <div id="mensagemErro" style={{ zIndex: 9999 }} />
        </JqxNotification>
      </>
    );
  };

  // TAG(render): utilidade pra dar Ctrl+F e pular umas 2000 linhas
  render() {
    return (
      <BlockUi blocking={this.state.carregamentoGlobal}>
        <PageScaffold titulo="Detalhes da Estação">
          <Container className="subform subform-nome-estacao subform-aberto">
            <FForm
              onSubmit={this.enviarFormulario}
              initialValues={this.valoresIniciais}
              decorators={this.decoradores}
              subscription={{}}
              mutators={{ setValue }}
              render={this.renderForm}
            />
          </Container>
        </PageScaffold>
      </BlockUi>
    );
  }
}

export default connect(null, mapDispatchToProps)(Estacao);
