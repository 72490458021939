import React, { useState, useEffect } from "react";
import { FieldArray } from "react-final-form-arrays";
import FileGrid from "../../file/Grid/FileGrid";
import { Field, useForm } from "react-final-form";
import PropTypes from "prop-types";
import { connect, useDispatch } from "react-redux";

import Lightbox from "react-awesome-lightbox";
// You need to import the CSS only once
import "react-awesome-lightbox/build/style.css";
import { mapearTipo } from "../../../util/Arquivo";
import Erro from "../../Erro";
import { limparArquivos } from "../../../reducers/fileReducers";

function FileGridField({
  campoNovosArquivos,
  campoArquivosExistentes,
  campoArquivosExcluidos,
  chaveListaArquivos = "arquivos_" + campoNovosArquivos,
  disable,
  todosArquivosCarregados,
  limiteArquivos,
  changedFormGPS,
  clickVisualizar,
  excluidos,
  limparListaExcluidos,
  nomeExibicaoArquivoGetter = () => () => null,
  ...rest
}) {

  const dispatch = useDispatch();
  const form = useForm();
  const subscription = { initial: true, value: true, valid: true, error: true };

  const [lightbox, setLightbox] = useState(null); // Controla a Lightbox (visualização de imagens)

 // useEffect(() => {
 //   if(limparListaExcluidos)
 //     form.mutators.setValue(campoArquivosExcluidos, []);
//
  //}, [limparListaExcluidos]);

  useEffect(() => {
    //Função acionada ao remover o componente.
    return () => {
      if (chaveListaArquivos === "arquivosGPS") {
        dispatch(limparArquivos({ store: chaveListaArquivos }))
      }

      if (chaveListaArquivos.indexOf('arquivosIlustracoes') === -1) {
        dispatch(limparArquivos({ store: chaveListaArquivos }))
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Field name={campoArquivosExcluidos} subscription={{ value: true }}>
        {({ input: { value: arquivosExcluidos = [] } }) => {

          if(excluidos)
            excluidos(arquivosExcluidos);

          return (
            <Field
              name={campoArquivosExistentes}
              subscription={{ value: true }}
            >
              {({ input: { value: arquivosExistentes = [] }, meta }) => {
                let arquivos =
                  arquivosExistentes === "" ? [] : arquivosExistentes;
                return (
                  <FieldArray
                    name={campoNovosArquivos}
                    subscription={subscription}
                    key={todosArquivosCarregados}
                    validate={() => {
                      return todosArquivosCarregados
                        ? undefined
                        : "Ainda há arquivos sendo carregados. Aguarde.";
                    }}
                  >
                    {({ fields }) => {
                      return (
                        <>
                          {fields.map((name, i) => {
                            console.log("name", name, i);
                            return (
                              <Field
                                key={i}
                                component="input"
                                type="hidden"
                                name={name}
                                subscription={subscription}
                              />
                            );
                          })}
                          <FileGrid
                            nome={chaveListaArquivos}
                            idsArquivosExistentes={arquivos}
                            permitirUpload={!clickVisualizar ?? !disable}
                            permitirExclusao={!clickVisualizar ?? !disable}
                            limiteArquivos={limiteArquivos}
                            onAdicionar={(hash) => {
                              fields.push(hash);
                            }}
                            nomeExibicaoArquivoGetter={nomeExibicaoArquivoGetter}
                            onExcluir={(hash) => {
                              let idx = fields.value.indexOf(hash);
                              console.log('hash', hash)
                              if (idx >= 0) {
                                fields.remove(idx);
                              }
                              // Atualizar campo de hash excluídosar
                              if (chaveListaArquivos !== "arquivosGPS") {
                                form.mutators.setValue(campoArquivosExcluidos, [...(arquivosExcluidos || []), hash]);
                              } else {
                                changedFormGPS()
                              }
                            }}
                            onClickImagem={(i, arquivos) => {
                              // TODO: fix FileGridField não re-renderizando algumas vezes após atualizar lightbox
                              const images = arquivos
                                .filter(
                                  (arq) => mapearTipo(arq[1].tipo) === "imagem"
                                ) // Exibir apenas imagens
                                .map((arq, i) => ({
                                  url: `${process.env.REACT_APP_API_URL}${arq[1].caminho}`,
                                  title: nomeExibicaoArquivoGetter?.(arq[1], i + 1)?.(chaveListaArquivos, arq[0]),
                                }));
                              setLightbox({
                                startIndex: images.findIndex(
                                  (img) => img.title === nomeExibicaoArquivoGetter?.(arquivos[i][1], i + 1)?.(chaveListaArquivos, arquivos[i][0])
                                ),
                                images: images,
                              });
                            }}
                            renderAfter={() => {
                              const mensagemErro =
                                meta.touched && !meta.valid && meta.error;
                              return mensagemErro ? (
                                <Erro mensagem={mensagemErro} />
                              ) : null;
                            }}
                            {...rest}
                          />
                          <Field
                            key={arquivos}
                            name="idsExcluidos"
                            component="input"
                            type="hidden"
                          />
                        </>
                      );
                    }}
                  </FieldArray>
                );
              }}
            </Field>
          );
        }}
      </Field>
      {lightbox ? (
        <Lightbox
          images={lightbox.images}
          startIndex={lightbox.startIndex}
          onClose={() => {
            setLightbox(null);
          }}
        />
      ) : null}
    </>
  );
}

FileGridField.propTypes = {
  disable: PropTypes.bool,
  campoNovosArquivos: PropTypes.string.isRequired,
  campoArquivosExistentes: PropTypes.string.isRequired,
  campoArquivosExcluidos: PropTypes.string,
  chaveListaArquivos: PropTypes.string.isRequired,
  limiteArquivos: PropTypes.number,

  nomeExibicaoArquivoGetter: PropTypes.func,

  // Redux
  todosArquivosCarregados: PropTypes.bool,
};

FileGridField.defaultProps = {
  disable: false,
  limiteArquivos: Number.MAX_SAFE_INTEGER,
  campoArquivosExcluidos: "hashesArquivosExcluidos",
};

export default connect((state, { chaveListaArquivos }) => ({
  todosArquivosCarregados: Object.values(
    state.files[chaveListaArquivos] || {}
  ).every((arq) => "undefined" !== typeof arq.carregando && !arq.carregando),
}))(FileGridField);
