import React, { PureComponent } from "react";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { AmostraSubFormPart } from "../components/AmostraSubFormPart";
import { MatrizPredominanteField } from "../components/MatrizPredominanteField";
import { GrauIntemperismoField } from "../components/GrauIntemperismoField";
import { TipoMineralField } from "../components/TipoMineralField";
import { ModoDepositoField } from "../components/ModoDepositoField";
import { TipoAlteracaoField } from "../components/TipoAlteracaoField";
import {
  amostraDataFieldsCommon,
  AmostraSymbol,
  amostrasLabelsMap,
} from "../constants/amostras.constant";
import { ImagensAmostraSubForm } from "../components/ImagensAmostraSubForm";
import { MinerioSampleGeoquimicaModelLike } from "../../../../../models/activity-geoquimica.model";
import { AmostraGridSubForm } from "../components/AmostraGridSubForm";

export interface MinerioSectionFormProps {
  prefixoNome: string;
  items?: MinerioSampleGeoquimicaModelLike[];
  onItemsChanged?: (items: MinerioSampleGeoquimicaModelLike[]) => void;
}

export interface MinerioSectionFormState {}

export class MinerioSectionForm extends PureComponent<
  MinerioSectionFormProps,
  MinerioSectionFormState
> {
  datafieldsMinerio = [
    ...amostraDataFieldsCommon,
    {
      name: "id_matriz_predominante",
      type: "number",
      map: "id_matriz_predominante",
    },
    {
      name: "id_grau_intemperismo",
      type: "number",
      map: "id_grau_intemperismo",
    },
    {
      name: "id_tipo_alteracao",
      type: "number",
      map: "ambienteCrosta",
    },
    {
      name: "id_tipo_mineral",
      type: "number",
      map: "id_tipo_mineral",
    },
    {
      name: "id_modo_deposito",
      type: "number",
      map: "id_modo_deposito",
    },
  ];

  amostraSymbol = AmostraSymbol.Minerio;

  constructor(props) {
    super(props);
    this.state = {};
  }

  renderForm = ({ editando, prefixoNome }) => {
    return (
      <>
        <AmostraSubFormPart
          prefixoNome={prefixoNome}
          amostraSymbol={this.amostraSymbol}
          profundidadeMinRequired={false}
          profundidadeMaxRequired={false}
        />
        <Row className="my-3">
          <Col>
            <MatrizPredominanteField
              prefixoNome={prefixoNome}
              required={true}
            />
          </Col>
          <Col>
            <GrauIntemperismoField prefixoNome={prefixoNome} required={true} />
          </Col>
          <Col>
            <TipoAlteracaoField prefixoNome={prefixoNome} required={true} />
          </Col>
        </Row>
        <Row>
          <Col>
            <TipoMineralField prefixoNome={prefixoNome} required={true} />
          </Col>
          <Col>
            <ModoDepositoField prefixoNome={prefixoNome} required={true} />
          </Col>
        </Row>
        <Row className="my-3">
          <Col>
            <ImagensAmostraSubForm prefixoNome={prefixoNome} />
          </Col>
        </Row>
      </>
    );
  };

  render() {
    return (
      <>
        <Card className="my-3">
          <Card.Body>
            <Card.Subtitle>
              {amostrasLabelsMap.get(this.amostraSymbol)}
            </Card.Subtitle>
            <AmostraGridSubForm
              name="amostraMinerios"
              datafields={this.datafieldsMinerio}
              items={this.props.items}
              onItemsChanged={this.props.onItemsChanged}
              renderForm={this.renderForm}
            />
          </Card.Body>
        </Card>
      </>
    );
  }
}
