import React from "react";
import JqxGrid, { IGridColumn, IGridSource, IGridSourceDataFields, jqx } from "jqwidgets-scripts/jqwidgets-react-tsx/jqxgrid";
// import { ACCESS_TOKEN } from '../../constants';
import Localizacao from "../../../util/Localizacao";
import _ from "lodash";

// https://www.jqwidgets.com/react-components-documentation/documentation/jqxgrid/reactjs-grid-api.htm?search=
// https://www.jqwidgets.com/community/topic/always-show-filter-icon/

export interface TableProps {
  /** Optional name for this table, for debug logging. */
  name?: string,
  /**
   * Data that's used as source for the JqxGrid. If empty, the table will be
   * initialized empty. Note that if you're using a reference to this class to
   * add items to the table, make sure that you don't continue to pass a different
   * array as the items prop, because that may cause the table to refresh later
   * and become empty again. If you use this class' methods to change items in
   * the table, you should omit this prop.
   * 
   * To use an url instead, specify all that in the extraSourceParameters.
   */
  items?: any[],
  /**
   * Datafields (names of fields) for the JqxGrid. Avoid changing this array
   * if possible. It's preferrable to instantiate an array of datafields only
   * once.
   */
  datafields?: IGridSourceDataFields[],
  /**
   * Extra parameters to pass to the table's source. This is only used once when
   * the table first renders.
   * 
   * This can be used to specify an alternate source of data for the table, for
   * example a json file. If that's the case, then items and datafields can
   * be any value and will be ignored.
   */
  extraSourceParameters?: any,
  /**
   * Columns that are passed to the JqxTable.
   * 
   * This array should never change through this component's lifecycle. It is
   * recommended to use a constant array that does not get redeclared on every
   * render.
   * 
   * Avoid defining "cellsrender" callbacks that need to capture variables; instead,
   * it's better to include all necessary data in the rows themselves, so that
   * this array never needs to change.
   * 
   * The widths of these columns will be corrected whenever possible to add up to
   * 100%:
   * - If one or more columns don't have a width, they are given an equal share
   * of the remaining available width.
   * - If all columns have widths but they add up to less than a 100%, the last
   * column is given more width to fill up the table.
   * - If all columns have widths but they add up to more than a 100%, the last
   * columns is shrunk, but only if it has enough width. If the last column does
   * not have enough width (that is, it renders offscreen), then no corrections
   * are done.
   * - All calculations ignore hidden columns.
   * - All of these corrections are only applied when all widths are given in
   * percentage; if one or more columns define an absolute width, no corrections
   * are applied.
   */
  columns: IGridColumn[];
  autoshowloadelement?: boolean;
  altrows?: boolean;
  pageable?: boolean;
  filterable?: boolean;
  sortable?: boolean;
  autoheight?: boolean;
  columnsresize?: boolean;
  enabletooltips?: boolean;
  selectionmode?: "none" | "singlerow" | "checkbox";
  editable?: boolean;
  editmode?:  "click" | "selectedcell" | "selectedrow" | "dblclick" | "programmatic";
  oncellendedit?: (e: any) => void;

  /**
   * Hides this component. It is recommended to use this prop instead of simply
   * not rendering this component, otherwise the ref to this Table may not
   * initialize correctly.
   */
  hide?: boolean;

  /**
   * Optional ref that will receive a reference to the JqxGrid.
   */
  jqxRef?: React.RefObject<JqxGrid>;

  onRowSelectChanged?: (e?: Event) => void;
  onPagechanged?: (e?: Event) => void;
}


/**
 * Class that encapsulates a JqxTable.
 * 
 * It is important to note that JqxTables expect to receive props only once, and
 * then all data manipulation should be done through a ref. All operations that
 * add, update and delete rows should de done using a ref to this component, not
 * by changing the source that's passed as props. That's because changing the
 * props that reach JqxTable cause a databind to occur, which is bad for
 * performance.
 * 
 * For that reason, the source should be instantiated only once (with an empty
 * array of local data if needed), and then all operations on data should use
 * this component's methods, including changing column visibility.
 */
export class Table extends React.Component<TableProps> {
  constructor(props: TableProps) {
    super(props);
    this.setElementsTabIndex();
    this.tableRef = React.createRef<JqxGrid>();

    let extraSourceParameters = props.extraSourceParameters;

    // There's a bug in jqxgrid that causes a crash when sortcolumn is specified
    // without items in the table. It also happens when we call sortby() without
    // items in the table. I don't know the circumstances that cause this bug to
    // happen, but it doesn't happen in a clean project.
    if (extraSourceParameters?.sortcolumn && (!props.items || props.items.length === 0)) {
      extraSourceParameters = {
        ...extraSourceParameters,
        sortcolumn: null,
        sortdirection: null,
      }
      const originalSortcolumn = props.extraSourceParameters!.sortcolumn;
      const originalSortdirection = props.extraSourceParameters!.sortdirection ?? "desc";
      // Apply these later.
      this._applyInitialSort(originalSortcolumn, originalSortdirection, this);
    }

    this.tableData = {
      datafields: Array.isArray(props.datafields) ? props.datafields : [],
      datatype: "array",
      width: "100%",
      localdata: props.items,
      autoHeight: true,
      ...(extraSourceParameters ?? {}),
    };

    this.dataAdapter = new jqx.dataAdapter(this.tableData);
  }

  // Set to true to output logs from this class.
  DEBUG: boolean = false;

  /**
   * This whole method is a workaround. For some reason that I can't explain,
   * setting a sort column on an empty table causes an exception in the jqxgrid's
   * internal code. This does not happen in a clean project, but happens here.
   * 
   * The workaround is waiting until there's an item in the table to apply the
   * sort.
   */
  _applyInitialSort(column: string, direction: string, table: Table, count?: number): void {
    const _count = count ?? 0;
    if (_count > 40) {
      // give up
      return;
      // (this may happen normally if the table never receives any items)
    }
    if (!table.tableRef.current || table.tableRef.current!.getrows().length === 0) {
      // Main condition for trying again later.
      setTimeout(() => {
        this._applyInitialSort(column, direction, table, _count + 1);
      }, 200);
    } else {
      try {
        table.tableRef.current?.sortby(column, direction);
      } catch (e) {
        // If the sortby fails, also try again later.
        setTimeout(() => {
          this._applyInitialSort(column, direction, table, _count + 1);
        }, 200);
      }
    }
  }

  /**
   * Reference to the JqxGrid, for calling methods on it.
   * 
   * It is possible to access this ref from other classes, but that's not
   * recommended. Use this class' methods if possible.
   * */
  tableRef: React.RefObject<JqxGrid>;

  /** Data that goes into JqxGrid's data adapter. */
  tableData: any;

  /** Data adapter (confusingly, of type "IGridSource"), that should never change. */
  dataAdapter: IGridSource;

  getselectedrowindexes(): number[] {
    return this.tableRef.current?.getselectedrowindexes() ?? [];
  }

  selectrow(rowBoundIndex: number): void {
    this.tableRef.current?.selectrow(rowBoundIndex);
  }

  unselectrow(rowBoundIndex: number): void {
    this.tableRef.current?.unselectrow(rowBoundIndex);
  }

  getrowdata(rowBoundIndex: number): any {
    return this.tableRef.current?.getrowdata(rowBoundIndex);
  }

  /**
   * The "id" parameter is probably used for updating and deleting rows later.
   */
  addRow(data: any, id?: any, rowPosition?: any): void {
    this.tableRef.current?.addrow(id, data, rowPosition);
  }

  /**
   * Delete one or more rows. The rowIds parameter can be a single id, or an
   * array of ids.
   */
  deleterow(rowIds: string | number | Array<number | string>): void {
    this.tableRef.current?.deleterow(rowIds);
  }

  /**
   * Update one or more rows. The rowIds parameter can be a single id, or an
   * array of ids. The data is an array of items that will replace existing data.
   */
  updaterow(rowIds: string | number | Array<number | string>, data: any): void {
    // The JqxGrid has methods called beginupdate() and endupdate(), but they
    // don't seem to be necessary and and make operations slower.
    this.tableRef.current?.updaterow(rowIds, data);
  }

  /**
   * Replaces all the data in the table.
   * 
   * The second "ids" parameter is optional, and if null, the new data will be
   * inserted without ids. Ids are useful for updating and deleting specific
   * rows later.
   */
  resetrows(rows: any[], /* ids?: any[] */): void {
    // We can also clear the table using JqxGrid's clear() method, but for some
    // reason that's much slower than rebinding data to another array.
    this.tableData.localdata = rows;
    // Using "cells" is apparently an optimization that's more noticeable when
    // the amount of rows remains the same.
    this.tableRef.current?.updatebounddata("cells");
  }

  /**
   * Use this method to show a column that's already described in the data fields.
   * That is, do not change the collection of columns because that is slow to
   * render. All columns that need to be shown/hidden should already be in the
   * data fields, and hidden as needed.
   */
  showcolumn(datafieldname: string): void {
    this.tableRef.current?.showcolumn(datafieldname);
  }

  /**
   * Use this method to hide a column that's already described in the data fields.
   * That is, do not change the collection of columns because that is slow to
   * render. All columns that need to be shown/hidden should already be in the
   * data fields, and hidden as needed.
   */
  hidecolumn(datafieldname: string): void {
    this.tableRef.current?.hidecolumn(datafieldname);
  }
  
  addTabIndex(elementArray: any[]) {
    if (elementArray) {
      elementArray.forEach((element, index) => {
        element.setAttribute("tabindex", "0");
        return element;
      });
    }
  }

  setElementsTabIndex() {
    const _id = (this.tableRef?.current as any)?._componentSelector || null;
    const grid = document.querySelector(_id) || null;

    if (grid) {
      this.addTabIndex(grid.querySelectorAll(".jqx-button"));
      this.addTabIndex(grid.querySelectorAll(".jqx-input"));
      this.addTabIndex(grid.querySelectorAll(".jqx-dropdownlist"));
    }
  }

  // I don't actually know if this makes any difference. This method exists because
  // the previous implementation of Table (the function component) used something
  // like this too.
  onRowSelectChangedInternal(e: any) {
    this.props.onRowSelectChanged?.(e);
  }

  // componentDidUpdate(prevProps, prevState) {
  //   console.log(`componentDidUpdate: ${prevProps.name}`);
  //   Object.entries(this.props).forEach(([key, val]) =>
  //     prevProps[key] !== val && console.log(`Prop '${key}' changed`)
  //   );
  //   if (this.state) {
  //     Object.entries(this.state).forEach(([key, val]) =>
  //       prevState[key] !== val && console.log(`State '${key}' changed`)
  //     );
  //   }
  // }

  /**
   * Update items, but only if needed.
   */
  _updateItemsFromProps(props: TableProps) {
    this.DEBUG && console.log(`Table ${props.name} updating items, ref is:`)
    this.DEBUG && console.log(this.tableRef);
    // "datafields" is optional but it should be used rather than including
    // datafields in 
    const datafieldsChanged = props.datafields && !areArraysEqual(
      props.datafields,
      (this.dataAdapter as any)._source.datafields
    );
    // "items" is optional, so don't refresh from props if the items prop is
    // not informed at all. In that case, the table is expected to be manipulated
    // using refs.
    var itemsChanged = props.items && !areArraysEqual(
      props.items,
      (this.dataAdapter as any)._source.localdata
    );
    if (datafieldsChanged) {
      // Update the source with the new datafields.
      this.tableData.datafields = props.datafields;
    }
    // Skip this when localdata is specified in extraSourceParameters.
    // Currently, localdata is not supported. It may work, but won't update
    // automatically.
    if (
      itemsChanged /** && !(nextProps.extraSourceParameters as any)?.localdata **/
    ) {
      // Update the source with the new items.
      this.tableData.localdata = props.items;
    }
    // if (nextProps.extraSourceParameters !== this.props.extraSourceParameters) {
    //   // Currently, we're only able to handle changes in local data, and nothing
    //   // else. Any other fields of extraSourceParameters will be ignored when they
    //   // change. When this is specified, we expect items to be omitted.
    //   const prevData = (this.props.extraSourceParameters as any)?.localdata;
    //   const nextData = (nextProps.extraSourceParameters as any)?.localdata;
    //   if (Array.isArray(prevData) && Array.isArray(nextData) && !areArraysEqual(prevData, nextData)) {
    //     this.tableData.localdata = nextData;
    //     itemsChanged = true;
    //   }
    // }
    // We could also update the source when extraSourceParameters changes,
    // but for now let's ignore that to avoid additional operations on the
    // table.
    if (datafieldsChanged || itemsChanged) {
      if (!this.tableRef.current) {
        this.DEBUG &&
          console.log(
            `Table of ${props.name ?? "unknown"} does not have a ref!`
          );
      }
      if (datafieldsChanged) {
        this.DEBUG &&
          console.log(
            `Rerendering table ${props.name} because datafields changed.`
          );
      }
      if (itemsChanged) {
        this.DEBUG &&
          console.log(`Rerendering table ${props.name} because items changed.`);
      }
      this.tableRef.current?.updatebounddata("cells");
    }
  }

  /**
   * Rerender the table cells without changing the table's contents.
   */
  refreshItems() {
    this.tableRef.current?.updatebounddata("cells");
  }

  // This component absolutely needs an implementation of shouldComponentUpdate
  // because JqxTable's implementation of componentDidUpdate is unreasonably
  // slow and we want to avoid it as much as possible.
  shouldComponentUpdate(nextProps: TableProps) {
    // First of all, detect changes in the table's data to refresh if necessary.
    this._updateItemsFromProps(nextProps);

    // Update if any fields in props change.
    if (nextProps.altrows !== this.props.altrows
      || nextProps.autoheight !== this.props.autoheight
      || nextProps.autoshowloadelement !== this.props.autoshowloadelement
      || nextProps.columnsresize !== this.props.columnsresize
      || nextProps.enabletooltips !== this.props.enabletooltips
      || nextProps.filterable !== this.props.filterable
      || nextProps.pageable !== this.props.pageable
      || nextProps.selectionmode !== this.props.selectionmode
      || nextProps.sortable !== this.props.sortable) {
        this.DEBUG && console.log("Table rendering due to field change");
        this.DEBUG && console.log("From:");
        this.DEBUG && console.log({
          altrows: this.props.altrows,
          autoheight: this.props.autoheight,
          autoshowloadelement: this.props.autoshowloadelement,
          columnsresize: this.props.columnsresize,
          enabletooltips: this.props.enabletooltips,
          filterable: this.props.filterable,
          pageable: this.props.pageable,
          selectionmode: this.props.selectionmode,
          sortable: this.props.sortable,
        });
        this.DEBUG && console.log("To:");
        this.DEBUG && console.log({
          altrows: nextProps.altrows,
          autoheight: nextProps.autoheight,
          autoshowloadelement: nextProps.autoshowloadelement,
          columnsresize: nextProps.columnsresize,
          enabletooltips: nextProps.enabletooltips,
          filterable: nextProps.filterable,
          pageable: nextProps.pageable,
          selectionmode: nextProps.selectionmode,
          sortable: nextProps.sortable,
        })
        return true;
      }
      // For columns, we could stringify the arrays, but that would miss changes
      // in captures variables since the elements contain functions in them.
      // Reusing columns is an optimization that needs to be done in the components
      // higher up that use this table.
      // if (JSON.stringify(this.props.columns)?.localeCompare(JSON.stringify(nextProps.columns)) !== 0) {
      if (!areArraysEqual(this.props.columns, nextProps.columns)) {
        this.DEBUG && console.log("Table rerendering because columns changed");
        return true;
      }
      // Would be nice if we could compare functions by value, but I think we
      // would need to expand the syntax tree to compare closures.
      if (nextProps.onRowSelectChanged !== this.props.onRowSelectChanged) {
        this.DEBUG && console.log("Table rerendering due to onRowSelectChanged callback changing.")
        return true;
      }

      if (nextProps.hide !== this.props.hide) {
        this.DEBUG && console.log(`Table ${nextProps.name} rerendering due to "hide" property change.`)
        return true;
      }

      this.DEBUG && console.log(`Table rerendering avoided. (${nextProps.name})`)
      return false;
  }

  render() {
    // Hide this table using a display: none.
    this.DEBUG && console.log(`Rendering table ${this.props.name}`)
    return (
      <>
        <div style={{ width:"100%" }}>
          <JqxGrid
            source={this.dataAdapter}
            width={"100%"}
            editmode={this.props.editmode||"click"}
            editable={this.props.editable||false}
            style={this.props.hide ? {display: "none"} : undefined}
            selectionmode={this.props.selectionmode ?? "singlerow"}
            localization={Localizacao}
            ref={(ref) => {
              this.DEBUG && console.log(`${this.props.name} setting tableRef as `);
              this.DEBUG && console.log(ref);
              this.DEBUG && console.log(`at ${performance.now()}`);
              (this.tableRef as React.MutableRefObject<JqxGrid>).current = ref as any
              if (this.props.jqxRef) {
                this.DEBUG && console.log(`${this.props.name} setting jqx ref as `);
                this.DEBUG && console.log(ref);
                this.DEBUG && console.log(`at ${performance.now()}`);
                (this.props.jqxRef as React.MutableRefObject<JqxGrid>).current = ref as any
              }
            }}
            autoshowloadelement={this.props.autoshowloadelement ?? false}
            onRowselect={this.onRowSelectChangedInternal.bind(this)}
            onRowunselect={this.onRowSelectChangedInternal.bind(this)}
            {...this.props}
            columns={fixColumnWidths(this.props.columns)}
            cellhover= {()=>{var list = document.getElementsByClassName("jqx-grid-cell");
            for (let item of list) {
                item.removeAttribute('title');
            }}}
          />
        </div>
      </>
    );
  }

  static defaultProps = {
    altrows: true,
    pageable: true,
    filterable: true,
    sortable: true,
    autoheight: true,
    columnsresize: true,
    enabletooltips: true,
  };
}

// "Table" is both a default and a named export.
export default Table;


/**
 * Compares two arrays, comparing each item and their lengths. Two items that
 * are undefined will compared equal, while if only one is undefined, it'll compare
 * non-equal to the other.
 */
function areArraysEqual(l: any[] | undefined, r: any[] | undefined): boolean {
  if (l === undefined && r === undefined) {
    return true;
  }
  if (l === undefined || r === undefined) {
    return false;
  }

  if (l.length !== r.length) {
    return false;
  }
  for (var i = 0; i < l.length; i++) {
    if (l[i] !== r[i]) {
      return false;
    }
  }
  return true;
}

/**
 * Ensure that the widths of all columns add up to 100%. This will return a
 * new array without modifying the input, but some elements may be the same.
 */
function fixColumnWidths(columns: IGridColumn[]): IGridColumn[] {
  // These corrections can only be applied if all the widths are specified as
  // percentage. If one or more coluns specify an absolute width, then we can't
  // apply any corrections.
  const input = columns.filter((col) => !col.hidden);

  let flagAllPercents = true;
  const widthsPercent = input.map((col) => {
    const unparsed = col.width;
    if (typeof unparsed === "number") {
      flagAllPercents = false;
    }
    if (typeof unparsed !== "string") {
      return null;
    };
    if (!unparsed.endsWith("%")) {
      flagAllPercents = false;
    }
    const unparsedWithoutPercent = unparsed.replace("%", "");
    try {
      const parsed = parseFloat(unparsedWithoutPercent);
      if (isFinite(parsed) && parsed > 0) {
        return parsed
      }
    } catch (_) {
      return null;
    }
    return null;
  });
  if (!flagAllPercents) {
    return input;
  }

  if (
    widthsPercent.filter((value) => value === null).length > 0
  ) {
    // If there's more than one column without a width (given that all the
    // columns that do have widths are in percent), we give all those columns
    // without widths an equal share of the remaining space.
    let remainder = 100;
    let countColumnsWithoutWidth = 0;
    for (let i = 0; i < widthsPercent.length; i++) {
      if (widthsPercent[i] === null) {
        countColumnsWithoutWidth++; // <- Will be 1 or more after this loop.
      } else {
        remainder -= (widthsPercent as number[])[i];
      }
    }
    // Now give width to the colums that don't have width.
    if (remainder > 0) {
      for (let i = 0; i < widthsPercent.length; i++) {
        if (widthsPercent[i] === null) {
          input[i] = {
            ...input[i],
            width: `${remainder / countColumnsWithoutWidth}%`,
          }
        }
      }
    }
  } else if (
    widthsPercent.every((value) => value !== null)
    && (widthsPercent as number[]).reduce((a,b) => a + b) < 99.9999
  ) {
    // If the widths are all defined but add up to less than a hundred, give
    // more width to the last column.
    let remainder = 100;
    for (let i = 0; i < widthsPercent.length; i++) {
      remainder -= (widthsPercent as number[])[i];
    }
    const newRightmostColumnWidth =
      widthsPercent[widthsPercent.length - 1]! + remainder;
    input[widthsPercent.length - 1] = {
      ...input[widthsPercent.length - 1],
      width: `${newRightmostColumnWidth}%`,
    };
  } else if (
    widthsPercent.every((value) => value !== null)
    && (widthsPercent as number[]).reduce((a,b) => a + b) > 100.0001
  ) {
    // If the widths are all defined but add up to more than a hundred, take
    // width away from the last column. This will only be done if the last
    // column has enough width to spare.
    const excess = (widthsPercent as number[]).reduce((a,b) => a + b) - 100;
    if (widthsPercent[widthsPercent.length - 1]! >= excess) {
      // Take away width from the last column, but only if the last column
      // has enough width.
      input[widthsPercent.length - 1] = {
        ...input[widthsPercent.length - 1],
        width: `${widthsPercent[widthsPercent.length - 1]! - excess}%`,
      };
    }
  }

  return input;
}
