import React, { Component } from 'react'
import Container from 'react-bootstrap/Container'
import Card from '../../components/card'
import Localizacao from '../../util/Localizacao'
import { COLUNA_EXIBIR, COLUNA_EXCLUIR, COLUNA_BAIXAR } from '../../util/constantes'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import swal from "sweetalert"
import { Field, Form as FForm } from "react-final-form"
import arrayMutators from 'final-form-arrays'
import FileGridField from '../../components/field/FileGridField/FileGridField'
import httpCommons from '../../service/http-commons'
import { mostrarNotificacao } from "../../reducers/notificacaoReducer";
import { connect } from 'react-redux'
import PageScaffold from '../../components/scaffold/PageScaffold'
import Service from '../../service/Service'
import { DropdownListField } from '../../components/field/DropdownListField'
import { campoObrigatorioComMsgGenerica } from '../../util/validadores'
import { FocusableButton } from "../../components/Jqx/Button";
import { createControlColumn, Table } from '../../components/Jqx/Table'
import BlockUi from 'react-block-ui'
import { adicionarCabecalhoAuthAoJqXHR } from '../../util/jqxUtils'
import { swalErro } from "../../util/validadores";
import InfoPanelGPS from '../../components/infoPanelGPS'
import { FORM_ERROR } from "final-form";

class ArquivosGPS extends Component {
  constructor(props) {
    super(props)

    this.state = {
      idSelecionado: null,
      editado: false,
      carregando: false,
      exibirMensagemErro:false,
    }



    this.jqxGrid = React.createRef()

    this.url = `${process.env.REACT_APP_API_URL}/gps/simples`

    this.columns = [
      createControlColumn({
        iconClasses: ["fas", "fa-edit", "text-warning"],
        dataField: COLUNA_EXIBIR,
        ref: this.jqxGrid,
        onClick: this.visualizar.bind(this),
      }),
      createControlColumn({
        iconClasses: ["fas", "fa-trash", "text-danger"],
        dataField: COLUNA_EXCLUIR,
        ref: this.jqxGrid,
        onClick: this.excluir.bind(this),
        botaoAtivo: this.podeExcluirEntidadePadrao
      }),
      createControlColumn({
        iconClasses: ["fas", "fa-download"],
        dataField: COLUNA_BAIXAR,
        ref: this.jqxGrid,
        onClick: this.baixar.bind(this),
      }),
      { text: 'Nome', datafield: 'nome', width: "51%" },
      { text: 'Data importação', datafield: 'dataCriacao', width: "20%", filtertype: 'date', cellsformat: 'dd/MM/yyyy' },
      {
        text: 'Status', datafield: 'status', width: "20%",

        cellsrenderer: function (row, columnfield, value, defaulthtml, columnproperties, rowdata) {
          let hint = "";
          switch (value) {
            case "Não Vinculado": {
              value = "Pendente";
              hint = "Pendente de vinculo com a atividade, mas associado a uma estação";
              break;
            }
            case "Pendente": {
              value = "Não Vinculado";
              hint = "Não está vinculado a uma estação";
              break;
            }
            case "Vinculado":
              {
                hint = "Vinculado a uma atividade da estação";
                break;
              }
            default: {
              hint = "";
            }
          }
          return `<div className="jqx-grid-cell-left-align" style="margin-top: 8.5px;" title="${hint}">${value}</div>`;
        }, filtertype: 'checkedlist', filteritems:
          [
            { label: "Vinculado", value: "Vinculado" },
            { label: "Não Vinculado", value: "Pendente" },
            { label: "Pendente", value: "Não Vinculado" },
          ],
      },
      // { text: 'idStatus', datafield: 'idStatus', hidden: true },
    ]

    /**
     * Use a json file as datasource instead of an array.
     */
    this.extraSourceParameters = {
      id: 'id',
      url: this.url,
      datatype: 'json',
      beforeSend: adicionarCabecalhoAuthAoJqXHR,
    };

    this.datafields = [
      { name: COLUNA_EXIBIR, type: 'string', map: 'id' },
      { name: COLUNA_EXCLUIR, type: 'string', map: 'id' },
      { name: COLUNA_BAIXAR, type: 'string', map: 'idArquivo' },
      { name: 'permitirExclusao', type: 'boolean', map: 'permitirExclusao' },
      { name: 'nome', type: 'string', map: 'nomeOriginal' },
      { name: 'dataCriacao', type: 'date', map: 'dataImportacao' },
      { name: 'status', type: 'string', map: "status" },
      // { name: 'idStatus', type: 'string', map: "status>id" },
    ];

    this.visualizar = this.visualizar.bind(this)
    // this.alterarRegistroSelecionado = this.alterarRegistroSelecionado.bind(this)
    this.changedForm = this.changedForm.bind(this)
    this.voltar = this.voltar.bind(this)
    this.enviarFormulario = this.enviarFormulario.bind(this)
  }

  async componentDidMount() {
    localStorage.setItem('coodernadasVoltar', true);
    const [
      datums,
    ] = await Promise.all([
      Service("/gps/datums").query(),
    ])

    this.setState({
      datums: datums.data.dados,
      editado: false,
    });

  }
  visualizar(id) {
    this.props.history.push(
      {
        pathname: 'gps/porId',
        state: {
          ...this.props.location.state,
          idSelecionado: id
        }
      })
  }

  excluir(id) {
    let tableRef = this.jqxGrid?.current?.tableRef;
    swal({
      title: `Tem certeza que deseja excluir o arquivo selecionado?`,
      icon: "warning",
      buttons: { cancel: "Não, cancelar", confirm: { text: "Sim, desejo excluir!", className: "btn-danger" }, },
    }).then((result) => {
      if (result) {
        this.setState({
          editado: false,
        });
        Service("/gps/arquivo")
          .delete(id).then(() => {
            tableRef.current.updatebounddata("cells");
          })
          .catch(this.exibirSwalErroExcluir);
      }
    });


  }

  exibirSwalErroExcluir = async (err) => {
    return swalErro({ title: "Não foi possível excluir o arquivo GPS" });
  };

  podeExcluirEntidadePadrao(entidade) {
    return entidade.permitirExclusao;
  }

  baixar(idArquivo, rowdata) {
    let urlDownload = "arquivos/paraId/" + idArquivo + "/download ";
    let nomeArquivo = rowdata?.rowData.nome;
    if (!nomeArquivo) {
      nomeArquivo = "arquivogps.gpx";
    }

    httpCommons.get(urlDownload, {
      timeout: 0
    })
      .then((response) => {
        // Verificar se há forma melhor para realizar o download de arquivo texto, a princípio foi encontrado o mesmo exemplo
        // em vários fóruns, se não fizer desta forma ele abre o conteúdo na aba do navegador em vez de realizar o download
        let urlDownload = window.URL.createObjectURL(new Blob([response.data]), { type: 'text/plain' });
        const a = document.createElement("a");
        a.style.display = "none";
        document.body.appendChild(a);

        a.href = urlDownload;
        a.setAttribute("download", nomeArquivo);
        a.click();
        window.URL.revokeObjectURL(a.href);
        document.body.removeChild(a);
      })
      .catch((response) => {
        console.error(response);
      });

    this.setState({ carregando: false })
  }

  enviarFormulario(valores) {
    this.setState({
      carregando: true
    }, async () => {
      if (!valores.hashesNovosArquivos) {      
        this.setState({ carregando: false,exibirMensagemErro:true })
      }
      if (valores.hashesNovosArquivos) {
        const bodyFormData = new FormData();
        bodyFormData.append('hashesNovosArquivos', valores.hashesNovosArquivos);
        bodyFormData.append('idDatum', valores.idDatum);

        await httpCommons.post('gps/uploadArquivosPorHashes', bodyFormData, {
          timeout: 0
        })
          .then((response) => {
            this.props.dispararNotificacao("Os arquivos foram salvos com sucesso!", { tipo: "success" })
            this.setState({ carregando: false,exibirMensagemErro:false})             

            var excluirArquivo = document.getElementsByClassName('file-grid__icon file-grid__icon--exclude')
            while (excluirArquivo.length > 0) {
              excluirArquivo[0].click()
            }
            delete valores.hashesNovosArquivos;

          })
          .catch((response) => {
            console.error(response);
            this.setState({ carregando: false,exibirMensagemErro:false})
          });

      }
    })
  }

  changedForm() {
    !this.state.editado && this.setState({ editado: true });
  }

  async voltar() {
    if (this.state.editado) {
      const result = await swal({
        title: `Os dados preenchidos não foram salvos, deseja prosseguir mesmo assim?`,
        icon: "warning",
        buttons: { cancel: "Não, cancelar", confirm: { text: "Sim, desejo prosseguir!", className: "btn-danger" }, },
      })
      if (!result) return false;
    }
    this.props.history.goBack()
  }


  render() {
    const { carregando,exibirMensagemErro } = this.state;

    return (
      <BlockUi blocking={carregando}>
        <PageScaffold titulo="Arquivos GPS">
          <InfoPanelGPS />
          <Container>
            <Card className="mx-3">
              <Card.Body className="mx-3 card-body">
                <Card.Title>Lista de arquivos GPS</Card.Title>
                <Row>
                  <button style={{ opacity: 0 }}
                    tabIndex="0"
                    autoFocus={true}
                  />
                  {!carregando && (
                    <Table
                      ref={this.jqxGrid}
                      datafields={this.datafields}
                      extraSourceParameters={this.extraSourceParameters}
                      columns={this.columns}
                      localization={Localizacao}
                    />
                  )}
                </Row>
                <Row className="float-right my-1">
                  <Col className="p-0 pr-2">
                    <FocusableButton
                      onClick={this.voltar}>
                      Voltar
                    </FocusableButton>
                  </Col>
                </Row>
                <Row className="mt-5">
                  <Col>
                    <FForm
                      subscription={{}}
                      mutators={arrayMutators}
                      onSubmit={this.enviarFormulario}
                      render={({ handleSubmit, form: { submit } }) => {
                        return (
                          <>
                            <form onSubmit={handleSubmit} onChange={this.changedForm}>
                              <Row>
                                <Col md={4} className="p-0 mb-1 mt-1">
                                  <Field
                                    component={DropdownListField}
                                    name='idDatum'
                                    label="Datum"
                                    dica="Selecione o Datum referente ao arquivo GPS a ser carregado"
                                    elementos={this.state.datums}
                                    required
                                    validate={campoObrigatorioComMsgGenerica("Datum")}
                                    changedFormGPS={this.changedForm}
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <br></br>
                                  <Card.Title>Upload de novos arquivos GPS</Card.Title>
                                  {exibirMensagemErro && (
                                    <span style={{ color: "red" }}>Ao menos um arquivo deve ser inserido</span>
                                  )}
                                </Col>
                              </Row>
                              <Row>
                                <Col className="p-0 mb-1 mt-1">
                                  <FileGridField
                                    campoNovosArquivos={`hashesNovosArquivos`}
                                    campoArquivosExistentes={`idsArquivosExistentes`}
                                    chaveListaArquivos={`arquivosGPS`}
                                    bloquearTipos={true}
                                    tiposPermitidos={['.gpx', 'application/gpx+xml']}
                                    key={this.source}
                                    disable={false}
                                    changedFormGPS={this.changedForm}
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col className="pr-2 pt-1">
                                  <FocusableButton
                                    className="float-right "
                                    onClick={submit}
                                  >
                                    Salvar
                                  </FocusableButton>
                                </Col>
                              </Row>
                            </form>
                          </>
                        )
                      }}
                    />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Container>
        </PageScaffold>
      </BlockUi>
    )
  }
}

export default connect(
  null,
  (dispatch) => ({
    dispararNotificacao(mensagem, config) {
      dispatch(mostrarNotificacao(mensagem, config))
    },
  })
)(ArquivosGPS);
