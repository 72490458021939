import React, { PureComponent } from "react";
import Dropzone, {
  DropEvent,
  DropzoneState,
  FileRejection,
} from "react-dropzone";
import { FieldArray } from "react-final-form-arrays";
import { Field } from "react-final-form";
import FileCard from "../../../../../components/file/Card";
import geoquimicaService from "../../../../../service/GeoquimicaService";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import Lightbox from "react-awesome-lightbox";
import cryptoRandomString from "crypto-random-string";

interface ImageItem {
  readonly caminho?: string;
  tamanho?: number;
  hash?: string;
  key: string;
  nome?: string;
  tipo: string;
  carregando?: boolean;
  erro?: string;
  temp: boolean;
}

export interface ImagensAmostraSubFormProps {
  prefixoNome?: string;
  images?: string[];
}

export interface ImagensAmostraSubFormState {
  lightbox?: { images: { url: string; title: string }[]; startIndex: number };
}

export class ImagensAmostraSubForm extends PureComponent<
  ImagensAmostraSubFormProps,
  ImagensAmostraSubFormState
> {
  tiposPermitidos = ["image/*"];

  private prefixUrl = process.env.REACT_APP_GEOQUIMICA_API_URL;

  get prefixoNome() {
    return this.props.prefixoNome ?? "";
  }

  private imageItemsCacheMap = new Map<string, ImageItem>();
  private fieldsArray!: any;

  private lifecycle = new Subject();

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillUnmount() {
    this.lifecycle.next();
    this.lifecycle.complete();
  }

  onDropFile = (
    acceptedFiles: File[],
    fileRejections: FileRejection[],
    event: DropEvent
  ) => {
    const file = acceptedFiles?.[0];
    let item = this.handleImage(cryptoRandomString(20), true);
    item.carregando = true;
    item.nome = file.name;
    item.tamanho = file.size;
    this.fieldsArray.push(item);
    geoquimicaService
      .uploadAmostraImage(file)
      .pipe(takeUntil(this.lifecycle))
      .subscribe({
        next: (hash) => {
          item.carregando = false;
          item.hash = hash;
          const index = this.findItemIndex(item);
          this.fieldsArray?.update(index, item);
        },
        error: (response) => {
          item.carregando = false;
          item.erro = "Não foi possível fazer o upload!";
          const index = this.findItemIndex(item);
          this.fieldsArray?.update(index, item);
        },
      });
  };

  onRemoveImageClick = (itemOrKey: string | ImageItem) => {
    const index = this.findItemIndex(itemOrKey);
    if (index > -1) {
      const key = this.handleKey(itemOrKey);
      this.imageItemsCacheMap.delete(key);
      this.fieldsArray?.remove(index);
    }
  };

  onClickImagem = (item: ImageItem) => {
    this.setState({
      lightbox: {
        images: [
          {
            title: item.nome ?? "",
            url: `${this.prefixUrl}${item.caminho}`,
          },
        ],
        startIndex: 0,
      },
    });
  };

  onLightboxClose = () => {
    this.setState({ lightbox: undefined });
  };

  private findItemIndex(itemOrKey: string | ImageItem) {
    const key = this.handleKey(itemOrKey);
    const index = this.fieldsArray?.value?.findIndex((x) => {
      const aux = this.handleKey(x);
      return aux === key;
    });

    return index;
  }

  private handleKey(itemOrKey: string | ImageItem) {
    let key: string;
    if (typeof itemOrKey === "object") {
      key = itemOrKey.key ?? itemOrKey.hash;
    } else {
      key = itemOrKey;
    }

    return key;
  }

  private handleImage(itemOrKey: string | ImageItem, temp = false): ImageItem {
    const key = this.handleKey(itemOrKey);

    let item: ImageItem;
    if (this.imageItemsCacheMap.has(key)) {
      item = this.imageItemsCacheMap.get(key)!;
    } else {
      if (typeof itemOrKey === "object") {
        item = itemOrKey;
      } else {
        item = { key, tipo: "image/*", temp } as any;

        const urlGetter = (item: ImageItem) => {
          const hash = item.hash;
          if (!hash) {
            return "";
          }

          if (item.temp) {
            return `/arquivos/temp/${hash}`;
          } else {
            return `/arquivos/paraHash/${hash}/download`;
          }
        };

        Object.defineProperty(item, "caminho", {
          get: () => {
            return urlGetter(item);
          },
        });
      }

      this.imageItemsCacheMap.set(key, item);
    }

    return item;
  }

  private handleHashes(hashes: string[] = []): ImageItem[] {
    const items = hashes.map((x) => this.handleImage(x));
    return items;
  }

  renderFieldArray = ({ fields }) => {
    this.fieldsArray = fields;
    return (
      <>
        {fields.map((name, i) => {
          return <Field key={i} component="input" type="hidden" name={name} />;
        })}
        <div className="file-grid__files">
          {this.handleHashes(fields.value).map((item, i) => {
            return (
              <>{
                <FileCard
                  key={`${item.hash}_${i}`}
                  prefixUrl={this.prefixUrl}
                  nomeGrid={item.nome!}
                  chave={this.handleKey(item)}
                  // arquivo={item}
                  mostrarBotaoExclusao={true}
                  onExcluir={this.onRemoveImageClick as any}
                  onClickImagem={this.onClickImagem as any}
                />}
              </>
            );
          })}
        </div>
      </>
    );
  };

  renderDropzone = ({ getRootProps, getInputProps }: DropzoneState) => {
    return (
      <>
        <div className="file-grid">
          <div className="file-grid__drop-zone" {...getRootProps()}>
            <input type="file" name="files[]" {...getInputProps()} />
            <div className="file-grid__instructions">
              <p>Arraste um arquivo ou clique para adicionar</p>
            </div>
            <FieldArray name={`${this.props.prefixoNome ?? ""}imagens`}>
              {this.renderFieldArray}
            </FieldArray>
            {this.state.lightbox ? (
              <Lightbox
                images={this.state.lightbox.images}
                startIndex={this.state.lightbox.startIndex}
                onClose={this.onLightboxClose}
              />
            ) : null}
          </div>
        </div>
      </>
    );
  };

  render() {
    return (
      <>
        <Dropzone onDrop={this.onDropFile} maxFiles={1}>
          {this.renderDropzone}
        </Dropzone>
      </>
    );
  }
}
