import http from "../service/http-commons";
import axios from "axios";
import eventBus from '../service/EventBus';
import { verificarConexaoErro } from "../util/validadores";

export const ACCESS_TOKEN_KEY = 'accessToken';
export const USE_GOOGLE_OAUTH2 = 'USE_GOOGLE_OAUTH2';

const PREFIXO = "auth/";

/**
 * action types
 */
export const SUCESSO_AO_LOGAR = PREFIXO + "SUCESSO_AO_LOGAR";
export const ERRO_AO_LOGAR = PREFIXO + "ERRO_AO_LOGAR";
export const SUCESSO_AO_SAIR = PREFIXO + "SUCESSO_AO_SAIR";
export const SUCESSO_LOGIN_OAUTH = PREFIXO + "SUCESSO_LOGIN_OAUTH";

/*
 * action creators
 */

export const efetuarLogin = ({ usuario, senha }) => (dispatch) => {
  const formData = new FormData();
  formData.append("usuario", usuario)
  formData.append("senha", senha)

  return axios({
    method: 'post',
    data: formData,
    url: process.env.REACT_APP_API_URL + '/login',
    timeout: 10000,
  })
    .then(async res => {
      const data = res.data;
      if (data.erro) {
        await removerTokenAutenticacao()
        dispatch(erroAoLogar({
          erro: res.data.erro,
        }));
      } else if (data.token) {
        await persistirTokenAutenticacao(data.token)
        window.accessToken = data.token;
        dispatch(sucessoAoLogar({
          tokenAutenticacao: res.data.token,
          permissoes: res.data.permissoes,
          nome: res.data.nome,
        }))
      }
    })
    .catch(verificarConexaoErro)
    .catch(async () => {
      await removerTokenAutenticacao()
      dispatch(erroAoLogar({
        erro: "Ocorreu um erro ao logar",
      }));
    })
};

export const efetuarLoginOAuth = ({ token }) => (dispatch) => {
  return axios({
    method: 'post',
    url: process.env.REACT_APP_API_URL + '/refetchPermissoes',
    headers: { 'Authorization': `Bearer ${token}` },
  })
    .then(async res => {
      const data = res.data;
      if (data.erro) {
        await removerTokenAutenticacao()
        dispatch(erroAoLogar({
          erro: res.data.erro,
        }));
      } else if (data.token) {
        window.accessToken = data.token;
        await persistirTokenAutenticacao(data.token)
        dispatch(sucessoAoLogar({
          tokenAutenticacao: res.data.token,
          permissoes: res.data.permissoes,
          nome: res.data.nome,
        }))
      }
    })
    .catch(async () => {
      await removerTokenAutenticacao()
      dispatch(erroAoLogar({
        erro: "Acesso expirado",
      }));
    })
};

export const efetuarLoginOAuasdth = ({ token }) => ({
  type: SUCESSO_LOGIN_OAUTH, payload: { token }
});

export const efetuarLogoff = () => (dispatch) => {
  return http.post('/logout')
    .catch(res => {
      console.log(res)
    })
    .finally(async () => {
      await removerTokenAutenticacao()
      window.accessToken = undefined;
      sessionStorage.clear()
      eventBus.emit('logout');
      if(localStorage.getItem(USE_GOOGLE_OAUTH2)){
        localStorage.removeItem(USE_GOOGLE_OAUTH2);
        document.location.href = `/login`;
      }
      dispatch(sucessoAoSair())
    });
};

const sucessoAoLogar = (params) => ({
  type: SUCESSO_AO_LOGAR, payload: params
});

const erroAoLogar = ({erro}) => ({
  type: ERRO_AO_LOGAR, payload: {erro}
});

const sucessoAoSair = (params) => ({
  type: SUCESSO_AO_SAIR, payload: params
});

const persistirTokenAutenticacao = (token) => {
  return new Promise(resolve => {
    localStorage.setItem(ACCESS_TOKEN_KEY, token)
    setTimeout(() => resolve(), 100);
  })
}

const removerTokenAutenticacao = () => {
  return new Promise(resolve => {
    localStorage.removeItem(ACCESS_TOKEN_KEY)
    setTimeout(() => resolve(), 100);
  })
}

/**
 * selectors
 */
export const extrairPermissoes = (state) => {
  if (!state || !state.autenticacao || !state.autenticacao.permissoes) return {};
  // eu poderia simplesmente retornar state.autenticacao.permissoes, mas dessa forma fica mais simples alterar o nome de qlqr permissão :)
  return {
  }
}


/**
 * reducers
 */

export const authReducer = (state = {}, acao) => {
  switch (acao.type) {
    case SUCESSO_AO_LOGAR: return {
      tokenAutenticacao: acao.payload.tokenAutenticacao,
      permissoes: acao.payload.permissoes,
      nome: acao.payload.nome,
      autenticado: true
    };
    case ERRO_AO_LOGAR: return {
      autenticado: false,
      erro: acao.payload.erro,
    };
    case SUCESSO_AO_SAIR: return {
      autenticado: false,
    }
    default: return state;
  }
};
