import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Field, useForm } from "react-final-form";
import { aoEnviarSubForm } from "../../../../../util/mutadores";
import Card from "../../../../../components/card";
import { SubForm } from "../../../../../components/SubForm";
import { DropdownListField } from "../../../../../components/field/DropdownListField";
import { resolverExibidos } from "../../../../../components/SubForm/SubForm";
import { buscaPercentuaisArcabouco, calcularSomaPercentuais, validarPorcentagemTotal } from "../../../utils/gerais";
import FasesMinerais from "./FasesMinerais";
import { FORM_ERROR } from "final-form";
import { campoObrigatorioComMsgGenerica } from "../../../../../util/validadores";

const Cimento = ({
    permitirEdicao,
    bbNaturezaCimento,
    permitirInsercao,
    disabled,
    form,
    jaCadastrouCimento,
    exibirCimento
}) => {

    const onSubmit = aoEnviarSubForm(form);

    const colunasCimento = [
        {
            text: 'Natureza do Cimento',
            datafield: 'idNaturezaCimento',
            width: '30%',
            cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
                const objetoFiltrado = bbNaturezaCimento.find(elemento => elemento.id === value);
                const valor = objetoFiltrado ? objetoFiltrado.nome : '';
                return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${valor}</div>`;
            }
        },
        {
            text: 'Fases Minerais',
            datafield: 'idMinerais',
            width: '30%',
            cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
                let nomesCorrespondentes = "";
                const cimentos = resolverExibidos(form.getState().values?.cimentos);

                const cimentosDaLinha = cimentos?.[row];
                const fasesMinerais = cimentosDaLinha?.fasesMinerais;
                let nomesComPorcentagem = ''
                if (fasesMinerais) {
                    const fasesMineraisTela = resolverExibidos(fasesMinerais);

                    nomesComPorcentagem = fasesMineraisTela.map((item) => {
                        const mineralCorrespondente = bbNaturezaCimento.find(({ id }) => id === item.idMineral);

                        if (mineralCorrespondente) {
                            const { nome } = mineralCorrespondente;
                            const porcentagem = item.porcentagemCimento;
                            return ` ${nome} (${porcentagem}%)`;
                        } else {
                            return "";
                        }
                    });
                }
                return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;" title="${nomesCorrespondentes}">${nomesComPorcentagem}</div>`;
            }
        },
    ]
    const camposCimento = [
        { name: "idNaturezaCimento", type: "number", map: "idNaturezaCimento" },
        { name: "idMinerais", type: "number", map: "idMinerais" },
    ];

    const validarFasesMinerais = (valores) => {

        if (valores?.fasesMinerais) {
            let fasesMineraisTela = resolverExibidos(valores?.fasesMinerais);
            if (fasesMineraisTela.length > 0) {
                const validacaoFragmentosMinerais = validarPorcentagemTotal(valores, 'fasesMinerais', 'porcentagemCimento');
                if (validacaoFragmentosMinerais) {
                    return { [FORM_ERROR]: "A soma da porcentagens das Fases Minerais deve ser de 100%." }
                }
            }
        }

        return null
    }

    const valorFormulario = useForm()

    return (
        <>
            <div style={{ marginTop: '2.5rem', display: exibirCimento }}>
                <Card className="mt-3">
                    <Card.Body>
                        <Card.Title>Cimento</Card.Title>
                        <Field name={'cimentos'} subscription={{ value: true }}>
                            {({ input: { value: cimentos = [] } }) => (
                                <SubForm
                                    nome={'cimentos'}
                                    onSubmit={onSubmit}
                                    exibirTabelaSemRegistro={false}
                                    colunas={colunasCimento}
                                    elementos={cimentos}
                                    campos={camposCimento}
                                    permitirInsercao={permitirInsercao}
                                    validar={(valor) => validarFasesMinerais(valor)}
                                    exibirBotaoInsercao={!(disabled || jaCadastrouCimento)}
                                    permitirEdicao={!disabled}
                                    permitirExclusao={!disabled}
                                    permitirVisualizacao={disabled}
                                    podeExcluirEntidade={!disabled}
                                    renderForm={({ formProps: { form } }) => {

                                        let fasesMinerais = [];

                                        if (form.getState().values?.fasesMinerais) {
                                            fasesMinerais = resolverExibidos(form.getState().values?.fasesMinerais)
                                        }

                                        var valorCimentoSelecionado = form.getState().values?.idNaturezaCimento
                                        var naturezaCimento = valorFormulario?.getState().values?.cimentos

                                        var naturezaCimentoTela = []

                                        if (naturezaCimento) {
                                            naturezaCimentoTela = resolverExibidos(naturezaCimento)
                                        }

                                        var bbMineraisFiltrados = bbNaturezaCimento.filter(value => {
                                            return !naturezaCimentoTela.some(testeItem => testeItem.idNaturezaCimento === value.id && valorCimentoSelecionado !== value.id);
                                        });

                                        const somaTotalPercentuaisCimento = calcularSomaPercentuais(buscaPercentuaisArcabouco(fasesMinerais, 'porcentagemCimento'));

                                        return (
                                            <>
                                                <Row>
                                                    <Col md={3}>
                                                        <Field
                                                            component={DropdownListField}
                                                            name={"idNaturezaCimento"}
                                                            label="Natureza do Cimento"
                                                            dica="Selecione a natureza do cimento."
                                                            required
                                                            displayMember={'nome'}
                                                            elementos={bbMineraisFiltrados}
                                                            disabled={disabled}
                                                            validate={campoObrigatorioComMsgGenerica("Natureza do Cimento")}
                                                        />
                                                    </Col>
                                                </Row>
                                                <FasesMinerais
                                                    form={form}
                                                    permitirEdicao={true}
                                                    bbMinerais={bbNaturezaCimento}
                                                    disabled={disabled}
                                                    somaTotalPercentuais={somaTotalPercentuaisCimento}
                                                />
                                            </>
                                        )
                                    }
                                    }
                                />
                            )}
                        </Field>
                    </Card.Body >
                </Card>
            </div>
        </>
    );
};

export default Cimento;