import { applyMiddleware, compose, createStore } from "redux";
import rootReducer from './reducers';
import { createLogger } from "redux-logger";
import thunk from "redux-thunk";
import { createMigrate, persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

const migrations = {};

const persistConfig = {
  key: 'storage_aflora',
  storage,
  version: 1,
  migrate: createMigrate(migrations, {debug: false}),
  whitelist: ['autenticacao'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const middlewareArr = [thunk];

if (process.env.NODE_ENV !== 'production') {
  middlewareArr.push(createLogger({
    collapsed: true,
  }))
}

const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const store = createStore(
  persistedReducer,
  composeEnhancers(
    applyMiddleware(...middlewareArr)
  ),
);

const persistor = persistStore(store);

export {
  store,
  persistor,
};
