import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Field, useForm } from "react-final-form";
import { aoEnviarSubForm } from "../../../util/mutadores";
import Card from "../../../components/card";
import { SubForm } from "../../../components/SubForm";
import { DropdownListField } from "../../../components/field/DropdownListField";
import { getPropsPermissao, resolverExibidos } from "../../../components/SubForm/SubForm";
import { campoObrigatorioComMsgGenerica } from "../../../util/validadores";
import { FORM_ERROR } from "final-form";
import { CounterField } from "../../../components/field/CounterField";

const Processo = ({
    formProps,
    bbProcesso,
    disabled
}) => {

    const form = useForm()
    const onSubmit = aoEnviarSubForm(form);

    function ordenacaoCrescente(valor) {
        return valor.sort((a, b) => a.hierarquia - b.hierarquia);
    }

    const colunasAlteracaoHidrotermal = [
        {
            text: 'Processo',
            datafield: 'idProcesso',
            width: '20%',
            cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
                const tipoDeAlteracao = parseInt(rowdata?.idProcesso, 10);
                const objetoFiltrado = bbProcesso.find(elemento => elemento.id === tipoDeAlteracao);
                const valor = objetoFiltrado ? objetoFiltrado.nome : '';
                return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${valor}</div>`;
            }
        },
        {
            text: 'Hierarquia',
            datafield: 'hierarquia',
            width: '20%',
            align: "center",
            sortcolumn: 'hierarquia',
            cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
                return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px; text-align:center">${value}</div>`;
            },

        },
    ]
    const camposAlteracaoHidrotermal = [
        { name: "idProcesso", type: "number", map: "idProcesso" },
        { name: "hierarquia", type: "number", map: "hierarquia" },
    ];
    const validarCampos = (valores) => {

        const itens = formProps.getState()?.values;

        if (itens) {
            const estimativas = resolverExibidos(itens);

            const indexExistente = estimativas.findIndex(item => item.id === valores.id);

            if (indexExistente !== -1) {
                estimativas[indexExistente] = valores;
            } else {

                estimativas.push(valores);
            }

            if (estimativas && estimativas.length > 0) {

                if (!estimativas[0].hierarquia && !estimativas[0].idProcesso) {
                    return { [FORM_ERROR]: 'Preencha ao menos um dos campos para proseguir com a confirmação.' };
                }

            }
            return null;
        }
    };


    const valorFormulario = useForm();

    return (
        <>
            <div style={{ marginTop: '2.5rem' }}>
                <Card className="mt-3">
                    <Card.Body>
                        <Card.Title>Processo</Card.Title>
                        <Field name={'processo'} subscription={{ value: true }}>
                            {({ input: { value: processo = [] } }) => {
                                return (
                                    <SubForm
                                        ordenacaoPersonalizada={async (elementos) => await ordenacaoCrescente(elementos)}
                                        nome={'processo'}
                                        onSubmit={onSubmit}
                                        modoSubFormComGrid
                                        colunas={colunasAlteracaoHidrotermal}
                                        elementos={processo}
                                        campos={camposAlteracaoHidrotermal}
                                        validar={(valor) => validarCampos(valor)}
                                        {...getPropsPermissao(!disabled)}
                                        renderForm={({ formProps: { form } }) => {

                                            var valorAtual = form.getState().values?.idProcesso

                                            var processos = valorFormulario?.getState().values?.processo
                                            var processosEmTela = []
                                            if (processos) {
                                                processosEmTela = resolverExibidos(processos)
                                            }
                                            var bbProcessosFiltrados = bbProcesso.filter(value => {
                                                return !processosEmTela.some(testeItem => testeItem.idProcesso === value.id && valorAtual !== value.id);
                                            });

                                            return (<>
                                                <Row className="my-3">
                                                    <Col md={6}>
                                                        <Field
                                                            name={`idProcesso`}
                                                            component={DropdownListField}
                                                            label="Processo"
                                                            dica="Selecione um ou mais processos responsáveis pela formação da rocha  e modificações."
                                                            elementos={bbProcessosFiltrados}
                                                            disabled={disabled}
                                                        />

                                                    </Col>
                                                    {!valorAtual &&
                                                        <Col md={6}>
                                                            <Field
                                                                component={CounterField}
                                                                name={`hierarquia`}
                                                                label="Hierarquia"
                                                                dica="Selecione a hierarquia de cada processo observado."
                                                                disabled={true}
                                                            />
                                                        </Col>
                                                    }
                                                    {valorAtual &&
                                                        <Col md={6}>
                                                            <Field
                                                                component={CounterField}
                                                                name={`hierarquia`}
                                                                label="Hierarquia"
                                                                dica="Selecione a hierarquia de cada processo observado."
                                                                disabled={false}
                                                                required
                                                                validate={campoObrigatorioComMsgGenerica("Hierarquia")}
                                                            />
                                                        </Col>
                                                    }
                                                </Row>
                                            </>
                                            )
                                        }}
                                    />)
                            }}
                        </Field>
                    </Card.Body>
                </Card>
            </div>
        </>
    );
};

export default Processo;