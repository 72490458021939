import { flushSync } from "react-dom";

export const setValue = (args, state, utils) => {
  if (args.length === 2) {
    const field = args[0]
    const newValue = args[1]
    if (state.lastFormState.values[field] !== newValue) {
      utils.changeValue(state, field, () => newValue)
    }
  }
}

/**
 * Factory que retorna uma função para ser passada ao onSubmit do SubForm.
 * Atribui os valores enviados pelo SubForm ao final-form passado como parâmetro
 * @param form - Objeto final-form
 */
export const aoEnviarSubForm = form => {
  return ({ alteracoesForm, prefixoNome, dadosAdicionais = {} }) => {
    const { estadoEdicao: { estaEditando } = {}, registroEditado } = dadosAdicionais;

    flushSync ( ( ) => {  
      if (estaEditando && registroEditado) {
        // alteracoesForm é um objeto com os valores dos campos. Caso um campo foi editado e limpo,
        // ele não estará nesse objeto. Por isso, deve-se limpar o objeto que representa a
        // versão anterior do registro antes de atribuir os novos valores
        form.mutators.setValue(registroEditado, null);
      }

      for (const [key, val] of Object.entries(alteracoesForm)) {
        form.mutators.setValue(`${key}`, val)
      }
    });
  }
};