export const createIconButton = ({ iconClasses, onClick, ref, botaoAtivo, extraClassResolver, method, hint }) => (
  row,
  column,
  value,
  htmlElement
) => {
  let rowData = typeof row === "number"
    ? ref.current && ref.current.getrowdata && ref.current.getrowdata(row)
    : row.bounddata

  if(!rowData){
    rowData = ref?.current?.tableRef?.current?.getrowdata(row)
  }

  // In initwidget, row is a number instead of a row with bounddata, so we need
  // to use the table ref to obtain the bounddata from the table itself. Sometimes,
  // the ref is null (particularly when the table was hidden and becomes not
  // hidden anymore). If all these conditions align, the row data will be empty,
  // but this seems (I think) to only happen in cases where createwidget has
  // been just called before, with the correct result. Given that that is always
  // true, then we can just return in initwidget and keep the widget unchanged.
  // 
  // To my knowledge, this problem has always happened. It became apparent after
  // the Table refactor to eliminate unnecessary rerenders because originally,
  // the JqxGrid would always render about three times and only the first would
  // call this callback with a null ref, so the subsequent renders would hide
  // the problem.
  //
  // Note that in theory, createwidget should be called the first time a row
  // is created and initwidget should be called all subsequent times, but you'll
  // find that in our code these two methods get called more often than that.
  if (!rowData && method === "initwidget") {
    return;
  }

  if (htmlElement.firstChild) {
    htmlElement.firstChild.remove();
  }

  const button = document.createElement("button");
  button.classList.add("btn");
  button.title = hint ? hint : '';
  const iconEl = document.createElement("i");
  iconEl.classList.add(...iconClasses);
  button.appendChild(iconEl);

  htmlElement.appendChild(button);
  htmlElement.classList.add('d-flex');
  htmlElement.classList.add('justify-content-center');
  htmlElement.classList.add('align-items-center');
  const deveAtivar = typeof botaoAtivo === 'function' ? botaoAtivo(rowData) : !botaoAtivo;

  if (extraClassResolver) {
    iconEl.classList.add(extraClassResolver(rowData))
  }

  if (deveAtivar) {
    button.addEventListener("click", () => {
      onClick(value, {
        rowData: rowData,
      })
    });
  } else {
    iconEl.classList.add("text-muted");
    button.disabled = true
  }
};

/// The main difference between createwidget and initwidget is that in createwidget,
/// the first parameter "row" is an object containing a row's properties, and in
/// init widget "row" is the row's index.
/// Supposedly, createwidget gets called only once, and initwidget is called all
/// subsequent times.

export const createControlColumn = ({ onClick, iconClasses, dataField, ref, botaoAtivo, hintBotao,text }) => ({
  text: text||"",
  enabletooltips: false,
  menu: false,
  width: '3%',
  dataField: dataField,
  createwidget: createIconButton({ iconClasses, onClick, ref, botaoAtivo, method: "createwidget", hint: hintBotao }),
  initwidget: createIconButton({ iconClasses, onClick, ref, botaoAtivo, method: "initwidget", hint: hintBotao }),
  sortable: false,
  resizable: false,
});

export const criarBtnExcluirEstacao = ({ onClick, iconClasses, dataField, ref, botaoAtivo }) => ({
  text: "",
  enabletooltips: false,
  menu: false,
  width: '3%',
  dataField: dataField,
  createwidget: criarIconeExcluirEstacao({ iconClasses, onClick, ref, botaoAtivo, method: "createwidget" }),
  initwidget: criarIconeExcluirEstacao({ iconClasses, onClick, ref, botaoAtivo, method: "initwidget" }),
  sortable: false,
  resizable: false,
});

export const criarIconeExcluirEstacao = ({ iconClasses, onClick, ref, botaoAtivo, extraClassResolver, method }) => (
  row,
  column,
  value,
  htmlElement
) => {

  let rowData = undefined;

  if(typeof row === "number"){
    rowData = ref?.current?.props?.source?.originaldata[row];
    if(!rowData) {
      rowData = ref?.current?.current?.props?.source?.originaldata[row];
    }
  }
  else {
    rowData = row.bounddata;
  }

  // See comment in createIconButton, this is the same situation.
  if (!rowData && method === "initwidget") {
    return;
  }

  if (htmlElement.firstChild) {
    htmlElement.firstChild.remove();
  }

  let podeExcluir = false;

  if(rowData && rowData.podeExcluir)
    podeExcluir = true;

  const disabledClasses = ['fas', 'fa-trash', 'text-secondary'];

  const button = document.createElement("button");
  button.classList.add("btn");
  const iconEl = document.createElement("i");

  if(podeExcluir)
    iconEl.classList.add(...iconClasses);
  else 
    iconEl.classList.add(...disabledClasses);

  button.appendChild(iconEl);

  htmlElement.appendChild(button);
  htmlElement.classList.add('d-flex');
  htmlElement.classList.add('justify-content-center');
  htmlElement.classList.add('align-items-center');
  const deveAtivar = typeof botaoAtivo === 'function' ? botaoAtivo(rowData) : !botaoAtivo;

  if (extraClassResolver) {
    iconEl.classList.add(extraClassResolver(rowData))
  }

  if (deveAtivar) {
    button.addEventListener("click", () => {
      onClick(value, {
        rowData: rowData,
      })
    });
  } else {
    iconEl.classList.add("text-muted");
    button.disabled = true
  }

  if(!podeExcluir){
    button.disabled = true
  }
};

export const createCheckboxColumn = ({ dataField, text, beginEdit, cellClass }) => ({ 
  text: text, 
  dataField: dataField, 
  columntype: 'checkbox', 
  editable: true,
  cellbeginedit: beginEdit,
  cellclassname: cellClass
});

/**
 * Função que roda até a tabela terminar seu binding.
 * (Pode estar errado. Eu documentei essa função mas não fui eu que a escrevi.)
 * @param {*} ref Referência para uma instância de JqxGrid.
 * @param {*} fn Função a executar quando a tabela terminar seu binding.
 * @param {*} param2 Objeto contendo opções. A única opção é um timeout em ms.
 */
export const esperarAteTabelaDisponivel = (ref, fn, { timeout = 100 } = {}) => {
  const id = setInterval(() => {
    if (ref.current && ref.current.isBindingCompleted && ref.current.isBindingCompleted()) {
      fn()
      clearInterval(id);
    }
  }, timeout);
}
