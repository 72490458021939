import { SubFormAmostra } from "./components/informacoesAmostra/SubFormAmostra";
import { HiddenField } from "../../../../components/field/HiddenField";
import SubFormDetalhesIsocronas from "./components/detalhesIsocrononas/SubFormDetalhesIsocronas";
import SubFormDetalhesAquecimento from "./components/detalhesEquipamento/SubFormDetalhesAquecimento";
import MaterialSuplementar from "../../sharedComponents/MaterialSuplementar";
import SubFormIdadeAssociadaAmostra from "./components/IdadeAssociadaAmostra/SubFormIdadeAssociadaAmostra";
import { Field } from "react-final-form";

const AtividadeGeocron = ({
  formProps,
  amostraSelecionada,
  bbMinerais,
  bbSigma,
  bbTipoIdade,
  bbInterpretacao,
  bbSistemasIsotopicos,
  bbTecnicaAnalitica,
  bbMaterialAnalisado,
  bbExtencaoIlustracao,
  bbTipoIlustracao,
  idProjetoCorrente
}) => {
  return (
    <>
    <Field
        component={HiddenField}
        name="idProjetoCorrente"
        value={idProjetoCorrente}
      />
      <Field
        component={HiddenField}
        name="idProjetoHistorico"
        value={idProjetoCorrente}
      />
      <Field
        component={HiddenField}
        name="idArea"
        value={idProjetoCorrente}
      />
      <Field
        component={HiddenField}
        name="idSubArea"
        value={idProjetoCorrente}
      />
      <SubFormAmostra
        amostraSelecionada={amostraSelecionada}
        estaEditando={false}
        formRef={formProps}
        disabled={true}
        estaVisualizando={true}
        bbMinerais={bbMinerais}
      />
      <SubFormIdadeAssociadaAmostra
        formProps={formProps}
        bbSigma={bbSigma}
        bbTipoIdade={bbTipoIdade}
        bbInterpretacao={bbInterpretacao}
        bbSistemaIsotopico={bbSistemasIsotopicos}
        bbTecnicaAnalitica={bbTecnicaAnalitica}
        bbMaterialAnalisado={bbMaterialAnalisado}
        bbIdadeMilhoesDeAnos={[]}
        disabled={true}
        permitirInsercao={false}
      />
      <SubFormDetalhesIsocronas
        formRef={formProps}
        disabled={true}
        permitirInsercao={false}
        idAmostra={amostraSelecionada?.id}
      />
      <SubFormDetalhesAquecimento
        formRef={formProps}
        disabled={true}
        permitirInsercao={false}
      />
      <MaterialSuplementar
        amostraSelecionada={amostraSelecionada}
        disabled={true}
        extencoesIlustracao={bbExtencaoIlustracao}
        tiposIlustracao={bbTipoIlustracao}
        permitirInsercao={false}
      />
    </>
  );
};

export default AtividadeGeocron;
