import React from "react";

const Erro = ({mensagem}) => {
  return (
    !!mensagem && <>
      <span className="erro-campo" style={{color: 'red', fontSize: '10px'}}>{mensagem}</span>
    </>
  )
}

Erro.propTypes = {
  // mensagem: PropTypes.string,
}

export default Erro
