import React from "react";
import { Col, Row } from "react-bootstrap";
import { Field } from "react-final-form";
import { DropdownListField } from "../../../../../components/field/DropdownListField";
import { campoObrigatorioDefinitivoComMsgGenerica } from "../../../../../util/validadores";
import { resolverRequestAmostras } from "../AmostrasUtils/AmostraRequestUtils";
import CamposComuns from "./CamposComuns";

class Vegetacao extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      situacaoAmostra: [],
      ambienteBiotico: [],
    }
  };

  async componentDidMount() {
    this.setState({
      situacaoAmostra: await resolverRequestAmostras("/biblioteca/situacaoamostra"),
      ambienteBiotico: await resolverRequestAmostras("/biblioteca/ambientebiotico"),
    })
  }


  render() {
    return <>
      <CamposComuns
        duplicata={this.props.duplicata}
        materialColetadoPreenchido={true}
        labelCampo="Peso da amostra (g)"
        dicaCampo="Informe o peso da amostra, em gramas"
        profundidadeObrigatoria={true}
        prefixo={'vegetacao'}
        permitirEdicao={this.props.permitirEdicao}
        informacoesRelevantesObrigatorias={true}
        visualizar={this.props.visualizar}
      />

      <Field subscription={{ value: true }} name={'modoCadastro'}>
      {({ input: { value: modoCadastro } }) => {

        const validar = modoCadastro === 'DEFINITIVO';

        return <>
          <Row className="mt-3 mb-3">
            <Col md={6}>
              <Field
                disabled={!this.props.permitirEdicao}
                name="vegetacao.idAmbienteBiotico"
                component={DropdownListField}
                label="Ambiente biótico"
                dica="Selecione o ambiente biótico no local amostrado, se aplicável"
                required={validar}
                elementos={this.state.ambienteBiotico}
                validate={campoObrigatorioDefinitivoComMsgGenerica(
                  "Ambiente biótico"
                )}
              />
            </Col>
          </Row>
        </>
      }}
      </Field>
    </>
  }

}

export default Vegetacao;
