import PropTypes from "prop-types";
import { DropdownList } from "../../Jqx/DropdownList";
import Select, { createFilter } from "react-select";
import { useEffect, useState } from "react";

const DropdownListWithValue = ({ elementos, valueMember, onSelect, value, multiple, maximoSelecoes, isClearable = false, displayMember = 'nome', ...props }) => {
  const styles = {
    menu: provided => ({ ...provided, zIndex: 9999 }),

    control: (base) => ({
      ...base,
      minHeight: 25,
      height: 25,
      fontFamily: "Helvetica",
      backgroundColor: 'white',
      color: props.disabled ? '#dbdbdb' : 'black'
    }),
    dropdownIndicator: (base) => ({
      ...base,
      padding: 0,
      margin: 0
    }),
    clearIndicator: (base) => ({
      ...base,
      padding: 0,
      margin: 0
    }),
    indicatorSeparator: (base) => ({
      ...base,
      height: 23,
      minHeight: 0,
      padding: 0,
      margin: 0
    }),
    placeholder: (base) => ({
      ...base,
      fontSize: 13,
      padding: 0,
      margin: 0,
      paddingLeft: 5,
      fontFamily: "Helvetica",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      maxWidth: "90%",
      color: props.forcePlaceholderColor && !props.disabled ? 'black' : '#868686'
    }),
    container: (base) => ({
      ...base,
      padding: 0,
      margin: 0,
    }),

    singleValue: (base) => ({
      fontSize: 13,
      padding: 0,
      paddingTop: 0,
      paddingLeft: 10,
      margin: 0,
      fontFamily: "Helvetica",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      position: 'static',
      maxWidth: "90%",
    }),
    input: (base) => ({
      ...base,
      padding: 0,
      margin: 0,
      marginLeft: 5,
      position: 'static',
      overflow: 'none',
      fontSize: 13,
      fontFamily: "Helvetica"
    }),
    indicatorsContainer: (base) => ({
      ...base,
      minHeight: 23,
      maxHeight: 23,
    }),
    option: (base) => ({
      ...base,
      fontSize: 13,
      fontFamily: "Helvetica"
    }),
    valueContainer: props.checkboxes ? (provided, state) => ({
      ...provided,
      maxWidth: "90%",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      display: "initial",
      position: 'static',
      padding: 0,
      margin: 0,
      marginLeft: 5,
      fontSize: 13,
      fontFamily: "Helvetica"
    }) : (base) => ({
      ...base,
      overflow: 'hidden',
      position: 'static',
      padding: 0,
      margin: 0,
      maxWidth: "80%",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      fontSize: 13,
      fontFamily: "Helvetica"
    }),
  };

  const [elementosFormatados, setElementosFormatados] = useState([]);
  const [valorSelecionado, setValorSelecionado] = useState(null);

  const filterConfig = {
    ignoreCase: true,
    ignoreAccents: true,
    trim: true,
    matchFrom: 'any'
  };

  const internalOnChange = (onChangeProps) => {
    if (onChangeProps) {
      setValorSelecionado(onChangeProps);
      onSelect && onSelect(onChangeProps.value);
    } else {
      setValorSelecionado(null);
      onSelect && onSelect(null);
    }

  };

  useEffect(() => {
    let tempArray = [];
    if (elementos) {
      elementos.forEach(item => {
        tempArray.push({ value: item.id, label: item[displayMember] });
      })
    }
    setElementosFormatados(tempArray);
  }, [elementos, displayMember])

  useEffect(() => {
    if (value) {
      elementosFormatados.forEach(item => {
        if (item?.value?.toString() === value?.toString()) {
          setValorSelecionado(item);
        }
      })
    } else {
      setValorSelecionado(null);
    }
  }, [elementosFormatados, value])


  return <>
    {props.label && <label className="small form-label">{props.label}</label>}
    <Select
      autoFocus={props.autoFocus}
      isDisabled={props.disabled}
      noOptionsMessage={() => "Nenhuma opção encontrada."}
      placeholder={props.placeholder ?? 'Selecione..'}
      value={valorSelecionado}
      onChange={internalOnChange}
      options={elementosFormatados}
      filterOption={createFilter(filterConfig)}
      isClearable={isClearable}
      isSearchable={true}
      styles={styles}
    />
  </>;
}

DropdownListWithValue.propTypes = {
  ...DropdownList.propTypes,
  value: PropTypes.any,
  maximoSelecoes: PropTypes.number,
}

DropdownListWithValue.defaultProps = {
  ...DropdownList.defaultProps,
  maximoSelecoes: null,
}

export default DropdownListWithValue
