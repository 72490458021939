import React from "react";
import PropTypes from "prop-types";
import FocusableButton from "./FocusableButton";

const PrimaryButton = ({titulo, onClick, disabled = false, ...props}) => {
  return (
    <>
      <FocusableButton
        onClick={disabled ? null : onClick}
        disabled={disabled}
        {...props}
      >
        {titulo}
      </FocusableButton>
    </>
  )
}

PrimaryButton.propTypes = {
  disabled: PropTypes.bool,
  titulo: PropTypes.string,
  onClick: PropTypes.func,
}

PrimaryButton.defaultProps = {
}

export default React.memo(PrimaryButton);
