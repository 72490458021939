import { useRef, useEffect } from 'react';
import { Scatter } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
import './GeraImagemDiagrama.css';

const GeraImagemDiagrama = ({ configuracaoDiagrama, ponto, mostrarGrid = false }) => {
  const {
    imagePath,
    widthImagem,
    heightImagem,
    marginTopGrafico,
    marginLeftGrafico,
    widthGrafico,
    heightGrafico,
    yMinimo,
    yMaximo,
  } = configuracaoDiagrama;
  Chart.register(...registerables);

  const divRef = useRef();
  const divDiagramaRef = useRef();

  useEffect(() => {
    const canvas1 = divRef.current;
    const context1 = canvas1.getContext('2d');
    const image = new Image();
    image.src = imagePath;

    image.onload = () => {
      context1.drawImage(image, 0, 0, widthImagem, heightImagem);
    };
  }, [imagePath, widthImagem, heightImagem]);

  const scatterData = {
    datasets: [
      {
        label: 'Pontos',
        data: ponto,
        pointRadius: 4,
        pointBackgroundColor: 'rgba(255, 0, 0, 1)',
        borderWidth: 0,
        borderColor: 'rgba(255, 0, 0, 1)',
        backgroundColor: 'rgba(255, 0, 0, 15)',
        showLine: false,
        pointHoverRadius: 4,
        pointHoverBackgroundColor: 'rgba(255, 0, 0, 1)',
      },
    ],
  };

  const scatterOptions = {
    plugins: {
      title: {
        display: false
      },
      legend: {
        display: false
      },
      tooltip: {
        enabled: false,
      },
    },
    scales: {
      x: {
        type: 'linear',
        position: 'bottom',
        min: -2,
        max: 102,
        color: 'rgba(0, 0, 255, 1)',
        grid: {
          display: mostrarGrid,
          borderColor: 'rgba(0, 0, 0, 0)',
        },
        ticks: {
          display: false,
        },
      },
      y: {
        type: 'linear',
        position: 'left',
        min: yMinimo,
        max: yMaximo,
        grid: {
          display: mostrarGrid,
          borderColor: 'rgba(0, 0, 0, 0)',
        },
        ticks: {
          display: false,
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
  };

  return (
    <>
      <div ref={divDiagramaRef} style={{ position: 'relative', width: `${widthImagem}px`, height: `${heightImagem + 10}px`, border: '0px solid black' }}>
        <canvas ref={divRef} style={{ position: 'absolute', top: '0', left: '0', zIndex: 1 }} width={widthImagem} height={heightImagem} />

        <div style={{ position: 'absolute', top: `${marginTopGrafico}px`, left: `${marginLeftGrafico}px`, zIndex: 2, width: `${widthGrafico}px`, height: `${heightGrafico}px` }}>
          <Scatter data={scatterData} options={scatterOptions} />
        </div>
      </div>

      <div style={{
        textAlign: 'right',
        justifyContent: 'flex-end',
        marginTop: '1rem',
        marginRight: '7px'
      }}>

      </div>
    </>
  );
};

export default GeraImagemDiagrama;
