import React, { Component, useCallback } from "react";
import Container from "react-bootstrap/Container";
import { connect } from "react-redux";
import { COLUNA_EXIBIR } from "../../util/constantes";
import JqxGrid, { jqx } from "jqwidgets-scripts/jqwidgets-react-tsx/jqxgrid";
import Form from "react-bootstrap/Form";
import PageScaffold from "../../components/scaffold/PageScaffold";
import { createControlColumn, criarBtnExcluirEstacao, LegacyTable, Table } from "../../components/Jqx/Table";
import { Checkbox } from "../../components/Jqx/Checkbox";
import converterVetorParaObjetoIndexado from "../../util/converterVetorParaObjetoIndexado";
import Service, { BASEGEO } from "../../service/Service";
import { Col, Row } from "react-bootstrap";
import Hint from "../../components/hint/Hint";
import DropdownListWithValue from "../../components/Jqx/DropdownList/DropdownListWithValue";
import AppMapComponent from "../../components/mapa/map_component/AppMapComponent";
import { geoJSONParaArcGIS } from "../../util/mapa";
import { adicionarCabecalhoAuthAoJqXHR } from "../../util/jqxUtils";
import { swalConfirmarExclusao } from "../../util/validadores";
import { FocusableButton } from "../../components/Jqx/Button";


import { configMapaEstacoes } from '../../config/mapa';
import Card from "../../components/card"
import { selecionarVisita } from "../../reducers/visitaReducer";
import BlockUi from "react-block-ui";
import { DropdownListField } from "../../components/field/DropdownListField";
import LegendaMapa from "./components/mapa/LegendaMapa";
import InfoPanelGPS from "../../components/infoPanelGPS";

import aflora_tabela from '../../assets/img/aflora_tabela.png';
import amostra_tabela from '../../assets/img/amostra_tabela.png';
import recmin_tabela from '../../assets/img/recmin_tabela.png';
// Nota: Antigamente tínhamos este dataFieldsEstacoes definido somente dentro
// do adaptadorTabelasBase, sem passar como prop ao Table adicionalmente. Por
// algum motivo que não sabemos, isso fazia a tabela algumas vezes ignorar o
// datafield do COLUNA_EXIBIR, e então o callback de editar não tinha acesso
// ao id.
const dataFieldsEstacoes = [
  { name: `id`, type: "string", map: "id" },
  { name: COLUNA_EXIBIR, type: "string", map: "id" },
  { name: "projeto", type: "string", map: "projeto" },
  {
    name: "nomeEstacao",
    type: "string",
    map: "nomeEstacao",
  },
  {
    name: "toponimia",
    type: "string",
    map: "toponimia",
  },
  {
    name: "apelido",
    type: "string",
    map: "apelido",
  },
  {
    name: "podeExcluir",
    type: "boolean",
    map: "podeExcluir",
  },
  {
    name: "numeroVisitas",
    type: "number",
    map: "numeroVisitas",
  },
  {
    name: "dataUltimaVisita",
    type: "date",
    map: "dataUltimaVisita",
  },
  {
    name: "qtdAflora",
    type: "number",
    map: "qtdAflora",
  },
  {
    name: "qtdAmostra",
    type: "number",
    map: "qtdAmostra",
  },
  {
    name: "qtdRecmin",
    type: "number",
    map: "qtdRecmin",
  },
  {
    name: "atividadesDesenvolvidas",
    type: "number",
    map: "atividadesDesenvolvidas",
  },
];

const mapDispatchToProps = (dispatch) => {
  return {
    selecionarVisita(visita) {
      dispatch(selecionarVisita(visita))
    },
  };
};
class Estacoes extends Component {


  constructor(props) {
    super(props);

    this.servicoProjetos = Service("/projetos");
    this.servicoAreas = Service("/areas");
    this.servicoEstacoes = Service("/estacoes");

    /** @type {React.RefObject<JqxGrid>} */
    this.estacoesGrid1 = React.createRef();
    /** @type {React.RefObject<JqxGrid>} */
    this.estacoesGrid2 = React.createRef();

    const tabelaEstacoesUrl = `${process.env.REACT_APP_API_URL}/estacoes`;
    this.tabelaEstacoesHistoricasUrl = `${process.env.REACT_APP_API_URL}/estacoesHistoricas`;

    const that = this;
    this.adaptadorTabelasBase = {
      id: "id",
      datatype: "json",
      url: tabelaEstacoesUrl,
      datafields: dataFieldsEstacoes,
      beforeSend: adicionarCabecalhoAuthAoJqXHR,
      formatData: function (data) {
        return {
          ...data,
          idArea: that.state.idAreaSelecionada,
          idSubArea: that.state.idSubAreaSelecionada,
        };
      },
      sortcolumn: 'dataUltimaVisita',
      sortdirection: 'desc',
    };

    this.opcoesSessao = JSON.parse(sessionStorage.getItem("localidade"));

    this.state = {
      carregamentoGlobal: false,
      carregamentoMapa: true,
      projetos: null,
      idProjetoSelecionado: null,
      areas: null,
      idAreaSelecionada: null,
      subAreas: null,
      idSubAreaSelecionada: null,
      nomeProjeto: null,
      nomeArea: null,
      nomeSubArea: null,
      estacoes: null,
      idEstacaoSelecionada: null,
      mostrarEstacoesHistoricas: false,
      mostrarTabelaEstacoes: false,
      mapaCarregado: false,
      dadosCarregados: true,
    };

    // Estes objetos serão passados para a tabela, para usar um json como source
    // ao invés de dados passados na forma de um array.
    this.extraSourceParameters1 = this.adaptadorTabelasBase;
    this.extraSourceParameters2 = {
      ...this.adaptadorTabelasBase,
      url: this.tabelaEstacoesHistoricasUrl,
    };

    this.idLinhaSelecionada = null;

    this.setState({
      carregamentoGlobal: true
    });

    this.columnsHistoricas = [
      createControlColumn({
        iconClasses: ["fas", "fa-edit", "text-warning"],
        dataField: COLUNA_EXIBIR,
        ref: this.estacoesGrid2,
        onClick: this.editarEstacao.bind(this),
      }),
      criarBtnExcluirEstacao({
        iconClasses: ["fas", "fa-trash", "text-danger"],
        dataField: "id",
        ref: this.estacoesGrid2,
        onClick: this.excluirEstacao.bind(this),
      }),
      { text: "Data da última visita", datafield: "dataUltimaVisita", cellsformat: 'dd/MM/yyyy', width: "34%",
        cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
          let data = '';

          if(value){
            const isoString = new Date(value).toISOString();
            data = `${isoString.slice(8,10)}/${isoString.slice(5,7)}/${isoString.slice(0,4)}`;
          }

        return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${data}</div>`;
      }},
      { text: "Código da estação", datafield: "nomeEstacao", width: "20%", },
      { text: "Nome da estação", datafield: "apelido", width: "20%", },
      { text: "Número de visitas", datafield: "numeroVisitas", width: "20%", },
    ]

    this.columns = [
      createControlColumn({
        iconClasses: ["fas", "fa-edit", "text-warning"],
        dataField: COLUNA_EXIBIR,
        ref: this.estacoesGrid1,
        onClick: this.editarEstacao.bind(this),
      }),
      criarBtnExcluirEstacao({
        iconClasses: ["fas", "fa-trash", "text-danger"],
        dataField: "id",
        ref: this.estacoesGrid1,
        onClick: this.excluirEstacao.bind(this),
      }),

      { text: "Código da estação", datafield: "nomeEstacao", width: "22%", },
      { text: "Nome da estação", datafield: "apelido", width: "22%", },
      { text: "Número de visitas", datafield: "numeroVisitas", width: "18%", },
      { text: "Data da última visita", datafield: "dataUltimaVisita", cellsformat: 'dd/MM/yyyy', width: "15%",
      cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
        let data = '';
        if(value){
          const isoString = new Date(value).toISOString();
          data = `${isoString.slice(8,10)}/${isoString.slice(5,7)}/${isoString.slice(0,4)}`;
        }

      return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${data}</div>`;
    }},
    { text: "Atividades desenvolvidas", datafield: "atividadesDesenvolvidas", width: "17%", "filterable": false,
      cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
        let tela = "";
        if(rowdata.qtdAmostra > 0){
          tela = tela + `<img style="height: 30px;width: 30px;" title="Cadastros de  Amostras" src="${amostra_tabela}" /><span style="color:red; margin-right:10px;">${rowdata.qtdAmostra}</span>`
        }
        if(rowdata.qtdAflora > 0){
          tela = tela + `<img style="height: 30px;width: 30px" title="Cadastros de Afloramentos" src="${aflora_tabela}" /><span style="color:red; margin-right:10px;">${rowdata.qtdAflora}</span>`
        }
        if(rowdata.qtdRecmin > 0){
          tela = tela + `<img style="height: 30px;width: 30px;" title="Cadastros de Recursos Minerais" src="${recmin_tabela}" /><span style="color:red; margin-right:10px;">${rowdata.qtdRecmin}</span>`
        }

      return `<div class="jqx-grid-cell-left-align" style="margin-top: 2px;">${tela}</div>`;
    }},
      // createControlColumn({
      //   iconClasses: ["fas", "fa-leaf", "text-secondary"],
      //   dataField: "atividades",
      //   ref: this.estacoesGrid1,
      //   onClick: this.visualizar.bind(this)
      // }),
    ];

    this.geometryFilter = null;

    this.filtrarMapa = this.filtrarMapa.bind(this);
    this.onMapaCarregado = this.onMapaCarregado.bind(this);
    this.onFiltrar = this.onFiltrar.bind(this);
    this.onSelecaoEstacaoMudar = this.onSelecaoEstacaoMudar.bind(this);
    this.selecionarLinhaTabela = this.selecionarLinhaTabela.bind(this);
  }

  async componentDidMount() {
    localStorage.removeItem('FORM_DEFINICAO_ESTACAO');
    localStorage.removeItem('LISTA_PONTOS');
    localStorage.setItem('LAST_PAGE', 'estacoes');
    this.carregarProjetos();
  }

  componentWillUnmount() { }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // Atualiza this.geometryFilter e, se o mapa estiver carregado, envia o filtro
    this.filtrarMapa(prevState);
  }

  // **************************************************************************

  // Recuperar geometria da área ou subárea e atualizar mapa
  filtrarMapa(prevState) {
    // Ignora alterações de estado não importantes para a filtragem
    if (
      prevState.idProjetoSelecionado === this.state.idProjetoSelecionado &&
      prevState.idAreaSelecionada === this.state.idAreaSelecionada &&
      prevState.idSubAreaSelecionada === this.state.idSubAreaSelecionada &&
      prevState.mostrarEstacoesHistoricas ===
      this.state.mostrarEstacoesHistoricas
      // prevState.mapaCarregado === this.state.mapaCarregado
    ) {
      return;
    }

    if (
      this.state.idAreaSelecionada &&
      this.state.areasPorId[this.state.idAreaSelecionada]
    ) {
      const area = this.state.areasPorId[this.state.idAreaSelecionada];
      if (area) {
        const geometryFilterAtual = this.geometryFilter;
        if (this.state.idSubAreaSelecionada) {
          const subarea =
            this.state.subAreasPorId[this.state.idSubAreaSelecionada];
          if (subarea && subarea.geoJSON) {
            this.geometryFilter = geoJSONParaArcGIS(subarea.geoJSON);
          } else {
            this.geometryFilter = geoJSONParaArcGIS(area.geoJSON);
          }
        } else {
          this.geometryFilter = geoJSONParaArcGIS(area.geoJSON);
        }

        // Não enviar mensagem caso os parâmetros sejam os mesmos enviados anteriormente
        if (
          geometryFilterAtual === this.geometryFilter &&
          prevState.idProjetoSelecionado === this.state.idProjetoSelecionado &&
          prevState.mostrarEstacoesHistoricas ===
          this.state.mostrarEstacoesHistoricas
        ) {
          return;
        }

        if (this.state.mapaCarregado) {
          this.mapa.limparSelecaoMapa();
          this.mapa.filtrar(
            this.state.idProjetoSelecionado,
            this.geometryFilter,
            this.state.mostrarEstacoesHistoricas,
            true
          );
        }
      }
    } else {
      // Projeto não possui áreas ou usuário não possui acesso a nenhuma área no projeto
      if (this.state.mapaCarregado) {
        this.mapa.limparPontos(true);
        this.mapa.limparSelecaoMapa();
        this.mapa.filtrar(
          this.state.idProjetoSelecionado,
          null,
          this.state.mostrarEstacoesHistoricas,
          true
        );
      }
    }
  }

  onMapaCarregado() {
    this.carregamentoMapa = false;
    this.setState({ mapaCarregado: true });

    // Geralmente o projeto/area/subarea carregam e são selecionados automaticamente antes do mapa,
    // então quando o mapa carregar, utilizamos os valores atuais para filtragem
    if (this.state.dadosCarregados && this.state.idAreaSelecionada) {
      this.mapa.limparPontos(true);
      this.mapa.filtrar(
        this.state.idProjetoSelecionado,
        this.geometryFilter,
        this.state.mostrarEstacoesHistoricas,
        true
      );
    }
  }

  onFiltrar() {
    // Se isso não é chamado após filtrar, o filtro anterior continua no mapa
    this.mapa.limparPontos();
  }

  // **************************************************************************

  isProjetosCarregados = () => {
    return null != this.state.projetos;
  };

  mostrarAreas = () => {
    return null != this.state.areas && this.state.areas.length > 0;
  };

  mostrarSubAreas = () => {
    return null != this.state.subAreas && this.state.subAreas.length > 0;
  };

  mostrarTabelasDeEstacao = () => {
    // console.debug('Mostrar?', this.state.mostrarTabelaEstacoes);
    return this.state.mostrarTabelaEstacoes;
  };

  // **************************************************************************

  carregarProjetos = async (_) => {
    const projetosCarregados = await this.servicoProjetos.listar();
    this.setState(
      {
        projetos: projetosCarregados.data.dados,
        projetosPorId: converterVetorParaObjetoIndexado(
          projetosCarregados.data.dados
        ),
      },
      () => {
        if (this.opcoesSessao) {
          this.selecionarProjeto({
            idProjetoSelecionado: this.opcoesSessao.idProjeto,
          });
        }
      }
    );
    // Se já existe um projeto selecionado por padrão (quando o usuário já acessou o
    // sistema alguma vez e selecionou um projeto), carrega as áreas do projeto.
    // if (-1 !== this.state.indiceProjetoSelecionado) {
    //   this.sepro({
    //     execucaoAutomatica: true,
    //     args: {
    //       item: {
    //         index: this.state.indiceProjetoSelecionado,
    //       },
    //     },
    //   });
    // }
  };

  selecionarProjeto = async (evt) => {
    this.setState({
      carregamentoGlobal: true
    });
    this.setState({
      dadosCarregados: false,
    });
    const that = this;
    if (evt.idProjetoSelecionado !== this.state.idProjetoSelecionado) {
      const idProjetoSelecionado = evt.idProjetoSelecionado;


      if (null != idProjetoSelecionado && !isNaN(idProjetoSelecionado)) {
        this.setState({
          carregamentoGlobal: true
        });
        const respostaAreas = await this.servicoProjetos.customURL(
          "GET",
          `/${idProjetoSelecionado}/areas`
        );
        const areas = respostaAreas.data.dados || [];

        if (areas) {
          this.setState({
            carregamentoGlobal: true
          });
          this.setState(
            {
              areas,
              areasPorId: converterVetorParaObjetoIndexado(areas),

              subAreas: null,
              subAreasPorId: null,
              mostrarTabelaEstacoes: false,

              idProjetoSelecionado: evt.idProjetoSelecionado,
              idAreaSelecionada: null,
              idSubAreaSelecionada: null,
              dadosCarregados: false,
            },
            () => {
              if (areas.length >= 1) {
                const idAreaInicial =
                  this.opcoesSessao &&
                    this.state.idProjetoSelecionado ===
                    this.opcoesSessao.idProjeto
                    ? this.opcoesSessao.idArea
                    : areas[0].id; // Sessão ou primeira da lista
                that.selecionarArea({
                  idAreaSelecionada: idAreaInicial,
                });
              } else {
                this.setState({
                  dadosCarregados: true,
                });
              }
            }
          );
        }
      }
    }

    this.setState({
      carregamentoGlobal: false
    });
  };

  selecionarArea = async (evt) => {
    this.setState({
      dadosCarregados: false,
    });
    const that = this;
    if (evt.idAreaSelecionada !== this.state.idAreaSelecionada) {
      const idAreaSelecionada = evt.idAreaSelecionada;

      if (null != idAreaSelecionada && !isNaN(idAreaSelecionada)) {
        const respostaSubAreas = await this.servicoProjetos.customURL(
          "GET",
          `/${this.state.idProjetoSelecionado}/subAreas`
        );
        const subAreas = respostaSubAreas.data.dados;

        if (subAreas) {
          // esperarAteTabelaDisponivel(this.estacoesGrid1.current?.tableRef, () => {
          // })
          this.setState(
            {
              mostrarTabelaEstacoes: false,
              subAreas,
              subAreasPorId: converterVetorParaObjetoIndexado(subAreas),
              idAreaSelecionada: idAreaSelecionada,
              idSubAreaSelecionada: null,
            },
            () => {
              if (subAreas.length === 0) {
                that.setState({
                  mostrarTabelaEstacoes: true,
                  dadosCarregados: true,
                  mostrarEstacoesHistoricas:
                    this.opcoesSessao?.mostrarEstacoesHistoricas || false,
                });
              } else if (subAreas.length >= 1) {
                const idSubAreaInicial =
                  this.opcoesSessao &&
                    this.state.idProjetoSelecionado ===
                    this.opcoesSessao.idProjeto
                    ? this.opcoesSessao.idSubArea
                    : subAreas[0].id; // Sessão ou primeira da lista
                that.selecionarSubArea({
                  idSubAreaSelecionada: idSubAreaInicial,
                });
              }
            }
          );
        }
      }
    }
  };

  selecionarSubArea = async (evt) => {
    // const that = this;
    if (evt.idSubAreaSelecionada !== this.idSubAreaSelecionada) {
      const idSubAreaSelecionada = evt.idSubAreaSelecionada;
      this.setState(
        {
          mostrarTabelaEstacoes: false,
          idSubAreaSelecionada,
        },
        () => {
          this.setState({
            mostrarTabelaEstacoes: true,
            dadosCarregados: true,
            mostrarEstacoesHistoricas:
              this.opcoesSessao?.mostrarEstacoesHistoricas || false,
          });
        }
      );
    }
  };

  montarEstadoDeLocalidade() {
    const nomeSubArea =
      null != this.state.idSubAreaSelecionada
        ? this.state.subAreasPorId[this.state.idSubAreaSelecionada].nome
        : "";
    const geometriaProjeto = this.state.idSubAreaSelecionada
      ? this.state.subAreasPorId[this.state.idSubAreaSelecionada]
      : this.state.areasPorId[this.state.idAreaSelecionada];

    const localidade = {
      nomeProjeto:
        this.state.projetosPorId[this.state.idProjetoSelecionado].nome,
      nomeArea: this.state.areasPorId[this.state.idAreaSelecionada].nome,
      nomeSubArea,
      idProjeto: this.state.idProjetoSelecionado,
      idArea: this.state.idAreaSelecionada,
      idSubArea: this.state.idSubAreaSelecionada,
      hemisferio: geometriaProjeto.hemisferio, // Norte/Sul
      hemisferioHorizontal: geometriaProjeto.hemisferioHorizontal, // Leste/Oeste
      geometryFilter: this.geometryFilter,
      validadorProjeto:this.state.projetosPorId[this.state.idProjetoSelecionado].validador,
      revisorProjeto:this.state.projetosPorId[this.state.idProjetoSelecionado].revisor,
      publicadorProjeto:this.state.projetosPorId[this.state.idProjetoSelecionado].publicador,
    };

    // Armazenar opções selecionadas em sessão para serem lidas ao voltar nessa página
    sessionStorage.setItem(
      "localidade",
      JSON.stringify({
        idProjeto: localidade.idProjeto,
        idArea: localidade.idArea,
        idSubArea: localidade.idSubArea,
        nomeProjeto: localidade.nomeProjeto,
        nomeArea: localidade.nomeArea,
        nomeSubArea: localidade.nomeSubArea,
        mostrarEstacoesHistoricas: this.state.mostrarEstacoesHistoricas,
        validadorProjeto:localidade.validadorProjeto,
        revisorProjeto:localidade.revisorProjeto,
        publicadorProjeto:localidade.publicadorProjeto,
      })
    );


    this.props.selecionarVisita({
      nomeProjeto: localidade.nomeProjeto,
      area: localidade.nomeArea,
      subArea: localidade.nomeSubArea,
    })


    return localidade;
  }

  redirecionar = (caminho) => {
    this.props.history.push({
      pathname: caminho,
      state: this.montarEstadoDeLocalidade(),
    });
  };

  visualizar = (id) => {
    const estado = this.montarEstadoDeLocalidade();
    estado.idEstacaoSelecionada = id;
    estado.permitirEdicao = false;
    console.log("Visualizar estação - state:", estado);

    this.props.history.push({
      pathname: "estacao",
      state: estado,
    });
  };


  async excluirEstacao(id) {
    const confirmarExclusao = await swalConfirmarExclusao();

    if (!confirmarExclusao) return;

    try {
      this.mapa.limparPontos(true);
      await this.servicoEstacoes.delete(id);
      this.mapa.sendInitialize();
    }
    catch (err) {
      console.log('\n Erro ao excluir estação', err);
    }

    if (!this.state.idSubAreaSelecionada)
      this.selecionarArea({ idAreaSelecionada: this.state.idAreaSelecionada });

    else
      this.selecionarSubArea({ idSubAreaSelecionada: this.state.idSubAreaSelecionada });
  }

  editarEstacao(id) {
    const estado = this.montarEstadoDeLocalidade();
    estado.nomeEstacao = '';
    estado.idEstacaoSelecionada = id;
    estado.permitirEdicao = true;
    // console.log('Editar estação - state:', estado)

    localStorage.setItem("ID_ESTACAO_SELECIONADA", id);
    sessionStorage.setItem("ID_ESTACAO_SELECIONAD", id);

    this.props.history.push({
      pathname: "estacao",
      state: estado,
    });
  }

  grid1EstacoesExibido = () => {
    this.estacoesGrid1.current &&
      this.estacoesGrid1.current.gotopage(this.state.paginaSelecioada);
  };

  clicadoMostrarEstacoesHistoricas = (evt) => {
    this.setState((estadoAtual) => ({
      mostrarEstacoesHistoricas: !estadoAtual.mostrarEstacoesHistoricas,
    }));
    console.debug(
      "Exibir estações históricas:",
      this.state.mostrarEstacoesHistoricas
    );
  };

  isMostrarEstacoesHistoricas = () => {
    return (
      this.state.mostrarTabelaEstacoes && this.state.mostrarEstacoesHistoricas
    );
  };

  temProjetoSelecionado = () => {
    return this.state.idProjetoSelecionado && this.state.idProjetoSelecionado !== null;
  };

  async selecionarProjetoViaDropdown(valor) {
    this.setState({
      carregamentoGlobal: true
    });
    if (valor === null) {
      this.setState({
        idProjetoSelecionado: null,
        idAreaSelecionada: null,
        idSubAreaSelecionada: null,
        areas: null,
        subAreas: null,
        mostrarTabelaEstacoes: null
      })
    } else {
      if (
        this.state.idProjetoSelecionado !== valor &&
        this.state.dadosCarregados
      ) {
        await this.selecionarProjeto({
          idProjetoSelecionado: valor,
        });
      }
    }

    this.setState({
      carregamentoGlobal: false
    });
  }

  selecionarAreaViaDropdown(valor) {
    if (this.state.idAreaSelecionada !== valor && this.state.dadosCarregados) {
      this.selecionarArea({
        idAreaSelecionada: valor,
      });
    }
  }

  selecionarSubAreaViaDropdown(valor) {
    if (
      this.state.idSubAreaSelecionada !== valor &&
      this.state.dadosCarregados
    ) {
      this.selecionarSubArea({
        idSubAreaSelecionada: valor,
      });
    }
  }

  selecionarLinhaTabela(evt) {
    const id = evt.args.row?.id;
    if (!id || id === this.idLinhaSelecionada) return 0;
    if (evt.type === "rowselect") {
      if (this.state.mapaCarregado) {
        this.idLinhaSelecionada = id;
        this.mapa.selecionarEstacao(id);
      }
    }
  }

  onSelecaoEstacaoMudar(id) {
    if (id === this.idLinhaSelecionada) return 0;
    this.idLinhaSelecionada = id;

    if (this.estacoesGrid1?.current) {
      //Pega o tamanho da paginação
      const rowsPerPage = this.estacoesGrid1.current.getpaginginformation()?.pagesize
      // Obtém os dados de todas as linhas no grid
      const rowData = this.estacoesGrid1?.current?.getrows();
      // Encontra o índice da linha com base no ID
      const rowIndex = rowData?.findIndex(row => row.id === id);
      // Calcula o índice da página usando o índice da linha e o número de linhas por página
      const pageIndex = Math.floor(rowIndex / rowsPerPage);
      if (pageIndex) {
        this.estacoesGrid1.current.gotopage(pageIndex);
      }
      this.estacoesGrid1.current.clearselection();
      const row_grid1 = this.estacoesGrid1.current
        .getrows()
        .find((r) => r.id === id);
      if (row_grid1) {
        const boundIndex = row_grid1.boundindex;
        this.estacoesGrid1.current.selectrow(boundIndex);
      }
    }

    if (this.estacoesGrid2?.current) {
      //Pega o tamanho da paginação
      const rowsPerPageHistorica = this.estacoesGrid2.current.getpaginginformation()?.pagesize
      // Obtém os dados de todas as linhas no grid
      const rowDataHistorica = this.estacoesGrid2?.current?.getrows();
      // Encontra o índice da linha com base no ID
      const rowIndexHistorica = rowDataHistorica?.findIndex(row => row.id === id);
      // Calcula o índice da página usando o índice da linha e o número de linhas por página
      const pageIndexHistorica = Math.floor(rowIndexHistorica / rowsPerPageHistorica);
      if (pageIndexHistorica) {
        this.estacoesGrid2.current.gotopage(pageIndexHistorica);
      }

      this.estacoesGrid2.current.clearselection();
      const row_grid2 = this.estacoesGrid2.current
        .getrows()
        .find((r) => r.id === id);
      if (row_grid2) {
        const boundIndex = row_grid2.boundindex;
        this.estacoesGrid2.current.selectrow(boundIndex);
      }
    }
  }

  render() {
    return (
      <BlockUi blocking={this.state.carregamentoGlobal || this.carregamentoMapa}>
        <PageScaffold titulo="Atividade de campo" disableInfo>
          <Container>
          <InfoPanelGPS />
            <Card>
              <Card.Body>
                {this.mostrarTabelasDeEstacao() && (
                  <>
                    <Table
                      style={{ marginTop: "2.5em" }}
                      // Normalmente só precisaríamos passar o objeto de
                      // estacoesGrid como prop que ele seria setado automaticamente,
                      // mas precisamos que esse objeto seja um ref de JqxGrid
                      // porque ele é usado no array de colunas. O Table nos
                      // entrega um ref de Table, que contém um tableRef que é
                      // um ref de JqxGrid.
                      ref={ref => {
                        if (ref) {
                          this.estacoesGrid1.current = ref.tableRef.current;
                        }
                      }}
                      extraSourceParameters={this.extraSourceParameters1}
                      datafields={dataFieldsEstacoes}
                      columns={this.columns}
                      onRowSelectChanged={this.selecionarLinhaTabela}
                    // onRowdoubleclick={(evt) => this.visualizar(evt.args.row.bounddata.uid)}
                    />

                    <Row className="mt-4">
                    <Col md={12} >
                      <Col md={3} className="float-right">
                      <FocusableButton
                        className="float-right m-2"
                        onClick={() => this.redirecionar("index")}
                        >
                        Voltar
                      </FocusableButton>
                      </Col>
                      <Col md={7} className="float-right">
                        <FocusableButton
                          className="float-right m-2"
                          onClick={() => this.redirecionar("gps")}
                        >
                          Subir GPS
                        </FocusableButton>

                        <FocusableButton
                          className="float-right m-2"
                          onClick={() => this.redirecionar("/coordenadas")}
                        >
                          Novo cadastro
                        </FocusableButton>
                      </Col>
                      </Col>
                  </Row>
                  </>
                )}

                {this.mostrarTabelasDeEstacao() && (
                  <>
                    <div className="clearfix" />
                    <hr />
                    <Checkbox
                      label="Mostrar estações históricas"
                      dica="Selecione para ver estações de outros projetos na área desse projeto"
                      // disabled={true}
                      onChange={this.clicadoMostrarEstacoesHistoricas}
                      checked={this.state.mostrarEstacoesHistoricas}
                    />
                  </>
                )}
                {this.isMostrarEstacoesHistoricas() && (
                  <Table
                    ref={ref => {
                      if (ref) {
                        this.estacoesGrid2.current = ref.tableRef.current;
                      }
                    }}
                    extraSourceParameters={this.extraSourceParameters2}
                    datafields={dataFieldsEstacoes}
                    columns={this.columnsHistoricas}
                    style={{ marginTop: "1rem" }}
                    onRowSelectChanged={this.selecionarLinhaTabela}
                  // onRowdoubleclick={(evt) => this.visualizar(evt.args.row.bounddata.uid)}
                  // onCellclick={this.editarEstacao}
                  />
                )}
              </Card.Body>
            </Card>
            <hr />
            <Card>
              <Card.Body>
                <LegendaMapa
                  exibirEstacao={true}
                  exibirEstacaoHistorica={this.isMostrarEstacoesHistoricas()}
                  exibirEstacaoEditada={false}
                  exibirAtividade={false}
                  exibirPontoGPS={false}
                  exibirGeometriaProjeto={this.temProjetoSelecionado()}
                />


                <AppMapComponent
                  ref={(instance) => {
                    this.mapa = instance;
                  }}
                  config={configMapaEstacoes}
                  onCarregarMapa={this.onMapaCarregado}
                  onFiltrar={this.onFiltrar}
                  debugMensagens={!!process.env.REACT_APP_MAP_DEBUG}
                  onSelecaoEstacaoMudar={this.onSelecaoEstacaoMudar}

                // onSelecionarEstacao={params => this.aoSelecionarEstacaoMapa(params, form)}
                // onCriarNovaEstacao={params => this.aoCriarNovaEstacao(params, form)}
                />
              </Card.Body>
            </Card>
          </Container>
        </PageScaffold>
      </BlockUi>
    );
  }
}

export default connect(null, mapDispatchToProps)(Estacoes)
