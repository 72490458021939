import React, { useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";
import FileCard from "../Card";

import "./FileGrid.css"
import { connect } from "react-redux";
import { adicionarEEnviarArquivo, carregarArquivoPorId, removerArquivo } from "../../../reducers/fileReducers";
import { mostrarNotificacao } from "../../../reducers/notificacaoReducer";
import { useDispatch } from "react-redux";

// https://css-tricks.com/drag-and-drop-file-uploading/
// http://tympanus.net/codrops/2015/09/15/styling-customizing-file-inputs-smart-way/
// icones https://fontawesome.com/icons/file-word
// referencia https://uppy.io/examples/dashboard/
// vou precisar de uma lib de dnd? https://react-dnd.github.io/react-dnd/about

function FileGrid({nome, permitirUpload, arquivos = [], adicionarEnviarArquivo, onExcluir, onAdicionar = () => {},
                  removerDoEstado, carregarArquivos, onClickImagem, bloquearTipos,  permitirImagens, permitirAudios,
                  permitirVideos, tiposPermitidos, permitirExclusao, renderAfter, permitirZip, permitirRar,limiteArquivos,
                  preencherNomes = () => {}, nomeExibicaoArquivoGetter = () => () => null}) {

    const dispatch = useDispatch();

    useEffect(() => {
        carregarArquivos();
        preencherNomes(arquivos);
    }, [arquivos, carregarArquivos, preencherNomes]);


    const onDrop = useCallback(acceptedFiles => {

        if(acceptedFiles.length > limiteArquivos){
            acceptedFiles = acceptedFiles.slice(0,limiteArquivos)
        }
        acceptedFiles.forEach((file) => {

            const tipo = file.type
            ? file.type.split("/")[1]
            : file.name.substring(file.name.lastIndexOf(".")+1);

            if(tipo === 'gpx'){
                const fr = new FileReader();

                fr.addEventListener('load', function(e) {
                    const xmlString = e?.target?.result;

                    const domParser = new DOMParser();
                    const dom = domParser.parseFromString(xmlString, 'text/xml');

                    if(dom.documentElement.outerHTML.includes('parsererror')){
                        dispatch(mostrarNotificacao(`Não foi possível carregar o arquivo ${file.name} devido a um erro de mal formatação.`, { tipo: 'error' }, true));
                    }
                    else{
                        adicionarEnviarArquivo(file)
                        .then((res) => {
                            onAdicionar(res)
                        })
                        .catch(console.error)
                    }
                });

                fr.readAsText(file);
            }
            else {
                adicionarEnviarArquivo(file)
                .then((res) => {
                    onAdicionar(res)
                })
                .catch(console.error)
            }
        })
    }, [adicionarEnviarArquivo, limiteArquivos, onAdicionar]);

    let accept = null;

    if (bloquearTipos) {
        accept = [ ...tiposPermitidos ];

        if (permitirImagens) accept.push('image/*');
        if (permitirAudios)  accept.push('audio/*');
        if (permitirVideos)  accept.push('video/*');
        if (permitirRar) {
            accept.push("application/x-rar-compressed")
            accept.push("application/vnd.rar")
        }
        if (permitirZip) {
            accept.push("application/x-zip-compressed")
            accept.push("application/zip")
            accept.push("multipart/x-zip")
        }
    }

    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop, accept});

    const gridProps = permitirUpload
        ? getRootProps()
        : {};

    const inputProps = permitirUpload
        ? getInputProps()
        : {};


    const onExcluirEvent = (hash) => {
        removerDoEstado(hash);
        onExcluir(hash);
    };

    return <div className="file-grid">
        {permitirUpload &&  arquivos.length < limiteArquivos && <input type="file" name="files[]" {...inputProps} />}
        <div className="file-grid__drop-zone" {...gridProps}>
            {permitirUpload && arquivos.length < limiteArquivos &&
                <div className="file-grid__instructions">
                    {isDragActive
                        ? <p>
                            Solte o arquivo aqui...
                            <br></br>
                            { tiposPermitidos && tiposPermitidos.length ? `Formatos suportados: ${tiposPermitidos.join().toString().replaceAll(',',' ')}` : ''}
                          </p>
                        : <p>
                            Arraste um arquivo ou clique para adicionar
                            <br></br>
                            { tiposPermitidos && tiposPermitidos.length ? `Formatos suportados: ${tiposPermitidos.join().toString().replaceAll(',',' ')}` : ''}
                        </p>
                    }
                </div>
            }
            <div className="file-grid__files">
                {arquivos.map((arq, i) => {
                    if(i < limiteArquivos){
                        return (
                            <FileCard
                                key={i}
                                nomeGrid={nome}
                                onExcluir={onExcluirEvent}
                                onClickImagem={() => { onClickImagem(i, arquivos) }}
                                chave={arq[0]}
                                {...arq[1]}
                                mostrarBotaoExclusao={permitirExclusao}
                                nomeExibicaoArquivoGetter={nomeExibicaoArquivoGetter(arq[1], i + 1)}
                            />
                        );
                    } else return null;
                })}
            </div>
            { renderAfter && arquivos.length < limiteArquivos && <div className="file-grid__instructions">
                {renderAfter()}
            </div> }
        </div>
    </div>
}

FileGrid.propTypes = {
    nome: PropTypes.string.isRequired,
    idsArquivosExistentes: PropTypes.arrayOf(PropTypes.number).isRequired,
    permitirUpload: PropTypes.bool,
    permitirExclusao: PropTypes.bool,

    onExcluir: PropTypes.func,
    onAdicionar: PropTypes.func,
    onClickImagem: PropTypes.func,

    nomeExibicaoArquivoGetter: PropTypes.func,

    // Render props
    renderAfter: PropTypes.func,

    // Tipos
    bloquearTipos: PropTypes.bool,
    permitirImagens: PropTypes.bool,
    permitirAudios: PropTypes.bool,
    permitirVideos: PropTypes.bool,
    permitirZip: PropTypes.bool,
    permitirRar: PropTypes.bool,
    tiposPermitidos: PropTypes.arrayOf(PropTypes.string),

    // Redux
    adicionarEnviarArquivo: PropTypes.func,
    arquivos: PropTypes.array,
    carregarArquivos: PropTypes.func,
    removerDoEstado: PropTypes.func,
    limiteArquivos: PropTypes.number,
};

FileGrid.defaultProps = {
    permitirUpload: true,
    permitirExclusao: true,

    // Tipos
    bloquearTipos: false,
    permitirImagens: false,
    permitirAudios: false,
    permitirVideos: false,
    permitirZip: false,
    permitirRar: false,
    limiteArquivos: Number.MAX_VALUE,
    tiposPermitidos: [],
};

export default connect(
    ({ files }, { nome }) => {
        const arquivos = [];
        for (const [key, value] of Object.entries(files[nome] || {})) {
            if (value && !value.deletado) arquivos.push([key, value]);
        }

        const arquivosExistentes = arquivos.filter((arquivoObjetoChave) => (
            ![null, undefined].includes(arquivoObjetoChave[1]?.nomeExibicao) && arquivoObjetoChave[1]?.nomeExibicao?.length > 0
        ))

        const arquivosNovos = arquivos.filter((arquivoObjetoChave) => (
            [null, undefined].includes(arquivoObjetoChave[1]?.nomeExibicao) || arquivoObjetoChave[1]?.nomeExibicao?.length === 0
        ))

        const arquivosExistentesOrdenadosByNomeExibicao = arquivosExistentes.slice()
            .sort((arquivoObjetoChaveA, arquivoObjetoChaveB) => {
                const arquivoA = arquivoObjetoChaveA[1];
                const arquivoB = arquivoObjetoChaveB[1];
                return arquivoA?.nomeExibicao?.localeCompare(arquivoB?.nomeExibicao);
            });

        const arquivosAtuaisOrdenados = [
            ...arquivosExistentesOrdenadosByNomeExibicao,
            ...arquivosNovos
        ];

        return { arquivos: arquivosAtuaisOrdenados }
    },
    (dispatch, { nome, idsArquivosExistentes }) => ({
        adicionarEnviarArquivo: (file) => dispatch(adicionarEEnviarArquivo({
            store: nome,
            file,
        })),
        removerDoEstado: (chave) => {
            dispatch(removerArquivo({
                key: chave,
                store: nome,
            }))
        },
        carregarArquivos: () => {
            return (idsArquivosExistentes||[]).map(id => {
                return dispatch(carregarArquivoPorId({
                    store: nome,
                    id,
                }));
            });
        },
        dispatch,
    })
)(FileGrid);
