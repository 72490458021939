import React, { useEffect, useRef, useState } from "react";
import Card from "../../../../components/card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { SubForm } from "../../../../components/SubForm";
import { Field, useForm } from "react-final-form";
import { DropdownListField } from "../../../../components/field/DropdownListField";
import { campoObrigatorioComMsgGenerica, possuiRegistros, validarSe } from "../../../../util/validadores";
import Atitudes from "./Atitudes";
import { TextAreaField } from "../../../../components/field/TextAreaField";
import { getPropsPermissao } from "../../../../components/SubForm/SubForm";
import { CounterField } from "../../../../components/field/CounterField";

const campos = [
  { name: "idsRochas", type: "array", map: "idsRochas" },
  { name: "idTipo", type: "string", map: "idTipo" },
  { name: "hierarquia", type: "string", map: "hierarquia" },
  { name: "informacoesRelevantes", type: "text", map: "informacoesRelevantes" },
]

const possuiAtitudes = possuiRegistros(valores => valores?.atitudes,
  { min: 'Estruturas tectônicas devem possuir ao menos uma atitude' });

const EstruturasTectonicas = ({
  nome,
  onSubmit,
  // elementos,
  valoresIniciais,
  permitirEdicao,
  modoDefinitivoSelecionado,
  rochas,
  rochasPorId,
  tiposEstruturaTectonica = [],
  hierarquias = [],
  tiposNotacao = [],
  formAtividade,
  estTectonicasComErro
}) => {
  // These refs are for remembering the previous values of each array that's a
  // dependency of the columns array. We only recalculate the columns array when
  // there's an actual change in the arrays.
  const rochasPorIdRef = useRef(rochasPorId);
  const hierarquiasRef = useRef(hierarquias);
  const tiposEstruturaTectonicaRef = useRef(tiposEstruturaTectonica);
  const [colunasEstruturasTectonicas, setColunasEstruturasTectonicas] = useState(gerarColunasEstruturasTectonicas(rochasPorId, hierarquias, tiposEstruturaTectonica));

  useEffect(() => {
    // Only update the columns when at least one of the dependencies has really changed.
    // Otherwise, avoid changing the columns because that causes a table rerender.
    // (The *correct* thing to do would be including the names in the objects themselves (add extra fields)
    // and always recalculate those names when dependencies change, completely removing the need for
    // a cellsrenderer.)
    // Compare the dependencies by value.
    if (JSON.stringify(rochasPorIdRef.current) !== JSON.stringify(rochasPorId)
      || JSON.stringify(hierarquiasRef.current) !== JSON.stringify(hierarquias)
      || JSON.stringify(tiposEstruturaTectonicaRef.current) !== JSON.stringify(tiposEstruturaTectonica)) {
      setColunasEstruturasTectonicas(gerarColunasEstruturasTectonicas(rochasPorId, hierarquias, tiposEstruturaTectonica));
      rochasPorIdRef.current = rochasPorId;
      hierarquiasRef.current = hierarquias;
      tiposEstruturaTectonicaRef.current = tiposEstruturaTectonica;
    }
  }, [rochasPorId, hierarquias, tiposEstruturaTectonica])

  // Ref to an initial state. This is updated on every render with the current form
  // state, in order to recover the state when another SubForm is submitted. Normally,
  // submitting any other form resets the state here, because changing fields within
  // this card does not update the state for the entire form.
  // This needs to be a ref so that we can change its value without causing additional
  // renders. It'll be used when the SubForm normally renders.
  const valoresIniciaisRef = useRef(valoresIniciais);

  return (
    <div style={{ marginTop: '2.5rem' }}>
      <Card className="mt-3">
        <Card.Body>
        {estTectonicasComErro && <span style={{ color: "red" }}>Existem estruturas tectônicas com campos obrigatórios não preenchidos</span>}
          <Card.Title>Estruturas tectônicas</Card.Title>
          <Field name={nome} subscription={{ value: true }}>
            {({ input: { value: estruturasTectonicas = {} } }) => (
              <SubForm
                nome={nome}
                onSubmit={(params) => {
                  // Reset the initial values so that it doesn't get automatically
                  // recovered on the next render.
                  // It would be better to check if the submit was successful before
                  // resetting this ref, but it doesn't seem like that's possible.
                  valoresIniciaisRef.current = null;
                  onSubmit(params);
                }}
                elementos={estruturasTectonicas} // elementos
                campos={campos}
                colunas={colunasEstruturasTectonicas}
                // Using a subscription here is more efficient because it avoids rerenders,
                // but there's code running inside the SubForm's render to update a ref to
                // the initial state.
                // formSubscription={{}} <- Efficient but breaks our code :(

                // The ref of initial values will have a value only if this form was
                // submitted before. valoresIniciais seems to be always undefined.
                // Doing this recovers data that was already typed into this form
                // when another form is submitted.
                valoresIniciais={valoresIniciaisRef.current || valoresIniciais}
                {...getPropsPermissao(permitirEdicao)}
                alternarBotoesPai={true}
                validarVoltar={true}
                validar={(valores) => modoDefinitivoSelecionado ? possuiAtitudes(valores) : undefined}
                renderForm={({ formProps: { form, values }, prefixoNome }) => {

                  // Update the ref of initial values. This code needs to run
                  // when any field changes.
                  if (valoresIniciaisRef.current !== values) {
                    valoresIniciaisRef.current = values;
                  }

                  return (
                    <>
                      <Row className="my-3">
                        <Col md={6}>
                          <Field
                            component={DropdownListField}
                            name={`idsRochas`}
                            label="Rochas"
                            dica="Selecione a rocha para a qual deseja informar a estrutura tectônica"
                            elementos={rochas}
                            required={modoDefinitivoSelecionado}
                            disabled={!permitirEdicao}
                            displayMember={'nomeExibicao'}
                            validate={validarSe(modoDefinitivoSelecionado, campoObrigatorioComMsgGenerica("Rochas"))}
                            checkboxes={true}
                            multiple
                          />
                        </Col>
                      </Row>
                      <Row className="my-3">
                        <Col md={6}>
                          <Field
                            component={DropdownListField}
                            name={`idTipo`}
                            label={`Tipo de estruturas tectônicas`}
                            dica="Selecione o tipo de estrutura tectônica associada"
                            elementos={tiposEstruturaTectonica}
                            required={modoDefinitivoSelecionado}
                            disabled={!permitirEdicao}
                            validate={validarSe(modoDefinitivoSelecionado, campoObrigatorioComMsgGenerica(`Tipo de estrutura tectônica`))}
                          />
                        </Col>
                        <Col md={6}>
                          <Field
                            component={CounterField}
                            name={`hierarquia`}
                            label="Hierarquia"
                            dica="Selecione a hierarquia da estrutura tectônica descrita considerando apenas este afloramento"
                            elementos={hierarquias}
                            disabled={!permitirEdicao}
                            value= { formAtividade.getState().values[`hierarquia`] }
                            onChange={(value) => {
                              formAtividade.mutators.setValue(`hierarquia`, value)
                            }}
                           
                          />
                        </Col>
                      </Row>
                      <Row className="my-3">
                        <Col md={12}>
                          <Field
                            component={TextAreaField}
                            name={`informacoesRelevantes`}
                            label="Informações relevantes"
                            dica="Descreva detalhadamente as estruturas tectônicas associadas"
                            maxLength={250}
                            disabled={!permitirEdicao}
                          />
                        </Col>
                      </Row>
                      <Row className="my-3">
                        <Col md={12}>
                          <Field name={'idTipo'} subscription={{ value: true }}>
                            {({ input: { value } }) => {
                              return (
                                <Card>
                                  <Card.Body>
                                    <Atitudes
                                      nome={'atitudes'}
                                      formAtividade={formAtividade}
                                      elementos={form.getState().values.atitudes || undefined}
                                      valoresIniciais={valoresIniciais}
                                      permitirEdicao={permitirEdicao}
                                      tiposNotacao={tiposNotacao}
                                    />
                                  </Card.Body>
                                </Card>
                              )
                            }}
                          </Field>
                        </Col>
                      </Row>
                    </>
                  )
                }}
              />
            )}
          </Field>
        </Card.Body>
      </Card>
    </div>
  );
}

// Função que gera as colunas da tabela de estruturas tectônicas, extraída para que
// possamos instanciar as colunas já inicializadas da primeira vez, ao invés de
// usar um estado [] padrão. Isso evita um rerender da tabela.
function gerarColunasEstruturasTectonicas(rochasPorId, hierarquias, tiposEstruturaTectonica) {
  return [
    {
      text: 'Tipo de estrutura tectônica', datafield: 'idTipo', width: "27%",
      cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
        const nome = value ? tiposEstruturaTectonica.find(t => t.id === parseInt(value)).nome : '';
        return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${nome}</div>`;
      }
    },
    {
      text: 'Hierarquia', datafield: 'hierarquia', width: "20%",
      cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
        const nome = value ? hierarquias.find(t => t.id === parseInt(value)).nome : '';
        return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${nome}</div>`;
      }
    },
    {
      text: "Rochas", datafield: "idsRochas", width: "20%",

      cellsrenderer: function (row, columnfield, value, defaulthtml, columnproperties, rowdata) {
        let nome = '';
        
        try{
          const idsRochas = (rowdata[columnfield] || '').split(',')
          nome = idsRochas
            .filter(it => !!it)
            .map(it => rochasPorId && rochasPorId[Number(it)] ? rochasPorId[Number(it)].nomeExibicao : '')
            .join(', ')  
        }
        catch(err){}

        return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${nome}</div>`;
      }
    },
    {
      text: "Informações Relevantes", datafield: "informacoesRelevantes", width: "27%",
    },
  ];
}

export default EstruturasTectonicas;
