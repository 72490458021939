import React, { Component, useCallback } from "react";
import PropTypes from "prop-types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquare, faCircle, faVectorSquare } from '@fortawesome/free-solid-svg-icons';

import {faSquare as faSquareRegular} from "@fortawesome/free-regular-svg-icons";
import Container from "react-bootstrap/Container";
import { Col, Row } from "react-bootstrap";
import './LegendaMapa.css';

/*
   Props para LegendaMapa:
    exibirEstacao
    exibirEstacaoHistorica
    exibirEstacaoEditada
    exibirGeometriaProjeto
    exibirPontoGPS
    exibirPontoGerado
    exibirAtividade
    exibirAmostra
    outrosElementos: [
        {
            "descricao": "Descrição Legenda"
            "cor": "rgba(0,0,0,0)""
        }
    ]
*/


const ItemLegendaMapa = (
        { ...props }
    ) => {

    let className = props.className ? props.className : "";
    let chaveLegenda = props.idLegenda + "-1";
    let chaveIcone = props.idLegenda + "-2";
    return <>
            <Col key={chaveLegenda} md={1} className="p-0 d-flex justify-content-end">
                <FontAwesomeIcon id={props.idLegenda} style={props.styleColor} className={className} size="1x" icon={faCircle} />
            </Col>
            <Col key={chaveIcone} md={2} className="p-0 d-flex justify-content-start"><p className="m-0">{props.descricao}</p></Col>
        </>
    };

export default ItemLegendaMapa;