import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Form from "react-bootstrap/Form";
import JqxDropDownList from "jqwidgets-scripts/jqwidgets-react-tsx/jqxdropdownlist";
import Hint from "../../hint/Hint";
import Erro from "../../Erro";

import "./DropdownList.css"

const readOnlyStyle = {
  background: '#eee',
  pointerEvents: 'none',
  touchAction: 'none',
  tabIndex: -1,
}

const DropdownList = React.forwardRef(({ label, dica, onSelect, elementos, displayMember, valueMember,
  onCheckSelect, mensagemErro, width, required, selectedIndex, placeholder,
  multiple, listRef, readOnly, ...props },
  ref) => {

  const [dropdownFocus, setDropdownFocus] = useState(props?.autoFocus)
  const placeHolder = placeholder ? placeholder : ''

  const handleBlur = useCallback((dropdownElement) => {
    setDropdownFocus(false)
    dropdownElement.target.classList.remove('jqx-fill-state-focus-bootstrap')
  }, [setDropdownFocus]);

  // https://www.jqwidgets.com/react-components-documentation/documentation/jqxdropdownlist/reactjs-dropdownlist-api.htm?search=
  useEffect(() => {
    if (ref && elementos) {
      for (let i = 0; i < elementos.length; i++) {
        const item = elementos[i]
        if (item.itemDesabilitado && ref.current) {
          ref.current.disableAt(i)
        }
      }
    }

    if (dropdownFocus) {
      ref?.current?.focus();
    }
  }, [elementos, ref, dropdownFocus]);

  return (
    <>
      {label && <Form.Label className="small">
        {label}
        {required && <span style={{ color: "red" }}>*</span>}
        {dica && <Hint textoAjuda={dica} />}
      </Form.Label>}
      <JqxDropDownList
        ref={ref}
        width={width}
        theme="bootstrap"
        source={elementos}
        displayMember={displayMember}
        valueMember={valueMember}
        onSelect={onSelect}
        selectedIndex={selectedIndex}
        placeHolder={placeHolder}
        checkboxes={multiple}
        onCheckChange={onCheckSelect}
        style={readOnly ? readOnlyStyle : null}
        {...props}
        onBlur={handleBlur}
      />
      <Erro mensagem={mensagemErro} />
    </>
  )
})

DropdownList.propTypes = {
  elementos: PropTypes.oneOfType([PropTypes.array.isRequired, PropTypes.exact({}).isRequired]),
  label: PropTypes.string,
  dica: PropTypes.string,
  onSelect: PropTypes.func,
  onCheckSelect: PropTypes.func,
  displayMember: PropTypes.string,
  valueMember: PropTypes.string,
  width: PropTypes.string,
  required: PropTypes.bool,
  selectedIndex: PropTypes.number,
  mensagemErro: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  multiple: PropTypes.bool,
  listRef: PropTypes.any,
}

DropdownList.defaultProps = {
  width: "100%",
  displayMember: "nome",
  valueMember: "id",
  selectedIndex: -1,
  multiple: false,
}

export default DropdownList;
