export const TIPO_ATIVIDADE_PETROGRAFIA = {
  id: "PETROGRAFIA",
  nome: "PETRO - Cadastrar análise petrográfica",
  nomeOld: "PETROGRAFIA",
};

export const TIPO_ATIVIDADE_GEOCRONOLOGIA = {
  id: "GEOCRONOLOGIA",
  nome: "GEOCRON - Cadastrar análise geocronológica",
  nomeOld: "GEOCRONOLOGIA",
};


export const IGNEAS_METAMORFICAS = { id: "IGNEAS_METAMORFICAS", nome: "Ígneas/Metamórficas" };
export const SEDIMENTARES = { id: "SEDIMENTARES", nome: "Sedimentares" };

export const CLASSIFICACAO_INTERPRETACAO_SEDIMENTAR = "classificacaoInterpretacaoPetrograficaSedimentar"
export const CLASSIFICACAO_INTERPRETACAO_IGNEA_METAMORFICA = "classificacaoInterpretacaoPetrograficaIgneaMetamorfica"