import { PureComponent } from "react";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import geoquimicaService from "../../../../../service/GeoquimicaService";
import { Field } from "react-final-form";
import { DropdownListField } from "../../../../../components/field/DropdownListField";
import { ComboItemModelLike } from "../../../../../models/combo-item.model";
import {
  campoObrigatorioComMsgGenerica,
  comporValidadores,
} from "../../../../../util/validadores";

export interface CorSampleFieldProps {
  prefixoNome?: string;
  name?: string;
  label?: string;
  dica?: string;
  required?: boolean;
}

export interface CorSampleFieldState {
  corOptions: ComboItemModelLike[];
}

export class CorSampleField extends PureComponent<
  CorSampleFieldProps,
  CorSampleFieldState
> {
  label?: string;
  validateField?: any;

  private lifecycle = new Subject();

  constructor(props) {
    super(props);
    this.state = {
      corOptions: [],
    };
  }

  componentDidMount() {
    geoquimicaService
      .listCorOptions()
      .pipe(takeUntil(this.lifecycle))
      .subscribe((data) => {
        this.setState({ corOptions: data });
      });
  }

  componentWillUnmount() {
    this.lifecycle.next();
    this.lifecycle.complete();
  }

  render() {
    const label = this.props.label ?? "Cor";
    if (label !== this.label) {
      this.label = label;
      this.validateField = comporValidadores(
        campoObrigatorioComMsgGenerica(this.label)
      );
    }

    const required = !!this.props.required;
    return (
      <>
        <Field
          component={DropdownListField}
          name={`${this.props.prefixoNome ?? ""}${this.props.name}`}
          label={this.props.label}
          required={required}
          validate={required ? this.validateField : undefined}
          dica={this.props.dica}
          elementos={this.state.corOptions}
        />
      </>
    );
  }
}
