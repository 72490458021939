import { Component } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { Field } from "react-final-form";
import Hint from "../../../../../components/hint/Hint";
import Form from "react-bootstrap/Form";
import { DropdownListField } from "../../../../../components/field/DropdownListField";
import { HiddenField } from "../../../../../components/field/HiddenField";
import { NumberInputField } from "../../../../../components/field/NumberInputField";
import { RadioButtonSetField } from "../../../../../components/field/RadioButtonSetField";
import { SubForm } from "../../../../../components/SubForm";
import { getPropsPermissao, resolverExibidos } from "../../../../../components/SubForm/SubForm";
import { GRAU_IMPORTANCIA_SUBSTANCIA_PRINCIPAL, GRAU_IMPORTANCIA_SUBSTANCIA_SECUNDARIA } from "../../../../../util/constantes";
import { resolverMultiRequestRecmin, resolverRequestRecmin } from "../../../../../util/ResolverRequestRecmin";
import { campoObrigatorioComMsgGenerica, verificaSubstanciaExisteObrigatoria, unidadeMedidaSemTeor, validarSe } from "../../../../../util/validadores";


class Substancia extends Component {

  constructor(props) {
    super(props);

    this.state = {
      importanciaSubstancia: [
        GRAU_IMPORTANCIA_SUBSTANCIA_PRINCIPAL,
        GRAU_IMPORTANCIA_SUBSTANCIA_SECUNDARIA,
      ],
      classeUtilitaria: [],
      unidadeMedidaTeor: [],
      substanciasPrincipaisFiltradas: this.getSubstancias().primarias,
      substanciasSecundariasFiltradas: this.getSubstancias().secundarias,
      subsPrincipal: [],
      subsSecundarias:  [],
    }

    this.objSubs = undefined;

    this.collectionsCopy = {
      classeUtilitaria: [],
      unidadeMedidaTeor: [],
    }

    this.colunaSubstancia =
      !this.props.removerTeor ?
        [
          { text: "Importância", datafield: "importancia", width: "20%", },
          {
            text: "Substância", datafield: "substancia", width: "20%", cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
              let substancias;
              rowdata.importancia === "PRINCIPAL" ? substancias = this.getSubstancias().primarias : substancias = this.getSubstancias().secundarias;
              let nome = null;
              substancias?.map(substancia => {
                if (substancia.id?.toString() === rowdata.idTipoSubstancia?.toString()) {
                  nome = substancia.nome;
                }
              });
              return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px; ${nome ? '' : 'color: red;'}">${nome ?? 'Substância inválida'}</div>`;
            }
          },
          {
            text: "Classe utilitária", datafield: "idClasseUtilitaria", width: "34%", cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
              let classes = this.collectionsCopy.classeUtilitaria;
              let nome = "";
              classes?.map(classe => {
                if (classe.id?.toString() === rowdata.idClasseUtilitaria?.toString()) {
                  nome = classe.nome;
                }
              });
              return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${nome}</div>`;
            }
          },
          {
            text: "Teor", datafield: "teor", width: "20%",
            cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
              let unidadeMedida = this.collectionsCopy.unidadeMedidaTeor;
              let nome = "";
              if (unidadeMedida) {
                unidadeMedida?.map(unidade => {
                  if (unidade?.id?.toString() === rowdata?.idUnidadeMedidaTeor?.toString()) {
                    nome = unidade?.teorMedio;
                  }
                });
                if (rowdata.teor && nome) {
                  const teor = `${rowdata.teor}${' '}${nome}`;
                  return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${teor}</div>`;
                } else {
                  return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${""}</div>`;
                }

              }
            },
          },
        ] : [
          { text: "Importância", datafield: "importancia", width: "30%", },
          {
            text: "Substância", datafield: "substancia", width: "34%", cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
              let substancias;

              rowdata.importancia === "PRINCIPAL" ? substancias = this.getSubstancias().primarias : substancias = this.getSubstancias().secundarias;
              let nome = "";
              substancias?.map(substancia => {
                if (substancia.id?.toString() === rowdata.idTipoSubstancia?.toString()) {
                  nome = substancia.nome;
                }
              });
              return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${nome}</div>`;
            }
          },
          {
            text: "Classe utilitária", datafield: "idClasseUtilitaria", width: "30%", cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
              let classes = this.collectionsCopy.classeUtilitaria;
              let nome = "";
              classes?.map(classe => {
                if (classe.id?.toString() === rowdata.idClasseUtilitaria?.toString()) {
                  nome = classe.nome;
                }
              });
              return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${nome}</div>`;
            }
          },
        ];

    this.datafieldsSubstancia = [
      { name: "importancia", type: "string", map: "importancia" },
      { name: "idTipoSubstancia", type: "string", map: "idTipoSubstancia" },
      { name: "idClasseUtilitaria", type: "string", map: "idClasseUtilitaria" },
      { name: "teor", type: "string", map: "teor" },
      { name: "idUnidadeMedidaTeor", type: "string", map: "idUnidadeMedidaTeor" },
    ];

    //this.filtrarSubstanciasExistentes();
  }

  filtrarSubstanciasExistentes() {
    if (this.props.referenciaForm?.getState()?.values?.substancias !== undefined) {
      let substanciaTemp = resolverExibidos(this.props.referenciaForm?.getState()?.values?.substancias);


      let idsExistentes = [];
      substanciaTemp?.map(substancia => {
        idsExistentes.push(substancia.idTipoSubstancia);
        return 0;
      }

      )
      if (idsExistentes.length > 0) {
        this.setState({
          substanciasPrincipaisFiltradas: this.getSubstancias().primarias.filter(tipoSubstancia => !idsExistentes.includes(tipoSubstancia.id)),
          substanciasSecundariasFiltradas: this.getSubstancias().secundarias.filter(tipoSubstancia => !idsExistentes.includes(tipoSubstancia.id))
        })
      } else {
        this.setState({
          substanciasPrincipaisFiltradas: this.getSubstancias().primarias,
          substanciasSecundariasFiltradas: this.getSubstancias().secundarias,
        })
      }
      let substancias = this.props.referenciaForm?.getState()?.values?.substancias;

      let substanciasFiltradas = this.getSubstancias().primarias.concat(this.getSubstancias().secundarias);
      this.atualizarSubstanciasNovas(substancias, substanciasFiltradas);

    }
  }
  atualizarSubstanciasNovas(substancias, listaSubstanciasFiltradas) {
    if (!substancias || !substancias.novos || substancias.novos.length == 0)
      return;

    let listaNovaSubstancias = [];
    if (!listaSubstanciasFiltradas || listaSubstanciasFiltradas.length == 0) {
      this.props?.referenciaForm?.mutators?.setValue("substancias.novos", listaNovaSubstancias);
      return;
    }

    substancias.novos.map(substancia => {
      if (!substancia)
        return;
      let existe = listaSubstanciasFiltradas.some(tipoSubstancia => substancia.idTipoSubstancia == tipoSubstancia.id);
      if (!existe)
        return;
      listaNovaSubstancias.push(substancia);
    });
    this.props?.referenciaForm?.mutators?.setValue("substancias.novos", listaNovaSubstancias);
  }

  onSubmitSubForm({ alteracoesForm }, formProps) {
    formProps?.batch(() => {
      for (const [key, val] of Object.entries(alteracoesForm)) {
        formProps?.mutators?.setValue(`${key}`, val);
      }
    });

    if (formProps.getState().values.substancias !== undefined) {
      let substanciaTemp = resolverExibidos(formProps.getState().values.substancias);

      let idsExistentes = [];
      substanciaTemp?.map(substancia => {
        idsExistentes.push(substancia.idTipoSubstancia);
        return 0;
      }

      )
      if (idsExistentes.length > 0) {
        this.setState({
          subsPrincipal: this.getSubstancias().primarias.filter(tipoSubstancia => !idsExistentes.includes(tipoSubstancia.id)),
          subsSecundarias: this.getSubstancias().secundarias.filter(tipoSubstancia => !idsExistentes.includes(tipoSubstancia.id))
        });
      } else {
        this.setState({
          subsPrincipal: this.getSubstancias().primarias,
          subsSecundarias: this.getSubstancias().secundarias,
        });
      }
    }
  }

  aoEditarSubstancias(event, tableRef, formProps) {
    let idSubstanciaEditada = event.rowdata?.rowData?.idTipoSubstancia;
    if (idSubstanciaEditada)
      idSubstanciaEditada = parseInt(idSubstanciaEditada);

    if (formProps.getState().values.substancias !== undefined) {
      let substanciaTemp = resolverExibidos(formProps.getState().values.substancias);

      let idsExistentes = [];
      substanciaTemp?.map(substancia => {
        idsExistentes.push(substancia.idTipoSubstancia);
        return 0;
      }

      )
      if (idsExistentes.length > 0) {
        this.setState({
          subsPrincipal: this.getSubstancias().primarias.filter(tipoSubstancia => !idsExistentes.includes(tipoSubstancia.id) || tipoSubstancia.id == idSubstanciaEditada),
          subsSecundarias: this.getSubstancias().secundarias.filter(tipoSubstancia => !idsExistentes.includes(tipoSubstancia.id) || tipoSubstancia.id == idSubstanciaEditada),
        })

      } else {
        this.setState({
          subsPrincipal: this.getSubstancias().primarias,
          subsSecundarias: this.getSubstancias().secundarias,
        })
      }
    }
  }

  aoVoltarSubstancias(formProps) {
    if (formProps.getState().values.substancias !== undefined) {
      let substanciaTemp = resolverExibidos(formProps.getState().values.substancias);

      let idsExistentes = [];
      substanciaTemp?.map(substancia => {
        idsExistentes.push(substancia.idTipoSubstancia);
        return 0;
      }

      )
      if (idsExistentes.length > 0) {
        this.setState({
          subsPrincipal: this.getSubstancias().primarias.filter(tipoSubstancia => !idsExistentes.includes(tipoSubstancia.id)),
          subsSecundarias: this.getSubstancias().secundarias.filter(tipoSubstancia => !idsExistentes.includes(tipoSubstancia.id)),
        })

      } else {        
        this.setState({
          subsPrincipal: this.getSubstancias().primarias,
          subsSecundarias: this.getSubstancias().secundarias,
        })
      }
    }
  }

  async componentDidMount() {
    const response = await resolverMultiRequestRecmin({
      classeUtilitaria: { uri: "/classeUtilitaria" },
      unidadeMedidaTeor: { uri: "/medida/teor" },
    });
    this.setState({
      classeUtilitaria: response.classeUtilitaria,
      unidadeMedidaTeor: response.unidadeMedidaTeor,
      substanciasPrincipaisFiltradas: this.getSubstancias().primarias,
      substanciasSecundariasFiltradas: this.getSubstancias().secundarias
    });
    this.collectionsCopy.classeUtilitaria = response.classeUtilitaria;
    this.collectionsCopy.unidadeMedidaTeor = response.unidadeMedidaTeor;

    if(typeof window !== 'undefined') {
      window.addEventListener('SUBSTANCIAS_RECMIN', () => {
          let subsString = localStorage.getItem('SUBSTANCIAS_RECMIN');
          if(subsString){

            if(this.objSubs === subsString)
              return;

            try {
              let obj = JSON.parse(subsString);
              this.objSubs = subsString;

              this.setState({
                subsPrincipal: obj?.primarias ? obj.primarias : [],
                subsSecundarias: obj?.secundarias ? obj.secundarias : [],
              });
            }
            catch(err) {
              console.log("Erro ao atualizar subs", err);
            }
        }
      });
    }
  }

  
  componentWillUnmount() {
    if(typeof window !== 'undefined') {
      window.removeEventListener('SUBSTANCIAS_RECMIN', () => {
          let subsString = localStorage.getItem('SUBSTANCIAS_RECMIN');
          if(subsString){
        
            if(this.objSubs === subsString)
              return;
            
            try {
              let obj = JSON.parse(subsString);
              this.objSubs = subsString;

              this.setState({
                subsPrincipal: obj?.primarias ? obj.primarias : [],
                subsSecundarias: obj?.secundarias ? obj.secundarias : [],
              });
            }
            catch(err) {
              console.log("Erro ao atualizar subs", err);
            }
        }
      });
    }
  } 

  
  componentDidUpdate() {
    let subsString = localStorage.getItem('SUBSTANCIAS_RECMIN');
    if(subsString){
      
      if(this.objSubs === subsString)
        return;

      try {
        let obj = JSON.parse(subsString);

        this.objSubs = subsString;
        this.setState({
          subsPrincipal: obj?.primarias ? obj.primarias : [],
          subsSecundarias: obj?.secundarias ? obj.secundarias : [],
        })
      }
      catch(err) {
        console.log("Erro ao atualizar subs", err);
      }
    }
  }

  getSubstancias() {
    let subsString = localStorage.getItem(this.props.ChaveSubstancias ? this.props.ChaveSubstancias : 'SUBSTANCIAS_RECMIN');

    if(subsString){
      try {
        let obj = JSON.parse(subsString);
        return {
          primarias: obj?.primarias ? obj.primarias : [],  
          secundarias: obj?.secundarias ? obj.secundarias : [],     
        }
      }
      catch(err) {
        console.log("Erro ao pegar substancias do localstorage", err);
      }
    }

    return {
      primarias: [],
      secundarias: [],
    }
  }

  render() {
    return <Card>
      <Card.Body>
        <Field name={"substancias"} subscription={{ value: true }}>
          {({ input: { value: substancias = {} } }) => {

             if (this.props.substanciasDeposito) {
              let substanciasExistentes = resolverExibidos(substancias);
              this.props.preencherSubstanciasSubForm(this.props.referenciaForm, substanciasExistentes);
            }
            
            return <SubForm
              keepDirtyOnReinitialize
              {...getPropsPermissao(this.props.permitirEdicao)}
              tituloForm="Substância"
              nome="substancias"
              onSubmit={(alteracoesForm) => this.onSubmitSubForm(alteracoesForm, this.props.referenciaForm)}
              onBtnEditClick={(event, tableRef) => this.aoEditarSubstancias(event, tableRef, this.props.referenciaForm)}
              aoConfirmarVoltar={() => this.aoVoltarSubstancias(this.props.referenciaForm)}
              validar={(valores) => this.props.modoDefinitivo ? unidadeMedidaSemTeor(valores) : undefined}
              naoValidarCamposVoltar={["importanciaOriginal","substanciaOriginal"]}
              colunas={this.colunaSubstancia}
              campos={this.datafieldsSubstancia}
              elementos={
                substancias
              }
              formSubscription={{}}
              exibirAsterisco={this.props.modoDefinitivo}
              valoresIniciais={{
                importancia: "PRINCIPAL"
              }}
              renderForm={({ formProps }) => {
                formProps.form.mutators.setValue('importanciaOriginal', formProps.form.getState().values.importancia);
                return (
                  <>
                    <Row>
                      <Col md={12}>
                        <Card.Title>Substância
                        { this.props.modoDefinitivo ? <span style={{ color: "red" }}>*</span> : <></> }
                        </Card.Title>
                      </Col>
                    </Row>
                    <Row className="mb-3 mt-3">
                      <Col md={3}>
                        <Row>
                          {/* <Col md={12}>
                            <h3 className="h6 mb-3">Importância</h3>
                          </Col> */}
                        </Row>
                        <Row>
                          <Col md={12}>
                            <Container
                              fluid
                              className="vertical-line-right p-0 m-0"
                            >
                              <Form.Label className="small">
                                 Importância
                                <Hint textoAjuda={this.props.hintImportancia ? this.props.hintImportancia : "Informe se a substância é principal ou secundária. Substância principal é aquela que define a ocorrência ou depósito - produto, coproduto. Substância(s) secundária(s) é(são) o(s) subproduto(s)."} />
                                </Form.Label>
                              <Field
                                component={RadioButtonSetField}
                                name="importancia"
                                elementos={
                                  this.state.importanciaSubstancia
                                }
                                disabled={!this.props.permitirEdicao}
                              />
                            </Container>
                          </Col>
                        </Row>
                      </Col>
                      <Field
                        component={HiddenField}
                        name="importanciaOriginal"
                      />
                      <Field
                        component={HiddenField}
                        name="substanciaOriginal"
                      />
                      <Col md={9}>
                        <Row>
                          <Col md={6}>
                            <Field name={"importancia"} subscription={{ value: true }}>
                              {({ input: { value: importancia } }) => {
                                let arraySubstancias = [];
                                let arraySubstanciasTemp = [];
                                let substanciasExistentes = [];
                                let substanciaAtual = null;
                                let tempSubstanciasExistentes = this.props.referenciaForm?.getState()?.values?.substancias;
                                if (tempSubstanciasExistentes) {
                                  substanciasExistentes = resolverExibidos(tempSubstanciasExistentes);
                                }

                                if (formProps.form.getState().values.importanciaOriginal !== formProps.form.getState().values.importancia) {
                                  formProps.form.mutators.setValue('idTipoSubstancia', null);
                                };

                                if (importancia === 'PRINCIPAL') {
                                  this.state.subsPrincipal.sort((a, b) => (a.nome > b.nome) ? 1 : ((b.nome > a.nome) ? -1 : 0));
                                  this.state.subsPrincipal.forEach(substancia => {
                                    if (substancia.id === formProps.form.getState().values.idTipoSubstancia) {
                                      substanciaAtual = substancia;
                                    }
                                  })
                                  arraySubstanciasTemp = this.state.subsPrincipal;
                                }
                                if (importancia === 'SECUNDARIA') {
                                  this.state.subsSecundarias.sort((a, b) => (a.nome > b.nome) ? 1 : ((b.nome > a.nome) ? -1 : 0));
                                  this.state.subsSecundarias.forEach(substancia => {
                                    if (substancia.id === formProps.form.getState().values.idTipoSubstancia) {
                                      substanciaAtual = substancia;
                                    }
                                  })
                                  arraySubstanciasTemp = this.state.subsSecundarias;
                                }

                                let editId = formProps.form.getState().values?.id;

                                arraySubstanciasTemp?.map(substancia => {
                                  if (!substanciasExistentes) {
                                    arraySubstancias.push(substancia);
                                    return 0;
                                  }

                                  if ((!editId || editId < 0) && substanciasExistentes.some(s => s.idTipoSubstancia == substancia.id)) {
                                    return 0;
                                  }

                                  arraySubstancias.push(substancia);
                                  return 0;
                                });
                                if (formProps.form.getState().values.importanciaOriginal === formProps.form.getState().values.importancia) {
                                  if (formProps.form.getState().values.substanciaOriginal) {
                                    arraySubstancias.push(formProps.form.getState().values.substanciaOriginal);
                                  }
                                }
                                if (substanciaAtual) {
                                  formProps.form.mutators.setValue('idTipoSubstancia', substanciaAtual.id);
                                  formProps.form.mutators.setValue('substanciaOriginal', substanciaAtual);
                                  console.log(formProps.form.getState().values.idTipoSubstancia);
                                }

                                arraySubstancias.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i);

                                const atual = formProps?.form?.getState()?.values?.idTipoSubstancia;

                                if(atual){
                                  if(importancia === 'PRINCIPAL'){
                                    const itemAtual = this.state.subsPrincipal.find((e) => e.id === atual);
                                    if(itemAtual)
                                      arraySubstancias.push(itemAtual);
                                  }
                                  else {
                                    const itemAtual = this.state.subsSecundarias.find((e) => e.id === atual);
                                    if(itemAtual)
                                      arraySubstancias.push(itemAtual);
                                  }

                                  arraySubstancias.sort((a, b) => (a.nome > b.nome) ? 1 : ((b.nome > a.nome) ? -1 : 0));
                                }


                                arraySubstancias = arraySubstancias.filter((v,i,a) => a.findIndex(v2 => (v2.id===v.id)) ===i);

                                return <Field
                                  name="idTipoSubstancia"
                                  component={DropdownListField}
                                  label="Substância"
                                  dica={this.props.hintSubstancia ? this.props.hintSubstancia : "Informar a substância a partir da biblioteca"}
                                  elementos={arraySubstancias}
                                  required={this.props.modoDefinitivo}
                                  validate={(value) => {
                                    if(this.props.modoDefinitivo){
                                      if (typeof value === 'undefined' || !value) {
                                        return campoObrigatorioComMsgGenerica("Substância")();
                                      }
                                      return verificaSubstanciaExisteObrigatoria(value, arraySubstancias)();
                                    }
                                  }}
                                  disabled={!this.props.permitirEdicao}
                                />
                              }}</Field>

                          </Col>
                          <Col md={6}>
                            <Field
                              name="idClasseUtilitaria"
                              component={DropdownListField}
                              label="Classe utilitária"
                              dica={this.props.hintClasseUtilitaria ? this.props.hintClasseUtilitaria : "Selecione a classe que melhor defina o uso e/ou aplicação da substância."}
                              elementos={this.state.classeUtilitaria}
                              required={this.props.modoDefinitivo}
                              initialValue=""
                              validate={validarSe(this.props.modoDefinitivo, campoObrigatorioComMsgGenerica(
                                "Classe utilitária"
                              ))}
                              disabled={!this.props.permitirEdicao}
                            />
                          </Col>
                        </Row>
                        <Row className="mt-4 mb-3">
                          {!this.props.removerTeor &&
                            <>
                              <Col md={6}>
                                <Field
                                  name="teor"
                                  component={NumberInputField}

                                  label="Teor"
                                  dica="Informe, se possível, o teor da substância"
                                  initialValue=""
                                  casasDecimais={3}
                                  casasInteiros={9}
                                  disabled={!this.props.permitirEdicao}
                                  permitirNegativos={false}
                                />
                              </Col>
                              <Col md={6}>
                                <Field
                                  name="idUnidadeMedidaTeor"
                                  component={DropdownListField}
                                  label="Unidade de medida do teor"
                                  displayMember={'teorMedio'}
                                  dica="Informar a unidade de quantificação da substância"
                                  elementos={this.state.unidadeMedidaTeor}
                                  initialValue=""
                                  disabled={!this.props.permitirEdicao}
                                />
                              </Col>
                            </>
                          }
                        </Row>
                      </Col>
                    </Row>
                  </>
                );
              }}
            />
            }
          }
        </Field>
      </Card.Body>

    </Card>

  }
}

export default Substancia;
