import { PureComponent } from "react";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import geoquimicaService from "../../../../../service/GeoquimicaService";
import { Field } from "react-final-form";
import { DropdownListField } from "../../../../../components/field/DropdownListField";
import { ComboItemModelLike } from "../../../../../models/combo-item.model";
import { campoObrigatorioComMsgGenerica, comporValidadores } from "../../../../../util/validadores";

export interface VelocidadeCorrenteFieldProps {
    prefixoNome?: string;
    name?: string;
    required?: boolean;
}

export interface VelocidadeCorrenteFieldState {
    velocidadeCorrenteOptions: ComboItemModelLike[];
}

export class VelocidadeCorrenteField extends PureComponent<VelocidadeCorrenteFieldProps, VelocidadeCorrenteFieldState> {
    label = 'Velocidade corrente';
    validateField = comporValidadores(campoObrigatorioComMsgGenerica(this.label));

    private lifecycle = new Subject();

    constructor(props) {
        super(props);
        this.state = {
            velocidadeCorrenteOptions: [],
        };
    }

    componentDidMount() {
      geoquimicaService.listVelocidadeCorrenteOptions().pipe(takeUntil(this.lifecycle)).subscribe(data => {
        this.setState({velocidadeCorrenteOptions: data});
      });
    }

    componentWillUnmount() {
        this.lifecycle.next();
        this.lifecycle.complete();
    }

    render() {
        const required = !!this.props.required;
        return (
            <>
                <Field
                    component={DropdownListField}
                    name={`${this.props.prefixoNome ?? ''}${this.props.name ?? 'id_velocidade_corrente'}`}
                    label={this.label}
                    required={required}
                    validate={required ? this.validateField : undefined}
                    elementos={this.state.velocidadeCorrenteOptions}
                />
            </>
        );
    }
}
