import React, { useCallback, useMemo, useRef } from "react";
import PropTypes from "prop-types";
import Form from "react-bootstrap/Form";
import NumberFormat from "react-number-format";
import Hint from "../../../components/hint/Hint";
import Erro from "../../../components/Erro";


const CustomNumberInput = ({
  valor,
  label,
  dica,
  onChange,
  required,
  mensagemErro,
  casasDecimais,
  separadorDecimal,
  permitirNegativos,
  permitirPositivos,
  max,
  valorMaximo,
  valorMinimo,
  min,
  usarOnchange,
  defaultNegativo,
  name,
  casasInteiros, 
  maxInteiros,
  maxLength,
  ...props
}) => {
  const ref = useRef(null);

  const prepareMaxValue = () => {
    let inteiros = '';
    let decimais = '';
    let text = '';
     
    if(maxInteiros){
      text = maxInteiros;
    }
    else{
      for(let i = 0; i < casasInteiros; i++) 
        inteiros = `9${inteiros}`;
        text = inteiros;
    }

    if(casasDecimais){
      for(let i = 0; i < casasDecimais; i++) 
        decimais = `9${decimais}`;
          
      text = `${text}.${decimais}`;
    }
        
    return parseFloat(text);
  }
   
  const withValueLimit = ({ floatValue }) => {
    if((valorMaximo !== undefined && valorMaximo !== null) && (valorMinimo !== undefined && valorMinimo !== null)){
      return (floatValue <= valorMaximo && floatValue >= valorMinimo);
    }

    if(valorMaximo !== undefined && valorMaximo !== null)
      return floatValue <= valorMaximo;
    else
      return floatValue <= prepareMaxValue();
  }

  const minValue = useMemo(() => {
    if (!permitirNegativos) {
      return Math.min(0, min);
    }
    return min;
  }, [min, permitirNegativos]);

  const maxValue = useMemo(() => {
    if (!permitirPositivos) {
      return Math.max(0, max);
    }
    return max;
  }, [max, permitirPositivos]);

  const onBlur = useCallback((e) => {
    if (defaultNegativo && parseFloat(e.target.value) >= 0) {
      e.target.value = "-" + e.target.value;
    }

    if (!defaultNegativo && e.target.value.indexOf('-') !== -1) {
      let value = e.target.value
      value = value.replace(/^-(\d)/, '$1')
      e.target.value = value;
    }

    const valorComoString = (ref.current?.value ?? "").replace(",", ".");
    const valor = parseFloat(valorComoString);
    if (isNaN(valor)) return onChange?.(undefined);
    if (valor < minValue) {
      onChange?.(minValue);
    } else if (valor > maxValue) {
      onChange?.(maxValue);
    } else {
      onChange?.(valor);
    }
  }, [minValue, maxValue, onChange, defaultNegativo]);

  const onFocus = useCallback(
    (e) => {
      if (defaultNegativo && !e.target.value) {
        e.target.value = "-";
      }
    },
    [defaultNegativo]
  );

  const onChangeInternal = useCallback(
    (e) => {
      if (usarOnchange) {
        onChange?.(e);
      }
    },
    [usarOnchange, onChange]
  );

  const onKeyUp = useCallback(
    (event) => {
      console.log(`[${event.target.value}]`);
      if (defaultNegativo && (event.target.value === "" || event.target.value === undefined)) {
        event.target.value = "-";
      }
    },
    [defaultNegativo]
  );

  return (
    <>
      {label && (
        <Form.Label className="small">
          {label}
          {required && <span style={{ color: "red" }}>*</span>}
          {dica && <Hint textoAjuda={dica} />}
        </Form.Label>
      )}
      <NumberFormat
        value={valor}
       
        decimalScale={0}
        fixedDecimalScale={true}
        maxLength={maxLength}
      
        allowLeadingZeros={true}
        className={"aflora-number-input"}
        onBlur={onBlur}
        onKeyUp={onKeyUp}
        //max={max}
       // isAllowed={casasInteiros ? withValueLimit : undefined}
        onFocus={onFocus}
        getInputRef={(r) => (ref.current = r)}
        onChange={onChangeInternal}
        {...props}
      />
      <Erro mensagem={mensagemErro} />
    </>
  );
};

CustomNumberInput.propTypes = {
  valor: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  label: PropTypes.string,
  dica: PropTypes.string,
  mensagemErro: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  required: PropTypes.bool,

  onChange: PropTypes.func,

  casasDecimais: PropTypes.number,
  separadorDecimal: PropTypes.string,
  permitirNegativos: PropTypes.bool,
  permitirPositivos: PropTypes.bool,
};

CustomNumberInput.defaultProps = {
  casasDecimais: 0,
  separadorDecimal: ",",
  permitirNegativos: true,
  permitirPositivos: true,
  min: Number.MIN_SAFE_INTEGER,
  max: Number.MAX_SAFE_INTEGER,
};

export default React.memo(CustomNumberInput);
