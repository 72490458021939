import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import Form from "react-bootstrap/Form";
import Hint from "../../hint/Hint";
import JqxInput from "jqwidgets-scripts/jqwidgets-react-tsx/jqxinput";
import Erro from "../../Erro";

const TextInput = ({ valor, label, dica, onChange, required, mensagemErro, placeholder, readOnly, onBlur, ...props }) => {
  const [autoFocusInput, setAutoFocusInput] = useState(props?.autoFocus)

  const handleBlur = useCallback((event) => {
    event.target.classList.remove("jqx-fill-state-focus-bootstrap")
    event.target.classList.remove("jqx-fill-state-focus")
    setAutoFocusInput(false)
    const valor = event.target.value
    onBlur && onBlur(valor)
  }, [setAutoFocusInput, onBlur]);

  const onChangeCallback = useCallback((evt) => {
    setAutoFocusInput(false)
    const valor = evt.target.value
    onChange && onChange(valor)
  }, [onChange, setAutoFocusInput])

  const captureInputChange = useCallback((query) => {
    setAutoFocusInput(false)
    onChange(query)
  }, [onChange, setAutoFocusInput]);

  const inputElRef = useRef();

  useEffect(() => {
    const inputEl = inputElRef.current;
    if (inputEl) {
      if (readOnly === true) {
        inputEl.readOnly = true;
      } else {
        inputEl.readOnly = false;
      }
    }
  }, [readOnly, inputElRef]);

  // Mover essa função para dentro do useCallback ou envolver essa função dentro de seu próprio useCallback
  // faz com que a funcionalidade de foco automático pare de funcionar
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const inputRef = (inputText) => {
    if (inputText && autoFocusInput) {
      inputText.focus();
    }
  };

  const refFn = useCallback((inputText) => {
    inputElRef.current = document.querySelector(`input${inputText?._componentSelector}`);
    if (props?.ref) return props?.ref
    if (props?.autoFocus) {
      return inputRef(inputText)
    }
  }, [inputElRef, props?.ref, props?.autoFocus, inputRef]);

  if (undefined === valor || null === valor) valor = '';

  const placeHolder = placeholder ? placeholder : ''

  return (
    <>
      {label && <Form.Label className="small">
        {label}
        {required && <span style={{ color: "red" }}>*</span>}
        {dica && <Hint textoAjuda={dica} />}
      </Form.Label>}
      <JqxInput
        width={"100%"}
        theme="bootstrap"
        onChange={onChangeCallback}
        value={valor}
        placeHolder={placeHolder}
        {...props}
        ref={refFn}
        onBlur={handleBlur}
        source={captureInputChange}
      />
      <Erro mensagem={mensagemErro} />
    </>
  )
}

TextInput.propTypes = {
  valor: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  mensagemErro: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  dica: PropTypes.string,
  required: PropTypes.bool,
  readOnly: PropTypes.bool,

  onChange: PropTypes.func,
}

TextInput.defaultProps = {
}

export default React.memo(TextInput);
