import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import Service from "../../service/Service";
import InformacoesCoordenadasMapa from "./components/mapa/InformacoesCoordenadasMapa";
import PageScaffold from "../../components/scaffold/PageScaffold";
import InfoPanel from "../../components/infoPanel";
import InfoPanelGPS from "../../components/infoPanelGPS";

export default class Coordenadas extends Component {
  constructor(props) {
    super(props);

    let pontosGPSSelecionados = this.props.location.state.pontosGPS || []; // Vindos da tela de pontos GPS
    let exibirLegendaPontosGPS = pontosGPSSelecionados.length > 0;
    this.state = {
      nomeProjeto: this.props.location.state.nomeProjeto,
      nomeArea: this.props.location.state.nomeArea,
      nomeSubArea: this.props.location.state.nomeSubArea,
      idProjeto: this.props.location.state.idProjeto,
      idArea: this.props.location.state.idArea,
      idSubArea: this.props.location.state.idSubArea,
      geometryFilter: this.props.location.state.geometryFilter,
      pontosGPSSelecionados: pontosGPSSelecionados, 
      exibirLegendaPontosGPS: exibirLegendaPontosGPS,
      hemisferio: this.props.location.state.hemisferio,
      hemisferioHorizontal: this.props.location.state.hemisferioHorizontal,
    };
  }

  //functions

  async componentDidMount() {
    localStorage.setItem("LAST_PAGE", 'coordenadas');

    const [
      datums,
      // pontosGPSpendentesRequisicao
      raioParaPontos,
    ] = await Promise.all([
      Service("/gps/datums").query(),
      // Service("/gps/pontosGPS").query(),
      Service(`/gps/raioParaPontos?idArea=${this.state.idArea}`).query(),
    ])
    console.log('Raio para os pontos:', raioParaPontos.data)

    
    this.setState({
      datums: datums.data.dados,
      pontosGPSpendentes: this.state.pontosGPSSelecionados.map((e) => ({
        id: e.id, // O id do ponto GPS é necessário para saber qual ponto associar
        latitude: e.latitude,
        longitude: e.longitude,
        altitude: e.altitude,
        latitudeWGS84: e.latitudeWGS84,
        longitudeWGS84: e.longitudeWGS84,
        nome: e.nome,
        idDatum: e.idDatum,
        // Raio do círculo em volta do ponto, deve ser utilizado apenas na tela de coordenadas. 
        // Na tela de cadastro de atividade, o raio vem da área da visita (ver decoradorMapa)
        distancia: raioParaPontos.data,
      })),
      raioParaPontos: raioParaPontos.data,
    });
  }
  render() {
    return (
      <PageScaffold titulo="Definição da estação">
        <InfoPanelGPS />
        <Container>
          <InformacoesCoordenadasMapa
            forcarLatitudeLogitudeEstacaoSelecionada={true}
            incluirDatum={false}
            history={this.props.history}
            datums={this.state.datums}
            nomeProjeto={this.state.nomeProjeto}
            nomeArea={this.state.nomeArea}
            nomeSubArea={this.state.nomeSubArea}
            idProjeto={this.state.idProjeto}
            idArea={this.state.idArea}
            idSubArea={this.state.idSubArea}
            pontosGPSpendentes={this.state.pontosGPSpendentes}
            raioParaPontos={this.state.raioParaPontos}
            geometryFilter={this.state.geometryFilter}
            hemisferio={this.state.hemisferio}
            hemisferioHorizontal={this.state.hemisferioHorizontal}
            autoFocus
            exibirLegendaEstacao={true}
            exibirLegendaEstacaoHistorica={true}
            exibirLegendaEstacaoEditada={false}
            exibirLegendaAtividade={false}
            exibirLegendaPontoGPS={this.state.exibirLegendaPontosGPS}
            exibirLegendaPontoGerado={true}
            exibirLegendaGeometriaProjeto={true}
            exibirHistoricas={true}
          />
        </Container>
      </PageScaffold>
    );
  }
}
