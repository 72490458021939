import { PureComponent } from "react";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { AmostraSubFormPart } from "../components/AmostraSubFormPart";
import {
  amostraDataFieldsCommon,
  AmostraSymbol,
  amostrasLabelsMap,
} from "../constants/amostras.constant";
import { SituacaoAmostraField } from "../components/SituacaoAmostraField";
import { AmbienteBioticoField } from "../components/AmbienteBioticoField";
import { VegetacaoSampleGeoquimicaModelLike } from "../../../../../models/activity-geoquimica.model";
import { ImagensAmostraSubForm } from "../components/ImagensAmostraSubForm";
import { AmostraGridSubForm } from "../components/AmostraGridSubForm";

export interface VegetacaoSectionFormProps {
  prefixoNome: string;
  items?: VegetacaoSampleGeoquimicaModelLike[];
  onItemsChanged?: (items: VegetacaoSampleGeoquimicaModelLike[]) => void;
}

export interface VegetacaoSectionFormState {}

export class VegetacaoSectionForm extends PureComponent<
  VegetacaoSectionFormProps,
  VegetacaoSectionFormState
> {
  datafieldsVegetacao = [
    ...amostraDataFieldsCommon,
    {
      name: "id_situacao_amostra",
      type: "string",
      map: "id_situacao_amostra",
    },
    {
      name: "id_ambiente_biotico",
      type: "number",
      map: "id_ambiente_biotico",
    },
  ];

  amostraSymbol = AmostraSymbol.Vegetacao;

  constructor(props) {
    super(props);
    this.state = {};
  }

  renderForm = ({ editando, prefixoNome }) => {
    return (
      <>
        <AmostraSubFormPart
          prefixoNome={prefixoNome}
          amostraSymbol={this.amostraSymbol}
          profundidadeMinRequired={true}
          profundidadeMaxRequired={true}
        />
        <Row className="my-3">
          <Col>
            <SituacaoAmostraField prefixoNome={prefixoNome} required={true} />
          </Col>
          <Col>
            <AmbienteBioticoField prefixoNome={prefixoNome} required={true} />
          </Col>
        </Row>
        <Row className="my-3">
          <Col>
            <ImagensAmostraSubForm prefixoNome={prefixoNome} />
          </Col>
        </Row>
      </>
    );
  };

  render() {
    return (
      <>
        <Card className="my-3">
          <Card.Body>
            <Card.Subtitle>
              {amostrasLabelsMap.get(this.amostraSymbol)}
            </Card.Subtitle>
            <AmostraGridSubForm
              name="amostraVegetacao"
              datafields={this.datafieldsVegetacao}
              items={this.props.items}
              onItemsChanged={this.props.onItemsChanged}
              renderForm={this.renderForm}
            />
          </Card.Body>
        </Card>
      </>
    );
  }
}
