import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { Field } from "react-final-form";
import { DropdownListField } from "../../../../../components/field/DropdownListField";

class CaracterizacaoAmbiente extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  };
  render() { 
    return <>
      <Card>
        <Card.Body className="p-4 mt-3">
          <Row>
            <Col md={12}>
              <Card.Text>
                Caracterização do ambiente
              </Card.Text>
            </Col>
          </Row>
          <Row className="mt-3 mb-3">
            <Col md={4}>
              <Field
                disabled={this.props.permitirEdicao}
                name={`${this.props.prefixoNome}caracterizacaoAmbiente.idRochaRegional`}
                component={DropdownListField}
                label="Rocha regional"
                dica="Selecione o tipo de rocha predominante na região"
                elementos={this.props.rochaRegional.dados}
              />
            </Col>
            <Col md={4}>            
              <Field
                disabled={this.props.permitirEdicao}
                name={`caracterizacaoAmbiente.idsIdadesGeologicas`}
                component={DropdownListField}
                label="Idade geológica"
                dica="Selecione o(s) período(s) geológico(s) da rocha predominante na região."
                elementos={this.props.idadeGeologica.dados}
                checkboxes={true}
                multiple
              />
            </Col>
            <Col md={4}>
              <Field
                disabled={this.props.permitirEdicao}
                name={`${this.props.prefixoNome}caracterizacaoAmbiente.idPluviosidade`}
                component={DropdownListField}
                label="Pluviosidade"
                dica="Selecione a pluviosidade na época da visita"
                elementos={this.props.pluviosidade.dados}
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md={4}>
              <Field
                disabled={this.props.permitirEdicao}
                name={`${this.props.prefixoNome}caracterizacaoAmbiente.idSituacaoTopografica`}
                component={DropdownListField}
                label="Situação topográfica"
                dica="Selecione a situação topográfica do local"
                elementos={this.props.situacaoTopografica.dados}
              />
            </Col>
            <Col md={4}>
              <Field
                disabled={this.props.permitirEdicao}
                name={`${this.props.prefixoNome}caracterizacaoAmbiente.idRelevo`}
                component={DropdownListField}
                label="Relevo"
                dica="Selecione o tipo de relevo predominante no local"
                elementos={this.props.relevo.dados}
              />
            </Col>
            <Col md={4}>
              <Field
                disabled={this.props.permitirEdicao}
                name={`${this.props.prefixoNome}caracterizacaoAmbiente.idInfluenciaAntropica`}
                component={DropdownListField}
                label="Influência antrópica"
                dica="Selecione uma influência antrópica predominante no local"
                elementos={this.props.influenciaAntropica.dados}
              />
            </Col>
          </Row>
          <Row className="mt-3 mb-4">
            <Col md={12}>
              <Field
                disabled={this.props.permitirEdicao}
                name={`${this.props.prefixoNome}caracterizacaoAmbiente.idTipoVegetacao`}
                component={DropdownListField}
                label="Tipo de vegetação"
                dica="Selecione o tipo de vegetação predominante no local"
                elementos={this.props.tipoVegetacao.dados}
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  }

}

export default CaracterizacaoAmbiente;
