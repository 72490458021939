import React, { PureComponent } from "react";
import { Field } from "react-final-form";
import { NumberInputField } from "../../../../../components/field/NumberInputField";
import { campoObrigatorioComMsgGenerica, comporValidadores } from "../../../../../util/validadores";

export interface LarguraRioFieldProps {
    prefixoNome?: string;
    name?: string;
    unity?: string;
    required?: boolean;
}

export interface LarguraRioFieldState {
}

export class LarguraRioField extends PureComponent<LarguraRioFieldProps, LarguraRioFieldState> {
    label = 'Largura do rio';
    validateField = comporValidadores(campoObrigatorioComMsgGenerica(this.label));

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const unity = this.props.unity ?? 'm';

        const required = !!this.props.required;
        return (
            <>
                <Field
                    component={NumberInputField}
                    name={`${this.props.prefixoNome ?? ''}${this.props.name ?? 'nu_largura_rio'}`}
                    label={`${this.label} (${unity})`}
                    required={required}
                    validate={required ? this.validateField : undefined}
                    casasInteiros={9}
                    casasDecimais={2}
                    min={0}
                />
            </>
        );
    }
}
