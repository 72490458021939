export const amostraDataFieldsCommon = [
  {
    name: "id",
    type: "number",
    map: "id",
  },
  {
    name: "id_amostra",
    type: "number",
    map: "id_amostra",
  },
  {
    name: "tipo",
    type: "number",
    map: "tipo",
  },
  {
    name: "fonte",
    type: "number",
    map: "fonte",
  },
  {
    name: "numero_do_campo",
    type: "string",
    map: "numero_do_campo",
  },
  {
    name: "amostraExtra",
    type: "string",
    map: "amostraExtra",
  },
  {
    name: "materialColetado",
    type: "string",
    map: "materialColetado",
  },
  {
    name: "pesoAmostra",
    type: "number",
    map: "pesoAmostra",
  },
  {
    name: "profundidadeMinima",
    type: "number",
    map: "profundidadeMinima",
  },
  {
    name: "profundidadeMaxima",
    type: "number",
    map: "profundidadeMaxima",
  },
  {
    name: "observacao",
    type: "string",
    map: "observacao",
  },

  {
    name: "numero_etiqueta",
    type: "string",
    map: "numero_etiqueta",
  },
  {
    name: "status",
    type: "string",
    map: "status",
  },
  {
    name: "resultados",
    type: "string",
    map: "resultados",
  },
];

export enum AmostraSymbol {
  Agua = "A",
  ConcentradoBateia = "B",
  Crosta = "C",
  Minerio = "M",
  Outros = "Z",
  Overbank = "O",
  Rocha = "R",
  SedimentoCorrente = "S",
  Solo = "L",
  Vegetacao = "V",
}

export const amostrasLabelsMap = new Map<AmostraSymbol, string>([
  [AmostraSymbol.Agua, 'Água'],
  [AmostraSymbol.ConcentradoBateia, 'Concentrado de bateia'],
  [AmostraSymbol.Crosta, 'Crosta'],
  [AmostraSymbol.Minerio, 'Minério'],
  [AmostraSymbol.Outros, 'Outros'],
  [AmostraSymbol.Overbank, 'Overbank'],
  [AmostraSymbol.Rocha, 'Rocha'],
  [AmostraSymbol.SedimentoCorrente, 'Sedimento Corrente'],
  [AmostraSymbol.Solo, 'Solo'],
  [AmostraSymbol.Vegetacao, 'Vegetação']
]);
