import React, { useCallback } from "react";
import BlockUi from "react-block-ui";
import AtividadesAssociadas from "./sections/AtividadesAssociadas";
import { setValue } from "../../../../util/mutadores/index";
import { campoObrigatorioComMsgGenerica, swalConfirmarExclusao, swalErro } from "../../../../util/validadores";
import { RadioButtonWithFieldSetField } from "../../../../components/field/RadioButtonSetField";
import { Card, Col, Row } from "react-bootstrap";
import { Field, FormSpy } from "react-final-form";
import InformacoesCoordenadas from "../mapa/InformacoesCoordenadas";
import { configMapaAmostras } from "../../../../config/mapa";
import { DropdownListField } from "../../../../components/field/DropdownListField";
import { TextInputField } from "../../../../components/field/TextInputField";
import { SubForm } from "../../../../components/SubForm";
import PontosGPSAfloramento from "../afloramento/PontosGPSAfloramento";
import Agua from "./sections/Agua.js";
import ConcentradoBateia from "./sections/ConcentradoBateia";
import Crosta from "./sections/Crosta";
import Minerio from "./sections/Minerio";
import Outros from "./sections/Outros";
import Overbank from "./sections/Overbank";
import Rocha from "./sections/Rocha";
import SedimentoCorrente from "./sections/SedimentoCorrente";
import Solo from "./sections/Solo";
import Vegetacao from "./sections/Vegetacao";
import Ilustracoes2 from "../Ilustracoes2";
import { HiddenField } from "../../../../components/field/HiddenField";
import swal from "sweetalert";
import { resolverExibidos } from "../../../../components/SubForm/SubForm";
import { salvarAmostra } from "./AmostrasUtils/AmostraRequestUtils";
import { connect } from "react-redux";
import { mostrarNotificacao } from "../../../../reducers/notificacaoReducer";
import Service, { AMOSTRAS, AFLORA } from "../../../../service/Service";
import { limparArquivos } from "../../../../reducers/fileReducers";
import LetterSequenceGenerator from "./AmostrasUtils/LetterSequenceGenerator";
import { TIPO_ATIVIDADE_AFLORAMENTO, TIPO_ATIVIDADE_RECURSO_MINERAL } from "../../../../util/constantes";
import { decoradorMapa, decoradorSirgas2000 } from "../../../../util/decoradores/decoradorMapa";
import { resetarLatLongInvalidaAoTrocarHemisferio } from "../../../../util/decoradores/decoradorSeletorCoordenadas";
import isEqual from "lodash/isEqual";
import { CheckboxField } from "../../../../components/field/CheckboxField";
import { resolverRequestAmostras } from "../amostras/AmostrasUtils/AmostraRequestUtils";

const classesExibidas = [
  { id: 1, nome: "Água" },
  { id: 2, nome: "Concentrado de bateia" },
  { id: 16, nome: "Crosta" },
  { id: 8, nome: "Minério" },
  { id: 14, nome: "Outros" },
  { id: 17, nome: "OverBank" },
  { id: 9, nome: "Rocha" },
  { id: 10, nome: "Sedimento corrente" },
  { id: 7, nome: "Solo" },
  { id: 12, nome: "Vegetação" }
];

const AUTOSAVE_DELAY = process?.env?.REACT_APP_AUTOSAVE_DELAY ? process.env.REACT_APP_AUTOSAVE_DELAY : 300000;

const IlustracoesAmostrasContainer = ({ props, state, valoresIniciais, tempNumeroAmostra, aoAbrirSubForm, aoFecharSubForm }) => {
  const IlustracoesAmostras = useCallback(() => {
    return <Ilustracoes2
      valoresIniciais={valoresIniciais}
      visitas={props.visitas}
      visitaSelecionada={props.visitaSelecionada}
      tituloForm="Conteúdo Multimídia"
      nome={'ilustracoes'}
      permitirEdicao={state.duplicata ? false : state.permitirEdicao}
      tiposIlustracao={props.tiposIlustracao}
      extencoesIlustracao={props.extencoesIlustracao}
      tipoAtividadeIlustracao={"amostra"}
      nomeIlustracaoAmostra={tempNumeroAmostra}
      legendaObrigatoria={true}
      permitirVisualizacao={state.duplicata ? true : !state.permitirEdicao}
      aoAbrirSubForm={aoAbrirSubForm}
      aoFecharSubForm={aoFecharSubForm}
    />
  }, [props, state, tempNumeroAmostra, valoresIniciais]);

  return <IlustracoesAmostras />
}

function pegarBotaoPeloTexto(texto) {
  const botoes = document.querySelectorAll('div[role="button"]');
  for (const botao of botoes) {
    if (botao.textContent.trim() === texto) {
      return botao;
    }
  }
  return null;
}
let formularioIlustracoesAberto = false;
let AmostrasInternal = class Amostras extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isClasseAmostraHabilitado: true,
      atividadeAflora: undefined,
      locaisArmazenamento: [],
      formValuesCopy: undefined,
      carregouValoresIniciais: false,
      exibirModalEdicao: false,
      permitirAdicaoAssociacao: false,
      idRecursoMineralDaAtividade: "",
      idAfloramentoDaAtividade: "",
      numeroCampoAfloramento: "",
      numeroCampoRecursoMineral: "",
      materiaisGeologicosRecursoMineral: [],
      materiaisGeologicosAfloramento: [],
      pontosGpsDisponiveis: this.props.pontosAtribuidosAEstacao,
      cadastrarNovamente: false,
      permitirEdicao: true,
      exibirTela: true,
      idAtividade: this.props.idAtividade,
      dadosTabela: [],
      clickExcluir: false,
      clickEditar: false,
      clickVisualizar: false,
      possuiDuplicata: false,
      possuiDuplicataCadastrada: false,
      exibirBotaoInsercao: this.props.exibirBotaoInsercao,
      duplicata: false,
      siglas: {
        "1": "A",
        "2": "B",
        "16": "D",
        "8": "M",
        "14": "Z",
        "17": "O",
        "9": "R",
        "10": "S",
        "7": "L",
        "12": "V"
      },
      idItemAtual: null,
      valoresIniciais: {
        modoCadastro: "DEFINITIVO",
        coordenadas: this.props.coordenadas,
        idMetodoGeoposicionamento: this.props.idMetodoGeoposicionamento,
        altitude: this.props.altitude,
        possuiDuplicata: false,
      },
      formularioAberto: false,
      modoCadastro: [
        {
          id: "RASCUNHO",
          nome: "Rascunho"
        },
        {
          id: "DEFINITIVO",
          nome: "Completo"
        }
      ],
      carregamentoGlobal: true,
      classe: this.props.classesAmostra
        .filter(classe => classesExibidas.find((c) => c.id === classe.id))
        .sort((a, b) => a.nome.localeCompare(b.nome))
    };

    this.decoradoresAtividades = [decoradorSirgas2000(this.props.visitaSelecionada), decoradorMapa(), resetarLatLongInvalidaAoTrocarHemisferio()];

    this.interval = null;

    this.colunasAmostras = [
      {
        text: "Modo de cadastro", datafield: "modoCadastro", width: "22%", cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
          switch (value) {
            case "RASCUNHO": value = "Rascunho"; break;
            case "DEFINITIVO": value = "Completo"; break;
            case "TEMPORARIO": value = "Temporario"; break;
            default: value = "";
          }
          return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${value}</div>`;;
        }
      },
      {
        text: "Classe", datafield: "idClasse", width: "52%",
        cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
          let linhaFormatada = this.montarCellRenderPadrao(this.state.classe, rowdata.idClasse);
          return linhaFormatada;
        },
      },
      { text: "Nº da amostra", datafield: "numeroAmostra", width: "20%" },
    ];
    this.datafieldsAmostras = [
      { name: "modoCadastro", type: "string", map: "modoCadastro" },
      { name: "idClasse", type: "string", map: "idClasse" },
      { name: "numeroAmostra", type: "string", map: "numeroAmostra" },
    ];
  }


  async atualizarPontosEstacao() {
    await Service(`/gps/pontosGPS/paraEstacao?idEstacao=${this.props.idEstacao}`).query()
      .then(({ data: { dados: pontos } }) => {
        this.setState({
          pontosGpsDisponiveis: pontos
        })
      })
      .catch((err) =>
        console.error(
          "Erro ao buscar pontos não vinculados associados a estação:",
          err
        )
      );
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  async componentDidMount() {

    this.setState({
      locaisArmazenamento: await resolverRequestAmostras("/biblioteca/localarmazenamento"),
    })

    if (this.props.abrirFormAmostras) {
      this.setState({
        formularioAberto: this.props.abrirFormAmostras,
        clickEditar: this.props?.atividadeEmEdicao?.id > 0 ? true : false,
        idItemAtual: this.props?.atividadeEmEdicao?.id,
        valoresIniciais: this.props?.atividadeEmEdicao,
        carregouValoresIniciais: true,
      });
    }

    const atividadeAfloraItem = this.props?.atividades.find(a => a.idVisita.toString() === this.props.visitaSelecionada?.toString() && (a.nomeTipo === TIPO_ATIVIDADE_AFLORAMENTO.nome || a.nomeTipo === TIPO_ATIVIDADE_AFLORAMENTO.nomeOld))

    if (atividadeAfloraItem) {
      const resAflora = await Service('/afloramentos', AFLORA).get(atividadeAfloraItem.id);
      let aflora = resAflora.data.dados;
      this.setState({ atividadeAflora: aflora });
    }

    this.props.atividades.map(atividade => {
      if (atividade.idVisita?.toString() === this.props.visitaSelecionada?.toString()) {
        if (atividade.nomeTipo === TIPO_ATIVIDADE_AFLORAMENTO.nome || atividade.nomeTipo === TIPO_ATIVIDADE_AFLORAMENTO.nomeOld) {
          this.setState({
            idAfloramentoDaAtividade: atividade.id,
            numeroCampoAfloramento: atividade.numeroCampo,
            permitirAdicaoAssociacao: true
          })

          Service('/materialgeologico').query({ idsAtividades: atividade.id })
            .then(({ data: { dados: atividade } }) => {
              let materiaisGeologicos = [];
              let tempMateriaisGeologicos = atividade[0]?.materiaisGeologicos ?? [];
              tempMateriaisGeologicos.map(materialGeologico => { materiaisGeologicos.push({ id: materialGeologico.idMaterialGeologico, nome: `${materialGeologico.descricao} (${materialGeologico.numeroMaterialGeologico})` }) })
              this.setState({
                materiaisGeologicosAfloramento: materiaisGeologicos
              })
            });

        }
        if (atividade.nomeTipo === TIPO_ATIVIDADE_RECURSO_MINERAL.nome || atividade.nomeTipo === TIPO_ATIVIDADE_RECURSO_MINERAL.nomeOld) {
          this.setState({
            idRecursoMineralDaAtividade: atividade.id,
            numeroCampoRecursoMineral: atividade.numeroCampo,
            permitirAdicaoAssociacao: true
          })
          Service('/materialgeologico').query({ idsAtividades: atividade.id })
            .then(({ data: { dados: atividade } }) => {
              let materiaisGeologicos = [];
              let tempMateriaisGeologicos = atividade[0]?.materiaisGeologicos ?? [];
              tempMateriaisGeologicos.map(materialGeologico => { materiaisGeologicos.push({ id: materialGeologico.idMaterialGeologico, nome: `${materialGeologico.descricao} (${materialGeologico.numeroMaterialGeologico})` }) })
              this.setState({
                materiaisGeologicosRecursoMineral: materiaisGeologicos
              })
            });
        }
      }
      return true;
    })

    let permitirInsercao = this.props?.atividadeEmEdicao?.id > 0 ? this.props.permitirInsercao ? true : false : true;

    await this.atualizarPontosEstacao();


    let dadosTabela = [];
    if (this.props.referenciaForm.form.getState().values.amostras) {
      dadosTabela = resolverExibidos(this.props.referenciaForm.form.getState().values.amostras);
    } else {
      dadosTabela = [];
    }
    dadosTabela.map(item => item.idAtividade = this.state.idAtividade);
    dadosTabela.map(item => item.idCadastroAmostra = this.state.idAtividade);

    if (!this.props.permitirEdicao)
      permitirInsercao = false;

    this.setState({
      carregamentoGlobal: false,
      permitirEdicao: this.props.permitirEdicao,
      permitirInsercao: permitirInsercao,
      dadosTabela: dadosTabela
    })


    this.interval = setInterval(() => {
      if (this.state.permitirEdicao) {
        const formValues = this.props?.referenciaForm?.form?.getState()?.values;

        const body = {
          idColetor: this.props?.idColetor,
          idVisita: formValues?.idVisita,
          idEstacao: this.props?.idEstacao,
          toponimia: formValues?.toponimia,
          numeroCampo: this.state.formValuesCopy?.numeroCampo,
          atividade: this.state.formValuesCopy,
          tipoAtividade: formValues?.tipo,
          permitirExclusao: true,
          permitirEdicao: true,
          modoCadastro: 'TEMPORARIO',
          modoEdicao: 'BASEGEO'
        }

        Service('/estacoes/atividadeTemporaria', 'basegeo').post(body)
          .then(() => this.props.enviarNotificacaoAtividadeTemporariaSalva())
          .catch((erro) => console.log("Erro no salvamento automatico", erro))
      }
    }, AUTOSAVE_DELAY);

  }

  async mostrarModal(elementoAtual) {
    return swal({
      title: 'Amostra cadastrada!',
      text: "Deseja cadastrar outra amostra?",
      icon: 'success',
      buttons: { cancel: "Não, retornar", confirm: { text: "Sim, cadastrar outra amostra", value: true, className: 'btn-primary' } },
    }).then(async (value) => {
      if (value) {
        let coordenadas = [];
        let caracterizacaoAmbiente = [];
        let numeroAmostra = "";
        let idClasse = "";
        coordenadas = elementoAtual.coordenadas;
        caracterizacaoAmbiente = elementoAtual.caracterizacaoAmbiente;
        idClasse = elementoAtual.idClasse;
        numeroAmostra = elementoAtual.numeroAmostra;

        // amostras.map(amostra => {
        //   if (amostra?.id?.toString() === this?.state?.idItemAtual?.toString()) {
        //     amostraAtual = amostra;
        //     coordenadas = amostraAtual.coordenadas;
        //     caracterizacaoAmbiente = amostraAtual.caracterizacaoAmbiente;
        //     idClasse = amostraAtual.idClasse;
        //     numeroAmostra = amostraAtual.numeroAmostra
        //   } else {
        //     amostraAtual = amostras[amostras.length - 1]
        //     coordenadas = amostraAtual.coordenadas
        //     caracterizacaoAmbiente = amostraAtual.caracterizacaoAmbiente
        //     idClasse = amostraAtual.idClasse;
        //     numeroAmostra = amostraAtual.numeroAmostra
        //   }

        // })

        await this.setState({
          formularioAberto: false,
        })
        await this.setState({
          cadastrarNovamente: true,
          formularioAberto: true,
          valoresIniciais: {
            modoCadastro: "DEFINITIVO", coordenadas: coordenadas, caracterizacaoAmbiente: caracterizacaoAmbiente,
            idClasse: idClasse, numeroAmostra: numeroAmostra,
            idMetodoGeoposicionamento: elementoAtual.idMetodoGeoposicionamento,
            altitude: elementoAtual.altitude,
            idAtividade: this.state.idAtividade,
            idCadastroAmostra: this.state.idAtividade,
            idVisita: this.props.visitaSelecionada
          },
          idItemAtual: null
        })
      } else {
        this.setState({
          formularioAberto: false,
          cadastrarNovamente: false,
          valoresIniciais: {
            modoCadastro: "DEFINITIVO", coordenadas: this.props.coordenadas,
            idMetodoGeoposicionamento: this.props.idMetodoGeoposicionamento,
            altitude: this.props.altitude,
            idAtividade: this.state.idAtividade,
            idCadastroAmostra: this.state.idAtividade,
            idVisita: this.props.visitaSelecionada
          },
        })
      }
    })
  }


  async onSubmit({ alteracoesForm, novosElementos, prefixoAtual }) {
    this.state.exibirModalEdicao = false;
    this.props.referenciaForm.form.batch(() => {
      for (const [key, val] of Object.entries(alteracoesForm)) {
        this.props.referenciaForm.form.mutators.setValue(`${key}`, val);
      }
    });


    let elementoAtual = null;

    if (this.state.clickEditar && novosElementos && novosElementos.editadosPorId) {
      for (const [key, val] of Object.entries(novosElementos.editadosPorId)) {
        if (key.includes(`${this.state.idItemAtual}`)) {
          elementoAtual = val;
        }
      }

    }

    this.setState({
      exibirTela: false
    })

    let amostras = resolverExibidos(this.props.referenciaForm.form.getState().values.amostras);
    if (this.state.idItemAtual === null || this.state.idItemAtual === undefined) {
      elementoAtual = amostras[amostras.length - 1];
      elementoAtual.idCadastroAmostra = elementoAtual.idAtividade
      elementoAtual.idVisita = this.props.visitaSelecionada
    } else {
      if (elementoAtual === null) {
        amostras.map(amostra => {
          if (amostra.id.toString() === this.state.idItemAtual.toString()) {
            elementoAtual = amostra;
            elementoAtual.idCadastroAmostra = elementoAtual.idAtividade
            elementoAtual.idVisita = this.props.visitaSelecionada
          }
        })
      }
    }

    if (elementoAtual.associacoes) {
      let tempAssociacoes = resolverExibidos(elementoAtual.associacoes);
      elementoAtual.associacoes = tempAssociacoes
    }


    elementoAtual.possuiDuplicata = this.state.possuiDuplicata;


    if (this.state.possuiDuplicataCadastrada) {
      elementoAtual.possuiDuplicata = true;
    }

    elementoAtual.idCadastroAmostra = elementoAtual.idAtividade;
    try {
      let response = await salvarAmostra('/cadastroAmostra/amostra', elementoAtual);
      await Service(`/estacoes/atividadeTemporaria/${this.props.idEstacao}/${this.props.visitaSelecionada}/CADASTRO_AMOSTRA`, 'basegeo').delete();
      this.setState({
        possuiDuplicata: false,
        possuiDuplicataCadastrada: false,
        duplicata: false
      });
      this.setState({
        idAtividade: response.data.dados.idCadastroAmostra,
      })
      if (!this.state.clickEditar && !this.state.clickExcluir) {
        this.setState({ formularioAberto: false });
        this.mostrarModal(elementoAtual);
      }
      if (this.state.clickEditar) {
        this.setState({
          formularioAberto: false,
          cadastrarNovamente: false,
          valoresIniciais: { modoCadastro: "DEFINITIVO", coordenadas: this.props.coordenadas },
        })
      }

      if (elementoAtual.ilustracoes && elementoAtual.ilustracoes.existentes) {
        elementoAtual.ilustracoes.existentes.forEach(ilustracao => {
          this.props.limparListaArquivos(`arquivosIlustracoes_${ilustracao.id}`);
        });
      }

      await Service('/cadastroAmostra', AMOSTRAS).get(this.state.idAtividade)
        .then(({ data: { dados: atividade } }) => {
          this.props.enviarNotificacaoAtividadeSalva();
          let dadosTabela = [];
          if (atividade.amostras) {
            atividade.amostras = {
              existentes:
                atividade.amostras

            }
            dadosTabela = resolverExibidos(atividade.amostras);
            dadosTabela.map(item => item.idAtividade = this.state.idAtividade);
            dadosTabela.map(item => item.idCadastroAmostra = this.state.idAtividade);
            dadosTabela.map(item => item.idVisita = this.props.visitaSelecionada);
          } else {
            dadosTabela = []
          }
          this.props.referenciaForm.form.mutators.setValue("amostras", atividade.amostras);
          this.setState({
            idItemAtual: null,
            dadosTabela: dadosTabela,
            clickExcluir: false,
            clickEditar: false
          })
          this.setState({
            exibirTela: true,
          })
        })
    } catch (e) {
      console.log(e);
    }

    this.setState({
      exibirTela: false
    });

    this.setState({
      idItemAtual: null,
      exibirTela: true,
      clickExcluir: false,
      clickEditar: false,
      valoresIniciais: {
        modoCadastro: "DEFINITIVO",
        coordenadas: this.props.coordenadas,
        idMetodoGeoposicionamento: this.props.idMetodoGeoposicionamento,
        altitude: this.props.altitude,
        idAtividade: this.state.idAtividade,
        idCadastroAmostra: this.state.idAtividade,
        idVisita: this.props.visitaSelecionada
      }

    })


    await this.atualizarPontosEstacao();
  }

  montarCellRenderPadrao(items, nomeChave) {
    let nome = "";
    items?.map(item => {
      if (item?.id?.toString() === nomeChave?.toString()) {
        nome = item.nome;
      }
    });
    return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${nome}</div>`;
  }


  async preencherIdItemAtual(event, tableRef, tipoPreenchimento) {
    let idItemAtual = event?.rowdata?.rowData?.idExibir ?? event?.rowdata?.rowData?.idVisualizar
    let tempAmostras = this.props.referenciaForm.form.getState().values.amostras;
    delete tempAmostras.editadosPorId;
    this.props.referenciaForm.form.mutators.setValue('amostras', tempAmostras);

    try {
      await Service('/cadastroAmostra/amostra', AMOSTRAS).get(idItemAtual)
        .then(({ data: { dados: atividade = {} } }) => {

          let possuiDuplicataFlag = false
          let duplicataDeAmostraFlag = false

          if (atividade.possuiDuplicata) {
            possuiDuplicataFlag = true;
          }

          if (atividade.duplicata) {
            duplicataDeAmostraFlag = true;
          }

          this.setState({
            possuiDuplicataCadastrada: possuiDuplicataFlag,
            duplicata: duplicataDeAmostraFlag
          });
      
          for (let i = 0; i < atividade.associacoes.length; i++) {
            atividade.associacoes[i].id = i + 1;
          }
          
          atividade.associacoes = {
            existentes: atividade.associacoes
          }

          if (tipoPreenchimento === "editar") {
            this.setState({
              clickEditar: true,
              idItemAtual: idItemAtual,
              valoresIniciais: atividade,
              carregouValoresIniciais: true,
            })
          }
          if (tipoPreenchimento === "visualizar") {
            this.setState({
              clickVisualizar: true,
              permitirEdicao: false,
              idItemAtual: idItemAtual,
              valoresIniciais: atividade
            })
          }
        })
    } catch (e) {

    }

    this.state.exibirModalEdicao = false;
  }

  preencherAssociacoes(items) {
    this.props.referenciaForm.form.mutators.setValue('associacoes', items);
  }

  async excluirAmostra(e, tableRef) {
    e.preventDefault();
    this.setState({
      clickExcluir: true
    })

    const idExcluido = e.value;

    this.setState({
      carregamentoGlobal: true
    })

    await Service("/cadastroAmostra/amostra", AMOSTRAS)
      .delete(idExcluido)
      .catch(this.exibirSwalErroExcluir);
    Service('/cadastroAmostra', AMOSTRAS).get(this.state.idAtividade)
      .then(({ data: { dados: atividade } }) => {
        atividade.tipo = 'CADASTRO_AMOSTRA';
        atividade.nomeTipo = 'Cadastrar amostras'
        let existentes = [];
        if (atividade.amostras) {
          atividade.amostras.map(amostra => {
            existentes.push(amostra);
          })
          atividade.amostras = {
            existentes: existentes ?? []
          }
        } else {
          atividade.amostras = {
            existentes: []
          }
        }
        let dadosTabela = resolverExibidos(atividade.amostras)
        this.props.referenciaForm.form.mutators.setValue('amostras', atividade.amostras);
        this.setState({
          dadosTabela: dadosTabela
        })
        if (dadosTabela.length === 0) {
          const meuBotao = pegarBotaoPeloTexto('Voltar');
          meuBotao?.firstChild.click();
        }
      })

    this.setState({
      carregamentoGlobal: false,
      clickExcluir: false
    })


  }

  exibirSwalErroExcluir = async () => {
    return swalErro({ title: "Não foi possível excluir a atividade" });
  };

  gerarNumeroAmostra(event, idClasseTemp) {
    let idClasse = event?.args?.item?.originalItem?.id ?? idClasseTemp;
    let sequenciaLetrasFinais = "";
    let totalClassesIguais = 0;
    let amostras = this.props.referenciaForm.form.getState().values.amostras ?? [];
    let nomeVisita = "";
    amostras = resolverExibidos(amostras);

    if (amostras && amostras.length)
      amostras = amostras?.filter((a) => a?.numeroAmostra?.slice(a.numeroAmostra.length - 4) != '-DUP');

    let siglaClasse = this?.state?.siglas[idClasse?.toString()] ?? "";
    amostras.map(item => {
      if (item.idClasse === idClasse) {
        totalClassesIguais++;
      }
    })

    let gerador = new LetterSequenceGenerator();
    if (totalClassesIguais > 0) {
      for (let i = 0; i < totalClassesIguais; i++) {
        sequenciaLetrasFinais = gerador.next();
      }
      if (!this.state.clickEditar) {
        sequenciaLetrasFinais = gerador.next();
      }

      sequenciaLetrasFinais = `${sequenciaLetrasFinais}`
    }

    this.props?.visitas?.map(visita => {
      if (visita?.id?.toString() === this?.props?.visitaSelecionada?.toString()) {
        nomeVisita = visita.nome;
      }
    })

    let elementosNomeVisita = nomeVisita.split('-');
    return `${elementosNomeVisita[0]}-${elementosNomeVisita[1]}-${siglaClasse}-${elementosNomeVisita[2]}${sequenciaLetrasFinais}`

  }

  permitirEdicaoClasse() {
    if (this.state.permitirEdicao === false || this.state.clickEditar === true) {
      return true;
    }
  }

  forcarLimparComboBox(className) {
    setTimeout(() => {
      try {
        document.getElementsByClassName(className)[0].children[1].children[0].children[0].textContent = 'Selecione..';
      } catch (err) {
        console.log('Erro ao limpar combo', err);
      }
    }, 500);
  }

  forcarValorCampo(className, valor) {
    setTimeout(() => {
      try {
        document.getElementsByClassName(className)[0].children[1].children[0].children[0].textContent = valor ? valor : '';
      } catch (err) {
        console.log('Erro ao forçar valor na combo', err);
      }
    }, 500);
  }

  async validarExclusaoAmostra(id, rowdata, tableRef, props) {
    try {
      if (this.props.atividadesVisita.idRecmin || this.props.atividadesVisita.idAfloramento) {
        const res = await Service('/cadastroAmostra/amostra', AMOSTRAS).get(id);
        const amostra = res.data.dados;
        let rochasAssociadas = [];
        let possuiAssociacaoRecmin = false;
        let possuiAssociacaoAflora = false

        if (amostra.associacoes && amostra.associacoes.length) {
          if (props.atividadesVisita.idAfloramento) {
            const resAflora = await Service('/afloramentos', AFLORA).get(this.props.atividadesVisita.idAfloramento);
            let aflora = resAflora.data.dados;
            let rochas = resolverExibidos(aflora.rochas);

            amostra.associacoes.forEach(associacao => {
              if (associacao.tipoAtividade === 'AFLORAMENTO') {
                if (associacao.idMaterialGeologico) {
                  let rocha = rochas.find(r => r.id === associacao.idMaterialGeologico);

                  if (rocha)
                    rochasAssociadas.push(rocha.numeroMaterialGeologico)
                }

                possuiAssociacaoAflora = true;
              }
            });
          }

          amostra.associacoes.forEach(associacao => {
            if (associacao.tipoAtividade === 'RECURSO_MINERAL')
              possuiAssociacaoRecmin = true;
          });
          
          if (possuiAssociacaoAflora && possuiAssociacaoRecmin) {
            let texto = '';
            let multiplasRochas = rochasAssociadas.length > 1;

            if (rochasAssociadas.length) {
              texto = multiplasRochas ? `Para a amostra ${amostra.numeroAmostra} estão associadas as rochas ${rochasAssociadas.toString().replace(/,/g, ', ')} e um Recurso mineral, caso seja excluida será desassociada deles` :
                `Para a amostra ${amostra.numeroAmostra} está associada a rocha ${rochasAssociadas[0]} e um Recurso mineral, caso seja excluida será desassociada deles`;
            }
            else {
              texto = `Para a amostra ${amostra.numeroAmostra} está associado um Afloramento e um Recurso mineral, caso seja excluida será desassociada deles`
            }

            texto += ', deseja realmente excluir esse registro ?';

            return swal({
              title: texto,
              icon: 'warning',
              buttons: { cancel: "Não, cancelar", confirm: { text: "Sim, desejo excluir!", className: "btn-danger" } },
            });
          }
          else if (possuiAssociacaoAflora) {
            let multiplasRochas = rochasAssociadas.length > 1;
            let texto = '';

            if (rochasAssociadas.length) {
              texto = multiplasRochas ? `Para a amostra ${amostra.numeroAmostra} estão associadas as rochas ${rochasAssociadas.toString().replace(/,/g, ', ')}, caso seja excluida será desassociada delas` :
                ` Para a amostra ${amostra.numeroAmostra} está associada a rocha ${rochasAssociadas[0]}, caso seja excluida será desassociada dela`;
            }
            else {
              texto = `Para a amostra ${amostra.numeroAmostra} está associado um Afloramento, caso seja excluida será desassociada dele`
            }

            texto += ', deseja realmente excluir esse registro ?';

            return swal({
              title: texto,
              icon: 'warning',
              buttons: { cancel: "Não, cancelar", confirm: { text: "Sim, desejo excluir!", className: "btn-danger" } },
            });
          }
          else if (possuiAssociacaoRecmin) {

            return swal({
              title: `Para a amostra ${amostra.numeroAmostra} está associado um Recurso mineral, caso seja excluida será desassociada dele, deseja realmente excluir esse registro ?`,
              icon: 'warning',
              buttons: { cancel: "Não, cancelar", confirm: { text: "Sim, desejo excluir!", className: "btn-danger" } },
            });

          }
        }
      }
    }
    catch (err) {
      console.log('e', err)
    }

    return await swalConfirmarExclusao();
  }

  aoAbrirSubForm = () => {
    formularioIlustracoesAberto = true;
  };

  aoFecharSubForm = () => {
    formularioIlustracoesAberto = false;
  };


  render() {
    return (
      <BlockUi blocking={this.state.carregamentoGlobal}>
        <>
          {this.state.exibirTela &&
            <Field name={"amostras"} subscription={{ value: true, values: true }}>
              {({ input: { value: amostras = {}, values } }) => {
                return <SubForm
                  criarDecoradores={prefixoSubForm => this.decoradoresAtividades}
                  tituloForm="Amostras cadastradas"
                  exibirBotaoInsercao={this.state.exibirBotaoInsercao}
                  permitirInsercao={this.state.permitirInsercao}
                  permitirEdicao={this.state.permitirEdicao}
                  permitirExclusao={this.state.permitirEdicao}
                  permitirGerarPdf={this.props.permitirGerarPdf}
                  onBtnGerarPdfClick={(event, tableRef, rowData) => {
                    this.props.gerarPdfAmostra(event, tableRef, rowData)
                  }}
                  permitirVisualizacao={!this.state.permitirEdicao && !this.props.permitirGerarPdf}
                  nome="amostras"
                  onSubmit={this.onSubmit.bind(this)}
                  onBtnDeleteClick={this.excluirAmostra.bind(this)}
                  colunas={this.colunasAmostras}
                  campos={this.datafieldsAmostras}
                  exibirCardTabela={true}
                  elementos={
                    amostras
                  }
                  validarVoltar={this.state.permitirEdicao ? this.state.exibirModalEdicao : false}
                  onBtnViewClick={(event, tableRef) => {
                    this.preencherIdItemAtual(event, tableRef, "visualizar")
                  }}
                  parametrosParaTableSource={{
                    width: '100%',
                  }}
                  itensTabela={this.state.dadosTabela}
                  aoConfirmarVoltar={() => {

                    this.setState({
                      possuiDuplicata: false,
                      possuiDuplicataCadastrada: false,
                      duplicata: false,
                      idItemAtual: null, clickEditar: false, valoresIniciais: { possuiDuplicata: false, modoCadastro: "DEFINITIVO", coordenadas: this.props.coordenadas, idMetodoGeoposicionamento: this.props.idMetodoGeoposicionamento, altitude: this.props.altitude }
                    });

                    if (this.props.abrirFormAmostras)
                      this.props.aoFecharSubFormAtividades();
                  }}
                  onBtnEditClick={async (event, tableRef) => await this.preencherIdItemAtual(event, tableRef, "editar")}
                  formularioAberto={this.state.formularioAberto}
                  mutators={{ setValue }}
                  formSubscription={{}}
                  labelBotaoConfirmar="Salvar"
                  valoresIniciais={this.state.valoresIniciais}
                  exibirBotoesPadrao={true}
                  keepDirtyOnReinitialize={true}
                  validarExclusaoAtividade={(id, rowdata, tableRef) => this.validarExclusaoAmostra(id, rowdata, tableRef, this.props)}
                  renderForm={({ formProps }) => {
                    formProps.form.batch(() => {
                      for (const [key, val] of Object.entries(this?.state?.valoresIniciais)) {
                        formProps.form.mutators.setValue(`${key}`, val);
                      }
                      if (this.state.clickEditar) {
                        formProps.form.mutators.setValue('id', this.state.idItemAtual);
                      }
                      formProps.form.mutators.setValue('idAtividade', this.state.idAtividade);
                      formProps.form.mutators.setValue('idCadastroAmostra', this.state.idCadastroAmostra);
                      formProps.form.mutators.setValue('idVisita', this.props.visitaSelecionada);
                    });

                    return <>
                      <FormSpy subscription={{ values: true }}>
                        {({ values }) => {
                          this.state.formValuesCopy = values;

                          if (this.state.carregouValoresIniciais) {
                            let iniciais = Object.assign({}, this.state.valoresIniciais);
                            let form = Object.assign({}, values);

                            iniciais.coordenadas = undefined;
                            form.coordenadas = undefined;

                            iniciais.coordenada = undefined;
                            form.coordenada = undefined;

                            iniciais.idAtividade = undefined;
                            form.idAtividade = undefined;

                            iniciais = Object.keys(iniciais).sort().reduce(
                              (obj, key) => {
                                obj[key] = iniciais[key];
                                return obj;
                              },
                              {}
                            );

                            form = Object.keys(form).sort().reduce(
                              (obj, key) => {
                                obj[key] = form[key];
                                return obj;
                              },
                              {}
                            );

                            let exibir = !isEqual(iniciais, form);

                            this.state.exibirModalEdicao = exibir;
                          }
                          return <></>
                        }}
                      </FormSpy>

                      <Field
                        component={HiddenField}
                        name="forceUpdate"
                        initialValue={null}
                      />

                      <Field
                        component={HiddenField}
                        name="id"
                        initialValue={-3}
                      />
                      <Field
                        component={HiddenField}
                        name="idCadastroAmostra"
                        initialValue={this.props.visitaSelecionada}
                      />
                      <Field
                        component={HiddenField}
                        name="idVisita"
                        initialValue={this.props.visitaSelecionada}
                      />
                      <Field
                        component={HiddenField}
                        name="idAtividade"
                        initialValue={this.state.idAtividade}
                      />
                      <Field
                        component={HiddenField}
                        name="atividadesAssociadas"
                        initialValue={this.state.atividadesAssociadas}
                        duplicata={this.state.duplicata}
                      />
                      <Field
                        component={HiddenField}
                        name="possuiDuplicata"
                        initialValue={this.state.possuiDuplicata}
                      />
                      <Field name={'modoCadastro'} subscription={{ value: true }}>
                        {({ input: { value: modoCadastro = {} } }) => {
                          let desabilitado = false;
                          if ((this.state.clickEditar && modoCadastro === "DEFINITIVO" && this.state.idItemAtual > 1) || !this.state.permitirEdicao) {
                            desabilitado = true;
                          }

                          if (modoCadastro != 'DEFINITIVO')
                            Array.from(document.getElementsByClassName('fa-sync')).forEach(e => e.classList.add('text-muted'));

                          else
                            Array.from(document.getElementsByClassName('fa-sync')).forEach(e => e.classList.remove('text-muted'));


                          return <Field
                            disabled={desabilitado}
                            name="modoCadastro"
                            component={RadioButtonWithFieldSetField}
                            label="Modo de cadastro"
                            dica="O Modo Rascunho permite salvar informações parciais; o Modo Completo exige informações completas conforme presença de (*)"
                            elementos={this.state.modoCadastro}
                            horizontal
                            validate={campoObrigatorioComMsgGenerica(
                              "Modo de cadastro"
                            )}
                          />
                        }}
                      </Field>
                      <Card className="mt-2">
                        <Card.Body>
                          <Row className="">
                            <Col>
                              <Field name={'idsPontosAssociados'} subscription={{ value: true }}>
                                {({ input: { value: idsPontosAssociados = {} } }) => {
                                  return (<PontosGPSAfloramento
                                    idEstacao={this.props.idEstacao}
                                    permitirEdicao={this.state.permitirEdicao}
                                    pontosAtribuidosAEstacao={this.state.pontosGpsDisponiveis}
                                  />);
                                }}
                              </Field>
                            </Col>
                          </Row>
                        </Card.Body>
                        <InformacoesCoordenadas
                          customPaddingAndMargin="p-0 m-0"
                          configMapa={configMapaAmostras}
                          datums={this.props.datums}
                          permitirEdicao={this.state.duplicata ? false : this.state.permitirEdicao}
                          pontosAtribuidosAEstacao={this.props.pontosAtribuidosAEstacao}
                          visitaSelecionada={this.props.visitaSelecionada}
                          area={this.props.area}
                          subArea={this.props.subArea}
                          metodosGeoposicionamento={this.props.metodosGeoposicionamento}
                          categoriasLimiteArea={this.props.categoriasLimiteArea}
                          altitude={true}
                          mapaAtividade={true}
                          fusos={this.props.fusos}
                          coordenadasAtuais={this.props.coordenadas}
                          exibirLegendaEstacao={true}
                          exibirLegendaEstacaoHistorica={false}
                          exibirLegendaEstacaoEditada={false}
                          exibirLegendaAtividade={false}
                          exibirLegendaAmostra={true}
                          exibirLegendaPontoGPS={false}
                          exibirLegendaGeometriaProjeto={true}
                          naoExibirEmCol={true}
                        />
                      </Card>

                      <br></br>
                      <Field name={"idClasse"} subscription={{ value: true }}>
                        {({ input: { value: idClasse } }) => {

                          return (

                            <FormSpy subscription={{ values: true }}>
                              {({ values }) => {
                                let habilitarAssociacoes = this.state.permitirAdicaoAssociacao && !!idClasse;

                                let mensagemDesabilitado = '';

                                if (!this.state.permitirAdicaoAssociacao && !idClasse)
                                  mensagemDesabilitado = '(Desabilitado por falta de Associações possíveis e selecionar uma classe)';
                                else if (!this.state.permitirAdicaoAssociacao)
                                  mensagemDesabilitado = '(Desabilitado por falta de Associações possíveis)';
                                else if (!idClasse)
                                  mensagemDesabilitado = '(Desabilitado por falta de selecionar uma classe)';


                                try {
                                  if (values?.associacoes) {
                                    let associacoes = resolverExibidos(values?.associacoes);
                                   
                                    for (let e of associacoes) {
                                      if (e.tipoAtividade === "AFLORAMENTO") {
                                        if (!this.state.idRecursoMineralDaAtividade) {
                                          habilitarAssociacoes = false;
                                        }
                                      }
                                      if (e.tipoAtividade === "RECURSO_MINERAL") {
                                        if (!this.state.idAfloramentoDaAtividade) {
                                          habilitarAssociacoes = false;
                                        }
                                      }
                                    }
                                  }
                                } catch (error) {
                                  console.log(error)
                                }
                                // Fix temporario para botão de adicionar que não fica ativo ao mudar para disabled=false
                                if (habilitarAssociacoes) {
                                  let id = document.getElementsByClassName('br-button primary small jqx-rc-all jqx-button jqx-widget jqx-fill-state-normal')[0].id;
                                  document.getElementById(id).style = "opacity: 1";
                                }
                                let statusBibliografia = formProps?.form?.getState()?.values?.statusBibliografia === "S"
                               
                                return (
                                  <>
                                    {(statusBibliografia || !habilitarAssociacoes) &&
                                      <AtividadesAssociadas
                                        mensagemDesabilitado={mensagemDesabilitado}
                                        numeroCampoAfloramento={this.state.numeroCampoAfloramento}
                                        numeroCampoRecursoMineral={this.state.numeroCampoRecursoMineral}
                                        idAfloramentoDaAtividade={this.state.idAfloramentoDaAtividade}
                                        idRecursoMineralDaAtividade={this.state.idRecursoMineralDaAtividade}
                                        preencherAssociacoes={this.preencherAssociacoes.bind(this)}
                                        materiaisGeologicosAfloramento={this.state.materiaisGeologicosAfloramento}
                                        materiaisGeologicosRecursoMineral={this.state.materiaisGeologicosRecursoMineral}
                                        permitirEdicao={this.state.duplicata ? false : this.state.permitirEdicao}
                                        referenciaForm={formProps.form}
                                        mutators={{ setValue }}
                                        permitirAdicao={false}
                                        formPropsPai={formProps}
                                        visitas={this.props.visitas}
                                        visitaSelecionada={this.props.visitaSelecionada}
                                        duplicata={this.state.duplicata}
                                        atividadeAflora={this.state.atividadeAflora}                                      
                                      />
                                    }
                                    {!statusBibliografia && habilitarAssociacoes &&
                                      <AtividadesAssociadas
                                        mensagemDesabilitado={mensagemDesabilitado}
                                        numeroCampoAfloramento={this.state.numeroCampoAfloramento}
                                        numeroCampoRecursoMineral={this.state.numeroCampoRecursoMineral}
                                        idAfloramentoDaAtividade={this.state.idAfloramentoDaAtividade}
                                        idRecursoMineralDaAtividade={this.state.idRecursoMineralDaAtividade}
                                        preencherAssociacoes={this.preencherAssociacoes.bind(this)}
                                        materiaisGeologicosAfloramento={this.state.materiaisGeologicosAfloramento}
                                        materiaisGeologicosRecursoMineral={this.state.materiaisGeologicosRecursoMineral}
                                        permitirEdicao={this.state.duplicata ? false : this.state.permitirEdicao}
                                        referenciaForm={formProps.form}
                                        mutators={{ setValue }}
                                        permitirAdicao={true}
                                        formPropsPai={formProps}
                                        visitas={this.props.visitas}
                                        visitaSelecionada={this.props.visitaSelecionada}
                                        duplicata={this.state.duplicata}
                                        atividadeAflora={this.state.atividadeAflora}
                                       
                                      />
                                    }
                                  </>
                                )
                              }}</FormSpy>)

                        }}
                      </Field>
                      <Field name={"idClasse"} subscription={{ value: true }}>
                        {({ input: { value: idClasse } }) => {
                          return <>
                            <Card className="mt-3">
                              <Card.Body>
                                <Row className="mb-3">
                                  <Col md={12}>
                                    <Card.Title>
                                      Informações da amostra
                                    </Card.Title>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col md={12}>
                                    {!this.state.isClasseAmostraHabilitado && <>
                                      <span className="erro-campo" style={{ color: 'red', fontSize: '10px' }}> Alteração da classe fica indisponível enquanto o conteúdo multimídia estiver aberto.</span>
                                    </>}
                                  </Col>
                                </Row>
                                <Row className="mb-3">
                                  <Field name={"idClasse"} subscription={{ value: true }}>
                                    {({ input: { value: idClasse } }) => {

                                      let desabilitar = false;
                                      if (this.state.permitirEdicao === false || this.state.clickEditar === true) {
                                        desabilitar = true;
                                      }
                                      if (!desabilitar && idClasse) {
                                        let nomeAmostra = this.gerarNumeroAmostra(null, formProps.form.getState().values.idClasse);

                                        // Limpar classes de amostra
                                        formProps.form.mutators.setValue('numeroAmostra', nomeAmostra);
                                        formProps.form.mutators.setValue('agua', {});
                                        formProps.form.mutators.setValue('concentradoBateia', {});
                                        formProps.form.mutators.setValue('crosta', {});
                                        formProps.form.mutators.setValue('minerio', {});
                                        formProps.form.mutators.setValue('outros', {});
                                        formProps.form.mutators.setValue('overbank', {});
                                        formProps.form.mutators.setValue('rocha', {});
                                        formProps.form.mutators.setValue('sedimentoCorrente', {});
                                        formProps.form.mutators.setValue('solo', {});
                                        formProps.form.mutators.setValue('vegetacao', {});

                                        // Limpar local de armazenamento 
                                        this.forcarLimparComboBox('idLocalArmazenamento');
                                        formProps.form.mutators.setValue('idLocalArmazenamento', null);
                                      }
                                      return <Col md={3}>
                                        <Field
                                          disabled={this.state.duplicata ? true : desabilitar}
                                          name="idClasse"
                                          component={DropdownListField}
                                          label="Classe"
                                          dica="Selecione a classe da amostra que será coletada"
                                          required
                                          validate={campoObrigatorioComMsgGenerica(
                                            "Classe"
                                          )}
                                          elementos={this.state.classe}
                                          onChange={(event) => {
                                            if (!this.state.clickEditar && !this.state.clickVisualizar) {
                                              let numeroAmostra = this.gerarNumeroAmostra(event);
                                              formProps.form.mutators.setValue("numeroAmostra", numeroAmostra)
                                            }
                                            if (formularioIlustracoesAberto) {
                                              swal({
                                                title: "O conteúdo multimídia, que não foi confirmado, deverá ser anexado novamente.",
                                                icon: "info",
                                                buttons: {
                                                  confirm: {
                                                    text: "Ok",
                                                    className: "btn-primary",
                                                  },
                                                },
                                              })
                                            }
                                          }
                                          }
                                        />
                                      </Col>
                                    }}
                                  </Field>

                                  <Col md={3}>
                                    <Field name="idLocalArmazenamento" subscription={{ value: true }}>
                                      {({ input: { value: idLocalArmazenamento } }) => {
                                        let nomeSelecionado = '';

                                        if (idLocalArmazenamento && this.state?.locaisArmazenamento?.length) {

                                          const itemSelecionado = this.state.locaisArmazenamento.find(item => item.id === idLocalArmazenamento);

                                          if (itemSelecionado)
                                            nomeSelecionado = itemSelecionado.nome;

                                          this.forcarValorCampo('idLocalArmazenamento', nomeSelecionado);
                                        }

                                        return <Field
                                          disabled={!this.props.permitirEdicao}
                                          name="idLocalArmazenamento"
                                          component={DropdownListField}
                                          label="Local de armazenamento"
                                          dica="Informe o local de armazenamento da amostra coletada (Unidade Regional, Litoteca, SECLAB, etc.)"
                                          elementos={this.state.locaisArmazenamento}
                                          customClass={'idLocalArmazenamento'}
                                        />
                                      }}
                                    </Field>
                                  </Col>

                                  <Col md={3}>
                                    <Field name={"numeroAmostra"} subscription={{ value: true }}>
                                      {({ input: { value: numeroAmostra } }) => {
                                        if (this.state.cadastrarNovamente) {
                                          let nomeAmostra = this.gerarNumeroAmostra(null, formProps.form.getState().values.idClasse);
                                          formProps.form.mutators.setValue('numeroAmostra', nomeAmostra)
                                        }
                                        return <Field
                                          name="numeroAmostra"
                                          component={TextInputField}
                                          label="Nome da amostra"
                                          dica="Preenchimento automático, Nome da amostra composto pelo CÓDIGO ÚNICO DO PROJETO - SIGLA DO RESPONSÁVEL - SÍMBOLO DA CLASSE DE AMOSTRA - NÚMERO DA VISITA - LETRA SEQUENCIAL"
                                          initialValue={formProps.form.getState().values.numeroAmostra}
                                          disabled
                                        />
                                      }}
                                    </Field>

                                  </Col>

                                  <Col md={3}>
                                    <Field name={"possuiDuplicata"} subscription={{ value: true }}>
                                      {({ input: { value: possuiDuplicata } }) => {

                                        const atualizaValorDuplicata = (flag) => {
                                          formProps.form.mutators.setValue('possuiDuplicata', flag);
                                          this.state.possuiDuplicata = flag;
                                        };

                                        if (this.state.duplicata) return <></>

                                        return <Field
                                          disabled={this.state.possuiDuplicataCadastrada ? true : !this.props.permitirEdicao}
                                          name='possuiDuplicata'
                                          component={CheckboxField}
                                          label={'Duplicata'}
                                          dica={'Informe se há duplicata para a amostra a ser cadastrada. Em caso positivo, serão cadastradas duas amostras, com a segunda possuindo o sufixo "DUP" no Nome da amostra'}
                                          className={'checkDuplicata'}
                                          checked={this.state.possuiDuplicata || this.state.possuiDuplicataCadastrada}
                                          onCheck={atualizaValorDuplicata}
                                        />
                                      }}
                                    </Field>
                                  </Col>
                                </Row>
                                {formProps.form.getState().values.idClasse?.toString() === "1" &&
                                  <Agua referenciaForm={this.props.referenciaForm.form}
                                    visualizar={!this.state.permitirEdicao}
                                    permitirEdicao={this.state.duplicata ? false : this.state.permitirEdicao}
                                  />}
                                {formProps.form.getState().values.idClasse?.toString() === "2" &&
                                  <ConcentradoBateia duplicata={this.state.duplicata} referenciaForm={this.props.referenciaForm.form}
                                    permitirEdicao={this.state.duplicata ? false : this.state.permitirEdicao}
                                    visualizar={!this.state.permitirEdicao}
                                  />}
                                {formProps.form.getState().values.idClasse?.toString() === "16" &&
                                  <Crosta duplicata={this.state.duplicata} referenciaForm={this.props.referenciaForm.form}
                                    permitirEdicao={this.state.duplicata ? false : this.state.permitirEdicao}
                                    visualizar={!this.state.permitirEdicao}
                                  />}
                                {formProps.form.getState().values.idClasse?.toString() === "8" &&
                                  <Minerio duplicata={this.state.duplicata} referenciaForm={this.props.referenciaForm.form}
                                    permitirEdicao={this.state.duplicata ? false : this.state.permitirEdicao}
                                    visualizar={!this.state.permitirEdicao}
                                  />}
                                {formProps.form.getState().values.idClasse?.toString() === "14" &&
                                  <Outros duplicata={this.state.duplicata} referenciaForm={this.props.referenciaForm.form}
                                    permitirEdicao={this.state.duplicata ? false : this.state.permitirEdicao}
                                    visualizar={!this.state.permitirEdicao}
                                  />}
                                {formProps.form.getState().values.idClasse?.toString() === "17" &&
                                  <Overbank duplicata={this.state.duplicata} referenciaForm={this.props.referenciaForm.form}
                                    permitirEdicao={this.state.duplicata ? false : this.state.permitirEdicao}
                                    visualizar={!this.state.permitirEdicao}
                                  />}
                                {formProps.form.getState().values.idClasse?.toString() === "9" &&
                                  <Rocha duplicata={this.state.duplicata} referenciaForm={this.props.referenciaForm.form}
                                    permitirEdicao={this.state.duplicata ? false : this.state.permitirEdicao}
                                    visualizar={!this.state.permitirEdicao}
                                  />}
                                {formProps.form.getState().values.idClasse?.toString() === "10" &&
                                  <SedimentoCorrente duplicata={this.state.duplicata} referenciaForm={this.props.referenciaForm.form}
                                    permitirEdicao={this.state.duplicata ? false : this.state.permitirEdicao}
                                    visualizar={!this.state.permitirEdicao}
                                  />}
                                {formProps.form.getState().values.idClasse?.toString() === "7" &&
                                  <Solo duplicata={this.state.duplicata} referenciaForm={this.props.referenciaForm.form}
                                    permitirEdicao={this.state.duplicata ? false : this.state.permitirEdicao}
                                    visualizar={!this.state.permitirEdicao}
                                  />}
                                {formProps.form.getState().values.idClasse?.toString() === "12" &&
                                  <Vegetacao duplicata={this.state.duplicata} referenciaForm={this.props.referenciaForm.form}
                                    permitirEdicao={this.state.duplicata ? false : this.state.permitirEdicao}
                                    visualizar={!this.state.permitirEdicao}
                                  />}
                              </Card.Body>
                            </Card>
                          </>
                        }}
                      </Field>
                      <Card className="mt-3 mb-3">
                        <Card.Body className="p-4 mt-4">
                          <Field name={"numeroAmostra"} subscription={{ value: true }}>
                            {({ input: { value: numeroAmostra } }) => {
                              let tempNumeroAmostra = formProps.form.getState().values.numeroAmostra ?? ""
                              let valoresIniciais = formProps.form.getState().values.ilustracoes ?? [];
                              valoresIniciais = resolverExibidos(valoresIniciais);
                              if (tempNumeroAmostra) {
                                for (let i = 0; i < valoresIniciais.length; i++) {
                                  valoresIniciais[i].nomeGerado = `${tempNumeroAmostra}-${i + 1}`
                                }
                              }
                              valoresIniciais = {
                                novos: [valoresIniciais]
                              }
                              return <IlustracoesAmostrasContainer
                                props={this.props}
                                state={this.state}
                                valoresIniciais={valoresIniciais}
                                tempNumeroAmostra={tempNumeroAmostra}
                                aoAbrirSubForm={this.aoAbrirSubForm}
                                aoFecharSubForm={this.aoFecharSubForm}
                              />
                            }}
                          </Field>

                        </Card.Body>
                      </Card>
                    </>
                  }}
                />
              }}
            </Field>
          }
        </>
      </BlockUi>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    limparListaArquivos(chave) {
      dispatch(
        limparArquivos({
          store: chave
        })
      )
    },
    enviarNotificacaoError(mensagem) {
      dispatch(
        mostrarNotificacao(mensagem, {
          tipo: "error",
        })
      );
    },
    enviarNotificacaoSucesso(mensagem) {
      dispatch(
        mostrarNotificacao(mensagem, {
          tipo: "success",
        })
      );
    },
    enviarNotificacaoAtividadeSalva() {
      dispatch(
        mostrarNotificacao('Atividade salva com sucesso', { tipo: 'success' }, true)
      );
    },
    enviarNotificacaoAtividadeTemporariaSalva() {
      dispatch(
        mostrarNotificacao('Por segurança seus dados foram salvos de forma temporária', { tipo: 'info' }, true)
      );
    },
  };
};

AmostrasInternal = connect(
  null,
  mapDispatchToProps
)(AmostrasInternal);

export const Amostras = AmostrasInternal;

export default Amostras;
