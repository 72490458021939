import { toInteger } from "lodash";
import { resolverExibidos } from "../../../../components/SubForm/SubForm";
import { ARRAY_ERROR, FORM_ERROR } from "final-form";
import { IGNEAS_METAMORFICAS } from "../contantes/constantesComuns";

export const validacoesGeraisPetrografia = (valores) => {

  //valida soma total dos percentuais das compocicoes mineralogicas
  if (valores?.composicoesMineralogicas) {
    const composicoes = resolverExibidos(valores?.composicoesMineralogicas);

    if (composicoes && composicoes.length > 0) {
      const somaPercentuais = composicoes.reduce((total, item) => {

        if (item?.numeroPercentual) {
          return total + toInteger(item?.numeroPercentual);
        }
        return total;
      }, 0);

      if (somaPercentuais !== 100) {
        return { [FORM_ERROR]: 'A soma dos percentuais da Composição mineralógica deve ser 100%.' };
      }
    }
  }
  //valida soma total dos percentuais dos minerais alteracao
  if (valores?.mineraisAlteracao) {
    const composicoes = resolverExibidos(valores?.mineraisAlteracao);

    if (composicoes && composicoes.length > 0) {
      const somaPercentuais = composicoes.reduce((total, item) => {

        if (item?.numeroPercentual) {
          return total + toInteger(item?.numeroPercentual);
        }
        return total;
      }, 0);

      if (somaPercentuais !== 100) {
        return { [FORM_ERROR]: 'A soma dos percentuais de minerais de alteração deve ser 100%.' };
      }
    }
  }

  //valida obrigatoriedade da fonter de referencia
  if (!valores?.fontesReferencia) {
    return {
      [FORM_ERROR]: 'Fonte de referência é obrigatório.',
      [ARRAY_ERROR]: 'Fonte de referência é obrigatório.'
    };
  }

  //valida soma total dos percentuais de porosidade
  if (valores?.porosidades) {
    const porosidades = resolverExibidos(valores?.porosidades);

    if (porosidades && porosidades.length > 0) {
      const somaPercentuais = porosidades.reduce((total, item) => {

        if (item?.porcentagemPoros) {
          return total + toInteger(item?.porcentagemPoros);
        }
        return total;
      }, 0);

      if (somaPercentuais !== 100) {
        return { [FORM_ERROR]: 'A soma das % de poros de porosidade deve ser 100%.' };
      }
    }
  }

};

export const validacoesArcabouco = (form) => {
  let arcaboucos = form.getState().values?.arcaboucos;
  if (!arcaboucos) {
    return
  }

  let arcaboucosTela = resolverExibidos(arcaboucos);
  const temSomaPorcentagemDosSubformsDiferenteDe100 = arcaboucosTela.some(item => {
    const fragmentosMinerais = item?.fragmentosMinerais;
    if (!fragmentosMinerais) {
      return false;
    }
    
    const itens = resolverExibidos(fragmentosMinerais);
    const totalPorcentagem = itens.reduce((total, objeto) => total + objeto.porcentagemArcabouco, 0);

    return totalPorcentagem !== 100;
  });
  console.log('temSomaPorcentagemDosSubformsDiferenteDe100', temSomaPorcentagemDosSubformsDiferenteDe100)
  if (temSomaPorcentagemDosSubformsDiferenteDe100) {
    return {
      [FORM_ERROR]: 'A soma das % deve ser 100%.',
      [ARRAY_ERROR]: 'A soma das % deve ser 100%.'
    };
  }else {
    return null;
  }

};