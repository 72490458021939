import React, { useCallback, useEffect, useState } from "react";
import { RadioButtonSet } from "../../Jqx/RadioButtonSet";
import converterVetorParaObjetoIndexado from "../../../util/converterVetorParaObjetoIndexado";
import RadioButtonSetWithFieldSet from "../../Jqx/RadioButtonSet/RadioButtonSetWithFieldSet";

// eu sei que o nome ficou ruim maaaaas foi necessário
const RadioButtonWithFieldSetField = ({input, meta, elementos, extratorId, valorDefault, ...props}) => {
  const onChange = useCallback((val) => {
    input.onChange(extratorId(val))
  }, [input, extratorId])

  const [cacheElementos, setCacheElementos] = useState({})

  useEffect(() => {
    setCacheElementos(converterVetorParaObjetoIndexado(elementos, {
      extratorId,
    }))
  }, [elementos, extratorId])

  const valor = !!input.value
    ? cacheElementos[input.value]
    : valorDefault ? valorDefault : null;

  const mensagemErro = meta.touched && !meta.valid && meta.error;

  return (
    <>
    <RadioButtonSetWithFieldSet
      {...props}
      valor={valor}
      onClick={onChange}
      elementos={elementos}
      extratorId={extratorId}
      mensagemErro={mensagemErro}
    />
    </>
  )
}

RadioButtonWithFieldSetField.propTypes = {
  ...RadioButtonSet.propTypes,
}

RadioButtonWithFieldSetField.defaultProps = {
  ...RadioButtonSet.defaultProps,
}

export default RadioButtonWithFieldSetField
