import React from "react";
import { Col, Row } from "react-bootstrap";
import { Field } from "react-final-form";
import { DropdownListField } from "../../../../../components/field/DropdownListField";
import { resolverRequestAmostras } from "../AmostrasUtils/AmostraRequestUtils";
import CamposComuns from "./CamposComuns";

class Rocha extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tipoAlteracao: [],
      grauIntemperismo: [],
      matrizPredominante: [],
      texturaRocha: [],
    }
  };

  async componentDidMount() {
    this.setState({
      tipoAlteracao: await resolverRequestAmostras("/biblioteca/tipoalteracao"),
      grauIntemperismo: await resolverRequestAmostras("/biblioteca/grauintemperismo"),
      matrizPredominante: await resolverRequestAmostras("/biblioteca/matrizpredominante"),
      texturaRocha: await resolverRequestAmostras("/biblioteca/texturarocha"),
    })
  }


  render() {
    return <>
      <CamposComuns
        duplicata={this.props.duplicata}
        permitirEdicao={this.props.permitirEdicao}
        labelCampo="Peso da amostra (g)"
        dicaCampo="Informe o peso da amostra, em gramas"
        profundidadeObrigatoria={false}
        informacoesRelevantesObrigatorias={true}
        prefixo={'rocha'}
        visualizar={this.props.visualizar}
      />
      <Row className="mt-3">
        <Col md={6}>
        <Field
            name="rocha.idGrauIntemperismo"
            subscription={{ value: true }}>
            {({ input: { value: idGrau } }) => {
              let listaFiltrada = this.state.grauIntemperismo.filter(grau => grau.atual === 'S');

              if (idGrau && this.state.grauIntemperismo.length && !listaFiltrada.find((grau) => grau.id === idGrau)) {
                const item = this.state.grauIntemperismo.find((grau) => grau.id === idGrau);

                if (item && item.atual === 'N') {
                  listaFiltrada.push(item);
                  listaFiltrada = listaFiltrada.sort((a, b) => a.nome.localeCompare(b.nome))
                }
              }
              return <Field
                component={DropdownListField}
                name="rocha.idGrauIntemperismo"
                label="Grau de intemperismo"
                dica="Selecione o grau de intemperismo da rocha coletada"
                elementos={listaFiltrada}
                disabled={!this.props.permitirEdicao}
              />
            }}
          </Field>
        </Col>
        <Col md={6}>
          <Field 
           name={'rocha.idTipoAlteracao'}
           subscription={{ value: true }}>
             {({ input: { value: tipoAlteracao } }) => {
                                
                let listaFiltrada = this.state.tipoAlteracao.filter((t) => t.atual === 'S');

                if(tipoAlteracao && this.state.tipoAlteracao.length && !listaFiltrada.find((t) => t.id === tipoAlteracao)){
                    const item = this.state.tipoAlteracao.find((t) => t.id === tipoAlteracao);

                    if(item && item.atual === 'N'){
                      listaFiltrada.push(item);
                      listaFiltrada = listaFiltrada.sort((a, b) => a.nome.localeCompare(b.nome))
                    }
                }

                return <Field
                  disabled={!this.props.permitirEdicao}
                  name="rocha.idTipoAlteracao"
                  component={DropdownListField}
                  label="Tipo de alteração hidrotermal"
                  dica="Selecione o tipo de alteração hidrotermal observado no material coletado, se aplicável"
                  elementos={listaFiltrada}
                />
            }}
          </Field>
        </Col>
      </Row>
      <Row className="mt-3 mb-3">
        <Col md={12}>
          <Field
            disabled={!this.props.permitirEdicao}
            name="rocha.idsTexturasRocha"
            component={DropdownListField}
            label="Textura da rocha"
            dica="Selecione a textura da rocha coletada"
            elementos={this.state.texturaRocha}
            checkboxes={true}
          />
        </Col>
      </Row>
    </>
  }

}

export default Rocha;
