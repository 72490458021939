import PropTypes from "prop-types";
import { FocusableButton } from "../../../../../../components/Jqx/Button";

function BotalVoltarAprovacao({ aoVoltar }) {
    return <FocusableButton
        className="m-2"
        onClick={aoVoltar}>
        Voltar
    </FocusableButton>
}

BotalVoltarAprovacao.propTypes = {
    aoVoltar: PropTypes.func,
}

export { BotalVoltarAprovacao };
