import React from "react";
import { Col, Row } from "react-bootstrap";
import { Field } from "react-final-form";
import { DropdownListField } from "../../../../../components/field/DropdownListField";
import { NumberInputField } from "../../../../../components/field/NumberInputField";
import { campoObrigatorioDefinitivoComMsgGenerica } from "../../../../../util/validadores";
import { resolverRequestAmostras } from "../AmostrasUtils/AmostraRequestUtils";
import CamposComuns from "./CamposComuns";

class ConcentradoBateia extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      velocidadeCorrente: [],
      nivelAgua: [],
      areaDrenagem: [],
      turbidezAgua: [],
      posicaoLeito: [],
      corAgua: [],
      grauArredondamento: [],
      corMaterialColetado: [],
      situacaoAmostra: [],
    }
  };


  async componentDidMount() {
    this.setState({
      velocidadeCorrente: await resolverRequestAmostras("/amostra/agua/velocidadeCorrente"),
      nivelAgua: await resolverRequestAmostras("/biblioteca/nivelagua"),
      areaDrenagem: await resolverRequestAmostras("/biblioteca/areadrenagem"),
      turbidezAgua: await resolverRequestAmostras("/biblioteca/turbidez"),
      posicaoLeito: await resolverRequestAmostras("/amostra/agua/posicaoLeito"),
      corAgua: await resolverRequestAmostras("/biblioteca/amostras/cor"),
      grauArredondamento: await resolverRequestAmostras("/biblioteca/arredondamento"),
      corMaterialColetado: await resolverRequestAmostras("/biblioteca/amostras/cor"),
      situacaoAmostra: await resolverRequestAmostras("/biblioteca/situacaoamostra"),
    })
  }


  render() {
    return <>
      <CamposComuns
        duplicata={this.props.duplicata}
        materialColetadoPreenchido={true}
        permitirEdicao={this.props.permitirEdicao}
        labelCampo="Peso da amostra (g)"
        dicaCampo="Informe o peso da amostra, em gramas"
        profundidadeObrigatoria={false}
        prefixo={'concentradoBateia'}
        informacoesRelevantesObrigatorias={true}
        visualizar={this.props.visualizar}
      />

      <Field subscription={{ value: true }} name={'modoCadastro'}>
      {({ input: { value: modoCadastro } }) => {

        const validar = modoCadastro === 'DEFINITIVO';

        return <>
              <Row className="mt-3">
        <Col md={4}>
          <Field
            disabled={!this.props.permitirEdicao}
            name="concentradoBateia.idSituacaoAmostra"
            component={DropdownListField}
            label="Situação da amostra"
            dica="Selecione a situação que melhor se aplica à coleta do material amostrado"
            elementos={this.state.situacaoAmostra}
          />
        </Col>
        <Col md={4}>
          <Field
            disabled={!this.props.permitirEdicao}
            name="concentradoBateia.larguraRio"
            component={NumberInputField}
            permitirNegativos={false}
            label="Largura do rio (m)"
            dica="Informe a largura do rio, se aplicável"
            casasDecimais={4}
            casasInteiros={4}
          />
        </Col>
        <Col md={4}>
          <Field
            disabled={!this.props.permitirEdicao}
            name="concentradoBateia.profundidadeRio"
            component={NumberInputField}
            permitirNegativos={false}
            label="Profundidade do rio (m)"
            dica="Informe a profundidade do rio, se aplicável"
            casasDecimais={4}
            casasInteiros={4}
          />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col md={4}>
          <Field
            disabled={!this.props.permitirEdicao}
            name="concentradoBateia.idVelocidadeCorrente"
            component={DropdownListField}
            label="Velocidade da corrente"
            dica="Selecione a velocidade da corrente, se aplicável"
            elementos={this.state.velocidadeCorrente}
          />
        </Col>
        <Col md={4}>
          <Field
            disabled={!this.props.permitirEdicao}
            name="concentradoBateia.idNivelAgua"
            component={DropdownListField}
            label="Nível da água"
            dica="Selecione o nível d'água da drenagem, se aplicável"
            elementos={this.state.nivelAgua}
          />
        </Col>
        <Col md={4}>
          <Field
            disabled={!this.props.permitirEdicao}
            name="concentradoBateia.idPosicaoLeito"
            component={DropdownListField}
            label="Posição do leito"
            dica="Selecione a posição da amostra no leito do rio, se aplicável"
            elementos={this.state.posicaoLeito}
          />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col md={4}>
          <Field
            disabled={!this.props.permitirEdicao}
            name="concentradoBateia.idGrauArredondamento"
            component={DropdownListField}
            label="Grau de arredondamento"
            dica="Selecione o grau de arredondamento do material original amostrado, se aplicável"
            elementos={this.state.grauArredondamento}
          />
        </Col>
        <Col md={4}>
          <Field
            disabled={!this.props.permitirEdicao}
            name="concentradoBateia.volumeOriginal"
            component={NumberInputField}
            permitirNegativos={false}
            label="Volume original (l)"
            dica="Informe o volume inicial do material amostrado, em litros"
            required={validar}
            casasDecimais={4}
            casasInteiros={4}
            validate={campoObrigatorioDefinitivoComMsgGenerica(
              "Volume original"
            )}
          />
        </Col>
        <Col md={4}>
          <Field
            disabled={!this.props.permitirEdicao}
            name="concentradoBateia.idCorMaterialColetado"
            component={DropdownListField}
            label="Cor do material coletado"
            dica="Selecione a cor predominante do material coletado"
            elementos={this.state.corMaterialColetado}
          />
        </Col>
      </Row>
      <Row className="mt-3 mb-3">
        <Col md={4}>
          <Field
            disabled={!this.props.permitirEdicao}
            name="concentradoBateia.idAreaDrenagem"
            component={DropdownListField}
            label="Área de drenagem"
            dica="Selecione a área da bacia de drenagem, se aplicável"
            elementos={this.state.areaDrenagem}
          />
        </Col>
        <Col md={4}>
          <Field
            disabled={!this.props.permitirEdicao}
            name="concentradoBateia.idTurbidezAgua"
            component={DropdownListField}
            label="Turbidez da água"
            dica="Selecione o nível de turbidez da água no momento da amostragem, se aplicável"
            elementos={this.state.turbidezAgua}
          />
        </Col>
        <Col md={4}>
          <Field
            disabled={!this.props.permitirEdicao}
            name="concentradoBateia.idCorAgua"
            component={DropdownListField}
            label="Cor da água"
            dica="Selecione a cor da água, se aplicável"
            elementos={this.state.corAgua}
          />
        </Col>
      </Row>
        
        </>
      }}
      </Field>
    </>
  }

}

export default ConcentradoBateia;
