import converterVetorParaObjetoIndexado from "../util/converterVetorParaObjetoIndexado";
import Service, { AFLORA } from "./Service";

export const getBibliotecasAfloramento = async () => {
    const [
        tiposAfloramento,
        maturidadesComposicionais,
        maturidadesTexturais,
        esfericidades,
        granulacoes,
        arredondamentos,
        relacoesArcaboucoMatriz,
        naturezasMatriz,
        texturas,
        indicesCor,
        naturezasComposicaoSedimentar,
        granulometrias,
        selecoes,
        rochasNomeadas,
        geometrias,
        grausIntemperismos,
        tiposRelacaoContato,
        tiposMetamorfismo,
        hierarquias,
        faciesMetamorfismo,
        formasOcorrencia,
        tiposAlteracaoHidrotermal,
        intensidadesAlteracaoHidrotermal,
        tiposEstrutura,
        tiposNotacao,
        tiposEstruturaTectonica,
        tiposRochaEnclave,
        classesRocha,
        subClassesRocha,
    ] = await Promise.all([
        Service("/afloramentos/tipos", AFLORA).query(),
        Service("/rochas/maturidadesComposicionais", AFLORA).query(),
        Service("/rochas/maturidadesTexturais", AFLORA).query(),
        Service("/rochas/esfericidades", AFLORA).query(),
        Service("/rochas/granulacoes", AFLORA).query(),
        Service("/rochas/arredondamentos", AFLORA).query(),
        Service("/rochas/relacoesArcaboucoMatriz", AFLORA).query(),
        Service("/rochas/naturezasMatriz", AFLORA).query(),
        Service("/rochas/texturas", AFLORA).query(),
        Service("/rochas/indicesCor", AFLORA).query(),
        Service("/rochas/naturezasComposicao", AFLORA).query(),
        Service("/rochas/granulometrias", AFLORA).query(),
        Service("/rochas/selecoes", AFLORA).query(),
        Service("/rochas/nomeadas", AFLORA).query(),
        Service("/rochas/geometrias", AFLORA).query(),
        Service("/intemperismos/graus", AFLORA).query(),
        Service("/tiposRelacaoContato", AFLORA).query(),
        Service("/tiposMetamorfismo", AFLORA).query(),
        Service("/hierarquias", AFLORA).query(),
        Service("/faciesMetamorfismo", AFLORA).query(),
        Service("/formasOcorrenciaAlteracaoHidrotermal", AFLORA).query(),
        Service("/tiposAlteracaoHidrotermal", AFLORA).query(),
        Service("/intensidadesAlteracaoHidrotermal", AFLORA).query(),
        Service("/tiposEstrutura", AFLORA).query(),
        Service("/tiposNotacao", AFLORA).query(),
        Service("/tiposEstruturaTectonica", AFLORA).query(),
        Service("/tiposRochaEnclave", AFLORA).query(),
        Service("/tipoRocha", AFLORA).query(),
        Service("/subclasserocha", AFLORA).query(),
    ]);
    return {
        tiposAfloramento: tiposAfloramento.data.dados,
        maturidadesComposicionais: maturidadesComposicionais.data.dados,
        maturidadesTexturais: maturidadesTexturais.data.dados,
        esfericidades: esfericidades.data.dados,
        granulacoes: granulacoes.data.dados,
        arredondamentos: arredondamentos.data.dados,
        relacoesArcaboucoMatriz: relacoesArcaboucoMatriz.data.dados,
        naturezasMatriz: naturezasMatriz.data.dados,
        texturas: texturas.data.dados,
        indicesCor: indicesCor.data.dados,
        naturezasComposicaoSedimentar: naturezasComposicaoSedimentar.data.dados,
        granulometrias: granulometrias.data.dados,
        selecoes: selecoes.data.dados,
        rochasNomeadas: rochasNomeadas.data.dados,
        geometrias: geometrias.data.dados,
        grausIntemperismos: grausIntemperismos.data.dados,
        tiposRelacaoContato: tiposRelacaoContato.data.dados,
        tiposMetamorfismo: tiposMetamorfismo.data.dados,
        hierarquias: hierarquias.data.dados,
        faciesMetamorfismo: faciesMetamorfismo.data.dados,
        formasOcorrencia: formasOcorrencia.data.dados,
        tiposAlteracaoHidrotermal: tiposAlteracaoHidrotermal.data.dados,
        intensidadesAlteracaoHidrotermal: intensidadesAlteracaoHidrotermal.data.dados,
        tiposEstrutura: tiposEstrutura.data.dados,
        tiposNotacao: tiposNotacao.data.dados,
        tiposEstruturaTectonica: tiposEstruturaTectonica.data.dados,
        tiposRochaEnclave: tiposRochaEnclave.data.dados,
        classesRocha: classesRocha.data.dados.filter(r => r.id === 1 || r.id === 2 || r.id === 5),
        subClassesRocha: subClassesRocha.data.dados,
        rochasNomeadasPorId: converterVetorParaObjetoIndexado(
          rochasNomeadas.data.dados
        ),
    };
}