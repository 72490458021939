import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Field } from "react-final-form";
import { aoEnviarSubForm } from "../../../../../util/mutadores";
import Card from "../../../../../components/card";
import { SubForm } from "../../../../../components/SubForm";
import { DropdownListField } from "../../../../../components/field/DropdownListField";
import { resolverExibidos } from "../../../../../components/SubForm/SubForm";
import FragmentosMinerais from "./FragmentosMinerais";
import ComponentesAloquimicos from "./ComponentesAloquimicos";
import FragmentosLiticos from "./FragmentosLiticos";
import { useEffect, useState } from "react";
import Service, { BASEGEO, PETRO } from "../../../../../service/Service";
import { buscaPercentuaisArcabouco, calcularSomaPercentuais, validacoesGeraisArcabouco } from "../../../utils/gerais";

const Arcabouco = ({
    permitirEdicao,
    form,
    bbUnidadeMedida,
    bbMinerais,
    bbPrefixo,
    bbRocha,
    bbComplemento,
    exibeArcabouco,
    permitirInsercao,
    jaCadastrouArcabouco,
    disabled
}) => {
    const [bbArredondamento, setBbArredondamento] = useState([]);
    const [bbEsfericidade, setBbEsfericidade] = useState([]);
    const [bbSelecionamento, setBbSelecionamento] = useState([]);
    const [bbAloquimicoCarbonato, setBbAloquimicoCarbonato] = useState([]);

    const onSubmit = aoEnviarSubForm(form);

    const colunasArcaboucos = [
        {
            text: 'Fragmentos minerais',
            datafield: 'idMinerais',
            width: '30%',
            cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
                let nomesCorrespondentes = "";
                const arcaboucos = resolverExibidos(form.getState().values?.arcaboucos);
                const arcaboucosDaLinha = arcaboucos?.[row];
                const fragmentosMinerais = arcaboucosDaLinha?.fragmentosMinerais;

                if (fragmentosMinerais) {
                    const fragmentosMineraisTela = resolverExibidos(fragmentosMinerais);

                    if (!fragmentosMineraisTela || !fragmentosMineraisTela.length) {
                        console.log("Nenhum fragmento mineral na tela.");
                    }

                    const listaDeIds = fragmentosMineraisTela.map(({ idMineral }) => idMineral);

                    nomesCorrespondentes = bbMinerais
                        .filter(({ id }) => listaDeIds.includes(id))
                        .map(({ nome }) => `${nome}, `)
                        .join('')
                        .replace(/,\s*$/, '');
                }

                return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;" title="${nomesCorrespondentes}">${nomesCorrespondentes}</div>`;
            }
        },
        {
            text: 'Fragmentos líticos',
            datafield: 'idLiticos',
            width: '30%',
            cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {

                const arcaboucos = resolverExibidos(form.getState().values?.arcaboucos);
                const arcaboucosDaLinha = arcaboucos?.[row];
                const fragmentosLiticos = arcaboucosDaLinha?.fragmentosLiticos;
                let listFragmentos = [];
                if (fragmentosLiticos) {
                    const fragmentosLiticosTela = resolverExibidos(fragmentosLiticos);

                    if (!fragmentosLiticosTela || !fragmentosLiticosTela.length) {
                        console.log("Nenhum fragmento lítico na tela.");
                    }
                    
                    for (let i = 0; i < fragmentosLiticosTela.length; i++) {
                        const fragmento = fragmentosLiticosTela[i];
                        const prefixo = bbPrefixo.find(elemento => elemento.id === fragmento?.idPrefixo);
                        const rocha = bbRocha.find(elemento => elemento.id === fragmento?.idRocha);
                        const complemento = bbComplemento.find(elemento => elemento.id === fragmento?.idComplemento);
                    
                        let valor = '';

                        valor += prefixo?.nome ? prefixo?.nome : '';
                        if (rocha?.nome || complemento?.nome) {
                            valor += ' ';
                        }

                        valor += rocha?.nome ? rocha?.nome : '';
                        if (complemento?.nome && (i !== fragmentosLiticosTela.length - 1)) {
                            valor += ' ';
                        }

                        valor += complemento?.nome ? complemento?.nome : '';
                        listFragmentos.push(valor);
                    }
                    

                }
                let nomesCorrespondentes = listFragmentos.join(", ");
                return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;" title="${nomesCorrespondentes}">${nomesCorrespondentes}</div>`;
            }
        },
        {
            text: 'Componentes aloquímicos',
            datafield: 'idAloquimicos',
            width: '30%',
            cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
                let nomesCorrespondentes = "";
                const arcaboucos = resolverExibidos(form.getState().values?.arcaboucos);
                const arcaboucosDaLinha = arcaboucos?.[row];
                const componentesAloquimicos = arcaboucosDaLinha?.componentesAloquimicos;

                if (componentesAloquimicos) {
                    const componentesAloquimicosTela = resolverExibidos(componentesAloquimicos);

                    if (!componentesAloquimicosTela || !componentesAloquimicosTela.length) {
                        console.log("Nenhum componente deposicional na tela.");
                    }

                    const listaDeIds = componentesAloquimicosTela.map(({ idAloquimicoCarbonato }) => idAloquimicoCarbonato);

                    nomesCorrespondentes = bbAloquimicoCarbonato
                        .filter(({ id }) => listaDeIds.includes(id))
                        .map(({ nome }) => `${nome}, `)
                        .join('')
                        .replace(/,\s*$/, ''); 
                }

                return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;" title="${nomesCorrespondentes}">${nomesCorrespondentes}</div>`;
            }
        }
    ]
    const camposArcaboucos = [
        { name: "idMinerais", type: "number", map: "idMinerais" },
        { name: "idLiticos", type: "number", map: "idLiticos" },
        { name: "idAloquimicos", type: "number", map: "idAloquimicos" },
    ];

    async function carregarBibliotecas() {
        try {
            const bbSelecionamento = await Service('/bibliotecas/selecionamento', BASEGEO).query()
            const { data: { dados: selecionamento = [] } } = bbSelecionamento;
            setBbSelecionamento(selecionamento)


            const bbEsfericidade = await Service('/bibliotecas/esfericidade', BASEGEO).query()
            const { data: { dados: esfericidade = [] } } = bbEsfericidade;
            setBbEsfericidade(esfericidade)


            const bbArredondamento = await Service('/bibliotecas/arredondamento', BASEGEO).query()
            const { data: { dados: arredondamento = [] } } = bbArredondamento;
            setBbArredondamento(arredondamento)

            const bbAloquimicoCarbonato = await Service('/bibliotecas/aloquimico-carbonato', PETRO).query()
            const { data: { dados: aloquimicoCarbonato = [] } } = bbAloquimicoCarbonato;
            setBbAloquimicoCarbonato(aloquimicoCarbonato)

        } catch (err) {
            console.log("Erro ao consultar bibliotecas: ", err);
        }
    }
    useEffect(() => {
        carregarBibliotecas()
    }, []);

    return (
        <>
            <div style={{ marginTop: '2.5rem', display: exibeArcabouco }}>
                <Card className="mt-3">
                    <Card.Body>
                        <Card.Title>Arcabouço</Card.Title>
                        <Field name={'arcaboucos'} subscription={{ value: true }}>
                            {({ input: { value: arcaboucos = [] } }) => (
                                <SubForm
                                    nome={'arcaboucos'}
                                    onSubmit={onSubmit}
                                    exibirTabelaSemRegistro={false}
                                    colunas={colunasArcaboucos}
                                    elementos={arcaboucos}
                                    campos={camposArcaboucos}
                                    podeExcluirEntidade={!disabled}
                                    exibirBotaoInsercao={!(disabled || jaCadastrouArcabouco)}
                                    validar={(valores) => validacoesGeraisArcabouco(valores)}
                                    permitirInsercao={permitirInsercao}
                                    permitirEdicao={!disabled}
                                    permitirExclusao={!disabled}
                                    permitirVisualizacao={disabled}
                                    renderForm={({ formProps }) => {
                                        let fragmentosMinerais = [];

                                        if (formProps.form.getState().values?.fragmentosMinerais) {
                                            fragmentosMinerais = resolverExibidos(formProps.form.getState().values?.fragmentosMinerais)
                                        }
                                        let fragmentosLiticos = [];

                                        if (formProps.form.getState().values?.fragmentosLiticos) {
                                            fragmentosLiticos = resolverExibidos(formProps.form.getState().values?.fragmentosLiticos)
                                        }
                                        let componentesAloquimicos = [];

                                        if (formProps.form.getState().values?.componentesAloquimicos) {
                                            componentesAloquimicos = resolverExibidos(formProps.form.getState().values?.componentesAloquimicos)
                                        }

                                        const somaTotalPercentuaisFragmentosMinerais = calcularSomaPercentuais(buscaPercentuaisArcabouco(fragmentosMinerais, 'porcentagemArcabouco'));
                                        const somaTotalPercentuaisFragmentosLiticos = calcularSomaPercentuais(buscaPercentuaisArcabouco(fragmentosLiticos, 'porcentagemFracao'));
                                        const somaTotalPercentuaisComponentesAloquimicos = calcularSomaPercentuais(buscaPercentuaisArcabouco(componentesAloquimicos, 'porcentagemFracao'));

                                        return (
                                            <>
                                                <FragmentosMinerais
                                                    form={formProps.form}
                                                    permitirEdicao={true}
                                                    bbUnidadeComprimento={bbUnidadeMedida}
                                                    bbMinerais={bbMinerais}
                                                    disabled={disabled}
                                                    somaTotalPercentuais={somaTotalPercentuaisFragmentosMinerais}
                                                />
                                                <FragmentosLiticos
                                                    form={formProps.form}
                                                    permitirEdicao={true}
                                                    bbPrefixoRocha={bbPrefixo}
                                                    bbRocha={bbRocha}
                                                    bbSufixoRocha={bbComplemento}
                                                    bbUnidadeComprimento={bbUnidadeMedida}
                                                    disabled={disabled}
                                                    somaTotalPercentuais={somaTotalPercentuaisFragmentosLiticos}
                                                />
                                                <ComponentesAloquimicos
                                                    form={formProps.form}
                                                    permitirEdicao={true}
                                                    bbAloquimicoCarbonato={bbAloquimicoCarbonato}
                                                    bbUnidadeComprimento={bbUnidadeMedida}
                                                    disabled={disabled}
                                                    somaTotalPercentuais={somaTotalPercentuaisComponentesAloquimicos}
                                                />
                                                <div style={{ marginTop: '2.5rem' }}>
                                                    <Card className="mt-3">
                                                        <Card.Body>
                                                            <Card.Title>Estrutura do arcabouço</Card.Title>
                                                            <Row className="my-3">
                                                                <Col md={4}>
                                                                    <Field
                                                                        component={DropdownListField}
                                                                        name={"idSelecao"}
                                                                        label="Seleção"
                                                                        dica="Selecione o grau de seleção observado nos componentes do arcabouço. "
                                                                        elementos={bbSelecionamento}
                                                                        displayMember={'nome'}
                                                                        disabled={disabled}
                                                                    />
                                                                </Col>
                                                                <Col md={4}>
                                                                    <Field
                                                                        component={DropdownListField}
                                                                        name={"idArredondamento"}
                                                                        label="Arredondamento"
                                                                        dica="Selecione o arredondamento observado nos componentes do arcabouço."
                                                                        elementos={bbArredondamento}
                                                                        displayMember={'nome'}
                                                                        disabled={disabled}
                                                                    />
                                                                </Col>
                                                                <Col md={4}>
                                                                    <Field
                                                                        component={DropdownListField}
                                                                        name={"idEsfericidade"}
                                                                        label="Esfericidade"
                                                                        dica="Selecione a esfericidade observada nos componentes do arcabouço."
                                                                        elementos={bbEsfericidade}
                                                                        displayMember={'nome'}
                                                                        disabled={disabled}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </Card.Body>
                                                    </Card>
                                                </div>
                                            </>
                                        )
                                    }
                                    }
                                />
                            )}
                        </Field>
                    </Card.Body >
                </Card>
            </div>
        </>
    );
};

export default Arcabouco;