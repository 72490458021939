import { combineReducers } from "redux";
import { fileReducer } from "./fileReducers";
import { notificacaoReducer } from "./notificacaoReducer";
import { authReducer } from "./authReducers";
import { visitaReducer } from "./visitaReducer";

export default combineReducers({
  files: fileReducer,
  notificacoes: notificacaoReducer,
  autenticacao: authReducer,
  visita: visitaReducer,
});
