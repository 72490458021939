import React, { useCallback, useEffect, useRef, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {
  AMOSTRA,
  OPCAO_ROCHA_SEDIMENTAR,
  OPCAO_ROCHA_SEDIMENTAR_INCONSOLIDADA,
  TIPO_ROCHA_AFLORAMENTO_IGNEA,
  TIPO_ROCHA_AFLORAMENTO_METAMORFICA,
  TIPO_ROCHA_AFLORAMENTO_SEDIMENTAR,
  TIPO_ROCHA_AFLORAMENTO_METASSEDIMENTAR,
  TIPO_ROCHA_AFLORAMENTO_META_IGNEA
} from "../../../../../util/constantes";
import Graos from "./Graos";
import Clastos from "./Clastos";
import { Field, useField, useForm, FormSpy } from "react-final-form";
import { DropdownListField } from "../../../../../components/field/DropdownListField";
import { campoObrigatorioComMsgGenerica, rochaContemMinerais, validarSe } from "../../../../../util/validadores";
import { SubForm, LegacySubForm } from "../../../../../components/SubForm";
import { TextInput } from "../../../../../components/Jqx/TextInput";
import { NumberInputField } from "../../../../../components/field/NumberInputField";
import { TextAreaField } from "../../../../../components/field/TextAreaField";
import { RadioButtonSetField, RadioButtonWithFieldSetField } from "../../../../../components/field/RadioButtonSetField";
import MineraisRocha from "./MineraisRocha";
import { getPropsPermissao, resolverExibidos } from "../../../../../components/SubForm/SubForm";
import resolverNomeRocha from "../../../utils/resolverNomeRocha";
import { swalConfirmarExclusao } from "../../../../../util/validadores";
import { Container, Button } from "react-bootstrap";
import Card from "../../../../../components/card";
import swal from "sweetalert";
import { useDispatch } from "react-redux";

import Fields from "../../../../../util/Fields";
import { Table, createControlColumn } from "../../../../../components/Jqx/Table";
import { TextInputField } from "../../../../../components/field/TextInputField";
import Service, { RECMIN, AMOSTRAS } from "../../../../../service/Service";
import { flushSync } from "react-dom";
import { Modal } from "../../../../../components/Sobre/modal/Modal/Modal";
import { CounterField } from "../../../../../components/field/CounterField";
import { mostrarNotificacao } from "../../../../../reducers/notificacaoReducer";

const opcoesRochaSedimentar = [
  OPCAO_ROCHA_SEDIMENTAR,
  OPCAO_ROCHA_SEDIMENTAR_INCONSOLIDADA,
]

const possuiMateriaOrganica = [
  { id: "SIM", nome: "Sim" },
  { id: "NAO", nome: "Não" },
];

const camposRochas = [
  { name: "nomeRocha", type: "string", map: "nomeRocha" },
  { name: "idRochaNomeada", type: "number", map: "idRochaNomeada" },
  { name: "idComplemento", type: "number", map: "idComplemento", },
  { name: "estimativaPercentual", type: "number", map: "estimativaPercentual" },
  { name: "numeroMaterialGeologico", type: "string", map: "numeroMaterialGeologico" },
]

const colunasRochas = [
  {
    text: "Nome", datafield: "nomeRocha", width: "70%",
  },
  {
    text: "Material geológico", datafield: "numeroMaterialGeologico", width: "12%",
  },
  {
    text: "Estimativa Percentual", datafield: "estimativaPercentual", width: "12%",
  },
];


const dataFieldsAssociacao = [
  { name: "vinculado", type: "boolean", map: "vinculado" },
  { name: "id", type: "number", map: "id" },
  { name: "numeroAmostra", type: "string", map: "numeroAmostra" },
]

/**
 * @user_story [23811]
 * Solicitada a remoção mensagem de alerta
 * quanto ao valor do percentual cadastrado.
 */
// const PercentualMineraisRocha = ({ prefixoNome = '' }) => {
//   const fieldMinerais = useField(`${prefixoNome}mineraisNomenclatura`, { subscription: { value: true } })

//   let mineraisExibidos = []

//   if (fieldMinerais.input.value) {
//     mineraisExibidos = resolverExibidos(fieldMinerais.input.value)
//   }

//   const somatoriaPorcentagem = mineraisExibidos && mineraisExibidos.length > 0 ?
//     mineraisExibidos.map(it => it.proporcao != null && it.proporcao != undefined ? it.proporcao : 0).reduce((accumulator, currentValue) => accumulator + currentValue)
//     : 0;
//   let soma = parseFloat(somatoriaPorcentagem ? somatoriaPorcentagem.toFixed(2) : 0);
//   if (soma !== 100 && soma !== 0) {
//     //TODO: deve exibir mensagem informativa de que os minerais não totalizam 100% e por este motivo a Atividade será salva como Rascunho.
//     //entender como fazer com que isso ocorra.
//     // return <Field
//     //   component={TextInputField}
//     //   name={`${prefixoNome}percentualMineraisRocha`}
//     //   label="Percentual de minerais na rocha"
//     //   dica="Percentual de minerais na rocha"
//     //   disabled={true}
//     //   validate={campoObrigatorioComMsgGenerica("Minerais selecionados não compõem 100% da rocha", {forcarMensagemEnviada : true})}
//     //   required
//     //   placeholder={somatoriaPorcentagem }
//     // />
//     return <small className="text-danger">{`Minerais selecionados compõem ${soma}% da rocha.`}</small>
//   }

//   return <> </>

// }

const idsMineraisNomenclaturaRocha = (idsMineraisAtual = []) => {
  return idsMineraisAtual
    .filter((it) => it.compoeNomeRocha === "SIM" && it.proporcao > 5)
    .sort((min1, min2) => min2.proporcao - min1.proporcao)
    .map(it => it.idMineral)
    .slice(0, 4)
}

const NomeCompletoRocha = ({
  mineraisPorId,
  rochasNomeadasPorId,
  prefixosPorId,
  complementosPorId,
  prefixoNome = '',
}) => {
  const fieldRochaNomeada = useField(`${prefixoNome}idRochaNomeada`, { subscription: { value: true } })
  const fieldMinerais = useField(`${prefixoNome}mineraisNomenclatura`, { subscription: { value: true } })
  const fieldPrefixo = useField(`${prefixoNome}idPrefixo`, { subscription: { value: true } })
  const fieldComplemento = useField(`${prefixoNome}idComplemento`, { subscription: { value: true } })
  const fieldNomeRocha = useField('nomeRocha');

  let mineraisExibidos = []

  if (fieldMinerais.input.value) {
    mineraisExibidos = resolverExibidos(fieldMinerais.input.value)
  }

  const idsMinerais = idsMineraisNomenclaturaRocha(mineraisExibidos)


  const valor = resolverNomeRocha({
    mineraisPorId,
    rochasNomeadasPorId,
    prefixosPorId,
    complementosPorId,

    idsMinerais,
    idPrefixo: fieldPrefixo.input.value,
    idRochaNomeada: fieldRochaNomeada.input.value,
    idComplemento: fieldComplemento.input.value,
  })

  // Inserir nome gerado da rocha no campo, para ser enviado e acessível ao backend
  fieldNomeRocha.input.onChange(valor)

  return (<TextInput valor={valor} disabled maxLength={250} />)
}

const TipoRochaEh = ({ tipo, children }) => {
  const tipoRocha = useField("idTipoRocha", {
    subscription: { value: true },
  })

  let tipoContido = false
  if (tipo.length && tipo.length > 0) {
    tipoContido = tipo.map(it => it?.id).includes(tipoRocha.input.value)
  } else {
    tipoContido = tipo?.id === tipoRocha.input.value
  }

  return tipoContido ? children : null
}


let formNovoIntemperismo = undefined;
let formNovaAlteracaoHidrotermal = undefined;
let valoresIniciaisIntemperismo = undefined;
let valoresIniciaisAltercaoHidrotermal = undefined;
let valoresNovasTexturas = undefined;
let tabelaCompatibilizacao = undefined;
let numeroAmostra = '';
let idRochaNova = undefined;
let step = 1;
let totalSteps = 0;

const Rochas = ({
  nome,
  aoAbrirSubForm,
  aoFecharSubForm,
  formularioAberto,
  onSubmit,
  // elementos,
  valoresIniciais,
  permitirEdicao,
  multimidiaAberto,
  fecharMultimidia,
  presencasMateriaOrganica = [],
  maturidadesComposicionais = [],
  maturidadesTexturais = [],
  esfericidades = [],
  granulacoes = [],
  arredondamentos = [],
  relacoesArcaboucoMatriz = [],
  naturezasMatriz = [],
  texturas = [],
  indicesCor = [],
  naturezasComposicaoSedimentar = [],
  granulometrias = [],
  selecoes = [],
  rochasNomeadas = [],
  minerais = [],
  prefixos = [],
  complementos = [],
  geometrias = [],
  cores = [],
  referenciaForm,
  mineraisPorId,
  rochasNomeadasPorId,
  prefixosPorId,
  complementosPorId,
  modoDefinitivoSelecionado,
  visitaSelecionada,
  classesRocha = [],
  subClassesRocha = [],
  rochasComErro,
  atividadesVisita,
  grausIntemperismos = [],
  formasOcorrencia = [],
  tiposAlteracaoHidrotermal = [],
  intensidadesAlteracaoHidrotermal = [],
  hierarquias = [],
}) => {
  const form = useForm();
  const dispatch = useDispatch();
  const [elementos, setElementos] = useState(form.getState().values.rochas);
  const [idsExcluidos, setIdsExcluidos] = useState([])
  const [clickEditar, setClickEditar] = useState(false);
  const [clickVisualizar, setClickVisualizar] = useState(false);
  const [amostrasRocha, setAmostrasRocha] = useState([]);
  const [recmin, setRecmin] = useState({});
  const [possuiErros, setPossuiErros] = useState(false);

  useEffect(() => {
    setPossuiErros(rochasComErro);
  }, [rochasComErro])

  // Ref de Table
  /** @type {React.RefObject<Table>} */
  const tabelaRochasRef = React.createRef();
  const tabelaAssociacaoRef = React.createRef();

  function forcarLimparComboBox(className) {
    setTimeout(() => {
      try {
        document.getElementsByClassName(className)[0].children[1].children[0].children[0].textContent = 'Selecione..';
      } catch (err) {
        console.log('Erro ao limpar combo', err);
      }
    }, 500);
  }

  function forcarValorCampo(className, valor) {
    setTimeout(() => {
      try {
        document.getElementsByClassName(className)[0].children[1].children[0].children[0].textContent = valor ? valor : '';
      } catch (err) {
        console.log('Erro ao forçar valor na combo', err);
      }
    }, 500);
  }


  function limparCompatibilizacao(formRochaRef) {
    formNovoIntemperismo = undefined;
    formNovaAlteracaoHidrotermal = undefined;
    valoresIniciaisIntemperismo = undefined;
    valoresIniciaisAltercaoHidrotermal = undefined;
    valoresNovasTexturas = undefined;
    tabelaCompatibilizacao = undefined;
    numeroAmostra = '';
    idRochaNova = undefined;
    step = 1;
    totalSteps = 0;

    if (formRochaRef) {
      flushSync(() => {
        formRochaRef.form.mutators.remove('exibirModal');
        formRochaRef.form.mutators.setValue('exibirModal', []);
        formRochaRef.form.change('exibirModal', []);
      });
    }
  }

  async function confirmaFecharModal(formRochaRef) {
    const flag = await swal({
      title: 'A sincronização não será efetuada.',
      icon: 'warning',
      buttons: { cancel: "Cancelar", confirm: { text: "Ok", value: true, className: 'btn-primary' } },
    });

    if (flag)
      limparCompatibilizacao(formRochaRef);
  }

  async function compatibilizarCampos(idAmostra, formRochaRef) {
    try {
      const amostraRes = await Service('/cadastroAmostra/amostra', AMOSTRAS).get(idAmostra);
      const amostra = amostraRes.data.dados;
      let criarNovoIntemperismo = false;
      let criarNovaAlteracaoHidrotermal = false;
      let grausIntemperismo = undefined;
      let tipoAlteracaoHidro = undefined;
      let texturasAmostra = undefined;
      let idClasse = amostra.idClasse;
      let idRocha = formRochaRef.form.getState().values.id;
      let classeRocha = formRochaRef.form.getState().values.idTipoRocha;
      let tabelaIntemperismo = { naoSeAplica: true, valorAtual: '', valorNovo: '' };
      let tabelaAlteracaoHidro = { naoSeAplica: true, valorAtual: '', valorNovo: '' };
      let tabelaTextura = { naoSeAplica: true, valorAtual: '', valorNovo: '' };
      numeroAmostra = amostra.numeroAmostra;

      switch (idClasse) {
        // Minerio
        case 8: {
          grausIntemperismo = amostra.minerio.idGrauIntemperismo;
          tipoAlteracaoHidro = amostra.minerio.idTipoAlteracao;
          break;
        }

        // Rocha
        case 9: {
          if (amostra.rocha.idsTexturasRocha && amostra.rocha.idsTexturasRocha.length)
            texturasAmostra = amostra.rocha.idsTexturasRocha;

          grausIntemperismo = amostra.rocha.idGrauIntemperismo;
          tipoAlteracaoHidro = amostra.rocha.idTipoAlteracao;
          break;
        }

        // Outros
        case 14: {
          grausIntemperismo = amostra.outros.idGrauIntemperismo;
          tipoAlteracaoHidro = amostra.outros.idTipoAlteracao;
          break;
        }

        // Crosta
        case 16: {
          grausIntemperismo = amostra.crosta.idGrauIntemperismoCrosta;
          break;
        }
      }

      if ((grausIntemperismo !== null && grausIntemperismo !== undefined && grausIntemperismo !== '') || tipoAlteracaoHidro ||
        (texturasAmostra && (classeRocha === TIPO_ROCHA_AFLORAMENTO_IGNEA.id || classeRocha === TIPO_ROCHA_AFLORAMENTO_METAMORFICA.id))) {
        idRochaNova = idRocha;

        if (texturasAmostra && (classeRocha === TIPO_ROCHA_AFLORAMENTO_IGNEA.id || classeRocha === TIPO_ROCHA_AFLORAMENTO_METAMORFICA.id)) {
          tabelaTextura.naoSeAplica = false;
          tabelaTextura.valorNovo = texturasAmostra.map(id => texturas.find(textura => textura.id == id).nome).toString().replace(/,/g, ', ');
          let idsTexturasRocha = formRochaRef.form.getState()?.values?.idsTexturas;

          if (idsTexturasRocha) {
            tabelaTextura.valorAtual = idsTexturasRocha
              .map(id => texturas.find(textura => textura.id == id).nome).toString().replace(/,/g, ', ');
          }

          valoresNovasTexturas = texturasAmostra;
        }

        if (grausIntemperismo !== null && grausIntemperismo !== undefined && grausIntemperismo !== '') {
          const valoresIntemp = {
            idRocha: 1,
            idGrau: grausIntemperismo
          };

          tabelaIntemperismo.naoSeAplica = false;
          tabelaIntemperismo.valorNovo = grausIntemperismos.find(i => i.id === grausIntemperismo).nome;

          if (!referenciaForm.getState().values.intemperismos) {
            criarNovoIntemperismo = true;
            valoresIniciaisIntemperismo = valoresIntemp;
          }
          else {
            const intemperismosFormObj = referenciaForm.getState().values.intemperismos;
            const intemperismos = resolverExibidos(intemperismosFormObj);
            const intemperismoRocha = intemperismos.find(i => i.idRocha === idRocha);

            if (!intemperismoRocha) {
              criarNovoIntemperismo = true;
              valoresIniciaisIntemperismo = valoresIntemp;
            }
            else {
              tabelaIntemperismo.valorAtual = grausIntemperismos.find(i => i.id === intemperismoRocha.idGrau).nome;
              intemperismoRocha.idGrau = grausIntemperismo;

              if (intemperismoRocha.id < 0) {
                let indexIntemperismo = intemperismosFormObj.novos.findIndex(i => i.id === intemperismoRocha.id);
                intemperismosFormObj.novos[indexIntemperismo] = intemperismoRocha;
              }
              else {
                if (intemperismosFormObj.editadosPorId && intemperismosFormObj.editadosPorId[`id_${intemperismoRocha.id}`])
                  intemperismosFormObj.editadosPorId[`id_${intemperismoRocha.id}`].idGrau = grausIntemperismo;
                else {
                  intemperismosFormObj.editadosPorId = {};
                  intemperismosFormObj.editadosPorId[`id_${intemperismoRocha.id}`] = intemperismoRocha;
                }
              }

              flushSync(() => {
                referenciaForm.mutators.remove('intemperismos');
                referenciaForm.mutators.setValue('intemperismos', intemperismosFormObj);
                referenciaForm.change('intemperismos', intemperismosFormObj);
              });
            }
          }
        }

        if (tipoAlteracaoHidro) {

          const valoresAltHidro = {
            idRocha: 1,
            idTipo: tipoAlteracaoHidro
          };

          tabelaAlteracaoHidro.naoSeAplica = false;
          tabelaAlteracaoHidro.valorNovo = tiposAlteracaoHidrotermal.find(i => i.id === tipoAlteracaoHidro).nome;

          if (!referenciaForm.getState().values.alteracoesHidrotermais) {
            criarNovaAlteracaoHidrotermal = true;
            valoresIniciaisAltercaoHidrotermal = valoresAltHidro;
          }
          else {
            const alteracoesHidrotermaisFormObj = referenciaForm.getState().values.alteracoesHidrotermais;
            const alteracoesHidrotermais = resolverExibidos(alteracoesHidrotermaisFormObj);
            const alteracaoHidrotermalRocha = alteracoesHidrotermais.find(i => i.idRocha === idRocha);

            if (!alteracaoHidrotermalRocha) {
              criarNovaAlteracaoHidrotermal = true;
              valoresIniciaisAltercaoHidrotermal = valoresAltHidro;
            }
            else {
              tabelaAlteracaoHidro.valorAtual = tiposAlteracaoHidrotermal.find(i => i.id === alteracaoHidrotermalRocha.idTipo).nome;
              alteracaoHidrotermalRocha.idTipo = tipoAlteracaoHidro;

              if (alteracaoHidrotermalRocha.id < 0) {
                let indexAlteracao = alteracoesHidrotermaisFormObj.novos.findIndex(i => i.id === alteracaoHidrotermalRocha.id);
                alteracoesHidrotermaisFormObj.novos[indexAlteracao] = alteracaoHidrotermalRocha;
              }
              else {
                if (alteracoesHidrotermaisFormObj.editadosPorId && alteracoesHidrotermaisFormObj.editadosPorId[`id_${alteracaoHidrotermalRocha.id}`])
                  alteracoesHidrotermaisFormObj.editadosPorId[`id_${alteracaoHidrotermalRocha.id}`].idTipo = tipoAlteracaoHidro;
                else {
                  alteracoesHidrotermaisFormObj.editadosPorId = {};
                  alteracoesHidrotermaisFormObj.editadosPorId[`id_${alteracaoHidrotermalRocha.id}`] = alteracaoHidrotermalRocha;
                }
              }

              flushSync(() => {
                referenciaForm.mutators.remove('alteracoesHidrotermais');
                referenciaForm.mutators.setValue('alteracoesHidrotermais', alteracoesHidrotermaisFormObj);
                referenciaForm.change('alteracoesHidrotermais', alteracoesHidrotermaisFormObj);
              });
            }
          }
        }

        let total = 2;

        if (criarNovaAlteracaoHidrotermal)
          total += 1;

        if (criarNovoIntemperismo)
          total += 1;

        step = 1;
        totalSteps = total;

        tabelaCompatibilizacao = {
          intemperismo: tabelaIntemperismo,
          alteracaoHidro: tabelaAlteracaoHidro,
          texturas: tabelaTextura
        };

        let exibirModal = ['INICIO', 'CONCLUSAO'];
        if (criarNovoIntemperismo)
          exibirModal.push('INTEMPERISMO');

        if (criarNovaAlteracaoHidrotermal)
          exibirModal.push('ALTERACAO_HIDROTERMAL');

        flushSync(() => {
          formRochaRef.form.mutators.remove('exibirModal');
          formRochaRef.form.mutators.setValue('exibirModal', exibirModal);
          formRochaRef.form.change('exibirModal', exibirModal);
        });
      }
      else {
        await swal({
          title: `Não há preenchimento dos campos compatíveis na Amostra (${amostra.numeroAmostra}).`,
          icon: 'info',
          buttons: { cancel: "Cancelar" },
        });
      }
    }
    catch (err) {
      console.log("Erro ao compatibilizar campos: ", err);
    }
  }

  function carregarAmostras() {
    Service('/cadastroAmostra/visita', AMOSTRAS).get(visitaSelecionada)
      .then(({ data: { dados: amostras } }) => {
        let tempArray = [];
        amostras.map(amostra => tempArray.push({ id: amostra?.id, numeroAmostra: amostra.numeroAmostra }));

        let filtrada = []
        filtrada = amostras.filter((a) => !a.numeroAmostra.includes('-DUP'));

        amostras.forEach((a) => {
          if (a.numeroAmostra.includes('-DUP')) {
            filtrada.forEach((f) => {
              if (f.numeroAmostra + '-DUP' === a.numeroAmostra) {
                f.numeroAmostra = `${f.numeroAmostra} / ${a.numeroAmostra}`;
              }
            });
          }
        });

        setAmostrasRocha(filtrada)
      })
  }


  function carregarRecmin() {
    if (atividadesVisita.idRecmin)
      Service('/recursoMineral', RECMIN).get(atividadesVisita.idRecmin)
        .then(({ data: { dados: recmin } }) => {
          setRecmin(recmin);
        })
        .catch();
  }

  useEffect(() => {
    carregarAmostras();
    carregarRecmin();
  }, [rochasNomeadasPorId])

  // Usado para que possamos referenciar esse objeto de dentro do callback que
  // determina se um elemento pode ser deletado, sem precisar recriar o callback.
  // Não recriar o callback significa que a tabela não vai fazer rerenders
  // automáticos, que são devagares, mas nós precisamos dar um refresh manualmente
  // na tabela quando o valor dentro desse ref muda sem nada mais mudar na tabela.
  const dependentesRochaRef = useRef({});

  // Atualiza dependentes e retorna true se uma atualização foi necessária.
  // O array de dependentes fica em um ref, então é necessário fazer atualizações
  // manuais depois de chamar este callback.
  const atualizarDependentes = useCallback((newForm) => {
    const dependentesNovos = {
      alteracoesHidrotermais: (newForm ?? form).getState().values?.alteracoesHidrotermais,
      estruturasPrimarias: (newForm ?? form).getState().values?.estruturasPrimarias,
      estruturasTectonicas: (newForm ?? form).getState().values?.estruturasTectonicas,
      intemperismos: (newForm ?? form).getState().values?.intemperismos,
      metamorfismos: (newForm ?? form).getState().values?.metamorfismos,
      rochasEnclave: (newForm ?? form).getState().values?.rochasEnclave,
      relacoesContato: (newForm ?? form).getState().values?.relacoesContato,
    };
    // Se houve mudanças, faz a tabela renderizar novamente. Isso não garante
    // que o update é realmente necessário, mas é difícil saber quando não é.
    if (JSON.stringify(dependentesRochaRef.current) !== JSON.stringify(dependentesNovos)) {
      // Atualiza o ref de dependentes para a próxima vez que este callback for
      // chamado.
      dependentesRochaRef.current = dependentesNovos;
      return true;
    }
    return false;
  }, []);

  // Quando os valores do form mudam, atualizamos os dependentes e depois a
  // tabela. A tabela renderiza relativamente devagar, então só damos o refresh
  // quando os dependentes realmente atualizaram.
  // Note que isso não funciona sempre. Também temos uma lógica parecida dentro
  // de um Field onde temos um form com os outros campos da tela.
  useEffect(() => {
    if (atualizarDependentes()) {
      tabelaRochasRef.current?.refreshItems();
    }
  }, [
    form.getState().values
  ]);

  // Este callback é criado uma vez só, e serve para a tabela saber se pode
  // deletar um elemento.
  const podeDeletarRocha = useCallback((elemento) => {
    let dependentesRocha = dependentesRochaRef.current;

    for (let dependente in dependentesRocha) {
      let filhoDaRocha = dependentesRocha[dependente]

      for (let indice = 0; indice <= filhoDaRocha?.existentes?.length; indice++) {
        if (filhoDaRocha?.idsExcluidos?.includes(filhoDaRocha?.existentes[indice]?.id)) continue
        if (filhoDaRocha?.existentes[indice]?.idRocha === elemento?.idExibir || filhoDaRocha?.existentes[indice]?.idsRochas?.includes(elemento?.idExibir))
          return false;
      }

      for (let indice = 0; indice <= filhoDaRocha?.novos?.length; indice++) {
        if (filhoDaRocha?.novos[indice]?.idRocha === elemento?.idExibir || filhoDaRocha?.novos[indice]?.idsRochas?.includes(elemento?.idExibir))
          return false;
      }
    }

    return true;
  }, []);

  function aoExcluirRocha(event, tableRef) {
    event.preventDefault();
    let idExcluido = event.value;
    let tempIdsExcluidos = idsExcluidos;
    tempIdsExcluidos.push(idExcluido);
    setIdsExcluidos(tempIdsExcluidos);
    let novoArrayRochas = [];
    let tempElementos = resolverExibidos(elementos);
    tempElementos.map(rocha => {
      if (rocha?.id.toString() !== idExcluido.toString()) {
        novoArrayRochas.push(rocha);
      }
    })

    novoArrayRochas = novoArrayRochas.sort((a, b) => a.numeroMaterialGeologico.substring(a.numeroMaterialGeologico.length, a.numeroMaterialGeologico.length - 3) - b.numeroMaterialGeologico.substring(b.numeroMaterialGeologico.length, b.numeroMaterialGeologico.length - 3));
    for (let i = 0; i < novoArrayRochas.length; i++) {
      let arrayCasas = Array.from(String(i), Number);
      let nome = "";
      for (let y = arrayCasas.length; y < 3; y++) {
        nome += "0"
      }
      nome = `MG-${nome}${i + 1}`;
      novoArrayRochas[i].numeroMaterialGeologico = nome;
    }

    let novos = novoArrayRochas.filter((item) => item.id < 0);
    let existentes = novoArrayRochas.filter((item) => item.id > 0);

    tempElementos = {
      idsExcluidos: [...new Set(idsExcluidos)],
      existentes: existentes.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i),
      novos: novos.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i)
    };

    setElementos(tempElementos);
    form.mutators.setValue('rochas', tempElementos);
    tableRef.current?.updatebounddata();
    tableRef.current?.refresh();
  }

  function onSubmitRocha({ alteracoesForm }) {
    const referenciaFormValues = referenciaForm.getState().values
    if (formNovoIntemperismo) {
      let formInt = formNovoIntemperismo.novosElementos.novos[0];
      formInt.idRocha = idRochaNova;

      let intemperismosFormObj = referenciaFormValues.intemperismos;

      if (!intemperismosFormObj)
        intemperismosFormObj = {};

      let novosInts = intemperismosFormObj.novos;

      if (!novosInts)
        novosInts = [];

      novosInts.push(formInt);

      intemperismosFormObj.novos = novosInts;

      let newForm = {
        novos: intemperismosFormObj.novos,
        existentes: intemperismosFormObj.existentes,
        editadosPorId: intemperismosFormObj.editadosPorId,
        idsExcluidos: intemperismosFormObj.idsExcluidos,
      }

      referenciaForm.mutators.setValue('intemperismos', newForm);
    }

    if (formNovaAlteracaoHidrotermal) {
      let formAlt = formNovaAlteracaoHidrotermal.novosElementos.novos[0];
      formAlt.idRocha = idRochaNova;

      let alteracoesFormObj = referenciaFormValues.alteracoesHidrotermais;

      if (!alteracoesFormObj)
        alteracoesFormObj = {};

      let novasAlts = alteracoesFormObj.novos;

      if (!novasAlts)
        novasAlts = [];

      novasAlts.push(formAlt);

      alteracoesFormObj.novos = novasAlts;

      let newForm = {
        novos: alteracoesFormObj.novos,
        existentes: alteracoesFormObj.existentes,
        editadosPorId: alteracoesFormObj.editadosPorId,
        idsExcluidos: alteracoesFormObj.idsExcluidos,
      }

      referenciaForm.mutators.setValue('alteracoesHidrotermais', newForm);
    }

    formNovoIntemperismo = undefined;
    formNovaAlteracaoHidrotermal = undefined;
    valoresIniciaisIntemperismo = undefined;
    valoresIniciaisAltercaoHidrotermal = undefined;
    idRochaNova = undefined;
    step = 1;
    totalSteps = 0;

    form.batch(() => {
      for (const [key, val] of Object.entries(alteracoesForm)) {
        form.mutators.setValue(`${key}`, val);
      }
    });
    const rochas = form.getState().values.rochas;
    setElementos(rochas ?? []);
    setClickEditar(false);
    setClickVisualizar(false);
  }

  async function validarExclusaoRocha(id, rowdata, tableRef) {
    try {
      let rocha = tableRef.current.tableData.localdata.find(r => r.id === id);
      let materialGeologicoRocha = rocha.numeroMaterialGeologico;
      let amostrasAssociadas = [];
      let possuiAssociacaoRecmin = false;

      if (rocha.idsAmostrasAssociadas && rocha.idsAmostrasAssociadas.length)
        rocha.idsAmostrasAssociadas.forEach(id => amostrasAssociadas.push(amostrasRocha.find(amostra => amostra.id == id).numeroAmostra));

      if (recmin && recmin.estudoMetalogenetico) {
        const estudos = resolverExibidos(recmin.estudoMetalogenetico);

        estudos.forEach(estudo => {
          if (estudo.metalogenia && estudo.metalogenia.dadosDescritivos && estudo.metalogenia.dadosDescritivos.rochasEncaixantes) {
            const encaixantes = resolverExibidos(estudo.metalogenia.dadosDescritivos.rochasEncaixantes);

            encaixantes.forEach(encaixante => {
              if (encaixante.idRochaAfloramento === id)
                possuiAssociacaoRecmin = true;
            });
          }

          if (estudo.metalogenia && estudo.metalogenia.dadosDescritivos && estudo.metalogenia.dadosDescritivos.rochasHospedeiras) {
            const hospedeiras = resolverExibidos(estudo.metalogenia.dadosDescritivos.rochasHospedeiras);

            hospedeiras.forEach(hospedeira => {
              if (hospedeira.idRochaAfloramento === id)
                possuiAssociacaoRecmin = true;
            });
          }
        });
      }

      if (amostrasAssociadas.length && possuiAssociacaoRecmin) {
        let multiplasAmostras = amostrasAssociadas.length > 1;

        let texto = multiplasAmostras ? `Para a rocha ${materialGeologicoRocha} estão associadas as amostras ${amostrasAssociadas.toString().replace(/,/g, ', ')} e um Recurso Mineral, caso seja excluida será desassociada deles` :
          `Para a rocha ${materialGeologicoRocha} está associada a amostra ${amostrasAssociadas[0]} e um Recurso Mineral, caso seja excluida será desassociada deles`;

        texto += ', deseja realmente excluir esse registro ?';

        return swal({
          title: texto,
          icon: 'warning',
          buttons: { cancel: "Não, cancelar", confirm: { text: "Sim, desejo excluir!", className: "btn-danger" } },
        });
      }
      else if (amostrasAssociadas.length) {
        let multiplasAmostras = amostrasAssociadas.length > 1;

        let texto = multiplasAmostras ? `Para a rocha ${materialGeologicoRocha} estão associadas as amostras ${amostrasAssociadas.toString().replace(/,/g, ', ')}, caso seja excluida será desassociada delas` :
          `Para a rocha ${materialGeologicoRocha} está associada a amostra ${amostrasAssociadas[0]}, caso seja excluida será desassociada dela`;

        texto += ', deseja realmente excluir esse registro ?';

        return swal({
          title: texto,
          icon: 'warning',
          buttons: { cancel: "Não, cancelar", confirm: { text: "Sim, desejo excluir!", className: "btn-danger" } },
        });
      }
      else if (possuiAssociacaoRecmin) {
        return swal({
          title: `Para a rocha ${materialGeologicoRocha} està associado um Recurso mineral, caso seja excluida será desassociada dele, deseja realmente excluir esse registro ?`,
          icon: 'warning',
          buttons: { cancel: "Não, cancelar", confirm: { text: "Sim, desejo excluir!", className: "btn-danger" } },
        });
      }
    }
    catch (err) {
      console.log("errr", err)
    }

    return await swalConfirmarExclusao();
  }

  async function compatibilizarTexturas(formRochaRef) {
    let classeRocha = formRochaRef.form.getState().values.idTipoRocha;

    if (valoresNovasTexturas && (classeRocha === TIPO_ROCHA_AFLORAMENTO_IGNEA.id || classeRocha === TIPO_ROCHA_AFLORAMENTO_METAMORFICA.id)) {
      formRochaRef.form.mutators.setValue('idsTexturas', valoresNovasTexturas);
      flushSync(() => {
        formRochaRef.form.mutators.remove('idsTexturas');
        formRochaRef.form.mutators.setValue('idsTexturas', valoresNovasTexturas);
        formRochaRef.form.change('idsTexturas', valoresNovasTexturas);
      });
    }
  }

  const swalConfirmarExclusao = () => {
    return swal({
      title: 'Deseja realmente excluir esse registro?',
      icon: 'warning',
      buttons: { cancel: "Não, cancelar", confirm: { text: "Sim, desejo excluir!", className: "btn-danger" } },
    });
  };


  return (<>
    <Card className="my-4">
      <Card.Body>
        {possuiErros && <span style={{ color: "red" }}>Existem rochas com campos obrigatórios não preenchidos</span>}
        <Card.Title>
          Rochas
          {<span style={{ color: "red" }}>*</span>}
        </Card.Title>
        {multimidiaAberto && <span style={{ color: "red" }}>Alterações em Rochas ficam indisponíveis enquanto o subformulário de Conteúdo multimídia estiver aberto.</span>}
        {/* Essa subscription para todos esses valores é feita pois o aoCarregarDependentesRocha
            precisa dos valores desses outros subforms atualizados */}
        <Fields names={['alteracoesHidrotermais', 'estruturasPrimarias', 'estruturasTectonicas', 'modoCadastro',
          'intemperismos', 'metamorfismos', 'rochasEnclave', 'relacoesContato', 'idsAmostrasAssociadas']}>
          {(input) => {
            // Lá fora tem toda uma lógica para atualizar dependentes automaticamente,
            // mas não funciona sempre. Então aqui dentro também rodamos uma lógica
            // para atualizar a tabela manualmente se necessário.

            const modoDefinitivo = input?.modoCadastro?.input?.value === 'DEFINITIVO';

            const dependentesNovos = {
              alteracoesHidrotermais: input.alteracoesHidrotermais?.input?.value,
              estruturasPrimarias: input.estruturasPrimarias?.input?.value,
              estruturasTectonicas: input.estruturasTectonicas?.input?.value,
              intemperismos: input.intemperismos?.input?.value,
              metamorfismos: input.metamorfismos?.input?.value,
              rochasEnclave: input.rochasEnclave?.input?.value,
              relacoesContato: input.relacoesContato?.input?.value,
            };
            // Este refresh manual só precisa rodar quando os dependentes mudaram.
            // O ref serve para lembrarmos o valor anterior.
            if (JSON.stringify(dependentesRochaRef.current) !== JSON.stringify(dependentesNovos)) {
              // Atualiza o ref de dependentes para a próxima vez que este callback for
              // chamado.
              dependentesRochaRef.current = dependentesNovos;
              tabelaRochasRef.current?.refreshItems();
            }         

            return <SubForm
              nome={nome}
              tableRef={tabelaRochasRef}
              validar={(valores) => rochaContemMinerais(valores, modoDefinitivoSelecionado)}
              onSubmit={(alteracoesForm) => {
                onSubmitRocha(alteracoesForm);
              }}
              formularioAberto={formularioAberto}
              onOpen={aoAbrirSubForm}
              onClose={aoFecharSubForm}
              elementos={elementos || undefined}
              campos={camposRochas}
              colunas={colunasRochas}
              valoresIniciais={valoresIniciais}
              naoValidarCamposVoltar={[
                "numeroMaterialGeologico",
                "nomeRocha",
                "nomeExibicao"
              ]}
              permitirInsercao={multimidiaAberto ? false : permitirEdicao}
              exibirBotaoInsercao={multimidiaAberto ? false : permitirEdicao}
              permitirEdicao={multimidiaAberto ? false : permitirEdicao}
              permitirExclusao={multimidiaAberto ? false : permitirEdicao}
              permitirVisualizacao={multimidiaAberto ? true : !permitirEdicao}
              onBtnEditClick={(event) => { setClickEditar(true) }}
              onBtnViewClick={(event) => { setClickVisualizar(true) }}
              onBtnDeleteClick={aoExcluirRocha.bind(this)}
              podeExcluirEntidade={podeDeletarRocha}
              alternarBotoesPai={true}
              validarVoltar={true}
              formSubscription={{}}
              validarExclusaoAtividade={validarExclusaoRocha}
              renderForm={({ editando, prefixoNome, formProps }) => {
                return (
                  <>
                    <Field name="exibirModal" subscription={{ value: true }}>
                      {({ input: { value: exibirModal } }) => {

                        let exibirInicio = false;
                        let criarIntemperismo = false;
                        let criarAlteracaoHidrotermal = false;
                        let exibirConclusao = false;

                        if (exibirModal && exibirModal.length) {
                          if (exibirModal.find(i => i === 'INICIO'))
                            exibirInicio = true;

                          else if (exibirModal.find(i => i === 'INTEMPERISMO'))
                            criarIntemperismo = true;

                          else if (exibirModal.find(i => i === 'ALTERACAO_HIDROTERMAL'))
                            criarAlteracaoHidrotermal = true;

                          else if (exibirModal.find(i => i === 'CONCLUSAO'))
                            exibirConclusao = true;
                        }

                        return <Modal
                          tamanho="xl"
                          aberto={exibirInicio || exibirConclusao || criarAlteracaoHidrotermal || criarIntemperismo}
                          aoFechar={() => confirmaFecharModal(formProps)}
                          titulo=""
                          scrollable={true}
                          align="center"
                          navBar={<p>Seram salvar ao confirmar a rocha</p>}
                          comNavBar={true}>

                          {
                            exibirInicio &&
                            <Card.Body>
                              <Card.Title></Card.Title>
                              <>
                                <h1>Há preenchimento no Cadastro da Amostra ({numeroAmostra}).</h1>
                                <h1>Deseja utilizar os dados ?</h1>
                                <br></br>
                                <div style={{
                                  display: 'inline',
                                  width: "100%",
                                }}>
                                  <div style={{
                                    display: 'inline',
                                    width: "12%",
                                    float: 'right',
                                  }}>
                                    <Button
                                      style={{
                                        color: 'white',
                                        fontSize: 14,
                                        backgroundColor: '#1351b4',
                                        borderRadius: '100em',
                                        fontSize: '16.8px',
                                        fontWeight: '500',
                                        width: '110px',
                                        float: 'right',
                                      }}
                                      onClick={() => {
                                        step += 1;

                                        let newValue = exibirModal.filter(i => i != 'INICIO');

                                        flushSync(() => {
                                          formProps.form.mutators.remove('exibirModal');
                                          formProps.form.mutators.setValue('exibirModal', newValue);
                                          formProps.form.change('exibirModal', newValue);
                                        });
                                      }
                                      }>
                                      Sim</Button>
                                  </div>
                                  <div style={{
                                    display: 'inline',
                                    width: "20%",
                                    float: 'right',
                                  }}>
                                    <Button
                                      style={{
                                        color: "black",
                                        fontSize: 14,
                                        backgroundColor: "gainsboro",
                                        borderRadius: '100em',
                                        borderColor: "gainsboro",
                                        fontSize: '16.8px',
                                        fontWeight: '500',
                                        width: '200px',
                                      }}
                                      onClick={() => confirmaFecharModal(formProps)}>
                                      Não, cancelar</Button>
                                  </div>
                                </div>
                                <br></br>
                              </>
                            </Card.Body>
                          }

                          {
                            criarIntemperismo &&
                            <Card.Body>
                              <Card.Title>Intemperismos</Card.Title>
                              <SubForm
                                tituloForm="Intemperismo"
                                nome={'intemperismos'}
                                permitirInsercao={true}
                                colunas={[]}
                                campos={[]}
                                labelBotaoVoltar="Não sincronizar"
                                aoConfirmarVoltar={() => {
                                  step += 1;

                                  formNovoIntemperismo = undefined;
                                  valoresIniciaisIntemperismo = undefined;

                                  let newValue = exibirModal.filter(i => i != 'INTEMPERISMO');

                                  tabelaCompatibilizacao.intemperismo.naoSeAplica = true;

                                  flushSync(() => {
                                    formProps.form.mutators.remove('exibirModal');
                                    formProps.form.mutators.setValue('exibirModal', newValue);
                                    formProps.form.change('exibirModal', newValue);
                                  });
                                }}
                                valoresIniciais={valoresIniciaisIntemperismo}
                                onSubmit={(params) => {
                                  step += 1;

                                  formNovoIntemperismo = params;
                                  valoresIniciaisIntemperismo = undefined;

                                  let newValue = exibirModal.filter(i => i != 'INTEMPERISMO');

                                  flushSync(() => {
                                    formProps.form.mutators.remove('exibirModal');
                                    formProps.form.mutators.setValue('exibirModal', newValue);
                                    formProps.form.change('exibirModal', newValue);
                                  });
                                }}
                                formularioAberto={true}
                                mostrarBotaoConfirmar={true}
                                renderForm={({ formProps: { form, values }, prefixoNome }) => {
                                  return (
                                    <>
                                      <Row className="mt-3">
                                        <Col md={6}>
                                          <Field
                                            component={DropdownListField}
                                            name={`idRocha`}
                                            label="Rocha"
                                            dica="Selecione a rocha associada ao intemperismo, se observado"
                                            elementos={[{ id: 1, nomeExibicao: 'Rocha selecionada' }]}
                                            required={true}
                                            disabled={true}
                                            displayMember={'nomeExibicao'}
                                            multiple
                                          />
                                        </Col>
                                        <Col md={6}>
                                          <Field
                                            component={DropdownListField}
                                            name={`idGrau`}
                                            label="Grau de intemperismo"
                                            dica="Selecione a intensidade do intemperismo associada à rocha"
                                            elementos={grausIntemperismos}
                                            required={true}
                                            disabled={true}
                                          />
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col md={12}>
                                          <Field
                                            component={TextAreaField}
                                            name={`descricao`}
                                            label="Descrição do intemperismo no afloramento"
                                            dica="Detalhe o perfil intempérico observado. Por exemplo, saprolito/horizonte mosqueado/crosta ferruginosa/horizonte do solo"
                                            disabled={false}
                                            maxLength={250}
                                          />
                                        </Col>
                                      </Row>
                                    </>
                                  )
                                }}
                              />
                            </Card.Body>
                          }

                          {
                            criarAlteracaoHidrotermal &&
                            <Card.Body>
                              <Card.Title>Alterações hidrotermais</Card.Title>
                              <SubForm
                                tituloForm="Alterações hidrotermais"
                                nome={'alteracoesHidrotermais'}
                                permitirInsercao={true}
                                labelBotaoVoltar="Não sincronizar"
                                onSubmit={(params) => {
                                  step += 1;

                                  valoresIniciaisAltercaoHidrotermal = undefined;
                                  formNovaAlteracaoHidrotermal = params;

                                  let newValue = exibirModal.filter(i => i != 'ALTERACAO_HIDROTERMAL');

                                  flushSync(() => {
                                    formProps.form.mutators.remove('exibirModal');
                                    formProps.form.mutators.setValue('exibirModal', newValue);
                                    formProps.form.change('exibirModal', newValue);
                                  });
                                }}
                                aoConfirmarVoltar={() => {
                                  step += 1;

                                  formNovaAlteracaoHidrotermal = undefined;
                                  valoresIniciaisAltercaoHidrotermal = undefined;

                                  let newValue = exibirModal.filter(i => i != 'ALTERACAO_HIDROTERMAL');
                                  tabelaCompatibilizacao.alteracaoHidro.naoSeAplica = true;

                                  flushSync(() => {
                                    formProps.form.mutators.remove('exibirModal');
                                    formProps.form.mutators.setValue('exibirModal', newValue);
                                    formProps.form.change('exibirModal', newValue);
                                  });
                                }}
                                campos={[]}
                                colunas={[]}
                                valoresIniciais={valoresIniciaisAltercaoHidrotermal}
                                formularioAberto={true}
                                mostrarBotaoConfirmar={true}
                                renderForm={({ formProps: { form, values }, prefixoNome }) => {
                                  return (
                                    <>
                                      <Row className="my-3">
                                        <Col md={6}>
                                          <Field
                                            component={DropdownListField}
                                            name={`idRocha`}
                                            label="Rocha"
                                            dica="Selecione uma ou mais rochas para a qual deseja informar a alteração hidrotermal"
                                            elementos={[{ id: 1, nomeExibicao: 'Rocha selecionada' }]}
                                            required={true}
                                            disabled={true}
                                            displayMember={'nomeExibicao'}
                                            multiple
                                          />
                                        </Col>
                                        <Col md={6}>
                                          <Field
                                            component={DropdownListField}
                                            name={`idsMinerais`}
                                            label="Minerais de alteração"
                                            dica="Selecione o mineral ou o conjunto de minerais característicos desta alteração hidrotermal."
                                            elementos={minerais}
                                            disabled={false}
                                            checkboxes={true}
                                            multiple
                                            required={true}
                                            validate={validarSe(modoDefinitivoSelecionado, campoObrigatorioComMsgGenerica("Minerais de alteração"))}
                                          />
                                        </Col>
                                      </Row>
                                      <Row className="my-3">
                                        <Col md={6}>
                                          <Field
                                            component={DropdownListField}
                                            name={`idFormaOcorrencia`}
                                            label="Forma ocorrência"
                                            dica="Selecione a forma de ocorrência do halo de alteração hidrotermal observado"
                                            elementos={formasOcorrencia}
                                            disabled={false}
                                          />
                                        </Col>
                                        <Col md={6}>
                                          <Field
                                            component={DropdownListField}
                                            name={`idTipo`}
                                            label="Tipo de alteração"
                                            dica="Selecione o tipo de alteração hidrotermal observado na rocha"
                                            elementos={tiposAlteracaoHidrotermal}
                                            disabled={true}
                                          />
                                        </Col>
                                      </Row>
                                      <Row className="my-3">
                                        <Col md={6}>
                                          <Field
                                            component={DropdownListField}
                                            name={`idIntensidadeAlteracao`}
                                            label="Intensidade da alteração"
                                            dica="Selecione o grau de intensidade da alteração hidrotermal observada na rocha"
                                            elementos={intensidadesAlteracaoHidrotermal}
                                            disabled={false}
                                            required={true}
                                            validate={validarSe(modoDefinitivoSelecionado, campoObrigatorioComMsgGenerica("Intensidade alteração"))}
                                          />
                                        </Col>
                                        <Col md={6}>
                                          <Field
                                            component={CounterField}
                                            name={`hierarquia`}
                                            label="Hierarquia"
                                            dica="Selecione a hierarquia da alteração em relação aos processos geológicos observados neste afloramento"
                                            elementos={hierarquias}
                                            disabled={false}
                                            value={form.getState().values[`hierarquia`]}
                                            onChange={(value) => {
                                              form.mutators.setValue(`hierarquia`, value)
                                            }}
                                          />
                                        </Col>
                                      </Row>
                                      <Row className="my-3">
                                        <Col md={12}>
                                          <Field
                                            component={TextAreaField}
                                            name={`informacoesRelevantes`}
                                            label="Informações relevantes"
                                            dica="Caso necesário, detalhe as alterações hidrotermais observadas"
                                            maxLength={250}
                                            disabled={false}
                                          />
                                        </Col>
                                      </Row>
                                    </>
                                  )
                                }}
                              />
                            </Card.Body>
                          }

                          {
                            exibirConclusao &&
                            <Card.Body>
                              <Card.Title>Resultado da compatibilização com Amostra</Card.Title>
                              <>
                                <>
                                  <h3 style={{ textAlign: 'center' }}>Textura</h3>

                                  {
                                    tabelaCompatibilizacao.texturas.naoSeAplica ?
                                      <table>
                                        <tr>
                                          <th>Não se aplica</th>
                                        </tr>
                                        <tr></tr>
                                      </table> :
                                      <table>
                                        <tr>
                                          <th>Como está no Afloramento: </th>
                                          <th>Como ficará no Afloramento: </th>
                                        </tr>
                                        <tr>
                                          <td>{tabelaCompatibilizacao.texturas.valorAtual}</td>
                                          <td>{tabelaCompatibilizacao.texturas.valorNovo}</td>
                                        </tr>
                                      </table>
                                  }


                                  <h3 style={{ textAlign: 'center' }}>Tipo de alteração</h3>
                                  {
                                    tabelaCompatibilizacao.alteracaoHidro.naoSeAplica ?
                                      <table>
                                        <tr>
                                          <th>Não se aplica</th>
                                        </tr>
                                        <tr></tr>
                                      </table> :
                                      <table>
                                        <tr>
                                          <th>Como está no Afloramento: </th>
                                          <th>Como ficará no Afloramento: </th>
                                        </tr>
                                        <tr>
                                          <td>{tabelaCompatibilizacao.alteracaoHidro.valorAtual}</td>
                                          <td>{tabelaCompatibilizacao.alteracaoHidro.valorNovo}</td>
                                        </tr>
                                      </table>
                                  }

                                  <h3 style={{ textAlign: 'center' }}>Grau de intemperismo</h3>
                                  {
                                    tabelaCompatibilizacao.intemperismo.naoSeAplica ?
                                      <table>
                                        <tr>
                                          <th>Não se aplica</th>
                                        </tr>
                                        <tr></tr>
                                      </table> :
                                      <table>
                                        <tr>
                                          <th>Como está no Afloramento: </th>
                                          <th>Como ficará no Afloramento: </th>
                                        </tr>
                                        <tr>
                                          <td>{tabelaCompatibilizacao.intemperismo.valorAtual}</td>
                                          <td>{tabelaCompatibilizacao.intemperismo.valorNovo}</td>
                                        </tr>
                                      </table>
                                  }

                                  <br></br>
                                  <div style={{
                                    display: 'inline',
                                    width: "100%",
                                  }}>
                                    <div style={{
                                      display: 'inline',
                                      width: "12%",
                                      float: 'right',
                                    }}>
                                      <Button
                                        style={{
                                          color: 'white',
                                          fontSize: 14,
                                          backgroundColor: '#1351b4',
                                          borderRadius: '100em',
                                          fontSize: '16.8px',
                                          fontWeight: '500',
                                          width: '110px',
                                          float: 'right',
                                        }}
                                        onClick={() => {
                                          step += 1;
                                          compatibilizarTexturas(formProps);

                                          flushSync(() => {
                                            formProps.form.mutators.remove('exibirModal');
                                            formProps.form.mutators.setValue('exibirModal', []);
                                            formProps.form.change('exibirModal', []);
                                          });
                                        }
                                        }>
                                        Confirmar</Button>
                                    </div>
                                    <div style={{
                                      display: 'inline',
                                      width: "20%",
                                      float: 'right',
                                    }}>
                                      <Button
                                        style={{
                                          color: "black",
                                          fontSize: 14,
                                          backgroundColor: "gainsboro",
                                          borderRadius: '100em',
                                          borderColor: "gainsboro",
                                          fontSize: '16.8px',
                                          fontWeight: '500',
                                          width: '200px',
                                        }}
                                        onClick={() => confirmaFecharModal(formProps)}>
                                        Não sincronizar</Button>
                                    </div>
                                  </div>
                                </>
                                <br></br>
                              </>
                            </Card.Body>
                          }

                          <p>O Intemperismo e a Alteração hidrotermal serão cadastrados após a finalização do cadastro da Rocha.</p>
                          <br></br>
                          <span>{step + "/" + totalSteps}</span>
                        </Modal>
                      }}
                    </Field>

                    <Row>
                      <Col>
                        <Field
                          component={DropdownListField}
                          name={`${prefixoNome}idTipoRocha`}
                          label="Classe da rocha"
                          dica="Selecione a classe da rocha presente no afloramento"
                          elementos={classesRocha}
                          required
                          disabled={!permitirEdicao || editando}
                          validate={campoObrigatorioComMsgGenerica("Classe da rocha")}
                        />
                      </Col>
                      <Col md={6}>
                        <Field name={`${prefixoNome}numeroMaterialGeologico`} subscription={{ value: true }}>
                          {({ input: { value: numeroMaterialGeologico } }) => {

                            if (!clickEditar && !clickVisualizar) {
                              let rochas = [];
                              let prefixoMaterial = "MG-"
                              let nome = ""
                              let totalRochas = 0;
                              let arrayCasas = [];
                              if (referenciaForm.getState().values.rochas) {
                                rochas = resolverExibidos(referenciaForm.getState().values.rochas)
                              }
                              rochas.map(item => totalRochas++);
                              totalRochas++;
                              arrayCasas = Array.from(String(totalRochas), Number);
                              for (let i = arrayCasas.length; i < 3; i++) {
                                nome += "0"
                              }
                              nome = `${prefixoMaterial}${nome}${totalRochas}`
                              formProps.form.mutators.setValue(`${prefixoNome}numeroMaterialGeologico`, nome)
                            }


                            return <Field
                              component={TextInputField}
                              name={`${prefixoNome}numeroMaterialGeologico`}
                              label="Material geológico"
                              dica="Sigla automática gerada como identificador do material geológico"
                              disabled
                            />
                          }}
                        </Field>

                      </Col>
                    </Row>

                    <Row style={{ marginTop: 10 }}>
                      <Col>
                        <FormSpy subscription={{ values: true }}>
                          {({ values }) => {
                            let idTipoRocha = values.idTipoRocha;
                            let idSubClasseRocha = values.idSubClasseRocha;

                            let filtro1 = rochasNomeadas.filter(r => r.idTipoRocha === idTipoRocha && r.rochaAtual === 'S');
                            let filtro2 = [...new Set(filtro1.map(i => i.idSubclasseRocha))];

                            let listaFiltrada = subClassesRocha.filter((subClasse) => filtro2.find(i => i === subClasse.id));

                            if (!idSubClasseRocha)
                              forcarLimparComboBox('subClasse');

                            if (idSubClasseRocha && listaFiltrada) {
                              if (!listaFiltrada.find(r => r.id === idSubClasseRocha)) {
                                formProps.form.mutators.setValue(`${prefixoNome}idSubClasseRocha`, null);
                                forcarLimparComboBox('subClasse');
                              }
                              else {
                                let subClasse = listaFiltrada.find(r => r.id === idSubClasseRocha);
                                forcarValorCampo('subClasse', subClasse.nome);
                              }
                            }

                            return <Field
                              component={DropdownListField}
                              name={`${prefixoNome}idSubClasseRocha`}
                              label="Subclasse da rocha"
                              dica="Informe a subclasse da rocha presente no afloramento"
                              elementos={listaFiltrada}
                              required
                              disabled={multimidiaAberto || !permitirEdicao}
                              readOnly={editando}
                              customClass={'subClasse'}
                              validate={campoObrigatorioComMsgGenerica("Subclasse da rocha")}
                            />
                          }}
                        </FormSpy>
                      </Col>
                    </Row>
                    <Card className="my-3">
                      <Card.Body>
                        <Card.Subtitle>
                          Nomenclatura de rochas
                        </Card.Subtitle>
                        <Row className="my-3">
                          <Col>
                            <Field name={`${prefixoNome}idPrefixo`} subscription={{ value: true }}>
                              {({ input: { value: idPrefixo } }) => {

                                if (idPrefixo && prefixos && prefixos.length) {
                                  let prefixo = prefixos.find(p => p.id === idPrefixo);
                                  forcarValorCampo('prefixo', prefixo.nome);
                                }

                                return <Field
                                  component={DropdownListField}
                                  name={`${prefixoNome}idPrefixo`}
                                  label="Prefixo"
                                  dica="Se for o caso, defina o prefixo de nomenclatura de sua rocha de acordo com o tipo"
                                  elementos={prefixos}
                                  customClass={'prefixo'}
                                  disabled={multimidiaAberto || !permitirEdicao}
                                />
                              }}
                            </Field>
                          </Col>
                          <Col>
                            <Field name={`${prefixoNome}idSubClasseRocha`} subscription={{ value: true }}>
                              {({ input: { value: idSubClasseRocha } }) => {

                                let idTipoRocha = formProps.form.getState().values[`${prefixoNome}idTipoRocha`];

                                let rochasNomeadasFiltradasPorTipoRocha = rochasNomeadas.filter(r => r.idSubclasseRocha === idSubClasseRocha && r.idTipoRocha === idTipoRocha);

                                let idRochaNomeada = formProps.form.getState().values[`${prefixoNome}idRochaNomeada`];

                                if (!idRochaNomeada)
                                  forcarLimparComboBox('nomeada');

                                if (idRochaNomeada && rochasNomeadasFiltradasPorTipoRocha) {
                                  if (!rochasNomeadasFiltradasPorTipoRocha.find(r => r.id === idRochaNomeada)) {
                                    formProps.form.mutators.setValue(`${prefixoNome}idRochaNomeada`, null);
                                    forcarLimparComboBox('nomeada');

                                    formProps.form.mutators.setValue(`${prefixoNome}idPrefixo`, null);
                                    forcarLimparComboBox('prefixo');

                                    formProps.form.mutators.setValue(`${prefixoNome}idComplemento`, null);
                                    forcarLimparComboBox('complemento');
                                  }
                                  else {
                                    let rocha = rochasNomeadasFiltradasPorTipoRocha.find(r => r.id === idRochaNomeada);
                                    forcarValorCampo('nomeada', rocha.nome);;
                                  }
                                }

                                return (
                                  <Field name={`${prefixoNome}idRochaNomeada`} subscription={{ value: true }}>
                                    {({ input: { value: idRochaNomeada } }) => {


                                      let tempArray = [];
                                      rochasNomeadasFiltradasPorTipoRocha?.forEach(item => {
                                        if (item?.rochaAtual === 'S' || item.id.toString() === idRochaNomeada.toString()) {
                                          tempArray.push(item);
                                        }
                                      })

                                      if (idRochaNomeada) {
                                        let rocha = tempArray.find(r => r.id === idRochaNomeada);
                                        forcarValorCampo('nomeada', rocha.nome);
                                      }

                                      return <Field
                                        component={DropdownListField}
                                        name={`${prefixoNome}idRochaNomeada`}
                                        label="Rocha"
                                        dica="Selecione o nome simples da rocha"
                                        elementos={tempArray}
                                        required={true}
                                        disabled={multimidiaAberto || !permitirEdicao}
                                        customClass={'nomeada'}
                                        validate={validarSe(true, campoObrigatorioComMsgGenerica("Rocha"))}
                                      />
                                    }}</Field>
                                );
                              }}
                            </Field>
                          </Col>
                          <Col>
                            <Field name={`${prefixoNome}idComplemento`} subscription={{ value: true }}>
                              {({ input: { value: idComplemento } }) => {

                                if (idComplemento && complementos && complementos.length) {
                                  let complemento = complementos.find(c => c.id === idComplemento);
                                  forcarValorCampo('complemento', complemento.nome);
                                }

                                return <Field
                                  component={DropdownListField}
                                  name={`${prefixoNome}idComplemento`}
                                  label="Complemento"
                                  dica="Se for o caso, defina o complemento de nomenclatura de sua rocha de acordo com o tipo"
                                  elementos={complementos}
                                  customClass={'complemento'}
                                  disabled={multimidiaAberto || !permitirEdicao}
                                />
                              }}
                            </Field>
                          </Col>
                        </Row>
                        <Row className="my-3">
                          <Col>
                            <MineraisRocha
                              formProps={formProps}
                              minerais={minerais}
                              mineraisPorId={mineraisPorId}
                              prefixoNome={prefixoNome}
                              permitirEdicao={multimidiaAberto ? false : permitirEdicao}
                              modoDefinitivoSelecionado={modoDefinitivoSelecionado}
                            />
                          </Col>
                        </Row>
                        <Row className="my-3">
                          <Col>
                            <NomeCompletoRocha
                              mineraisPorId={mineraisPorId}
                              rochasNomeadasPorId={rochasNomeadasPorId}
                              prefixosPorId={prefixosPorId}
                              complementosPorId={complementosPorId}
                              prefixoNome={prefixoNome}
                            />
                          </Col>
                        </Row>
                        {/* <Row className="my-3">
                          <Col>
                            <PercentualMineraisRocha
                              prefixoNome={prefixoNome}
                            />
                          </Col>
                        </Row> */}
                      </Card.Body>
                    </Card>
                    <Row>
                      <Col>
                        <Field
                          component={NumberInputField}
                          name={`${prefixoNome}cota`}
                          label="Cota"
                          dica="Informe a posição vertical relativa da rocha no afloramento, poço etc."
                          casasInteiros={6}
                          permitirNegativos={false}
                          disabled={multimidiaAberto||!permitirEdicao}                     
                        />
                      </Col>
                      <Col>
                        <Field
                          component={NumberInputField}
                          name={`${prefixoNome}estimativaPercentual`}
                          label="Estimativa percentual da rocha no afloramento (%)"
                          dica="Informar a % relativa que cada rocha representa no afloramento, nos casos de afloramentos com apenas uma rocha inserir 100%"
                          disabled={multimidiaAberto || !permitirEdicao}
                          valorMaximo={100}
                          permitirNegativos={false}
                          casasInteiros={3}
                          required={modoDefinitivoSelecionado}
                          validate={validarSe(modoDefinitivoSelecionado, campoObrigatorioComMsgGenerica("Estimativa percentual"))}
                        />
                      </Col>
                      <Col className='label-input-select'>
                        <Field
                          component={DropdownListField}
                          name={`${prefixoNome}idGeometria`}
                          label="Geometria"
                          dica="Selecione a geometria que melhor representa a forma de ocorrência(s) de sua(s) rocha(s)"
                          elementos={geometrias}
                          disabled={multimidiaAberto || !permitirEdicao}
                        />
                      </Col>
                    </Row>
                    <Row className="my-3">
                      <Col>
                        <Field
                          component={DropdownListField}
                          name={`${prefixoNome}idsCores`}
                          label="Cor"
                          dica="Selecione as cores observadas na Rocha"
                          elementos={cores}
                          disabled={multimidiaAberto || !permitirEdicao}
                          checkboxes={true}
                          multiple
                        />
                      </Col>
                    </Row>

                    <TipoRochaEh prefixoNome={prefixoNome} tipo={[TIPO_ROCHA_AFLORAMENTO_METASSEDIMENTAR, TIPO_ROCHA_AFLORAMENTO_META_IGNEA, TIPO_ROCHA_AFLORAMENTO_IGNEA, TIPO_ROCHA_AFLORAMENTO_METAMORFICA]}>
                      <>
                        <Row className="my-3">
                          <Col>
                            <Field
                              component={DropdownListField}
                              name={`${prefixoNome}idsGranulacoes`}
                              label="Granulação"
                              dica="Selecione a granulação da rocha descrita"
                              elementos={granulacoes}
                              required={modoDefinitivoSelecionado}
                              disabled={multimidiaAberto || !permitirEdicao}
                              validate={validarSe(modoDefinitivoSelecionado, campoObrigatorioComMsgGenerica("Granulação"))}
                              checkboxes={true}
                              multiple
                            />
                          </Col>
                        </Row>
                        <Row className="my-3">
                          <Col>
                            <Field
                              component={DropdownListField}
                              name={`${prefixoNome}idsTexturas`}
                              label="Textura"
                              dica="A textura de uma rocha refere-se ao tamanho, forma, disposição, contatos e arranjo ou organização de seus componentes minerais e, se existirem, vítreos."
                              elementos={texturas}
                              required={modoDefinitivoSelecionado}
                              disabled={multimidiaAberto || !permitirEdicao}
                              validate={validarSe(modoDefinitivoSelecionado, campoObrigatorioComMsgGenerica("Textura"))}
                              checkboxes={true}
                              multiple
                            />
                          </Col>

                          <TipoRochaEh tipo={[TIPO_ROCHA_AFLORAMENTO_IGNEA, TIPO_ROCHA_AFLORAMENTO_META_IGNEA]}>
                            <Col>
                              <Field
                                component={DropdownListField}
                                name={`${prefixoNome}idsIndicesCor`}
                                label="Índice de cor"
                                dica="Estimativa de minerais máficos na rocha"
                                elementos={indicesCor}
                                required={modoDefinitivoSelecionado}
                                disabled={multimidiaAberto || !permitirEdicao}
                                validate={validarSe(modoDefinitivoSelecionado, campoObrigatorioComMsgGenerica("Índice de cor"))}
                                checkboxes={true}
                                multiple
                              />
                            </Col>
                          </TipoRochaEh>
                        </Row>
                        <Row className="my-3">
                          <Col>
                            <Field
                              component={TextAreaField}
                              name={`${prefixoNome}descricaoTextura`}
                              label="Descrição textura"
                              dica="Descrição mais detalhada da textura"
                              disabled={multimidiaAberto || !permitirEdicao}
                              maxLength={2000}
                            />
                          </Col>
                        </Row>
                      </>
                    </TipoRochaEh>

                    <TipoRochaEh prefixoNome={prefixoNome} tipo={[TIPO_ROCHA_AFLORAMENTO_METASSEDIMENTAR, TIPO_ROCHA_AFLORAMENTO_SEDIMENTAR]}>
                      <div>
                        <Row className="my-3">
                          <Col>
                            <Field
                              component={RadioButtonWithFieldSetField}
                              name={`${prefixoNome}opcaoRochaSedimentar`}
                              elementos={opcoesRochaSedimentar}
                              disabled={multimidiaAberto || !permitirEdicao}
                              initialValue={opcoesRochaSedimentar[0]?.id}
                            />
                          </Col>
                          <Col>
                            <Field
                              component={DropdownListField}
                              name={`${prefixoNome}idNaturezaComposicaoSedimentar`}
                              label="Natureza da composição"
                              dica="Selecione o principal tipo de material que forma a rocha"
                              elementos={naturezasComposicaoSedimentar}
                              disabled={multimidiaAberto || !permitirEdicao}
                            />
                          </Col>
                          <Col>
                            <Field
                              component={DropdownListField}
                              name={`${prefixoNome}idGranulometria`}
                              label="Granulometria"
                              dica="Selecione as dimensões das partículas da rocha"
                              elementos={granulometrias}
                              disabled={multimidiaAberto || !permitirEdicao}
                            />
                          </Col>
                        </Row>
                        <Row className="my-3">
                          <Col>
                            <Field
                              component={DropdownListField}
                              name={`${prefixoNome}idSelecao`}
                              label="Seleção"
                              dica="Selecione o grau de seleção dos grãos que compõem a rocha/sedimento descrito"
                              elementos={selecoes}
                              disabled={multimidiaAberto || !permitirEdicao}
                            />
                          </Col>
                          <Col>
                            <Field
                              component={DropdownListField}
                              name={`${prefixoNome}idArredondamento`}
                              label="Arredondamento"
                              dica="Selecione o grau de arredondamento que melhor se aplica aos grãos que compõem a rocha/sedimento descrito de forma visual ou medido"
                              elementos={arredondamentos}
                              disabled={multimidiaAberto || !permitirEdicao}
                            />
                          </Col>
                          <Col>
                            <Field
                              component={DropdownListField}
                              name={`${prefixoNome}idEsfericidade`}
                              label="Esfericidade"
                              dica="Selecione o grau de esfericidade dos grãos que compõem a rocha/sedimento descrito."
                              elementos={esfericidades}
                              disabled={multimidiaAberto || !permitirEdicao}
                            />
                          </Col>
                        </Row>
                        <Row className="my-3">
                          <Col>
                            <Field
                              component={DropdownListField}
                              name={`${prefixoNome}idMaturidadeTextural`}
                              label="Maturidade textural"
                              dica="Selecione o grau de maturidade textural da rocha/sedimento descrito"
                              elementos={maturidadesTexturais}
                              disabled={multimidiaAberto || !permitirEdicao}
                            />
                          </Col>
                          <Col>
                            <Field
                              component={DropdownListField}
                              name={`${prefixoNome}idMaturidadeComposicional`}
                              label="Maturidade composicional"
                              dica="Selecione o grau de maturidade composicional da rocha/sedimento descrito"
                              elementos={maturidadesComposicionais}
                              disabled={multimidiaAberto || !permitirEdicao}
                            />
                          </Col>
                          <Col>
                            <Field
                              component={RadioButtonWithFieldSetField}
                              name={`${prefixoNome}idPresencaMateriaOrganica`}
                              label="Presença de matéria orgânica"
                              dica="Informe se há ocorrência de matéria orgânica"
                              elementos={possuiMateriaOrganica}
                              initialValue={possuiMateriaOrganica[1]?.id}
                              horizontal
                              disabled={multimidiaAberto || !permitirEdicao}
                            />
                          </Col>
                        </Row>
                        <Card className="my-3">
                          <Card.Body>
                            <Card.Subtitle>
                              Arcabouço / matriz
                            </Card.Subtitle>
                            <Row className="my-3">
                              <Col>
                                <Field
                                  component={DropdownListField}
                                  name={`${prefixoNome}idRelacaoArcaboucoMatriz`}
                                  elementos={relacoesArcaboucoMatriz}
                                  label="Relação arcabouço matriz"
                                  dica="Selecione a relação arcabouço-matriz que melhor descreve a rocha/sedimento"
                                  disabled={multimidiaAberto || !permitirEdicao}
                                />
                              </Col>
                              <Col>
                                <Field
                                  component={NumberInputField}
                                  name={`${prefixoNome}porcentagemMatrizRocha`}
                                  label="Percentual matriz na rocha (%)"
                                  dica="Informe o percentual da matriz na rocha/sedimento descrito"
                                  disabled={multimidiaAberto || !permitirEdicao}
                                  permitirNegativos={false}
                                  valorMaximo={100}
                                  casasInteiros={3}
                                />
                              </Col>
                            </Row>
                            <Row className="my-3">
                              <Col>
                                <Card className="my-3">
                                  <Card.Body>
                                    <Card.Subtitle>
                                      Arcabouço
                                    </Card.Subtitle>
                                    <Row className="my-3">
                                      <Col>
                                        <Field
                                          component={DropdownListField}
                                          name={`${prefixoNome}idGranulometriaArcabouco`}
                                          label="Granulometria"
                                          dica="Selecione a granulometria dos fragmentos que compõem o arcabouço da rocha/sedimento descrito"
                                          elementos={granulometrias}
                                          disabled={multimidiaAberto || !permitirEdicao}
                                        />
                                      </Col>
                                      <Col>
                                        <Field
                                          component={DropdownListField}
                                          name={`${prefixoNome}idArredondamentoArcabouco`}
                                          label="Arredondamento"
                                          dica="Selecione o grau de arredondamento que melhor descreve os fragmentos que compõem o arcabouço da rocha/sedimento descrito"
                                          elementos={arredondamentos}
                                          disabled={multimidiaAberto || !permitirEdicao}
                                        />
                                      </Col>
                                    </Row>
                                    <Row className="my-3">
                                      <Col>
                                        <Field
                                          component={DropdownListField}
                                          name={`${prefixoNome}idEsfericidadeArcabouco`}
                                          label="Esfericidade"
                                          dica="Selecione o grau de esfericidade dos grãos que compõem o arcabouço da rocha/sedimento descrito."
                                          elementos={esfericidades}
                                          disabled={multimidiaAberto || !permitirEdicao}
                                        />
                                      </Col>
                                      <Col>
                                        <Field
                                          component={NumberInputField}
                                          name={`${prefixoNome}porcentagemGraoClasto`}
                                          label="Porcentagem relação grão/clasto (%)"
                                          dica="Informe a relação percentual entre os grãos  e os clastos"
                                          permitirNegativos={false}
                                          valorMaximo={100}
                                          casasInteiros={3}
                                          disabled={multimidiaAberto || !permitirEdicao}
                                        />
                                      </Col>
                                    </Row>
                                    <Graos
                                      minerais={minerais}
                                      mineraisPorId={mineraisPorId}
                                      modoDefinitivoSelecionado={modoDefinitivoSelecionado}
                                      valoresIniciais={valoresIniciais}
                                      permitirEdicao={multimidiaAberto ? false : permitirEdicao}
                                    />

                                    <Clastos
                                      minerais={minerais}
                                      mineraisPorId={mineraisPorId}
                                      rochasNomeadas={rochasNomeadas}
                                      rochasNomeadasPorId={rochasNomeadasPorId}
                                      complementos={complementos}
                                      complementosPorId={complementosPorId}
                                      prefixos={prefixos}
                                      prefixosPorId={prefixosPorId}
                                      modoDefinitivoSelecionado={modoDefinitivoSelecionado}
                                      valoresIniciais={valoresIniciais}
                                      permitirEdicao={multimidiaAberto ? false : permitirEdicao}
                                    />
                                  </Card.Body>
                                </Card>
                              </Col>
                            </Row>
                            <Row className="my-3">
                              <Col>
                                <Card className="my-3">
                                  <Card.Body>
                                    <Card.Subtitle>Matriz</Card.Subtitle>
                                    <Row className="my-3">
                                      <Col>
                                        <Field
                                          component={DropdownListField}
                                          name={`${prefixoNome}idNaturezaMatriz`}
                                          elementos={naturezasMatriz}
                                          label="Natureza da matriz"
                                          dica="Selecione a natureza da matriz que compõe a rocha/sedimento descrito"
                                          disabled={multimidiaAberto || !permitirEdicao}
                                        />
                                      </Col>
                                      <Col>
                                        <Field
                                          component={DropdownListField}
                                          name={`${prefixoNome}idGranulometriaMatriz`}
                                          elementos={granulometrias}
                                          label="Granulometria"
                                          dica="Selecione a granulometria dos grãos que compõem a matriz"
                                          disabled={multimidiaAberto || !permitirEdicao}
                                        />
                                      </Col>
                                    </Row>
                                  </Card.Body>
                                </Card>
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                      </div>
                    </TipoRochaEh>
                    <Container fluid className="border pb-4 mb-3">
                      <Row className="mb-2 mt-3">
                        <Col md={12}>
                          <Card.Title>
                            Amostras associadas
                          </Card.Title>
                        </Col>
                      </Row>
                      <Row>
                        <Field name={"idsAmostrasAssociadas"} subscription={{ value: true }}>
                          {({ input: { value: idsAmostrasAssociadas } }) => {

                            const columnsTabelaAssociacao = [
                              {
                                text: "Vincular / desvincular", datafield: "vinculado", columntype: "checkbox", type: "boolean", width: '12%', enabletooltips: false,
                              },
                              createControlColumn({
                                iconClasses: ["fas", "fa-sync", "text-primary"],
                                dataField: 'id',
                                ref: tabelaAssociacaoRef,
                                onClick: (entidade) => compatibilizarCampos(entidade, formProps),
                                botaoAtivo: (entidade) => {
                                  if (modoDefinitivo === false)
                                    return false

                                  let amostra = amostrasRocha.find(a => a.id === entidade.id);

                                  if (!amostra || amostra.modoCadastro != 'DEFINITIVO' || !amostra.vinculado)
                                    return false;

                                  let listaPossiveis = [16, 8, 14, 9];

                                  if (!listaPossiveis.find(e => e === amostra.idClasse))
                                    return false;

                                  return true;
                                },
                                hintBotao: 'Validação da associação do material geológico do afloramento com a amostra'
                              }),
                              { text: "Número da amostra", datafield: "numeroAmostra", editable: false, width: '82%' },
                            ]


                            let idsPontosAssociados = idsAmostrasAssociadas ?? [];
                            let tempData = amostrasRocha.slice();
                            tempData.forEach(item => {
                              item.vinculado = false
                            });
                            for (let i = 0; i < idsPontosAssociados.length; i++) {
                              tempData.map(item => {
                                if (item?.id === idsPontosAssociados[i]) {
                                  item.vinculado = true;
                                }
                              })
                            }
                            if (JSON.stringify(tempData) !== JSON.stringify(amostrasRocha)) {
                              setAmostrasRocha(tempData);
                            }

                            let itensFiltrados = amostrasRocha;

                            if (elementos) {
                              let idRochaAberta = formProps.form.getState()?.values?.id;

                              let rochasParaFiltrar = resolverExibidos(elementos);
                              rochasParaFiltrar = rochasParaFiltrar.filter(r => r.id != idRochaAberta);

                              let idsUtilizados = [];

                              rochasParaFiltrar.forEach(rocha => {
                                if (rocha.idsAmostrasAssociadas && rocha.idsAmostrasAssociadas.length)
                                  idsUtilizados.push(...rocha.idsAmostrasAssociadas);
                              });

                              itensFiltrados = amostrasRocha.filter(a => !idsUtilizados.find(i => a.id === i));
                            }

                            return <Col md={12}>
                              <Table
                                selectionmode='singlecell'
                                editable={true}
                                editmode='click'
                                items={itensFiltrados}
                                ref={ref => {
                                  if (ref) {
                                    tabelaAssociacaoRef.current = ref.tableRef;
                                  }
                                }}
                                oncellendedit={(e) => {
                                  // Este callback costumava usar um ref para esta tabela,
                                  // mas às vezes o ref pode ser nulo; aparentemente, usar
                                  // refs para tabelas dentro de muitos componentes não
                                  // funciona muito bem. De qualquer maneira, é mais simples
                                  // e mais correto usar o parâmetro deste callback, que
                                  // inclui o row alterado. Tanto e.args.row.vinculado quanto
                                  // e.args.value devem possuir o mesmo valor.
                                  // Aqui criamos uma cópia de amostrasRocha, exceto que ele
                                  // terá o campo "vinculado" alterado onde o usuário clicou.
                                  const amostrasAssociadas = amostrasRocha.slice(); // copy
                                  const rochaAlteradaIndex = amostrasAssociadas.findIndex((rocha) => rocha.id === e.args.row.id);
                                  if (rochaAlteradaIndex !== -1) {
                                    // O row não tem necessariamente todos os campos que
                                    // aparecem no amostrasRocha.
                                    amostrasAssociadas[rochaAlteradaIndex] = {
                                      ...amostrasAssociadas[rochaAlteradaIndex],
                                      vinculado: e.args.row.vinculado,
                                    };
                                  }

                                  // O form usa vetores contendo os ids associados. Isso complica a lógica
                                  // um pouco, pois se nós submetermos um array somente com os ids associados,
                                  // ele vai virar um objeto como {"0": 1100, "1": 1500,} e não vai sobrescrever
                                  // possíveis itens em posições posteriores como {"2": 1201} que já existiam.
                                  // Por isso, mandamos um vetor que contém todos os elementos, mas onde os elementos
                                  // não associados não null.
                                  // Antigamente não fazíamos isso, e desassociar amostras (usando o checkbox) não
                                  // funcionava direito.
                                  const mutators = formProps.form.mutators;
                                  mutators.setValue(
                                    "idsAmostrasAssociadas",
                                    amostrasAssociadas
                                      .map(amostra => amostra.vinculado ? amostra.id : null),
                                  );

                                  // Também vamos alterar o array de elementos, já que isso não acontece automaticamente.
                                  //setAmostrasRocha(amostrasAssociadas);
                                }}
                                columns={columnsTabelaAssociacao}
                                datafields={dataFieldsAssociacao}
                              />
                            </Col>
                          }}
                        </Field>

                      </Row>
                    </Container>
                  </>
                )
              }}
            />
          }}
        </Fields>

      </Card.Body>
    </Card>
  </>)
}

export default Rochas;
