import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { DropdownList } from "../../Jqx/DropdownList";
import converterVetorParaObjetoIndexado, { INDICE_ORIGINAL } from "../../../util/converterVetorParaObjetoIndexado";
import isArray from "lodash/isArray";

const DropdownListWithValue = ({ elementos, valueMember, onSelect, value, multiple, maximoSelecoes, opcaoNula = true, ...props }) => {
  const ref = React.useRef()
  const [cacheElementos, setCacheElementos] = useState({})
  const [elementosFormatados, setElementosFormatados] = useState([]);
  // isto é necessário para evitar um ciclo infinito ao setar os valores iniciais (quando múltiplos)
  // setChecked -> onCheck -> DropdownListWithValue#render() -> setChecked
  const eventosHabilitados = React.useRef(true)

  useEffect(() => {
    if (elementos !== null && elementos !== undefined) {
      let tempElementos = [...elementos];
      if (opcaoNula) {
        tempElementos.unshift({ id: null, nome: props.placeholder });
      }
      setCacheElementos(converterVetorParaObjetoIndexado(tempElementos, {
        extratorId: e => e[valueMember],
      }))
      setElementosFormatados(tempElementos);
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elementos, valueMember])

  useEffect(() => {
    if (!ref.current) return;
    if (!cacheElementos) return;

    if (value && isArray(value)) {
      eventosHabilitados.current = false
      ref.current.clearSelection()

      value
        .map(it => cacheElementos[it] ? cacheElementos[it][INDICE_ORIGINAL] : null)
        .filter(it => null != it)
        .forEach(it => ref.current.checkIndex(it))
      eventosHabilitados.current = true
    }
  }, [cacheElementos, value])

  useEffect(() => {
    const id = ref.current?._id || null;
    const disabled = props.disabled;

    if (id) {
      const element = document.getElementById(id);
      if (disabled) {
        element.setAttribute("tabindex", "-1")
        element.style.pointerEvents = "none";
      } else {
        element.setAttribute("tabindex", "0")
        element.style.pointerEvents = "auto";
      }
    }
  }, [ref, props.disabled])

  const onChange = useCallback((evt) => {
    if (eventosHabilitados.current !== null && evt?.args?.index !== null) {
      const idx = evt?.args?.index
      if (idx < 0) {
        onSelect && onSelect(null)
      }
      else {
        if (elementosFormatados[evt?.args?.index] !== null && elementosFormatados[evt?.args?.index] !== undefined && elementosFormatados[evt?.args?.index].hasOwnProperty(valueMember)) {
          const valorSelecionado = elementosFormatados[evt?.args?.index][valueMember]
          onSelect && onSelect(valorSelecionado)
        }
      }
    }

  }, [elementosFormatados, valueMember, onSelect])

  const onCheckSelect = useCallback((evt) => {
    if (eventosHabilitados.current) {
      if (ref.current) {
        const valores = ref.current.getCheckedItems()
          .map(it => it.value)
        onSelect && onSelect(valores)
      } else {
        console.warn("onCheckSelect chamado sem o ref carregado o.O")
        onSelect && onSelect(null)
      }
    }
  }, [onSelect])

  if (undefined === value) value = null
  let indiceSelecionado = parseInt(cacheElementos[value] && cacheElementos[value][INDICE_ORIGINAL])
  if (isNaN(indiceSelecionado)) indiceSelecionado = -1;

  return (
    <>
      <DropdownList
        {...props}
        ref={ref}
        onSelect={multiple ? null : onChange}
        onCheckSelect={multiple ? onCheckSelect : null}
        valueMember={valueMember}
        elementos={elementosFormatados ?? []}
        selectedIndex={indiceSelecionado}
        checkboxes={multiple}
      />
    </>
  )
}

DropdownListWithValue.propTypes = {
  ...DropdownList.propTypes,
  value: PropTypes.any,
  maximoSelecoes: PropTypes.number,
}

DropdownListWithValue.defaultProps = {
  ...DropdownList.defaultProps,
  maximoSelecoes: null,
}

export default DropdownListWithValue
