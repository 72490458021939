
const prefixo = 'notificacao/'
/**
 * global action types
 */
export const MOSTRAR_NOTIFICACAO = prefixo + 'MOSTRAR_NOTIFICACAO';

/*
 * action creators
 */

export const mostrarNotificacao = (mensagem = '', {
  tipo = 'error',
} = {}, forcarExibicao = false) => ({
  type: MOSTRAR_NOTIFICACAO,
  payload: { mensagem, tipo, forcarExibicao },
});

/**
 * reducers
 */

const initialState = {
  notificacaoAtual: null,
};

export const notificacaoReducer = (state = initialState, action) => {
  switch (action.type) {
    case MOSTRAR_NOTIFICACAO: return Object.assign({}, state, {
      notificacaoAtual: {
        mensagem: action.payload.mensagem,
        tipo: action.payload.tipo,
        forcarExibicao: action.payload.forcarExibicao
      },
    });
    default: return state;
  }
};
