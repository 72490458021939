import React, { useEffect, useRef, useState } from "react";
import Card from "../../../../../components/card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { SubForm, LegacySubForm } from "../../../../../components/SubForm";
import { Field, useField, useForm } from "react-final-form";
import { DropdownListField } from "../../../../../components/field/DropdownListField";
import { campoObrigatorioComMsgGenerica, validarSe } from "../../../../../util/validadores";
import { TextInput } from "../../../../../components/Jqx/TextInput";
import resolverNomeRocha from "../../../utils/resolverNomeRocha";
import isArray from "lodash/isArray";
import isString from "lodash/isString";
import { aoEnviarSubForm } from "../../../../../util/mutadores";
import { getPropsPermissao } from "../../../../../components/SubForm/SubForm";

const campos = [
  { name: "idsMinerais", type: "array", map: "idsMinerais" },
  { name: "idPrefixo", type: "number", map: "idPrefixo" },
  { name: "idComplemento", type: "number", map: "idComplemento" },
  { name: "idRochaNomeada", type: "number", map: "idRochaNomeada" },
];

const NomeCompletoClasto = ({
  mineraisPorId,
  rochasNomeadasPorId,
  prefixosPorId,
  complementosPorId,
  prefixoNome = '',
}) => {
  const fieldRochaNomeada = useField(`${prefixoNome}idRochaNomeada`, { subscription: { value: true } })
  const fieldMinerais = useField(`${prefixoNome}idsMinerais`, { subscription: { value: true } })
  const fieldPrefixo = useField(`${prefixoNome}idPrefixo`, { subscription: { value: true } })
  const fieldComplemento = useField(`${prefixoNome}idComplemento`, { subscription: { value: true } })

  // aqui, o valor de idsMinerais pode ser uma string...
  const idsMinerais = isArray(fieldMinerais.input.value)
    ? fieldMinerais.input.value
    : []

  const valor = resolverNomeRocha({
    mineraisPorId,
    rochasNomeadasPorId,
    prefixosPorId,
    complementosPorId,
    usarMinerais: false,
    idsMinerais,
    idPrefixo: fieldPrefixo.input.value,
    idRochaNomeada: fieldRochaNomeada.input.value,
    idComplemento: fieldComplemento.input.value,
  })

  return <TextInput
    valor={valor}
    disabled
  />
}

const Clastos = ({
  minerais,
  mineraisPorId,
  rochasNomeadas,
  rochasNomeadasPorId,
  complementos,
  complementosPorId,
  prefixos,
  prefixosPorId,
  aoAbrirSubForm,
  aoFecharSubForm,
  valoresIniciais,
  permitirEdicao,
  modoDefinitivoSelecionado,
}) => {
  const form = useForm();

  // These refs are for remembering the previous values of each array that's a
  // dependency of the columns array. We only recalculate the columns array when
  // there's an actual change in the arrays.
  const mineraisPorIdRef = useRef(mineraisPorId);
  const rochasNomeadasPorIdRef = useRef(rochasNomeadasPorId);
  const prefixosPorIdRef = useRef(prefixosPorId);
  const complementosPorIdRef = useRef(complementosPorId);
  const [colunasClastos, setColunasClastos] = useState(gerarColunasClastos(mineraisPorId, rochasNomeadasPorId, prefixosPorId, complementosPorId));

  useEffect(() => {
    // Only update the columns when at least one of the dependencies has really changed.
    // Otherwise, avoid changing the columns because that causes a table rerender.
    // (The *correct* thing to do would be including the names in the objects themselves (add extra fields)
    // and always recalculate those names when dependencies change, completely removing the need for
    // a cellsrenderer.)
    // Compare the dependencies by value.
    if (JSON.stringify(mineraisPorIdRef.current) !== JSON.stringify(mineraisPorId)
      || JSON.stringify(rochasNomeadasPorIdRef.current) !== JSON.stringify(rochasNomeadasPorId)
      || JSON.stringify(prefixosPorIdRef.current) !== JSON.stringify(prefixosPorId)
      || JSON.stringify(complementosPorIdRef.current) !== JSON.stringify(complementosPorId)) {
      setColunasClastos(mineraisPorId, rochasNomeadasPorId, prefixosPorId, complementosPorId);
      mineraisPorIdRef.current = mineraisPorId;
      rochasNomeadasPorIdRef.current = rochasNomeadasPorId;
      prefixosPorIdRef.current = prefixosPorId;
      complementosPorIdRef.current = complementosPorId;
    }
  }, [mineraisPorId, rochasNomeadasPorId, prefixosPorId, complementosPorId])

  const onSubmit = aoEnviarSubForm(form);

  return (
    <div>
      <Card className="mt-5">
        <Card.Body>
          <Card.Subtitle>Clastos</Card.Subtitle>
          <Field name={`clastos`} subscription={{ value: true }}>
            {({ input: { value: clastos = {} } }) => (
              <SubForm
                nome="clastos"
                onSubmit={onSubmit}
                elementos={clastos} // form.getState().values.clastos
                campos={campos}
                colunas={colunasClastos}
                formSubscription={{}}
                onOpen={aoAbrirSubForm}
                onClose={aoFecharSubForm}
                valoresIniciais={valoresIniciais}
                {...getPropsPermissao(permitirEdicao)}
                alternarBotoesPai={true}
                validarVoltar={true}
                exibirTabelaSemRegistro={true}
                renderForm={({ prefixoNome }) => {
                  return (
                    <>
                      <Row className="my-3">
                        <Col md={12}>
                          <Field name={`${prefixoNome}idRochaNomeada`} subscription={{ value: true }}>
                            {({ input: { value: idRochaNomeada } }) => {
                              let tempArray = [];
                              rochasNomeadas?.forEach(item => {
                                if (item?.rochaAtual === 'S' || item.id.toString() === idRochaNomeada.toString()) {
                                  tempArray.push(item);
                                }
                              })
                              return (
                                <Field
                                  component={DropdownListField}
                                  name={`${prefixoNome}idRochaNomeada`}
                                  label="Rocha"
                                  dica="Selecione qual rocha compõe os clastos"
                                  elementos={tempArray}
                                  required={modoDefinitivoSelecionado}
                                  disabled={!permitirEdicao}
                                  validate={validarSe(modoDefinitivoSelecionado, campoObrigatorioComMsgGenerica("Rocha"))}
                                />);
                            }}</Field>
                        </Col>
                        <Col md={12}>
                          {console.log(modoDefinitivoSelecionado)}
                          <Field
                            component={DropdownListField}
                            name={`${prefixoNome}idsMinerais`}
                            label="Minerais"
                            dica="Selecione até quatro minerais que compõe os clastos."
                            //Se a funcionalidade for corrigda para que possa selecionar mais de 4 minerais, ele deverá voltar. 
                            // dica="Selecione qual mineral compõe os clastos"
                            elementos={minerais}
                            disabled={!permitirEdicao}
                            checkboxes={true}
                            multiple
                            sizelimitForSelection={4}
                            required={modoDefinitivoSelecionado}
                            validate={validarSe(modoDefinitivoSelecionado, campoObrigatorioComMsgGenerica("Minerais"))}
                          />
                        </Col>
                      </Row>
                      <Row className="my-3">
                        <Col>
                          <Field
                            component={DropdownListField}
                            name={`${prefixoNome}idPrefixo`}
                            label="Prefixo"
                            dica="Se for o caso, selecione o prefixo de nomenclatura dos clastos de acordo com o tipo"
                            elementos={prefixos}
                            disabled={!permitirEdicao}
                          />
                        </Col>
                        <Col>
                          <Field
                            component={DropdownListField}
                            name={`${prefixoNome}idComplemento`}
                            label="Complemento"
                            dica="Se for o caso, selecione o complemento de nomenclatura dos clastos de acordo com o tipo"
                            elementos={complementos}
                            disabled={!permitirEdicao}
                          />
                        </Col>
                      </Row>
                      <Row className="my-3">
                        <Col>
                          <NomeCompletoClasto
                            mineraisPorId={mineraisPorId}
                            rochasNomeadasPorId={rochasNomeadasPorId}
                            prefixosPorId={prefixosPorId}
                            complementosPorId={complementosPorId}
                            prefixoNome={prefixoNome}
                          />
                        </Col>
                      </Row>
                    </>
                  )
                }}
              />
            )}
          </Field>
        </Card.Body>
      </Card>
    </div>
  );
}

function gerarColunasClastos(mineraisPorId, rochasNomeadasPorId, prefixosPorId, complementosPorId) {
  return [
    {
      text: "Rochas", datafield: "idRochaNomeada", width: "94%",

      cellsrenderer: function (row, columnfield, value, defaulthtml, columnproperties, rowdata) {
        const idsMinerais = isString(rowdata.idsMinerais)
          ? rowdata.idsMinerais.split(',')
            .filter(it => !!it)
            .map(it => Number(it))
          : []

        const nome = resolverNomeRocha({
          mineraisPorId,
          rochasNomeadasPorId,
          prefixosPorId,
          complementosPorId,
          usarMinerais: false,
          idsMinerais,
          idPrefixo: rowdata.idPrefixo,
          idRochaNomeada: rowdata.idRochaNomeada,
          idComplemento: rowdata.idComplemento,
        })

        return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${nome}</div>`;
      }
    },
  ];
}

export default Clastos;
