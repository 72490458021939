import createDecorator from 'final-form-calculate';
import get from "lodash/get";
import { CATEGORIA_LOCALIZACAO_FORA } from '../constantes';
import {
  converterCoordenadas,
  converterCoordenadasParaSirgas,
  converterCoordenadasParaSirgasComCategoria
} from "../converterCoordenadas";

const criarCamposObservados = (prefix, {
  incluirTodosValores = false,
  incluirDatum = true,
} = {}) => {
  let res = incluirDatum ? [
    `${prefix}tipoEntrada`,
    `${prefix}idDatum`,
  ] : [`${prefix}tipoEntrada`];

  if (incluirTodosValores) {
    res = [
      ...res,
      `${prefix}latitude`,
      `${prefix}longitude`,
      `${prefix}x`,
      `${prefix}y`,
      `${prefix}grauLatitude`,
      `${prefix}minutoLatitude`,
      `${prefix}segundoLatitude`,
      `${prefix}grauLongitude`,
      `${prefix}minutoLongitude`,
      `${prefix}segundoLongitude`,
      `${prefix}fuso`
    ];
  }

  return res;
}

const getCoordenadasDeValores = (valores, prefixo) =>
  null !== prefixo && undefined !== prefixo && '' !== prefixo
    ? get(valores, prefixo)
    : valores;

export const decoradorMapa = (
  {
    prefixoCoordenadas = 'coordenadas',
    callbackAoConverter,
    retornarWGS84 = false,
    incluirDatum = true,
  } = {},
) => {
  const prefixoComPonto = prefixoCoordenadas ? prefixoCoordenadas + '.' : '';
  const camposObservados = criarCamposObservados(prefixoComPonto, { incluirDatum });

  return createDecorator({
    field: camposObservados,
    updates: (valoresAlterados, campoAlterado, valoresNovos, valoresAntigos) => {
      let coordenadasAntigas = getCoordenadasDeValores(valoresAntigos, prefixoCoordenadas, {}) ?? {};
      let coordenadasNovas = getCoordenadasDeValores(valoresNovos, prefixoCoordenadas, {}) ?? {};

      const paraTipo = coordenadasNovas.tipoEntrada;
      const paraDatum = coordenadasNovas.idDatum;

      // Altera apenas o DATUM do valor antigo para não converter os valores de latitude e longitude para novos valores
      
      let cloneCoordenadasAntigas = Object.assign({}, coordenadasAntigas);
      cloneCoordenadasAntigas.idDatum = paraDatum;

      return converterCoordenadas(cloneCoordenadasAntigas, paraTipo, paraDatum, {
        retornarWGS84
      })
        .then(res => {
          if (!res.sucesso) return {}

          if (callbackAoConverter) {
            callbackAoConverter(res.dados)
          }

          const modificacoes = {
            [`${prefixoComPonto}latitude`]: res.dados.resultado.latitude,
            [`${prefixoComPonto}longitude`]: res.dados.resultado.longitude,
            [`${prefixoComPonto}x`]: res.dados.resultado.x,
            [`${prefixoComPonto}y`]: res.dados.resultado.y,
            [`${prefixoComPonto}grauLatitude`]: res.dados.resultado.grauLatitude,
            [`${prefixoComPonto}minutoLatitude`]: res.dados.resultado.minutoLatitude,
            [`${prefixoComPonto}segundoLatitude`]: res.dados.resultado.segundoLatitude,
            [`${prefixoComPonto}grauLongitude`]: res.dados.resultado.grauLongitude,
            [`${prefixoComPonto}minutoLongitude`]: res.dados.resultado.minutoLongitude,
            [`${prefixoComPonto}segundoLongitude`]: res.dados.resultado.segundoLongitude,
            [`${prefixoComPonto}fuso`]: res.dados.resultado.fuso,
            [`${prefixoComPonto}idDatum`]: res.dados.resultado.idDatum,
          };

          return modificacoes
        })
        .catch(res => {
          console.log('erro ao converter coordenadas')
          return {}
        })
    }
  })
}

export const decoradorSirgas2000 = (
  paramsVisita,
  {
    prefixoCoordenadas = 'coordenadas',
    callbackAoConverter,
  } = {},
) => {
  const prefixoComPonto = prefixoCoordenadas ? prefixoCoordenadas + '.' : '';
  const camposObservados = criarCamposObservados(prefixoComPonto, {
    incluirTodosValores: true,
  });

  return createDecorator({
    field: camposObservados,
    updates: (valoresAlterados, campoAlterado, valoresNovos) => {

      const coordenadasAntigas = getCoordenadasDeValores(valoresNovos, prefixoCoordenadas, {}) ?? {};
      const {idVisita, visitas} = valoresNovos;
      let visitaId = paramsVisita || visitas?.existentes[0]?.id
      if(visitaId) {
        return converterCoordenadasParaSirgasComCategoria(coordenadasAntigas, visitaId)
        .then(res => {
          if (!res.sucesso) return {}

          if (callbackAoConverter) {
            callbackAoConverter(res.dados)
          }

          const { latitude, longitude, categoriaLocalizacao } = res.dados.resultado;
          const modificacoes = {
            [`coordenadas.latitudeSIRGAS2000`]: latitude,
            [`coordenadas.longitudeSIRGAS2000`]: longitude,
            [`coordenada.dentroArea`]: categoriaLocalizacao !== CATEGORIA_LOCALIZACAO_FORA.id, // Dentro ou entorno
            [`coordenadas.categoriaLocalizacao`]: categoriaLocalizacao,
          };

          return modificacoes
        })
      }

      if (!idVisita) { // Não é uma atividade (mapaEstacao)
        return converterCoordenadasParaSirgas(coordenadasAntigas)
          .then(res => {
            if (!res.sucesso) return {}
  
            if (callbackAoConverter) {
              callbackAoConverter(res.dados)
            }
            const modificacoes = {
              [`coordenadas.latitudeSIRGAS2000`]: res.dados.resultado.latitude,
              [`coordenadas.longitudeSIRGAS2000`]: res.dados.resultado.longitude,
            };
  
            return modificacoes
          })
      } 
      
    }
  })
}
