import Estacoes from "./views/estacoes/Estacoes";
import Coordenadas from "./views/estacoes/Coordenadas";
import Estacao from "./views/estacoes/Estacao";
import ArquivosDetalhes from "./views/arquivos/ArquivosDetalhes";
import Login from "./views/Login/Login";
import ArquivosGPS from "./views/arquivos/ArquivosGPS";
import Index from "./views/Index";
import RecursoMineral from "./views/estacoes/RecursoMineral";
import Deposito from "./views/estacoes/components/RecursoMineral/DepositoPrototipo/Deposito";
import inicial from "./views/estacoes/inicial";
import SobreVersao from "./views/sobre/SobreVersao";
import TelaInicial from "./views/estacoes/TelaInicial";
import OAuth2RedirectHandler from "./views/estacoes/components/oauth2/OAuth2RedirectHandler";

import DadosAnaliticos from "./views/dadosAnaliticos/DadosAnaliticos";
import RegistrarDadoAnalitico from "./views/dadosAnaliticos/RegistrarDadoAnaliticos";
import Validacao from "./views/validacao/Validacao";
import Publicacao from "./views/publicacao/Publicacao";

export const mainRoutes = [
  { path: "/", component: Index, requerAutenticacao: false },
  { path: "/login", component: Login, requerAutenticacao: false },
  {path: "/sobre-versao", component: SobreVersao, requerAutenticacao: false },
  {path: "/oauth2-redirect", component: OAuth2RedirectHandler, requerAutenticacao: false },

  { path: "/index", component: TelaInicial },
  { path: "/estacoes", component: Estacoes },
  { path: "/estacao", component: Estacao },
  { path: "/coordenadas", component: Coordenadas },
  { path: "/gps/porId", component: ArquivosDetalhes },
  { path: "/gps", component: ArquivosGPS },
  // { path: "/arquivos", component: Arquivos },
  { path: "/arquivos-detalhes", component: ArquivosDetalhes },
  { path: "/recurso-mineral", component: RecursoMineral },
  { path: "/prototipoDeposito", component: Deposito },
  { path: "/dados-analiticos", component: DadosAnaliticos },
  { path: "/validacao", component: Validacao },
  { path: "/registrar-analiticos", component: RegistrarDadoAnalitico },
  { path: "/publicacao", component: Publicacao },
]
