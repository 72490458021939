import React, { Component } from 'react';
import { Redirect } from 'react-router-dom'

import {connect} from "react-redux"
import { efetuarLoginOAuth } from '../../../../reducers/authReducers';

class OAuth2RedirectHandler extends Component {
    getUrlParameter(name) {
        // eslint-disable-next-line
        name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
        var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
        let url = this.props.location.search
        // fix rápido porque, aparentemente, o backend codifica a URL de forma diferente que o frontend decodifica @.@
        url = url.replace("%E1", "%C3%A1").replace("%E3", "%C3%A3")
        console.log('this.props.autenticado2',this.props.autenticado)
        localStorage.setItem('USE_GOOGLE_OAUTH2', 'true');
        var results = regex.exec(url);
        return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
    };
    render() {        
        const token = this.getUrlParameter('token');
        const error = this.getUrlParameter('error');
        console.log('this.props.autenticado',this.props.autenticado)
        if (!this.props.autenticado && token) {
            this.props.efetuarLoginOAuth(token);
            return <Redirect to="/index" />;
        } else {
            // TODO: erro ao clicar em cancelar
            if(error !== "[invalid_request]"){
                return <Redirect to={{
                    pathname: "/login",
                    state: {
                        erro: error
                    }
                }}/>; 
            }

            return <Redirect to={{
                pathname: "/login",
            }}/>;

        }
    }
}

export default connect(
    (state) =>({
        autenticado: state.autenticacao.autenticado,
    }),
    (dispatch) => ({
        efetuarLoginOAuth(token) {
            dispatch(efetuarLoginOAuth({token}))
        }
    })
)(OAuth2RedirectHandler);