import React, { Component } from 'react';
import "../ModalNavBar/ModalNavBar.css";

import logo_sgp from '../../../assets/img/Logo_SGB_Creditos.png';
import logo_basegeo from '../../../assets/img/logo_base_geo_azul.png';

export default class ModalNavBar extends Component {
    render() {
        return (
            <nav id={'ModalNavBar'}>
                <div id={'topo1'}>
                    <div className="logos-cabecalho">
                        <div className="logo-container logo-sgih">
                            <img className="logo-cabecalho-img" src={logo_basegeo} alt=""/>
                        </div>
                        <div className="logo-container logo-sgih">
                           
                        </div>
                        <div className="logo-container logo-sgih">
                            <img className="logo-cabecalho-img" src={logo_sgp} alt=""/>
                        </div>
                    </div>
                </div>
            </nav>
        )
    }
}
