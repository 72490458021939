import React, { useCallback } from "react";
import { TextInput } from "../../Jqx/TextInput";

const TextInputField = ({ input, meta, onChange, onBlur, ...props }) => {
  const onChangeCallback = useCallback((evt) => {
    input.onChange(evt)
    onChange?.(evt)
  }, [input, onChange])

  const mensagemErro = meta.touched && !meta.valid && meta.error;

  return (
    <>
    <TextInput
      {...props}
      // TODO: mudar pra onBlur
      onChange={onChangeCallback}
      valor={input.value}
      mensagemErro={mensagemErro}
      onBlur={onBlur}
    />
    </>
  )
}

TextInputField.propTypes = {
  ...TextInput.propTypes,
}

TextInputField.defaultProps = {
  ...TextInput.defaultProps,
}

export default TextInputField
