import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Field, useForm } from "react-final-form";
import { aoEnviarSubForm } from "../../../util/mutadores";
import Card from "../../../components/card";
import { SubForm } from "../../../components/SubForm";
import { TextAreaField } from "../../../components/field/TextAreaField";
import Processo from "./Processo";
import { resolverExibidos } from "../../../components/SubForm/SubForm";
import { FORM_ERROR } from "final-form";

const Petrogenese = ({
    formProps,
    bbProcesso,
    disabled
}) => {   
    const form = useForm()
    const onSubmit = aoEnviarSubForm(form);
    const colunasPetrogenese = [
        {
            text: 'Processo',
            datafield: 'idProcesso',
            width: '30%',
            cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {

                let nomesCorrespondentes = ""

                let petrogenese = resolverExibidos(formProps.form.getState().values?.petrogenese)
                const petrogeneseDaLinha = petrogenese?.[row];
                const processos = petrogeneseDaLinha?.processo;

                if (processos) {
                    const processosEmTela = resolverExibidos(processos)

                    const listaDeIds = processosEmTela.map(({ idProcesso }) => idProcesso);

                    nomesCorrespondentes = bbProcesso
                        .filter(({ id }) => listaDeIds.includes(id))
                        .map(({ nome }) => nome);

                }
                              
                return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;" title="${nomesCorrespondentes}">${nomesCorrespondentes}</div>`;
            }
        },
        {
            text: 'Hierarquia',
            datafield: 'hierarquia',
            width: '30%',
            cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {

                let nomesCorrespondentes = ""
                
                let petrogenese = resolverExibidos(formProps.form.getState().values?.petrogenese)
                const petrogeneseDaLinha = petrogenese?.[row];
                const processos = petrogeneseDaLinha?.processo;
                if (processos) {
                    const processosEmTela = resolverExibidos(processos)
                    nomesCorrespondentes = processosEmTela.map((itens) => itens.hierarquia)
                }

                return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;" title="${nomesCorrespondentes}">${nomesCorrespondentes}</div>`;
            }
        }
    ]

    const camposProcessos = [
        { name: "idProcesso", type: "string", map: "idProcesso" },
        { name: "hierarquia", type: "number", map: "hierarquia" },
        { name: "informacoesRelevantes", type: "string", map: "informacoesRelevantes" }
    ];

    const validarCampos = (valores) => {
        const itens = formProps.form.getState().values;

        if (itens) {
            const estimativas = resolverExibidos(itens);

            const indexExistente = estimativas.findIndex(item => item.id === valores.id);

            if (indexExistente !== -1) {
                estimativas[indexExistente] = valores;
            } else {

                estimativas.push(valores);
            }

            if (estimativas && estimativas.length > 0) {

                if (!estimativas[0].hierarquia && !estimativas[0].processo && !estimativas[0].informacoesRelevantes) {
                    return { [FORM_ERROR]: 'Preencha ao menos um dos campos para proseguir com a confirmação.' };
                }

            }
            return null;
        }
    };




    return (
        <>
            <div style={{ marginTop: '2.5rem' }}>
                <Card className="mt-3">
                    <Card.Body>
                        <Card.Title>Petrogênese</Card.Title>
                        <Field name={'petrogenese'} subscription={{ value: true }}>
                            {({ input: { value: alteracaoHidrotermal = [] } }) => {
                                return (
                                    <SubForm
                                        nome={'petrogenese'}
                                        onSubmit={onSubmit}
                                        colunas={colunasPetrogenese}
                                        elementos={alteracaoHidrotermal}
                                        campos={camposProcessos}
                                        podeExcluirEntidade={!disabled}
                                        exibirBotaoInsercao={!disabled}
                                        permitirEdicao={!disabled}
                                        permitirExclusao={!disabled}
                                        permitirVisualizacao={disabled}
                                        validar={(valor) => validarCampos(valor)}
                                        renderForm={() => {
                                            return (<>
                                                <Processo
                                                    bbProcesso={bbProcesso}
                                                    formProps={formProps.form}
                                                    disabled={disabled}
                                                />
                                                <Row className="my-3">
                                                    <Col md={12}>
                                                        <Field
                                                            component={TextAreaField}
                                                            name={'informacoesRelevantes'}
                                                            label="Informações relevantes"
                                                            dica="Descreva/relacione outras informações relevantes relacionadas aos processos de formação da rocha e de modificações observados na lâmina."
                                                            maxLength={2000}
                                                        />
                                                    </Col>
                                                </Row>
                                            </>
                                            )
                                        }}
                                    />)
                            }}
                        </Field>
                    </Card.Body>
                </Card>
            </div>
        </>
    );
};

export default Petrogenese;