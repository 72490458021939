export const INCLUIDO = 1;
export const PENDENTE = 2;

export const DECIMAL = 0;
export const UTM = 1;
export const GRAUS = 2;

export const SUL = "S";
export const NORTE = "N";

export const CATEGORIA_LOCALIZACAO_DENTRO = {
  id: 1,
  nome: "Localização dentro"
};
export const CATEGORIA_LOCALIZACAO_ENTORNO = {
  id: 2,
  nome: "Localização entorno"
};
export const CATEGORIA_LOCALIZACAO_FORA = {
  id: 3,
  nome: "Localização fora"
};

export const COLUNA_EXIBIR = "idExibir";
export const COLUNA_EXCLUIR = "idExcluir";
export const COLUNA_BAIXAR = "idBaixar";
export const COLUNA_ADICIONAR = "Adicionar";

export const AFLORAMENTO = 1;
export const AMOSTRA = 2;
export const RECURSOS_MINERAIS = 3;

export const IGNEA = 1;
export const METAMORFICA = 2;
export const SEDIMENTAR = 5;

export const CROQUI = 1;
export const VIDEO = 2;
export const IMAGEM = 3;
export const GRAFICO = 4;
export const DESENHO = 5;

export const SIRGAS_2000 = 1;
export const WGS84 = 2;

export const HEMISFERIO_SUL = {
  id: "SUL",
  nome: "Sul",
};

export const HEMISFERIO_NORTE = {
  id: "NORTE",
  nome: "Norte",
};

export const HEMISFERIO_LESTE = {
  id: "LESTE",
  nome: "Leste",
};

export const HEMISFERIO_OESTE = {
  id: "OESTE",
  nome: "Oeste",
};

export const TIPO_ENTRADA_COORDS_DECIMAL = {
  id: "DECIMAL",
  nome: "Decimal",
};
export const TIPO_ENTRADA_COORDS_UTM = {
  id: "UTM",
  nome: "UTM",
};
export const TIPO_ENTRADA_COORDS_GRAUS = {
  id: "GRAUS",
  nome: "Graus",
};

export const TIPO_ATIVIDADE_AFLORAMENTO = {
  id: "AFLORAMENTO",
  nome: "AFLORA - Descrever afloramento",
  nomeOld: "Descrever afloramento",
};

export const TIPO_ATIVIDADE_GEOCRONOLOGIA = {
  id: "GEOCRONOLOGIA",
  nome: "GEOCRONOLOGIA - Cadastrar análise geocronológica",
  nomeOld: "Cadastrar análise geocronológica",
}

export const TIPO_ATIVIDADE_GEOQUIMICA = {
  id: "GEOQUIMICA",
  nome: "Geoquímica"
};

export const TIPO_ATIVIDADE_OUTRAS_ATIVIDADES = {
  id: "OUTRAS_ATIVIDADES",
  nome: "Outras coletas e atividades",
};
export const TIPO_ATIVIDADE_AMOSTRAS = {
  id: "CADASTRO_AMOSTRA",
  nome: "AMOSTRAS - Cadastrar amostras",
  nomeOld: "Cadastrar amostras",
};
export const TIPO_ATIVIDADE_RECURSO_MINERAL = {
  id: "RECURSO_MINERAL",
  nome: "RECMIN - Cadastrar recurso mineral",
  nomeOld: "Cadastrar recurso mineral",
};
export const TIPO_ATIVIDADE_PETROGRAFIA = {
  id: "PETROGRAFIA",
  nome: "PETROGRAFIA - Cadastrar dados analíticos",
  nomeOld: "Cadastrar dados analíticos",
}

export const TIPO_CADASTRO_ATIVIDADE_RASCUNHO = {
  id: "RASCUNHO",
  nome: "Rascunho",
};

export const TIPO_CADASTRO_ATIVIDADE_DEFINITIVO = {
  id: "DEFINITIVO",
  nome: "Completo",
};

export const TIPO_ROCHA_AFLORAMENTO_IGNEA = {
  // id: "IGNEA",
  id: 1,
  nome: "Ígnea"
}

export const TIPO_ROCHA_AFLORAMENTO_METAMORFICA = {
  // id: "METAMORFICA",
  id: 2,
  nome: "Metamórfica"
}

export const TIPO_ROCHA_AFLORAMENTO_SEDIMENTAR = {
  // id: "SEDIMENTAR",
  id: 5,
  nome: "Sedimentar ou sedimentos inconsolidados"
}

export const TIPO_ROCHA_AFLORAMENTO_METASSEDIMENTAR = {
  // id: "METAMORFICA",
  id: 7,
  nome: "Metassedimentar"
}

export const TIPO_ROCHA_AFLORAMENTO_META_IGNEA = {
  // id: "SEDIMENTAR",
  id: 8,
  nome: "Meta-ígneas"
}

// TIPOS DE ESTRUTURA PRIMARIA
export const TIPO_ESTRUTURA_PRIMARIA_IGNEA = {
  id: "I",
  idBanco: 1,
  nome: "Ígnea"
}

export const TIPO_ESTRUTURA_PRIMARIA_SEDIMENTAR = {
  id: "S",  
  idBanco: 5,
  nome: "Sedimentar ou sedimentos inconsolidados"
}
//

export const OPCAO_ROCHA_SEDIMENTAR = {
  id: "ROCHA_SEDIMENTAR",
  nome: "Rocha sedimentar",
};

export const OPCAO_ROCHA_SEDIMENTAR_INCONSOLIDADA = {
  id: "SEDIMENTO_INCONSOLIDADE",
  nome: "Sedimento inconsolidado",
};

export const MODO_CADASTRO_SIMPLIFICADO = {
  id: "SIMPLIFICADO",
  nome: "Simplificado",
};

export const MODO_CADASTRO_BASE_PROJETOS = {
  id: "BASE_PROJETOS",
  nome: "Base de projetos",
};

export const MODO_CADASTRO_DETALHADO = {
  id: "DETALHADO",
  nome: "Detalhado",
};

export const GRAU_IMPORTANCIA_SUBSTANCIA_PRINCIPAL = {
  id: "PRINCIPAL",
  nome: "Principal",
};

export const GRAU_IMPORTANCIA_SUBSTANCIA_SECUNDARIA = {
  id: "SECUNDARIA",
  nome: "Secundária",
};

export const STATUS_ECONOMICO_MINA = {
  id: "Mina",
  nome: "Mina",
};

export const STATUS_ECONOMICO_MINERACAO_ARTESANAL = {
  id: "Mineração artesanal",
  nome: "Mineração artesanal",
};

export const GRAU_IMPORTANCIA_RECURSO_MINERAL_DEPOSITO = {
  id: "Depósito",
  nome: "Depósito",
};

export const GRAU_IMPORTANCIA_RECURSO_MINERAL_OCORRENCIA_MINERAL = {
  id: "Ocorrência mineral",
  nome: "Ocorrência mineral",
};

// Constantes de origem de fonte de referencia
export const ATIVIDADE_EXPLORACAO = {
  id: "ATIVIDADE_EXPLORACAO",
  nome: "Atividade de Exploração"
}

export const OCORRENCIA_MINERAL = {
  id: "OCORRENCIA_MINERAL",
  nome: "Ocorrência Mineral"
}
export const DEPOSITO = {
  id: "DEPOSITO",
  nome: "Depósito"
}

export const MINA_GARIMPO = {
  id: "MINA_GARIMPO",
  nome: "Mina ou Garimpo"
}

export const ATIVIDADE_MINERACAO = {
  id: "ATIVIDADE_MINERACAO",
  nome: "Atividade de Mineração"
}

export const ESTUDO_METALOGENETICO = {
  id: "ESTUDO_METALOGENETICO",
  nome: "Estudo Metalogenético"
}
