import React from "react";
import { Col, Row } from "react-bootstrap";
import { Field } from "react-final-form";
import { DropdownListField } from "../../../../../components/field/DropdownListField";
import { NumberInputField } from "../../../../../components/field/NumberInputField";
import { campoObrigatorioDefinitivoComMsgGenerica } from "../../../../../util/validadores";
import { resolverRequestAmostras } from "../AmostrasUtils/AmostraRequestUtils";
import CamposComuns from "./CamposComuns";
import { TextAreaField } from "../../../../../components/field/TextAreaField";

class Outros extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      situacaoAmostra: [],
      matrizPredominante: [],
      grauIntemperismo: [],
      tipoMineral: [],
      modoDeposito: [],
      velocidadeCorrente: [],
      nivelAgua: [],
      areaDrenagem: [],
      turbidezAgua: [],
      posicaoLeito: [],
      corAgua: [],
      corMaterialColetado: [],
      ambienteBiotico: [],
      arredondamento: [],
      tipoAlteracao: [],
      formaDeposito: [],
    }
  };

  async componentDidMount() {
    this.setState({
      situacaoAmostra: await resolverRequestAmostras("/biblioteca/situacaoamostra"),
      matrizPredominante: await resolverRequestAmostras("/biblioteca/matrizpredominante"),
      grauIntemperismo: await resolverRequestAmostras("/biblioteca/grauintemperismo"),
      tipoMineral: await resolverRequestAmostras("/biblioteca/tipomineral"),
      modoDeposito: await resolverRequestAmostras("/biblioteca/mododeposito"),
      velocidadeCorrente: await resolverRequestAmostras("/amostra/agua/velocidadeCorrente"),
      nivelAgua: await resolverRequestAmostras("/biblioteca/nivelagua"),
      areaDrenagem: await resolverRequestAmostras("/biblioteca/areadrenagem"),
      turbidezAgua: await resolverRequestAmostras("/biblioteca/turbidez"),
      posicaoLeito: await resolverRequestAmostras("/amostra/agua/posicaoLeito"),
      corAgua: await resolverRequestAmostras("/biblioteca/amostras/cor"),
      corMaterialColetado: await resolverRequestAmostras("/biblioteca/amostras/cor"),
      ambienteBiotico: await resolverRequestAmostras("/biblioteca/ambientebiotico"),
      arredondamento: await resolverRequestAmostras("/biblioteca/arredondamento"),
      tipoAlteracao: await resolverRequestAmostras("/biblioteca/tipoalteracao"),
      formaDeposito: await resolverRequestAmostras("/biblioteca/formadeposito"),
    })
  }


  render() {
    return <>
      <CamposComuns
        duplicata={this.props.duplicata}
        permitirEdicao={this.props.permitirEdicao}
        labelCampo="Peso da amostra (g)"
        dicaCampo="Informe o peso da amostra, em gramas"
        profundidadeObrigatoria={false}
        informacoesRelevantesObrigatorias={true}
        prefixo={'outros'}
        visualizar={this.props.visualizar}
      />

      <Field subscription={{ value: true }} name={'modoCadastro'}>
      {({ input: { value: modoCadastro } }) => {

        const validar = modoCadastro === 'DEFINITIVO';

        return <>
              <Row className="mt-3">
        <Col md={6}>
          <Field
            maxLength={250}
            disabled={!this.props.permitirEdicao}
            name={"outros.justificativa"}
            component={TextAreaField}
            label="Justifique o uso dessa classe"
            dica="Informe o motivo do material cadastrado não se adequar as demais classes"
            required={validar}
            validate={campoObrigatorioDefinitivoComMsgGenerica("Justifique o uso dessa classes")}
          />
        </Col>

        <Col md={6}>
          <Field
            maxLength={250}
            disabled={!this.props.permitirEdicao}
            name={"outros.sugestaoNovaClasse"}
            component={TextAreaField}
            label="Sugestão de criação de nova classe"
            dica="Sugira nova classe de amostra a ser criada"
            required={validar}
            validate={campoObrigatorioDefinitivoComMsgGenerica("Sugestão de criação de nova classe")}
          />
        </Col>
      </Row>

      <Row className="mt-3">
        <Col md={4}>
          <Field
            disabled={!this.props.permitirEdicao}
            name="outros.idSituacaoAmostra"
            component={DropdownListField}
            label="Situação da amostra"
            dica="Selecione a situação que melhor se aplica à coleta do material amostrado"
            elementos={this.state.situacaoAmostra}
          />
        </Col>
        <Col md={4}>
          <Field
            disabled={!this.props.permitirEdicao}
            name="outros.idMatrizPredominante"
            component={DropdownListField}
            label="Matriz predominante"
            dica="Selecione a matriz predominante da rocha coletada"
            elementos={this.state.matrizPredominante}
          />
        </Col>
        <Col md={4}>

          <Field
            name="outros.idGrauIntemperismo"
            subscription={{ value: true }}>
            {({ input: { value: idGrau } }) => {
              let listaFiltrada = this.state.grauIntemperismo.filter(grau => grau.atual === 'S');

              if (idGrau && this.state.grauIntemperismo.length && !listaFiltrada.find((grau) => grau.id === idGrau)) {
                const item = this.state.grauIntemperismo.find((grau) => grau.id === idGrau);

                if (item && item.atual === 'N') {
                  listaFiltrada.push(item);
                  listaFiltrada = listaFiltrada.sort((a, b) => a.nome.localeCompare(b.nome))
                }
              }
              return <Field
                component={DropdownListField}
                name="outros.idGrauIntemperismo"
                label="Grau de intemperismo"
                dica="Selecione o grau de intemperismo da amostra coletada"
                elementos={listaFiltrada}
                disabled={!this.props.permitirEdicao}
              />
            }}
          </Field>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col md={4}>
          <Field
            disabled={!this.props.permitirEdicao}
            name="outros.idTipoMineral"
            component={DropdownListField}
            label="Tipo do mineral"
            dica="Selecione o tipo de mineral que a amostra melhor se encaixa, se aplicável"
            elementos={this.state.tipoMineral}
          />
        </Col>
        <Col md={4}>
          <Field
            disabled={!this.props.permitirEdicao}
            name="outros.idModoDeposito"
            component={DropdownListField}
            label="Modo do depósito"
            dica="Selecione o modo de depósito que a amostra se encontra, se aplicáve"
            elementos={this.state.modoDeposito}
          />
        </Col>
        <Col md={4}>
          <Field
            disabled={!this.props.permitirEdicao}
            name="outros.larguraRio"
            component={NumberInputField}
            permitirNegativos={false}
            label="Largura do rio (m)"
            dica="Informe a largura do rio em metros, se aplicável"
            casasDecimais={4}
            casasInteiros={4}
          />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col md={4}>
          <Field
            disabled={!this.props.permitirEdicao}
            name="outros.profundidadeRio"
            component={NumberInputField}
            permitirNegativos={false}
            label="Profundidade do rio (m)"
            dica="Informe a profundidade do rio em metros,se aplicável"
            casasDecimais={4}
            casasInteiros={4}
          />
        </Col>
        <Col md={4}>
          <Field
            disabled={!this.props.permitirEdicao}
            name="outros.idVelocidadeCorrente"
            component={DropdownListField}
            label="Velocidade da corrente"
            dica="Selecione a velocidade da corrente, se aplicável"
            elementos={this.state.velocidadeCorrente}
          />
        </Col>
        <Col md={4}>
          <Field
            disabled={!this.props.permitirEdicao}
            name="outros.idNivelAgua"
            component={DropdownListField}
            label="Nível da água"
            dica="Selecione o nível d'água da drenagem, se aplicável"
            elementos={this.state.nivelAgua}
          />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col md={4}>
          <Field
            disabled={!this.props.permitirEdicao}
            name="outros.idAreaDrenagem"
            component={DropdownListField}
            label="Área de drenagem"
            dica="Selecione a área da bacia de drenagem, se aplicável"
            elementos={this.state.areaDrenagem}
          />
        </Col>
        <Col md={4}>
          <Field
            disabled={!this.props.permitirEdicao}
            name="outros.idTurbidezAgua"
            component={DropdownListField}
            label="Turbidez da água"
            dica="Selecione o nível de turbidez da água no momento da amostragem, se aplicável"
            elementos={this.state.turbidezAgua}
          />
        </Col>
        <Col md={4}>
          <Field
            disabled={!this.props.permitirEdicao}
            name="outros.idPosicaoLeito"
            component={DropdownListField}
            label="Posição do leito"
            dica="Selecione a posição da amostra no leito do rio, se aplicável"
            elementos={this.state.posicaoLeito}
          />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col md={4}>
          <Field
            disabled={!this.props.permitirEdicao}
            name="outros.idCorAgua"
            component={DropdownListField}
            label="Cor da água"
            dica="Selecione a cor da água, se aplicável"
            elementos={this.state.corAgua}
          />
        </Col>
        <Col md={4}>
          <Field
            disabled={!this.props.permitirEdicao}
            name="outros.idCorMaterialColetado"
            component={DropdownListField}
            label="Cor do material coletado"
            dica="Selecione a cor predominante do material coletado"
            elementos={this.state.corMaterialColetado}
          />
        </Col>
        <Col md={4}>
          <Field
            disabled={!this.props.permitirEdicao}
            name="outros.idAmbienteBiotico"
            component={DropdownListField}
            label="Ambiente biótico"
            dica="Selecione o ambiente biótico no local amostrado, se aplicável"
            elementos={this.state.ambienteBiotico}
          />
        </Col>
      </Row>
      <Row className="mt-3 mb-3">
        <Col md={4}>
          <Field
            disabled={!this.props.permitirEdicao}
            name="outros.idGrauArredondamento"
            component={DropdownListField}
            label="Arredondamento"
            dica="Selecione o grau de arredondamento do material original amostrado, se aplicável"
            elementos={this.state.arredondamento}
          />
        </Col>
        <Col md={4}>
          <Field
            name={'outros.idTipoAlteracao'}
            subscription={{ value: true }}>
            {({ input: { value: tipoAlteracao } }) => {

              let listaFiltrada = this.state.tipoAlteracao.filter((t) => t.atual === 'S');

              if (tipoAlteracao && this.state.tipoAlteracao.length && !listaFiltrada.find((t) => t.id === tipoAlteracao)) {
                const item = this.state.tipoAlteracao.find((t) => t.id === tipoAlteracao);

                if (item && item.atual === 'N') {
                  listaFiltrada.push(item);
                  listaFiltrada = listaFiltrada.sort((a, b) => a.nome.localeCompare(b.nome))
                }
              }

              return <Field
                disabled={!this.props.permitirEdicao}
                name="outros.idTipoAlteracao"
                component={DropdownListField}
                label="Tipo de alteração hidrotermal"
                dica="Selecione o tipo de alteração hidrotermal observado no material coletado, se aplicável"
                elementos={listaFiltrada}
              />
            }}
          </Field>
        </Col>
        <Col md={4}>
          <Field
            disabled={!this.props.permitirEdicao}
            name="outros.idFormaDeposito"
            component={DropdownListField}
            label="Forma do depósito"
            dica="Selecione a forma de ocorrência do depósito do material coletado, se aplicável"
            elementos={this.state.formaDeposito}
          />
        </Col>
      </Row>
        </>
      }}
      </Field>
    </>
  }

}

export default Outros;
