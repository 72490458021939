import React, { useEffect, useRef, useState } from "react";
import Card from "../../../components/card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SubForm from "../../../components/SubForm/SubForm";
import TextInputField from "../../../components/field/TextInputField/TextInputField";
import { Field, useForm } from "react-final-form";
import DropdownListField from "../../../components/field/DropdownListField/DropdownListField";
import { campoObrigatorioComMsgGenerica, validarVisitaEstacao } from "../../../util/validadores";
import DateTimeInputField from "../../../components/field/DateTimeInputField/DateTimeInputField";
import TextAreaField from "../../../components/field/TextAreaField/TextAreaField";
import converterVetorParaObjetoIndexado from "../../../util/converterVetorParaObjetoIndexado";
import { aoEnviarSubForm } from "../../../util/mutadores";
import Service, { BASEGEO } from "../../../service/Service";
import CaracterizacaoAmbiente from "./amostras/sections/CaracterizacaoAmbiente";



let lista = null;

const camposVisitasEstacao = [
  {
    name: "id",
    type: "number",
    map: "id",
  },
  {
    name: "idInstituicao",
    type: "string",
    map: "idInstituicao",
  },
  {
    name: "nomeProjeto",
    type: "string",
    map: "nomeProjeto",
  },
  {
    name: "dataInicio",
    type: "date",
    map: "dataInicio",
  },
  {
    name: "nome",
    type: "string",
    map: "nome"
  },
  {
    name: "idUsuarioColetor",
    type: "number",
    map: "idUsuarioColetor"
  }
];

// Itera pelas visitas existentes e editadasPorId e bloqueia exclusão caso ela 
// possua uma atividade associada à ela no banco, caso contrário, permite exclusão
const atualizarPermitirExclusao = (visitas, idsVisitasComAtividadesExistentes) => {
  if (!idsVisitasComAtividadesExistentes || !visitas) return; // Ignora valor inicial do final-form (string vazia)

  // if (visitas.existentes) {
  //   visitas.existentes.forEach((visita = {}, i) => {
  //     if (idsVisitasComAtividadesExistentes.includes(Number(visita.id))) {
  //       visitas.existentes[i].permitirExclusao = false;
  //     } else {
  //       visitas.existentes[i].permitirExclusao = true;
  //     }
  //   });
  // }

  // if (visitas.editadosPorId) {
  //   for (let [key, value] of Object.entries(visitas.editadosPorId)) {
  //     if (idsVisitasComAtividadesExistentes.includes(Number(value.id))) {
  //       visitas.editadosPorId[key].permitirExclusao = false;
  //     } else {
  //       visitas.editadosPorId[key].permitirExclusao = true;
  //     }
  //   }
  // }
};

const VisitasEstacaoFunc = (props) => {
  const form = useForm()
  const [valoresIniciais, setValoresIniciais] = useState({})
  const [idsVisitasComAtividadesExistentes, setIdsVisitasComAtividadesExistentes] = useState(null);
  let bloquearCamposVisualizacao = false;
  let listaColetores = props.coletores;
  const [rochaRegional, setRochaRegional] = useState([])
  const [idadeGeologica, setIdadeGeologica] = useState([])
  const [pluviosidade, setPluviosidade] = useState([])
  const [situacaoTopografica, setSituacaoTopografica] = useState([])
  const [relevo, setRelevo] = useState([])
  const [influenciaAntropica, setInfluenciaAntropica] = useState([])
  const [tipoVegetacao, setTipoVegetacao] = useState([])
  
  if(!lista)
    lista = props.coletores;

  const [colunasVisitasEstacao, setColunasVisitasEstacao] = useState(gerarColunasVisitasEstacao(props.instituicoes, form));
  const [nomeVisita, setNomeVisita] = useState();

  // Recupera ids das visitas que no banco possuem atividades, para bloquear exclusão
  // de visitas caso atividades sejam criadas/excluídas na outra aba enquanto essa não foi salva
  useEffect(() => {
    if (props.idEstacao) { // Pode ser undefined na primeira renderização do componente Estacao
      Service(`estacoes/${props.idEstacao}/visitasComAtividades`, BASEGEO).query()
        .then(res => {
          setIdsVisitasComAtividadesExistentes(res.data.dados);
        })
        .catch(err => console.warn('Não foi possível buscar quais visitas possuem atividades para bloquear exclusão de visitas. Talvez haja dessincronização no bloqueio'));
    }
  }, [props.idEstacao]);

  useEffect(() => {
    setValoresIniciais({
      apelidoTemp: form.getState().values.apelido,
      nomeProjeto: props.nomeProjeto,
      area: props.nomeArea,
      subArea: props.nomeSubArea,
      idArea: props.idArea,
      idSubArea: props.idSubArea,
    })
  }, [props.nomeProjeto, props.nomeArea, props.nomeSubArea, props.idArea, props.idSubArea])

  // This ref is for remembering the previous values of each array that's a
  // dependency of the columns array. We only recalculate the columns array when
  // there's an actual change in the arrays.
  const instituicoesRef = useRef(props.instituicoes);

  useEffect(() => {
    // Only update the columns when at least one of the dependencies has really changed.
    // Otherwise, avoid changing the columns because that causes a table rerender.
    // (The *correct* thing to do would be including the names in the objects themselves (add extra fields)
    // and always recalculate those names when dependencies change, completely removing the need for
    // a cellsrenderer.)
    // Compare the dependencies by value.
    if (JSON.stringify(instituicoesRef.current) !== JSON.stringify(props.instituicoes)) {
      setColunasVisitasEstacao(gerarColunasVisitasEstacao(props.instituicoes, form, listaColetores));
      instituicoesRef.current = props.instituicoes;
    }
  }, [props.instituicoes, form.getState().values.apelido])

//consulta os sequenciais
  useEffect(() => {
    if (form?.getState()?.values?.idArea) {
      Service(`/visitas/retornaSequencial/`+form?.getState()?.values?.idArea, BASEGEO).query()
        .then(res => {
          setNomeVisita(res.data);
        })
        .catch(err => console.warn('Não foi possível buscar os sequenciais'));
    }
  }, [form.getState().values.idArea]);

  const enviaFormulario = aoEnviarSubForm(form);
//ao registrar visita chama funçao que incrementa sequencial se for edição
  function onSubmit(form){
    let idVerificaEdicao = form.getState().values.idNovaVisita;
    if(idVerificaEdicao < 0){
      funcaoQueTrocaSingla();
    }
    
    return enviaFormulario;
  }
//incrementa sequencial correspondente  quando registra uma visita
  function funcaoQueTrocaSingla(){
    nomeVisita?.forEach(element => {
      if(form.getState().values.sigla === element.sigla){
        element.novoSequencial ++
      }
    });
  }
//gera o nome da visita utilizando os sequenciais
  function geraNomeVisita(coletor) {
    form.mutators.setValue('sigla',coletor?.sigla);
    let nome;
    nomeVisita?.forEach(element => {
      if(coletor?.sigla === element.sigla){
        
        let sequencia = element.novoSequencial;
        sequencia = ("0000" + (sequencia)).slice(-4);;
         nome = element.idProjeto + '-' + element.sigla + '-' + sequencia;
      }
      
    });
    return nome;
  }
//registra o id para saber se é uma visita nova
  function isEdicao(idVisita){
    form.mutators.setValue('idNovaVisita',idVisita);
  }
//reseta o sequencial e incrementa novamente com o numero correto de visitas para o usuario
  function corrigeSequencialExcluirVisita(idUsuarioColetor) {
    let nome;
    nomeVisita?.forEach(element => {
      if (element.idUsuario === idUsuarioColetor){
        let sequencia = element.novoSequencial;
        sequencia = ("0000" + (sequencia)).slice(-4);;
         nome = element.idProjeto + '-' + element.sigla + '-' + sequencia;
         element.novoSequencial ++;
      }
    });
    return nome;
  }
//ao excluir uma visita todos os nomes sao alterados para arrumar o sequencial que compoe o nome
  function aoExcluirVisita(form){
    nomeVisita?.forEach(element => {
      element.novoSequencial =element.sequencial;
    });
    let novasVisitas = form.getState().values?.visitas?.novos;
    
    novasVisitas?.forEach(element => {
      if (element){
        element.nome = corrigeSequencialExcluirVisita(element?.idUsuarioColetor);
      }
    });

  }
  useEffect(() => {
    Service(`/biblioteca/rocharegional`, BASEGEO).query()
    .then(res => {
      setRochaRegional(res.data);
    })
    .catch(err => console.warn('Não foi possível buscar Rocha Regional', err));
    Service(`/biblioteca/idadegeologica`, BASEGEO).query()
    .then(res => {
      setIdadeGeologica(res.data);
    })
    .catch(err => console.warn('Não foi possível buscar Idade Geologica', err));
    Service(`/biblioteca/pluviosidade`, BASEGEO).query()
    .then(res => {
      setPluviosidade(res.data);
    })
    .catch(err => console.warn('Não foi possível buscar Pluviosidade', err));
    Service(`/biblioteca/situacaotopografica`, BASEGEO).query()
    .then(res => {
      setSituacaoTopografica(res.data);
    })
    .catch(err => console.warn('Não foi possível buscar Situacao Topografica', err));
    Service(`/biblioteca/relevo`, BASEGEO).query()
    .then(res => {
      setRelevo(res.data);
    })
    .catch(err => console.warn('Não foi possível buscar Relevo', err));
    Service(`/biblioteca/influenciaantropica`, BASEGEO).query()
    .then(res => {
      setInfluenciaAntropica(res.data);
    })
    .catch(err => console.warn('Não foi possível buscar Influenciaantropica', err));
    Service(`/biblioteca/tipovegetacao`, BASEGEO).query()
    .then(res => {
      setTipoVegetacao(res.data);
    })
    .catch(err => console.warn('Não foi possível buscar Tipo Vegetacao', err));
  }, []);

  return (
    <>
      <Card className="card-mt">
        <Card.Body>
          <Card.Title>Visitas <span style={{ color: "red" }}>*</span></Card.Title>
          { props.exibirMensagemDeErro ? <p style={{marginTop: '5px', marginBottom: '5px'}} className="text-danger">Ao menos uma visita deve ser cadastrada para salvar a estação</p> : <></> }
          <Field name="visitas" subscription={{ value: true }}>
            {({ input: { value: visitas = {} } }) => {
              if (idsVisitasComAtividadesExistentes) {
                atualizarPermitirExclusao(visitas, idsVisitasComAtividadesExistentes);
              }

              return (<SubForm
                nome="visitas"
                colunas={colunasVisitasEstacao}
                campos={camposVisitasEstacao}
                formSubscription={{}}
                valoresIniciais={valoresIniciais}
                aoConfirmarVoltar={() => { bloquearCamposVisualizacao = false }}
                onBtnViewClick={() => { bloquearCamposVisualizacao = true }}
                onSubmit={onSubmit(form)}
                permitirVisualizacao={true}
                // onBtnViewClick={() => setBloquearCamposVisualizacao(true)}
                permitirInsercao={props.permitirEdicao}
                elementos={visitas ?? {}} // form.getState().values.visitas
                onOpen={() => props.aoAbrirSubForm()}
                onClose={() => props.aoFecharSubForm()}
                alternarBotoesPai={true}
                validarVoltar={true}
                onBtnDeleteClick={aoExcluirVisita(form)}
                validar={validarVisitaEstacao}
                renderForm={({ formProps: { form }, prefixoNome }) => {
                  let editando = false;
                  if (form.getState().values?.nome){
                    editando = true;
                  }

                  //TODO: (marco) habilita usuario cadastrado quando usuario logado possui permissão somente para edição
                  const possiveisColetores = [];
                  const idColetor = form.getState().values.idUsuarioColetor;
                  for (let index = 0; index < props.coletores.length; index++) {
                    var element = props.coletores[index];
                    if (element.id === idColetor) {
                      element = {
                        ...element,
                        itemDesabilitado: false
                      }
                    }
                    if (element.itemDesabilitado === false)
                      possiveisColetores.push(element)
                  }

                  return <>
                    <Row className="mt-3">
                      <Field
                        name={`${prefixoNome}idArea`}
                        component="input" type="hidden"
                      />
                      <Field
                        name={`${prefixoNome}idSubArea`}
                        component="input" type="hidden"
                      />
                      <Col md={3}>
                      <Field name={`${prefixoNome}idUsuarioColetor`} subscription={{ value: true }}>
                          {({ input: { value } }) => {
                            if (value) {
                              const coletor = possiveisColetores.find(coletor => coletor.id === value);
                              let idVerificaEdicao = form.getState().values.id;
                              let nomePreenchido = form.getState().values?.nome
                              if(idVerificaEdicao < 0 && nomePreenchido === undefined){
                                form.mutators.setValue('nome',geraNomeVisita(coletor));
                                isEdicao(idVerificaEdicao);
                              }
                            } else {
                              form.mutators.setValue('nome', undefined);
                            }
                            return <Field
                              component={TextInputField}
                              name={`nome`}
                              label="Nome"
                              dica="Preenchimento automático contendo CÓDIGO DO PROJETO - SIGLA DO RESPONSÁVEL - NÚMERO DA VISITA (sequencial)"
                              disabled={true}
                            />
                          }}
                        </Field>
                      </Col>
                      <Col md={3} className='visit-label'>
                        <Field
                          disabled={bloquearCamposVisualizacao ?? false}
                          component={DropdownListField}
                          name={`${prefixoNome}idInstituicao`}
                          label="Instituição"
                          dica="Selecione a instituição a qual o responsável pelo cadastro pertence"
                          elementos={props.instituicoes}
                          required
                          initialValue={verificaListaUnica(props.instituicoes)}
                          validate={campoObrigatorioComMsgGenerica("Instituição")}
                        />
                      </Col>
                      <Col md={3}>
                        {/* Uma visita possui uma área e pode ter uma subárea. Não existe associação
                          direta entre projeto e visita. Esse campo é apenas para exibição */}
                        {/* O valor idProjeto vem do back e é utilizado no frontend ao carregar o formulário, 
                          mas por outro motivo (recuperar coletores/instituicoes) específicos no caso de estações históricas.
                          Por isso não é necessário um Field para ele
                          Ver Estacao#componentDidMount */}
                        <Field
                          component={TextInputField}
                          name={`${prefixoNome}nomeProjeto`}
                          label="Projeto"
                          dica="Preenchimento automático com o Nome do Projeto"
                          disabled={true}
                        />
                      </Col>
                      <Col md={3}>
                        <Field
                          component={TextInputField}
                          name={`${prefixoNome}area`}
                          label="Área"
                          dica="Preenchimento automático com a Área em que a estação está localizada"
                          disabled={true}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col className='label-input-text'>
                        <Field
                          component={TextInputField}
                          name={`${prefixoNome}subArea`}
                          label="Subárea"
                          dica="Preenchimento automático com a Subárea em que a estação está localizada"
                          disabled={true}
                        />
                      </Col>
                      <Col>
                        <Field
                          disabled={(bloquearCamposVisualizacao ?? false)}
                          component={DropdownListField}
                          name={`${prefixoNome}idUsuarioColetor`}
                          label="Coletor"
                          dica="Selecione o/a responsável pela coleta das informações"
                          // elementos={props.coletores}
                          elementos={possiveisColetores}
                          required
                          initialValue={verificaItemUnico(possiveisColetores, form, `${prefixoNome}idUsuarioColetor`)}
                          validate={campoObrigatorioComMsgGenerica("Coletor")}
                        />
                      </Col>
                      <Col className='date-input'>
                        <Field name={`${prefixoNome}dataTermino`} subscription={{ value: true }}>
                          {({ input: { value } }) => <>
                            <Field
                              disabled={bloquearCamposVisualizacao ?? false}
                              component={DateTimeInputField}
                              name={`${prefixoNome}dataInicio`}
                              label="Data de início"
                              dica="Informar a data de início da visita nesta estação"
                              required
                              validate={campoObrigatorioComMsgGenerica("Data de início")}
                            />
                          </>}
                        </Field>
                      </Col>
                      <Col className='date-input'>
                        <Field name={`${prefixoNome}dataInicio`} subscription={{ value: true }}>
                          {({ input: { value } }) => <>
                            <Field
                              disabled={bloquearCamposVisualizacao ?? false}
                              component={DateTimeInputField}
                              name={`${prefixoNome}dataTermino`}
                              label="Data de término"
                              dica="Informar a data de término da visita nesta estação"
                              required
                              validate={campoObrigatorioComMsgGenerica("Data de término")}
                            />
                          </>}
                        </Field>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Field
                          disabled={bloquearCamposVisualizacao ?? false}
                          component={TextAreaField}
                          name={`${prefixoNome}objetivos`}
                          label="Objetivos da visita"
                          dica="Descreva os objetivos da visita"
                          maxLength={2000}
                        />
                      </Col>
                      <Col>
                        <Field
                          disabled={bloquearCamposVisualizacao ?? false}
                          component={TextAreaField}
                          name={`${prefixoNome}toponimia`}
                          label="Toponímia"
                          dica="Descrição detalhada do local da estação"
                          required
                          validate={campoObrigatorioComMsgGenerica("Toponímia")}
                          maxLength={2000}
                        />
                      </Col>
                      <Col>
                        <Field
                          disabled={bloquearCamposVisualizacao ?? false}
                          component={TextAreaField}
                          name={`${prefixoNome}informacoesRelevantes`}
                          label="Informações relevantes"
                          dica="Registre informações que enriqueçam a descrição do local e/ou das atividades realizadas na visita."
                          maxLength={2000}
                        />
                      </Col>
                    </Row>
                    <br></br>
                    <CaracterizacaoAmbiente
                      permitirEdicao={bloquearCamposVisualizacao ?? false}
                      prefixoNome={prefixoNome}
                      rochaRegional={rochaRegional}
                      idadeGeologica={idadeGeologica}
                      pluviosidade={pluviosidade}
                      situacaoTopografica={situacaoTopografica}
                      relevo={relevo}
                      influenciaAntropica={influenciaAntropica}
                      tipoVegetacao={tipoVegetacao}
                    />
                  </>
                }}
                tabIndexAdicionar={5}
              />);
            }}        
          </Field>
        </Card.Body>
      </Card>
    </>
  );
}

function gerarColunasVisitasEstacao(instituicoes, form, listaColetores) {
  const instituicoesPorId = converterVetorParaObjetoIndexado(instituicoes);
  return [
    {
      text: "Nome", datafield: "nome", width: '21%'
    },
    {
      text: "Instituição", datafield: "idInstituicao", width: '30%',
      cellsrenderer: function (row, columnfield, value, defaulthtml, columnproperties, rowdata) {
        const idInstituicao = rowdata[columnfield]
        const nomeInst = idInstituicao && instituicoesPorId && instituicoesPorId[idInstituicao]
          ? instituicoesPorId[idInstituicao].nome
          : ''
        return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${nomeInst}</div>`;
      }
    },
    {
      text: "Responsável", datafield: "apelido", width: '20%', cellsrenderer: function (row, columnfield, value, defaulthtml, columnproperties, rowdata) {
        const coletor = lista ? lista.find((c) => c.id === rowdata.idUsuarioColetor) : null;

        let text = '';

        if(coletor != null)
          text = coletor.nome + " - " + coletor.sigla;

        return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${text}</div>`;
      }
    },
    { text: "Data início", datafield: "dataInicio", cellsformat: 'dd/MM/yyyy', width: '20%' },
  ];
}

function verificaListaUnica(lista) {
  if (lista) {
    if (lista.length === 1) {
      return lista[0].id;
    }
  }
}

function verificaItemUnico(lista, form, campo) {
  const valor = verificaListaUnica(lista);
  if (valor) {
    form.mutators.setValue(campo, valor);
  }
}

export default VisitasEstacaoFunc;
