import { useCallback } from "react";
import Service from "../../../service/Service";

export function useCarregarColetoresProjeto({ idProjeto, coletores, setColetores }) {

    const carregarColetoresProjeto = useCallback(async () => {
        if (coletores)
            return;

        return Service("/coletores/paraProjeto")
            .query({ idProjeto })
            .then(({ data: { dados: coletores } }) => {
                setColetores(coletores);
            })
            .catch((err) =>
                console.error(
                    "Erro ao buscar visitas da estacao:",
                    err
                )
            );
    }, [idProjeto, coletores, setColetores]);

    return carregarColetoresProjeto;
}