import React, { useCallback, useState, useEffect } from "react";
import Checkbox from "../Jqx/Checkbox/Checkbox";

const CheckboxField = ({input, meta, ...props}) => {
  const [value, setValue] = useState(props.checked);
  
  const onChange = useCallback((evt) => {
    setValue(evt.args.checked);
    props.onCheck(evt.args.checked);
    input.onChange(evt)
  }, [input])

  const mensagemErro = meta.touched && !meta.valid && meta.error;

  useEffect(() => {
    setValue(props.checked);
  }, [props.checked])

  return <>
    {
      value ?
        <Checkbox
          {...props}
          onChange={onChange}
          checked
          mensagemErro={mensagemErro}
        /> 
        : 
        <Checkbox
          {...props}
          onChange={onChange}
          mensagemErro={mensagemErro}
        />
      }
  </>
}

CheckboxField.propTypes = {
  ...Checkbox.propTypes,
}

CheckboxField.defaultProps = {
  ...Checkbox.defaultProps,
}

export {CheckboxField}
