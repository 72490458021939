import $ from "jquery";
import { Observable } from "rxjs";
import "./printThis";

export interface PrintOptions {
  afterHtmlCloned?: Function;
}

export class PrintingService {
  print(
    elementOrSelector: HTMLElement | string,
    options?: PrintOptions
  ): Observable<void> {
    return new Observable((subscriber) => {
      const abortController = new AbortController();
      subscriber.add(() => {
        abortController.abort();
      });

      $(elementOrSelector).printThis({
        afterHtmlCloned: (...args) => {
          if (options?.afterHtmlCloned) {
            return options.afterHtmlCloned(...args);
          }
        },
        onSuccess: () => {
          subscriber.next();
          subscriber.complete();
        },
        onError: (e) => {
          subscriber.error(e);
        },
        canvas: true,
        importCSS: true,
        importStyle: true,
        base: true,
        printContainer: true,
        copyTagClasses: true,
        printDelay: 150,
        abortSignal: abortController.signal,
      });
    });
  }
}
