import { TextInput } from "../../Jqx/TextInput";
import { Field } from "react-final-form";
import { DropdownListField } from "../DropdownListField";

const YearInputField = ({ input,valorFinal,valorInicial, label, ...props }) => {
  let elementos=[];
    for(var i = valorInicial; i <= valorFinal;i++){
      let elemento = {id:i,nome:i}
      elementos.push(elemento);
    }
  return (
    <>
      <Field
          component={DropdownListField}
          elementos={elementos}
          name={input.name}
          label={label}
          dica={props.dica}
          required={props.required}
        />
    </>
  )
}

YearInputField.propTypes = {
  ...TextInput.propTypes,
}

YearInputField.defaultProps = {
  ...TextInput.defaultProps,
}

export default YearInputField