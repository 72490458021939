import React, { Component, useCallback } from "react";
import PropTypes from "prop-types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquare, faCircle, faVectorSquare } from '@fortawesome/free-solid-svg-icons';

import {faSquare as faSquareRegular} from "@fortawesome/free-regular-svg-icons";
import Container from "react-bootstrap/Container";
import { Col, Row } from "react-bootstrap";
import './LegendaMapaAnalitico.css';

const LegendaMapaAnalitico = (
        { ...props }
    ) => {
    return <>
        <Row>
            <Col md={12} id="legendaMapaAnalitico" className="ml-0 legenda-mapa">
                <Container className="borda-legenda p-2 mb-3 pr-4 pl-0">
                <Row className="d-flex align-items-center">
                    {iconeEstacao(props.exibirEstacao)}
                    {iconeEstacaoHistorica(props.exibirEstacaoHistorica)}
                    {iconeEstacaoEditada(props.exibirEstacaoEditada)}
                    {iconeAreaSubarea(props.exibirGeometriaProjeto)}
                    {iconePontoGPS(props.exibirPontoGPS)}
                    {iconePontoGerado(props.exibirPontoGerado)}
                    {iconeAtividade(props.exibirAtividade)}
                    {iconeAmostra(props.exibirAmostra)}
                </Row>
                </Container>

            </Col>
        </Row>
    </>
}

function iconeEstacao(exibir) {
    if(exibir)
        return <>
            {/* Estacao */}
            <Col md={1} className="p-0 d-flex justify-content-end">
                        <FontAwesomeIcon id="iconeEstacao" className="amostra" size="sm" icon={faSquare} />
                    </Col>
            <Col md={2} className="p-0 d-flex justify-content-start"><p className="m-0">Amostra</p></Col>
        </>
    return <></>
}

function iconeEstacaoHistorica(exibir) {
    if(exibir)
        return <>
            {/*Estacao  Histórica*/}                    
            <Col md={1} className="p-0 d-flex justify-content-end">
                <FontAwesomeIcon id="iconeEstacaoHistorica" className="amostra-historica" icon={faSquare} />
            </Col>
            <Col md={2} className="p-0 d-flex justify-content-start"><p className="m-0">Amostra histórica</p></Col>
        </>
    return <></>
}

function iconeEstacaoEditada(exibir) {
    if(exibir)
        return <>
            {/* Estacao  Editada*/}                    
            <Col md={1} className="p-0 d-flex justify-content-end">
                <FontAwesomeIcon id="iconeEstacaoEditada" size="sm" className="amostra-editada" icon={faSquare} />
            </Col>
            <Col md={2} className="p-0 d-flex justify-content-start"><p className="m-0">Amostra editada</p></Col>

        </>
    return <></>
}

function iconePontoGPS(exibir) {
    if(exibir)
        return <>
            {/* Ponto GPS Não Selecionado*/}                    
            <Col md={1} className="p-0 d-flex justify-content-end">
                <FontAwesomeIcon id="iconePontoGPSNaoSelecionado" className="ponto-gps ponto-gps-nao-selecionado" size="sm" icon={faSquare} />
            </Col>
            <Col md={2} className="p-0 d-flex justify-content-start"><p className="m-0">Ponto GPS não selecionado</p></Col>

            {/* Ponto GPS Selecionado*/}                    
            <Col md={1} className="p-0 d-flex justify-content-end">
                <FontAwesomeIcon id="iconePontoGPSSelecionado" className="ponto-gps ponto-gps-selecionado" size="sm" icon={faSquare} />
            </Col>
            <Col md={2} className="p-0 d-flex justify-content-start"><p className="m-0">Ponto GPS selecionado</p></Col>
        </>
    return <></>
}

function iconePontoGerado(exibir) {
    if(exibir)
        return <>
            {/* Ponto Gerado Não Selecionado*/}                    
            <Col md={1} className="p-0 d-flex justify-content-end">
                <FontAwesomeIcon id="iconePontoGeradoNaoSelecionado" size="1x"  className="ponto-gerado-nao-selecionado" icon={faCircle} />
            </Col>
            <Col md={2} className="p-0 d-flex justify-content-start"><p className="m-0">Ponto gerado não selecionado</p></Col>

            {/* Ponto Gerado  Selecionado*/}                    
            <Col md={1} className="p-0 d-flex justify-content-end">
                <FontAwesomeIcon id="iconePontoGeradoSelecionado" size="1x" className="ponto-gerado-selecionado" icon={faCircle} />
            </Col>
            <Col md={2} className="p-0 d-flex justify-content-start"><p className="m-0">Ponto gerado selecionado</p></Col>
        </>
    return <></>
}

function iconeAreaSubarea(exibir){
    if(exibir)
        return <>
            {/* Estacao  Projeto*/}                    
            <Col md={1} className="p-0 d-flex justify-content-end">
                <FontAwesomeIcon id="iconeProjeto" size="1x" className="geometria-projeto" icon={faSquareRegular} />
            </Col>
            <Col md={2} className="p-0 d-flex justify-content-start"><p className="m-0">Área/Subárea</p></Col>
        </>
    return <></>
}

function iconeAtividade(exibir) {
    if(exibir)
        return <>
            {/* Atividade */}                    
            <Col md={1} className="p-0 d-flex justify-content-end">
                <FontAwesomeIcon id="iconeAtividade" size="1x" color="rgba(255, 160, 0, 0.5)" icon={faSquare} />
            </Col>
            <Col md={2} className="p-0 d-flex justify-content-start"><p className="m-0">Atividade</p></Col>
        </>
    return <></>
}

function iconeAmostra(exibir) {
    if(exibir)
        return <>
            {/* Atividade */}                    
            <Col md={1} className="p-0 d-flex justify-content-end">
                <FontAwesomeIcon id="iconeAmostra" size="1x" color="rgba(255, 160, 0, 0.5)" icon={faSquare} />
            </Col>
            <Col md={2} className="p-0 d-flex justify-content-start"><p className="m-0">Amostra</p></Col>
        </>
    return <></>
}

LegendaMapaAnalitico.propTypes = {
    exibirEstacao:PropTypes.bool,
    exibirEstacaoHistorica:PropTypes.bool,
    exibirEstacaoEditada:PropTypes.bool,
    exibirAtividade:PropTypes.bool,
    exibirAmostra:PropTypes.bool,
    exibirPontoGPS:PropTypes.bool,
    exibirPontoGerado:PropTypes.bool,
    exibirGeometriaProjeto:PropTypes.bool,
  }
  
LegendaMapaAnalitico.defaultProps = {
    exibirEstacao:true,
    exibirEstacaoHistorica:false,
    exibirEstacaoEditada:false,
    exibirAtividade:false,
    exibirAmostra:false,
    exibirPontoGPS:false,
    exibirPontoGerado:false,
    exibirGeometriaProjeto:false,
}

export default LegendaMapaAnalitico
  