import React, { useMemo } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import { mainRoutes } from "../../routes.js";
// import { createBrowserHistory } from "history"
import { useSelector } from "react-redux";

// const history = createBrowserHistory()

const Rotas = () => {
  const usuarioAutenticado = useSelector(state => state.autenticacao.autenticado);

  const rotas = useMemo(() => {
    return mainRoutes.map((prop, key) => {
      const requerAuth = undefined === prop.requerAutenticacao || prop.requerAutenticacao;
      return requerAuth ? (
        <PrivateRoute
          exact
          path={prop.path}
          component={prop.component}
          key={key}
          authenticated={usuarioAutenticado}
        />
      ) : (
        <Route
          exact
          key={key}
          path={prop.path}
          component={prop.component}
          // params={prop.params}
        />
      )
    });
  }, [usuarioAutenticado])

  return (
    // <Router basename={process.env.REACT_APP_BASE_HREF ?? '/'} history={history}>
    <Router basename={process.env.REACT_APP_BASE_HREF ?? '/'}>
      <Switch>
        {rotas}
      </Switch>
    </Router>
  );
}

export default Rotas;
