import React, { useCallback, useEffect, useMemo, useState } from "react"
import MaskedInput from "./MaskedInput"
import { MaskedEnum, MaskedRange } from 'imask'

const ID_NOTACAO_QUADRANTE = 2
const ID_NOTACAO_CLER = 3

const clerBlocks = {
  d: {
    mask: MaskedRange,
    from: 0,
    to: 90
  },
  s: {
    mask: MaskedRange,
    from: 0,
    to: 360
  },
}

const quadranteBlocks = {
  n: {
    mask: MaskedEnum,
    enum: ['N', 'n']
  },
  s: {
    mask: MaskedEnum,
    enum: ['S', 's']
  },
  w: {
    mask: MaskedEnum,
    enum: ['W','w']
  },
  e: {
    mask: MaskedEnum,
    enum: ['E','e']
  },
  NUMBER:{
    mask: MaskedRange,
    from:0,
    to:90,
  }
}

const AtitudeField = ({ idTipoNotacao, onChange, disabled, input, meta, ...props }) => {
  const onChangeCallback = useCallback((evt) => {
    const uppercased = evt?.toUpperCase()
    input.onChange(uppercased)
    onChange?.(uppercased)
  }, [input, onChange])

  const [exemplo, setExemplo] = useState('');

  const mensagemErro = meta.touched && !meta.valid && meta.error;

  const mascara = useMemo(() => {
    if (ID_NOTACAO_CLER === idTipoNotacao) {
      return {
        mask: [
          {
            mask: 's/d',
            blocks: clerBlocks,
          },
          {
            mask: 'd/s',
            blocks: clerBlocks,
          },
        ]
      }
    } else if (ID_NOTACAO_QUADRANTE === idTipoNotacao) {
      return {
        mask: [
          {
            mask:"nNUMBERe/NUMBERse",
            blocks: quadranteBlocks
          },
          {
            mask:"nNUMBERe/NUMBERnw",
            blocks: quadranteBlocks
          },
          {
            mask:"sNUMBERe/NUMBERsw",
            blocks: quadranteBlocks
          },
          {
            mask:"sNUMBERe/NUMBERne",
            blocks: quadranteBlocks
          },
          {
            mask:"sNUMBERw/NUMBERse",
            blocks: quadranteBlocks
          },
          {
            mask:"sNUMBERw/NUMBERnw",
            blocks: quadranteBlocks
          },          
          {
            mask:"nNUMBERw/NUMBERne",
            blocks: quadranteBlocks
          },          
          {
            mask:"nNUMBERw/NUMBERsw",
            blocks: quadranteBlocks
          },          
          {
            mask:"NUMBERne/nNUMBERe",
            blocks: quadranteBlocks
          },
          {
            mask:"NUMBERnw/nNUMBERw",
            blocks: quadranteBlocks
          },
          {
            mask:"NUMBERse/sNUMBERe",
            blocks: quadranteBlocks
          },
          {
            mask:"NUMBERsw/sNUMBERw",
            blocks: quadranteBlocks
          },
        ]
      }
    }

    return { mask: '' }
  }, [idTipoNotacao])

  useEffect(() => {    
    if (ID_NOTACAO_CLER === idTipoNotacao) 
      setExemplo('Exemplos: planar - 162/33 , linear - 45/090');
      
    else if (ID_NOTACAO_QUADRANTE === idTipoNotacao) 
      setExemplo('Exemplos: planar - N56E/45SE , linear - 45SE/S56E');
      
    else
      setExemplo('');

  }, [idTipoNotacao]);

  return (
    <>
      <MaskedInput
        onAccept={onChangeCallback}
        placeholderChar={'_'}
        placeholder={exemplo}
        disabled={disabled}
        mensagemErro={mensagemErro}
        value={input.value}
        lazy={false}
        {...mascara}
        {...props}
      />
    </>
  )
}

export default AtitudeField
