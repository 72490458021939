const Localizacao = {
  // localização para componente Table
  filterstringcomparisonoperators: ['vazio', 'não vazio', 'contém', 'contém(combinado)',
  'não contém', 'não contém(combinado)', 'começa com', 'começa com(combinado)',
  'termina com', 'termina com(combinado)', 'igual', 'igual(combinado)', 'nulo', 'não nulo'],
  filterdatecomparisonoperators: ['igual','diferente', 'menor que', 'menor ou igual', 'maior que', 'maior ou igual', 'nulo', 'não nulo'],
  filternumericcomparisonoperators: ['igual', 'diferente', 'menor que', 'menor ou igual', 'maior que', 'maior que ou igual', 'nulo', 'não nulo'],
  filterbooleancomparisonoperators: ['igual', 'diferente'],
  currencysymbol: "R$ ",
  filterorconditionstring: "Ou",
  filterandconditionstring: "E",
  filtershowrowstring: "Mostrar linhas contendo:",
  sortascendingstring: "Ordem Crescende",
  sortdescendingstring: "Ordem Decrescente",
  sortremovestring: "Remover Ordenação",
  filterclearstring: "Limpar",
  filterstring: "Filtrar",
  emptydatastring: "Sem dados para exibir",
  pagergotopagestring: "Ir para a página:",
  pagershowrowsstring: "Quantidade de linhas:",
  pagerrangestring: " de ",
  loadtext: "Carregando...",

  filtershowrowdatestring: "Mostrar linhas em que a data:",
  currencysymbolposition: "before",
  decimalseparator: ',',
  thousandsseparator: '.',
  pagerpreviousbuttonstring: "anterior",
  pagernextbuttonstring: "próxima",
  groupsheaderstring: "Arraste uma coluna e solte-a aqui para agrupar por essa coluna",
  groupbystring: "Agrupar por esta coluna",
  groupremovestring: "Remover dos grupos",
  filterselectallstring: "(Selecionar tudo)",
  filterchoosestring: "Por favor, escolha:",
  validationstring: "O valor inserido não é válido",
  filterselectstring: "Selecionar filtro",
  days: {
      // full day names
      names: ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"],
      // abbreviated day names
      namesAbbr: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"]
  },

  months: {
      // full month names (13 months for lunar calendards -- 13th month should be "" if not lunar)
      names: ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro", ""],
      // abbreviated month names
      namesAbbr: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez", ""]
  },

  // separator of parts of a date (e.g. '/' in 11/05/1955)
  '/': "/",
  // separator of parts of a time (e.g. ':' in 05:44 PM)
  ':': ":",
  // the first day of the week (0 = Sunday, 1 = Monday, etc)
  firstDay: 0,
  twoDigitYearMax: 2099,
  patterns: {
      // short date pattern
      d: "M/d/yyyy",
      // long date pattern
      D: "dddd, MMMM dd, yyyy",
      // short time pattern
      t: "h:mm tt",
      // long time pattern
      T: "h:mm:ss tt",
      // long date, short time pattern
      f: "dddd, MMMM dd, yyyy h:mm tt",
      // long date, long time pattern
      F: "dddd, MMMM dd, yyyy h:mm:ss tt",
      // month/day pattern
      M: "MMMM dd",
      // month/year pattern
      Y: "yyyy MMMM",
      // S is a sortable format that does not vary by culture
      S: "yyyy\u0027-\u0027MM\u0027-\u0027dd\u0027T\u0027HH\u0027:\u0027mm\u0027:\u0027ss"
  },
  percentsymbol: "%",
  clearstring: "Limpar",
  todaystring: "Hoje",


  // localização para componente Arquivos > FileUploader
  browseButton: "Adicionar",
  uploadButton: "Salvar todos",
  cancelButton: "Remover todos",
  uploadFileTooltip: "Salvar arquivo",
  cancelFileTooltip: "Remover arquivo",
}

export default Localizacao;
