import { PrimaryButton } from "../../../../../../components/Jqx/Button";
import PropTypes from "prop-types";

function BotaoEnviarReavaliacao({ aoEnviar }) {
    return <PrimaryButton
        className="float-right m-1 ml-2"
        onClick={() => aoEnviar()}
        titulo="Enviar"
    />
}

BotaoEnviarReavaliacao.propTypes = {
    aoEnviar: PropTypes.func,
}

export { BotaoEnviarReavaliacao };
