import React, { PureComponent } from "react";
import { Subscription } from "rxjs";
import etiquetasService from "../../../../../../service/EtiquetasService";

import "./EtiquetaAmostra.scss";

export interface EtiquetaAmostraProps {
  barcode: string;
}

export interface EtiquetaAmostraState {
  image?: HTMLImageElement;
}

export class EtiquetaAmostra extends PureComponent<
  EtiquetaAmostraProps,
  EtiquetaAmostraState
> {
  private imageSubscription!: Subscription;

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.handleImageIfCan();
  }

  componentDidUpdate(prevProps: Readonly<EtiquetaAmostraProps>) {
    if (prevProps.barcode !== this.props.barcode) {
      this.handleImageIfCan();
    }
  }

  componentWillUnmount() {
    this.imageSubscription?.unsubscribe();
  }

  private handleImageIfCan() {
    this.imageSubscription?.unsubscribe();
    this.imageSubscription = etiquetasService
      .generateEtiquetaImage(this.props.barcode)
      .subscribe((image) => {
        this.setState({ image });
      });
  }

  render() {
    return (
      <div className="etiquetaAmostra">
        {this.state.image ? <img alt="" src={this.state.image.src} /> : null}
      </div>
    );
  }
}
