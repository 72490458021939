import { Field, useForm } from "react-final-form";
import { DropdownListField } from "../../../../../components/field/DropdownListField";
import {
  campoObrigatorioComMsgGenerica,
  totalizarProporcaoMinerais,
  validarSe,
} from "../../../../../util/validadores";
import { NumberInputField } from "../../../../../components/field/NumberInputField";
import { SubForm } from "../../../../../components/SubForm";
import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { RadioButtonWithFieldSetField } from "../../../../../components/field/RadioButtonSetField";
import { aoEnviarSubForm } from "../../../../../util/mutadores";
import { getPropsPermissao, resolverExibidos } from "../../../../../components/SubForm/SubForm";
import { FORM_ERROR } from "final-form";

const deveComporNomeRocha = [
  { id: 'SIM', nome: 'Sim' },
  { id: 'NAO', nome: 'Não' },
]
const camposMineraisNomenclatura = [
  { name: "idMineral", type: "number", map: "idMineral" },
  { name: "nomeMineral", type: "string", map: "nomeMineral" },
  { name: "proporcao", type: "number", map: "proporcao" },
  // {nam: "compoeNomeRocha", type: "string", map:"compoeNomeRocha"},
]


const MineraisRocha = ({ formProps, modoDefinitivoSelecionado, prefixoNome, aoAbrirSubForm, aoFecharSubForm, permitirEdicao, minerais, mineraisPorId }) => {
  const form = useForm();
 

  const tableRef = useRef();

  // These refs are for remembering the previous values of each array that's a
  // dependency of the columns array. We only recalculate the columns array when
  // there's an actual change in the arrays.
  const mineraisPorIdRef = useRef(mineraisPorId);
  const [colunasMinerais, setColunasMinerais] = useState(gerarColunasMineraisRocha(mineraisPorId));
  const [clickEditar, setClickEditar] = useState(false);
  const [clickVisualizar, setClickVisualizar] = useState(false);
  const [mineralEdicao, setMineralEdicao] = useState(null);

  useEffect(() => {
    // Only update the columns when at least one of the dependencies has really changed.
    // Otherwise, avoid changing the columns because that causes a table rerender.
    // (The *correct* thing to do would be including the names in the objects themselves (add extra fields)
    // and always recalculate those names when dependencies change, completely removing the need for
    // a cellsrenderer.)
    // Compare the dependencies by value.
    if (JSON.stringify(mineraisPorIdRef.current) !== JSON.stringify(mineraisPorId)) {
      setColunasMinerais(gerarColunasMineraisRocha(mineraisPorId));
      mineraisPorIdRef.current = mineraisPorId;
    }
  }, [mineraisPorId])


  const onSubmit = aoEnviarSubForm(form);

  function ordenacaoDecrescente(valor){
    return valor.sort((a, b) => b.proporcao - a.proporcao);
  }

  function validarMineralRocha(valores) {
    const mineraisNomenclaturaExistentes = resolverExibidos(form.getState().values?.mineraisNomenclatura || {}) || [];

    const mineraisNomenclaturaFiltrados = mineraisNomenclaturaExistentes.filter((mineralExistente) => (
      (clickEditar && mineralExistente?.id !== valores?.id) || mineralExistente?.id !== valores?.id
    ));

    const proporcaoTotalMineraisFiltrados = totalizarProporcaoMinerais(mineraisNomenclaturaFiltrados);

    const proporcaoTotalMinerais = proporcaoTotalMineraisFiltrados + (valores?.proporcao || 0);

    if (proporcaoTotalMinerais > 100) {
      return {
        [FORM_ERROR]: 'Minerais selecionados devem compor no máximo até 100% da rocha.'
      }
    }
  }

  return (
    <Field name={`${prefixoNome}mineraisNomenclatura`} subscription={{ value: true }}>
      {({ input: { value: mineraisNomenclatura = {} } }) => (
        <SubForm
          nome={`${prefixoNome}mineraisNomenclatura`}
          tableRef={tableRef}
          onSubmit={onSubmit}
          elementos={mineraisNomenclatura} 
          campos={camposMineraisNomenclatura}
          colunas={colunasMinerais}
          formSubscription={{}}
          onOpen={aoAbrirSubForm}
          onClose={aoFecharSubForm}
          {...getPropsPermissao(permitirEdicao)}
          ordenacaoPersonalizada={ordenacaoDecrescente}
          alternarBotoesPai={true}
          validarVoltar={true}
          exibirTabelaSemRegistro={true}
          validar={(valores) => validarMineralRocha(valores)}
          onBtnViewClick={() => {
            setClickVisualizar(true);
          }}
          onBtnEditClick={(event)=> {
            setClickEditar(true);

            const mineralEncontrado = (resolverExibidos(form.getState().values?.mineraisNomenclatura) || [])
              .find((mineral) => mineral.id === event?.value);

            if (mineralEncontrado?.idMineral !== undefined && mineralEncontrado?.idMineral != null) {
              setMineralEdicao(mineralEncontrado);
            }
          }}
          aoConfirmarVoltar={(_event) => {
            setClickEditar(false);
            setClickVisualizar(false);

            if (mineralEdicao !== null) {
              setMineralEdicao(null);
            }
          }}
          renderForm={({ prefixoNome }) => {
            let mineraisTela = formProps?.form.getState()?.values?.mineraisNomenclatura
            ? [...resolverExibidos(formProps?.form.getState()?.values?.mineraisNomenclatura)]
            : [];

            if (mineralEdicao) {
              mineraisTela = mineraisTela.filter((item) => item?.idMineral != mineralEdicao?.idMineral)
            }
            if (clickVisualizar) {
              mineraisTela = [];
            }
              const mineraisFiltrados = minerais.filter(mineral => 
                !mineraisTela.some(t => t.idMineral === mineral.id)
              );

            return (
              <>
                <Row className="mt-3">
                  <Col md={6}>
                    <Field
                      component={RadioButtonWithFieldSetField}
                      name={`${prefixoNome}compoeNomeRocha`}
                      label={`Compõe o nome da rocha?`}
                      dica={`Escolha se o mineral a ser cadastrado fará parte do nome da Rocha`}
                      elementos={deveComporNomeRocha}
                      initialValue={deveComporNomeRocha[1].id}
                      horizontal
                      disabled={!permitirEdicao}
                      valorDefault={deveComporNomeRocha[1]}
                    />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md={6}>
                    <Field
                      component={DropdownListField}
                      name={`${prefixoNome}idMineral`}
                      label="Mineral"
                      dica="Selecione o(s) mineral(is) macroscópico(s) identificado(s) na sua rocha"
                      elementos={mineraisFiltrados}
                      required={modoDefinitivoSelecionado}
                      disabled={!permitirEdicao}
                      validate={validarSe(modoDefinitivoSelecionado, campoObrigatorioComMsgGenerica("Mineral"))}
                    />
                  </Col>
                  <Col md={6} className='label-input-text'>
                    <Field
                      component={NumberInputField}
                      name={`${prefixoNome}proporcao`}
                      label="Proporção (%)"
                      dica="Porcentagem do mineral macroscópico identificado na sua rocha"
                      casasInteiros={3}
                      casasDecimais={2}
                      maxInteiros={100}
                      valorMaximo={100}
                      disabled={!permitirEdicao}
                      permitirNegativos={false}
                      usarOnchange={true}
                    />
                  </Col>
                </Row>
              </>
            )
          }}
        />
      )}
    </Field>
  );
}

function gerarColunasMineraisRocha(mineraisPorId) {
  return [
    {
      text: "Mineral", datafield: "idMineral", width: "79%",

      cellsrenderer: function (row, columnfield, value, defaulthtml, columnproperties, rowdata) {
        const idMineral = rowdata[columnfield]
        
        const nome =  typeof idMineral === 'number'&& mineraisPorId && mineraisPorId[idMineral]
          ? mineraisPorId[idMineral].nome
          : ''
        return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${nome}</div>`;
      }
    },
    { text: "Proporção", datafield: "proporcao", width: "15%", sortable: true, visible: true, hidden: false },
    // {text: "Compõe nome da rocha", datafield: "compoeNomeRocha"},
  ];
}

export default MineraisRocha
