import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import { connect } from "react-redux";
import { COLUNA_EXIBIR } from "../../util/constantes";
import PageScaffold from "../../components/scaffold/PageScaffold";
import { createControlColumn, Table } from "../../components/Jqx/Table";
import { Checkbox } from "../../components/Jqx/Checkbox";
import converterVetorParaObjetoIndexado from "../../util/converterVetorParaObjetoIndexado";
import Service, { BASEGEO, PETRO } from "../../service/Service";
import AppMapComponent from "../../components/mapa/map_component/AppMapComponent";
import { geoJSONParaArcGIS } from "../../util/mapa";
import { adicionarCabecalhoAuthAoJqXHR } from "../../util/jqxUtils";
import petrografia_tabela from '../../assets/img/petrografia_tabela.png';
import icone_geocron from '../../assets/img/icone_geocron.png';
import Card from "../../components/card"
import { selecionarVisita } from "../../reducers/visitaReducer";
import BlockUi from "react-block-ui";
import InfoPanelGPS from "../../components/infoPanelGPS";
import LegendaMapaAnalitico from "../estacoes/components/mapa/LegendaMapaAnalitico";
import { FocusableButton } from "../../components/Jqx/Button";
import { configMapaAmostra } from "../../config/mapa";

const dataFieldsAnaliticos = [
  { name: `id`, type: "string", map: "id" },
  { name: COLUNA_EXIBIR, type: "string", map: "id" },
  { name: "projeto", type: "string", map: "projeto" },
  { name: "area", type: "string", map: "area" },
  { name: "subArea", type: "string", map: "subArea" },
  { name: "podeExcluir", type: "boolean", map: "podeExcluir" },
  { name: "nomeAmostra", type: "string", map: "nomeAmostra" },
  { name: "nomeEstacao", type: "string", map: "nomeEstacao" },
  { name: "dataCadastro", type: "data", map: "dataCadastro" },
  { name: "qtdPetro", type: "number", map: "qtdPetro" },
  { name: "qtdGeocron", type: "number", map: "qtdGeocron" },
  { name: "dadosAnaliticos", type: "number", map: "dadosAnaliticos" },
  { name: "geoCron", type: "number", map: "geoCron" },
  { name: "idProjeto", type: "number", map: "idProjeto" },
];
const mapDispatchToProps = (dispatch) => {
  return {
    selecionarVisita(visita) {
      dispatch(selecionarVisita(visita))
    },
  };
};
class DadosAnaliticos extends Component {


  constructor(props) {
    super(props);

    this.servicoProjetos = Service("/projetos");
    this.servicoAreas = Service("/areas");
    this.servicoEstacoes = Service("/estacoes");

    /** @type {React.RefObject<JqxGrid>} */
    this.estacoesGrid1 = React.createRef();
    /** @type {React.RefObject<JqxGrid>} */
    this.estacoesGrid2 = React.createRef();
    
    const tabelaEstacoesUrl = `${process.env.REACT_APP_API_URL}/amostras`;
    this.tabelaEstacoesHistoricasUrl = `${process.env.REACT_APP_API_URL}/amostras-historicas`;

    const that = this;
    this.adaptadorTabelasBase = {
      id: "id",
      datatype: "json",
      url: tabelaEstacoesUrl,
      datafields: dataFieldsAnaliticos,
      beforeSend: adicionarCabecalhoAuthAoJqXHR,
      formatData: function (data) {
        return {
          ...data,
          idArea: that.state.idAreaSelecionada,
          idSubArea: that.state.idSubAreaSelecionada,
        };
      },
      sortcolumn: 'dataCadastro',
      sortdirection: 'desc',
    };

    this.opcoesSessao = JSON.parse(sessionStorage.getItem("localidade"));

    this.state = {
      carregamentoGlobal: false,
      carregamentoMapa: true,
      projetos: null,
      idProjetoSelecionado: null,
      areas: null,
      idAreaSelecionada: this.opcoesSessao?.idArea,
      subAreas: null,
      idSubAreaSelecionada: this.opcoesSessao?.idSubArea,
      nomeProjeto: null,
      nomeArea: null,
      nomeSubArea: null,
      estacoes: null,
      idEstacaoSelecionada: null,
      mostrarEstacoesHistoricas: false,
      mostrarTabelaEstacoes: false,
      mapaCarregado: false,
      dadosCarregados: true,
      amostraSelecionada: null,
      idAmostraSelecionada: null,
      isAmostraHistorica: null,
      amostra: null,
      mapaRef: null,
      idAmostras: [],
      idAmostrasHistoricas: [],
      amostrasHistoricas: [],
      amostrasFiltradasPorProjeto: [],
      idAmostrasFiltradasPorProjeto: [],
      idAmostrasHistoricasMapa: [],
      idProjetoHistorico: null
    };

    // Estes objetos serão passados para a tabela, para usar um json como source
    // ao invés de dados passados na forma de um array.
    this.extraSourceParameters1 = this.adaptadorTabelasBase;
    this.extraSourceParameters2 = {
      ...this.adaptadorTabelasBase,
      url: this.tabelaEstacoesHistoricasUrl,
    };

    this.idLinhaSelecionada = null;

    this.setState({
      carregamentoGlobal: true
    });

    this.columnsHistoricas = [
      createControlColumn({
        iconClasses: ["fas", "fa-edit", "text-success"],
        dataField: COLUNA_EXIBIR,
        ref: this.estacoesGrid2,
        onClick: (row, column, value, rowData) => this.selecionarAmostraHistorica(column),
      }),
      { text: "Nome da amostra", datafield: "nomeAmostra", width: "17%" },
      { text: "Nome da estação", datafield: "nomeEstacao", width: "12%" },
      {
        text: "Projeto/Área/Subárea", datafield: "projeto", width: "48%",
        cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
          const tela = rowdata?.projeto + '/' + rowdata?.area + '/' + rowdata?.subArea;
          return `<div style="margin-top: 7px; margin-left: 2px" title="${tela}">${tela}</div>`;
        }
      },
      {
        text: "Dados analíticos cadastrados",
        datafield: "dadosAnaliticos",
        width: "24%",
        filterable: false,
        cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
          let tela = "";
          if (rowdata.qtdPetro > 0) {
            tela = tela + `<img style="height: 15px;width: 15px; vertical-align: middle;" title="Cadastro de Petrografia" src="${petrografia_tabela}" /><span style="color:red; margin-right:10px;">${rowdata.qtdPetro}</span>`

          }
          if(rowdata.qtdGeocron > 0)
            tela = tela + `<img style="height: 15px;width: 15px; vertical-align: middle;" title="Cadastro de Geocronologia " src="${icone_geocron}" /><span style="color:red; margin-right:10px;">${rowdata.qtdGeocron}</span>`

          return `<div class="jqx-grid-cell-left-align" style="margin-top: 8px;">${tela}</div>`;

        }
      },
     

    ]

    this.columns = [
      createControlColumn({
        iconClasses: ["fas", "fa-edit", "text-success"],
        dataField: COLUNA_EXIBIR,
        ref: this.estacoesGrid1,
        onClick: (row, column, value, rowData) => this.selecionarAmostra(column),
      }),
      { text: "Nome da amostra", datafield: "nomeAmostra", width: "17%" },
      { text: "Nome da estação", datafield: "nomeEstacao", width: "44%" },
      {
        text: "Data do cadastro da amostra", datafield: "dataCadastro", cellsformat: 'dd/MM/yyyy', width: "16%",
        cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
          let data = '';

          if (value) {
            const isoString = new Date(value).toISOString();
            data = `${isoString.slice(8, 10)}/${isoString.slice(5, 7)}/${isoString.slice(0, 4)}`;
          }
          return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${data}</div>`;
        }
      },
      {
        text: "Dados analíticos cadastrados",
        datafield: "dadosAnaliticos",
        width: "24%",
        filterable: false,
        cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
          let tela = "";
          if (rowdata.qtdPetro > 0) {
            tela = tela + `<img style="height: 15px;width: 15px;" title="Cadastro de Petrografia" src="${petrografia_tabela}" /><span style="color:red; margin-right:10px;">${rowdata.qtdPetro}</span>`
          }
           if(rowdata.qtdGeocron > 0)
            tela = tela + `<img style="height: 15px;width: 15px; vertical-align: middle;" title="Cadastro de Geocronologia" src="${icone_geocron}" /><span style="color:red; margin-right:10px;">${rowdata.qtdGeocron}</span>`

          return `<div class="jqx-grid-cell-left-align" style="margin-top: 8px;">${tela}</div>`;

        }
      }
    ];

    this.geometryFilter = null;

    this.filtrarMapa = this.filtrarMapa.bind(this);
    this.onMapaCarregado = this.onMapaCarregado.bind(this);
    this.onFiltrar = this.onFiltrar.bind(this);
    this.onSelecaoEstacaoMudar = this.onSelecaoEstacaoMudar.bind(this);
    this.selecionarLinhaTabela = this.selecionarLinhaTabela.bind(this);
  }

  async componentDidMount() {
    localStorage.removeItem('FORM_DEFINICAO_ESTACAO');
    localStorage.removeItem('LISTA_PONTOS');
    localStorage.setItem('LAST_PAGE', 'estacoes');
    this.carregarProjetos();
    this.carregarAamostrasMapa();
  }

  componentWillUnmount() { }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // Atualiza this.geometryFilter e, se o mapa estiver carregado, envia o filtro
    this.filtrarMapa(prevState);
  }

  // **************************************************************************

  // Recuperar geometria da área ou subárea e atualizar mapa
  filtrarMapa(prevState) {
    // Ignora alterações de estado não importantes para a filtragem
    if (
      prevState.idProjetoSelecionado === this.state.idProjetoSelecionado &&
      prevState.idAreaSelecionada === this.state.idAreaSelecionada &&
      prevState.idSubAreaSelecionada === this.state.idSubAreaSelecionada &&
      prevState.mostrarEstacoesHistoricas ===
      this.state.mostrarEstacoesHistoricas
      // prevState.mapaCarregado === this.state.mapaCarregado
    ) {
      return;
    }

    if (
      this.state.idAreaSelecionada && this.state.areasPorId && 
      this.state.areasPorId[this.state.idAreaSelecionada]
    ) {
      const area = this.state.areasPorId[this.state.idAreaSelecionada];
      if (area) {
        const geometryFilterAtual = this.geometryFilter;
        if (this.state.idSubAreaSelecionada) {
          const subarea =
            this.state.subAreasPorId[this.state.idSubAreaSelecionada];
          if (subarea && subarea.geoJSON) {
            this.geometryFilter = geoJSONParaArcGIS(subarea.geoJSON);
          } else {
            this.geometryFilter = geoJSONParaArcGIS(area.geoJSON);
          }
        } else {
          this.geometryFilter = geoJSONParaArcGIS(area.geoJSON);
        }

        // Não enviar mensagem caso os parâmetros sejam os mesmos enviados anteriormente
        if (
          geometryFilterAtual === this.geometryFilter &&
          prevState.idProjetoSelecionado === this.state.idProjetoSelecionado &&
          prevState.mostrarEstacoesHistoricas ===
          this.state.mostrarEstacoesHistoricas
        ) {
          return;
        }

        if (this.state.mapaCarregado) {
          this.mapa.limparSelecaoMapa();
          this.mapa.filtrarAmostras(
            this.state.idAmostras,
            this.state.idAmostrasHistoricasMapa,
            this.geometryFilter,
            true,
            true
          );
        }
      }
    } else {
      // Projeto não possui áreas ou usuário não possui acesso a nenhuma área no projeto
      if (this.state.mapaCarregado) {
        this.mapa.limparPontos(true);
        this.mapa.limparSelecaoMapa();
        this.mapa.filtrarAmostras(
          this.state.idAmostras,
          this.state.idAmostrasHistoricasMapa,
          null,
          true,
          true
        );
      }
    }
  }

  onMapaCarregado() {
    this.carregamentoMapa = false;
    this.setState({ mapaCarregado: true });

    // Geralmente o projeto/area/subarea carregam e são selecionados automaticamente antes do mapa,
    // então quando o mapa carregar, utilizamos os valores atuais para filtragem
    if (this.state.dadosCarregados && this.state.idAreaSelecionada) {
      this.mapa.limparPontos(true);
      this.mapa.filtrarAmostras(
        this.state.idAmostras,
        this.state.idAmostrasHistoricasMapa,
        this.geometryFilter,
        true,
        true
      );
    }
  }

  onFiltrar() {
    // Se isso não é chamado após filtrar, o filtro anterior continua no mapa
    this.mapa.limparPontos();
  }

  // **************************************************************************

  isProjetosCarregados = () => {
    return null != this.state.projetos;
  };

  mostrarAreas = () => {
    return null != this.state.areas && this.state.areas.length > 0;
  };

  mostrarSubAreas = () => {
    return null != this.state.subAreas && this.state.subAreas.length > 0;
  };

  mostrarTabelasDeEstacao = () => {
    // console.debug('Mostrar?', this.state.mostrarTabelaEstacoes);
    return this.state.mostrarTabelaEstacoes;
  };

  // **************************************************************************

  carregarProjetos = async (_) => {
    const projetosCarregados = await this.servicoProjetos.listar();
    this.setState(
      {
        projetos: projetosCarregados.data.dados,
        projetosPorId: converterVetorParaObjetoIndexado(
          projetosCarregados.data.dados
        ),
      },
      () => {
        if (this.opcoesSessao) {
          this.selecionarProjeto({
            idProjetoSelecionado: this.opcoesSessao.idProjeto,
          });
        }
      }
    );
  };

  carregarAamostrasMapa = async (_) => {
    try {
      const parametros = {
        idArea: this.state.idAreaSelecionada,
        idSubArea: this.state.idSubAreaSelecionada
      };
      const responseAmostras = await Service('/amostras', BASEGEO).query(parametros)
      const { data: { dados: amostras = [] } } = responseAmostras;

      const responseAmostrasHistoricas = await Service('/amostras-historicas', BASEGEO).query(parametros)
      const { data: { dados: amostrasHistoricas = [] } } = responseAmostrasHistoricas;

      const listaIdsAmostras = amostras.map(objeto => objeto.id);
      const listaIdsAmostrasHistoricas = amostrasHistoricas.map(objeto => objeto.id);
 
      const parametrosFiltro = {
        idProjeto: JSON.parse(sessionStorage.getItem("localidade"))?.idProjeto,
        idsAmostras: listaIdsAmostrasHistoricas.join(', ')
      };

      let responseAmostrasHistoricasFiltradas;
      try {
        responseAmostrasHistoricasFiltradas = await Service('/filtrar-amostras-historicas', PETRO).query(parametrosFiltro)
      } catch (error) {
        console.log("Erro ao consultar amostras historicas filtradas")
        responseAmostrasHistoricasFiltradas = [];
      }
   
      const idAmostrasFiltradasPorProjeto = responseAmostrasHistoricasFiltradas?.data || [];

      const amostrasFiltradasPorProjeto = amostrasHistoricas.filter(amostra => idAmostrasFiltradasPorProjeto.includes(amostra.id));
      
      this.setState({
        idAmostras: listaIdsAmostras,
        idAmostrasHistoricas: listaIdsAmostrasHistoricas,
        amostrasHistoricas: amostrasHistoricas,
        amostrasFiltradasPorProjeto: amostrasFiltradasPorProjeto,
        idAmostrasFiltradasPorProjeto: idAmostrasFiltradasPorProjeto,
        idAmostrasHistoricasMapa: idAmostrasFiltradasPorProjeto
      });

      this.mapa.limparSelecaoMapa();
      this.mapa.filtrarAmostras(
        this.state.idAmostras,
        this.state.idAmostrasHistoricasMapa,
        this.geometryFilter,
        true,
        true
      );
    } catch (error) {
      console.error('Erro ao carregar os dados:', error);
    }
  }

  selecionarProjeto = async (evt) => {
    this.setState({
      carregamentoGlobal: true
    });
    this.setState({
      dadosCarregados: false,
    });
    const that = this;
    if (evt.idProjetoSelecionado !== this.state.idProjetoSelecionado) {
      const idProjetoSelecionado = evt.idProjetoSelecionado;


      if (null != idProjetoSelecionado && !isNaN(idProjetoSelecionado)) {
        this.setState({
          carregamentoGlobal: true
        });
        const respostaAreas = await this.servicoProjetos.customURL(
          "GET",
          `/${idProjetoSelecionado}/areas`
        );
        const areas = respostaAreas.data.dados || [];

        if (areas) {
          this.setState({
            carregamentoGlobal: true
          });
          this.setState(
            {
              areas,
              areasPorId: converterVetorParaObjetoIndexado(areas),

              subAreas: null,
              subAreasPorId: null,
              mostrarTabelaEstacoes: false,

              idProjetoSelecionado: evt.idProjetoSelecionado,
              idAreaSelecionada: null,
              idSubAreaSelecionada: null,
              dadosCarregados: false,
            },
            () => {
              if (areas.length >= 1) {
                const idAreaInicial =
                  this.opcoesSessao &&
                    this.state.idProjetoSelecionado ===
                    this.opcoesSessao.idProjeto
                    ? this.opcoesSessao.idArea
                    : areas[0].id; // Sessão ou primeira da lista
                that.selecionarArea({
                  idAreaSelecionada: idAreaInicial,
                });
              } else {
                this.setState({
                  dadosCarregados: true,
                });
              }
            }
          );
        }
      }
    }

    this.setState({
      carregamentoGlobal: false
    });
  };

  selecionarArea = async (evt) => {
    this.setState({
      dadosCarregados: false,
    });
    const that = this;
    if (evt.idAreaSelecionada !== this.state.idAreaSelecionada) {
      const idAreaSelecionada = evt.idAreaSelecionada;

      if (null != idAreaSelecionada && !isNaN(idAreaSelecionada)) {
        const respostaSubAreas = await this.servicoProjetos.customURL(
          "GET",
          `/${this.state.idProjetoSelecionado}/subAreas`
        );
        const subAreas = respostaSubAreas.data.dados;

        if (subAreas) {
          // esperarAteTabelaDisponivel(this.estacoesGrid1.current?.tableRef, () => {
          // })
          this.setState(
            {
              mostrarTabelaEstacoes: false,
              subAreas,
              subAreasPorId: converterVetorParaObjetoIndexado(subAreas),
              idAreaSelecionada: idAreaSelecionada,
              idSubAreaSelecionada: null,
            },
            () => {
              if (subAreas.length === 0) {
                that.setState({
                  mostrarTabelaEstacoes: true,
                  dadosCarregados: true,
                  mostrarEstacoesHistoricas:
                    this.opcoesSessao?.mostrarEstacoesHistoricas || false,
                });
              } else if (subAreas.length >= 1) {
                const idSubAreaInicial =
                  this.opcoesSessao &&
                    this.state.idProjetoSelecionado ===
                    this.opcoesSessao.idProjeto
                    ? this.opcoesSessao.idSubArea
                    : subAreas[0].id; // Sessão ou primeira da lista
                that.selecionarSubArea({
                  idSubAreaSelecionada: idSubAreaInicial,
                });
              }
            }
          );
        }
      }
    }
  };

  selecionarSubArea = async (evt) => {
    // const that = this;
    if (evt.idSubAreaSelecionada !== this.idSubAreaSelecionada) {
      const idSubAreaSelecionada = evt.idSubAreaSelecionada;
      this.setState(
        {
          mostrarTabelaEstacoes: false,
          idSubAreaSelecionada,
        },
        () => {
          this.setState({
            mostrarTabelaEstacoes: true,
            dadosCarregados: true,
            mostrarEstacoesHistoricas:
              this.opcoesSessao?.mostrarEstacoesHistoricas || false,
          });
        }
      );
    }
  };

  montarEstadoDeLocalidade() {

    const nomeSubArea =
      null != this.state.idSubAreaSelecionada
        ? this.state.subAreasPorId[this.state.idSubAreaSelecionada].nome
        : "";
    const geometriaProjeto = this.state.idSubAreaSelecionada
      ? this.state.subAreasPorId[this.state.idSubAreaSelecionada]
      : this.state.areasPorId[this.state.idAreaSelecionada];

    const localidade = {
      nomeProjeto:
        this.state.projetosPorId[this.state.idProjetoSelecionado].nome,
      nomeArea: this.state.areasPorId[this.state.idAreaSelecionada].nome,
      nomeSubArea,
      idProjeto: this.state.idProjetoSelecionado,
      idArea: this.state.idAreaSelecionada,
      idSubArea: this.state.idSubAreaSelecionada,
      hemisferio: geometriaProjeto.hemisferio, // Norte/Sul
      hemisferioHorizontal: geometriaProjeto.hemisferioHorizontal, // Leste/Oeste
      geometryFilter: this.geometryFilter,
      idAmostraSelecionada: this.state.idAmostraSelecionada,
      isAmostraHistorica: this.state.isAmostraHistorica,
      idProjetoHistorico: this.state.idProjetoHistorico
    };

    // Armazenar opções selecionadas em sessão para serem lidas ao voltar nessa página
    sessionStorage?.setItem(
      "localidade",
      JSON.stringify({
        idProjeto: localidade.idProjeto,
        idArea: localidade.idArea,
        idSubArea: localidade.idSubArea,
        nomeProjeto: localidade.nomeProjeto,
        nomeArea: localidade.nomeArea,
        nomeSubArea: localidade.nomeSubArea,
        mostrarEstacoesHistoricas: this.state.mostrarEstacoesHistoricas,
        amostra: this.state.amostra
      })
    );


    this.props.selecionarVisita({
      nomeProjeto: localidade.nomeProjeto,
      area: localidade.nomeArea,
      subArea: localidade.nomeSubArea,
    })


    return localidade;
  }

  redirecionar = (caminho) => {
    this.props.history.push({
      pathname: caminho,
      state: this.montarEstadoDeLocalidade(),
    });
  };



  selecionarAmostra(row, column, value, rowData) {
    this.setState({ isAmostraHistorica: false, idAmostraSelecionada: row?.rowData?.id, amostra: row?.rowData })
    this.redirecionar("/registrar-analiticos")
  }


  selecionarAmostraHistorica(row, column, value, rowData) {

    this.setState({ isAmostraHistorica: true, idAmostraSelecionada: row?.rowData.id, idProjetoHistorico: row?.rowData?.idProjeto })
    this.redirecionar("/registrar-analiticos")
  }

  grid1EstacoesExibido = () => {
    this.estacoesGrid1.current &&
      this.estacoesGrid1.current.gotopage(this.state.paginaSelecioada);
  };

  clicadoMostrarEstacoesHistoricas = (evt) => {
    this.setState((estadoAtual) => ({
      mostrarEstacoesHistoricas: !estadoAtual.mostrarEstacoesHistoricas,
      idAmostrasHistoricasMapa: !estadoAtual.mostrarEstacoesHistoricas ? this.state.idAmostrasHistoricas : this.state.idAmostrasFiltradasPorProjeto,
    }));
    console.debug(
      "Exibir amostras históricas:",
      this.state.mostrarEstacoesHistoricas
    );
  };

  isMostrarEstacoesHistoricas = () => {
    return (
      this.state.mostrarTabelaEstacoes && this.state.mostrarEstacoesHistoricas
    );
  };

  temProjetoSelecionado = () => {
    return (
      this.state.idProjetoSelecionado
    );
  };

  async selecionarProjetoViaDropdown(valor) {
    this.setState({
      carregamentoGlobal: true
    });
    if (valor === null) {
      this.setState({
        idProjetoSelecionado: null,
        idAreaSelecionada: null,
        idSubAreaSelecionada: null,
        areas: null,
        subAreas: null,
        mostrarTabelaEstacoes: null
      })
    } else {
      if (
        this.state.idProjetoSelecionado !== valor &&
        this.state.dadosCarregados
      ) {
        await this.selecionarProjeto({
          idProjetoSelecionado: valor,
        });
      }
    }

    this.setState({
      carregamentoGlobal: false
    });
  }

  limparSelecaoMapa() {
    const payload = {
      target: 'onClearSelection',
      state: {}
    };

    if (this.props.debugMensagens) console.info(`%cEnviando ao #${this.uid}: %c${payload.target}`, 'color: orange', 'color: #e772d4; font-weight: bold', payload.state)

    document.getElementById(this.uid).contentWindow.postMessage(payload, process.env.REACT_APP_URL_LOGIN_CLIENT);
  }

  selecionarAreaViaDropdown(valor) {
    if (this.state.idAreaSelecionada !== valor && this.state.dadosCarregados) {
      this.selecionarArea({
        idAreaSelecionada: valor,
      });
    }
  }

  selecionarSubAreaViaDropdown(valor) {
    if (
      this.state.idSubAreaSelecionada !== valor &&
      this.state.dadosCarregados
    ) {
      this.selecionarSubArea({
        idSubAreaSelecionada: valor,
      });
    }
  }

  selecionarLinhaTabela(evt) {
    const id = evt.args.row?.id;
    if (!id || id === this.idLinhaSelecionada) return 0;
    if (evt.type === "rowselect") {
      if (this.state.mapaCarregado) {
        this.idLinhaSelecionada = id;
        this.mapa.selecionarEstacao(id);
      }
    }
  }

  onSelecaoEstacaoMudar(id) {
    this.idLinhaSelecionada = id;

    if (this.estacoesGrid1?.current) {
      //Pega o tamanho da paginação
      const rowsPerPage = this.estacoesGrid1.current.getpaginginformation()?.pagesize
      // Obtém os dados de todas as linhas no grid
      const rowData = this.estacoesGrid1?.current?.getrows();
      // Encontra o índice da linha com base no ID
      const rowIndex = rowData?.findIndex(row => row.id === id);
      // Calcula o índice da página usando o índice da linha e o número de linhas por página
      const pageIndex = Math.floor(rowIndex / rowsPerPage);
      if (pageIndex) {
        this.estacoesGrid1.current.gotopage(pageIndex);
      }
      this.estacoesGrid1.current.clearselection();
      const row_grid1 = this.estacoesGrid1.current
        .getrows()
        .find((r) => r.id === id);
      if (row_grid1) {
        const boundIndex = row_grid1.boundindex;
        this.estacoesGrid1.current.selectrow(boundIndex);
      }
    }

    if (this.estacoesGrid2?.current) {
      //Pega o tamanho da paginação
      const rowsPerPageHistorica = this.estacoesGrid2.current.getpaginginformation()?.pagesize
      // Obtém os dados de todas as linhas no grid
      const rowDataHistorica = this.estacoesGrid2?.current?.getrows();
      // Encontra o índice da linha com base no ID
      const rowIndexHistorica = rowDataHistorica?.findIndex(row => row.id === id);
      // Calcula o índice da página usando o índice da linha e o número de linhas por página
      const pageIndexHistorica = Math.floor(rowIndexHistorica / rowsPerPageHistorica);
      if (pageIndexHistorica) {
        this.estacoesGrid2.current.gotopage(pageIndexHistorica);
      }

      this.estacoesGrid2.current.clearselection();
      const row_grid2 = this.estacoesGrid2.current
        .getrows()
        .find((r) => r.id === id);
      if (row_grid2) {
        const boundIndex = row_grid2.boundindex;
        this.estacoesGrid2.current.selectrow(boundIndex);
      }
    }
  }

  render() {
    return (
      <BlockUi blocking={this.state.carregamentoGlobal || this.carregamentoMapa}>
        <PageScaffold titulo="Cadastrar dados analíticos" disableInfo>
          <Container>
            <InfoPanelGPS />
            <Card>
              <Card.Body>
                {this.mostrarTabelasDeEstacao() && (
                  <>
                    <Table
                      style={{ marginTop: "2.5em" }}
                      ref={ref => {
                        if (ref) {
                          this.estacoesGrid1.current = ref.tableRef.current;
                        }
                      }}
                      extraSourceParameters={this.extraSourceParameters1}
                      datafields={dataFieldsAnaliticos}
                      columns={this.columns}
                      onRowSelectChanged={this.selecionarLinhaTabela}
                    />
                  </>
                )}

                {this.mostrarTabelasDeEstacao() && (
                  <>
                    <div className="clearfix" />
                    <hr />
                    <Checkbox
                      label="Mostrar todas as amostras históricas"
                      dica="Selecione para ver todas as amostras de outros projetos na área desse projeto"
                      onClick={this.clicadoMostrarEstacoesHistoricas}
                      checked={this.state.mostrarEstacoesHistoricas}
                    />
                  </>
                )}
                {
                  <Table
                    items={this.isMostrarEstacoesHistoricas() ? this.state.amostrasHistoricas : this.state.amostrasFiltradasPorProjeto}
                    ref={ref => {
                      if (ref) {
                        this.estacoesGrid2.current = ref.tableRef.current;
                      }
                    }}
                    columns={this.columnsHistoricas}
                    style={{ marginTop: "1rem" }}
                    onRowSelectChanged={this.selecionarLinhaTabela}
                  />
                }
                {/* {this.isMostrarEstacoesHistoricas() && (
                  <Table
                    items={this.state.amostrasHistoricas}
                    ref={ref => {
                      if (ref) {
                        this.estacoesGrid3.current = ref.tableRef.current;
                      }
                    }}
                    columns={this.columnsHistoricas}
                    style={{ marginTop: "1rem" }}
                    onRowSelectChanged={this.selecionarLinhaTabela}
                  />
                )} */}
                <FocusableButton
                  className="float-right m-1"
                  onClick={() => {
                    this.props.history.goBack();
                  }}>
                  Voltar
                </FocusableButton>
              </Card.Body>
            </Card>
            <hr />
            <Card>
              <Card.Body>
                <LegendaMapaAnalitico
                  exibirEstacao={true}
                  exibirEstacaoHistorica={true}
                  exibirEstacaoEditada={false}
                  exibirAtividade={false}
                  exibirPontoGPS={false}
                  exibirGeometriaProjeto={this.temProjetoSelecionado()}
                />


                <AppMapComponent
                  ref={(instance) => {
                    this.mapa = instance;
                  }}
                  config={configMapaAmostra}
                  onCarregarMapa={this.onMapaCarregado}
                  onFiltrar={this.onFiltrar}
                  debugMensagens={!!process.env.REACT_APP_MAP_DEBUG}
                  onSelecaoEstacaoMudar={this.onSelecaoEstacaoMudar}

                />
              </Card.Body>

            </Card>
          </Container>
        </PageScaffold>
      </BlockUi>
    );
  }
}

export default connect(null, mapDispatchToProps)(DadosAnaliticos)
