const PROFILE = process.env.REACT_APP_PROFILE?.toLowerCase() || "dev";

const configMapaEstacoes = require(`./${PROFILE}/estacoes.json`);
const configMapaPontosGPS = require(`./${PROFILE}/pontosGPS.json`);
const configMapaEstacao = require(`./${PROFILE}/estacao.json`);
const configMapaAfloramento = require(`./${PROFILE}/afloramento.json`);
const configMapaRecursoMineral = require(`./${PROFILE}/recursoMineral.json`);
const configMapaAmostras = require(`./${PROFILE}/amostras.json`);
const configMapaCoordenadas = require(`./${PROFILE}/coordenadas.json`);
const configMapaGeoquimica = require(`./${PROFILE}/geoquimica.json`);
const configMapaAmostra = require(`./${PROFILE}/amostra.json`);
const configMapaValidacao = require(`./${PROFILE}/validacao.json`);
const configMapaPublicacao = require(`./${PROFILE}/publicacao.json`);

export {
    configMapaEstacoes,
    configMapaPontosGPS, 
    configMapaEstacao,
    configMapaAfloramento,
    configMapaRecursoMineral,
    configMapaAmostras,
    configMapaCoordenadas,
    configMapaGeoquimica,
    configMapaAmostra,
    configMapaValidacao,
    configMapaPublicacao
};