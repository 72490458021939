import React, { useEffect, useRef, useState } from "react";
import Card from "../../../../components/card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { SubForm, LegacySubForm } from "../../../../components/SubForm";
import { Field, FormSpy } from "react-final-form";
import { DropdownListField } from "../../../../components/field/DropdownListField";
import { campoObrigatorioComMsgGenerica, validarSe } from "../../../../util/validadores";
import { TextAreaField } from "../../../../components/field/TextAreaField";
import { getPropsPermissao, resolverExibidos } from "../../../../components/SubForm/SubForm";

const campos = [
  { name: "idGrau", type: "string", map: "idGrau" },
  { name: "idRocha", type: "string", map: "idRocha" },
]

const Intemperismos = ({
  nome,
  onSubmit,
  // elementos,
  formAtividade,
  valoresIniciais,
  permitirEdicao,
  rochas,
  rochasPorId,
  modoDefinitivoSelecionado,
  grausIntemperismos,
  intemperismosComErro
}) => {
  // These refs are for remembering the previous values of each array that's a
  // dependency of the columns array. We only recalculate the columns array when
  // there's an actual change in the arrays.
  const rochasPorIdRef = useRef(rochasPorId);
  const grausIntemperismosRef = useRef(grausIntemperismos);
  const [erro, setErro] = useState(false);
  const [colunasIntemperismos, setColunasIntemperismos] = useState(gerarColunasIntemperismo(rochasPorId, grausIntemperismos))
  useEffect(() => {
    // Only update the columns when at least one of the dependencies has really changed.
    // Otherwise, avoid changing the columns because that causes a table rerender.
    // (The *correct* thing to do would be including the names in the objects themselves (add extra fields)
    // and always recalculate those names when dependencies change, completely removing the need for
    // a cellsrenderer.)
    // Compare the dependencies by value.
    if (JSON.stringify(rochasPorIdRef.current) !== JSON.stringify(rochasPorId)
        || JSON.stringify(grausIntemperismosRef.current) !== JSON.stringify(grausIntemperismos)
        ) {
      setColunasIntemperismos(gerarColunasIntemperismo(rochasPorId, grausIntemperismos))
      rochasPorIdRef.current = rochasPorId;
      grausIntemperismosRef.current = grausIntemperismos;
    }
  }, [rochasPorId, grausIntemperismos])

  useEffect(() => {
    setErro(intemperismosComErro);
  }, [intemperismosComErro])

  const valoresIniciaisRef = useRef(valoresIniciais);

  function aoConfirmarVoltar(form) {
    let keys = Object.keys(form.getState().values);

    keys.forEach(key => {
      form.mutators.setValue(key, undefined);
    });

    form.mutators.setValue('id', -1 * (Math.floor(Math.random() * 100) + 1));
    valoresIniciaisRef.current = null;
    valoresIniciais = null;
  }


  return (
    <div style={{ marginTop: '2.5rem' }}>
      <Card>
        <Card.Body>
        {erro && <span style={{ color: "red" }}>Existem intemperismos com campos obrigatórios não preenchidos</span>}
          <Card.Title>Intemperismos</Card.Title>
          <Field name={nome} subscription={{ value: true }}>
            {({ input: { value: intemperismos = {} } }) => (
               <LegacySubForm
                nome={nome}
                onSubmit={(params) => {
                  // Reset the initial values so that it doesn't get automatically
                  // recovered on the next render.
                  // It would be better to check if the submit was successful before
                  // resetting this ref, but it doesn't seem like that's possible.
                  valoresIniciaisRef.current = null;
                  onSubmit(params);
                }}
                elementos={intemperismos} // elementos
                campos={campos}
                colunas={colunasIntemperismos}
                aoConfirmarVoltar={aoConfirmarVoltar}
                valoresIniciais={valoresIniciaisRef.current || valoresIniciais}
                {...getPropsPermissao(permitirEdicao)}
                alternarBotoesPai={true}
                validarVoltar={true}
                renderForm={({ formProps: { form, values }, prefixoNome }) => {

                  // Update the ref of initial values. This code needs to run
                  // when any field changes.
                  if (valoresIniciaisRef.current !== values) {
                    valoresIniciaisRef.current = values;
                  }

                  let listaIntemperismos = resolverExibidos(intemperismos);
                  let idsRochasUtilizadas = [];
                  let idEditado = form.getState().values.id;

                  if(idEditado)
                    listaIntemperismos = listaIntemperismos.filter(i => i.id != idEditado);

                  listaIntemperismos.forEach(item => {
                    if(item && item.idRocha)
                      idsRochasUtilizadas.push(item.idRocha);
                  });

                  let rochasFiltradas = rochas.filter(r => !idsRochasUtilizadas.find(id => r.id === id));

                  return (
                    <>
                      <Row className="mt-3">
                        <Col md={6}>
                          <Field
                            component={DropdownListField}
                            name={`${prefixoNome}idRocha`}
                            label="Rocha"
                            dica="Selecione a rocha associada ao intemperismo, se observado"
                            elementos={rochasFiltradas}
                            required={modoDefinitivoSelecionado}
                            disabled={!permitirEdicao}
                            displayMember={'nomeExibicao'}
                            validate={validarSe(modoDefinitivoSelecionado, campoObrigatorioComMsgGenerica("Rochas"))}
                            multiple
                          />
                        </Col>
                        <Col md={6}>
                          <Field
                            name={`${prefixoNome}idGrau`}
                            subscription={{ value: true }}>
                                {({ input: { value: idGrau } }) => {

                                  let listaFiltrada = grausIntemperismos.filter(grau => grau.atual === 'S');

                                  if(idGrau && grausIntemperismos.length && !listaFiltrada.find((grau) => grau.id === idGrau)){
                                    const item = grausIntemperismos.find((grau) => grau.id === idGrau);
                
                                    if(item && item.atual === 'N'){
                                      listaFiltrada.push(item);
                                      listaFiltrada = listaFiltrada.sort((a, b) => a.nome.localeCompare(b.nome))
                                    }
                                }
                                  return <Field
                                  component={DropdownListField}
                                  name={`${prefixoNome}idGrau`}
                                  label="Grau de intemperismo"
                                  dica="Selecione a intensidade do intemperismo associada à rocha"
                                  elementos={listaFiltrada}
                                  required={modoDefinitivoSelecionado}
                                  disabled={!permitirEdicao}
                                  validate={validarSe(modoDefinitivoSelecionado, campoObrigatorioComMsgGenerica("Grau de intemperismo"))}
                                />
                                }}
                          </Field>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <Field
                            component={TextAreaField}
                            name={`${prefixoNome}descricao`}
                            label="Descrição do intemperismo no afloramento"
                            dica="Detalhe o perfil intempérico observado. Por exemplo, saprolito/horizonte mosqueado/crosta ferruginosa/horizonte do solo"
                            disabled={!permitirEdicao}
                            maxLength={250}
                          />
                        </Col>
                      </Row>
                    </>
                  )
                }}
              />
            )}
          </Field>
        </Card.Body>
      </Card>
    </div>
  );
}

// Função que gera as colunas da tabela de intemperismo, extraída para que
// possamos instanciar as colunas já inicializadas da primeira vez, ao invés de
// usar um estado [] padrão. Isso evita um rerender da tabela.
function gerarColunasIntemperismo(rochasPorId, grausIntemperismos) {
  return [
    {
      text: 'Grau de intemperismo', datafield: 'idGrau', width: '20%',
      cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
        const nome = value ? grausIntemperismos.find(g => g.id === parseInt(value)).nome : '';
        return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${nome}</div>`;
      }
    },
    {
      text: "Rocha", datafield: "idRocha", width: '74%',

      cellsrenderer: function (row, columnfield, value, defaulthtml, columnproperties, rowdata) {
        let nome = "";
        
        try{
          const idRocha = rowdata[columnfield];
          let rocha = rochasPorId[Number(idRocha)];
          nome = rocha ? rocha.nomeExibicao : ''
        }
        catch(err) {}

        return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${nome}</div>`;
      }
    },
  ];
}

export default Intemperismos;
