import { Col, Row } from "react-bootstrap";
import { Field, FormSpy, useForm } from "react-final-form";
import { DropdownListField } from "../../../components/field/DropdownListField";
import Hint from "../../../components/hint/Hint";
import { NumberInputField } from "../../../components/field/NumberInputField";

const RadioFieldWithDropdown = ({
  input,
  bbUnidadeMedida,
  ...props
}) => {
  const form = useForm();

  const validaMinimo = () => {
    if (form.getState().values?.numeroTamanhoMinimo > form.getState().values?.numeroTamanhoMaximo)
      return true;
  };

  const validaMaximo = () => {
    if (form.getState().values?.numeroTamanhoMaximo < form.getState().values?.numeroTamanhoMinimo)
      return true;
  };

  return (
    <>
      <FormSpy subscription={{ values: true }}>
        {({ values }) => {
          let obrigatorio = false;
          if (!!values?.idUnidadeComprimento || !!values?.numeroTamanhoMinimo || !!values?.numeroTamanhoMaximo) {
            obrigatorio = true;
          }
          return (
            <>
              <fieldset className="border p-2">
                <legend className="w-auto small" style={{ marginBottom: 8, fontSize: '16px' }}>
                  Tamanho
                  {obrigatorio && <span style={{ color: "red" }}>*</span>}
                  <Hint textoAjuda={'Defina a unidade de medida utilizada'} />
                </legend>

                <Col >
                  <Row>

                    {obrigatorio &&
                      (
                        <Col>
                          <Row>
                            <Col md={12} className="mb-2">
                              <Field
                                component={DropdownListField}
                                name={'idUnidadeComprimento'}
                                label={''}
                                dica={'Defina a unidade de medida utilizada'}
                                placeholder={'Selecione a unidade de medida...'}
                                elementos={bbUnidadeMedida}
                                required
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col md={6}>
                              <Field
                                component={NumberInputField}
                                name={'numeroTamanhoMinimo'}
                                label="Mínimo"
                                dica={'Dimensão mínima observada no conjunto da fase mineral cadastrada'}
                                casasInteiros={3}
                                casasDecimais={2}
                                maxInteiros={999}
                                required
                                validate={() => validaMinimo() ? "Valor mínimo não pode ser maior que o valor máximo." : undefined}
                              />
                            </Col>
                            <Col md={6}>
                              <Field
                                component={NumberInputField}
                                name={'numeroTamanhoMaximo'}
                                label="Máximo"
                                dica={'Dimensão máxima observada no conjunto da fase mineral cadastrada'}
                                casasInteiros={3}
                                casasDecimais={2}
                                maxInteiros={999}
                                required
                                validate={() => validaMaximo() ? "Valor máximo não pode ser menor que o valor mínimo." : undefined}
                              />
                            </Col>
                          </Row>
                        </Col>
                      )
                    }
                    {!obrigatorio && (
                      <Col>
                        <Row>
                          <Col md={12} className="mb-2">
                            <Field
                              component={DropdownListField}
                              name={'idUnidadeComprimento'}
                              label={''}
                              dica={'Defina a unidade de medida utilizada'}
                              placeholder={'Selecione a unidade de medida...'}
                              elementos={bbUnidadeMedida}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col md={6}>
                            <Field
                              component={NumberInputField}
                              name={'numeroTamanhoMinimo'}
                              label="Mínimo"
                              dica={'Dimensão mínima observada no conjunto da fase mineral cadastrada'}
                              casasInteiros={3}
                              casasDecimais={2}
                              maxInteiros={999}
                            />
                          </Col>
                          <Col md={6}>
                            <Field
                              component={NumberInputField}
                              name={'numeroTamanhoMaximo'}
                              label="Máximo"
                              dica={'Dimensão máxima observada no conjunto da fase mineral cadastrada'}
                              casasInteiros={3}
                              casasDecimais={2}
                              maxInteiros={999}
                            />
                          </Col>
                        </Row>
                      </Col>
                    )}




                  </Row>
                </Col>
              </fieldset>
            </>
          );
        }}
      </FormSpy>
    </>
  )
};

export default RadioFieldWithDropdown;
