import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Field, useForm } from "react-final-form";
import { aoEnviarSubForm } from "../../../util/mutadores";
import { Card } from "react-bootstrap";
import { SubForm } from "../../../components/SubForm";
import { DropdownListField } from "../../../components/field/DropdownListField";
import { NumberInputField } from "../../../components/field/NumberInputField";
import { campoObrigatorioComMsgGenerica } from "../../../util/validadores";
import { resolverExibidos } from "../../../components/SubForm/SubForm";
import { FORM_ERROR } from "final-form";



const Porosidade = ({
    permitirEdicao,
    bbTipoPorosidade,
    permitirInsercao,
    somaTotalPercentuaisPorosidade,
    disabled
}) => {
    const valorFormulario = useForm()
    const onSubmit = aoEnviarSubForm(valorFormulario);
    const colunasPorosidade = [
        {
            text: 'Tipo de Porosidade',
            datafield: 'idTipoPorosidade',
            width: '40%',
            cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
                const objetoFiltrado = bbTipoPorosidade.find(elemento => elemento.id === value);
                const valor = objetoFiltrado ? objetoFiltrado.nome : '';
                return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${valor}</div>`;
            },
        },
        {
            text: '% de poros',
            datafield: 'porcentagemPoros',
            width: '40%',
            cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {

                let valor = "";
                if (value === 0 || value === "0") {
                    valor = "Acessório";
                } else {
                    valor = value;
                }
                return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${valor}</div>`;
            }

        }
    ]
    const camposPorosidade = [
        { name: "idTipoPorosidade", type: "number", map: "idTipoPorosidade" },
        { name: "porcentagemPoros", type: "number", map: "porcentagemPoros" },
    ];

    const validarPorosidade = (valores) => {
        const porosidades = valorFormulario.getState().values?.porosidades;
        const itens = porosidades ? resolverExibidos(porosidades) : [];
        const idParaSubstituir = valores.id;
        const indiceParaSubstituir = itens.findIndex(item => item.id === idParaSubstituir);

        if (indiceParaSubstituir !== -1) {
            itens.splice(indiceParaSubstituir, 1, valores);
        } else {
            itens.push(valores);
        }

        let totalPorcentagem = 0;

        if (itens.length > 0) {
            totalPorcentagem = itens.reduce((total, objeto) => {
                return total + Number(objeto.porcentagemPoros);
            }, 0);
        }

        if (totalPorcentagem > 100) {
            return { [FORM_ERROR]: 'A soma da % na fração não deve ultrapassar 100%.' };
        }

        return null
    }

    return (
        <>
            <div style={{ marginTop: '2.5rem' }}>
                <Card className="mt-3">
                    <Card.Body>
                        <Card.Title>Porosidade</Card.Title>
                        {(somaTotalPercentuaisPorosidade != null && somaTotalPercentuaisPorosidade < 100) && (
                            <span className="erro-campo" style={{ color: 'blue', fontSize: '10px' }}>
                                A soma total da % de poros é de: {somaTotalPercentuaisPorosidade}%, mas deve ser 100%.
                            </span>
                        )}
                        <Field name={'porosidades'} subscription={{ value: true }}>
                            {({ input: { value: porosidades = [] } }) => (
                                <SubForm
                                    nome={'porosidades'}
                                    onSubmit={onSubmit}
                                    colunas={colunasPorosidade}
                                    elementos={porosidades}
                                    campos={camposPorosidade}
                                    modoSubFormComGrid
                                    validar={(valores) => validarPorosidade(valores)}
                                    podeExcluirEntidade={!disabled}
                                    permitirInsercao={permitirInsercao}
                                    exibirBotaoInsercao={!disabled}
                                    permitirEdicao={!disabled}
                                    permitirExclusao={!disabled}
                                    permitirVisualizacao={disabled}
                                    renderForm={({ formProps: { form } }) => {
                                        var valorAtual = form.getState().values?.idTipoPorosidade
                                        var porosidades = valorFormulario?.getState().values?.porosidades
                                        var porosidadesTela = []
                                        if (porosidades) {
                                            porosidadesTela = resolverExibidos(porosidades)

                                        }

                                        var bbTipoPorosidadeFiltrados = bbTipoPorosidade.filter(value => {
                                            return !porosidadesTela.some(item => item.idTipoPorosidade === value.id && valorAtual !== value.id);
                                        });
                                        return (
                                            <>
                                                <Row className="my-3">
                                                    <Col md={4}>
                                                        <Field
                                                            component={DropdownListField}
                                                            name={"idTipoPorosidade"}
                                                            label="Tipo de porosidade"
                                                            dica="Selecione o tipo de porosidade."
                                                            elementos={bbTipoPorosidadeFiltrados}
                                                            displayMember={'nome'}
                                                            required
                                                            validate={campoObrigatorioComMsgGenerica("Tipo de porosidade")}
                                                            disabled={disabled}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Field
                                                            name="porcentagemPoros"
                                                            component={NumberInputField}
                                                            label="% de poros"
                                                            dica="Estime concentração de poros em porcentagem."
                                                            permitirNegativos={false}
                                                            casasInteiros={3}
                                                            casasDecimais={0}
                                                            valorMaximo={100}
                                                            required
                                                            validate={campoObrigatorioComMsgGenerica("% de poros")}
                                                            disabled={disabled}
                                                        />
                                                    </Col>
                                                </Row>
                                            </>
                                        )
                                    }
                                    }
                                />
                            )}
                        </Field>
                    </Card.Body >
                </Card>
            </div>
        </>
    );
};

export default Porosidade;