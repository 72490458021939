import React, { Component } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { Field } from "react-final-form";
import { DateTimeInputField } from "../../../../../components/field/DateTimeInputField";
import { DropdownListField } from "../../../../../components/field/DropdownListField";
import TextAreaField from "../../../../../components/field/TextAreaField/TextAreaField";
import { SubForm } from "../../../../../components/SubForm";
import {
  MODO_CADASTRO_BASE_PROJETOS,
  MODO_CADASTRO_DETALHADO,
  MODO_CADASTRO_SIMPLIFICADO,
  ATIVIDADE_EXPLORACAO,
} from "../../../../../util/constantes";
import { campoObrigatorioComMsgGenerica, validacoesAtividadeExploracao, validarSe } from "../../../../../util/validadores";
import FonteReferencia from "../FonteReferencia/FonteReferencia";
import { setValue } from "../../../../../util/mutadores";
import { resolverMultiRequestRecmin } from "../../../../../util/ResolverRequestRecmin";
import { getPropsPermissao, resolverExibidos } from "../../../../../components/SubForm/SubForm";
class AtividadeExploracao extends Component {
  constructor(props) {
    super(props);

    this.state = {
      idTipoAtividade: [],
      idResultadoAtividade: [],
      modoCadastroFonteReferencia: [
        MODO_CADASTRO_SIMPLIFICADO,
        MODO_CADASTRO_DETALHADO,
        MODO_CADASTRO_BASE_PROJETOS
      ],
    };

    this.colunasAtividadeExploracao = [
      {
        text: "Tipo de atividade", datafield: "idTipoAtividade", width: "20%", cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
          let tiposAtividade = this.state.idTipoAtividade;
          let nome = "";
          tiposAtividade?.map(idTipoAtividade => {
            if (idTipoAtividade?.id?.toString() === rowdata?.idTipoAtividade?.toString()) {
              nome = idTipoAtividade.nome;
            }
          });
          return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${nome}</div>`;
        }
      },
      { text: "Data inicial", datafield: "dataInicial", width: "34%" },
      { text: "Data final", datafield: "dataFinal", width: "20%" },
      {
        text: "Resultado final", datafield: "idResultadoAtividade", width: "20%", cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
          let resultados = this.state.idResultadoAtividade;
          let nome = "";
          resultados?.map(resultado => {
            if (resultado?.id?.toString() === rowdata?.idResultadoAtividade?.toString()) {
              nome = resultado.nome;
            }
          });
          return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${nome}</div>`;
        }
      },
    ];

    this.datafieldsAtividadeExploracao = [
      { name: "idTipoAtividade", type: "string", map: "idTipoAtividade" },
      { name: "dataInicial", type: "string", map: "dataInicial" },
      { name: "dataFinal", type: "string", map: "dataFinal" },
      { name: "idResultadoAtividade", type: "string", map: "idResultadoAtividade" },
    ];

    this.colunasFonteReferenciaAtividadeExploracao = [
      {
        text: "Referência", datafield: "referencia",
        cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
          let text = '';

          if (rowdata.modoCadastro === "SIMPLIFICADO" && rowdata.descricao) {
            text = rowdata.descricao;
          };
          if (rowdata.modoCadastro === "DETALHADO" && rowdata.titulo) {
            text = rowdata.titulo;
          };
          if (rowdata.modoCadastro === "BASE_PROJETOS" && rowdata.projetoSGP) {
            text = rowdata.projetoSGP;
          };

          return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${text}</div>`;
        },
      },
    ];

    this.datafieldsFonteReferenciaAtividadeExploracao = [
      { name: "modoCadastro", type: "string", map: "modoCadastro" },
      { name: "autores", type: "string", map: "autores" },
      { name: "ano", type: "string", map: "ano" },
      { name: "volume", type: "string", map: "volume" },
      { name: "titulo", type: "string", map: "titulo" },
      { name: "paginaInicial", type: "string", map: "paginaInicial" },
      { name: "link", type: "string", map: "link" },
      { name: "descricao", type: "string", map: "descricao" },
      { name: "projetoSGP", type: "string", map: "projetoSGP" },
    ];

    /** @type {React.RefObject<import("../../../../../components/Jqx/Table").Table>} */
    this.tableRef = React.createRef();
  }

  async componentDidMount() {
    const responses = await resolverMultiRequestRecmin({
      idTipoAtividade: { uri: "/atividadeExploracao/tipo" },
      idResultadoAtividade: { uri: "/atividadeExploracao/resultado" },
    });

    // Se alguma das listas estava vazia antes, redesenhamos a tabela.
    const needsTableRedraw = (this.state.idTipoAtividade.length === 0 && responses.idTipoAtividade.length > 0)
      || (this.state.idResultadoAtividade.length === 0 && responses.idResultadoAtividade.length > 0);

    this.setState(responses);

    if (needsTableRedraw) {
      this.tableRef.current?.refreshItems();
    }
  }

  onSubmit({ alteracoesForm }) {
    this.props.referenciaForm.batch(() => {
      for (const [key, val] of Object.entries(alteracoesForm)) {
        this.props.referenciaForm.mutators.setValue(`${key}`, val);
      }
    });
    if (this.props.referenciaForm.getState().values.atividadesExploracao) {
      let arrayAtividadesExploracao = resolverExibidos(this.props.referenciaForm.getState().values.atividadesExploracao);
      let arrayFonteReferencia = [];
      arrayAtividadesExploracao?.map(atividadesExploracao => {
        if (atividadesExploracao.fontesReferencia) {
          let exibidos = resolverExibidos(atividadesExploracao.fontesReferencia);
          exibidos?.map(item => {
            if (item.modoCadastro === "SIMPLIFICADO") {
              item.referencia = item.descricao
            }
            if (item.modoCadastro === "DETALHADO") {
              const autores = item.autores.split(",");
              if (autores.length === 1) item.referencia = `${autores[0].split(" ").pop()}. (${item.ano}). ${item.titulo}.`;
              if (autores.length === 2) item.referencia = `${autores[0].split(" ").pop()} e ${autores[1].split(" ").pop()}. (${item.ano}). ${item.titulo}.`;
              if (autores.length >= 3) item.referencia = `${autores[0].split(" ").pop()} et al. (${item.ano}). ${item.titulo}.`
            }
            if (item.modoCadastro === "BASE_PROJETOS" && item.idProjeto !== "") item.referencia = item.idProjeto;
            if (item.modoCadastro === "BASE_PROJETOS" && item.idProjeto === "") item.referencia = item.link;
          }
          );
          arrayFonteReferencia.push(exibidos);
        }
        return 0;
      }
      );
      this.props.atualizarTabelaFonteReferenciaRecursoMineral(arrayFonteReferencia.flat(), "ATIVIDADE_EXPLORACAO");
    }
  }

  onSubmitSubForm({ alteracoesForm }, formProps) {
    formProps?.batch(() => {
      for (const [key, val] of Object.entries(alteracoesForm)) {
        formProps?.mutators?.setValue(`${key}`, val);
      }
    });
  }




  render() {
    return (
      <Field name={"atividadesExploracao"} subscription={{ value: true }}>
        {({ input: { value: atividadesExploracao = {} } }) =>
          <SubForm
            tableRef={this.tableRef}
            keepDirtyOnReinitialize
            tituloForm="Atividade de exploração"
            nome="atividadesExploracao"
            onSubmit={this.onSubmit.bind(this)}
            colunas={this.colunasAtividadeExploracao}
            campos={this.datafieldsAtividadeExploracao}
            mutators={{ setValue }}
            validar={(valores) => this.props.modoDefinitivo ? validacoesAtividadeExploracao(valores) : undefined}
            elementos={
              atividadesExploracao
            }
            {...getPropsPermissao(this.props.permitirEdicao)}
            formSubscription={{}}
            renderForm={({ formProps }) => {
              return (
                <>
                  <Row>
                    <Col md={12}>
                      <Card.Title>Atividade de exploração</Card.Title>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={3}>
                      <Field
                        disabled={!this.props.permitirEdicao}
                        name="idTipoAtividade"
                        component={DropdownListField}
                        label="Tipo de atividade"
                        dica="Selecione, a partir da biblioteca, o tipo de atividade exploratória executada no local visitado"
                        elementos={this.state.idTipoAtividade.sort((a,b) => (a.nome > b.nome) ? 1 : ((b.nome > a.nome) ? -1 : 0))}
                        required={this.props.modoDefinitivo}
                        initialValue=""
                        validate={validarSe(this.props.modoDefinitivo, campoObrigatorioComMsgGenerica(
                          "Tipo de atividade"
                        ))}
                      />
                    </Col>
                    <Col md={3}>
                      <Field name={`dataFinal`} subscription={{ value: true }}>
                        {({ input: { value } }) => <>
                          <Field
                            disabled={!this.props.permitirEdicao}
                            component={DateTimeInputField}
                            name="dataInicial"
                            label="Data inicial"
                            dica="Informe a data inicial da atividade de exploração cadastrada"
                            required={this.props.modoDefinitivo}
                            validate={validarSe(this.props.modoDefinitivo, campoObrigatorioComMsgGenerica("Data inicial"))}
                          />
                        </>
                        }
                      </Field>
                    </Col>
                    <Col md={3}>
                      <Field name={`dataInicial`} subscription={{ value: true }}>
                        {({ input: { value } }) => <>
                          <Field
                            disabled={!this.props.permitirEdicao}
                            component={DateTimeInputField}
                            name="dataFinal"
                            label="Data final"
                            dica="Informe a data final da atividade de exploração cadastrada"
                          />
                        </>
                        }
                      </Field>
                    </Col>
                    <Col md={3}>
                      <Field
                        disabled={!this.props.permitirEdicao}
                        name="idResultadoAtividade"
                        component={DropdownListField}
                        label="Resultado da atividade"
                        dica="Informe, a partir da biblioteca, o resultado obtido com a atividade de exploração desenvolvida no local visitado"
                        initialValue=""
                        elementos={this.state.idResultadoAtividade}
                      />
                    </Col>
                  </Row>
                  <Col md={12} className="p-0 mt-3">
                    <Field
                      disabled={!this.props.permitirEdicao}
                      name="descricaoResultado"
                      component={TextAreaField}
                      label="Informações Relevantes"
                      dica="Descreva claramente os resultados obtidos com a atividade exploratória no local visitado"
                      initialValue=""
                      maxLength={255}
                    />
                  </Col>
                  <Row className="mt-4">
                    <Col md={12}>
                      <FonteReferencia
                        permitirEdicao={this.props.permitirEdicao}
                        formPai={formProps}
                        mutators={{ setValue }}
                        origem={ATIVIDADE_EXPLORACAO}
                        modoDefinitivo={this.props.modoDefinitivo}
                      />
                    </Col>
                  </Row>
                </>
              );
            }}
          />
        }
      </Field>

    );
  }
}

export default AtividadeExploracao;
