import swal from "sweetalert";
import {
    CORRECAO_SOLICITADA,
    AGUARDANDO_REVISAO,
    AGUARDANDO_VALIDACAO,
    ATIVIDADE_MODIFICADA,
    VALIDADO
  } from "./constantes/StatusValidacaoPublicacao";

export const createValidationColumn = ({  ref,  method, hint}) => (
    row,
    column,
    value,
    htmlElement
  ) => {
    let rowData = typeof row === "number"
      ? ref.current && ref.current.getrowdata && ref.current.getrowdata(row)
      : row.bounddata

    if(!rowData){
      rowData = ref?.current?.tableRef?.current?.getrowdata(row)
    }

    if (!rowData && method === "initwidget") {
      return;
    }

    if (htmlElement.firstChild) {
      htmlElement.firstChild.remove();
    }

    const div = document.createElement("div");
    div.innerHTML = value;
    div.classList.add("jqx-grid-cell-left-align");
    div.style.color=getCorTextoDetalhe(rowData);
    const exibirDetalhesValidacao = validaExibicaoCorrecoes(rowData);
    if (exibirDetalhesValidacao) {
      const button = document.createElement("button");
      button.classList.add("btn");
      button.title = hint ? hint : '';
      const iconEl = document.createElement("i");
      button.appendChild(iconEl);
      iconEl.classList.add("fas", "fa-search", "text-primary", "ml-1");
      div.appendChild(button);
      button.addEventListener("click", async () => {
        await swal({
          title: `Comentários da revisão`,
          text: rowData?.comentarioRevisao || rowData?.comentarioValidacao || "[NENHUM COMENTÁRIO ADICIONADO]",
          icon: "info",
        });
      });
    }
    else{
      div.classList.add("status-validacao-detalhe")
    }
    htmlElement.appendChild(div);
};

const getCorTextoDetalhe=(rowData)=>{
  switch (rowData?.idStatusValidacao) {
    case AGUARDANDO_REVISAO.id: return  "#BF5700";
    case AGUARDANDO_VALIDACAO.id: return  "#BF5700";
    case CORRECAO_SOLICITADA.id: return  "#FF4B4B";
    case VALIDADO.id: return  "#8ABC7D";
    case ATIVIDADE_MODIFICADA.id: return  "#9B80FF";
    case null:
    default: return "#FFF";
  }
}

const validaExibicaoCorrecoes = (rowdata)=>{
  switch (rowdata?.idStatusValidacao) {
    case null:
    case AGUARDANDO_REVISAO.id: return  false;
    case AGUARDANDO_VALIDACAO.id: return  false;
    case CORRECAO_SOLICITADA.id: return  true;
    case VALIDADO.id: return false;
    case ATIVIDADE_MODIFICADA.id: return  false;
    default: return false;
  }
}

export const validacaoRowDisponivelParaSelecao = (row)=>{
  switch (row?.idStatusValidacao) {
    case AGUARDANDO_REVISAO.id: return  false;
    case AGUARDANDO_VALIDACAO.id: return  false;
    case CORRECAO_SOLICITADA.id: return  false;
    case VALIDADO.id: return false;
    case ATIVIDADE_MODIFICADA.id: return  true;
    case null: return true
    default: return true;
  }
}

export const validacaoRowDisponivelParaEdicao = (row)=>{
  switch (row?.idStatusValidacao) {
    case AGUARDANDO_REVISAO.id: return  false;
    case AGUARDANDO_VALIDACAO.id: return  false;
    case CORRECAO_SOLICITADA.id: return  true;
    case VALIDADO.id: return false;
    case ATIVIDADE_MODIFICADA.id: return  false;
    case null: return true
    default: return true;
  }
}

export const validaPermissaoEditarAtividadeValidacao = (row) => {
  return !!(row.permitirEdicao || row?.podeEditar)
}

export const validaPermissaoExcluirAtividadeValidacao = (row) => {
  return !!(row.permitirExclusao || row?.podeExcluir);
}