import { PureComponent } from "react";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { AmostraSubFormPart } from "../components/AmostraSubFormPart";
import { MatrizPredominanteField } from "../components/MatrizPredominanteField";
import { GrauIntemperismoField } from "../components/GrauIntemperismoField";
import { TexturaRochaField } from "../components/TexturaRochaField";
import { TipoAlteracaoField } from "../components/TipoAlteracaoField";
import {
  amostraDataFieldsCommon,
  AmostraSymbol,
  amostrasLabelsMap,
} from "../constants/amostras.constant";
import { ImagensAmostraSubForm } from "../components/ImagensAmostraSubForm";
import { RochaSampleGeoquimicaModelLike } from "../../../../../models/activity-geoquimica.model";
import { AmostraGridSubForm } from "../components/AmostraGridSubForm";

export interface RochaSectionFormProps {
  prefixoNome: string;
  items?: RochaSampleGeoquimicaModelLike[];
  onItemsChanged?: (items: RochaSampleGeoquimicaModelLike[]) => void;
}

export interface RochaSectionFormState {}

export class RochaSectionForm extends PureComponent<
  RochaSectionFormProps,
  RochaSectionFormState
> {
  datafieldsRocha = [
    ...amostraDataFieldsCommon,
    {
      name: "id_matriz_predominante",
      type: "number",
      map: "id_matriz_predominante",
    },
    {
      name: "id_grau_intemperismo",
      type: "number",
      map: "id_grau_intemperismo",
    },
    {
      name: "id_tipo_alteracao",
      type: "number",
      map: "ambienteCrosta",
    },
    {
      name: "id_textura_rocha",
      type: "number",
      map: "id_textura_rocha",
    },
  ];

  amostraSymbol = AmostraSymbol.Rocha;

  constructor(props) {
    super(props);
    this.state = {};
  }

  renderForm = ({ editando, prefixoNome }) => {
    return (
      <>
        <AmostraSubFormPart
          prefixoNome={prefixoNome}
          amostraSymbol={this.amostraSymbol}
          profundidadeMinRequired={false}
          profundidadeMaxRequired={false}
        />
        <Row className="my-3">
          <Col>
            <MatrizPredominanteField
              prefixoNome={prefixoNome}
              required={true}
            />
          </Col>
          <Col>
            <GrauIntemperismoField prefixoNome={prefixoNome} required={true} />
          </Col>
          <Col>
            <TipoAlteracaoField prefixoNome={prefixoNome} required={true} />
          </Col>
        </Row>
        <Row className="my-3">
          <Col>
            <TexturaRochaField prefixoNome={prefixoNome} required={true} />
          </Col>
        </Row>
        <Row className="my-3">
          <Col>
            <ImagensAmostraSubForm prefixoNome={prefixoNome} />
          </Col>
        </Row>
      </>
    );
  };

  render() {
    return (
      <>
        <Card className="my-3">
          <Card.Body>
            <Card.Subtitle>
              {amostrasLabelsMap.get(this.amostraSymbol)}
            </Card.Subtitle>
            <AmostraGridSubForm
              name="amostraRocha"
              datafields={this.datafieldsRocha}
              items={this.props.items}
              onItemsChanged={this.props.onItemsChanged}
              renderForm={this.renderForm}
            />
          </Card.Body>
        </Card>
      </>
    );
  }
}
