import Service from "../../../../../service/Service";

export const resolverRequestAmostras = async (uri, { parametros } = {}) => {
  let request = await Service(uri, "amostras").query(parametros);
  let dados = request.data.dados;
  return dados;
}

export const resolverRequestBaseGeo = async (uri, { parametros } = {}) => {
  let request = await Service(uri, "basegeo").query(parametros);
  let dados = request.data.dados;
  return dados;
}

export const salvarAmostra = async (uri, objeto) => {
  let response = await Service(uri, "amostras").post(objeto);
  return response;
}
