import React from "react";
import { Col, Row } from "react-bootstrap";
import { Field } from "react-final-form";
import { DropdownListField } from "../../../../../components/field/DropdownListField";
import { resolverRequestAmostras } from "../AmostrasUtils/AmostraRequestUtils";
import CamposComuns from "./CamposComuns";

class Minerio extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      matrizPredominante: [],
      grauIntemperismo: [],
      tipoAlteracao: [],
      tipoMineral: [],
      modoDeposito: [],
    }
  };

  async componentDidMount() {
    this.setState({
      matrizPredominante: await resolverRequestAmostras("/biblioteca/matrizpredominante"),
      grauIntemperismo: await resolverRequestAmostras("/biblioteca/grauintemperismo"),
      tipoAlteracao: await resolverRequestAmostras("/biblioteca/tipoalteracao"),
      tipoMineral: await resolverRequestAmostras("/biblioteca/tipomineral"),
      modoDeposito: await resolverRequestAmostras("/biblioteca/mododeposito"),
    })
  }


  render() {
    return <>
      <CamposComuns
        duplicata={this.props.duplicata}
        permitirEdicao={this.props.permitirEdicao}
        labelCampo="Peso da amostra (g)"
        dicaCampo="Informe o peso da amostra, em gramas"
        profundidadeObrigatoria={false}
        informacoesRelevantesObrigatorias={true}
        prefixo={'minerio'}
        visualizar={this.props.visualizar}
      />
      <Row className="mt-3">
        <Col md={6}>
          <Field
            name="minerio.idGrauIntemperismo"
            subscription={{ value: true }}>
            {({ input: { value: idGrau } }) => {
              let listaFiltrada = this.state.grauIntemperismo.filter(grau => grau.atual === 'S');

              if (idGrau && this.state.grauIntemperismo.length && !listaFiltrada.find((grau) => grau.id === idGrau)) {
                const item = this.state.grauIntemperismo.find((grau) => grau.id === idGrau);

                if (item && item.atual === 'N') {
                  listaFiltrada.push(item);
                  listaFiltrada = listaFiltrada.sort((a, b) => a.nome.localeCompare(b.nome))
                }
              }
              return <Field
                component={DropdownListField}
                name="minerio.idGrauIntemperismo"
                label="Grau de intemperismo"
                dica="Selecione o grau de intemperismo da amostra coletada"
                elementos={listaFiltrada}
                disabled={!this.props.permitirEdicao}
              />
            }}
          </Field>
        </Col>
        <Col md={6}>
          <Field
            name={'minerio.idTipoAlteracao'}
            subscription={{ value: true }}>
            {({ input: { value: tipoAlteracao } }) => {

              let listaFiltrada = this.state.tipoAlteracao.filter((t) => t.atual === 'S');

              if (tipoAlteracao && this.state.tipoAlteracao.length && !listaFiltrada.find((t) => t.id === tipoAlteracao)) {
                const item = this.state.tipoAlteracao.find((t) => t.id === tipoAlteracao);

                if (item && item.atual === 'N') {
                  listaFiltrada.push(item);
                  listaFiltrada = listaFiltrada.sort((a, b) => a.nome.localeCompare(b.nome))
                }
              }

              return <Field
                disabled={!this.props.permitirEdicao}
                name="minerio.idTipoAlteracao"
                component={DropdownListField}
                label="Tipo de alteração hidrotermal"
                dica="Selecione o tipo de alteração hidrotermal observado no material amostrado"
                elementos={listaFiltrada}
              />
            }}
          </Field>
        </Col>
      </Row>
    </>
  }

}

export default Minerio;
