import React from "react";
import PropTypes from "prop-types";
import NavBar from "../navBar/NavBar";
import Container from "react-bootstrap/Container";
import Notification from "../notificacao/Notificacao";

const PageScaffold = (props) => {
  return (
    <>
      <NavBar titulo={props.titulo} disableInfo={props.disableInfo}/>
      <div style={{ flexGrow: 1, overflow: "auto", marginTop: "2em", marginBottom: "2em" }}>
        <Container fluid>
          {props.children}
          <Notification/>
          {/*<ModalAlerta/>*/}
        </Container>
      </div>
    </>
  )
}

PageScaffold.propTypes = {
  titulo: PropTypes.string,
  children: PropTypes.node,
};

PageScaffold.defaultProps = {
  titulo: "Sistema de Gerenciamento de Informações Hidrométricas - SGIH",
};

export default PageScaffold;
