import React from "react";
import { Col, Row } from "react-bootstrap";
import { Field } from "react-final-form";
import { DropdownListField } from "../../../../../components/field/DropdownListField";
import { NumberInputField } from "../../../../../components/field/NumberInputField";
import { campoObrigatorioDefinitivoComMsgGenerica } from "../../../../../util/validadores";
import { resolverRequestAmostras } from "../AmostrasUtils/AmostraRequestUtils";
import CamposComuns from "./CamposComuns";

class Overbank extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

      velocidadeCorrente: [],
      nivelAgua: [],
      areaDrenagem: [],
      turbidezAgua: [],
      corAgua: [],
      corMaterialColetado: [],
    }
  };

  async componentDidMount() {
    this.setState({
      velocidadeCorrente: await resolverRequestAmostras("/amostra/agua/velocidadeCorrente"),
      nivelAgua: await resolverRequestAmostras("/biblioteca/nivelagua"),
      areaDrenagem: await resolverRequestAmostras("/biblioteca/areadrenagem"),
      turbidezAgua: await resolverRequestAmostras("/biblioteca/turbidez"),
      corAgua: await resolverRequestAmostras("/biblioteca/amostras/cor"),
      corMaterialColetado: await resolverRequestAmostras("/biblioteca/amostras/cor"),
    })
  }


  render() {
    return <>
      <CamposComuns
        duplicata={this.props.duplicata}
        materialColetadoPreenchido={true}
        permitirEdicao={this.props.permitirEdicao}
        labelCampo="Peso da amostra (g)"
        dicaCampo="Informe o peso da amostra, em gramas"
        profundidadeObrigatoria={false}
        informacoesRelevantesObrigatorias={true}
        prefixo={'overbank'}
        visualizar={this.props.visualizar}
      />

      <Field subscription={{ value: true }} name={'modoCadastro'}>
        {({ input: { value: modoCadastro } }) => {

        const validar = modoCadastro === 'DEFINITIVO';

          return <>
                  <Row className="mt-3">
        <Col md={4}>
          <Field
            disabled={!this.props.permitirEdicao}
            name="overbank.larguraRio"
            component={NumberInputField}
            permitirNegativos={false}
            label="Largura do rio (m)"
            dica="Informe a largura do rio em metros, se aplicável"
            required={validar}
            casasDecimais={4}
            casasInteiros={4}
            validate={campoObrigatorioDefinitivoComMsgGenerica(
              "Largura do rio (m)"
            )}
          />
        </Col>
        <Col md={4}>
          <Field
            disabled={!this.props.permitirEdicao}
            name="overbank.profundidadeRio"
            component={NumberInputField}
            permitirNegativos={false}
            label="Profundidade do rio (m)"
            dica="Informe a profundidade do rio em metros,se aplicável"
            required={validar}
            casasDecimais={4}
            casasInteiros={4}
            validate={campoObrigatorioDefinitivoComMsgGenerica(
              "Profundidade do rio (m)"
            )}
          />
        </Col>
        <Col md={4}>
          <Field
            disabled={!this.props.permitirEdicao}
            name="overbank.idVelocidadeCorrente"
            component={DropdownListField}
            label="Velocidade da corrente"
            dica="Selecione a velocidade da corrente, se aplicável"
            required={validar}
            elementos={this.state.velocidadeCorrente}
            validate={campoObrigatorioDefinitivoComMsgGenerica(
              "Velocidade da corrente"
            )}
          />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col md={4}>
          <Field
            disabled={!this.props.permitirEdicao}
            name="overbank.idNivelAgua"
            component={DropdownListField}
            label="Nível da água"
            dica="Selecione o nível d'água da drenagem, se aplicável"
            required={validar}
            elementos={this.state.nivelAgua}
            validate={campoObrigatorioDefinitivoComMsgGenerica(
              "Nível da água"
            )}
          />
        </Col>
        <Col md={4}>
          <Field
            disabled={!this.props.permitirEdicao}
            name="overbank.idAreaDrenagem"
            component={DropdownListField}
            label="Área de drenagem"
            dica="Selecione a área da bacia de drenagem, se aplicável"
            required={validar}
            elementos={this.state.areaDrenagem}
            validate={campoObrigatorioDefinitivoComMsgGenerica(
              "Área de drenagem"
            )}
          />
        </Col>
        <Col md={4}>
          <Field
            disabled={!this.props.permitirEdicao}
            name="overbank.idTurbidezAgua"
            component={DropdownListField}
            label="Turbidez da água"
            dica="Selecione o nível de turbidez da água no momento da amostragem, se aplicável"
            required={validar}
            elementos={this.state.turbidezAgua}
            validate={campoObrigatorioDefinitivoComMsgGenerica(
              "Turbidez da água"
            )}
          />
        </Col>
      </Row>
      <Row className="mt-3 mb-3">
        <Col md={6}>
          <Field
            disabled={!this.props.permitirEdicao}
            name="overbank.idCorAgua"
            component={DropdownListField}
            label="Cor da água"
            dica="Selecione a cor da água, se aplicável"
            required={validar}
            elementos={this.state.corAgua}
            validate={campoObrigatorioDefinitivoComMsgGenerica(
              "Cor da água"
            )}
          />
        </Col>
        <Col md={6}>
          <Field
            disabled={!this.props.permitirEdicao}
            name="overbank.idCorMaterialColetado"
            component={DropdownListField}
            label="Cor do material coletado"
            dica="Selecione a cor predominante do material coletado"
            required={validar}
            elementos={this.state.corMaterialColetado}
            validate={campoObrigatorioDefinitivoComMsgGenerica(
              "Cor do material coletado"
            )}
          />
        </Col>
      </Row>
          
          </>
        }}
        </Field>
    </>
  }

}

export default Overbank;
