import React, { useCallback } from "react";
import { PasswordInput } from "../Jqx/PasswordInput";

const PasswordField = ({input, meta, ...props}) => {
  const onChange = useCallback((evt) => {
    input.onChange(evt)
  }, [input])

  const mensagemErro = meta.touched && !meta.valid && meta.error;

  return (
    <PasswordInput
      {...props}
      onChange={onChange}
      valor={input.value}
      mensagemErro={mensagemErro}
    />
  )
}

PasswordField.propTypes = {
  ...PasswordInput.propTypes,
}

PasswordField.defaultProps = {
  ...PasswordInput.defaultProps,
}

export {PasswordField}
