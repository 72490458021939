import { filter, map } from "rxjs/operators";
import { Subject } from "rxjs";

interface EventWrapper {
  eventType: string;
  payload?: any;
}

export class EventBus {
  private eventStream = new Subject<EventWrapper>();

  emit(eventType: string, payload?: any) {
    this.eventStream.next({ eventType, payload });
  }

  on(...eventTypes: string[]) {
    return this.eventStream.pipe(
      filter((x) => eventTypes.includes(x.eventType)),
      map((x) => x.payload)
    );
  }
}

export default new EventBus();
