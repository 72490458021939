import React from 'react'
import PropTypes from "prop-types";

import "./navbar.css"
import { useSelector } from "react-redux";
import MenuLateral from '../menuLateral/MenuLateral';

const NavBar = ({ titulo = "" }) => {

  const nome = useSelector((state) => state.autenticacao.nome)
  let link = window.location.href;
  link = new URL(link).origin;

  return (
    <>
      <nav id="navBar-completo" >
        <div id="topo1-completo" className="–color-primary-darken-01">
          <a href={`${link}`}>
            <div id="logo"></div>
          </a>

        </div>
        <div id="topo2-completo">
          <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
            <p>Olá, {nome}</p>
          </div>
          <h2 className="navbar-titulo">{titulo}</h2>
          <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
            <MenuLateral/>
          </div>
        </div>
      </nav>
    </>
  )
}

NavBar.propTypes = {
  titulo: PropTypes.string,
};

export default NavBar;
