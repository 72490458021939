import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
// import { createIconButton, Table } from "../../../../components/Jqx/Table";
import { jqx } from "jqwidgets-scripts/jqwidgets-react-tsx/jqxgrid";
import { useField, useForm } from "react-final-form";
import { TIPO_ENTRADA_COORDS_DECIMAL } from "../../../../util/constantes";
import { Col, Row } from "react-bootstrap";
import { Table } from "../../../../components/Jqx/Table";

const PONTO_NAO_VINCULADO = "NAO_VINCULADO"
const PONTO_VINCULADO = "VINCULADO"
const NOME_CAMPO_PONTOS = "idsPontosAssociados"

let debounce;

const PontosGPSAfloramento = ({
  idEstacao,
  pontosAtribuidosAEstacao,
  permitirEdicao = true,
}) => {

  const [clicado, setClicado] = useState(false);

  const tableRef = useRef();
  const form = useForm();
  const coordenadasAtuais = form.getState().values.coordenadas || null
  const estadoCampoPontos = useField(NOME_CAMPO_PONTOS, {
    subscription: {
      value: false,
    }
  })

  const pontosSelecionados = useRef([])

  useEffect(() => {
    pontosSelecionados.current = estadoCampoPontos.value ?? []
  }, [estadoCampoPontos.value])


  const compararCoordenadaGPS = (editando = true) => {

    if (editando) {
      pontosSelecionados.current = [];
      //form.change(NOME_CAMPO_PONTOS, [])
      return false;
    }

    setClicado(true);

    const tabela = [...document.getElementsByClassName("jqx-grid-cell jqx-item")]
    const ponto = pontosAtribuidosAEstacao
      .filter(({ idDatum, latitude, longitude }) =>
        idDatum === coordenadasAtuais.idDatum &&
        latitude.toFixed(6) === coordenadasAtuais.latitude.toFixed(6) &&
        longitude.toFixed(6) === coordenadasAtuais.longitude.toFixed(6)
      )[0]

    if (!ponto) return false

    const id = ponto.id.toString();

    tabela?.map(element => element.title === id && element.querySelector("button").click())
  }

  useEffect(() => {
    const editando = form.getState().values.id > 0;
    clearTimeout(debounce);
    debounce = setTimeout(() => {
      if (coordenadasAtuais?.tipoEntrada === TIPO_ENTRADA_COORDS_DECIMAL.id) {
        clicado === false && compararCoordenadaGPS(editando);
      }
    }, 500)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coordenadasAtuais, clicado])

  const alterarAssociacaoPonto = useCallback((pontos) => {
    let novosPontos = [];
    pontos.map(ponto => {
      if (ponto.vinculado) {
        novosPontos = [ponto.id];
      }
    })
    // const jaEstaSelecionado = vinculado
    // let novosPontos
    // if (jaEstaSelecionado) {
    //   novosPontos = pontosSelecionados.current.filter(id => id !== idPonto)
    // } else {
    //   // TODO: (marco) Para seleção de varios pontos
    //   // novosPontos = [...pontosSelecionados.current, idPonto]
    //   // TODO: (marco) Para seleção de um ponto
    //   novosPontos = [idPonto]
    // }

    // pontosSelecionados.current = novosPontos
    form.change(NOME_CAMPO_PONTOS, novosPontos)
  }, [form]);

  // const iconRenderer = useMemo(() => createIconButton({
  //   ref: tableRef,
  //   className: "icone",
  //   onClick: alterarAssociacaoPonto,
  //   iconClasses: ["far", "text-secondary"],
  //   extraClassResolver: (ponto) => {
  //     if (ponto && pontosSelecionados.current.includes(ponto.id)) return "fa-check-square"
  //     return "fa-square"
  //   }
  // }), [alterarAssociacaoPonto, pontosSelecionados]);


  const source = useMemo(() => new jqx.dataAdapter({
    id: 'id',
    localdata: pontosAtribuidosAEstacao,
    datatype: 'array',
    datafields: [
      { name: 'id', type: 'string', map: "id" },
      { name: 'nome', type: 'string', map: 'nome' },
      { name: 'descricao', type: 'string', map: 'descricao' },
      { name: 'latitude', type: 'number', map: "latitude" },
      { name: 'longitude', type: 'number', map: "longitude" },
      { name: 'altitude', type: 'number', map: "altitude" },
      { name: 'idDatum', type: 'number', map: "idDatum" },
      { name: 'status', type: 'string', map: "status" },
      { name: 'vinculado', type: 'bool', map: "vinculado" },
    ],
  }), [pontosAtribuidosAEstacao]);

  const colunas = useMemo(() => [
    {
      text: 'Vincular/desvincular', width: "10%", menu: false, dataField: 'vinculado',
      columntype: "checkbox", type: "boolean", enabletooltips: false,
    },
    { text: 'Longitude', datafield: 'longitude', width: "15%", editable: false },
    { text: 'Latitude', datafield: 'latitude', width: "15%", editable: false },
    { text: 'Altitude (m)', datafield: 'altitude', width: "15%", editable: false },
    { text: 'Nome', datafield: 'nome', editable: false, width: "15%" },
    { text: 'Descrição', datafield: 'descricao', editable: false, width: "15%" },
    {
      text: 'Status', datafield: 'status', editable: false, width: "15%",  filtertype: 'checkedlist', filteritems:
      [
        { label: "Vinculado", value: "Vinculado" },
        { label: "Não Vinculado", value: "Pendente" },
        { label: "Pendente", value: "NAO_VINCULADO" },
      ],
      cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
        let hint = "";
        const status = rowdata.vinculado
        ? "Em preenchimento"
        : (
          PONTO_NAO_VINCULADO === rowdata.status
            ? "Pendente"
            : PONTO_VINCULADO === rowdata.status
              ? "Em preenchimento"
              : rowdata.status
        );
        switch (value) {
          case "NAO_VINCULADO": {
            value = "Pendente";
            hint = "Pendente de vinculo com a atividade, mas associado a uma estação";
            break;
          }
          case "PENDENTE": {
            value = "Não Vinculado";
            hint = "Não está vinculado a uma estação";
            break;
          }
          case "VINCULADO": {
            value = "Vinculado";
            hint = "Vinculado a uma atividade da estação";
            break;
          }
          default: {
            value = "";
            hint = "";
          };
        }
          return `<div className="jqx-grid-cell-left-align" style="margin-top: 8.5px;" data-id=${row.id} title="${hint}">${status}</div>`;
      },
    },
  ], []);


  const onRowClick = useCallback((evt) => {
    if (permitirEdicao) {
      const ponto = evt.args.row
      form.batch(() => {
        form.change("coordenadas.tipoEntrada", TIPO_ENTRADA_COORDS_DECIMAL.id)
        form.change("coordenadas.latitude", ponto.latitude)
        form.change("coordenadas.longitude", ponto.longitude)
        form.change("coordenadas.idDatum", ponto.idDatum)
        form.change("altitude", ponto?.altitude)
      })
      let tempSource = [];
      source._source.localdata.map(item => {
        if (item.id.toString() === ponto.id.toString()) {
          if (item.vinculado) {
            item.vinculado = !item.vinculado;
          } else {
            item.vinculado = true
          }

        } else {
          item.vinculado = false;
        }
        tempSource.push(item);
      })
      source._source.localdata = tempSource;
      alterarAssociacaoPonto(source._source.localdata)
      source.dataBind();
    }
  }, [alterarAssociacaoPonto, form, permitirEdicao, source]);

  return (
    <div className="mt-0">
      <Row>
        <Col md={12}>
          <h2 className="h5" style={{ marginBottom: ' 0.75rem' }}>Pontos GPS associados a estação</h2>
        </Col>
      </Row>
      <Table
        selectionmode='singlecell'
        editable={permitirEdicao}
        editmode='click’'
        ref={tableRef}
        autoshowloadelement={false}
        columns={colunas}
        source={source}
        oncellendedit={(event) => onRowClick(event)}
        className="custom_radio"
      />
    </div>
  )
}

export default PontosGPSAfloramento;
