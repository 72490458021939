import { Field, useForm } from "react-final-form";
import Card from "../../../components/card";
import { resolverExibidos } from "../../../components/SubForm/SubForm";
import IlustracoesFotomicrografia from "./IlustracoesFotomicrografia";

const Fotomicrografia = ({
    extencoesIlustracao,
    amostraSelecionada,
    bbPolarizacao,
    bbObjetiva,
    bbAnaliseLuz,
    disabled
}) => {
    function verificaSequencia(lista) {
        const listaSemExluidos = lista?.existentes.filter(item => !lista?.idsExcluidos.includes(parseInt(item.id)));
        const listaOrdenada = listaSemExluidos.sort((a, b) => {
            const numeroA = parseInt(a.nomeGerado.split('-').pop());
            const numeroB = parseInt(b.nomeGerado.split('-').pop());
            return numeroA - numeroB;
        });
        for (let i = 0; i < listaOrdenada.length; i++) {
            const numeroEsperado = (i + 1).toString()
            if (listaOrdenada[i].nomeGerado.split('-').pop() !== numeroEsperado) {
                return false;
            }
        }
        return true;
    }
    function verificaSequenciaNovosEExcluidos(lista) {
        const listaOrdenada = lista.sort((a, b) => {
            const numeroA = parseInt(a.nomeGerado.split('-').pop());
            const numeroB = parseInt(b.nomeGerado.split('-').pop());
            return numeroA - numeroB;
        });
        for (let i = 0; i < listaOrdenada.length; i++) {
            const numeroEsperado = (i + 1).toString()
            if (listaOrdenada[i].nomeGerado.split('-').pop() !== numeroEsperado) {
                return false;
            }
        }
        return true;
    }

    // Função para corrigir a sequência do nome gerado
    function corrigirSequenciaNovosEExcluidos(lista) {
        const listaOrdenada = lista.sort((a, b) => {
            const numeroA = parseInt(a.nomeGerado.split('-').pop());
            const numeroB = parseInt(b.nomeGerado.split('-').pop());
            return numeroA - numeroB;
        });
       
        for (let i = 0; i < listaOrdenada.length; i++) {            
            const numeroEsperado = (i + 1).toString();
            const nomeGerado =  listaOrdenada[i].nomeGerado;        
            
            listaOrdenada[i].nomeGerado = nomeGerado.slice(0, -1) + numeroEsperado;
        }

        lista = listaOrdenada
        return lista;
    }
    function corrigirSequencia(lista) {
        const listaSemExluidos = lista?.existentes.filter(item => !lista?.idsExcluidos.includes(parseInt(item.id)));
        const listaOrdenada = listaSemExluidos.sort((a, b) => {
            const numeroA = parseInt(a.nomeGerado.split('-').pop());
            const numeroB = parseInt(b.nomeGerado.split('-').pop());
            return numeroA - numeroB;
        });

        
        for (let i = 0; i < listaOrdenada.length; i++) {            
            const numeroEsperado = (i + 1).toString();
            const nomeGerado =  listaOrdenada[i].nomeGerado;        
            
            listaOrdenada[i].nomeGerado = nomeGerado.slice(0, -1) + numeroEsperado;
        }       

        lista.existentes = listaOrdenada
        return lista;
    }
    const form = useForm()

    return (
        <div style={{ marginTop: '2.5rem' }}>
            <Card>
                <Card.Body>
                    <Card.Title>Fotomicrografias</Card.Title>
                    <Field name={"numeroAmostra"} subscription={{ value: true }}>
                        {() => {
                            let tempNumeroAmostra = amostraSelecionada?.numeroAmostra ?? "";
                            let valoresIniciais = form.getState().values.IlustracoesFotomicrografia ?? [];
                            let valoresNovos = valoresIniciais?.novos?.filter(item => item !== null);
                            if (valoresIniciais?.novos?.length > 0) {
                                valoresIniciais = resolverExibidos(valoresIniciais)
                                let numeros = [];
                                let ilustracoesFiltradas = [];

                                for (let i = 0; i < valoresIniciais.length; i++) {

                                    if (valoresIniciais[i].nomeGerado.startsWith(tempNumeroAmostra + "-")) {
                                        let ultimoNumero = parseInt(valoresIniciais[i].nomeGerado.split("-").pop());
                                        numeros.push(ultimoNumero);
                                        ilustracoesFiltradas.push(valoresIniciais[i]);
                                    }
                                }


                                let maiorNumero = 1
                                if (numeros.length > 0) {
                                    maiorNumero = Math.max(...numeros);
                                }


                                for (let i = 0; i < valoresNovos.length; i++) {
                                    let nomeGerado = ''
                                    if (parseInt(valoresNovos[i].nomeGerado.split('-').pop() !== maiorNumero)) {
                                        nomeGerado = `${tempNumeroAmostra}-${maiorNumero + 1}`;
                                    } else {
                                        nomeGerado = `${tempNumeroAmostra}-${maiorNumero}`;
                                    }
                                    valoresNovos[i].nomeGerado = nomeGerado
                                    ilustracoesFiltradas.push(valoresNovos[i])
                                }

                                if (valoresIniciais?.length > 0) {
                                    if (!verificaSequenciaNovosEExcluidos(valoresIniciais)) {
                                        ilustracoesFiltradas = corrigirSequenciaNovosEExcluidos(valoresIniciais);
                                    }
                                }

                                const listaOrdenada = ilustracoesFiltradas.sort((a, b) => {
                                    const numeroA = parseInt(a.nomeGerado.split('-').pop());
                                    const numeroB = parseInt(b.nomeGerado.split('-').pop());
                                    return numeroA - numeroB;
                                });

                                valoresIniciais = {
                                    novos: listaOrdenada
                                };
                            } else {
                                if (valoresIniciais.idsExcluidos?.length > 0) {
                                    if (!verificaSequencia(valoresIniciais)) {
                                        valoresIniciais = corrigirSequencia(valoresIniciais);
                                    }
                                }
                                const listaOrdenada = valoresIniciais?.existentes?.sort((a, b) => {
                                    const numeroA = parseInt(a.nomeGerado.split('-').pop());
                                    const numeroB = parseInt(b.nomeGerado.split('-').pop());
                                    return numeroA - numeroB;
                                });
                                valoresIniciais = {
                                    novos: listaOrdenada
                                };
                            }

                            return <IlustracoesFotomicrografia
                                nome={'ilustracoesFotomicrografia'}
                                permitirEdicao={!disabled}
                                extencoesIlustracao={extencoesIlustracao}
                                tipoAtividadeIlustracao={"amostra"}
                                nomeIlustracaoAmostra={tempNumeroAmostra}
                                legendaObrigatoria
                                permitirVisualizacao={disabled}
                                bbObjetiva={bbObjetiva}
                                bbPolarizacao={bbPolarizacao}
                                bbAnaliseLuz={bbAnaliseLuz}
                            />
                        }}
                    </Field>
                </Card.Body>
            </Card>
        </div>
    );
};

export default Fotomicrografia;