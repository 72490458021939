import React, { useEffect, useRef, useState } from "react";
import Card from "../../../../components/card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { SubForm } from "../../../../components/SubForm";
import { Field, FormSpy } from "react-final-form";
import { DropdownListField } from "../../../../components/field/DropdownListField";
import { campoObrigatorioComMsgGenerica, possuiRegistros, validarSe } from "../../../../util/validadores";
import { TIPO_ESTRUTURA_PRIMARIA_IGNEA, TIPO_ESTRUTURA_PRIMARIA_SEDIMENTAR } from "../../../../util/constantes";
import Atitudes from "./Atitudes";
import { TextAreaField } from "../../../../components/field/TextAreaField";
import { getPropsPermissao } from "../../../../components/SubForm/SubForm";
import { HiddenField } from "../../../../components/field/HiddenField";

const campos = [
  { name: "tipoEstruturaPrimaria", type: "string", map: "tipoEstruturaPrimaria" },
  { name: "idTipo", type: "string", map: "idTipo" },
  { name: "idsRochas", type: "array", map: "idsRochas" },
  { name: "informacoesRelevantes", type: "text", map: "informacoesRelevantes" },
]

const tiposEstruturaPrimaria = [TIPO_ESTRUTURA_PRIMARIA_IGNEA, TIPO_ESTRUTURA_PRIMARIA_SEDIMENTAR]
//const tiposEstruturaPrimaria = [TIPO_ROCHA_AFLORAMENTO_IGNEA, TIPO_ROCHA_AFLORAMENTO_SEDIMENTAR]

const possuiAtitudes = possuiRegistros(valores => valores?.atitudes,
  { min: 'Estruturas primárias devem possuir ao menos uma atitude' });

const EstruturasPrimarias = ({
  nome,
  onSubmit,
  // elementos,
  valoresIniciais,
  permitirEdicao,
  modoDefinitivoSelecionado,
  rochas,
  rochasPorId,
  tiposEstrutura = [],
  tiposNotacao = [],
  formAtividade,
  estPrimariasComErro
}) => {
  // These refs are for remembering the previous values of each array that's a
  // dependency of the columns array. We only recalculate the columns array when
  // there's an actual change in the arrays.
  const rochasPorIdRef = useRef(rochasPorId);
  const tiposEstruturaRef = useRef(tiposEstrutura);

  const [colunasEstruturasPrimarias, setColunasEstruturasPrimarias] = useState(gerarColunasEstruturasPrimarias(rochasPorId, tiposEstrutura))

  useEffect(() => {
    // Only update the columns when at least one of the dependencies has really changed.
    // Otherwise, avoid changing the columns because that causes a table rerender.
    // (The *correct* thing to do would be including the names in the objects themselves (add extra fields)
    // and always recalculate those names when dependencies change, completely removing the need for
    // a cellsrenderer.)
    // Compare the dependencies by value.
    if (JSON.stringify(rochasPorIdRef.current) !== JSON.stringify(rochasPorId)
      || JSON.stringify(tiposEstruturaRef.current) !== JSON.stringify(tiposEstrutura)
    ) {
      setColunasEstruturasPrimarias(gerarColunasEstruturasPrimarias(rochasPorId, tiposEstrutura));
      rochasPorIdRef.current = rochasPorId;
      tiposEstruturaRef.current = tiposEstrutura;
    }
  }, [rochasPorId, tiposEstrutura])

  const valoresIniciaisRef = useRef(valoresIniciais);

  return (
    <div style={{ marginTop: '2.5rem' }}>
      <Card className="mt-3">
        <Card.Body>
        {estPrimariasComErro && <span style={{ color: "red" }}>Não possui um tipo de estrutura primária para o campo "Rochas".</span>}
          <Card.Title>Estruturas primárias</Card.Title>
          <Field name={nome} subscription={{ value: true }}>
            {({ input: { value: estruturasPrimarias = {} } }) => (
              <SubForm
                nome={nome}
                onSubmit={(params) => {
                  // Reset the initial values so that it doesn't get automatically
                  // recovered on the next render.
                  // It would be better to check if the submit was successful before
                  // resetting this ref, but it doesn't seem like that's possible.
                  valoresIniciaisRef.current = null;
                  onSubmit(params);
                }}
                elementos={estruturasPrimarias} // elementos
                campos={campos}
                colunas={colunasEstruturasPrimarias}
                valoresIniciais={valoresIniciaisRef.current || valoresIniciais}
                {...getPropsPermissao(permitirEdicao)}
                alternarBotoesPai={true}
                validarVoltar={true}
                naoValidarCamposVoltar={["tipoEstruturaPrimaria"]}
                validar={(valores) => {
                  if(modoDefinitivoSelecionado)
                    possuiAtitudes(valores);
                }}
                renderForm={({ formProps: { form, values }, prefixoNome }) => {

                  // Update the ref of initial values. This code needs to run
                  // when any field changes.
                  if (valoresIniciaisRef.current !== values) {
                    valoresIniciaisRef.current = values;
                  }
                  
                  return (
                    <>
                    <Row className="my-3">
                        <Col md={6}>
                            <Field
                                component={DropdownListField}
                                name={`tipoEstruturaPrimaria`}
                                label="Tipo de estrutura primária"
                                dica="Selecione o tipo da estrutura primária"
                                elementos={tiposEstruturaPrimaria}
                                required={modoDefinitivoSelecionado}
                                validate={validarSe(modoDefinitivoSelecionado, campoObrigatorioComMsgGenerica("Tipo de estrutura primária"))}
                              />
                        </Col>
                        <Col md={6}>
                          <Field name={'tipoEstruturaPrimaria'} subscription={{ value: true }}>
                            {({ input: { value: tipoEstruturaPrimaria } }) => {

                              let rochasFiltradas=[];
                              rochas.forEach(element => {
                                if(tipoEstruturaPrimaria === "S" && element.idTipoRocha === 5){
                                  rochasFiltradas.push(element);
                                }
                                if(tipoEstruturaPrimaria === "I" && element.idTipoRocha === 1){
                                  rochasFiltradas.push(element);
                                }
                                if((tipoEstruturaPrimaria === "I" && element.idTipoRocha === 2) || (tipoEstruturaPrimaria === "S" && element.idTipoRocha ===2)){
                                  rochasFiltradas.push(element);
                                }
                                
                              });

                              return <><Field
                                component={DropdownListField}
                                name={`idsRochas`}
                                label="Rochas"
                                dica="Seleciona a rocha associada à estrutura primária descrita (estrutura desenvolvida com a formação da rocha)"
                                elementos={rochasFiltradas}
                                required={modoDefinitivoSelecionado}
                                disabled={!permitirEdicao}
                                displayMember={'nomeExibicao'}
                                validate={validarSe(modoDefinitivoSelecionado, campoObrigatorioComMsgGenerica("Rochas"))}
                                checkboxes={true}
                                multiple
                              />
                              <FormSpy subscription={{ values: true }}>
                                  {({ values }) => {
                                    let nomeRocha = "";
                                    if (values?.tipoEstruturaPrimaria === "I") {
                                      nomeRocha = 'Ígnea'
                                    }
                                    if (values?.tipoEstruturaPrimaria === "S") {
                                      nomeRocha = 'Sedimentar ou sedimentos inconsolidados'
                                    }
                                    return (
                                      (rochasFiltradas.length < 1 && modoDefinitivoSelecionado && tipoEstruturaPrimaria) && <><br></br><Field
                                        name={`${values?.tipoEstruturaPrimaria}`}
                                        component={HiddenField}
                                        validate={() => {
                                          return ` É necessário cadastrar uma rocha dessa classe (${nomeRocha}) para essa estrutura.`;
                                        }}
                                      /> </>)
                                  }}
                                </FormSpy>
                             </>
                            }}</Field>
                        </Col> 
                      </Row>
                      <Row className="my-3">
                        <Col md={12}>
                          <Field
                            component={TextAreaField}
                            name={`informacoesRelevantes`}
                            label="Informações relevantes"
                            dica="Caso necessário, descreva detalhadamente as estrutuas primárias observadas"
                            maxLength={250}
                            disabled={!permitirEdicao}
                          />
                        </Col>
                      </Row>
                      <Row className="my-3">
                        <Col md={6}>
                          <Field name={'tipoEstruturaPrimaria'} subscription={{ value: true }}>
                            {({ input: { value: tipoEstruturaPrimaria } }) => {
                                let elementos = [];
                                let textoField = '';
                                let disabled = false;

                                if(!tipoEstruturaPrimaria){
                                  textoField = '';
                                  elementos = [];
                                  disabled = true;
                                }

                                else if(tipoEstruturaPrimaria){
                                  elementos = tiposEstrutura.filter((e) => e.estruturaAtual === 'S' && (e.tipo.toString() === tipoEstruturaPrimaria.toString() || e.tipo.toString() === 'G'))
                                  textoField = tiposEstruturaPrimaria.find(t => t.id === tipoEstruturaPrimaria).nome.toLowerCase();
                                  disabled = false;
                                }
                                return (
                                  <Field
                                    component={DropdownListField}
                                    name={`idTipo`}
                                    label={`Tipo de estrutura ${textoField}`}
                                    dica={`Selecione a estrutura ${textoField} primária associada, se observada`}
                                    elementos={elementos}
                                    required={modoDefinitivoSelecionado}
                                    disabled={disabled ? true : !permitirEdicao}
                                    validate={validarSe(modoDefinitivoSelecionado, campoObrigatorioComMsgGenerica(`Tipo de estrutura ${textoField}`))}
                                  />) 
                            }}
                          </Field>
                        </Col>
                      </Row>
                      <Row className="my-3">
                        <Col md={12}>
                          <Field name={'idTipo'} subscription={{ value: true }}>
                            {({ input: { value } }) => {
                              return (
                                <Card>
                                  <Card.Body>
                                    <Atitudes
                                      nome={'atitudes'}
                                      formAtividade={formAtividade}
                                      elementos={form.getState().values.atitudes || undefined}
                                      valoresIniciais={valoresIniciais}
                                      permitirEdicao={permitirEdicao}

                                      tiposNotacao={tiposNotacao}
                                    />
                                  </Card.Body>
                                </Card>
                              )
                            }}
                          </Field>
                        </Col>
                      </Row>
                    </>
                  )
                }}
              />
            )}
          </Field>
        </Card.Body>
      </Card>
    </div>
  );
}

// Função que gera as colunas da tabela de estruturas primárias, extraída para que
// possamos instanciar as colunas já inicializadas da primeira vez, ao invés de
// usar um estado [] padrão. Isso evita um rerender da tabela.
function gerarColunasEstruturasPrimarias(rochasPorId, tiposEstrutura) {
  return [
    {
      text: 'Tipo de estrutura primária', datafield: 'idTipo', width: "34%",
      cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
        const tipoEstruturaPrimaria = rowdata['tipoEstruturaPrimaria'] ? tiposEstruturaPrimaria.find(t => t?.id === rowdata['tipoEstruturaPrimaria']) : undefined;
        const nomeTipoEstruturaPrimaria = tipoEstruturaPrimaria?.nome ?? '';
        const nomeTipoEstrutura = value ? tiposEstrutura.find(t => t.id === parseInt(value)).nome : '';
        return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${nomeTipoEstruturaPrimaria} - ${nomeTipoEstrutura}</div>`;
      }
    },
    {
      text: "Rochas", datafield: "idsRochas", width: "30%",

      cellsrenderer: function (row, columnfield, value, defaulthtml, columnproperties, rowdata) {
        let nome = ''

        try {
          const idsRochas = (rowdata[columnfield] || '').split(',')
          nome = idsRochas
            .filter(it => !!it)
            .map(it => rochasPorId && rochasPorId[Number(it)] ? rochasPorId[Number(it)].nomeExibicao : '')
            .join(', ')
        }
        catch(err) {}

        return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${nome}</div>`;
      }
    },
    {
      text: "Informações Relevantes", datafield: "informacoesRelevantes", width: '30%'
    },
  ];
}

export default EstruturasPrimarias;
