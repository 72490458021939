import React, { PureComponent } from "react";
import { Subject } from "rxjs";
import { Field } from "react-final-form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { HiddenField } from "../../../../../components/field/HiddenField";
import { TextAreaField } from "../../../../../components/field/TextAreaField";
import { NumberInputField } from "../../../../../components/field/NumberInputField";
import { TextInputField } from "../../../../../components/field/TextInputField";
import { DropdownListField } from "../../../../../components/field/DropdownListField";
import { ComboItemModelLike } from "../../../../../models/combo-item.model";
import { takeUntil } from "rxjs/operators";
import geoquimicaService from "../../../../../service/GeoquimicaService";
import {
  campoObrigatorioComMsgGenerica,
  comporValidadores,
} from "../../../../../util/validadores";
import { AmostraSymbol } from "../constants/amostras.constant";

export interface AmostraSubFormPartProps {
  prefixoNome?: string;
  amostraSymbol: AmostraSymbol;
  pesoAmostraLabel?: string;
  profundidadeMinRequired?: boolean;
  profundidadeMaxRequired?: boolean;
}

export interface AmostraSubFormPartState {
  tipoAmostragemOptions: ComboItemModelLike[];
  fonteAmostraOptions: ComboItemModelLike[];
  materialColetadoOptions: ComboItemModelLike[];
  numeroCampoDefault?: string;
  validateProfundidadeMinima?: (...args) => any;
  validateProfundidadeMaxima?: (...args) => any;
}

export class AmostraSubFormPart extends PureComponent<
  AmostraSubFormPartProps,
  AmostraSubFormPartState
> {
  validateTipoAmostragem = comporValidadores(
    campoObrigatorioComMsgGenerica("Tipo amostragem")
  );
  validateFonte = comporValidadores(
    campoObrigatorioComMsgGenerica("Fonte amostra")
  );
  validateMaterialColetado = comporValidadores(
    campoObrigatorioComMsgGenerica("Material coletado")
  );
  validatePesoAmostra = comporValidadores(
    campoObrigatorioComMsgGenerica("Peso amostra")
  );

  private lifecycle = new Subject();

  constructor(props) {
    super(props);
    this.state = {
      tipoAmostragemOptions: [],
      fonteAmostraOptions: [],
      materialColetadoOptions: [],
      numeroCampoDefault: "",
      validateProfundidadeMinima: this.handleProfundidadeMinimaValidators(),
      validateProfundidadeMaxima: this.handleProfundidadeMaximaValidators(),
    };
  }

  componentDidMount() {
    geoquimicaService
      .listTipoAmostragemOptions()
      .pipe(takeUntil(this.lifecycle))
      .subscribe((data) => {
        this.setState({ tipoAmostragemOptions: data });
      });

    geoquimicaService
      .listFonteAmostraOptions()
      .pipe(takeUntil(this.lifecycle))
      .subscribe((data) => {
        this.setState({ fonteAmostraOptions: data });
      });

    geoquimicaService
      .listMaterialColetadoOptions()
      .pipe(takeUntil(this.lifecycle))
      .subscribe((data) => {
        this.setState({ materialColetadoOptions: data });
      });

    geoquimicaService
      .generateNumeroCampo(this.props.amostraSymbol)
      .pipe(takeUntil(this.lifecycle))
      .subscribe((value) => {
        this.setState({
          numeroCampoDefault: value,
        });
      });
  }

  componentDidUpdate(prevProps: Readonly<AmostraSubFormPartProps>) {
    const newState: Partial<AmostraSubFormPartState> = {};
    if (
      !!prevProps.profundidadeMaxRequired !==
      !!this.props.profundidadeMaxRequired
    ) {
      newState.validateProfundidadeMaxima = this.handleProfundidadeMaximaValidators();
    }

    if (
      !!prevProps.profundidadeMinRequired !==
      !!this.props.profundidadeMinRequired
    ) {
      newState.validateProfundidadeMinima = this.handleProfundidadeMinimaValidators();
    }

    if (Object.keys(newState).length) {
      this.setState(newState as any);
    }
  }

  componentWillUnmount() {
    this.lifecycle.next();
    this.lifecycle.complete();
  }

  private handleProfundidadeMinimaValidators() {
    const name = 'Profundidade mínima'
    const validators: any[] = [];
    if (this.props.profundidadeMinRequired) {
      validators.push(campoObrigatorioComMsgGenerica(name));
    }

    validators.push((value, allValues) => {
      const profundidadeMaxima = this.handleStrToNumber(allValues.profundidadeMaxima);
      value = this.handleStrToNumber(value);
      if (value > profundidadeMaxima) {
        return `O campo ${name} não pode ser maior do que a Profundidade máxima`;
      }
    });
    return comporValidadores(...validators);
  }

  private handleProfundidadeMaximaValidators() {
    const name = 'Profundidade máxima'
    const validators: any[] = [];
    if (this.props.profundidadeMaxRequired) {
      validators.push(campoObrigatorioComMsgGenerica(name));
    }

    validators.push((value, allValues) => {
      const profundidadeMinima = this.handleStrToNumber(allValues.profundidadeMinima);
      value = this.handleStrToNumber(value);
      if (value < profundidadeMinima) {
        return `O campo ${name} não pode ser menor do que a Profundidade mínima`;
      }
    });
    return comporValidadores(...validators);
  }

  private handleStrToNumber(str) {
    str = String(str ?? '0')?.replace(',', '.');
    const n = Number(str);
    return n;
  }

  render() {
    return (
      <>
        <Field
          component={HiddenField}
          name={`${this.props.prefixoNome}id_amostra`}
        />

        <Row className="my-3">
          <Col>
            <Field
              component={DropdownListField}
              name={`${this.props.prefixoNome}tipo`}
              label="Tipo amostragem"
              elementos={this.state.tipoAmostragemOptions}
              required
              validate={this.validateTipoAmostragem}
            />
          </Col>
          <Col>
            <Field
              component={DropdownListField}
              name={`${this.props.prefixoNome}fonte`}
              label="Fonte amostra"
              elementos={this.state.fonteAmostraOptions}
              required
              validate={this.validateFonte}
            />
          </Col>
          <Col>
            <Field
              component={TextInputField}
              name={`${this.props.prefixoNome}numero_do_campo`}
              label="Número campo"
              readOnly={true}
              defaultValue={this.state.numeroCampoDefault}
            />
          </Col>
        </Row>
        <Row className="my-3">
          <Col>
            <Field
              component={DropdownListField}
              name={`${this.props.prefixoNome}materialColetado`}
              label="Material coletado"
              elementos={this.state.materialColetadoOptions}
            />
          </Col>
          <Col>
            <Field
              component={NumberInputField}
              name={`${this.props.prefixoNome}pesoAmostra`}
              label={this.props.pesoAmostraLabel ?? "Peso da amostra (g)"}
              casasInteiros={9}
              casasDecimais={2}
              min={0}
              required
              validate={this.validatePesoAmostra}
            />
          </Col>
        </Row>
        <Row className="my-3">
          <Col>
            <Field
              component={NumberInputField}
              name={`${this.props.prefixoNome}profundidadeMinima`}
              label="Profundidade mínima (m)"
              casasInteiros={9}
              casasDecimais={2}
              min={0}
              required={!!this.props.profundidadeMinRequired}
              validate={this.state.validateProfundidadeMinima}
            />
          </Col>
          <Col>
            <Field
              component={NumberInputField}
              name={`${this.props.prefixoNome}profundidadeMaxima`}
              label="Profundidade máxima (m)"
              casasInteiros={9}
              casasDecimais={2}
              min={0}
              required={!!this.props.profundidadeMaxRequired}
              validate={this.state.validateProfundidadeMaxima}
            />
          </Col>
        </Row>
        <Row className="my-3">
          <Col>
            <Field
              component={TextAreaField}
              name={`${this.props.prefixoNome}observacoes`}
              label="Observação"
              maxLength={250}
            />
          </Col>
        </Row>
      </>
    );
  }
}
