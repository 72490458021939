import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { asapScheduler, Observable, Observer, Subject } from "rxjs";
import { observeOn, subscribeOn } from "rxjs/operators";

import axiosInstance from "./http-commons";

export interface HttpProgressEvent<T> {
  response?: AxiosResponse<T>;
  loaded?: number;
  total?: number;
  readonly percent: number;
}

export interface HttpRequestConfig {
  params?: any;
}

type HttpRequestConfigObserveProgress = HttpRequestConfig & {
  observeProgress: true;
};

type HttpRequestConfigTypes =
  | HttpRequestConfig
  | HttpRequestConfigObserveProgress;
type FactoryRequestFn<T> = (
  requestConfig: AxiosRequestConfig
) => Promise<AxiosResponse<T>>;

export class HttpClient {
  get<T = any>(
    url: string,
    config: HttpRequestConfigObserveProgress
  ): Observable<HttpProgressEvent<T>>;
  get<T = any>(
    url: string,
    config?: HttpRequestConfig
  ): Observable<AxiosResponse<T>>;
  get<T = any>(url: string, config?: HttpRequestConfigTypes) {
    return this._createRequestInternalObservable<T>((requestConfig) => {
      return axiosInstance.get<T>(url, requestConfig);
    }, config);
  }

  post<T = any>(
    url: string,
    data: any,
    config: HttpRequestConfigObserveProgress
  ): Observable<HttpProgressEvent<T>>;
  post<T = any>(
    url: string,
    data: any,
    config?: HttpRequestConfig
  ): Observable<AxiosResponse<T>>;
  post<T = any>(url: string, data: any, config?: HttpRequestConfigTypes) {
    return this._createRequestInternalObservable<T>((requestConfig) => {
      return axiosInstance.post<T>(url, data, requestConfig);
    }, config);
  }

  put<T = any>(
    url: string,
    data: any,
    config?: HttpRequestConfigObserveProgress
  ): Observable<HttpProgressEvent<T>>;
  put<T = any>(
    url: string,
    data: any,
    config: HttpRequestConfig
  ): Observable<AxiosResponse<T>>;
  put<T = any>(url: string, data: any, config?: HttpRequestConfigTypes) {
    return this._createRequestInternalObservable<T>((requestConfig) => {
      return axiosInstance.put<T>(url, data, requestConfig);
    }, config);
  }

  private _createRequestInternalObservable<T>(
    factoryRequest: FactoryRequestFn<T>,
    config?: HttpRequestConfigTypes
  ) {
    return new Observable<any>((subscriber) => {
      const source = axios.CancelToken.source();
      subscriber.add(() => {
        source.cancel();
      });

      const requestConfig: AxiosRequestConfig = {
        params: config?.params,
        cancelToken: source.token,
      };

      let observer: Observer<AxiosResponse<T>>;
      if ((config as any)?.observeProgress) {
        const subjectInternal = new Subject<AxiosResponse<T>>();
        const requestSubscription = subjectInternal.subscribe({
          next: (response) => {
            const event: HttpProgressEvent<T> = new HttpProgressEventImpl({
              response,
            });
            subscriber.next(event as any);
          },
          error: (response) => {
            subscriber.error(response);
          },
          complete: () => {
            subscriber.complete();
          },
        });
        requestConfig.onUploadProgress = (progressEvent) => {
          const event: HttpProgressEvent<T> = new HttpProgressEventImpl({
            loaded: progressEvent.loaded,
            total: progressEvent.total,
          });
          subscriber.next(event);
        };

        subscriber.add(requestSubscription);

        observer = subjectInternal;
      } else {
        observer = subscriber;
      }

      this._handleRequestPromise<T>(observer, factoryRequest(requestConfig));
    }).pipe(subscribeOn(asapScheduler), observeOn(asapScheduler));
  }

  private _handleRequestPromise<T>(
    observer: Observer<AxiosResponse<T>>,
    requestPromise: Promise<AxiosResponse<T>>
  ) {
    requestPromise
      .then((response) => {
        observer.next(response);
        observer.complete();
      })
      .catch((response) => {
        if (axios.isCancel(response)) {
          observer.complete();
        } else {
          observer.error(response);
        }
      });
  }
}

export default new HttpClient();

class HttpProgressEventImpl<T> implements HttpProgressEvent<T> {
  response?: AxiosResponse<T>;
  loaded?: number;
  total?: number;

  get percent() {
    let percent: number;
    if (typeof this.loaded === "number" && typeof this.total === "number") {
      percent = (this.loaded / this.total) * 100;
    }

    return percent!;
  }

  constructor(event?: Partial<HttpProgressEvent<T>>) {
    this.response = event?.response;
    this.loaded = event?.loaded;
    this.total = event?.total;
  }
}
