import React from "react";
import PropTypes from "prop-types";
import Form from "react-bootstrap/Form";
import Hint from "../../hint/Hint";
import JqxCheckBox from "jqwidgets-scripts/jqwidgets-react-tsx/jqxcheckbox";
import Erro from "../../Erro";

const Checkbox = React.forwardRef(({ label, dica, onClick, width, required, mensagemErro, ...props }, ref) => {
  return (
    <>
     {label && 
         <Form.Label className="small">
          {label} 
          {required && <span style={{ color: "red" }}>*</span>}
          {dica && <Hint textoAjuda={dica} />}
        </Form.Label>
      }

      <JqxCheckBox
        ref={ref}
        theme="bootstrap"
        width={width}
        onClick={onClick}
        disabled={props.disabled}
        {...props}
      >
      </JqxCheckBox>
      <Erro mensagem={mensagemErro} />
    </>
  )
})

Checkbox.propTypes = {
  nome: PropTypes.string,
  label: PropTypes.string,
  dica: PropTypes.string,
  onClick: PropTypes.func,
  width: PropTypes.string,
  mensagemErro: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
}

Checkbox.defaultProps = {
  width: "25%",
}

export default Checkbox;
