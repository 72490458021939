import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { SubForm, LegacySubForm } from "../../../../components/SubForm";
import { Field, useForm } from "react-final-form";
import { DropdownListField } from "../../../../components/field/DropdownListField";
import { campoObrigatorioComMsgGenerica } from "../../../../util/validadores";
import AppAltitudeComponent from "../../../../components/mapa/altitude_component/AppAltitudeComponent";
import AtitudeField from "../../../../components/field/AtitudeFIeld";
import { aoEnviarSubForm } from "../../../../util/mutadores";
import { getPropsPermissao } from "../../../../components/SubForm/SubForm";
import { geoJSONParaArcGIS } from "../../../../util/mapa";
import Service from "../../../../service/Service";

const ID_NOTACAO_QUADRANTE = 2
const ID_NOTACAO_CLER = 3

const camposAtitudes = [
  { name: "idTipoNotacao", type: "string", map: "idTipoNotacao" },
  { name: "atitude", type: "string", map: "atitude" },
]

const Atitudes = ({
  nome,
  valoresIniciais,
  permitirEdicao,

  tiposNotacao = [],
  formAtividade,
}) => {
  const form = useForm();
  const mapaRef = useRef(null)

  // Reference to the tiposNotacao array, to access it from the columns array
  // without including tiposNotacao as a dependency.
  // Note that this means that the table's columns won't respond to changes in
  // tiposNotacao until a rerender happens due to content changes.
  const tiposNotacaoRef = useRef(tiposNotacao);
  useEffect(() => {
    tiposNotacaoRef.current = tiposNotacao ?? [];
  }, [tiposNotacao]);

  const colunasAtitudes = useMemo(() => [
    {
      text: 'Notação', datafield: 'idTipoNotacao', width: "70%",
      cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
        let nome = '';

        try{
          nome = tiposNotacaoRef.current.find(t => t.id === parseInt(value))?.nome;
        }
        catch(err) {}
        
        return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${nome ?? " - "}</div>`;
      }
    },
    { text: 'Atitude', datafield: 'atitude', width: "24%" }
  ], []);

  const exibirAltitude = useCallback((atitude) => {
    const { latitudeSIRGAS2000, longitudeSIRGAS2000 } = formAtividade.getState().values?.coordenadas ?? {}

    if (!isFinite(latitudeSIRGAS2000) && !isFinite(longitudeSIRGAS2000)) {
      return
    }

    mapaRef.current?.renderizarAltitude({
      latitude: latitudeSIRGAS2000,
      longitude: longitudeSIRGAS2000,
      atitude: atitude,
    })
  }, [mapaRef, formAtividade])

  function validaAtitude(atitude, idTipoNotacao) {
    const tamanho = atitude.replace(/[_/]/g, '').length // /[_\/]/g
    return (ID_NOTACAO_QUADRANTE === idTipoNotacao && tamanho === 8) ||
      (ID_NOTACAO_CLER === idTipoNotacao && tamanho === 5)
  }

  const aoAlterarAtitude = useCallback(form => {
    let { atitude = '', idTipoNotacao } = form.getState().values

    if(!atitude)
      atitude = '';

    if (validaAtitude(atitude, idTipoNotacao)) {
      exibirAltitude(atitude)
    }
    if (!atitude || !validaAtitude(atitude, idTipoNotacao)) {
      exibirAltitude('')
    }
  }, [exibirAltitude])
  
  async function aoAbrirComponente(form){
    let idVisita = form?.getState().values.idVisita;
    if (idVisita) {      
      let geometriaProjeto = null;
      await Service("/visitas/geometriaVisita/" + idVisita).query()
        .then((resp) => {          
            geometriaProjeto =  geoJSONParaArcGIS(resp.data.geoJSON)
            if(geometriaProjeto){
              mapaRef.current?.renderizarGeometriaProjeto({
                geometriaProjeto: geometriaProjeto,
              });
            }
        })
        .catch((err) => {
          console.error("Falha ao obter geometria da visita", err);
        })
    }
  }
   

  const onSubmit = aoEnviarSubForm(form);

  return (
    <>
      <Field name={nome} subscription={{ value: true }}>
        {({ input: { value: atitudes = {} } }) => (
          <SubForm
            nome={nome}
            onSubmit={onSubmit}
            elementos={atitudes} // form.getState().values.atitudes
            campos={camposAtitudes}
            colunas={colunasAtitudes}
            formSubscription={{}}
            valoresIniciais={valoresIniciais}
            {...getPropsPermissao(permitirEdicao)}
            alternarBotoesPai={true}
            validarVoltar={true}
            exibirTabelaSemRegistro={true}
            renderForm={({ formProps: { form } }) => {
              return (
                <>
                  <Row className="my-3">
                    <Col md={6}>
                      <Field
                        component={DropdownListField}
                        name={`idTipoNotacao`}
                        label="Tipo da notação"
                        dica="Selecione o tipo de notação na qual será informada a atitude da estrutura"
                        elementos={tiposNotacao}
                        required
                        disabled={!permitirEdicao}
                        validate={campoObrigatorioComMsgGenerica("Tipo da notação")}
                      />
                    </Col>
                    <Col md={6}>
                      <Field name={'idTipoNotacao'} subscription={{ value: true }}>
                        {({ input: { value } }) => {       
                          
                          const { atitude, idTipoNotacao } = form.getState().values

                          if (atitude && !validaAtitude(atitude, idTipoNotacao)){
                            form.mutators.setValue('atitude', '');
                            aoAlterarAtitude(form)
                          }
                          
                          return (
                            <Field
                              component={AtitudeField}
                              idTipoNotacao={value}
                              name={'atitude'}
                              label="Atitude"
                              dica="Informe a atitude (plano com relação à horizontal e à linha meridiana N-S verdadeira) da estrutura na notação escolhida"
                              required
                              disabled={!permitirEdicao}
                              onChange={atitude => aoAlterarAtitude(form)}
                              // onChange={(v) => aoAlterarAtitude(v, value)}
                              validate={(value) => {
                                if (typeof value === 'undefined' || value.replace(/[_/]/g, '')?.length <= 0)
                                  return campoObrigatorioComMsgGenerica("Atitude")()

                                const { atitude, idTipoNotacao } = form.getState().values

                                if (!validaAtitude(atitude, idTipoNotacao))
                                  return "Verifique os valores inseridos para o tipo de notação escolhida"
                              }}
                            />
                          )
                        }}
                      </Field>
                    </Col>
                  </Row>
                  <Row>
                    <AppAltitudeComponent
                      ref={mapaRef}
                      debugMensagens={!!process.env.REACT_APP_MAP_DEBUG}
                      aoRenderizarMapa={() => {
                        aoAlterarAtitude(form)
                      }}
                      aoCarregar={() => { 
                        aoAbrirComponente(formAtividade)
                      }}
                    />
                  </Row>
                </>
              )
            }}
          />
        )}
      </Field>
    </>
  )
}

export default React.memo(Atitudes);
