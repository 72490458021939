import React, { Component } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { Field, FormSpy } from "react-final-form";
import { DateTimeInputField } from "../../../../../components/field/DateTimeInputField";
import { DropdownListField } from "../../../../../components/field/DropdownListField";
import { NumberInputField } from "../../../../../components/field/NumberInputField";
import { RadioButtonSetField } from "../../../../../components/field/RadioButtonSetField";
import { TextInputField } from "../../../../../components/field/TextInputField";
import { TextInput } from "../../../../../components/Jqx/TextInput";
import { SubForm } from "../../../../../components/SubForm";
import {
  campoObrigatorioComMsgGenerica,
  comporValidadores,
  residuoMineracaoSemUnidadeMedidaTeor,
  valorSemUnidade,
  validacaoResiduosMineracaoAtividadeMineracao,
  validacoesAtividadeMineracao,
  validacoesCamposRelacionadosAtividadeMineracao,
  validarSe
} from "../../../../../util/validadores";
import FonteReferencia from "../FonteReferencia/FonteReferencia";
import { setValue } from "../../../../../util/mutadores";
import { TextAreaField } from "../../../../../components/field/TextAreaField";
import { HiddenField } from "../../../../../components/field/HiddenField";
import { getPropsPermissao, resolverExibidos } from "../../../../../components/SubForm/SubForm";
import dayjs from "dayjs";
import { resolverMultiRequestRecmin } from "../../../../../util/ResolverRequestRecmin";
import {
  ATIVIDADE_MINERACAO
} from "../../../../../util/constantes";
import { RadioField } from "../../../../../components/field/RadioField";
import Hint from "../../../../../components/hint/Hint";
import YearInputField from "../../../../../components/field/YearInputField/YearInputField";

class AtividadeMineracao extends Component {
  constructor(props) {
    super(props);
    this.state = {
      exibirTratamentoMitigatorio: false,
      limparDataInicial: false,
      limparDataFinal: false,
      tratamentoMitigatorio: [
        {
          nome: "sim",
          id: "SIM",
        },
        {
          nome: "não",
          id: "NAO",
        },
      ],
      classificacaoData: [
        {
          nome: "Conhecido",
          id: "CONHECIDO",
        },
        {
          nome: "Estimado",
          id: "ESTIMADO",
        },
        {
          nome: "Indeterminado",
          id: "INDETERMINADO",
        },
      ],
      periodoProducao: [
        {
          nome: "Informação Acumulada",
          id: "ACUMULADA"
        },

        {
          nome: "Informação Anual",
          id: "ANUAL"
        },
      ],

      metodoLavra: [],
      metodoBeneficiamento: [],
      tipoMinerioProcessado: [],
      tipoMateriaPrima: [],
      medida: [],
      unidadeMedida: [],
      mitigacoes: [],
      nome: [],
      tipoResiduo: [],
      tipoArmazenamento: [],
      parametrosParaSelects: {
        longitude: this.props.referenciaForm.getState().values.coordenadas.longitude,
        latitude: this.props.referenciaForm.getState().values.coordenadas.latitude,
        tipoEntrada: this.props.referenciaForm.getState().values.coordenadas.tipoEntrada,
        hemisferio: this.props.referenciaForm.getState().values.coordenadas.hemisferio,
        idDatum: this.props.referenciaForm.getState().values.coordenadas.idDatum
      }
    };

    this.limparDataInicial = false;
    this.limparDataFinal = false;

    this.colunasAtividadeMineracao = [
      {
        text: "Método de lavra", datafield: "idMetodoLavra",
        cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
          let metodos = this.state.metodoLavra;
          let nome = "";
          metodos?.map(metodo => {
            if (metodo?.id?.toString() === rowdata?.idMetodoLavra?.toString()) {
              nome = metodo.nome;
            }
          });
          return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${nome}</div>`;
        }
      },
      {
        text: "Método de beneficiamento", datafield: "idMetodoBeneficiamento",
        cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
          let metodos = this.state.metodoBeneficiamento;
          let nome = "";
          metodos?.map(metodo => {
            if (metodo.id?.toString() === rowdata.idMetodoBeneficiamento?.toString()) {
              nome = metodo.nome;
            }
          });
          return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${nome}</div>`;
        }
      },
      { text: "Início da atividade", datafield: "inicioAtividade" },
      { text: "Término da Atividade", datafield: "terminoAtividade" },
      {
        text: "Possui dados econômicos", datafield: "possuiDadosEconomicos",
        cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
          const atividades = resolverExibidos(this.props.referenciaForm.getState().values.atividadesMineracao);

          if (!atividades || !atividades.length || !atividades.find((item) => item.id === rowdata.idExibir))
            return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">Não</div>`;

          const atividade = atividades.find((item) => item.id === rowdata.idExibir);

          if (!atividade.dadosEconomicos)
            return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">Não</div>`;

          const dadosEconomicosList = resolverExibidos(atividade.dadosEconomicos);

          if (!dadosEconomicosList || !dadosEconomicosList.length)
            return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">Não</div>`;

          return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">Sim (${dadosEconomicosList.length})</div>`;
        },
      },
      {
        text: "Possui resíduos de mineração",
        datafield: "possuiResiduosMineracao",
        cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
          const atividades = resolverExibidos(this.props.referenciaForm.getState().values.atividadesMineracao);

          if (!atividades || !atividades.length || !atividades.find((item) => item.id === rowdata.idExibir))
            return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">Não</div>`;

          const atividade = atividades.find((item) => item.id === rowdata.idExibir);

          if (!atividade.residuosMineracao)
            return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">Não</div>`;

          const residuosMineracaoList = resolverExibidos(atividade.residuosMineracao);

          if (!residuosMineracaoList || !residuosMineracaoList.length)
            return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">Não</div>`;

          return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">Sim (${residuosMineracaoList.length})</div>`;
        },
      },
    ];

    this.dataFieldAtividadeMineracao = [
      { name: "idMetodoLavra", type: "string", map: "idMetodoLavra" },
      { name: "idMetodoBeneficiamento", type: "string", map: "idMetodoBeneficiamento" },
      { name: "inicioAtividade", type: "string", map: "inicioAtividade" },
      { name: "terminoAtividade", type: "string", map: "terminoAtividade" },
      {
        name: "possuiDadosEconomicos",
        type: "string",
        map: "possuiDadosEconomicos",
      },
      {
        name: "possuiResiduosMineracao",
        type: "string",
        map: "possuiResiduosMineracao",
      },
    ];

    this.colunasDadosEconomicosMinerio = [
      { text: "Informação", datafield: "periodoProducao", width: "10%" },
      { text: "Ano da Produção", datafield: "infoAnual", width: "12%" },
      { text: "Ano final da Produção", datafield: "infoAcumulada", width: "12%" },
      {
        text: "Produto(s) beneficiado(s)", datafield: "idTipoSubstancia", width: "22%",
        cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
          let dado = this.dados.find(d => d.id === rowdata.idEditar);
          let produtos = dado ? dado.produtos : [];
        
          let texto = produtos.length ? [...new Set(produtos)].toString().replace(/,/g, ', ') : '';
          return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${texto}</div>`;
        }
      },
      {
        text: "Tipo de minério processado",
        datafield: "idTipoMinerioProcessado", width: "20%",
        cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
          let minerios = this.state.tipoMinerioProcessado;
          let nome = "";
          minerios?.map(minerio => {
            if (minerio.id?.toString() === rowdata.idTipoMinerioProcessado?.toString()) {
              nome = minerio.nome;
            }
          });
          return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${nome}</div>`;
        }
      },
      {
        text: "Quantidade", datafield: "quantidade", width: "12%",
        cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {

          let unidadeMedida = "";
          let quantidade = rowdata.quantidade ? rowdata.quantidade : "";

          if (this.state.medida && this.state.medida.length && rowdata.idUnidadeMedida) {
            const item = this.state.medida.find((unid) => unid.id === parseInt(rowdata.idUnidadeMedida));

            unidadeMedida = item?.nome;

            if (unidadeMedida) {
              quantidade = `${quantidade} ${unidadeMedida}`;
            }
          }

          return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${quantidade}</div>`;
        },
      },
    ];

    this.datafieldsDadosEconomicosMinerio = [
      { name: "periodoProducao", type: "string", map: "periodoProducao" },
      { name: "infoAnual", type: "string", map: "infoAnual" },
      { name: "infoAcumulada", type: "string", map: "infoAcumulada" },
      { name: "idTipoSubstancia", type: "string", map: "idTipoSubstancia" },
      {
        name: "idTipoMinerioProcessado",
        type: "string",
        map: "idTipoMinerioProcessado",
      },
      { name: "idTipoMateriaPrima", type: "string", map: "idTipoMateriaPrima" },
      { name: "quantidade", type: "number", map: "quantidade" },
      { name: "idUnidadeMedida", type: "string", map: "idUnidadeMedida" },
    ];
    this.dados = [];
    this.colunasProduto = [
      {
        text: "Substância", datafield: "idTipoSubstancia", width: "22%", cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
          let nomes = this.state.nome;
          let nome = "";
          nomes?.map(nm => {
            if (nm.id?.toString() === rowdata.idTipoSubstancia?.toString()) {
              nome = nm.nome;
            }
          });


          if(!this.dados.find(d => d.id === rowdata.idDadoEconomico))
            this.dados.push({ id:rowdata.idDadoEconomico, produtos: [nome]})
          else {
            this.dados.forEach((dado) => {
              if(dado.id === rowdata.idDadoEconomico)
                dado.produtos.push(nome);
            });
          }
          
          return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${nome}</div>`;
        }
      },
      {
        text: "Teor", datafield: "teor", width: "18%",
        cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
          let medidas = this.state.unidadeMedida;
          let nome = "";
          medidas?.map(medida => {
            if (medida.id?.toString() === rowdata.idMedidaTeor?.toString()) {
              nome = medida.teorMedio;
            }
          });
          const teor = `${rowdata.teor !== null && rowdata.teor !== undefined ? rowdata.teor : ''}${' '}${nome}`;
          return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${teor}</div>`;
        }
      },
      {
        text: "Produção", datafield: "producao", width: "14%", cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {

          let unidadeMedida = "";
          let producao = rowdata.producao ? rowdata.producao : "";

          if (this.state.medida && this.state.medida.length && rowdata.idMedidaProducao) {
            const item = this.state.medida.find((unid) => unid.id === parseInt(rowdata.idMedidaProducao));

            unidadeMedida = item?.nome;

            if (unidadeMedida) {
              producao = `${producao} ${unidadeMedida}`;
            }
          }

          return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${producao}</div>`;
        }
      },
      { text: "Recuperação da planta (%)", datafield: "recuperacao", width: "20%" },
      { text: "Recuperação de lavra (%)", datafield: "recuperacaoLavra", width: "20%" }
    ];

    this.datafieldsProduto = [
      { name: "idTipoSubstancia", type: "string", map: "idTipoSubstancia" },
      { name: "teor", type: "string", map: "teor" },
      { name: "producao", type: "number", map: "producao" },
      { name: "recuperacao", type: "number", map: "recuperacao" },
      { name: "recuperacaoLavra", type: "number", map: "recuperacaoLavra" },
      { name: "idMedidaTeor", type: "string", map: "idMedidaTeor" },
      { name: "idMedidaProducao", type: "string", map: "idMedidaProducao" },
      { name: "idDadoEconomico", type: "number", map: "idDadoEconomico" },
    ];

    this.colunasResiduoMineracao = [
      {
        text: "Tipo de resíduo", datafield: "idTipoResiduo", width: "20%", cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
          let residuos = this.state.tipoResiduo;
          let nome = "";
          residuos?.map(residuo => {
            if (residuo.id?.toString() === rowdata.idTipoResiduo?.toString()) {
              nome = residuo.nome;
            }
          });
          return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${nome}</div>`;
        }
      },
      {
        text: "Tipo de armazenamento", datafield: "idTipoArmazenamento", width: "20%", cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
          let armazenamentos = this.state.tipoArmazenamento;
          let nome = "";
          armazenamentos?.map(armazenamento => {
            if (armazenamento.id?.toString() === rowdata.idTipoArmazenamento?.toString()) {
              nome = armazenamento.nome;
            }
          });
          return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${nome}</div>`;
        }
      },
      {
        text: "Substância", datafield: "idTipoSubstancia", width: "20%",
        cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
          let substancias = this.state.nome;
          let nome = "";
          substancias?.map(substancia => {
            if (substancia?.id?.toString() === rowdata?.idTipoSubstancia?.toString()) {
              nome = substancia.nome;
            }
          });
          return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${nome}</div>`;
        }
      },
      {
        text: "Teor", datafield: "teor", width: "14%",
        cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
          let medidas = this.state.unidadeMedida;
          let nome = "";
          medidas?.map(medida => {
            if (medida.id?.toString() === rowdata.idUnidadeMedida?.toString()) {
              nome = medida.teorMedio;
            }
          });
          const teor = `${rowdata.teor !== null && rowdata.teor !== undefined ? rowdata.teor : ''}${' '}${nome}`;
          return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${teor}</div>`;
        }
      },
      {
        text: "Possui Impacto Ambiental", datafield: "possuiImpactoAmbiental", width: "20%",
        cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {

          if (rowdata.possuiImpactoAmbiental !== null && rowdata.possuiImpactoAmbiental !== undefined) {

            if (rowdata.possuiImpactoAmbiental === 0 || rowdata.possuiImpactoAmbiental === "Não" || rowdata.possuiImpactoAmbiental === "0") {
              return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">Não</div>`;
            }
            if (rowdata.possuiImpactoAmbiental >= 1) {
              return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">Sim (${rowdata.possuiImpactoAmbiental})</div>`;
            }
          }
          else {
            const atividadesMineracaoList = resolverExibidos(this.props.referenciaForm.getState().values.atividadesMineracao);

            if (!atividadesMineracaoList || !atividadesMineracaoList.length)
              return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">Não</div>`;


            let qtdImpactoAmbiental = 0;

            atividadesMineracaoList.forEach((mineracao) => {

              const residuosMineracaoList = resolverExibidos(mineracao.residuosMineracao);

              if (!residuosMineracaoList || !residuosMineracaoList.length) return;
              residuosMineracaoList.forEach((residuo) => {
                if (residuo.id === rowdata.idEditar) {

                  const impactos = resolverExibidos(residuo.impactosAmbientais);

                  if (!impactos || !impactos.length) return;

                  qtdImpactoAmbiental = impactos.length;

                }
              });
            });

            if (qtdImpactoAmbiental === 0) {
              return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">Não</div>`;
            }
            if (qtdImpactoAmbiental >= 1) {
              return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">Sim (${qtdImpactoAmbiental})</div>`;
            }
          }
        },
      },

    ];

    this.datafieldsResiduoMineracao = [
      { name: "idTipoResiduo", type: "string", map: "idTipoResiduo" },
      { name: "idTipoArmazenamento", type: "string", map: "idTipoArmazenamento" },
      { name: "idUnidadeMedida", type: "string", map: "idUnidadeMedida" },
      {
        name: "possuiImpactoAmbiental",
        type: "string",
        map: "possuiImpactoAmbiental",
      },
      { name: "complemento", type: "string", map: "complemento" },
      { name: "idTipoSubstancia", type: "string", map: "idTipoSubstancia" },
      { name: "teor", type: "string", map: "teor" },
    ];

    this.colunasImpactoAmbiental = [
      {
        text: "Impacto Ambiental", datafield: "idTipoImpactoAmbiental", width: "74%", cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
          let impactos = this.state.impactoAmbiental;
          let nome = "";
          impactos?.map(impacto => {
            if (impacto.id?.toString() === rowdata.idTipoImpactoAmbiental?.toString()) {
              nome = impacto.nome;
            }
          });
          return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${nome}</div>`;
        }
      },
      { text: "Tratamento mitigatório", datafield: "tratamentoMitigatorio", width: "20%", },
    ];

    this.datafieldsImpactoAmbiental = [
      { name: "idTipoImpactoAmbiental", type: "string", map: "idTipoImpactoAmbiental" },
      {
        name: "tratamentoMitigatorio",
        type: "string",
        map: "tratamentoMitigatorio",
      },
    ];

    /** @type {React.RefObject<import("../../../../../components/Jqx/Table").Table>} */
    this.tableRefAtividadesMineracao = React.createRef();
  }

  async componentDidMount() {
    const responses = await resolverMultiRequestRecmin({
      metodoLavra: { uri: "/metodoLavra" },
      metodoBeneficiamento: { uri: "/metodoBeneficiamento" },
      tipoMinerioProcessado: { uri: "/tipoMinerioProcessado" },
      medida: { uri: "/medida" },
      mitigacoes: { uri: "/mitigacao" },
      unidadeMedida: { uri: "/medida/teor" },
      tipoMateriaPrima: { uri: "/tipoMateriaPrima" },
      nome: { uri: "/tipoSubstancia" },
      tipoResiduo: { uri: "/tiposResiduo" },
      tipoArmazenamento: { uri: "/tipoArmazenamento" },
      impactoAmbiental: { uri: "/tipoImpactoAmbiental" },
    });

    // Se alguma das listas usada na tabela de atividades estava vazia antes,
    // redesenhamos a tabela de atividades.
    const needsTableRedraw = (this.state.metodoLavra.length === 0 && responses.metodoLavra.length > 0)
      || (this.state.metodoBeneficiamento.length === 0 && responses.metodoBeneficiamento.length > 0);

    this.setState(responses);

    if (needsTableRedraw) {
      this.tableRefAtividadesMineracao.current?.refreshItems();
    }
  }

  onSubmit({ alteracoesForm }) {
    this.limparDataInicial = false;
    this.limparDataFinal = false;

    this.setState({ limparDataInicial: false, limparDataFinal: false });

    this.props.referenciaForm.batch(() => {
      for (const [key, val] of Object.entries(alteracoesForm)) {
        this.props.referenciaForm.mutators.setValue(`${key}`, val);
      }
    });

    if (this.props.referenciaForm.getState().values.atividadesMineracao) {
      let arrayAtividaes = resolverExibidos(this.props.referenciaForm.getState().values.atividadesMineracao);
      let arrayFonteReferencia = [];
      arrayAtividaes?.map(atividade => {
        if (atividade.fontesReferencia) {
          let exibidos = resolverExibidos(atividade.fontesReferencia);
          exibidos?.map(item => {
            if (item.modoCadastro === "SIMPLIFICADO") {
              item.referencia = item.descricao
            }
            if (item.modoCadastro === "DETALHADO") {
              const autores = item.autores.split(",");
              if (autores.length === 1) item.referencia = `${autores[0].split(" ").pop()}. (${item.ano}). ${item.titulo}.`;
              if (autores.length === 2) item.referencia = `${autores[0].split(" ").pop()} e ${autores[1].split(" ").pop()}. (${item.ano}). ${item.titulo}.`;
              if (autores.length >= 3) item.referencia = `${autores[0].split(" ").pop()} et al. (${item.ano}). ${item.titulo}.`
            }
            if (item.modoCadastro === "BASE_PROJETOS" && item.idProjeto !== "") item.referencia = item.idProjeto;
            if (item.modoCadastro === "BASE_PROJETOS" && item.idProjeto === "") item.referencia = item.link;
          }
          );
          arrayFonteReferencia.push(exibidos);
        }
        return 0;
      }
      );
      this.props.atualizarTabelaFonteReferenciaRecursoMineral(arrayFonteReferencia.flat(), "ATIVIDADE_MINERACAO");
    }

  }

  onSubmitSubForm({ alteracoesForm }, form, nome) {
    form?.batch(() => {
      for (const [key, val] of Object.entries(alteracoesForm)) {
        form?.mutators?.setValue(`${key}`, val);
      }
    });

    if (nome === "possuiDadosEconomicos") {
      let dadosEconomicosMinerioExibidos = resolverExibidos(form.getState().values.dadosEconomicos);
      form?.mutators?.setValue(`possuiDadosEconomicos`,
        dadosEconomicosMinerioExibidos.length);
    }

    if (nome === "possuiResiduosMineracao") {
      let residuosMineracaoExibidos = resolverExibidos(form.getState().values.residuosMineracao);
      form?.mutators?.setValue(`possuiResiduosMineracao`,
        residuosMineracaoExibidos.length);
    }

    if (nome === "possuiImpactoAmbiental") {
      let impactoAmbientalExibido = resolverExibidos(form.getState().values.impactosAmbientais);
      form?.mutators?.setValue(`possuiImpactoAmbiental`,
        impactoAmbientalExibido.length);
    }
  }

  calcularDias(formProps) {
    if (formProps.form.getState().values.inicioAtividade && formProps.form.getState().values.terminoAtividade) {
      let inicio = dayjs(formProps.form.getState().values.inicioAtividade, 'DD/MM/YYYY');
      let termino = dayjs(formProps.form.getState().values.terminoAtividade, 'DD/MM/YYYY');
      if (inicio.isBefore(termino)) {
        formProps.form?.mutators?.setValue(`duracaoAtividade`, termino.diff(inicio, 'day'))
      } else {
        formProps.form?.mutators?.setValue(`duracaoAtividade`, null)
      }
    }
  }

  setProdutos(rowdata, atividades) {
    let idAtividade = rowdata.idEditar;
    this.dados = [];

    if(rowdata.rowData)
      idAtividade = rowdata.rowData.idEditar;

    let listaAtividades = resolverExibidos(atividades);
    let atividade = listaAtividades.find(a => a.id === idAtividade);

    if(atividade && atividade.dadosEconomicos){
      let dadosEco = resolverExibidos(atividade.dadosEconomicos);

      if(dadosEco) {
        dadosEco.forEach(dado => {
          let produtosList = [];

          if(dado && dado.produtos) {
            let produtosDado = resolverExibidos(dado.produtos);
            produtosDado.forEach(p => {
              if(p && p.idTipoSubstancia){
                let sub = this.props.substancias.find(s => s.id === p.idTipoSubstancia);
                if(sub) 
                  produtosList.push(sub.nome);
              }
            });
          }
         
          this.dados.push({ id: dado.id, produtos: produtosList });
        });
      }
    }
  }
  validaUnidade() {
    return 'O campo "Unidade de medida da quantidade" é obrigatório'
  }
  validaQuantidade() {
    return 'O campo "Quantidade" é obrigatório'
  }
  validaTipoMinerio() {
    return 'O campo "Tipo de minério processado" é obrigatório'
  }

  render() {
    return (
      <Field name={"atividadesMineracao"} subscription={{ value: true }}>
        {({ input: { value: atividadesMineracao = {} } }) =>
          <SubForm
            {...getPropsPermissao(this.props.permitirEdicao)}
            tableRef={this.tableRefAtividadesMineracao}
            keepDirtyOnReinitialize
            tituloForm="Atividade de mineração"
            nome="atividadesMineracao"
            onSubmit={this.onSubmit.bind(this)}
            onBtnEditClick={(event, table, rowdata) => this.setProdutos.bind(this)(rowdata, atividadesMineracao)}
            colunas={this.colunasAtividadeMineracao}
            campos={this.dataFieldAtividadeMineracao}
            validar={(valores) => this.props.modoDefinitivo ? validacoesAtividadeMineracao(valores) : undefined}
            elementos={
              atividadesMineracao
            }
            naoValidarCamposVoltar={[
              "duracaoAtividade"
            ]}
            valoresIniciais={{
              resultadoMetodoLavra: [],
              tipoDataInicioAtividade: 'CONHECIDO',
              tipoDataTerminoAtividade: 'CONHECIDO',
              tipoDataPeriodoProducao: 'ACUMULADA',
              tipoDataPeriodoProducao: 'ANUAL'
            }}
            formSubscription={{}}
            renderForm={({ formProps }) => {
              return (
                <>
                  <Col md={12} className="p-0 mt-3">
                    <Field
                      name="possuiDadosEconomicos"
                      component={HiddenField}
                      initialValue={0}
                    />
                  </Col>
                  <Col md={12} className="p-0 mt-3">
                    <Field
                      name="possuiResiduosMineracao"
                      component={HiddenField}
                      initialValue={0}
                    />
                  </Col>
                  <Row>
                    <Col md={12}>
                      <Card.Title>Atividade de mineração</Card.Title>
                    </Col>
                  </Row>

                  <FormSpy subscription={{ values: true }}>
                    {({ values }) =>

                      <Row>
                        <Field
                          component={HiddenField}
                          name={`resultadoMetodoLavra`}
                        />
                        <Col md={6}>
                          <Field
                            disabled={!this.props.permitirEdicao}
                            name="idMetodoLavra"
                            component={DropdownListField}
                            label="Método de lavra"
                            dica="Informe, a partir da biblioteca, o método de lavra utilizado na Mina ou Garimpo visitado"
                            required={this.props.modoDefinitivo}
                            initialValue=""
                            elementos={this.state.metodoLavra}
                            validate={validarSe(this.props.modoDefinitivo, campoObrigatorioComMsgGenerica(
                              "Método de lavra"
                            ))}
                          />
                        </Col>
                        <Col md={6}>
                          <Field name="idMetodoBeneficiamento" subscription={{ value: true }}>
                            {({ input: { value: idMetodoBeneficiamento } }) => {

                              return (
                                <Field
                                  disabled={!this.props.permitirEdicao}
                                  name="idMetodoBeneficiamento"
                                  component={DropdownListField}
                                  label="Método de beneficiamento"
                                  dica="Informe, a partir da biblioteca, o método de beneficiamento utilizado na Mina ou Garimpo visitado"
                                  elementos={this.state.metodoBeneficiamento}
                                  required={this.props.modoDefinitivo}
                                  validate={validarSe(this.props.modoDefinitivo, campoObrigatorioComMsgGenerica(
                                    "Método de beneficiamento"
                                  ))}
                                />
                              );
                            }}
                          </Field>

                        </Col>
                      </Row>
                    }

                  </FormSpy>

                  <Row className="mt-4">
                    <Col md={4}>
                      <Field name={"tipoDataInicioAtividade"} subscription={{ value: true }}>
                        {({ input: { value: tipoDataInicioAtividade } }) => {
                          return <Field
                            disabled={!this.props.permitirEdicao}
                            label="Início da atividade"
                            required={this.props.modoDefinitivo}
                            dica="Informe, se possível, a data de início da atividade de mineração desenvolvida na Mina ou Garimpo visitado"
                            horizontal
                            component={
                              RadioButtonSetField
                            }
                            name="tipoDataInicioAtividade"
                            elementos={
                              this.state
                                .classificacaoData
                            }
                            initialValue={
                              this.state
                                .classificacaoData[0]
                                .id
                            }
                            onChange={() => {
                              if (formProps.form.getState().values?.tipoDataInicioAtividade === 'INDETERMINADO') {
                                formProps.form.mutators.setValue('inicioAtividade', null);
                                formProps.form.mutators.setValue('duracaoAtividade', null);
                              }
                            }}
                          />

                        }}
                      </Field>

                    </Col>
                    <Col md={4}>
                      <Field name={"tipoDataTerminoAtividade"} subscription={{ value: true }}>
                        {({ input: { value: tipoDataTerminoAtividade } }) => {
                          return <Field
                            disabled={!this.props.permitirEdicao}
                            label="Término da atividade"
                            dica="Informe, se possível, a data de término da atividade de mineração desenvolvida na Mina ou Garimpo visitado"
                            horizontal
                            required={this.props.modoDefinitivo}
                            component={
                              RadioButtonSetField
                            }
                            name="tipoDataTerminoAtividade"
                            elementos={
                              this.state
                                .classificacaoData
                            }
                            initialValue={
                              this.state
                                .classificacaoData[0]
                                .id
                            }
                            onChange={() => {
                              if (formProps.form.getState().values?.tipoDataTerminoAtividade === 'INDETERMINADO') {
                                formProps.form.mutators.setValue('terminoAtividade', null);
                                formProps.form.mutators.setValue('duracaoAtividade', null);
                              }
                            }}
                          />
                        }}
                      </Field>

                    </Col>
                  </Row>

                  <FormSpy subscription={{ values: true }}>
                    {({ values }) => {
                      return (
                        <Row>
                          <Col md={4}>
                            {formProps.form.getState().values.tipoDataInicioAtividade !== "INDETERMINADO" &&
                              <Field
                                component={DateTimeInputField}
                                disabled={!this.props.permitirEdicao}
                                name="inicioAtividade"
                                initialValue=""
                                label={` `}
                                validate={validarSe(this.props.modoDefinitivo, campoObrigatorioComMsgGenerica(
                                  "Início da atividade"
                                ))}
                                onBlur={
                                  this.calcularDias(formProps)
                                }
                              />
                            }
                            {formProps.form.getState().values.tipoDataInicioAtividade === "INDETERMINADO" &&
                              <Field
                                component={DateTimeInputField}
                                disabled={formProps.form.getState().values?.tipoDataInicioAtividade === "INDETERMINADO" ?? !this.props.permitirEdicao}
                                name="inicioAtividade"
                                initialValue=""
                                label={` `}
                                onBlur={
                                  this.calcularDias(formProps)
                                }
                              />
                            }
                          </Col>



                          <Col md={4}>
                            {formProps.form.getState().values.tipoDataTerminoAtividade !== "INDETERMINADO" &&
                              <Field
                                component={DateTimeInputField}
                                disabled={!this.props.permitirEdicao}
                                name="terminoAtividade"
                                initialValue=""
                                label={` `}
                                validate={validarSe(this.props.modoDefinitivo, campoObrigatorioComMsgGenerica(
                                  "Término da atividade"
                                ))}
                                onBlur={
                                  this.calcularDias(formProps)
                                }
                              />
                            }
                            {formProps.form.getState().values.tipoDataTerminoAtividade === "INDETERMINADO" &&
                              <Field
                                component={DateTimeInputField}
                                disabled={formProps.form.getState().values?.tipoDataTerminoAtividade === "INDETERMINADO" ?? !this.props.permitirEdicao}
                                name="terminoAtividade"
                                initialValue=""
                                label={` `}
                                onBlur={
                                  this.calcularDias(formProps)
                                }
                              />
                            }
                          </Col>
                          <Col md={4}>
                            <Field
                              disabled
                              component={TextInputField}
                              name="duracaoAtividade"
                              label="Duração da atividade (dias)"
                              dica="Informe a duração, em número de dias, da atividade de mineração na Mina ou Garimpo visitado"
                              initialValue=""
                            />
                          </Col>
                          <Col md={12}>
                            <Field
                              component={TextAreaField}
                              name={`informacoesRelevantes`}
                              label="Informações relevantes"
                              dica="Caso necessário, detalhe as atividades de mineração observadas"
                              maxLength={250}
                              disabled={!this.props.permitirEdicao}
                            />
                          </Col>
                        </Row>
                      )

                    }}
                  </FormSpy>

                  <Card className="mt-3">
                    <Card.Body>
                      <Row>
                        <Col md={12}>
                          <Field name={"dadosEconomicos"} subscription={{ value: true }}>
                            {({ input: { value: dadosEconomicos = {} } }) =>
                              <SubForm
                                keepDirtyOnReinitialize
                                {...getPropsPermissao(this.props.permitirEdicao)}
                                tituloForm="Dados econômicos do minério"
                                nome="dadosEconomicos"
                                onSubmit={(alteracoesForm) => this.onSubmitSubForm(alteracoesForm, formProps.form, "possuiDadosEconomicos")}
                                colunas={this.colunasDadosEconomicosMinerio}
                                campos={this.datafieldsDadosEconomicosMinerio}
                                elementos={
                                  dadosEconomicos
                                }
                                formSubscription={{}}
                                renderForm={({ formProps }) => {
                                  return (
                                    <>
                                      <Row>
                                        <Col md={12}>
                                          <Card.Title>Dados econômicos do minério</Card.Title>
                                        </Col>
                                      </Row>
                                      <fieldset className="border p-2">
                                        {<legend className="w-auto small" style={{ margin: 0, fontSize: '16px' }}>
                                          {'Produto bruto'}
                                          {<Hint textoAjuda={'Considere, para fins de cadastramento nessa parte do formulário, a substância bruta produzida e recuperada na frente de lavra da Mina ou Garimpo visitado'} />}
                                        </legend>}
                                        <Field name={"idTipoMinerioProcessado"} subscription={{ value: true }}>
                                          {({ input: { value: idTipoMinerioProcessado = [] } }) => {
                                            let camposObrigatorios = false; 
                                            let minerios = this.state.tipoMinerioProcessado;
                                            let nome = "";
                                            minerios?.map(minerio => {
                                              if (minerio.id?.toString() === idTipoMinerioProcessado.toString()) {
                                                nome = minerio.nome;
                                              }
                                            });
                                            if (nome === "Não especificado"){
                                              camposObrigatorios = true;
                                            }else{
                                              camposObrigatorios = false
                                            }

                                            let tipoPreenchido = false;
                                            if(idTipoMinerioProcessado){
                                              tipoPreenchido = true;
                                            }
                                            return (
                                              <>
                                                <Row>
                                                  <Col md={4}>
                                                    <Field
                                                      disabled={!this.props.permitirEdicao}
                                                      name="idTipoMinerioProcessado"
                                                      component={DropdownListField}
                                                      label="Tipo de minério processado"
                                                      dica="Informe, a partir da biblioteca, o tipo de minério levado para o beneficiamento na Mina ou Garimpo visitado"
                                                      initialValue=""
                                                      elementos={this.state.tipoMinerioProcessado}
                                                      required={this.props.modoDefinitivo}
                                                    />
                                                  </Col>
                                                  <Col md={4}>
                                                    <Field
                                                      disabled={!this.props.permitirEdicao}
                                                      name="quantidade"
                                                      component={NumberInputField}
                                                      permitirNegativos={false}
                                                      label="Quantidade"
                                                      dica="Informe a quantidade de minério enviado para o benefiamento, durante o período considerado, na Mina ou Garimpo visitado"
                                                      initialValue=""
                                                      casasDecimais={3}
                                                      casasInteiros={9}
                                                      required={this.props.modoDefinitivo && !camposObrigatorios}
                                                    />
                                                  </Col>
                                                  <Col md={4}>
                                                    <Field
                                                      disabled={!this.props.permitirEdicao}
                                                      name="idUnidadeMedida"
                                                      component={DropdownListField}
                                                      label="Unidade de medida da quantidade"
                                                      dica="Informe a unidade de medida referente ao minério enviado para o benefiamento, durante o período considerado, na Mina ou Garimpo visitado"
                                                      initialValue=""
                                                      elementos={this.state.medida}
                                                      required={this.props.modoDefinitivo && !camposObrigatorios}
                                                    />
                                                  </Col>
                                                </Row>
                                                <Row>
                                                  <Col md={4}>{(this.props.modoDefinitivo && !tipoPreenchido) &&
                                                    <Field
                                                      name="validaTipoMinerio"
                                                      component={HiddenField}
                                                      validate={this.validaTipoMinerio}
                                                    />
                                                  }</Col>
                                                  <Field name={"quantidade"} subscription={{ value: true }}>
                                                    {({ input: { value: quantidade = [] } }) => {
                                                      return (
                                                        <Col md={4}>{(this.props.modoDefinitivo && !camposObrigatorios && !quantidade) &&
                                                          <Field
                                                            name="validaQuantidade"
                                                            component={HiddenField}
                                                            validate={this.validaQuantidade}
                                                          />
                                                        }</Col>
                                                      )
                                                    }}</Field>
                                                  <Field name={"idUnidadeMedida"} subscription={{ value: true }}>
                                                    {({ input: { value: idUnidadeMedida = [] } }) => {
                                                      return (
                                                        <Col md={4}>{(this.props.modoDefinitivo && !camposObrigatorios && !idUnidadeMedida) &&
                                                          <Field
                                                            name="validaUnidade"
                                                            component={HiddenField}
                                                            validate={this.validaUnidade}
                                                          />
                                                        }</Col>
                                                      )
                                                    }}</Field>
                                                </Row>
                                                <Row>
                                                  <Col md={4}>
                                                    <Field
                                                      disabled={!this.props.permitirEdicao}
                                                      name="idTipoMateriaPrima"
                                                      component={DropdownListField}
                                                      label="Tipo de matéria-prima"
                                                      dica="Informe, a partir da biblioteca, o tipo de matéria prima enviado para beneficiamento na Mina ou Garimpo visitado"
                                                      initialValue=""
                                                      elementos={this.state.tipoMateriaPrima}
                                                    />
                                                  </Col>
                                                  <Col md={4}>
                                                    <Field
                                                      disabled={!this.props.permitirEdicao}
                                                      name="relacaoEsterilMinerio"
                                                      component={NumberInputField}
                                                      permitirNegativos={false}
                                                      label="Relação estéril minério"
                                                      dica="Informe a relação estéril/minério na Mina ou Garimpo visitado"
                                                      initialValue=""
                                                      casasDecimais={3}
                                                      casasInteiros={9}
                                                    />
                                                  </Col>
                                                </Row>
                                              </>
                                            )
                                          }}</Field>
                                      </fieldset>

                                      <Row>
                                        <Col md={4}>
                                          <Field name={"periodoProducao"} subscription={{ value: true }}>
                                            {({ input: { value: periodoProducao } }) => {
                                              return <Field
                                                disabled={!this.props.permitirEdicao}
                                                label="Período da produção"
                                                required={this.props.modoDefinitivo}
                                                horizontal
                                                component={
                                                  RadioButtonSetField
                                                }
                                                name="periodoProducao"
                                                elementos={
                                                  this.state
                                                    .periodoProducao
                                                }
                                                validate={validarSe(this.props.modoDefinitivo, campoObrigatorioComMsgGenerica(
                                                  "Período da produção"
                                                ))}
                                                onChange={() => {
                                                  if (formProps.form.getState().values?.periodoProducao === 'ACUMULADA') {
                                                    formProps.form.mutators.setValue('infoAnual', null);
                                                  }
                                                  if (formProps.form.getState().values?.periodoProducao === 'ANUAL') {
                                                    formProps.form.mutators.setValue('infoAcumulada', null);
                                                  }
                                                }}
                                              />
                                            }}
                                          </Field>
                                        </Col>
                                        <FormSpy subscription={{ values: true }}>
                                          {({ values }) => {
                                            return (
                                              <Col md={4}>
                                                {formProps.form.getState().values.periodoProducao === "ANUAL" &&
                                                  <Field
                                                    component={YearInputField}
                                                    disabled={formProps.form.getState().values?.periodoProducao === "ACUMULADA" ?? !this.props.permitirEdicao}
                                                    name="infoAnual"
                                                    label="Ano da produção"
                                                    valorInicial={1960}
                                                    dica="Ano da produção"
                                                    valorFinal={new Date().getUTCFullYear()}
                                                    required={this.props.modoDefinitivo}
                                                    validate={validarSe(this.props.modoDefinitivo, campoObrigatorioComMsgGenerica(
                                                      "Ano da produção"
                                                    ))}
                                                  />
                                                }
                                                {formProps.form.getState().values.periodoProducao === "ACUMULADA" &&
                                                  <Field
                                                    component={YearInputField}
                                                    maxLength={4}
                                                    disabled={formProps.form.getState().values?.periodoProducao === "ANUAL" ?? !this.props.permitirEdicao}
                                                    name="infoAcumulada"
                                                    valorFinal={new Date().getUTCFullYear()}
                                                    valorInicial={1960}
                                                    required={this.props.modoDefinitivo}
                                                    label={`Ano Final da produção`}
                                                    dica="Ano Final da produção"
                                                    validate={validarSe(this.props.modoDefinitivo, campoObrigatorioComMsgGenerica(
                                                      "Ano Final da produção"
                                                    ))}
                                                  />
                                                }
                                              </Col>
                                            )
                                          }}
                                        </FormSpy>

                                        <Col md={4}>
                                          <Field
                                            disabled={!this.props.permitirEdicao}
                                            component={RadioField}
                                            name="data"
                                            campo="data"
                                            label="Data da obtenção da informação"
                                            modoDefinitivo={this.props.modoDefinitivo}
                                            tipoCampo={DateTimeInputField}
                                            colunas={3}
                                            max={this.props.dataCriacao}
                                            exibirAsterisco={this.props.modoDefinitivo}
                                            dica="Informe, se possível, a data de obtenção da informação cadastrada"
                                          />
                                        </Col>

                                      </Row>
                                      <Card className="mb-3 mt-3">
                                        <Card.Body>
                                          <Row>
                                            <Col md={12}>
                                              <Field name={"produtos"} subscription={{ value: true }}>
                                                {({ input: { value: produtos = {} } }) =>
                                                  <SubForm
                                                    {...getPropsPermissao(this.props.permitirEdicao)}
                                                    tituloForm="Produto Beneficiado"
                                                    nome="produtos"
                                                    onSubmit={(alteracoesForm) => this.onSubmitSubForm(alteracoesForm, formProps?.form)}
                                                    colunas={this.colunasProduto}
                                                    campos={this.datafieldsProduto}
                                                    elementos={
                                                      produtos
                                                    }
                                                    validar={(valores) => this.props.modoDefinitivo ? comporValidadores(valorSemUnidade)(valores) : undefined}
                                                    formSubscription={{}}
                                                    renderForm={() => {
                                                      return (
                                                        <>
                                                          <Field name="idDadoEconomico">
                                                            {({ input: idDadoEconomico }) => {
                                                                let valorInicial = formProps?.form?.getState()?.values?.id;

                                                                return <Field
                                                                name="idDadoEconomico"
                                                                component={HiddenField}
                                                                initialValue={valorInicial}
                                                              />
                                                            }}
                                                          </Field>
                                                          <Row>
                                                            <Col md={12}>
                                                              <Card.Title>Produto Beneficiado</Card.Title>
                                                            </Col>
                                                          </Row>
                                                          <Row className="mb-4">
                                                            <Col md={6}>
                                                              <Field
                                                                disabled={!this.props.permitirEdicao}
                                                                name="idTipoSubstancia"
                                                                component={DropdownListField}
                                                                label="Substância"
                                                                dica="Informe, a partir da biblioteca, a substância produzida no beneficiamento"
                                                                initialValue=""
                                                                elementos={this.props.substancias.sort((a, b) => a.nome.localeCompare(b.nome))}
                                                                required={this.props.modoDefinitivo}
                                                                validate={validarSe(this.props.modoDefinitivo, campoObrigatorioComMsgGenerica("Substância"))}
                                                              />
                                                            </Col>
                                                            <Col md={3}>
                                                              <Field
                                                                disabled={!this.props.permitirEdicao}
                                                                name="producao"
                                                                component={NumberInputField}
                                                                permitirNegativos={false}
                                                                label="Produção"
                                                                dica="Informe, se possível, a produção do processo de beneficiamento observado na Mina ou Garimpo visitado"
                                                                initialValue=""
                                                                casasDecimais={3}
                                                                casasInteiros={9}
                                                              />
                                                            </Col>
                                                            <Col md={3}>
                                                              <Field
                                                                disabled={!this.props.permitirEdicao}
                                                                name="idMedidaProducao"
                                                                component={DropdownListField}
                                                                label="Unidade de medida da produção"
                                                                dica="Informe, a partir da biblioteca, a unidade de medida do produto beneficiado na Mina ou Garimpo visitado"
                                                                initialValue=""
                                                                elementos={this.state.medida}
                                                              />
                                                            </Col>
                                                            <Col md={3}>
                                                              <Field
                                                                disabled={!this.props.permitirEdicao}
                                                                name="teor"
                                                                component={NumberInputField}
                                                                permitirNegativos={false}
                                                                label="Teor"
                                                                dica="Informe o teor do produto resultante do processo de beneficiamento cadastrado na Mina ou Garimpo visitado"
                                                                initialValue=""
                                                                casasDecimais={3}
                                                                casasInteiros={9}
                                                              />
                                                            </Col>
                                                            <Col md={3}>
                                                              <Field
                                                                disabled={!this.props.permitirEdicao}
                                                                name="idMedidaTeor"
                                                                component={DropdownListField}
                                                                label="Unidade de medida do teor"
                                                                dica="Informe, a partir da biblioteca, a unidade do teor para o produto beneficiado na Mina ou Garimpo visitado"
                                                                initialValue=""
                                                                displayMember={"teorMedio"}
                                                                elementos={this.state.unidadeMedida}
                                                              />
                                                            </Col>
                                                            <Col md={3}>
                                                              <Field
                                                                disabled={!this.props.permitirEdicao}
                                                                name="recuperacao"
                                                                component={NumberInputField}
                                                                permitirNegativos={false}
                                                                label="Recuperação da planta (%)"
                                                                dica="Informe, se possível, a eficiência da planta de beneficiamento cadastrada para a Mina ou Garimpo visitado"
                                                                initialValue=""
                                                                valorMaximo={100}
                                                                casasDecimais={4}
                                                                casasInteiros={3}
                                                              />
                                                            </Col>
                                                            <Col md={3}>
                                                              <Field
                                                                disabled={!this.props.permitirEdicao}
                                                                name="recuperacaoLavra"
                                                                component={NumberInputField}
                                                                permitirNegativos={false}
                                                                label="Recuperação de lavra (%)"
                                                                dica="Informe, se possível, a eficiência do método de lavra cadastrado para a Mina ou Garimpo visitado"
                                                                initialValue=""
                                                                valorMaximo={100}
                                                                casasDecimais={4}
                                                                casasInteiros={3}
                                                              />
                                                            </Col>
                                                          </Row>
                                                        </>
                                                      );
                                                    }}
                                                  />
                                                }
                                              </Field>
                                            </Col>
                                          </Row>
                                        </Card.Body>
                                      </Card>

                                    </>
                                  );
                                }}
                              />
                            }
                          </Field>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                  <Card className="mt-3">
                    <Card.Body>

                      <Row>
                        <Col md={12}>
                          <Field name={"residuosMineracao"} subscription={{ value: true }}>
                            {({ input: { value: residuosMineracao = {} } }) =>
                              <SubForm
                                keepDirtyOnReinitialize
                                {...getPropsPermissao(this.props.permitirEdicao)}
                                tituloForm="Resíduo de mineração"
                                nome="residuosMineracao"
                                onSubmit={(alteracoesForm) => this.onSubmitSubForm(alteracoesForm, formProps.form, "possuiResiduosMineracao")}
                                colunas={this.colunasResiduoMineracao}
                                campos={this.datafieldsResiduoMineracao}
                                elementos={
                                  residuosMineracao
                                }
                                validar={(valores) => this.props.modoDefinitivo ? comporValidadores(validacoesCamposRelacionadosAtividadeMineracao)(valores) : undefined}
                                formSubscription={{}}
                                renderForm={({ formProps }) => {
                                  return (
                                    <>
                                      <Col>
                                        <Field
                                          name="possuiImpactoAmbiental"
                                          component={HiddenField}
                                          initialValue={"Não"}
                                        />
                                      </Col>
                                      <Row>
                                        <Col md={12}>
                                          <Card.Title>Resíduo de mineração</Card.Title>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col md={4}>
                                          <Field
                                            disabled={!this.props.permitirEdicao}
                                            name="idTipoResiduo"
                                            component={DropdownListField}
                                            label="Tipo de resíduo"
                                            dica="Informe, a partir da biblioteca, o tipo de resíduo produzido na atividade de mineração empregada na Mina ou Garimpo visitado"
                                            initialValue=""
                                            elementos={this.state.tipoResiduo}
                                          />
                                        </Col>
                                        <Col md={4}>
                                          <Field
                                            disabled={!this.props.permitirEdicao}
                                            name="idTipoArmazenamento"
                                            component={DropdownListField}
                                            label="Tipo de armazenamento"
                                            dica="Informe, a partir da biblioteca, o tipo de armazenamento de resíduo de mineração utilizado na Mina ou Garimpo visitado"
                                            initialValue=""
                                            elementos={this.state.tipoArmazenamento}
                                          />
                                        </Col>
                                        <Col md={4}>
                                          <Field
                                            disabled={!this.props.permitirEdicao}
                                            name="idTipoSubstancia"
                                            component={DropdownListField}
                                            label="Substância"
                                            dica="Informe, a partir da biblioteca, a substância presente no resíduo de mineração, se houver"
                                            initialValue=""
                                            elementos={this.state.nome}
                                            required={this.props.modoDefinitivo}
                                            validate={validarSe(this.props.modoDefinitivo, campoObrigatorioComMsgGenerica("Substância"))}
                                          />
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col md={4}>
                                          <Field
                                            disabled={!this.props.permitirEdicao}
                                            name="teor"
                                            component={NumberInputField}
                                            permitirNegativos={false}
                                            label="Teor"
                                            dica="Informe o teor da substância identificada e cadastrada para o rejeito observado na Mina ou Garimpo visitado"
                                            initialValue=""
                                            casasDecimais={3}
                                            casasInteiros={9}
                                            required={this.props.modoDefinitivo}
                                            validate={validarSe(this.props.modoDefinitivo, campoObrigatorioComMsgGenerica("Teor"))}
                                          />
                                        </Col>
                                        <Col md={4}>
                                          <Field
                                            disabled={!this.props.permitirEdicao}
                                            name="idUnidadeMedida"
                                            component={DropdownListField}
                                            displayMember={'teorMedio'}
                                            label="Unidade de medida do teor"
                                            dica="Informe, a partir da biblioteca, a unidade de medida para o teor informado"
                                            initialValue=""
                                            elementos={this.state.unidadeMedida}
                                            required={this.props.modoDefinitivo}
                                            validate={validarSe(this.props.modoDefinitivo, campoObrigatorioComMsgGenerica("Unidade de medida do teor"))}
                                          />
                                        </Col>
                                      </Row>
                                      <Row className="mt-4">
                                        <Col md={12}>
                                          <Field
                                            disabled={!this.props.permitirEdicao}
                                            component={TextAreaField}
                                            name="complemento"
                                            label="Informações relevantes"
                                            dica="Detalhe as informações do resíduo de mineração da maneira mais completa e sucinta"
                                            maxLength={255}
                                          />
                                        </Col>
                                      </Row>
                                      <Card className="mt-3">
                                        <Card.Body>
                                          <Row className="mt-4">
                                          <Col md={12}>
                                            <Field name={"impactosAmbientais"} subscription={{ value: true }}>
                                              {({ input: { value: impactosAmbientais = {} } }) =>
                                                <SubForm
                                                  {...getPropsPermissao(this.props.permitirEdicao)}
                                                  tituloForm="Impacto ambiental"
                                                  nome="impactosAmbientais"
                                                  onSubmit={(alteracoesForm) => this.onSubmitSubForm(alteracoesForm, formProps.form, "possuiImpactoAmbiental")}
                                                  colunas={this.colunasImpactoAmbiental}
                                                  campos={this.datafieldsImpactoAmbiental}
                                                  elementos={
                                                    impactosAmbientais
                                                  }
                                                  formSubscription={{}}
                                                  renderForm={({ formProps }) => {
                                                    return (
                                                      <>
                                                        <Row>
                                                          <Col md={12}>
                                                            <Card.Title>Impacto ambiental</Card.Title>
                                                          </Col>
                                                        </Row>
                                                        <Row>
                                                          <Col md={12}>
                                                            <Container fluid className="p-0">
                                                              <Row>
                                                                <Col md={12}>
                                                                  <legend className="w-auto small">
                                                                    Tratamento mitigatório?
                                                                  </legend>
                                                                </Col>
                                                              </Row>

                                                              <Row className="mb-3">
                                                                <Col>
                                                                  <Field name={"tratamentoMitigatorio"} subscription={{ value: true }}>
                                                                    {({ input: tratamentoMitigatorio }) => {
                                                                      console.log('Tratamento mitigatório', tratamentoMitigatorio.value)
                                                                      let exibir = false;
                                                                      if (tratamentoMitigatorio && tratamentoMitigatorio.value) {
                                                                        exibir = tratamentoMitigatorio.value === 'SIM' ? true : false;
                                                                      }
                                                                      return <>

                                                                        <Row>
                                                                          <Col md={3}>
                                                                            <Field
                                                                              disabled={!this.props.permitirEdicao}
                                                                              component={
                                                                                RadioButtonSetField
                                                                              }
                                                                              name="tratamentoMitigatorio"
                                                                              dica={"Informe se foi observado algum processo mitigatório para os impactos ambientais produzidos pela Mina ou Garimpo visitado"}
                                                                              elementos={
                                                                                this.state
                                                                                  .tratamentoMitigatorio
                                                                              }
                                                                              initialValue={"NAO"}
                                                                            />
                                                                          </Col>
                                                                          <Col md={4}>
                                                                            <Field
                                                                              disabled={!this.props.permitirEdicao}
                                                                              name="idTipoImpactoAmbiental"
                                                                              component={DropdownListField}
                                                                              label="Impacto Ambiental"
                                                                              dica="Informe, a partir da biblioteca, o tipo de impacto ambiental observado na Mina ou Garimpo visitado"
                                                                              initialValue=""
                                                                              required={this.props.modoDefinitivo}
                                                                              elementos={
                                                                                this.state.impactoAmbiental
                                                                              }
                                                                              validate={validarSe(this.props.modoDefinitivo, campoObrigatorioComMsgGenerica(
                                                                                "Impacto ambiental"
                                                                              ))}
                                                                            />
                                                                          </Col>
                                                                          <Col md={5}>
                                                                            {
                                                                              exibir ?
                                                                                <Field
                                                                                  disabled={!this.props.permitirEdicao}
                                                                                  name="idTratamentosMitigatorios"
                                                                                  component={DropdownListField}
                                                                                  label="Tratamento mitigatório"
                                                                                  dica="Informe se foi observado algum processo mitigatório para os impactos ambientais produzidos pela Mina ou Garimpo visitado"
                                                                                  initialValue=""
                                                                                  required={this.props.modoDefinitivo}
                                                                                  elementos={
                                                                                    this.state.mitigacoes
                                                                                  }
                                                                                  validate={validarSe(this.props.modoDefinitivo, campoObrigatorioComMsgGenerica(
                                                                                    "Tratamento mitigatório"
                                                                                  ))}
                                                                                  checkboxes={true}
                                                                                  multiple
                                                                                /> : <></>
                                                                            }
                                                                          </Col>
                                                                        </Row>


                                                                      </>
                                                                    }}
                                                                  </Field>
                                                                </Col>
                                                              </Row>
                                                            </Container>
                                                          </Col>

                                                        </Row>
                                                      </>
                                                    );
                                                  }}
                                                />
                                              }</Field>
                                          </Col>
                                          </Row>
                                        </Card.Body>
                                      </Card>
                                    </>
                                  );
                                }}
                              />
                            }

                          </Field>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                  <Row>
                    <Col md={12} className="mt-3">
                      <FonteReferencia
                        permitirEdicao={this.props.permitirEdicao}
                        formPai={formProps}
                        mutators={{ setValue }}
                        origem={ATIVIDADE_MINERACAO}
                        modoDefinitivo={this.props.modoDefinitivo}
                      />
                    </Col>
                  </Row>
                </>
              );
            }}
          />
        }
      </Field>
    );
  }
}

export default AtividadeMineracao;
