import { PureComponent } from "react";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { AmostraSubFormPart } from "../components/AmostraSubFormPart";
import {
  amostraDataFieldsCommon,
  AmostraSymbol,
  amostrasLabelsMap,
} from "../constants/amostras.constant";
import { GrauIntemperismoField } from "../components/GrauIntemperismoField";
import { AmbienteCrostaField } from "../components/AmbienteCrostaField";
import { ComposicaoCrostaField } from "../components/ComposicaoCrostaField";
import { DecomposicaoCrostaField } from "../components/DecomposicaoCrostaField";
import { ImagensAmostraSubForm } from "../components/ImagensAmostraSubForm";
import { CrostaSampleGeoquimicaModelLike } from "../../../../../models/activity-geoquimica.model";
import { AmostraGridSubForm } from "../components/AmostraGridSubForm";

export interface CrostaSectionFormProps {
  prefixoNome: string;
  items?: CrostaSampleGeoquimicaModelLike[];
  onItemsChanged?: (items: CrostaSampleGeoquimicaModelLike[]) => void;
}

export interface CrostaSectionFormState {}

export class CrostaSectionForm extends PureComponent<
  CrostaSectionFormProps,
  CrostaSectionFormState
> {
  datafieldsCrosta = [
    ...amostraDataFieldsCommon,
    {
      name: "id_grau_intemperismo_crosta",
      type: "number",
      map: "id_grau_intemperismo_crosta",
    },
    {
      name: "id_ambiente_crosta",
      type: "number",
      map: "id_ambiente_crosta",
    },
    {
      name: "id_composicao_crosta",
      type: "number",
      map: "id_composicao_crosta",
    },
    {
      name: "id_decomposicao_crosta",
      type: "number",
      map: "id_decomposicao_crosta",
    },
  ];

  amostraSymbol = AmostraSymbol.Crosta;

  constructor(props) {
    super(props);
    this.state = {};
  }

  renderForm = ({ editando, prefixoNome }) => {
    return (
      <>
        <AmostraSubFormPart
          prefixoNome={prefixoNome}
          amostraSymbol={this.amostraSymbol}
          profundidadeMinRequired={true}
          profundidadeMaxRequired={true}
        />
        <Row className="my-3">
          <Col>
            <GrauIntemperismoField
              prefixoNome={prefixoNome}
              name="id_grau_intemperismo_crosta"
              required={true}
            />
          </Col>
          <Col>
            <AmbienteCrostaField
              prefixoNome={prefixoNome}
              name="id_ambiente_crosta"
              required={true}
            />
          </Col>
          <Col>
            <ComposicaoCrostaField
              prefixoNome={prefixoNome}
              name="id_composicao_crosta"
              required={true}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <DecomposicaoCrostaField
              prefixoNome={prefixoNome}
              name="id_decomposicao_crosta"
              required={true}
            />
          </Col>
        </Row>
        <Row className="my-3">
          <Col>
            <ImagensAmostraSubForm prefixoNome={prefixoNome} />
          </Col>
        </Row>
      </>
    );
  };

  render() {
    return (
      <>
        <Card className="my-3">
          <Card.Body>
            <Card.Subtitle>
              {amostrasLabelsMap.get(this.amostraSymbol)}
            </Card.Subtitle>
            <AmostraGridSubForm
              name="amostraCrosta"
              datafields={this.datafieldsCrosta}
              items={this.props.items}
              onItemsChanged={this.props.onItemsChanged}
              renderForm={this.renderForm}
            />
          </Card.Body>
        </Card>
      </>
    );
  }
}
