import React, { useEffect, useState } from "react";
import Card from "../../../../components/card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Field, useField, useForm, FormSpy } from "react-final-form";
import { RadioButtonWithFieldSetField } from "../../../../components/field/RadioButtonSetField";
import { campoObrigatorioComMsgGenerica, validarSe, campoObrigatorioDefinitivoComMsgGenerica } from "../../../../util/validadores";
import PontosGPSAfloramento from "./PontosGPSAfloramento";
import { DropdownListField } from "../../../../components/field/DropdownListField";
import { TextInputField } from "../../../../components/field/TextInputField";
import { NumberInputField } from "../../../../components/field/NumberInputField";
import { TextAreaField } from "../../../../components/field/TextAreaField";
import InformacoesCoordenadas from "../mapa/InformacoesCoordenadas";
import DadosAfloramento from "./DadosAfloramento";
import { TIPO_CADASTRO_ATIVIDADE_DEFINITIVO, TIPO_CADASTRO_ATIVIDADE_RASCUNHO } from "../../../../util/constantes";
import Service from "../../../../service/Service";
import { resolverRequestBaseGeo } from "../amostras/AmostrasUtils/AmostraRequestUtils";

import { configMapaAfloramento } from '../../../../config/mapa';

import { resolverExibidos } from '../../../../components/SubForm/SubForm';
import { useDispatch } from "react-redux";
import { mostrarNotificacao } from "../../../../reducers/notificacaoReducer";

const modosCadastroAtividade = [
  TIPO_CADASTRO_ATIVIDADE_RASCUNHO,
  TIPO_CADASTRO_ATIVIDADE_DEFINITIVO,
]


const EstaEmModoDefinitivo = ({ children, prefixoNome }) => {
  const modoCadastro = useField(`${prefixoNome}modoCadastro`, {
    subscription: { value: true },
  })
  return children(TIPO_CADASTRO_ATIVIDADE_DEFINITIVO.id === modoCadastro.input.value)
}

const AtividadeAfloramento = ({
  prefixoNome,
  permitirEdicao,
  editando,
  idEstacao,
  pontosAtribuidosAEstacao,
  referenciaForm,
  visitaSelecionada,
  area,
  subArea,

  // Bibliotecas
  coletores = [],
  metodosGeoposicionamento = [],
  categoriasLimiteArea = [],
  datums = [],

  minerais = [],
  prefixos = [],
  complementos = [],
  cores = [],
  tiposIlustracao = [],
  extencoesIlustracao = [],

  mineraisPorId = {},
  prefixosPorId = {},
  complementosPorId = {},

  tiposAfloramento = [],
  maturidadesComposicionais = [],
  maturidadesTexturais = [],
  esfericidades = [],
  granulacoes = [],
  arredondamentos = [],
  relacoesArcaboucoMatriz = [],
  naturezasMatriz = [],
  texturas = [],
  indicesCor = [],
  naturezasComposicaoSedimentar = [],
  granulometrias = [],
  selecoes = [],
  rochasNomeadas = [],
  geometrias = [],
  grausIntemperismos = [],
  tiposRelacaoContato = [],
  tiposMetamorfismo = [],
  hierarquias = [],
  faciesMetamorfismo = [],
  formasOcorrencia = [],
  tiposAlteracaoHidrotermal = [],
  intensidadesAlteracaoHidrotermal = [],
  tiposEstrutura = [],
  tiposNotacao = [],
  tiposEstruturaTectonica = [],
  tiposRochaEnclave = [],
  rochasNomeadasPorId = {},
  classesRocha = [],
  subClassesRocha = [],
  visitas,
  atividadesVisita,
}) => {
  const form = useForm();
  let modoCadastroAtualDefinitivo = false;

  const [coletorList, setColetorList] = useState([]);
  const [modoDefinitivo, setModoDefinitivo] = useState(true);
  const [forceUpdate, setForceUpdate] = useState(undefined);

  const AUTOSAVE_DELAY = process?.env?.REACT_APP_AUTOSAVE_DELAY ? process.env.REACT_APP_AUTOSAVE_DELAY : 300000;
  const dispatch = useDispatch();

  useEffect(() => {
    const interval = setInterval(() => {
      if (permitirEdicao) {
        const formValues = form?.getState()?.values;

        const body = {
          idColetor: formValues?.idUsuarioColetor,
          idVisita: formValues?.idVisita,
          idEstacao: idEstacao,
          toponimia: formValues?.toponimia,
          numeroCampo: formValues?.numeroCampo,
          atividade: formValues,
          tipoAtividade: formValues?.tipo,
          permitirExclusao: true,
          permitirEdicao: true,
          modoCadastro: 'TEMPORARIO',
          modoEdicao: 'BASEGEO'
        }

        Service('/estacoes/atividadeTemporaria', 'basegeo').post(body)
          .then(() => dispatch(mostrarNotificacao('Por segurança seus dados foram salvos de forma temporária', { tipo: 'info' }, true)))
          .catch((erro) => console.log("Erro no salvamento automatico", erro))
      }
    }, AUTOSAVE_DELAY);

    return () => clearInterval(interval);
  }, [AUTOSAVE_DELAY, dispatch, form, idEstacao, permitirEdicao]);

  if (form.getState().values.id > 1) {
    if (form.getState().values.modoCadastro === "DEFINITIVO" || permitirEdicao === false) {
      modoCadastroAtualDefinitivo = true
    }
  }

  let toponimia = '';

  let visita = JSON.parse(sessionStorage.getItem("visita"));


  if (visita && visita.toponimia)
    toponimia = visita.toponimia;

  useEffect(() => {
    const getColetores = async () => {
      try {
        const localidade = JSON.parse(sessionStorage.getItem("localidade"));

        const lista = await resolverRequestBaseGeo(`/coletores/paraProjeto/${localidade.idProjeto}`);

        const idColetor = form?.getState()?.values?.idUsuarioColetor;

        let listaFiltrada = [];

        lista.forEach((coletor) => {
          const item = coletor;

          if (item.id === idColetor)
            item.itemDesabilitado = false;

          listaFiltrada.push(item);
        });

        listaFiltrada = listaFiltrada.filter((coletor) => (coletor.itemDesabilitado === false && coletor.id === idColetor));

        setColetorList(listaFiltrada);
      }
      catch (err) {
        console.log('\n ERRO AO CARREGAR COLETORES', err);
        setColetorList(coletores);
      }
    }

    getColetores();
  }, [form?.getState()?.values?.idUsuarioColetor]);

  return (
    <>
      <div>
        <Row className="mb-3">
          <Col md={5}>
            <Field name={`${prefixoNome}modoCadastro`} subscription={{ value: true }}>
              {({ input: { value: modoCadastro } }) => {

                setModoDefinitivo(modoCadastro === 'DEFINITIVO');

                return <Field
                  component={RadioButtonWithFieldSetField}
                  name={`${prefixoNome}modoCadastro`}
                  label="Modo de cadastro"
                  dica="O Modo Rascunho permite salvar informações parciais; o Modo Completo exige informações completas conforme presença de (*)"
                  elementos={modosCadastroAtividade}
                  horizontal
                  required
                  disabled={modoCadastroAtualDefinitivo}
                  validate={campoObrigatorioComMsgGenerica("Modo de cadastro")}
                />
              }}
            </Field>
          </Col>
        </Row>
        <Card>
          <Card.Body>
            <Field
              name={"idsPontosAssociados"}
              subscription={{ value: true }}
            >
              {({ input: { value: idsPontosAssociados = {} } }) => {
                return (
                  <PontosGPSAfloramento
                    idEstacao={idEstacao}
                    pontosAtribuidosAEstacao={pontosAtribuidosAEstacao}
                    permitirEdicao={permitirEdicao}
                  />
                );
              }}
            </Field>
          </Card.Body>

          <InformacoesCoordenadas
            configMapa={configMapaAfloramento}
            mapaAtividade={true}
            prefixoCampos={prefixoNome}
            datums={datums}
            permitirEdicao={permitirEdicao}
            pontosAtribuidosAEstacao={pontosAtribuidosAEstacao}
            visitaSelecionada={visitaSelecionada}
            visitas={visitas}
            area={area}
            subArea={subArea}
            metodosGeoposicionamento={metodosGeoposicionamento}
            categoriasLimiteArea={categoriasLimiteArea}
            altitude={true}
            exibirLegendaEstacao={true}
            exibirLegendaEstacaoHistorica={false}
            exibirLegendaEstacaoEditada={false}
            exibirLegendaAtividade={true}
            exibirLegendaPontoGPS={false}
            exibirLegendaGeometriaProjeto={true}
            naoExibirEmCol={true}
          />

        </Card>

        <Card>
          <Card.Body>
            <Card.Title>Dados do afloramento</Card.Title>
            <Row className="mb-4 mt-3">
              <Col className="label-input-select">
                <EstaEmModoDefinitivo prefixoNome={prefixoNome}>
                  {(modoDefinitivoSelecionado) => {
                    return (<Field
                      component={DropdownListField}
                      name={`${prefixoNome}idTipoAfloramento`}
                      label="Tipo afloramento"
                      dica="Escolha o tipo de afloramento que melhor representa o que será descrito"
                      elementos={tiposAfloramento}
                      required={modoDefinitivoSelecionado}
                      disabled={!permitirEdicao}
                      validate={
                        validarSe(modoDefinitivoSelecionado,
                          campoObrigatorioDefinitivoComMsgGenerica(
                            "Tipo do afloramento"
                          ))}
                    />)
                  }}
                </EstaEmModoDefinitivo>
              </Col>
              <Col md={2}>
                <EstaEmModoDefinitivo prefixoNome={prefixoNome}>
                  {(modoDefinitivoSelecionado) => {
                    return (
                      <Field
                        component={NumberInputField}
                        name={`${prefixoNome}comprimentoAfloramento`}
                        label="Comprimento (m)"
                        casasInteiros={4}
                        casasDecimais={0}
                        max={999999999}
                        permitirNegativos={false}
                        disabled={!permitirEdicao}
                        required={modoDefinitivoSelecionado}
                        validate={
                          validarSe(modoDefinitivoSelecionado,
                            campoObrigatorioDefinitivoComMsgGenerica("Comprimento"))}
                        usarOnchange={true}
                      />)
                  }}
                </EstaEmModoDefinitivo>
              </Col>
              <Col md={2}>
                <EstaEmModoDefinitivo prefixoNome={prefixoNome}>
                  {(modoDefinitivoSelecionado) => {
                    return (
                      <Field
                        component={NumberInputField}
                        name={`${prefixoNome}larguraAfloramento`}
                        label="Largura/Altura (m)"
                        casasInteiros={4}
                        casasDecimais={0}
                        max={999999999}
                        permitirNegativos={false}
                        disabled={!permitirEdicao}
                        required={modoDefinitivoSelecionado}
                        validate={
                          validarSe(
                            modoDefinitivoSelecionado,
                            campoObrigatorioDefinitivoComMsgGenerica("Largura"))}
                        usarOnchange={true}
                      />)
                  }}
                </EstaEmModoDefinitivo>
              </Col>
              <Col>
                <Field
                  component={TextInputField}
                  name={`${prefixoNome}numeroCampo`}
                  label="Nome de campo"
                  dica="Preenchimento automático: Código do Projeto - Sigla do Coletor - Número sequencial"
                  required
                  disabled={true}
                  value="1234-ASD-"
                />
              </Col>
            </Row>
            <Row className="my-3">
              <Col>
                <Field
                  component={DropdownListField}
                  name={`${prefixoNome}idUsuarioColetor`}
                  label="Responsável"
                  dica="Selecione o/a responsável pela coleta das informações"
                  elementos={coletorList}
                  required
                  disabled={true}
                  validate={campoObrigatorioComMsgGenerica("Coletor")}
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <Field
                  component={TextAreaField}
                  name={`${prefixoNome}toponimia`}
                  label="Toponímia"
                  dica="Preenchimento automático, conforme descrição da visita"
                  disabled={true}
                  placeholder={toponimia}
                />
              </Col>
              <Col>
                <EstaEmModoDefinitivo prefixoNome={prefixoNome}>
                  {(modoDefinitivoSelecionado) => {
                    return (
                      <Field
                        component={TextAreaField}
                        name={`${prefixoNome}informacoesRelevantes`}
                        label="Informações relevantes"
                        dica="Inserir toda e qualquer informação que seja importante e não esteja contemplada nas demais opções de preenchimento"
                        required={modoDefinitivoSelecionado}
                        disabled={!permitirEdicao}
                        validate={validarSe(
                          modoDefinitivoSelecionado,
                          campoObrigatorioDefinitivoComMsgGenerica(
                            "Informações relevantes"
                          )
                        )}
                        maxLength={2000}
                      />
                    );
                  }}
                </EstaEmModoDefinitivo>
              </Col>

            </Row>
          </Card.Body>
        </Card>

        <EstaEmModoDefinitivo prefixoNome={prefixoNome}>
          {(modoDefinitivoSelecionado) =>
            <Field subscription={{ values: true }}>
              {({ values }) => {
                let formValues = values;

                let rochasComErro = false;
                let estPrimComErro = false;
                let estTecComErro = false;
                let metaComErro = false;
                let altHidroComErro = false;
                let rochaEncComErro = false;
                let relContatoComErro = false;
                let intComErro = false;

                if (values && values.modoCadastro === 'DEFINITIVO') {
                  // Rochas
                  if (formValues.rochas) {
                    const rochas = resolverExibidos(formValues.rochas).filter(i => i != null);
                    let erroRochas = false;

                    rochas.forEach(r => {

                      if (r.idTipoRocha === 1 || r.idTipoRocha === 8) {
                        if (!r.idRochaNomeada || !r.estimativaPercentual || !r.idsGranulacoes ||
                          !r.idsGranulacoes.length || !r.idsTexturas || !r.idsTexturas.length || !r.idsIndicesCor || !r.idsIndicesCor.length) {
                          erroRochas = true;
                        }
                      }

                      else if (r.idTipoRocha === 2) {
                        if (!r.idRochaNomeada || !r.estimativaPercentual || !r.idsGranulacoes ||
                          !r.idsGranulacoes.length || !r.idsTexturas || !r.idsTexturas.length) {
                          erroRochas = true;
                        }
                      }

                      else if (r.idTipoRocha === 7) {
                        if (!r.idRochaNomeada || !r.estimativaPercentual || !r.idsGranulacoes
                          || !r.idsGranulacoes.length || !r.idsTexturas || !r.idsTexturas.length) {
                          erroRochas = true;
                        }

                        if (r.clastos) {
                          const clastos = resolverExibidos(r.clastos).filter(i => i != null);

                          clastos.forEach(c => {
                            if (!c.idRochaNomeada || !c.idsMinerais || !c.idsMinerais.length)
                              erroRochas = true;
                          });
                        }
                      }

                      else if (r.idTipoRocha === 5) {

                        if (!r.idRochaNomeada || !r.estimativaPercentual) {
                          erroRochas = true;
                        }

                        if (r.clastos) {
                          const clastos = resolverExibidos(r.clastos).filter(i => i != null);

                          clastos.forEach(c => {
                            if (!c.idRochaNomeada || !c.idsMinerais || !c.idsMinerais.length)
                              erroRochas = true;
                          });
                        }
                      }

                    });

                    rochasComErro = erroRochas;
                  }

                  // Estruturas primarias
                  if (formValues.estruturasPrimarias) {
                    const estruturasPrimarias = resolverExibidos(formValues.estruturasPrimarias).filter(i => i != null);
                    let erroEstruturasPrimarias = false;

                    estruturasPrimarias.forEach(p => {
                      if (!p.idsRochas || !p.idsRochas.length || !p.tipoEstruturaPrimaria || !p.idTipo)
                        erroEstruturasPrimarias = true;
                    });

                    estPrimComErro = erroEstruturasPrimarias;
                  }

                  // Estruturas tectonicas
                  if (formValues.estruturasTectonicas) {
                    const estruturasTectonicas = resolverExibidos(formValues.estruturasTectonicas).filter(i => i != null);
                    let erroEstruturasTectonicas = false;

                    estruturasTectonicas.forEach(t => {
                      if (!t.idsRochas || !t.idsRochas.length || !t.idTipo)
                        erroEstruturasTectonicas = true;
                    });

                    estTecComErro = erroEstruturasTectonicas;
                  }

                  // Metamorfismos
                  if (formValues.metamorfismos) {
                    const metamorfismos = resolverExibidos(formValues.metamorfismos).filter(i => i != null);
                    let erroMetamorfismos = false;

                    metamorfismos.forEach(m => {
                      if (!m.idsRochas || !m.idsRochas.length || !m.idTipo || !m.idFacies)
                        erroMetamorfismos = true;
                    });

                    metaComErro = erroMetamorfismos;
                  }

                  // Alterações hidrotermais
                  if (formValues.alteracoesHidrotermais) {
                    const alteracoesHidrotermais = resolverExibidos(formValues.alteracoesHidrotermais).filter(i => i != null);
                    let erroAlteracoesHidrotermais = false;

                    alteracoesHidrotermais.forEach(r => {
                      if (!r.idRocha || !r.idsMinerais || !r.idsMinerais.length || !r.idIntensidadeAlteracao)
                        erroAlteracoesHidrotermais = true;
                    });

                    altHidroComErro = erroAlteracoesHidrotermais;
                  }

                  // Rochas de enclave
                  if (formValues.rochasEnclave) {
                    const rochasEnclave = resolverExibidos(formValues.rochasEnclave).filter(i => i != null);
                    let erroRochasEnclave = false;

                    rochasEnclave.forEach(r => {
                      if (!r.idsRochas || !r.idsRochas.length || !r.idRochaHospedeira || !r.idTipo)
                        erroRochasEnclave = true;
                    });

                    rochaEncComErro = erroRochasEnclave;
                  }

                  // Relações de contato
                  if (formValues.relacoesContato) {
                    const relacoesContato = resolverExibidos(formValues.relacoesContato).filter(i => i != null);
                    let erroRelacoesContato = false;

                    relacoesContato.forEach(r => {
                      if (!r.idsRochas || !r.idsRochas.length || !r.idTipoContato || !r.descricaoRelacaoContato)
                        erroRelacoesContato = true;
                    });

                    relContatoComErro = erroRelacoesContato;
                  }

                  // Intemperismos
                  if (formValues.intemperismos) {
                    const intemperismos = resolverExibidos(formValues.intemperismos).filter(i => i != null);
                    let erroInt = false;

                    intemperismos.forEach(i => {
                      if (!i.idRocha || i.idGrau === undefined || i.idGrau === null || i.idGrau === '')
                        erroInt = true;
                    });

                    intComErro = erroInt;
                  }
                }

                return <DadosAfloramento
                  form={form}
                  visitaSelecionada={visitaSelecionada}
                  visitas={visitas}
                  permitirEdicao={permitirEdicao}
                  maturidadesComposicionais={maturidadesComposicionais}
                  maturidadesTexturais={maturidadesTexturais}
                  esfericidades={esfericidades}
                  granulacoes={granulacoes}
                  arredondamentos={arredondamentos}
                  relacoesArcaboucoMatriz={relacoesArcaboucoMatriz}
                  naturezasMatriz={naturezasMatriz}
                  texturas={texturas}
                  indicesCor={indicesCor}
                  naturezasComposicaoSedimentar={naturezasComposicaoSedimentar}
                  granulometrias={granulometrias}
                  selecoes={selecoes}
                  rochasNomeadas={rochasNomeadas}
                  minerais={minerais}
                  prefixos={prefixos}
                  complementos={complementos}
                  geometrias={geometrias}
                  cores={cores}
                  grausIntemperismos={grausIntemperismos}
                  tiposIlustracao={tiposIlustracao}
                  extencoesIlustracao={extencoesIlustracao}
                  tiposRelacaoContato={tiposRelacaoContato}
                  tiposMetamorfismo={tiposMetamorfismo}
                  hierarquias={hierarquias}
                  faciesMetamorfismo={faciesMetamorfismo}
                  formasOcorrencia={formasOcorrencia}
                  tiposAlteracaoHidrotermal={tiposAlteracaoHidrotermal}
                  intensidadesAlteracaoHidrotermal={intensidadesAlteracaoHidrotermal}
                  tiposEstrutura={tiposEstrutura}
                  tiposNotacao={tiposNotacao}
                  tiposEstruturaTectonica={tiposEstruturaTectonica}
                  tiposRochaEnclave={tiposRochaEnclave}
                  mineraisPorId={mineraisPorId}
                  rochasNomeadasPorId={rochasNomeadasPorId}
                  prefixosPorId={prefixosPorId}
                  complementosPorId={complementosPorId}
                  modoDefinitivoSelecionado={modoDefinitivoSelecionado}
                  classesRocha={classesRocha}
                  rochasComErro={rochasComErro}
                  estPrimariasComErro={estPrimComErro}
                  estTectonicasComErro={estTecComErro}
                  metamorfismosComErro={metaComErro}
                  altHidrotermaisComErro={altHidroComErro}
                  rochasEnclaveComErro={rochaEncComErro}
                  relacaoContatoComErro={relContatoComErro}
                  intemperismosComErro={intComErro}
                  subClassesRocha={subClassesRocha}
                  atividadesVisita={atividadesVisita}
                />
              }}
            </Field>
          }
        </EstaEmModoDefinitivo>
      </div>
    </>
  )
}

export default AtividadeAfloramento;
