import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Field } from "react-final-form";
import { aoEnviarSubForm } from "../../../util/mutadores";
import Card from "../../../components/card";
import { SubForm } from "../../../components/SubForm";
import { DropdownListField } from "../../../components/field/DropdownListField";
import { RadioButtonSetField } from "../../../components/field/RadioButtonSetField";
import { getPropsPermissao, resolverExibidos } from "../../../components/SubForm/SubForm";
import { FORM_ERROR } from "final-form";

const ProcessosDiageneticos = ({
    form,
    bbCompactacao,
    bbEstagioDiagenetico,
    permitirVizualizacao,
    disabled
}) => {

    const onSubmit = aoEnviarSubForm(form);
    const OPTIONS = [
        { nome: "Sim", id: "S" },
        { nome: "Não", id: "N" },
    ];
    
    const colunasProcessoDiagenetico = [
        {
            text: 'Compactação',
            datafield: 'idCompactacao',
            width: '30%',
            cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
                const compactacaoId = parseInt(rowdata?.idCompactacao, 10);
                const objetoFiltrado = bbCompactacao.find(elemento => elemento.id === compactacaoId);
                const valor = objetoFiltrado ? objetoFiltrado.nome : '';
                return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${valor}</div>`;
            }
        },

        {
            text: 'Estágio Diagenético',
            datafield: 'idEstagioDiagenetico',
            width: '30%',
            cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
                const estagioDiageneticoId = parseInt(rowdata?.idEstagioDiagenetico, 10);
                const objetoFiltrado = bbEstagioDiagenetico.find(elemento => elemento.id === estagioDiageneticoId);
                const valor = objetoFiltrado ? objetoFiltrado.nome : '';
                return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${valor}</div>`;
            }
        },

        {
            text: 'Presença de Óleo/Betume',
            datafield: 'presencaOleo',
            width: '40%',
            cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
                let valor = ""
                if (value === 'N') {
                    valor = 'Não'
                } if (value === 'S') {
                    valor = 'Sim'
                }
                return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${valor}</div>`;
            }
        },

    ]
    const validarCampos = (valores) => {
        const itens = form.getState().values;

        if (itens) {
            const estimativas = resolverExibidos(itens);

            const indexExistente = estimativas.findIndex(item => item.id === valores.id);

            if (indexExistente !== -1) {

                estimativas[indexExistente] = valores;
            } else {

                estimativas.push(valores);
            }

            if (estimativas && estimativas.length > 0) {

                if (!estimativas[0].presencaOleo && !estimativas[0].idCompactacao && !estimativas[0].idEstagioDiagenetico) {
                    return { [FORM_ERROR]: 'Preencha ao menos um dos campos para proseguir com a confirmação.' };
                }

            }
            return null;
        }
    };

    const camposProcessoDiagenetico = [
        { name: "idCompactacao", type: "string", map: "idCompactacao" },
        { name: "idEstagioDiagenetico", type: "string", map: "idEstagioDiagenetico" },
        { name: "presencaOleo", type: "string", map: "presencaOleo" },
    ];

    return (
        <>
            <div style={{ marginTop: '2.5rem' }}>
                <Card className="mt-3">
                    <Card.Body>
                        <Card.Title>Processos diagenéticos</Card.Title>
                        <Field name={'processoDiagenetico'} subscription={{ value: true }}>
                            {({ input: { value: processoDiagenetico = [] } }) => {
                                let exibirBotaoInsercao = true;
                                const diageneticoTela = resolverExibidos(processoDiagenetico);
                                if (diageneticoTela.length > 0) {
                                    exibirBotaoInsercao = false;
                                }
                                return (<>
                                    <SubForm
                                        nome='processoDiagenetico'
                                        onSubmit={onSubmit}
                                        modoSubFormComGrid
                                        colunas={colunasProcessoDiagenetico}
                                        exibirBotaoInsercao={exibirBotaoInsercao && !disabled}
                                        elementos={processoDiagenetico}
                                        campos={camposProcessoDiagenetico}
                                        validar={(valor) => validarCampos(valor)}
                                        permitirVisualizacao={disabled}
                                        permitirInsercao={!disabled}
                                        permitirEdicao={!disabled}
                                        permitirExclusao={!disabled}
                                        renderForm={({ formProps: { form } }) => {
                                            return (
                                                <div style={{ display: "flex", flexDirection: "column" }}>
                                                    <Row className="my-3">
                                                        <Col md={4}  >
                                                            <Field
                                                                component={DropdownListField}
                                                                name="idCompactacao"
                                                                label="Compactação"
                                                                dica="Selecione o tipo ou processo de compactação observado."
                                                                elementos={bbCompactacao}
                                                                disabled={disabled}
                                                            />
                                                        </Col>
                                                        <Col md={4}  >
                                                            <Field
                                                                component={DropdownListField}
                                                                name="idEstagioDiagenetico"
                                                                label="Estágio Diagenético"
                                                                dica="Selecione o estágio diagenético."
                                                                elementos={bbEstagioDiagenetico}
                                                                disabled={disabled}
                                                            />
                                                        </Col>

                                                        <Col md={4}>
                                                            <Field
                                                                name="presencaOleo"
                                                                component={RadioButtonSetField}
                                                                label="Presença de Óleo/Betume"
                                                                elementos={OPTIONS}
                                                                horizontal
                                                                dica="Existe a presença de óleo ou betume? Responda sim ou não."
                                                                disabled={disabled}
                                                            />
                                                        </Col>

                                                    </Row>
                                                </div>
                                            )
                                        }}
                                    />
                                </>)
                            }}
                        </Field>
                    </Card.Body>
                </Card>
            </div>
        </>
    );
};

export default ProcessosDiageneticos;