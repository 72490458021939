import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import Form from "react-bootstrap/Form";
import NumberFormat from "react-number-format";
import Hint from "../../hint/Hint";
import Erro from "../../Erro";
import "./NumberInput.css";
import DynamicNumber from 'react-dynamic-number';

function useForceUpdate() {
  const [value, setValue] = useState(0); // integer state
  return () => setValue(value => value + 1); // update the state to force render
}

const NumberInput = ({
  valor,
  label,
  dica,
  onChange,
  required,
  mensagemErro,
  casasDecimais,
  separadorDecimal,
  permitirNegativos,
  permitirPositivos,
  max,
  valorMaximo,
  valorMinimo,
  min,
  usarOnchange,
  defaultNegativo,
  name,
  casasInteiros,
  maxInteiros,
  ...props
}) => {


  useEffect(() => {
  }, [])

  const forceUpdate = useForceUpdate();

  const onChangeInternal = useCallback(
    (e, modelValue) => {
      if (e.target.value.includes(',') && casasDecimais === 0) {
        e.target.value = e.target.value.replace(',', '');
      }
      if (e.target.value.length > casasInteiros + casasDecimais) {
        e.preventDefault();
      }
      else if (e.target?.value?.toString().length <= (casasInteiros + casasDecimais) && casasDecimais > 0) {
        if ((e.target?.value?.toString().length > casasInteiros) && !e.target.value.includes(',')) {
          let arrayValue = e.target.value.split('');
          let formatedValue = '';
          for (let i = 0; i < arrayValue.length; i++) {
            if (i === arrayValue.length - 1) {
              formatedValue = formatedValue + ',' + arrayValue[i];
            } else {
              formatedValue = formatedValue + arrayValue[i];
            }

          }
          onChange?.(formatedValue);
          setTimeout(function () { e.target.selectionStart = e.target.selectionEnd = 10000; }, 0);
        }
      } else {
        e.preventDefault();
        e.stopPropagation();
        onChange?.(e.target.value);
      }
      //forceUpdate();
    },
    [usarOnchange, onChange]
  );

  const onBlur = useCallback((e) => {
    let minimoValorInput = valorMinimo ?? min;
    let maximoValorInput = valorMaximo ?? max;
    const valorComoString = (e.target.value ?? "").replace(",", ".");
    const tempValor = parseFloat(valorComoString);
    if (isNaN(tempValor)) return onChange?.(null);
    if (parseFloat(tempValor) < parseFloat(minimoValorInput)) {
      onChange?.(minimoValorInput);
      forceUpdate();
    } else if (parseFloat(tempValor) > parseFloat(maximoValorInput)) {
      onChange?.(maximoValorInput);
      forceUpdate();
    } else {
      onChange?.(tempValor);
      forceUpdate();
    }
  }, [onChange]);


  function validarValor() {
    if(valor == null || valor == undefined) return '';

    if(typeof valor == 'string' && valor.length)
      return parseFloat(valor);

    return valor;
  }

  return (
    <>
      {label && (
        <Form.Label className="small">
          {label}
          {required && <span style={{ color: "red" }}>*</span>}
          {dica && <Hint textoAjuda={dica} />}
        </Form.Label>
      )}
      <DynamicNumber
        disabled={props.disabled}
        className={"aflora-number-input"}
        value={validarValor()} 
        separator={','}
        onBlur={onBlur}
        integer={casasInteiros}
        fraction={casasDecimais}
        positive={true}
        negative={permitirNegativos}
        thousand={false}
        onChange={onChangeInternal}
      />

      <Erro mensagem={mensagemErro} />
    </>
  );
};

NumberInput.propTypes = {
  valor: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  label: PropTypes.string,
  dica: PropTypes.string,
  mensagemErro: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  required: PropTypes.bool,

  onChange: PropTypes.func,

  casasDecimais: PropTypes.number,
  separadorDecimal: PropTypes.string,
  permitirNegativos: PropTypes.bool,
  permitirPositivos: PropTypes.bool,
};

NumberInput.defaultProps = {
  casasDecimais: 0,
  separadorDecimal: ",",
  permitirNegativos: true,
  permitirPositivos: true,
  min: Number.MIN_SAFE_INTEGER,
  max: Number.MAX_SAFE_INTEGER,
};

export default React.memo(NumberInput);
