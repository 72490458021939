import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Field, useForm } from "react-final-form";
import { aoEnviarSubForm } from "../../../../../util/mutadores";
import Card from "../../../../../components/card";
import { SubForm } from "../../../../../components/SubForm";
import { DropdownListField } from "../../../../../components/field/DropdownListField";
import { resolverExibidos } from "../../../../../components/SubForm/SubForm";
import { campoObrigatorioComMsgGenerica } from "../../../../../util/validadores";
import FasesMinerais from "./FasesMinerais";
import { buscaPercentuaisArcabouco, calcularSomaPercentuais, validacoesGeraisMatriz } from "../../../utils/gerais";
import { useEffect, useState } from "react";
import Service, { BASEGEO } from "../../../../../service/Service";


const Matriz = ({
    permitirEdicao,
    form,
    bbUnidadeMedida,
    bbMinerais,
    bbNaturezaMatriz,
    exibeMatriz,
    permitirInsercao,
    jacadastrouMatriz,
    disabled
}) => {
    const onSubmit = aoEnviarSubForm(form);
    const [bbMorfologia, setBbMorfologia] = useState([]);
    const colunasMatrizes = [
        {
            text: 'Tipo de Matriz',
            datafield: 'idTipoMatriz',
            width: '30%',
            cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
                const objetoFiltrado = bbNaturezaMatriz.find(elemento => elemento.id === value);
                const valor = objetoFiltrado ? objetoFiltrado.nome : '';
                return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${valor}</div>`;
            }
        },
        {
            text: 'Fases Minerais',
            datafield: 'idFasesMinerais',
            width: '30%',
            cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
                let nomesCorrespondentes = ""
                let matrizes = resolverExibidos(form.getState().values?.matrizes)
                const matrizDaLinha = matrizes?.[row];
                const fasesMinerais = matrizDaLinha?.fasesMinerais;

                if (fasesMinerais) {
                    const fasesMineraisTela = resolverExibidos(fasesMinerais);

                    if (!fasesMineraisTela || !fasesMineraisTela.length) {
                        console.log("Nenhuma dase mineral na tela.");
                    }

                    nomesCorrespondentes = fasesMineraisTela.map((item) => {
                        const mineralCorrespondente = bbMinerais.find(({ id }) => id === item.idMineral);
                    
                        if (mineralCorrespondente) {
                            const { nome } = mineralCorrespondente;
                            const porcentagem = item.porcentagemFracao;
                            return ` ${nome} (${porcentagem}%)`; 
                        } else {
                            return "";
                        }
                    });
                    
                }

                return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;" title="${nomesCorrespondentes}">${nomesCorrespondentes}</div>`;
            }
        }
    ]
    const camposMatrizes = [
        { name: "idTipoMatriz", type: "number", map: "idTipoMatriz" },
        { name: "idFasesMinerais", type: "number", map: "idFasesMinerais" },
    ];

    async function carregarBibliotecas() {
        try {
            const morfologias = await Service('/bibliotecas/morfologia', BASEGEO).query()
            const { data: { dados: morfologia = [] } } = morfologias;
            setBbMorfologia(morfologia)

        } catch (err) {
            console.log("Erro ao consultar bibliotecas: ", err);
        }
    }
    useEffect(() => {
        carregarBibliotecas()
    }, []);

    const valorFormulario = useForm()
    return (
        <>
            <div style={{ marginTop: '2.5rem', display: exibeMatriz }}>
                <Card className="mt-3">
                    <Card.Body>
                        <Card.Title>Matriz</Card.Title>
                        <Field name={'matrizes'} subscription={{ value: true }}>
                            {({ input: { value: matrizes = [] } }) => (
                                <SubForm
                                    nome={'matrizes'}
                                    onSubmit={onSubmit}
                                    colunas={colunasMatrizes}
                                    elementos={matrizes}
                                    campos={camposMatrizes}
                                    validar={(valores) => validacoesGeraisMatriz(valores)}
                                    podeExcluirEntidade={!disabled}
                                    permitirInsercao={permitirInsercao}
                                    exibirBotaoInsercao={!(disabled || jacadastrouMatriz)}
                                    permitirEdicao={!disabled}
                                    permitirExclusao={!disabled}
                                    permitirVisualizacao={disabled}
                                    renderForm={({ formProps }) => {

                                        let fasesMinerais = [];

                                        if (formProps.form.getState().values?.fasesMinerais) {
                                            fasesMinerais = resolverExibidos(formProps.form.getState().values?.fasesMinerais)
                                        }

                                        const somaTotalFasesMinerais = calcularSomaPercentuais(buscaPercentuaisArcabouco(fasesMinerais, 'porcentagemFracao'));
      
                                        var valorAtual = formProps.form.getState().values?.idTipoMatriz
                                        var matrizes = valorFormulario?.getState().values?.matrizes
                                        var matrizesTela = []
                                        if (matrizes) {
                                            matrizesTela = resolverExibidos(matrizes)
                                        }
                                        var bbNaturezaMatrizFiltrados = bbNaturezaMatriz.filter(value => {
                                            return !matrizesTela.some(item => item.idTipoMatriz === value.id && valorAtual !== value.id);
                                        });
                                        
                                        return (
                                            <>


                                                <Row className="my-3">
                                                    <Col md={4}>
                                                        <Field
                                                            component={DropdownListField}
                                                            name={"idTipoMatriz"}
                                                            label="Tipo da Matriz"
                                                            dica="Selecione o tipo de matriz observada. "
                                                            elementos={bbNaturezaMatrizFiltrados}
                                                            displayMember={'nome'}
                                                            disabled={disabled}
                                                            required
                                                            validate={campoObrigatorioComMsgGenerica("Tipo da Matriz")}
                                                        />
                                                    </Col>
                                                </Row>
                                                <FasesMinerais
                                                    bbMinerais={bbMinerais}
                                                    bbUnidadeComprimento={bbUnidadeMedida}
                                                    bbMorfologia={bbMorfologia}
                                                    disabled={disabled}
                                                    form={form}
                                                    somaTotalPercentuais={somaTotalFasesMinerais}
                                                />
                                            </>
                                        )
                                    }
                                    }
                                />
                            )}
                        </Field>
                    </Card.Body >
                </Card>
            </div>
        </>
    );
};

export default Matriz;