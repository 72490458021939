/* eslint-disable react-hooks/exhaustive-deps */
import AtividadeAfloramento from "../../../../estacoes/components/afloramento/AtividadeAfloramento";
import { useState, useCallback, useEffect, useRef } from "react";
import { getBibliotecasAfloramento } from "../../../../../service/BibliotecasService";
import { swalErro } from "../../../../../util/validadores";
import Service from "../../../../../service/Service";
import converterVetorParaObjetoIndexado from "../../../../../util/converterVetorParaObjetoIndexado";
import { decoradorSirgas2000, decoradorMapa } from "../../../../../util/decoradores/decoradorMapa";
import { resetarLatLongInvalidaAoTrocarHemisferio } from "../../../../../util/decoradores/decoradorSeletorCoordenadas";
import { Form } from "react-final-form";

const exibirSwalErroBibliotecas = (
    setIdTipoAtividadeSelecionada
) => {
    return (err) => {
        console.error("Erro ao recuperar bibliotecas:", err);
        setIdTipoAtividadeSelecionada(null);
        swalErro({
            title: "Não foi possível carregar os dados do módulo selecionado",
        });
    };
};

const AfloramentoAprovacao = ({ atividade }) => {
    const [bibliotecasAfloramento, setBibliotecasAfloramento] = useState(null);
    const [visitas, setVisitas] = useState(null);
    const [idVisitaSelecionada, setIdVisitaSelecionada] = useState(null);
    const [area, setArea] = useState(null);
    const [subArea, setSubarea] = useState(null);
    const [idProjeto, setIdProjeto] = useState(null);

    //#region Bibliotecas
    const [metodosGeoposicionamento, setMetodosGeoposicionamento] = useState(null);
    const [categoriasLimiteArea, setCategoriasLimiteArea] = useState(null);
    const [datums, setDatums] = useState(null);
    const [minerais, setMinerais] = useState(null);
    const [prefixos, setPrefixos] = useState(null);
    const [complementos, setComplementos] = useState(null);
    const [cores, setCores] = useState(null);
    const [tiposIlustracao, setTiposIlustracao] = useState(null);
    const [extensoesIlustracao, setExtensoesIlustracao] = useState(null);
    const [mineraisPorId, setMineraisPorId] = useState(null);
    const [prefixosPorId, setPrefixosPorId] = useState(null);
    const [complementosPorId, setComplementosPorId] = useState(null);
    //#endregion

    const [coletores, setColetores] = useState(null);
    const [prefixoNome,] = useState("")
    const [atividadesVisitaPorTipo, setAtividadesVisitaPorTipo] = useState(null);

    const [atividadeEmAprovacao,] = useState(atividade);
    const [idEstacao,] = useState(atividade.idEstacao);
    const pontosAtribuidosAEstacao = useRef([]);
    const [visitaAtividade, setVisitaAtividade] = useState(null);

    const [, setBibliotecaVazia] = useState(null);
    const decoradores = [decoradorSirgas2000(atividade.idVisita), decoradorMapa(), resetarLatLongInvalidaAoTrocarHemisferio()];

    const atualizarPontosAtribuidosAEstacao = useCallback(async () => {
        if (pontosAtribuidosAEstacao.current.length > 0)
            return;
        return Service("/gps/pontosGPS/paraEstacao")
            .query({ idEstacao })
            .then(({ data: { dados: pontos } }) => {
                pontosAtribuidosAEstacao.current = pontos || [];
            })
            .catch((err) =>
                console.error(
                    "Erro ao buscar pontos não vinculados associados a estação:",
                    err
                )
            );
    }, [pontosAtribuidosAEstacao, idEstacao]);

    const carregarBibliotecasAfloramento = useCallback(async () => {
        const bibliotecas = await getBibliotecasAfloramento()
            .catch(exibirSwalErroBibliotecas(setBibliotecaVazia));
        if (!bibliotecas) return;
        setBibliotecasAfloramento(bibliotecas);
    }, []);

    const carregarVisitasEstacao = useCallback(async () => {
        return Service(`/visitas/${idEstacao}`)
            .query()
            .then(({ data: { dados: visitasEstacao } }) => {
                setVisitas(visitasEstacao);
            })
            .catch((err) =>
                console.error(
                    "Erro ao buscar visitas da estacao:",
                    err
                )
            );
    }, [idEstacao]);

    useEffect(() => {
        if (!bibliotecasAfloramento)
            carregarBibliotecasAfloramento();
        if (!visitas)
            carregarVisitasEstacao();

        atualizarPontosAtribuidosAEstacao();

        if (!idVisitaSelecionada)
            setIdVisitaSelecionada(atividadeEmAprovacao.idVisita);

        if (!visitaAtividade && visitas !== null) {
            const visita = visitas.find((visita) => visita.id === atividadeEmAprovacao.idVisita.toString());
            setVisitaAtividade(visita);
            if (!area)
                setArea(visita.area);
            if (!subArea)
                setSubarea(visita.subArea);
            if (!idProjeto)
                setIdProjeto(visita.idProjeto);
        }
    }, [atualizarPontosAtribuidosAEstacao, carregarBibliotecasAfloramento, carregarVisitasEstacao,
        idProjeto,
        idVisitaSelecionada,
        subArea,
        visitaAtividade,
        visitas,
        area,
        bibliotecasAfloramento,
        atividadeEmAprovacao]);

    useEffect(() => {
        if (!idVisitaSelecionada)
            return;
    }, [idVisitaSelecionada]);

    useEffect(() => {
        if (!idProjeto || !idVisitaSelecionada)
            return;

        const listaBibliotecas = [
            coletores,
            metodosGeoposicionamento,
            categoriasLimiteArea,
            datums,
            minerais,
            prefixos,
            complementos,
            cores,
            tiposIlustracao,
            extensoesIlustracao,
            mineraisPorId,
            prefixosPorId,
            complementosPorId,
            atividadesVisitaPorTipo
        ];

        if (!listaBibliotecas.some(biblioteca => !biblioteca)) {
            return;
        }

        Promise.all(
            [Service("/gps/datums").query(),
            Service("/minerais").query(),
            Service("/prefixos").query(),
            Service("/complementos").query(),
            Service("/cores").query(),
            Service("/tiposIlustracao").query(),
            Service("/extencoesIlustracao").query(),
            Service("/categoriasLimiteArea").query(),
            Service("/metodosGeoPosicionamento").query(),
            Service(`/atividades/tipoAtividade/visita/${idVisitaSelecionada}`).query(),
            Service(`/coletores/paraProjeto/${idProjeto}`).query()
            ]
        ).then(([
            datumResponse,
            mineraisResponse,
            prefixosResponse,
            complementosResponse,
            coresResponse,
            tiposIlustracaoResponse,
            extensoesIlustracaoResponse,
            categoriasLimiteAreaResponse,
            metodosGeoposicionamentoResponse,
            atividadesVisitaPorTipoResponse,
            coletoresProjetoResponse
        ]) => {
            setDatums(datumResponse.data.dados);
            setMinerais(mineraisResponse.data.dados);
            setPrefixos(prefixosResponse.data.dados);
            setComplementos(complementosResponse.data.dados);
            setCores(coresResponse.data.dados);
            setTiposIlustracao(tiposIlustracaoResponse.data.dados);
            setExtensoesIlustracao(extensoesIlustracaoResponse.data.dados);
            setCategoriasLimiteArea(categoriasLimiteAreaResponse.data.dados);
            setMetodosGeoposicionamento(metodosGeoposicionamentoResponse.data.dados);
            setAtividadesVisitaPorTipo(atividadesVisitaPorTipoResponse.data.dados);
            setColetores(coletoresProjetoResponse.data.dados);

            setMineraisPorId(converterVetorParaObjetoIndexado(mineraisResponse.data.dados));
            setPrefixosPorId(converterVetorParaObjetoIndexado(prefixosResponse.data.dados));
            setComplementosPorId(converterVetorParaObjetoIndexado(complementosResponse.data.dados));
        });
    }, [idProjeto, idVisitaSelecionada]);

    const AtividadeCarregada = useCallback(() => {
        const listaDependencias = [visitas,
            idVisitaSelecionada,
            area,
            subArea,
            bibliotecasAfloramento,
            coletores,
            metodosGeoposicionamento,
            categoriasLimiteArea,
            datums,
            minerais,
            prefixos,
            complementos,
            cores,
            tiposIlustracao,
            extensoesIlustracao,
            mineraisPorId,
            prefixosPorId,
            complementosPorId,
            atividadesVisitaPorTipo]

        if (listaDependencias.some(biblioteca => !biblioteca)) {
            return <></>;
        }
        return (<AtividadeAfloramento
            prefixoNome={prefixoNome}
            permitirEdicao={false}
            editando={false}
            idEstacao={idEstacao}
            pontosAtribuidosAEstacao={pontosAtribuidosAEstacao.current}
            visitas={visitas}
            visitaSelecionada={idVisitaSelecionada}
            area={area}
            subArea={subArea}
            // Bibliotecas
            {...(bibliotecasAfloramento || {})}
            coletores={coletores}
            metodosGeoposicionamento={metodosGeoposicionamento}
            categoriasLimiteArea={categoriasLimiteArea}
            datums={datums}
            minerais={minerais}
            prefixos={prefixos}
            complementos={complementos}
            cores={cores}
            tiposIlustracao={tiposIlustracao}
            extencoesIlustracao={extensoesIlustracao}
            mineraisPorId={mineraisPorId}
            prefixosPorId={prefixosPorId}
            complementosPorId={complementosPorId}
            atividadesVisita={atividadesVisitaPorTipo}
        />);

    }, [area,
        atividadesVisitaPorTipo,
        bibliotecasAfloramento,
        categoriasLimiteArea,
        coletores,
        complementos,
        complementosPorId,
        cores,
        datums,
        extensoesIlustracao,
        idEstacao,
        idVisitaSelecionada,
        metodosGeoposicionamento,
        minerais,
        mineraisPorId,
        prefixoNome,
        prefixos,
        prefixosPorId,
        subArea,
        tiposIlustracao,
        visitas
    ]);


    return (
        <Form id="formAtividadeAfloramento"
            onSubmit={() => { }}
            decorators={decoradores}
            initialValues={atividade}
            render={() => {
                return (<AtividadeCarregada />);
            }} />
    );
}

export { AfloramentoAprovacao };