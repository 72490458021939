import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";

class Index extends Component {
  render() {
    if (this.props.autenticado) {
      return <Redirect to={{ pathname: "/index" }} />;
    }

    return <Redirect to={{ pathname: "/login" }} />;
  }
}

export default connect(
  (state) => ({
    autenticado: state.autenticacao.autenticado,
  }),
)(Index);
