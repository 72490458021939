import React, { useEffect, useRef, useState } from "react";
import Card from "../../../../components/card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { SubForm } from "../../../../components/SubForm";
import { Field, useForm } from "react-final-form";
import { DropdownListField } from "../../../../components/field/DropdownListField";
import { campoObrigatorioComMsgGenerica, validarSe } from "../../../../util/validadores";
import { TextAreaField } from "../../../../components/field/TextAreaField";
import { getPropsPermissao, resolverExibidos } from "../../../../components/SubForm/SubForm";
import { FORM_ERROR } from "final-form";

const camposRelacaoContato = [
  { name: "idTipoContato", type: "string", map: "idTipoContato" },
  { name: "idsRochas", type: "array", map: "idsRochas" },
]

const RelacoesContato = ({
  nome,
  onSubmit,
  // elementos,
  valoresIniciais,
  permitirEdicao,
  modoDefinitivoSelecionado,
  rochas,
  rochasPorId,
  tiposRelacaoContato,
  relacaoContatoComErro
}) => {
  // These refs are for remembering the previous values of each array that's a
  // dependency of the columns array. We only recalculate the columns array when
  // there's an actual change in the arrays.
  const rochasPorIdRef = useRef(rochasPorId);
  const tiposRelacaoContatoRef = useRef(tiposRelacaoContato);

  const [colunasRelacoesContato, setColunasRelacoesContato] = useState(gerarColunasRelacoesContato(rochasPorId, tiposRelacaoContato))
  const [botaoAdicionarHabilitado, setBotaoAdicionarHabilitado] = useState(true);

  const valoresIniciaisRef = useRef(valoresIniciais);

  useEffect(() => {
    // Only update the columns when at least one of the dependencies has really changed.
    // Otherwise, avoid changing the columns because that causes a table rerender.
    // (The *correct* thing to do would be including the names in the objects themselves (add extra fields)
    // and always recalculate those names when dependencies change, completely removing the need for
    // a cellsrenderer.)
    // Compare the dependencies by value.
    if (JSON.stringify(rochasPorIdRef.current) !== JSON.stringify(rochasPorId)
      || JSON.stringify(tiposRelacaoContatoRef.current) !== JSON.stringify(tiposRelacaoContato)
    ) {
      setColunasRelacoesContato(gerarColunasRelacoesContato(rochasPorId, tiposRelacaoContato));
      rochasPorIdRef.current = rochasPorId;
      tiposRelacaoContatoRef.current = tiposRelacaoContato;
    }
  }, [rochasPorId, tiposRelacaoContato])

  const form = useForm();

  useEffect(() => {
    if (form.getState().values.rochas) {
      let totalRochas = resolverExibidos(form.getState().values.rochas).length;
      if (totalRochas < 2 || !permitirEdicao) {
        setBotaoAdicionarHabilitado(false);
      }
    }
  }, [form, permitirEdicao]);


  const validarRelacoesContato = (valores) => {
    if (valores.idsRochas && valores.idsRochas.length !== 2) {
      return {
        [FORM_ERROR]: 'Duas rochas devem ser selecionadas para uma relação de contato'
      }
    }
    
    if(form?.getState()?.values?.relacoesContato){
      let listaRelacoes = resolverExibidos(form?.getState()?.values?.relacoesContato);
      let flag = false;
      listaRelacoes.forEach(relacao => { 
        if(valores.id != relacao.id){

          if(valores && valores.idsRochas && relacao.idsRochas && relacao.idsRochas.find(id => valores.idsRochas[0] == id) && relacao.idsRochas.find(id => valores.idsRochas[1] == id))
            flag = true;
        }
      })
      if(flag)
        return { [FORM_ERROR]: 'Não deve descrever um novo contato com o mesmo par de rochas.'}
    }
    
  }


  return (<Field name={'rochas'} subscription={{ value: true }}>
    {({ input: { value: formRochas = {} } }) => {
      if (form.getState().values.rochas) {
        let totalRochas = resolverExibidos(form.getState().values.rochas).length;
        if (totalRochas < 2 || !permitirEdicao) {
          setBotaoAdicionarHabilitado(false);
        } else {
          setBotaoAdicionarHabilitado(true);
        }
      }
      return <div style={{ marginTop: '2.5rem' }}>
        <Card className="mt-3">
          <Card.Body>
            {relacaoContatoComErro && <span style={{ color: "red" }}>Existem relações de contato com campos obrigatórios não preenchidos</span>}
            <Card.Title>Relação de contato</Card.Title>
            <Field name={nome} subscription={{ value: true }}>
              {({ input: { value: relacoesContato = {} } }) => (
                <SubForm
                  nome={nome}
                  tituloForm={botaoAdicionarHabilitado ? '' : <Card.Subtitle className="mt-2">(Habilitado com duas rochas ou mais)</Card.Subtitle>}
                  onSubmit={(params) => {
                    // Reset the initial values so that it doesn't get automatically
                    // recovered on the next render.
                    // It would be better to check if the submit was successful before
                    // resetting this ref, but it doesn't seem like that's possible.
                    valoresIniciaisRef.current = null;
                    onSubmit(params);
                  }}
                  elementos={relacoesContato} // form.getState().values.relacoesContato
                  campos={camposRelacaoContato}
                  colunas={colunasRelacoesContato}
                  valoresIniciais={valoresIniciaisRef.current || valoresIniciais}
                  {...getPropsPermissao(permitirEdicao)}
                  alternarBotoesPai={true}
                  validarVoltar={true}
                  validar={(valores) => validarRelacoesContato(valores)}
                  exibirBotaoInsercao={botaoAdicionarHabilitado}
                  renderForm={({ formProps: { form, values }, prefixoNome }) => {

                    // Update the ref of initial values. This code needs to run
                    // when any field changes.
                    if (valoresIniciaisRef.current !== values) {
                      valoresIniciaisRef.current = values;
                    }
                    return (
                      <>
                        <Row className="my-3">
                          <Col>
                            <Field
                              component={DropdownListField}
                              name={`${prefixoNome}idsRochas`}
                              label="Rochas"
                              dica="Selecione dentre as rochas descritas aquelas que apresentam relação de contato (superfície ou zona de separação de unidades geológicas ou estratigráficas como corpos e camadas rochosos)"
                              elementos={rochas}
                              displayMember={'nomeExibicao'}
                              required={modoDefinitivoSelecionado}
                              validate={validarSe(modoDefinitivoSelecionado, campoObrigatorioComMsgGenerica("Rochas"))}
                              disabled={!permitirEdicao}
                              checkboxes={true}
                              multiple
                            />
                          </Col>
                          <Col>
                            <Field
                              component={DropdownListField}
                              name={`${prefixoNome}idTipoContato`}
                              label="Tipo de contato"
                              dica="Selecione o Tipo de contato observado entre as rochas escolhidas"
                              elementos={tiposRelacaoContato}
                              required={modoDefinitivoSelecionado}
                              disabled={!permitirEdicao}
                              validate={validarSe(modoDefinitivoSelecionado, campoObrigatorioComMsgGenerica("Tipo de contato"))}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Field
                              component={TextAreaField}
                              name={`${prefixoNome}descricaoRelacaoContato`}
                              label="Descrição da relação de contato"
                              dica="Caso necessário, detalhe a descrição das relações de contato"
                              disabled={!permitirEdicao}
                              required={modoDefinitivoSelecionado}
                              validate={validarSe(modoDefinitivoSelecionado, campoObrigatorioComMsgGenerica("Descrição da relação de contato"))}
                              maxLength={250}
                            />
                          </Col>
                        </Row>
                      </>
                    )
                  }}
                />
              )}
            </Field>
          </Card.Body>
        </Card>
      </div>
    }}</Field>
  );
}

// Função que gera as colunas da tabela de relações de contato, extraída para que
// possamos instanciar as colunas já inicializadas da primeira vez, ao invés de
// usar um estado [] padrão. Isso evita um rerender da tabela.
function gerarColunasRelacoesContato(rochasPorId, tiposRelacaoContato) {
  return [
    {
      text: 'Tipo de contato', datafield: 'idTipoContato', width: '20%',
      cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
        const nome = value ? tiposRelacaoContato.find(t => t.id === parseInt(value)).nome : "";
        return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${nome}</div>`;
      }
    },
    {
      text: "Rochas", datafield: "idsRochas", width: '74%',

      cellsrenderer: function (row, columnfield, value, defaulthtml, columnproperties, rowdata) {
        let nome = ''
        
        try {
          const idsRochas = (rowdata[columnfield] || '').split(',')

          nome = idsRochas ? idsRochas
            .filter(it => !!it)
            .map(it => rochasPorId && rochasPorId[Number(it)] ? rochasPorId[Number(it)].nomeExibicao : '')
            .join(', ') : ''
        }
        catch(err) {
          console.log("Erro na tabela de relaçoes de contato", err);
          nome = ''
        }

        return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${nome}</div>`;
      }
    },
  ];
}

export default RelacoesContato;
