import { Component } from 'react'
import swal from 'sweetalert'

export default class Erro extends Component {
    constructor(props) {
        super(props)
        this.state = { houveErro: false }
    }
    componentDidCatch(error, info) {
        this.setState({ houveErro: true })
    }
    render() {
        if (this.state.houveErro) {
           window.location.pathname = "/";
            /*
            return swal({
                title: 'Algo deu errado.',
                icon: 'error',
                buttons: {
                    confirm: {
                        text: 'Retornar a página inicial',
                        className: "btn-primary"
                    }
                }
            }).then((result) => {
                if (result) {
                    window.location.pathname = "/"
                }
            })*/
        }
        return this.props.children
    }
}
