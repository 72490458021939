/* eslint-disable react-hooks/exhaustive-deps */
import Amostras from "../../../../estacoes/components/amostras/Amostras";
import { useState, useCallback, useEffect, useRef } from "react";
import Service from "../../../../../service/Service";
import converterVetorParaObjetoIndexado from "../../../../../util/converterVetorParaObjetoIndexado";
import { decoradorSirgas2000, decoradorMapa } from "../../../../../util/decoradores/decoradorMapa";
import { resetarLatLongInvalidaAoTrocarHemisferio } from "../../../../../util/decoradores/decoradorSeletorCoordenadas";
import { Form } from "react-final-form";
import { setValue } from "../../../../../util/mutadores";
import { useAtualizarPontosAtribuidosEstacao } from "../../../hooks/atualizar-pontos-atribuidos-estacao";
import { useCarregarVisitasEstacao } from "../../../hooks/carregar-visitas-estacao";
import { useCarregarColetoresProjeto } from "../../../hooks/carregar-coletores-projeto";

const AmostrasAprovacao = ({ atividade }) => {
    const [visitas, setVisitas] = useState(null);
    const [idVisitaSelecionada, setIdVisitaSelecionada] = useState(null);
    const [area, setArea] = useState(null);
    const [subArea, setSubarea] = useState(null);
    const [idProjeto, setIdProjeto] = useState(null);
    const [coletores, setColetores] = useState(null);

    //#region Bibliotecas
    const [metodosGeoposicionamento, setMetodosGeoposicionamento] = useState(null);
    const [categoriasLimiteArea, setCategoriasLimiteArea] = useState(null);
    const [datums, setDatums] = useState(null);
    const [minerais, setMinerais] = useState(null);
    const [prefixos, setPrefixos] = useState(null);
    const [complementos, setComplementos] = useState(null);
    const [cores, setCores] = useState(null);
    const [tiposIlustracao, setTiposIlustracao] = useState(null);
    const [extensoesIlustracao, setExtensoesIlustracao] = useState(null);
    const [mineraisPorId, setMineraisPorId] = useState(null);
    const [prefixosPorId, setPrefixosPorId] = useState(null);
    const [complementosPorId, setComplementosPorId] = useState(null);
    const [classesAmostra, setClassesAmostra] = useState(null);
    //#endregion

    const [atividadesVisitaPorTipo, setAtividadesVisitaPorTipo] = useState(null);
    const [atividadesVisita, setAtividadesVisita] = useState(null);

    const [prefixoNome,] = useState("")
    const [atividadeEmAprovacao,] = useState(atividade);
    const [idEstacao,] = useState(atividade.idEstacao);
    const pontosAtribuidosAEstacao = useRef([]);
    const [visitaAtividade, setVisitaAtividade] = useState(null);
    const [abrirFormAmostras, ] = useState(false);

    const decoradores = [decoradorSirgas2000(atividade.idVisita), decoradorMapa(), resetarLatLongInvalidaAoTrocarHemisferio()];

    const atualizarPontosAtribuidosAEstacao = useAtualizarPontosAtribuidosEstacao({
        pontosAtribuidosAEstacao,
        idEstacao
    });

    const carregarVisitasEstacao = useCarregarVisitasEstacao({
        idEstacao,
        setVisitas
    })

    const carregarColetores = useCarregarColetoresProjeto({
        idProjeto,
        coletores,
        setColetores
    });

    useEffect(() => {
        if (!visitas)
            carregarVisitasEstacao();

        atualizarPontosAtribuidosAEstacao();

        if (!idVisitaSelecionada)
            setIdVisitaSelecionada(atividadeEmAprovacao.idVisita);

        if (!visitaAtividade && visitas !== null) {
            const visita = visitas.find((visita) => visita.id === atividadeEmAprovacao.idVisita.toString());
            setVisitaAtividade(visita);
            if (!area)
                setArea(visita.area);
            if (!subArea)
                setSubarea(visita.subArea);
            if (!idProjeto)
                setIdProjeto(visita.idProjeto);
            if (idProjeto !== null)
                carregarColetores();
        }
    }, [atualizarPontosAtribuidosAEstacao, carregarColetores, carregarVisitasEstacao,
        idProjeto,
        idVisitaSelecionada,
        subArea,
        visitaAtividade,
        visitas,
        area,
        atividadeEmAprovacao]);

    useEffect(() => {
        if (!idVisitaSelecionada)
            return;
    }, [idVisitaSelecionada]);

    useEffect(() => {
        if (!idProjeto || !idVisitaSelecionada)
            return;

        const listaBibliotecas = [
            coletores,
            metodosGeoposicionamento,
            categoriasLimiteArea,
            datums,
            minerais,
            prefixos,
            complementos,
            cores,
            classesAmostra,
            tiposIlustracao,
            extensoesIlustracao,
            mineraisPorId,
            prefixosPorId,
            complementosPorId,
            classesAmostra,
            atividadesVisitaPorTipo,
            atividadesVisita
        ];

        if (!listaBibliotecas.some(biblioteca => !biblioteca)) {
            return;
        }

        Promise.all(
            [Service("/gps/datums").query(),
            Service("/minerais").query(),
            Service("/prefixos").query(),
            Service("/complementos").query(),
            Service("/cores").query(),
            Service("/tiposIlustracao").query(),
            Service("/extencoesIlustracao").query(),
            Service("/categoriasLimiteArea").query(),
            Service("/metodosGeoPosicionamento").query(),
            Service("classesAmostraIndependente").query(),
            Service(`/atividades/tipoAtividade/visita/${idVisitaSelecionada}`).query(),
            Service(`/atividades/visita/${idVisitaSelecionada}`).query(),
            Service(`/coletores/paraProjeto/${idProjeto}`).query(),
            ]
        ).then(([
            datumResponse,
            mineraisResponse,
            prefixosResponse,
            complementosResponse,
            coresResponse,
            tiposIlustracaoResponse,
            extensoesIlustracaoResponse,
            categoriasLimiteAreaResponse,
            metodosGeoposicionamentoResponse,
            classesAmostraResponse,
            atividadesVisitaPorTipoResponse,
            atividadesVisitaResponse,
            coletoresProjetoResponse,
        ]) => {
            setDatums(datumResponse.data.dados);
            setMinerais(mineraisResponse.data.dados);
            setPrefixos(prefixosResponse.data.dados);
            setComplementos(complementosResponse.data.dados);
            setCores(coresResponse.data.dados);
            setTiposIlustracao(tiposIlustracaoResponse.data.dados);
            setExtensoesIlustracao(extensoesIlustracaoResponse.data.dados);
            setCategoriasLimiteArea(categoriasLimiteAreaResponse.data.dados);
            setMetodosGeoposicionamento(metodosGeoposicionamentoResponse.data.dados);
            setAtividadesVisitaPorTipo(atividadesVisitaPorTipoResponse.data.dados);
            setAtividadesVisita(atividadesVisitaResponse.data.dados);
            setColetores(coletoresProjetoResponse.data.dados);
            setClassesAmostra(classesAmostraResponse.data.dados);

            setMineraisPorId(converterVetorParaObjetoIndexado(mineraisResponse.data.dados));
            setPrefixosPorId(converterVetorParaObjetoIndexado(prefixosResponse.data.dados));
            setComplementosPorId(converterVetorParaObjetoIndexado(complementosResponse.data.dados));
        });
    }, [idProjeto, idVisitaSelecionada]);

    const AtividadeCarregada = useCallback(({ referenciaForm }) => {
        const listaDependencias = [visitas,
            idVisitaSelecionada,
            area,
            subArea,
            coletores,
            metodosGeoposicionamento,
            categoriasLimiteArea,
            datums,
            minerais,
            prefixos,
            complementos,
            cores,
            classesAmostra,
            tiposIlustracao,
            extensoesIlustracao,
            mineraisPorId,
            prefixosPorId,
            complementosPorId,
            atividadesVisitaPorTipo,
            atividadesVisita
        ]

        if (listaDependencias.some(biblioteca => !biblioteca)) {
            return <></>;
        }
        return (<Amostras
            atualizarPontosGps={atualizarPontosAtribuidosAEstacao}
            exibirBotaoInsercao={false}
            idAtividade={atividadeEmAprovacao.id}
            prefixoNome={prefixoNome}
            permitirEdicao={false}
            editando={false}
            referenciaForm={referenciaForm}
            idEstacao={idEstacao}
            pontosAtribuidosAEstacao={pontosAtribuidosAEstacao.current}
            metodosGeoposicionamento={metodosGeoposicionamento}
            datums={datums}
            tiposIlustracao={tiposIlustracao}
            extencoesIlustracao={extensoesIlustracao}
            minerais={minerais}
            mineraisPorId={mineraisPorId}
            complementosPorId={complementosPorId}
            prefixosPorId={prefixosPorId}
            area={area}
            subArea={subArea}
            visitaSelecionada={idVisitaSelecionada}
            visitas={visitas}
            categoriasLimiteArea={categoriasLimiteArea}
            idProjeto={idProjeto}
            coletores={coletores}
            idColetor={visitaAtividade.idUsuarioColetor}
            //idMetodoGeoposicionamento={valoresIniciaisAtividades.idMetodoGeoposicionamento}
            //altitude={valoresIniciaisAtividades.altitude}
            atualizaAtividades={() => { }}
            coordenadas={atividadeEmAprovacao.coordenadas}
            atividades={atividadesVisita}
            classesAmostra={classesAmostra}
            abrirFormAmostras={abrirFormAmostras}
            atividadeEmEdicao={atividadeEmAprovacao}
            aoFecharSubFormAtividades={() => { }}
            atividadesVisita={atividadesVisitaPorTipo}
            permitirInsercao={false}
        />);

    }, [abrirFormAmostras,
        area,
        atividadeEmAprovacao,
        atividadesVisitaPorTipo,
        atividadesVisita,
        atualizarPontosAtribuidosAEstacao,
        categoriasLimiteArea, classesAmostra,
        coletores,
        complementos,
        complementosPorId,
        cores,
        datums,
        extensoesIlustracao,
        idEstacao,
        idProjeto,
        idVisitaSelecionada,
        metodosGeoposicionamento,
        minerais,
        mineraisPorId,
        prefixoNome,
        prefixos,
        prefixosPorId,
        subArea,
        tiposIlustracao,
        visitaAtividade,
        visitas]);


    return (
        <Form id="formAtividadeAmostras"
            onSubmit={() => { }}
            decorators={decoradores}
            initialValues={atividade}
            mutators={{ setValue }}
            render={(formProps) => {
                return (<AtividadeCarregada referenciaForm={formProps} />);
            }} />
    );
}

export { AmostrasAprovacao };