import React, { PureComponent } from "react";
import { Field } from "react-final-form";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { DropdownListField } from "../../../../../components/field/DropdownListField";
import geoquimicaService from "../../../../../service/GeoquimicaService";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ComboItemModelLike } from "../../../../../models/combo-item.model";
import { campoObrigatorioComMsgGenerica, comporValidadores } from "../../../../../util/validadores";

export interface CaracterizacaoAmbienteSectionFormProps {
  prefixoNome: string;
}

export interface CaracterizacaoAmbienteSectionFormState {

  rochasRegionais: ComboItemModelLike[];
  idadesGeologicas: ComboItemModelLike[];
  pluviosidades: ComboItemModelLike[];
  situacoesTopograficas: ComboItemModelLike[];
  relevos: ComboItemModelLike[];
  influenciasAntropicas: ComboItemModelLike[];
  tiposVegetacao: ComboItemModelLike[];
}

export class CaracterizacaoAmbienteSectionForm extends PureComponent<CaracterizacaoAmbienteSectionFormProps, CaracterizacaoAmbienteSectionFormState> {


  private lifecycle = new Subject();

  constructor(props) {
    super(props);
    this.state = {
      rochasRegionais: [],
      idadesGeologicas: [],
      pluviosidades: [],
      situacoesTopograficas: [],
      relevos: [],
      influenciasAntropicas: [],
      tiposVegetacao: []
    };
  }

  componentDidMount() {
    geoquimicaService.listRochaRegionalOptions().pipe(takeUntil(this.lifecycle)).subscribe(data => {
      this.setState({ rochasRegionais: data });
    });

    geoquimicaService.listIdadeGeologicaOptions().pipe(takeUntil(this.lifecycle)).subscribe(data => {
      this.setState({ idadesGeologicas: data });
    });

    geoquimicaService.listPluviosidadeOptions().pipe(takeUntil(this.lifecycle)).subscribe(data => {
      this.setState({ pluviosidades: data });
    });

    geoquimicaService.listSituacaoTopograficaOptions().pipe(takeUntil(this.lifecycle)).subscribe(data => {
      this.setState({ situacoesTopograficas: data });
    });

    geoquimicaService.listRelevoOptions().pipe(takeUntil(this.lifecycle)).subscribe(data => {
      this.setState({ relevos: data });
    });

    geoquimicaService.listInfluenciaAntropica().pipe(takeUntil(this.lifecycle)).subscribe(data => {
      this.setState({ influenciasAntropicas: data });
    });

    geoquimicaService.listTipoVegetacaoOptions().pipe(takeUntil(this.lifecycle)).subscribe(data => {
      this.setState({ tiposVegetacao: data });
    });
  }

  componentWillUnmount() {
    this.lifecycle.next();
    this.lifecycle.complete();
  }

  render() {
    const objFieldName = 'descricaoAmbiente.';

    return <Card className="mt-4 w-100 card-mb2">
      <Card.Body>
        <Card.Title>Caracterização do ambiente</Card.Title>
        <Row className="my-3">
          <Col>
            <Field
              component={DropdownListField}
              name={`${objFieldName}rochaRegional`}
              label="Rocha Regional"
              elementos={this.state.rochasRegionais}
            />
          </Col>
          <Col>
            <Field
              component={DropdownListField}
              name={`${objFieldName}idadeGeologica`}
              label="Idade geológica"
              elementos={this.state.idadesGeologicas}
            />
          </Col>
          <Col>
            <Field
              component={DropdownListField}
              name={`${objFieldName}pluviosidade`}
              label="Pluviosidade"
              elementos={this.state.pluviosidades}
            />
          </Col>
        </Row>
        <Row className="my-3">

          <Col>
            <Field
              component={DropdownListField}
              name={`${objFieldName}situacaoTopografica`}
              label="Situação topográfica"
              elementos={this.state.situacoesTopograficas}
            />
          </Col>
          <Col>
            <Field
              component={DropdownListField}
              name={`${objFieldName}relevo`}
              label="Relevo"
              elementos={this.state.relevos}
            />
          </Col>
          <Col>
            <Field
              component={DropdownListField}
              name={`${objFieldName}influenciaAntropica`}
              label="Influência antrópica"
              elementos={this.state.influenciasAntropicas}
            />
          </Col>
        </Row>
        <Row className="my-3">
          <Col>
            <Field
              component={DropdownListField}
              name={`${objFieldName}tipoVegetacao`}
              label="Tipo vegetação"
              elementos={this.state.tiposVegetacao}
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  }
}
