import React from "react";
import PropTypes from "prop-types";
import ModalBS from "react-bootstrap/Modal"

function Modal({ children, titulo, mostrarBotaoFechar = true, aberto, aoFechar, tamanho = "sm", ...otherProps }) {
    const navbar = otherProps.navbar;
    const footer = otherProps.footer;
    return <ModalBS size={tamanho}
                  show={aberto}
                  onHide={aoFechar}
                  scrollable={otherProps.scrollable}
                  >
        <ModalBS.Header closeButton={mostrarBotaoFechar}>
            { titulo &&
                <ModalBS.Title>
                    {titulo}
                </ModalBS.Title>
            }
            { otherProps.comNavBar &&
                <>
                {navbar}
                </>
            }
        </ModalBS.Header>
        <ModalBS.Body>
            {children}
        </ModalBS.Body>
        { otherProps.comFooter &&
                <>
                {footer}
                </>
            }
            
    </ModalBS>
}

Modal.propTypes = {
    children: PropTypes.node,
    titulo: PropTypes.string,
    mostrarBotaoFechar: PropTypes.bool,
    aberto: PropTypes.bool,
    aoFechar: PropTypes.func,
    tamanho: PropTypes.oneOf(['sm', 'lg', 'xl']),
}

export {Modal};
