import { FORM_ERROR } from "final-form";
import React, {useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { Field } from "react-final-form";
import swal from "sweetalert";
import { DropdownListField } from "../../../../../components/field/DropdownListField";
import { HiddenField } from "../../../../../components/field/HiddenField";
import { TextInputField } from "../../../../../components/field/TextInputField";
import { LegacySubForm } from "../../../../../components/SubForm";
import { resolverExibidos } from "../../../../../components/SubForm/SubForm";
import { TIPO_ROCHA_AFLORAMENTO_SEDIMENTAR } from "../../../../../util/constantes";
import { campoObrigatorioComMsgGenerica, validarSe } from "../../../../../util/validadores/index"
import { resolverRequestAmostras, resolverRequestBaseGeo } from "../AmostrasUtils/AmostraRequestUtils";
const AtividadesAssociadas = (props) => {
  const [tipoAtividade] = useState([
    { id: "RECURSO_MINERAL", nome: "Recurso mineral" },
    { id: "AFLORAMENTO", nome: "Afloramento" }
  ]);

  const [materialGeologico, setMaterialGeologico] = useState([]); 



  let refForm = null

  const colunasAtividadesAssociadas = [
    {
      text: "Tipo de atividade", datafield: "tipoAtividade", width: "15%",
      cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
        let linhaFormatada = montarCellRenderPadrao(tipoAtividade, rowdata.tipoAtividade);
        return linhaFormatada;
      },
    },
    { text: "Número de campo", datafield: "numeroCampoAtividade", width: "15%" },
    {
      text: "Material geológico", datafield: "idMaterialGeologico", width: "61%",
      cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
        let elemento = "";
        props.materiaisGeologicosAfloramento?.forEach(item => {
          if (item?.id?.toString() === rowdata?.idMaterialGeologico?.toString()) {
            elemento = item;
          }
        });
        if (elemento === "") {
          props.materiaisGeologicosRecursoMineral?.forEach(item => {
            if (item?.id?.toString() === rowdata?.idMaterialGeologico?.toString()) {
              elemento = item;
            }
          });
        }

        return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${elemento.nome ?? ""}</div>`;
      },
    },
  ];

  const datafieldsAtividadesAssociadas = [
    { name: "tipoAtividade", type: "string", map: "tipoAtividade" },
    { name: "numeroCampoAtividade", type: "string", map: "numeroCampoAtividade" },
    { name: "idMaterialGeologico", type: "string", map: "idMaterialGeologico" },
  ];

  useEffect(() => {
    const fetchMaterialGeologico = async () => {
      const data = await resolverRequestBaseGeo(`/materialgeologico/visita/${props.visitaSelecionada}`);
      setMaterialGeologico(data);
    };

    fetchMaterialGeologico();
  }, [props.visitaSelecionada]);

  const onSubmitSubForm = ({ alteracoesForm }) => {
    props.referenciaForm?.batch(() => {
      for (const [key, val] of Object.entries(alteracoesForm)) {
        props.referenciaForm?.mutators?.setValue(`${key}`, val);
      }
    });
  }


  const montarCellRenderPadrao = (items, nomeChave) => {
    const item = items.find(item => item?.id?.toString() === nomeChave?.toString());
    const nome = item ? item.nome : '';
    return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${nome}</div>`;
  };



  const atividadePermiteCompatibilizacao = (entidade) => {
    try {
      const amostrasDefinitivo = props.referenciaForm?.getState()?.values?.modoCadastro === 'DEFINITIVO';
      const afloraDefinitivo = props.atividadeAflora && props.atividadeAflora.modoCadastro === 'DEFINITIVO';
      const idMaterialGeologico = entidade.idMaterialGeologico;

      return idMaterialGeologico && amostrasDefinitivo && afloraDefinitivo && entidade && entidade.tipoAtividade === 'AFLORAMENTO';
    } catch (err) {
      console.log('Erro ao verificar se o botão de compatibilização pode ser ativado', err);
      return false;
    }
  };

  const compatibilizarCampos = async (id, rowdata) => {
    const idMaterialGeologico = parseInt(rowdata.rowData.idMaterialGeologico);

    if (!idMaterialGeologico) return;

    const classesPossiveis = [8, 9, 14, 16];
    const idClasse = props.referenciaForm.getState().values.idClasse;

    if (props.referenciaForm.getState().values.modoCadastro !== 'DEFINITIVO') return;

    if (!classesPossiveis.find(id => id === idClasse)) {
      return swal({
        title: "A classe de amostra selecionada não possui compatibilização com o afloramento",
        icon: 'info',
        buttons: { confirm: 'Entendido' }
      });
    }

    const alteracoesHidro = resolverExibidos(props.atividadeAflora.alteracoesHidrotermais);
    const alteracaoUtilizada = alteracoesHidro.find(a => a.idRocha === idMaterialGeologico);
    const tipoAlteracao = alteracaoUtilizada ? alteracaoUtilizada.idTipo : undefined;

    const intemperismos = resolverExibidos(props.atividadeAflora.intemperismos);
    const intemperismoUtilizado = intemperismos.find(i => i.idRocha === idMaterialGeologico);
    const grauIntemperismo = intemperismoUtilizado ? intemperismoUtilizado.idGrau : undefined;

    const rochas = resolverExibidos(props.atividadeAflora.rochas);
    const rochaUtilizada = rochas.find(r => r.id === idMaterialGeologico);
    const idsTexturasNovas = rochaUtilizada?.idsTexturas || [];

    let listaTexturas = await resolverRequestAmostras("/biblioteca/texturarocha");
    let listaHidrotermal = await resolverRequestAmostras("/biblioteca/tipoalteracao");
    let listaIntemperismos = await resolverRequestAmostras("/biblioteca/grauintemperismo");

    let grauIntemperismoNovo = listaIntemperismos.find(item => item.id === grauIntemperismo);
    let tipoAlteracaoNovo = listaHidrotermal.find(item => item.id === tipoAlteracao);
    let texturasNovo = idsTexturasNovas.map(id => listaTexturas.find(item => item.id === id));

    let grauIntemperismoAntigo;
    let tipoAlteracaoAntigo;
    let texturasAntigas = [];

    let nomeClasse = 'rocha';
    let atributoGrauIntemperismo = 'idGrauIntemperismo';

    if (idClasse === 8) nomeClasse = 'minerio';
    else if (idClasse === 14) nomeClasse = 'outros';
    else if (idClasse === 16) {
      nomeClasse = 'crosta';
      atributoGrauIntemperismo = 'idGrauIntemperismoCrosta';
    }

    let idsTexturasAntigas = props.formPropsPai.form.getState().values[nomeClasse]?.idsTexturasRocha;

    if (idsTexturasAntigas) {
      texturasAntigas = idsTexturasAntigas.map(id => listaTexturas.find(item => item.id === id));
    }

    grauIntemperismoAntigo = listaIntemperismos.find(item => item.id === props.formPropsPai.form.getState().values[nomeClasse]?.[atributoGrauIntemperismo]);
    tipoAlteracaoAntigo = listaHidrotermal.find(item => item.id === props.formPropsPai.form.getState().values[nomeClasse]?.idTipoAlteracao);

    let texto = '';

    texto += "\nTextura";

    if (idClasse !== 9 || rochaUtilizada?.idTipoRocha === TIPO_ROCHA_AFLORAMENTO_SEDIMENTAR.id) {
      texto += "\nNão se aplica";
    } else {
      texto += "\nComo está no Amostra: " + texturasAntigas.map(i => i.nome).toString().replace(/,/g, ', ');
      texto += "\nComo ficará no Amostra: " + texturasNovo.map(i => i.nome).toString().replace(/,/g, ', ');
    }

    texto += "\n\nGrau de intemperismo";
    texto += "\nComo está no Amostra: " + (grauIntemperismoAntigo ? grauIntemperismoAntigo.nome : '');
    texto += "\nComo ficará no Amostra: " + (grauIntemperismoNovo ? grauIntemperismoNovo.nome : '');

    texto += "\n\nTipo de alteração hidrotermal";

    if (idClasse !== 16) {
      texto += "\nComo está no Amostra: " + (tipoAlteracaoAntigo ? tipoAlteracaoAntigo.nome : '');
      texto += "\nComo ficará no Amostra: " + (tipoAlteracaoNovo ? tipoAlteracaoNovo.nome : '');
    } else {
      texto += "\nNão se aplica";
    }

    const modalCompatibilizacao = (compatibilizarFunc) => {
      swal({
        title: `Há preenchimento no cadastro do Afloramento (${props.atividadeAflora.numeroCampo}).\n Deseja utilizar os dados ?`,
        icon: 'info',
        buttons: { cancel: "Não, cancelar", confirm: { text: "Sim", value: true, className: 'btn-primary' } },
      })
        .then((value) => {
          if (value) {
            swal({
              title: 'Resultado da compatibilização com o Afloramento',
              text: texto,
              buttons: { cancel: "Não sincronizar", confirm: { text: "Confirmar", value: true, className: 'btn-primary' } },
            })
              .then((value) => {
                if (value) compatibilizarFunc();
              });
          }
        });
    };
    const modalSemCamposParaCompatibilizar = () => {
      return swal({
        title: "O afloramento não possui campos para compatibilizar com a Amostra",
        icon: 'info',
        buttons: { confirm: 'Entendido' }
      });
    }
    switch (idClasse) {
      // Minerio
      case 8: {
        if ((grauIntemperismo === null || grauIntemperismo === undefined || grauIntemperismo === '') && !tipoAlteracao)
          return modalSemCamposParaCompatibilizar();

        modalCompatibilizacao(() => {
          if (grauIntemperismo !== null && grauIntemperismo !== undefined && grauIntemperismo !== '')
            props.referenciaForm.mutators.setValue('minerio.idGrauIntemperismo', grauIntemperismo);

          if (tipoAlteracao)
            props.referenciaForm.mutators.setValue('minerio.idTipoAlteracao', tipoAlteracao);
        });
        break;
      }

      // Rocha
      case 9:
        if ((grauIntemperismo === null || grauIntemperismo === undefined || grauIntemperismo === '') && !tipoAlteracao && (!idsTexturasNovas || !idsTexturasNovas.length))
          return modalSemCamposParaCompatibilizar();

        modalCompatibilizacao(() => {
          if (idsTexturasNovas && idsTexturasNovas.length)
            props.referenciaForm.mutators.setValue('rocha.idsTexturasRocha', idsTexturasNovas);

          if (grauIntemperismo !== null && grauIntemperismo !== undefined && grauIntemperismo !== '')
            props.referenciaForm.mutators.setValue('rocha.idGrauIntemperismo', grauIntemperismo);

          if (tipoAlteracao)
            props.referenciaForm.mutators.setValue('rocha.idTipoAlteracao', tipoAlteracao);
        });
        break;

      // Outros
      case 14:
        if ((grauIntemperismo === null || grauIntemperismo === undefined || grauIntemperismo === '') && !tipoAlteracao)
          return modalSemCamposParaCompatibilizar();

        modalCompatibilizacao(() => {
          if (grauIntemperismo !== null && grauIntemperismo !== undefined && grauIntemperismo !== '')
            props.referenciaForm.mutators.setValue('outros.idGrauIntemperismo', grauIntemperismo);

          if (tipoAlteracao)
            props.referenciaForm.mutators.setValue('outros.idTipoAlteracao', tipoAlteracao);
        });
        break;

      // Crosta
      case 16:
        if (grauIntemperismo === null || grauIntemperismo === undefined || grauIntemperismo === '')
          return modalSemCamposParaCompatibilizar();

        modalCompatibilizacao(() => {
          if (grauIntemperismo !== null && grauIntemperismo !== undefined && grauIntemperismo !== '')
            props.referenciaForm.mutators.setValue('crosta.idGrauIntemperismoCrosta', grauIntemperismo);
        });
        break;
    }
  };

  const validacoesAtividadesAssociadas = (valores, form) => {
    let associacoes = form.getState().values.associacoes ?? [];
    associacoes = resolverExibidos(associacoes);

    let associacaoRepetida = false;
    const associacao = valores?.id;
    const material = valores?.idMaterialGeologico;

    if (valores) {
      associacoes.forEach((item) => {
        if (material && item.idMaterialGeologico === material && item.id !== associacao) {
          associacaoRepetida = true;
        }
      });
    }

    if (associacaoRepetida) {
      return {
        [FORM_ERROR]: 'Não é possível cadastrar associações repetidas.'
      };
    }
  };


  return (
    <Card className="mb-3" style={{ marginTop: '15px' }}>
      <Card.Body className="p-4 mt-3 mb-4">
        <Field name={"modoCadastro"} subscription={{ value: true }}>
          {({ input: { value: modoCadastro = {} } }) => {

            const validar = modoCadastro === 'DEFINITIVO';

            return <Field name={"associacoes"} subscription={{ value: true }}>
              {({ input: { value: associacoes = {} } }) =>
                <LegacySubForm
                  compatibilizar={compatibilizarCampos}
                  podeCompatibilizar={atividadePermiteCompatibilizacao}
                  permitirCompatibilizacao={true}
                  exibirBotaoInsercao={props.permitirEdicao}
                  permitirEdicao={props.permitirEdicao}
                  permitirExclusao={props.permitirEdicao}
                  permitirVisualizacao={!props.permitirEdicao}
                  permitirInsercao={props.permitirAdicao ? true : false}
                  tituloForm={
                    props.duplicata ? "Associação da amostra" :
                      props.permitirAdicao
                        ? "Associação da amostra"
                        : <Card.Title>Associação da amostra <Card.Subtitle className="mt-2">{props.mensagemDesabilitado}</Card.Subtitle></Card.Title>
                  }
                  nome="associacoes"
                  onSubmit={onSubmitSubForm}
                  colunas={colunasAtividadesAssociadas}
                  campos={datafieldsAtividadesAssociadas}
                  elementos={
                    props.formPropsPai.form.getState().values.associacoes
                  }
                  validar={(valores) => validacoesAtividadesAssociadas(valores, props.referenciaForm)}
                  formSubscription={{}}
                  isAtividadeAssociada={true}
                  renderForm={({ formProps }) => {
                    refForm = formProps.form;
                    return (
                      <>
                        <Row>
                          <Col md={12}>
                            <Card.Title>Associação da amostra</Card.Title>
                          </Col>
                        </Row>
                        <Field
                          component={HiddenField}
                          name="idAtividade"
                        />
                        <Row className="mb-4">
                          <Col md={4}>
                            <Field name={"tipoAtividade"} subscription={{ value: true }}>
                              {({ input: { value: tipoAtividade } }) => {

                                let elementos = [];

                                let hasAfloramento = false;
                                let hasRecmin = false;
                                try {
                                  if (props.formPropsPai.form.getState().values?.associacoes) {
                                    let associacoes = resolverExibidos(props.formPropsPai.form.getState().values?.associacoes);
                                    for (const aux of associacoes) {
                                      if (aux.tipoAtividade === "AFLORAMENTO") {
                                        hasAfloramento = true;
                                      }
                                      if (aux.tipoAtividade === "RECURSO_MINERAL") {
                                        hasRecmin = true;
                                      }
                                    }
                                  }
                                } catch (error) {
                                  console.log(error)
                                }

                                if (formProps.form.getState().values?.tipoAtividade === "RECURSO_MINERAL") {
                                hasRecmin = false;
                              }
                              if (formProps.form.getState().values?.tipoAtividade === "AFLORAMENTO") {
                                hasAfloramento = false;
                              }

                              if (formProps.form.getState().values?.tipoAtividade === "RECURSO_MINERAL") {
                                hasRecmin = false;
                              }
                              if (props.idAfloramentoDaAtividade && !hasAfloramento)
                                  elementos.push({ id: "AFLORAMENTO", nome: "Afloramento" });

                                if (props.idRecursoMineralDaAtividade && !hasRecmin)
                                  elementos.push({ id: "RECURSO_MINERAL", nome: "Recurso mineral" });

                                if (tipoAtividade === "AFLORAMENTO")
                                  formProps.form.mutators.setValue("numeroCampoAtividade", props.numeroCampoAfloramento);

                                if (tipoAtividade === "RECURSO_MINERAL") {
                                  formProps.form.mutators.setValue("numeroCampoAtividade", props.numeroCampoRecursoMineral);
                                  formProps.form.mutators.setValue("idMaterialGeologico", null);
                                }

                                return <Field
                                  disabled={!props.permitirEdicao}
                                  name="tipoAtividade"
                                  component={DropdownListField}
                                  label="Tipo de atividade"
                                  dica="Selecione o tipo de atividade em que foi cadastrado o material geológico que você deseja associar a esta amostra"
                                  elementos={elementos}
                                  required
                                  validate={campoObrigatorioComMsgGenerica(
                                    "Tipo de atividade"
                                  )}
                                />
                              }}
                            </Field>
                          </Col>
                          <Col md={4}>
                            <Field name={"numeroCampoAtividade"} subscription={{ value: true }}>
                              {({ input: { value: numeroCampoAtividade } }) => {
                                let nomeVisita = "";
                                if (formProps.form.getState().values.tipoAtividade === "AFLORAMENTO") {
                                  nomeVisita = props.numeroCampoAfloramento
                                }
                                if (formProps.form.getState().values.tipoAtividade === "RECURSO_MINERAL") {
                                  nomeVisita = props.numeroCampoRecursoMineral
                                }
                                return <Field
                                  name="numeroCampoAtividade"
                                  component={TextInputField}
                                  label="Número de campo"
                                  dica="Número de campo para referência"
                                  initialValue={nomeVisita}
                                  disabled
                                />
                              }}
                            </Field>

                          </Col>
                          <Col md={4}>
                            <Field name={"tipoAtividade"} subscription={{ value: true }}>
                              {({ input: { value: tipoAtividade } }) => {

                                let elementos = [];
                                if (tipoAtividade === "AFLORAMENTO") {
                                  formProps.form.mutators.setValue('idAtividade', props.idAfloramentoDaAtividade);
                                  elementos = props.materiaisGeologicosAfloramento;

                                  return <Field
                                    disabled={!props.permitirEdicao}
                                    name="idMaterialGeologico"
                                    component={DropdownListField}
                                    label="Material geológico"
                                    dica="Selecione o material geológico cadastrado na atividade para associação a amostra descrita"
                                    elementos={elementos}
                                    required={validar}
                                    validate={validarSe(validar, campoObrigatorioComMsgGenerica("Material geológico"))}
                                  />
                                }
                                if (tipoAtividade === "RECURSO_MINERAL") {
                                  formProps.form.mutators.setValue('idAtividade', props.idRecursoMineralDaAtividade);
                                  elementos = props.materiaisGeologicosRecursoMineral;
                                }

                                return <></>
                              }}
                            </Field>
                          </Col>
                        </Row>
                      </>
                    );
                  }}
                />
              }
            </Field>
          }}
        </Field>
      </Card.Body>
    </Card>
  );

}

export default AtividadesAssociadas;
