import { useCallback, useEffect, useMemo, useState } from "react"
import MaskedInput from "./MaskedInput"
import IMask from 'imask'
import { Field } from "react-final-form"
import { HiddenField } from "./HiddenField"

const clerBlocks = {
  d: {
    mask: new IMask.MaskedRange,
    from: 0,
    to: 9999
  },
  s: {
    mask: new IMask.MaskedRange,
    from: 0,
    to: 999999
  },
}

const TextMaskField = ({ idTipoNotacao, disabled, input, meta, ...props }) => {
  const mensagemErro = meta.touched && !meta.valid && meta.error;
  const exemplo = '999999/9999';
  const [valor, setValor] = useState();
  const [mostrarErro, setMostratErro] = useState(false);
  const onChange = useCallback((evt) => {
    setValor(evt.target.value)
    input.onChange(evt)
  }, [input])
  const mascara = useMemo(() => {
    return {
      mask: [
        {
          mask: 's/d',
          blocks: clerBlocks,
        },
        {
          mask: 'd/s',
          blocks: clerBlocks,
        },
      ]
    }
  }, [])
  function validaValorMinimo(valores, form) {
    return 'Preenchimento do campo incompleto(999999/9999).'
  }
  useEffect(() => {
    if (valor) {
      if (valor.length < 11) {
        setMostratErro(true);
      } else {
        setMostratErro(false);
      }
    }
  }, [props, valor, mostrarErro])
  return (
    <>
      <MaskedInput
        placeholderChar={'_'}
        placeholder={exemplo}
        disabled={disabled}
        onChange={onChange}
        mensagemErro={mensagemErro}
        value={input.value}
        {...mascara}
        {...props}
      />
      {mostrarErro &&
        <Field
          name="validaValorMinimo"
          component={HiddenField}
          validate={validaValorMinimo}
        />
      }
    </>
  )
}

export default TextMaskField
