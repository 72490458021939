import { PureComponent } from "react";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { AmostraSubFormPart } from "../components/AmostraSubFormPart";
import {
  amostraDataFieldsCommon,
  AmostraSymbol,
  amostrasLabelsMap,
} from "../constants/amostras.constant";
import { VelocidadeCorrenteField } from "../components/VelocidadeCorrenteField";
import { NivelAguaField } from "../components/NivelAguaField";
import { PosicaoLeitoField } from "../components/PosicaoLeitoField";
import { AreaDrenagemField } from "../components/AreaDrenagemField";
import { TurbidezAguaField } from "../components/TurbidezAguaField";
import { CorSampleField } from "../components/CorSampleField";
import { SituacaoAmostraField } from "../components/SituacaoAmostraField";
import { LarguraRioField } from "../components/LarguraRioField";
import { ProfundidadeRioField } from "../components/ProfundidadeRioField";
import { VolumeOriginalField } from "../components/VolumeOriginalField";
import { ArredondamentoField } from "../components/ArredondamentoField";
import { BateiaSampleGeoquimicaModelLike } from "../../../../../models/activity-geoquimica.model";
import { ImagensAmostraSubForm } from "../components/ImagensAmostraSubForm";
import { AmostraGridSubForm } from "../components/AmostraGridSubForm";

export interface ConcentradoBateiaSectionFormProps {
  prefixoNome: string;
  items?: BateiaSampleGeoquimicaModelLike[];
  onItemsChanged?: (items: BateiaSampleGeoquimicaModelLike[]) => void;
}

export interface ConcentradoBateiaSectionFormState {}

export class ConcentradoBateiaSectionForm extends PureComponent<
  ConcentradoBateiaSectionFormProps,
  ConcentradoBateiaSectionFormState
> {
  datafieldsConcentradoBateia = [
    ...amostraDataFieldsCommon,
    {
      name: "id_situacao_amostra",
      type: "number",
      map: "id_situacao_amostra",
    },
    {
      name: "nu_largura_rio",
      type: "number",
      map: "nu_largura_rio",
    },
    {
      name: "nu_profundidade_rio",
      type: "number",
      map: "nu_profundidade_rio",
    },
    {
      name: "id_velocidade_corrente",
      type: "number",
      map: "id_velocidade_corrente",
    },
    {
      name: "id_nivel_agua",
      type: "number",
      map: "id_nivel_agua",
    },
    {
      name: "id_posicao_leito",
      type: "number",
      map: "id_posicao_leito",
    },
    {
      name: "arredondamento",
      type: "string",
      map: "arredondamento",
    },
    {
      name: "nu_volume_original",
      type: "number",
      map: "nu_volume_original",
    },
    {
      name: "id_cor_material_coletado",
      type: "number",
      map: "id_cor_material_coletado",
    },
    {
      name: "id_area_drenagem",
      type: "number",
      map: "id_area_drenagem",
    },
    {
      name: "id_turbidez",
      type: "number",
      map: "id_turbidez",
    },
    {
      name: "id_cor_agua",
      type: "number",
      map: "id_cor_agua",
    },
  ];

  amostraSymbol = AmostraSymbol.ConcentradoBateia;

  constructor(props) {
    super(props);
    this.state = {};
  }

  renderForm = ({ editando, prefixoNome }) => {
    return (
      <>
        <AmostraSubFormPart
          prefixoNome={prefixoNome}
          amostraSymbol={this.amostraSymbol}
          profundidadeMinRequired={true}
          profundidadeMaxRequired={true}
        />
        <Row className="my-3">
          <Col>
            <SituacaoAmostraField prefixoNome={prefixoNome} required={true} />
          </Col>
          <Col>
            <LarguraRioField
              prefixoNome={prefixoNome}
              name="nu_largura_rio"
              required={true}
            />
          </Col>
          <Col>
            <ProfundidadeRioField
              prefixoNome={prefixoNome}
              name="nu_profundidade_rio"
              required={true}
            />
          </Col>
        </Row>
        <Row className="my-3">
          <Col>
            <VelocidadeCorrenteField
              prefixoNome={prefixoNome}
              name="id_velocidade_corrente"
              required={true}
            />
          </Col>
          <Col>
            <NivelAguaField
              prefixoNome={prefixoNome}
              name="id_nivel_agua"
              required={true}
            />
          </Col>
          <Col>
            <PosicaoLeitoField
              prefixoNome={prefixoNome}
              name="id_posicao_leito"
              required={true}
            />
          </Col>
        </Row>
        <Row className="my-3">
          <Col>
            <ArredondamentoField
              prefixoNome={prefixoNome}
              label="Grau de arredondamento"
              required={true}
            />
          </Col>
          <Col>
            <VolumeOriginalField
              prefixoNome={prefixoNome}
              name="nu_volume_original"
              required={true}
            />
          </Col>
          <Col>
            <CorSampleField
              prefixoNome={prefixoNome}
              name="id_cor_material_coletado"
              label="Cor do material coletado"
              required={true}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <AreaDrenagemField
              prefixoNome={prefixoNome}
              name="id_area_drenagem"
              required={true}
            />
          </Col>
          <Col>
            <TurbidezAguaField
              prefixoNome={prefixoNome}
              name="id_turbidez"
              required={true}
            />
          </Col>
          <Col>
            <CorSampleField
              prefixoNome={prefixoNome}
              name={`id_cor_agua`}
              label="Cor água"
              required={true}
            />
          </Col>
        </Row>
        <Row className="my-3">
          <Col>
            <ImagensAmostraSubForm prefixoNome={prefixoNome} />
          </Col>
        </Row>
      </>
    );
  };

  render() {
    return (
      <>
        <Card className="my-3">
          <Card.Body>
            <Card.Subtitle>
              {amostrasLabelsMap.get(this.amostraSymbol)}
            </Card.Subtitle>
            <AmostraGridSubForm
              name="amostraBateia"
              datafields={this.datafieldsConcentradoBateia}
              items={this.props.items}
              onItemsChanged={this.props.onItemsChanged}
              renderForm={this.renderForm}
            />
          </Card.Body>
        </Card>
      </>
    );
  }
}
