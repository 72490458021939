import { FORM_ERROR } from "final-form";
import { resolverExibidos } from "../../../../components/SubForm/SubForm";

export const calcularSomaPercentuais = (percentuais) => {
  let soma;
  if (percentuais) {
    soma = percentuais.reduce((soma, item) => {
      const numeroPercentual = item || 0;
      return Number(Number(soma) + Number(numeroPercentual));
    }, 0);
  }
  return soma;
};

export const buscaPercentuais = (dados) => {

  if (!dados || !dados.length) {
    return;
  }
  const retorno = [];
  dados.forEach((item) => {
    if (item?.numeroPercentual) {
      retorno.push(item?.numeroPercentual);
    }
  });
  return retorno;
}

export const buscaPercentuaisParaEstimativaModal = (dados) => {

  if (!dados || !dados.length) {
    return;
  }
  const retorno = [];
  dados.forEach((item) => {

    for (let campo in item) {
      if (campo !== 'id') {
        retorno.push(item[campo]);
      }
    }

  });
  return retorno;
}

export const buscaPercentuaisParaPorosidade = (dados) => {

  if (!dados || !dados.length) {
    return;
  }
  const retorno = [];
  dados.forEach((item) => {
    if (item?.porcentagemPoros) {
      retorno.push(item?.porcentagemPoros);
    }
  });
  return retorno;
}

export const validaGranulometriaMinima = ((value, allValues, fieldState) => {
  if (allValues?.granulometriaMinima > allValues?.granulometriaMaxima) {
    return "Valor mínimo não pode ser maior que o valor máximo.";
  }
})

export const validaGranulometriaMaxima = ((value, allValues, fieldState) => {
  if (allValues?.granulometriaMaxima < allValues?.granulometriaMinima) {
    return "Valor máximo não pode ser menor que o valor mínimo.";
  }
})


export const validacoesGeraisArcabouco = (valores) => {
  let fragmentosMineraisExibidos = []
  let fragmentosLiticosExibidos = []
  let componentesAloquimicosExibidos = []
  if (valores?.fragmentosMinerais || valores?.fragmentosLiticos || valores?.componentesAloquimicos) {
    fragmentosMineraisExibidos = resolverExibidos(valores?.fragmentosMinerais||[])
    fragmentosLiticosExibidos = resolverExibidos(valores?.fragmentosLiticos||[])
    componentesAloquimicosExibidos = resolverExibidos(valores?.componentesAloquimicos||[])
  }

  if (fragmentosMineraisExibidos?.length === 0 && fragmentosLiticosExibidos?.length === 0 && componentesAloquimicosExibidos?.length === 0) {
    return { [FORM_ERROR]: "Ao menos um dos subformularios deve ser preenchido." }
  }

  const fragmentosLiticosTitulo = "Fragmentos Líticos"
  const fragmentosMineraisTitulo = "Fragmentos Minerais"
  const componentesAloquimicosTitulo = "Componentes Aloquímicos"

  const validacaoFragmentosMinerais = validarPorcentagemTotal(valores, 'fragmentosMinerais', 'porcentagemArcabouco');
  const validacaoFragmentosLiticos = validarPorcentagemTotal(valores, 'fragmentosLiticos', 'porcentagemFracao');
  const validacaoComponentesAloquimicos = validarPorcentagemTotal(valores, 'componentesAloquimicos', 'porcentagemFracao');

  let titulosInvalidos = [];
  if (validacaoFragmentosMinerais) {
    titulosInvalidos.push(fragmentosMineraisTitulo);
  }

  if (validacaoFragmentosLiticos) {
    titulosInvalidos.push(fragmentosLiticosTitulo);
  }

  if (validacaoComponentesAloquimicos) {
    titulosInvalidos.push(componentesAloquimicosTitulo);
  }

  const titulosInvalidosStr = titulosInvalidos.join(', ');

  if (validacaoFragmentosMinerais || validacaoFragmentosLiticos || validacaoComponentesAloquimicos) {
    return { [FORM_ERROR]: `A soma das porcentagens em cada subform a seguir deve ser 100%: ${titulosInvalidosStr}.` }
  }


  return null;
};


export const validacoesGeraisMatriz = (valores) => {
  if (valores?.fasesMinerais) {
    let fasesMineraisTela = resolverExibidos(valores?.fasesMinerais);
    if (fasesMineraisTela.length > 0) {
      const validacaoFragmentosMinerais = validarPorcentagemTotal(valores, 'fasesMinerais', 'porcentagemFracao');
      if (validacaoFragmentosMinerais) {
        return { [FORM_ERROR]: "A soma das porcentagens das Fases Minerais deve ser 100%." }
      }
    }
  }

};
export const buscaPercentuaisArcabouco = (dados, valor) => {
  if (!dados || !dados.length) {
    return;
  }
  const retorno = [];
  dados.forEach((item) => {
    if (item[valor]) {
      retorno.push(item[valor]);
    }
  });
  return retorno;
}
export const validarPorcentagemTotal = (valores, propriedade, campo) => {
  const itens = valores?.[propriedade];
  if (!itens) {
    return false;
  }

  const itensTela = resolverExibidos(itens);
  if (!itensTela || itensTela.length <= 0) {
    return false;
  }

  const totalPorcentagem = itensTela.reduce((total, objeto) => total + objeto?.[campo], 0);
  if (totalPorcentagem !== 100) {
    return true;
  }

  return false;
};

export const validarCamposVazios = (valores, propriedade, campo) => {
  const itens = valores?.[propriedade];
  if (!itens) {
    return false;
  }

  return false;
};

export function forcarLimparComboBox(className) {
  setTimeout(() => {
    try {
      document.getElementsByClassName(className)[0].children[1].children[0].children[0].textContent = 'Selecione..';
    } catch (err) {
      console.log('Erro ao limpar combo', err);
    }
  }, 50);
}

export function forcarValorCampo(className, valor) {
  setTimeout(() => {
    try {
      document.getElementsByClassName(className)[0].children[1].children[0].children[0].textContent = valor ? valor : '';
    } catch (err) {
      console.log('Erro ao forçar valor na combo', err);
    }
  }, 50);
}