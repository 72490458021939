import http from "../service/http-commons";

const PREFIXO = "files/";

/**
 * action types
 */
export const LIMPAR_ARQUIVOS = PREFIXO + "LIMPAR_ARQUIVOS";
export const REMOVER_ARQUIVO = PREFIXO + "REMOVER_ARQUIVO";
export const RETORNAR_ARQUIVO = PREFIXO + "RETORNAR_ARQUIVO";
export const ADICIONAR_ARQUIVO = PREFIXO + "ADICIONAR_ARQUIVO";
export const ERRO_AO_CARREGAR = PREFIXO + "ERRO_AO_CARREGAR";
export const CARREGADO_COM_SUCESSO = PREFIXO + "CARREGADO_COM_SUCESSO";
export const INICIAR_CARREGAMENTO = PREFIXO + "INICIAR_ENVIO";
export const ATUALIZAR_CHAVE = PREFIXO + "ATUALIZAR_CHAVE";
export const SINCRONIZAR_ARQUIVOS = PREFIXO + "SINCRONIZAR_ARQUIVOS";

/*
 * action creators
 */

const iniciarCarregamento = ({ key, store }) => ({
    type: INICIAR_CARREGAMENTO, payload: { key, store }
});

const erroAoCarregar = ({ key, store, erro }) => ({
    type: ERRO_AO_CARREGAR, payload: { key, store, erro }
});

const carregadoComSucesso = ({ key, store, retorno, caminho }) => ({
    type: CARREGADO_COM_SUCESSO, payload: { key, store, retorno, caminho }
});

const atualizarChave = ({ store, oldKey, newKey }) => ({
    type: ATUALIZAR_CHAVE, payload: { store, oldKey, newKey }
});

export const removerArquivoInterno = ({ key, store }) => ({
    type: REMOVER_ARQUIVO, payload: { key, store }
});

export const retornarArquivoInterno = ({ key, store }) => ({
    type: RETORNAR_ARQUIVO, payload: { key, store }
});


export const adicionarArquivo = ({ key, id, store, nome, nomeExibicao, caminho, tamanho, tipo, baixavel }) => ({
    type: ADICIONAR_ARQUIVO, payload: { key, id, store, nome, nomeExibicao, caminho, tamanho, tipo, baixavel }
});

export const limparArquivos = ({ store }) => ({
    type: LIMPAR_ARQUIVOS, payload: { store }
});

export const sincronizarArquivos = ({ store, hashesArquivosExistentes }) => ({
    type: SINCRONIZAR_ARQUIVOS, payload: { store, hashesArquivosExistentes }
});

export const removerDaListaDeExcluidos = ({ key, store }) => (dispatch, getState) => {
    console.log({ key, store })
    return new Promise(resolve => {
        dispatch(retornarArquivoInterno({ key, store }));
        resolve(":D")
    })
}

export const removerArquivo = ({ key, store }) => (dispatch, getState) => {
    // Remover arquivo imediatamente, por endpoint
    // const { id } = getState().files[store][key];
    // if (id) {
    //     return http.delete(`/arquivos/paraId/${id}`)
    //         .then(() => {
    //             dispatch(removerArquivoInterno({ key, store }));
    //         })
    //         .catch(res => {
    //             dispatch(erroAoCarregar({
    //                 key, store,
    //                 erro: res.data
    //             }));
    //         });
    // }

    // eslint-disable-next-line no-undef
    return new Promise(resolve => {
        dispatch(removerArquivoInterno({ key, store }));
        resolve(":D")
    })
};

let autoId = -10000;
export const adicionarEEnviarArquivo = ({ store, file }) => (dispatch) => {
    // eslint-disable-next-line no-undef
    const bodyFormData = new FormData();
    bodyFormData.append('arquivo', file);

    const key = autoId++;
    // eslint-disable-next-line no-undef
    const url = URL.createObjectURL(file);

    const tipo = file.type
        ? file.type.split("/")[1]
        : file.name.substring(file.name.lastIndexOf(".")+1);

    dispatch(adicionarArquivo({
        key,
        store,
        nome: file.name,
        nomeExibicao: '',
        caminho: url,
        tamanho: file.size,
        tipo: tipo || "",
        baixavel: false,
    }));

    dispatch(iniciarCarregamento({ key, store }));

    return http.post('/arquivos/armazenarTemporariamente', bodyFormData, {
        timeout: 0
    })
        .then((response) => {
            dispatch(carregadoComSucesso({
                key,
                store,
                retorno: response.data,
                // eslint-disable-next-line no-undef
                caminho: `/arquivos/temp/${response.data}`
            }));
            dispatch(atualizarChave({
                store,
                oldKey: key,
                newKey: response.data,
            }));

            // eslint-disable-next-line no-undef
            URL.revokeObjectURL(url);

            return response.data;
        })
        .catch((response) => {
            // eslint-disable-next-line no-undef
            console.log(response);
            dispatch(erroAoCarregar({
                store,
                key,
                erro: "Não foi possível salvar o arquivo"
            }));
        });
};

export const carregarArquivoPorId = ({ store, id }) => (dispatch, getState) => {
    const key = id;
    const storeAtual = getState().files[store];
    if (storeAtual && storeAtual[key]) {
        return;
    }

    const url = `/arquivos/paraId/${id}`;
    return http.get(url, {
        headers: {
            'Accept': 'application/json',
        },
        timeout: 0
    })
        .then((response) => {
            dispatch(adicionarArquivo({
                key,
                id,
                store,
                nome: response.data.nome,
                nomeExibicao: response.data.nomeExibicao,
                caminho: response.data.caminho,
                tamanho: response.data.tamanho,
                tipo: response.data.tipo,
                baixavel: true,
            }));
            dispatch(carregadoComSucesso({
                key,
                store,
            }));
        })
        .catch((response) => {
            // eslint-disable-next-line no-undef
            console.log(response);
            dispatch(erroAoCarregar({
                store,
                key,
                erro: "Não foi possível recuperar o arquivo"
            }));
        });
};


/**
 * reducers
 */

const atualizarDadosArquivo = (state, storeName, key, dados) => ({
    ...state,
    [storeName]: {
        ...(state[storeName]||{}),
        [key]: {
            ...((!!state[storeName] && state[storeName][key]) || {}),
            ...dados,
        }
    }
});

const sincronizarDadosArquivos = (state, dados) => {
    const dadosConvertidosEmString = dados.map(String);
    const stateFiltrado = Object.keys(state).reduce((acc, key) => {
        acc[key] = Object.keys(state[key]).reduce((innerAcc, innerKey) => {
            if (dadosConvertidosEmString.includes(innerKey)) {
                innerAcc[innerKey] = state[key][innerKey];
            }
            return innerAcc;
        }, {});
        return acc;
    }, {});

    return stateFiltrado;
};

const limparArquivosReducer = (state, storeName) => {
    const {
        // eslint-disable-next-line no-unused-vars
        [storeName]: excluido,
        ...fileStores
    } = state;

    return fileStores;
};

const atualizarChaveReducer = (state, storeName, oldKey, newKey) => {
    const {
        [storeName]: {
            [oldKey]: valor,
            ...files
        },
        ...fileStores
    } = state;

    return {
        ...fileStores,
        [storeName]: {
            ...files,
            [newKey]: valor,
        },
    };
};

export const fileReducer = (state = {}, acao) => {
    switch (acao.type) {
        case ATUALIZAR_CHAVE: return atualizarChaveReducer(state, acao.payload.store, acao.payload.oldKey, acao.payload.newKey);
        case LIMPAR_ARQUIVOS: return limparArquivosReducer(state, acao.payload.store);
        case REMOVER_ARQUIVO: return atualizarDadosArquivo(state, acao.payload.store, acao.payload.key, {
            deletado: true,
        });
        case RETORNAR_ARQUIVO: return atualizarDadosArquivo(state, acao.payload.store, acao.payload.key, {
            deletado: false,
        });
        case ADICIONAR_ARQUIVO: return atualizarDadosArquivo(state, acao.payload.store, acao.payload.key, {
            id: acao.payload.id,
            nome: acao.payload.nome,
            nomeExibicao: acao.payload.nomeExibicao,
            caminho: acao.payload.caminho,
            tamanho: acao.payload.tamanho,
            tipo: acao.payload.tipo,
            baixavel: acao.payload.baixavel,
        });
        case ERRO_AO_CARREGAR: return atualizarDadosArquivo(state, acao.payload.store, acao.payload.key, {
            carregado: false,
            carregando: false,
            id: acao.payload.key,
            erro: acao.payload.erro,
        });
        case CARREGADO_COM_SUCESSO: return atualizarDadosArquivo(state, acao.payload.store, acao.payload.key, {
            carregado: true,
            carregando: false,
            retorno: acao.payload.retorno,
            caminho: acao.payload.caminho || state[acao.payload.store][acao.payload.key].caminho,
        });
        case INICIAR_CARREGAMENTO: return atualizarDadosArquivo(state, acao.payload.store, acao.payload.key, {
            carregando: true,
        });
        case SINCRONIZAR_ARQUIVOS: return sincronizarDadosArquivos(state, acao.payload.store, acao.payload.hashesArquivosExistentes, {
            carregando: true,
        });
        default: return state;
    }
};
