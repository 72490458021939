import React, { PureComponent } from "react";
import { AbstractSampleGeoquimicaModelLike } from "../../../../../models/activity-geoquimica.model";
import { Popup } from "../../../../../components/Jqx/Popup/Popup";
import { EtiquetaAmostra } from "./EtiquetaAmostra/EtiquetaAmostra";
import PrimaryButton from "../../../../../components/Jqx/Button/PrimaryButton";
import { takeUntil } from "rxjs/operators";
import { Subject, Subscription } from "rxjs";
import printingService from "../../../../../service/PrintingService";
import TableComponent, {
  Table,
} from "../../../../../components/Jqx/Table/Table";
import geoquimicaService from "../../../../../service/GeoquimicaService";
import { IGridColumn, IGridSourceDataFields, jqx } from "jqwidgets-scripts/jqwidgets-react-tsx/jqxgrid";
import { mostrarNotificacao } from "../../../../../reducers/notificacaoReducer";
import { connect } from "react-redux";
import { Form as FForm, Field } from "react-final-form";
import { DropdownListField } from "../../../../../components/field/DropdownListField";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { ComboItemModelLike } from "../../../../../models/combo-item.model";
import { SubForm } from "../../../../../components/SubForm";

type Elementos = {
  existentes: AbstractSampleGeoquimicaModelLike[];
  novos: AbstractSampleGeoquimicaModelLike[];
  editadosPorId: {};
  idsExcluidos: [];
};

export interface AmostraGridSubFormProps {
  name: string;
  datafields: any[];
  items?: AbstractSampleGeoquimicaModelLike[];
  onItemsChanged?: (items: AbstractSampleGeoquimicaModelLike[]) => void;
  renderForm: ({ formProps, editando, prefixoNome }) => any;
  enviarNotificacaoError?: (msg: string) => void;
}

export interface AmostraGridSubFormState {
  elementos: Elementos;

  popupAmostraResultadosVisible?: boolean;
  amostra?: AbstractSampleGeoquimicaModelLike;
  tableItems: any[];
  statusOptions: ComboItemModelLike[];
  isBtnFinalizeResultsPopupDisabled?: boolean;

  numeroEtiqueta?: string;
  popupEtiquetaVisible?: boolean;
  isBtnPrintTagsPopupDisabled?: boolean;
}

const popupTagsContainerStyle: React.CSSProperties = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  maxHeight: "395px",
};

const popupTagsContentStyle: React.CSSProperties = {
  flex: 1,
  overflowY: "auto",
};

const popupTagsBtnPrintStyle: React.CSSProperties = {
  margin: "15px",
};

const sampleResultsDataFields: IGridSourceDataFields[] = [
  { name: "method", type: "string" },
  { name: "analyte", type: "string" },
  { name: "unit", type: "string" },
  { name: "mdl", type: "string" },
  { name: "type", type: "string" },
  { name: "value", type: "string" },
];

const sampleResultsColumns = [
  { text: "Method", datafield: "method" },
  { text: "Analyte", datafield: "analyte" },
  { text: "Unit", datafield: "unit" },
  { text: "Mdl", datafield: "mdl" },
  { text: "Type", datafield: "type" },
  { text: "Value", datafield: "value" },
];

let AmostraGridSubFormInternal = class AmostraGridSubForm extends PureComponent<
  AmostraGridSubFormProps,
  AmostraGridSubFormState
> {
  private createResultadosLinkWidget = (
    row: any,
    column: any,
    value: string,
    cellElement: HTMLDivElement
  ) => {
    const amostra: AbstractSampleGeoquimicaModelLike = row.bounddata;
    if (
      cellElement &&
      !cellElement.firstChild &&
      amostra?.id_amostra &&
      amostra?.status
    ) {
      const btnLink = document.createElement("a");
      cellElement.append(btnLink);

      btnLink.classList.add("jqx-grid-cell-left-align");
      btnLink.style.display = "block";
      btnLink.style.marginTop = "8px";
      btnLink.href = "javascript:void(0);";
      btnLink.innerHTML = "Mais detalhes";
      btnLink.addEventListener("click", (e) => {
        e.preventDefault();
        if (!this.state.popupAmostraResultadosVisible) {
          this.setState({ popupAmostraResultadosVisible: true, amostra });
        }
      });
    }
  };
  private createEtiquetaLinkWidget = (
    row: any,
    column: any,
    value: string,
    cellElement: HTMLDivElement
  ) => {
    if (cellElement && !cellElement.firstChild && value) {
      const btnLink = document.createElement("a");
      cellElement.append(btnLink);

      btnLink.classList.add("jqx-grid-cell-left-align");
      btnLink.style.display = "block";
      btnLink.style.marginTop = "8px";
      btnLink.href = "javascript:void(0);";
      btnLink.innerHTML = value;
      btnLink.addEventListener("click", (e) => {
        e.preventDefault();
        if (!this.state.popupEtiquetaVisible) {
          this.setState({ popupEtiquetaVisible: true, numeroEtiqueta: value });
        }
      });
    }
  };

  colunas: IGridColumn[] = [
    { text: "Tipo amostragem", datafield: "tipo" },
    { text: "Fonte amostra", datafield: "fonte" },
    { text: "Número campo", datafield: "numero_do_campo" },
    { text: "Material coletado", datafield: "materialColetado" },
    {
      text: "Número de Etiqueta",
      datafield: "numero_etiqueta",
      initwidget: this.createEtiquetaLinkWidget,
      createwidget: this.createEtiquetaLinkWidget,
    },
    { text: "Status", datafield: "status" },
    {
      text: "Resultados",
      datafield: "resultados",
      initwidget: this.createResultadosLinkWidget,
      createwidget: this.createResultadosLinkWidget,
    },
  ];

  private sampleResultsTableRef = React.createRef<Table>();
  private printingTagContainerRef = React.createRef<HTMLDivElement>();

  private sampleResultsSubscription!: Subscription;

  private lifecycle = new Subject();

  constructor(props) {
    super(props);

    const elementos = this.handleElementosGrid();
    this.state = {
      elementos,
      tableItems: [],
      statusOptions: [
        {
          id: "Aprovado",
          nome: "Aprovado?",
        },
        {
          id: "Reprovado",
          nome: "Reprovado?",
        },
      ],
    };
  }

  componentDidUpdate(
    prevProps: Readonly<AmostraGridSubFormProps>,
    prevState: Readonly<AmostraGridSubFormState>
  ) {
    if (this.props.items !== prevProps.items) {
      const elementos = this.handleElementosGrid();
      this.setState({ elementos });
    }

    if (this.state.amostra?.id_amostra !== prevState.amostra?.id_amostra) {
      this.handleSampleResults();
    }
  }

  componentWillUnmount() {
    this.lifecycle.next();
    this.lifecycle.complete();
  }

  onPopupResultsLabClose = () => {
    if (!this.state.popupAmostraResultadosVisible) {
      return;
    }

    this.setState({
      popupAmostraResultadosVisible: false,
      amostra: undefined,
      tableItems: [],
    });
  };

  onPopupEtiquetaClose = () => {
    this.setState({ popupEtiquetaVisible: false, numeroEtiqueta: undefined });
  };

  onBtnPrintTagsClick = () => {
    if (this.state.isBtnPrintTagsPopupDisabled) {
      return;
    }

    this.setState({ isBtnPrintTagsPopupDisabled: true });
    printingService
      .print(this.printingTagContainerRef.current!)
      .pipe(takeUntil(this.lifecycle))
      .subscribe({
        next: () => {
          this.setState({ isBtnPrintTagsPopupDisabled: false });
        },
        error: (e) => {
          console.error(e);
          this.setState({ isBtnPrintTagsPopupDisabled: false });
        },
      });
  };

  onSubmit = (values) => {
    const items: AbstractSampleGeoquimicaModelLike[] = values?.exibidos ?? [];
    items?.forEach((item) => {
      item.imagens =
        item.imagens?.filter((x) => {
          if (typeof x === "object") {
            return !(x as any).erro;
          }

          return true;
        }) ?? [];
    });
    this.props.onItemsChanged?.call(this.props.onItemsChanged, items);
  };

  private handleElementosGrid() {
    const elementos: Elementos = {
      ...(this.state?.elementos ?? {}),
      existentes: this.props.items?.filter((x) => x.id! > 0) ?? [],
      novos: this.props.items?.filter((x) => x.id! < 0) ?? [],
    };

    return elementos;
  }

  private handleSampleResults() {
    this.sampleResultsSubscription?.unsubscribe();
    if (!this.state.amostra?.id_amostra) {
      return;
    }

    this.sampleResultsSubscription = geoquimicaService
      .listSampleLabResults(this.state.amostra?.id_amostra!)
      .pipe(takeUntil(this.lifecycle))
      .subscribe({
        next: (data) => {
          this.setState({ tableItems: data });
        },
        error: (e) => {
          this.props.enviarNotificacaoError?.(
            "Não foi possível listar os resultados!"
          );
        },
      });
  }

  render() {
    return (
      <>
        <SubForm
          nome={this.props.name}
          onSubmit={this.onSubmit}
          elementos={this.state.elementos as any}
          campos={this.props.datafields}
          colunas={this.colunas}
          permitirInsercao={true}
          renderForm={this.props.renderForm}
        />

        <Popup
          opened={this.state.popupAmostraResultadosVisible}
          title="Resultado do Laboratório"
          size="lg"
          onClose={this.onPopupResultsLabClose}
        >
          <div>
            <TableComponent
              ref={this.sampleResultsTableRef}
              columns={sampleResultsColumns}
              datafields={sampleResultsDataFields}
              items={this.state.tableItems}
              altrows={true}
            />
          </div>
        </Popup>

        <Popup
          opened={this.state.popupEtiquetaVisible}
          title="Etiqueta"
          onClose={this.onPopupEtiquetaClose}
        >
          <div style={popupTagsContainerStyle}>
            <div
              ref={this.printingTagContainerRef}
              style={popupTagsContentStyle}
            >
              {!this.state.numeroEtiqueta ? null : (
                <EtiquetaAmostra barcode={this.state.numeroEtiqueta} />
              )}
            </div>

            <PrimaryButton
              style={popupTagsBtnPrintStyle}
              onClick={this.onBtnPrintTagsClick}
              titulo="Imprimir"
              disabled={this.state.isBtnPrintTagsPopupDisabled}
            />
          </div>
        </Popup>
      </>
    );
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    enviarNotificacaoError(mensagem) {
      dispatch(
        mostrarNotificacao(mensagem, {
          tipo: "error",
        })
      );
    },
  };
};

AmostraGridSubFormInternal = connect(
  null,
  mapDispatchToProps
)(AmostraGridSubFormInternal) as any;

export const AmostraGridSubForm = AmostraGridSubFormInternal;
