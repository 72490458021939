export const CLASSES_AMOSTRA_PETROGRAFIA = [
  { id: 8, nome: "Minério" },
  { id: 14, nome: "Outros" },
  { id: 9, nome: "Rocha" },
  { id: 7, nome: "Solo" },
]

export const CLASSE_MINERIO = { id: 8, nome: "Minério" };
export const CLASSE_OUTROS = { id: 14, nome: "Outros" };
export const CLASSE_ROCHA = { id: 9, nome: "Rocha" };
export const CLASSE_SOLO = { id: 7, nome: "Solo" };

export const IGNEA = 1;
export const METAMORFICA = 2;
export const SEDIMENTAR = 5;