import { verificarConexaoErro } from "../util/validadores";
import http from "./http-commons";

// **********************************************************************

export const AFLORA = 'aflora';
export const BASEGEO = 'basegeo';
export const OUTRAS_COLETAS = 'outrascoletas';
export const RECMIN = 'recmin';
export const MOCKED = 'mocked';
export const LITOESTATIGRAFIA = 'litoestatigrafia'
export const AMOSTRAS = 'amostras'
export const PETRO = 'petro'
export const VALIDACAO = 'validacao'
export const GEOCRON = 'geocron'
export const Modulos = {
  basegeo: { baseURL: process.env.REACT_APP_API_URL },
  aflora: { baseURL: process.env.REACT_APP_API_URL_AFLORA },
  outrascoletas: { baseURL: process.env.REACT_APP_API_URL_OUTRAS_COLETAS },
  recmin: { baseURL: process.env.REACT_APP_API_URL_RECMIN },
  litoestatigrafia: { baseURL: process.env.REACT_APP_API_URL_LITOESTATIGRAFIA },
  mocked: { baseURL: process.env.REACT_APP_API_MOCK_URL },
  amostras: { baseURL: process.env.REACT_APP_AMOSTRAS_API_URL },
  petro: { baseURL: process.env.REACT_APP_API_URL_PETRO },
  validacao: { baseURL: process.env.REACT_APP_API_URL_VALIDACAO },
  geocron: { baseURL: process.env.REACT_APP_API_URL_GEOCRON },

}

// **********************************************************************

const Service = (path, nomeModulo = BASEGEO) => {
  const baseURL = Modulos[nomeModulo].baseURL;
  let defaultActions = {
    get: (id) => http.get(`${path}/${id}`, { baseURL }).catch(verificarConexaoErro),
    post: (obj) => http.post(path, obj, { baseURL }).catch(verificarConexaoErro),
    query: (params) => http.get(path, { params, baseURL }).catch(verificarConexaoErro),
    listar: (params) => http.get(path, { params, baseURL }).catch(verificarConexaoErro),
    customURL: (verb, complementPath, objSend) => {
      const url = `${path}${complementPath}`;
      switch (verb.toUpperCase()) {
        case "POST":
          return http.post(url, objSend, { baseURL }).catch(verificarConexaoErro);
        case "PUT":
          return http.put(url, objSend, { baseURL }).catch(verificarConexaoErro);
        case "PATCH":
          return http.patch(url, objSend, { baseURL }).catch(verificarConexaoErro);
        case "GET":
          return http.get(url, { baseURL }).catch(verificarConexaoErro);
        default:
          return null;
      }
    },

    // ***** old methods
    update: (id, obj) => http.put(path + "/" + id, obj, { baseURL }).catch(verificarConexaoErro),
    delete: (id) => http.delete(id ? `${path}/${id}`: path, { baseURL }).catch(verificarConexaoErro),
  };

  return Object.assign(defaultActions);
};

export default Service;
