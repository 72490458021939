import React, { useCallback } from "react";
import DateTimeInput from "../../Jqx/DateTimeInput/DateTimeInput";
import dayjs from "dayjs";
import PropTypes from "prop-types";

const DateTimeInputField = ({ input, meta, formatoData, min, max, ...props }) => {
  const onChange = useCallback((date) => {
    if (date) {
      date = dayjs(date).format(formatoData)
    }
    input.onChange(date)
  }, [input, formatoData])

  const valorConvertido = '' !== input.value ? dayjs(input.value, formatoData).toDate() : null
  const valorMinimo = min ? dayjs(min, formatoData).toDate() : null
  const valorMaximo = max ? dayjs(max, formatoData).toDate() : null

  const mensagemErro = meta.touched && !meta.valid && meta.error;

  return (
    <>
    <DateTimeInput
      {...props}
      onChange={onChange}
      valor={valorConvertido}
      mensagemErro={mensagemErro}
      min={valorMinimo}
      max={valorMaximo}
      width={'98%'}
    />
    </>
  );
};

DateTimeInputField.propTypes = {
  ...DateTimeInput.propTypes,
  min: PropTypes.string,
  max: PropTypes.string,
  formatoData: PropTypes.string.isRequired,
};

DateTimeInputField.defaultProps = {
  ...DateTimeInput.defaultProps,
  formatoData: "DD/MM/YYYY",
};

export default DateTimeInputField;
