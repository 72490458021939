import React, { useCallback } from "react";
import Erro from "../Erro";

const HiddenField = ({input, meta, elementos, extratorId,...props}) => {
  const onChange = useCallback((val) => {
    console.log('hidden')
    console.log(val)
    input.onChange(val)
  }, [input])


  const mensagemErro = meta.touched && !meta.valid && meta.error;

  return (
    <>
      <input
        type="hidden"
        value={input.value}
        onChange={onChange}
      />
      <Erro mensagem={mensagemErro} />
    </>
  )
}

HiddenField.propTypes = {
}

HiddenField.defaultProps = {
}

export {HiddenField}
