import isFinite from "lodash/isFinite";
import Service from "../service/Service";
import { TIPO_ENTRADA_COORDS_DECIMAL, TIPO_ENTRADA_COORDS_GRAUS, TIPO_ENTRADA_COORDS_UTM } from "./constantes";

const servicoCoordenadas = Service('');

const verificaCoordenadas = (coordenadas) => {
  if (coordenadas?.tipoEntrada === TIPO_ENTRADA_COORDS_UTM.id && typeof coordenadas?.fuso !== "number") return false

  if (coordenadas?.tipoEntrada === TIPO_ENTRADA_COORDS_GRAUS.id && (coordenadas?.grauLongitude === 0 || coordenadas?.grauLongitude === null)) return false

  if (coordenadas?.tipoEntrada === TIPO_ENTRADA_COORDS_DECIMAL.id && (coordenadas?.longitude === 0 || coordenadas?.longitude === null)) return false

  return true
}

export const converterCoordenadas = (coordenadas, paraTipo, paraDatum, {
  retornarWGS84 = false,
} = {}) => {
  const temDadosSuficientesParaConverter = coordenadas && Object.keys(coordenadas).length > 0 &&
    paraTipo && paraDatum;

  const deveConverter = (() => {
    if (
      coordenadas.tipoEntrada === TIPO_ENTRADA_COORDS_DECIMAL.id &&
      isFinite(coordenadas.latitude) && isFinite(coordenadas.longitude)
    ) {
      return true
    }

    if (
      coordenadas.tipoEntrada === TIPO_ENTRADA_COORDS_UTM.id &&
      isFinite(coordenadas.x) && isFinite(coordenadas.y) && isFinite(coordenadas.fuso)
    ) {
      return true
    }

    if (
      coordenadas.tipoEntrada === TIPO_ENTRADA_COORDS_GRAUS.id &&
      isFinite(coordenadas.grauLatitude) && isFinite(coordenadas.minutoLatitude) && isFinite(coordenadas.segundoLatitude) &&
      isFinite(coordenadas.grauLongitude) && isFinite(coordenadas.minutoLongitude) && isFinite(coordenadas.segundoLongitude)
    ) {
      return true
    }

    return false
  })()

  if (!temDadosSuficientesParaConverter || !deveConverter) {
    console.log('sem dados suficientes pra converter. skipping...')
    return Promise.resolve({
      sucesso: false,
    });
  }

  if ('number' !== typeof coordenadas.idDatum) {
    return Promise.resolve({
      sucesso: false,
    });
  }

  const deCoordenada = {
    ...coordenadas,
    latitude: isFinite(coordenadas.latitude) ? coordenadas.latitude : 0,
    longitude: isFinite(coordenadas.longitude) ? coordenadas.longitude : 0,
    x: isFinite(coordenadas.x) ? coordenadas.x : 0,
    y: isFinite(coordenadas.y) ? coordenadas.y : 0,
    grauLatitude: isFinite(coordenadas.grauLatitude) ? coordenadas.grauLatitude : 0,
    minutoLatitude: isFinite(coordenadas.minutoLatitude) ? coordenadas.minutoLatitude : 0,
    segundoLatitude: isFinite(coordenadas.segundoLatitude) ? coordenadas.segundoLatitude : 0,
    grauLongitude: isFinite(coordenadas.grauLongitude) ? coordenadas.grauLongitude : 0,
    minutoLongitude: isFinite(coordenadas.minutoLongitude) ? coordenadas.minutoLongitude : 0,
    segundoLongitude: isFinite(coordenadas.segundoLongitude) ? coordenadas.segundoLongitude : 0,
    fuso: isFinite(coordenadas.fuso) ? Number(coordenadas.fuso) : null
  }

  const params = {
    de: deCoordenada,
    paraTipo: paraTipo,
    paraDatumId: paraDatum,
    retornarValorWGS84: retornarWGS84,
  }

  return servicoCoordenadas.customURL("POST", "/coordenadas/converter", params)
    .then((res) => {
      return {
        sucesso: true,
        dados: res.data,
      };
    })
    .catch(res => {
      console.log(`erro ao converter coordenadas, mudando nada`, res)
      return {
        sucesso: false,
      }
    })
}

export const converterCoordenadasParaSirgas = (coordenadas) => {
  const temDadosSuficientesParaConverter = coordenadas && Object.keys(coordenadas).length > 0;
  if (!verificaCoordenadas(coordenadas)) return Promise.resolve({ sucesso: false, message: "Falha na conversão, dados inconsistentes" })

  if (!temDadosSuficientesParaConverter) {
    console.log('sem dados suficientes pra converter. skipping...')
    return Promise.resolve({
      sucesso: false,
    });
  }

  if ('number' !== typeof coordenadas.idDatum) {
    return Promise.resolve({
      sucesso: false,
    });
  }

  const params = {
    ...coordenadas,
    latitude: isFinite(coordenadas.latitude) ? coordenadas.latitude : 0,
    longitude: isFinite(coordenadas.longitude) ? coordenadas.longitude : 0,
    x: isFinite(coordenadas.x) ? coordenadas.x : 0,
    y: isFinite(coordenadas.y) ? coordenadas.y : 0,
    grauLatitude: isFinite(coordenadas.grauLatitude) ? coordenadas.grauLatitude : 0,
    minutoLatitude: isFinite(coordenadas.minutoLatitude) ? coordenadas.minutoLatitude : 0,
    segundoLatitude: isFinite(coordenadas.segundoLatitude) ? coordenadas.segundoLatitude : 0,
    grauLongitude: isFinite(coordenadas.grauLongitude) ? coordenadas.grauLongitude : 0,
    minutoLongitude: isFinite(coordenadas.minutoLongitude) ? coordenadas.minutoLongitude : 0,
    segundoLongitude: isFinite(coordenadas.segundoLongitude) ? coordenadas.segundoLongitude : 0,
    fuso: coordenadas?.fuso,
  }

  return servicoCoordenadas.customURL("POST", "/coordenadas/converterParaSirgas2000", params)
    .then((res) => {
      return {
        sucesso: true,
        dados: res.data,
      };
    })
    .catch(res => {
      console.log(`erro ao converter coordenadas, mudando nada`, res)
      return {
        sucesso: false,
      }
    })
}

export const converterCoordenadasParaSirgasComCategoria = (coordenadas, idVisita) => {

  if (!verificaCoordenadas(coordenadas)) return Promise.resolve({ sucesso: false, message: "Falha na conversão, dados inconsistentes" })

  const temDadosSuficientesParaConverter = coordenadas && Object.keys(coordenadas).length > 0;

  if (!temDadosSuficientesParaConverter) {
    console.log('sem dados suficientes pra converter. skipping...')
    return Promise.resolve({
      sucesso: false,
    });
  }

  if ('number' !== typeof coordenadas.idDatum) {
    return Promise.resolve({
      sucesso: false,
    });
  }

  const params = {
    ...coordenadas,
    latitude: isFinite(coordenadas.latitude) ? coordenadas.latitude : 0,
    longitude: isFinite(coordenadas.longitude) ? coordenadas.longitude : 0,
    x: isFinite(coordenadas.x) ? coordenadas.x : 0,
    y: isFinite(coordenadas.y) ? coordenadas.y : 0,
    grauLatitude: isFinite(coordenadas.grauLatitude) ? coordenadas.grauLatitude : 0,
    minutoLatitude: isFinite(coordenadas.minutoLatitude) ? coordenadas.minutoLatitude : 0,
    segundoLatitude: isFinite(coordenadas.segundoLatitude) ? coordenadas.segundoLatitude : 0,
    grauLongitude: isFinite(coordenadas.grauLongitude) ? coordenadas.grauLongitude : 0,
    minutoLongitude: isFinite(coordenadas.minutoLongitude) ? coordenadas.minutoLongitude : 0,
    segundoLongitude: isFinite(coordenadas.segundoLongitude) ? coordenadas.segundoLongitude : 0,
    fuso: coordenadas?.fuso,
  }

  return servicoCoordenadas.customURL("POST", "/coordenadas/converterParaSirgas2000ComCategoria/" + idVisita, params)
    .then((res) => {
      return {
        sucesso: true,
        dados: res.data,
      };
    })
    .catch(res => {
      console.log(`erro ao converter coordenadas, mudando nada`, res)
      return {
        sucesso: false,
      }
    })
}
