import { Card, Col, Row } from "react-bootstrap";
import { Field, useForm } from "react-final-form";
import { TextInputField } from "../../../components/field/TextInputField";
import { DateTimeInputField } from "../../../components/field/DateTimeInputField";
import { NumberInputField } from "../../../components/field/NumberInputField";
import { DropdownListField } from "../../../components/field/DropdownListField";
import { campoObrigatorioComMsgGenerica } from "../../../util/validadores";
import { useEffect, useState } from "react";
import Service, { BASEGEO, PETRO } from "../../../service/Service";
import { resolverRequestAmostras } from "../../estacoes/components/amostras/AmostrasUtils/AmostraRequestUtils";
import TextInputNumeroLote from "../../../components/field/TextInputField/TextInputNumeroLote";

function verificaRegra(valor) {

    if (!valor) {
        return false
    }

    if (valor.length !== 6) {
        return false;
    }

    const letras = valor.substring(0, 3);

    if (!letras.match(/^[A-Z]{3}$/)) {
        return false;
    }

    const numeros = valor.substring(3);
    if (!(/^\d{3}$/.test(numeros))) {
        return false;
    }

    if (!(/^[A-Z]{3}$/.test(letras))) {
        return false;
    }

    return true;
}

const InformacoesAnaliseCard = ({
    setCarregamentoGlobal,
    form,
    valoresIniciais,
    coletores,
    isChefeProjeto,
    idAmostra,
    projetoAreaSubArea,
    disabled
}) => {
    const [uf, setUf] = useState([]);
    const [tipoSecao, setTipoSecao] = useState([]);
    const [unidadeRegional, setUnidadeRegional] = useState([]);
    const [petrografos, setPetrografos] = useState([]);
    const [lamina, setLamina] = useState([]);
    const [numeroLote, setNumerolote] = useState()
    const [timeoutId, setTimeoutId] = useState()

    useEffect(() => {
        const petrografos = coletores.map(objeto => ({
            id: objeto.id,
            nome: `${objeto.nome} (${objeto.sigla})`,
            sigla: objeto.sigla,
            itemDesabilitado: objeto.itemDesabilitado
        }))
        setPetrografos(petrografos)
    }, [coletores]);
    useEffect(() => {
        const fetchData = async () => {
            setCarregamentoGlobal(true)
            try {
                const estadosResponse = await Service(`/bibliotecas/estados`, BASEGEO).query();
                setUf(estadosResponse?.data?.dados);

                const tiposSecaoResponse = await Service(`/bibliotecas/tipos-secao`, BASEGEO).query();
                setTipoSecao(tiposSecaoResponse?.data?.dados);

                const localArmazenamento = await resolverRequestAmostras("/biblioteca/localarmazenamento");
                setUnidadeRegional(localArmazenamento);

                const response = await Service(`/petrografias/proxima-lamina/amostra`, PETRO)
                    .get(idAmostra)

                if (response?.data?.dados?.length > 0) {
                    setLamina(response?.data?.dados);
                }
            } catch (error) {
                console.error("Erro ao carregar dados:", error);
            } finally {
                setCarregamentoGlobal(false)
            }
        };

        fetchData();
    }, [setCarregamentoGlobal, idAmostra]);


    async function validarNumeroLote(e) {
        
        clearTimeout(timeoutId); // Cancela o timeout anterior, se existir        
        let novoNumeroLote = await e.replace(/\D/g, '');
        if (novoNumeroLote === "0000") {
            setNumerolote("0001");
            return
        }
        if (novoNumeroLote.length === 5) {
            novoNumeroLote = novoNumeroLote.replace(/^0/, '');
        }
        // Remove o último caractere se o tamanho for maior que 4 caracteres
        if (novoNumeroLote.length > 4) {
            novoNumeroLote = novoNumeroLote.slice(0, -1);
        }

        setNumerolote(novoNumeroLote)

        const novoTimeoutId = setTimeout(() => {
            if (parseInt(novoNumeroLote, 10)) {
                setNumerolote(novoNumeroLote.padStart(4, '0'));
            }
        }, 800);
        setTimeoutId(novoTimeoutId);
    }
    return (
        <Card className="mt-3">
            <Card.Body>
                <Row className="mb-3">
                    <Col md={12}>
                        <Card.Title>Informações da análise</Card.Title>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col md={12}>
                        <Field
                            name="projetoAreaSubarea"
                            component={TextInputField}
                            label="Projeto/ área/ subárea"
                            dica="Projeto de trabalho/Informe a área do projeto/Informe a Subárea do projeto"
                            disabled
                            defaultValue={projetoAreaSubArea}
                        />
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col md={4}>
                        <Field
                            component={TextInputField}
                            name="nomeEstacao"
                            label="Nome da amostra"
                            dica="Nome da Estação do Serviço Geológico do Brasil no formato SGB-UF-NNNN"
                            disabled
                            defaultValue={valoresIniciais?.nomeAmostra}
                        />
                    </Col>
                    <Col md={4}>

                        <Field
                            component={DateTimeInputField}
                            name="dataAnalise"
                            label="Data da análise"
                            dica="Inserir a data final de execução da análise petrográfica."
                            max={valoresIniciais?.dataAnalise}
                            disabled={disabled}
                        />
                    </Col>
                    <Col md={4}>

                        <Field
                            component={DropdownListField}
                            name="idResponsavel"
                            label="Petrógrafo"
                            dica="Inserir o nome do executor/a ou do/a responsável pela análise petrográfica"
                            disabled={!isChefeProjeto || disabled}
                            elementos={petrografos}
                        />
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col md={4}>
                        <Field
                            component={TextInputField}
                            name="lamina"
                            label="Lâmina"
                            disabled
                            defaultValue={lamina}
                            dica="Exemplo: 88-ALF-R-0013-1, onde:
                                88 é o identificação única do projeto;
                                ALF é a sigla do responsável (técnico) pela amostra;
                                R é a letra que identifica a classe da amostra;
                                0013 é o número sequencial de identificação da amostra e 1 é o número da Lâmina"
                        />
                    </Col>
                    <Col md={3}>
                        <Field
                            name="numeroLote"
                            component={TextInputNumeroLote}
                            onChange={(e) => validarNumeroLote(e)}
                            label="Número do lote"
                            dica="Número do lote. Preencher com até quatro dígitos numéricos: XXXX"
                            required={true}
                            validate={
                                campoObrigatorioComMsgGenerica("Número do lote")
                            }
                            values={numeroLote}
                            maxLength={5}
                            useCustomInput={true}
                            disabled={disabled}
                        />
                    </Col>
                    <Col md={1}>
                        <Field
                            component={DropdownListField}
                            elementos={uf}
                            name={"idEstado"}
                            label={"UF"}
                            displayMember={"sigla"}
                            required={true}
                            validate={campoObrigatorioComMsgGenerica("UF")}
                            dica={"Inserir abreviação da Unidade Federativa. Unidade Federativa do local de preparação da amostra."}
                            disabled={disabled}
                        />
                    </Col>
                    <Col md={4}>
                        <Field
                            component={TextInputField}
                            name="numeroLaboratorio"
                            required={true}
                            validate={(value) => verificaRegra(value) ? undefined : 'Campo "Número de laboratório" deve seguir o padrão XXX999'}
                            label="Número de laboratório"
                            maxLength={6}
                            dica="Usar o padrão do manual de geoquímica: Três letras maiúsculas e três números sem espaço e sem hífen. Por exemplo GJK246."
                            disabled={disabled}
                        />
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col md={4}>
                        <Field
                            component={DropdownListField}
                            elementos={tipoSecao}
                            name={"idTipoSecao"}
                            displayMember={"nome"}
                            required={true}
                            validate={campoObrigatorioComMsgGenerica("Tipo de seção")}
                            label={"Tipo de seção"}
                            dica={"Escolher na listagem o tipo de seção preparada."}
                            disabled={disabled}
                        />
                    </Col>
                    <Col md={4}>
                        <Field
                            component={DropdownListField}
                            elementos={unidadeRegional}
                            name={"idLocalArmazenamento"}
                            label={"Local de armazenamento"}
                            dica={"Escolher a unidade regional de armazenamento da seção preparada.  "}
                            required
                            validate={campoObrigatorioComMsgGenerica("Local de armazenamento")}
                            disabled={disabled}
                        />
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
};

export default InformacoesAnaliseCard;