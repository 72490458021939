import React from "react";
import PropTypes from "prop-types";
import ModalBS from "react-bootstrap/Modal"
import { ToolbarAprovacao } from "../ToolbarAprovacao";
import BlockUi from "react-block-ui";

import './index.css';
const AtividadeValidacaoModal = ({
    componenteAtividade,
    titulo,
    mostrarBotaoFechar = true,
    aberto,
    aoFechar,
    aoAprovar,
    aoReavaliar,
    habilitarToolbarAprovacao,
    tamanho = "sm" }) => {
    return <> <BlockUi>
            <ModalBS size={tamanho}
                show={aberto}
                onHide={aoFechar}
                scrollable={true}
                centered
            >
                <ModalBS.Header closeButton={mostrarBotaoFechar}>
                    {titulo &&
                        <ModalBS.Title>
                            {titulo}
                        </ModalBS.Title>
                    }
                </ModalBS.Header>
                <ModalBS.Body>
                    <div className="modal-aprovacao-body">
                        {componenteAtividade}
                    </div>
                </ModalBS.Body>
                <ModalBS.Footer>
                    <ToolbarAprovacao
                        habilitarToolbarAprovacao={habilitarToolbarAprovacao}
                        aoAprovar={aoAprovar}
                        aoReavaliar={aoReavaliar}
                        aoVoltar={aoFechar} />
                </ModalBS.Footer>
            </ModalBS>
        </BlockUi>
    </>
}

AtividadeValidacaoModal.propTypes = {
    componenteAtividade: PropTypes.node,
    titulo: PropTypes.string,
    mostrarBotaoFechar: PropTypes.bool,
    aberto: PropTypes.bool,
    aoFechar: PropTypes.func,
    tamanho: PropTypes.oneOf(['sm', 'lg', 'xl']),
}

export { AtividadeValidacaoModal };
