import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { Field, FormSpy } from "react-final-form";
import { DateTimeInputField } from "../../../../../components/field/DateTimeInputField";
import { NumberInputField } from "../../../../../components/field/NumberInputField";
import { RadioButtonSetField } from "../../../../../components/field/RadioButtonSetField";
import { TextAreaField } from "../../../../../components/field/TextAreaField";
import { TextInputField } from "../../../../../components/field/TextInputField";
import Hint from "../../../../../components/hint/Hint";
import Form from "react-bootstrap/Form";
import { SubForm } from "../../../../../components/SubForm";
import { setValue } from "../../../../../util/mutadores";
import { faSquare } from '@fortawesome/free-solid-svg-icons';
import { resolverRequestRecmin } from "../../../../../util/ResolverRequestRecmin";
import FonteReferencia from "../FonteReferencia/FonteReferencia";
import Substancia from "../substancia/Substancia";
import { HiddenField } from "../../../../../components/field/HiddenField";
import { validarSe, validacoesDeposito, validarSubstanciaPrimariaDiferente, campoObrigatorioComMsgGenerica, camposDeClassificacaoDeposito, comporValidadores, fonteReferenciaVazia, substanciaObrigatoriaDeposito, unidadeMedidaSemTeor, unidadeMedidaSemTeorCorte, unidadeMedidaSemSubstancia, validacaoReserva } from "../../../../../util/validadores";
import dayjs from "dayjs";
import DropDownListField from "../../../../../components/field/DropdownListField/DropdownListField";
import { jqx } from "jqwidgets-scripts/jqwidgets-react-tsx/jqxbuttons";
import { FORM_ERROR } from "final-form";
import { getPropsPermissao, resolverExibidos } from "../../../../../components/SubForm/SubForm";
import { RadioField } from "../../../../../components/field/RadioField";

class Deposito extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expressaoGeografica: [],
      formaGeometrica: [],
      unidadesMedidaTeor: [{ id: 1, nome: 'l' }],
      metodoClassificacaoCalculo: [],
      unidadesMedida: [{ id: 1, nome: 'l' }],
      substancias: [],
      substanciasPrincipais: [],
      substanciasSecundarias: [],
      exibirRecurso: true,
      valoresIniciaisRecurso: { dadosInformadosRecurso: 'NAO' },
      valoresIniciaisReserva: { dadosInformadosReserva: 'NAO' },
      subList: []
    }

    this.substancias = [];

    this.colunasRecurso = [
      {
        text: "Substância", datafield: "idSubstancia", width: '13%', cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
          let nomeSubstancia = '';
          if (this.substancias) {
            this.substancias.forEach(substancia => {
              if (substancia.id?.toString() === rowdata.idSubstancia?.toString()) {
                nomeSubstancia = substancia.nome;
              }
            })
          }
          return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${nomeSubstancia}</div>`;;
        }
      },
      {
        text: "R. med", datafield: "recursoMedido", width: '16%', cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
          let linhaFormatada = this.montarCellRenderRecurso('recursoMedido', rowdata);
          return linhaFormatada;
        }
      },
      {
        text: "R. ind", datafield: "recursoIndicado", width: '13%', cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
          let linhaFormatada = this.montarCellRenderRecurso('recursoIndicado', rowdata);
          return linhaFormatada;
        }
      },
      {
        text: "R. inf", datafield: "recursoInferido", width: '13%', cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
          let linhaFormatada = this.montarCellRenderRecurso('recursoInferido', rowdata);
          return linhaFormatada;
        }
      },
      {
        text: "R. med + ind", datafield: "recursoMedidoIndicado", width: '13%', cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
          let linhaFormatada = this.montarCellRenderRecurso('recursoMedidoIndicado', rowdata);
          return linhaFormatada;
        }
      },
      {
        text: "R. ind + inf", datafield: "recursoIndicadoInferido", width: '13%', cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
          let linhaFormatada = this.montarCellRenderRecurso('recursoIndicadoInferido', rowdata);
          return linhaFormatada;
        }
      },
      {
        text: "R. med + ind + inf", datafield: "recursoMedidoIndicadoInferido", width: '13%', cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
          let linhaFormatada = this.montarCellRenderRecurso('recursoMedidoIndicadoInferido', rowdata);
          return linhaFormatada;
        }
      },
    ]


    this.dataFieldsRecurso = [
      { name: "idSubstancia", type: "string", map: "idSubstancia", },
      { name: "idUnidadeMedidaCalculo", type: "string", map: "idUnidadeMedidaCalculo", },
      { name: "idUnidadeMedidaTeor", type: "string", map: "idUnidadeMedidaTeor", },
      { name: "idUnidadeMedida", type: "string", map: "idUnidadeMedida", },
      { name: "recursoMedido", type: "string", map: "recursoMedido", },
      { name: "recursoMedidoTeor", type: "string", map: "recursoMedidoTeor" },
      { name: "recursoMedidoSubstanciaContida", type: "string", map: "recursoMedidoSubstanciaContida" },
      { name: "recursoIndicado", type: "string", map: "recursoIndicado" },
      { name: "recursoIndicadoTeor", type: "string", map: "recursoIndicadoTeor" },
      { name: "recursoIndicadoSubstanciaContida", type: "string", map: "recursoIndicadoSubstanciaContida" },
      { name: "recursoInferido", type: "string", map: "recursoInferido" },
      { name: "recursoInferidoTeor", type: "string", map: "recursoInferidoTeor" },
      { name: "recursoInferidoSubstanciaContida", type: "string", map: "recursoInferidoSubstanciaContida" },
      { name: "recursoMedidoIndicado", type: "string", map: "recursoMedidoIndicado" },
      { name: "recursoMedidoIndicadoTeor", type: "string", map: "recursoMedidoIndicadoTeor" },
      { name: "recursoMedidoIndicadoSubstanciaContida", type: "string", map: "recursoMedidoIndicadoSubstanciaContida" },
      { name: "recursoIndicadoInferido", type: "string", map: "recursoIndicadoInferido" },
      { name: "recursoIndicadoInferidoTeor", type: "string", map: "recursoIndicadoInferidoTeor" },
      { name: "recursoIndicadoInferidoSubstanciaContida", type: "string", map: "recursoIndicadoInferidoSubstanciaContida" },
      { name: "recursoMedidoIndicadoInferido", type: "string", map: "recursoMedidoIndicadoInferido" },
      { name: "recursoMedidoIndicadoInferidoTeor", type: "string", map: "recursoMedidoIndicadoInferidoTeor" },
      { name: "recursoMedidoIndicadoInferidoSubstanciaContida", type: "string", map: "recursoMedidoIndicadoInferidoSubstanciaContida" },
    ]


    this.colunasReserva = [
      {
        text: "Substância", datafield: "idSubstancia", width: '25%', cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
          let nomeSubstancia = '';
          if (this.substancias) {
            this.substancias.forEach(substancia => {
              if (substancia.id?.toString() === rowdata.idSubstancia?.toString()) {
                nomeSubstancia = substancia.nome;
              }
            })
          }
          return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${nomeSubstancia}</div>`;;
        }
      },
      {
        text: "R. provavel", datafield: "reservaProvavel", width: '23%', cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
          let linhaFormatada = this.montarCellRenderRecurso('reservaProvavel', rowdata);
          return linhaFormatada;
        }
      },
      {
        text: "R. provada", datafield: "reservaProvada", width: '23%', cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
          let linhaFormatada = this.montarCellRenderRecurso('reservaProvada', rowdata);
          return linhaFormatada;
        }
      },
      {
        text: "R. provada + provavel", datafield: "reservaProvadaProvavel", width: '23%', cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
          let linhaFormatada = this.montarCellRenderRecurso('reservaProvadaProvavel', rowdata);
          return linhaFormatada;
        }
      },
    ]

    this.dataFieldsReserva = [
      { name: "idSubstancia", type: "string", map: "idSubstancia", },
      { name: "idUnidadeMedidaCalculo", type: "string", map: "idUnidadeMedidaCalculo", },
      { name: "idUnidadeMedidaTeor", type: "string", map: "idUnidadeMedidaTeor", },
      { name: "idUnidadeMedida", type: "string", map: "idUnidadeMedida", },
      { name: "reservaProvada", type: "string", map: "reservaProvada", },
      { name: "reservaProvadaTeor", type: "string", map: "reservaProvadaTeor" },
      { name: "reservaProvadaSubstanciaContida", type: "string", map: "reservaProvadaSubstanciaContida" },
      { name: "reservaProvavel", type: "string", map: "reservaProvavel", },
      { name: "reservaProvavelTeor", type: "string", map: "reservaProvavelTeor" },
      { name: "reservaProvavelSubstanciaContida", type: "string", map: "reservaProvavelSubstanciaContida" },
      { name: "reservaProvadaProvavel", type: "string", map: "reservaProvadaProvavel", },
      { name: "reservaProvadaProvavelTeor", type: "string", map: "reservaProvadaProvavelTeor" },
      { name: "reservaProvadaProvavelSubstanciaContida", type: "string", map: "reservaProvadaProvavelSubstanciaContida" },
    ]

    this.colunasCalculoRecursoReserva = [
      { text: "Data da publicação da informação", datafield: "data", width: '30%' },
      { text: "Recurso", datafield: "dadosInformadosRecurso", width: '17%',  cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
        let texto = '';
        
        if(value === 'SIM')
          texto = 'Sim';
        else if(value === 'NAO')
          texto = 'Não';

        return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${texto}</div>`;
      }},
      { text: "Reserva", datafield: "dadosInformadosReserva", width: '17%',  cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
        let texto = '';
        
        if(value === 'SIM')
          texto = 'Sim';
        else if(value === 'NAO')
          texto = 'Não';

        return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${texto}</div>`;
      }},
      {
        text: "Método de classificação do cálculo", datafield: "idMetodoClassificacao", width: '30%', cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
          let nome = "";
          this.state.metodoClassificacaoCalculo?.forEach(item => {
            if (item?.id?.toString() === rowdata.idMetodoClassificacao?.toString()) {
              nome = item.nome;
            }
          });
          return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${nome}</div>`;
        }
      }
    ]
    this.dataFieldsCalculoRecursoReserva = [
      { name: "data", type: "string", map: "data" },
      { name: "dadosInformadosRecurso", type: "string", map: "dadosInformadosRecurso" },
      { name: "dadosInformadosReserva", type: "string", map: "dadosInformadosReserva" },
      { name: "idMetodoClassificacao", type: "string", map: "idMetodoClassificacao" },
    ]


    this.tabelaMetalogeniaRef = React.createRef();

    this.metalogeniaColumns = [
      { text: "Idade", datafield: "idade", width: "33%", align: 'center' },
      { text: "Erro", datafield: "erro", width: "33%", align: 'center' },
      { text: "Método", datafield: "metodo", width: "34%", align: 'center' },
    ];

    this.metalogeniaDataField = [
      { name: "id", type: "string", map: "id" },
      { name: "idade", type: "string", map: "idade" },
      { name: "erro", type: "string", map: "erro" },
      { name: "metodo", type: "string", map: "metodo" },
    ];

    this.metalogeniaSource = {
      dataType: 'array',
      localdata: [],
      datafields: this.metalogeniaDataField
    };

    this.metalogeniaAdapter = new jqx.dataAdapter(this.metalogeniaSource);

    this.colunasDeposito = [
      { text: "Nome", datafield: "nome", width: "54%" },
      { text: "Comprimento", datafield: "comprimento", width: "20%" },
      { text: "Largura", datafield: "largura", width: "20%" },
    ];

    this.datafieldsDeposito = [
      { name: "nome", type: "string", map: "nome" },
      { name: "comprimento", type: "number", map: "comprimento" },
      { name: "largura", type: "number", map: "largura" },
    ];

  }

  montarCellRenderRecurso(nome, rowdata) {
    let recurso = rowdata[nome] ?? '';
    let teor = rowdata[nome + 'Teor'] ?? '';
    let substanciaContida = rowdata[nome + 'SubstanciaContida'] ?? '';
    let unidadeMedidaRecurso = '';
    let unidadeMedidaTeor = '';
    let unidadeMedida = '';

    let tempArray = [];
    this.state.unidadesMedidaTeor.forEach(item => {
      tempArray.push({ id: item.id, nome: item.teorMedio })
    })

    if (recurso && recurso !== '') {
      this.state.unidadesMedida.forEach(medida => {
        if (medida.id?.toString() === rowdata.idUnidadeMedidaCalculo?.toString()) {
          unidadeMedidaRecurso = medida.sigla;
        }
      });
    }

    if (teor && teor !== '') {
      tempArray.forEach(medida => {
        if (medida.id?.toString() === rowdata.idUnidadeMedidaTeor?.toString()) {
          unidadeMedidaTeor = medida.nome;
        }
      });
    }

    if (substanciaContida && substanciaContida !== '') {
      this.state.unidadesMedida.forEach(medida => {
        if (medida.id?.toString() === rowdata.idUnidadeMedida?.toString()) {
          unidadeMedida = medida.sigla;
        }
      });
    }

    let linhaFormatada = `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;"><span style="color: blue">${recurso}${unidadeMedidaRecurso}</span> <span style="color: red">${teor}${unidadeMedidaTeor}</span> <span style="color: green">${substanciaContida}${unidadeMedida}</span></div>`;
    return linhaFormatada;
  }

  onSubmitSubform({ alteracoesForm }, formProps, origem) {
    formProps?.batch(() => {
      for (const [key, val] of Object.entries(alteracoesForm)) {
        formProps?.mutators?.setValue(`${key}`, val);
      }
    });
  }

  onSubmit({ alteracoesForm }) {
    this.props.referenciaForm.batch(() => {
      for (const [key, val] of Object.entries(alteracoesForm)) {
        this.props.referenciaForm.mutators.setValue(`${key}`, val);
      }
    });

    if (this.props.referenciaForm.getState().values.depositos) {
      let arrayDeposito = resolverExibidos(
        this.props.referenciaForm.getState().values.depositos
      );
      let arrayFonteReferencia = [];
      arrayDeposito.map((deposito) => {
        if (deposito.fontesReferencia) {
          let exibidos = resolverExibidos(deposito.fontesReferencia);
          exibidos.map((item) => {
            if (item.modoCadastro === "SIMPLIFICADO") {
              item.referencia = item.descricao;
            }
            if (item.modoCadastro === "DETALHADO") {
              const autores = item.autores.split(",");
              if (autores.length === 1)
                item.referencia = `${autores[0].split(" ").pop()}. (${item.ano
                  }). ${item.titulo}.`;
              if (autores.length === 2)
                item.referencia = `${autores[0].split(" ").pop()} e ${autores[1]
                  .split(" ")
                  .pop()}. (${item.ano}). ${item.titulo}.`;
              if (autores.length >= 3)
                item.referencia = `${autores[0].split(" ").pop()} et al. (${item.ano
                  }). ${item.titulo}.`;
            }
            if (item.modoCadastro === "BASE_PROJETOS" && item.idProjeto !== "")
              item.referencia = item.idProjeto;
            if (item.modoCadastro === "BASE_PROJETOS" && item.idProjeto === "")
              item.referencia = item.link;
          });
          arrayFonteReferencia.push(exibidos);
        }
        return 0;
      });
      this.props.atualizarTabelaFonteReferenciaRecursoMineral(
        arrayFonteReferencia.flat(),
        "DEPOSITO"
      );
    }
  }



  async componentDidMount() {
    let tempSubstancias = [];
    let substanciasPrincipais = [];
    let substanciasSecundarias = [];

    tempSubstancias = await resolverRequestRecmin("/tipoSubstancia");
    const half = Math.ceil(tempSubstancias.length / 2);
    substanciasPrincipais = tempSubstancias?.slice(0, half);
    substanciasSecundarias = tempSubstancias.slice(-half);



    this.setState({
      expressaoGeografica: await resolverRequestRecmin("/expressao"),
      formaGeometrica: await resolverRequestRecmin("/formaGeometrica"),
      tamanhoDeposito: await resolverRequestRecmin("/tamanhoDeposito"),
      metodoClassificacaoCalculo: await resolverRequestRecmin("/metodoClassificacao"),
      unidadesMedida: await resolverRequestRecmin("/medida"),
      unidadesMedidaTeor: await resolverRequestRecmin("/medida/teor"),
      substancias: await resolverRequestRecmin("/tipoSubstancia"),
      substanciasPrincipais: substanciasPrincipais,
      substanciasSecundarias: substanciasSecundarias
    })
  }

  preencherSubstanciasSubForm(form, substancias) {
    let tempSubstancias = [];

    substancias.forEach(substancia => {
      this.state.substancias.forEach(substanciaState => {
        if (substancia.idTipoSubstancia?.toString() === substanciaState.id?.toString()) {
          tempSubstancias.push(substanciaState)
        }
      })
    })
    this.substancias = tempSubstancias;
  }

  validacoesRecurso(valores) {
    let erros = [];
      
    if (!valores.idUnidadeMedidaCalculo && (valores.recursoMedido || valores.recursoIndicado || valores.recursoInferido || valores.recursoMedidoIndicado || valores.recursoMedidoIndicadoInferido || valores.recursoIndicadoInferido || valores.totalRecurso)) 
      erros.push(`Unidade de medida do cálculo é obrigatório. `);

    if (valores.idUnidadeMedidaCalculo && (!valores.recursoMedido && !valores.recursoIndicado && !valores.recursoInferido && !valores.recursoMedidoIndicado && !valores.recursoMedidoIndicadoInferido && !valores.recursoIndicadoInferido && !valores.totalRecurso))
      erros.push(`Um valor de recurso é obrigatório. `);

    if(valores.idSubstancia) {
      if (!valores.idUnidadeMedidaTeor) 
        erros.push(`Unidade de medida do teor é obrigatório. `);
        
      else if (!valores.teorCorte && !valores.recursoMedidoTeor && !valores.recursoIndicadoTeor && !valores.recursoInferidoTeor && !valores.recursoMedidoIndicadoTeor && !valores.recursoMedidoIndicadoInferidoTeor && !valores.recursoIndicadoInferidoTeor && !valores.totalRecursoTeor)
        erros.push(`Um valor de teor rec. é obrigatório. `);
    }

    if(valores.idUnidadeMedidaTeor && !valores.idSubstancia)
      erros.push(`Substancia é obrigatória. `);

    else if(!valores.idSubstancia && (valores.teorCorte || valores.recursoMedidoTeor || valores.recursoIndicadoTeor || valores.recursoInferidoTeor || valores.recursoMedidoIndicadoTeor || valores.recursoMedidoIndicadoInferidoTeor || valores.recursoIndicadoInferidoTeor || valores.totalRecursoTeor)) 
      erros.push(`Substancia é obrigatória. `);

    if (!valores.idUnidadeMedidaTeor && (valores.teorCorte || valores.recursoMedidoTeor || valores.recursoIndicadoTeor || valores.recursoInferidoTeor || valores.recursoMedidoIndicadoTeor || valores.recursoMedidoIndicadoInferidoTeor || valores.recursoIndicadoInferidoTeor || valores.totalRecursoTeor)) 
      erros.push(`Unidade de medida do teor é obrigatório. `);

    if(valores.idUnidadeMedidaTeor && (!valores.teorCorte && !valores.recursoMedidoTeor && !valores.recursoIndicadoTeor && !valores.recursoInferidoTeor && !valores.recursoMedidoIndicadoTeor && !valores.recursoMedidoIndicadoInferidoTeor && !valores.recursoIndicadoInferidoTeor && !valores.totalRecursoTeor))
      erros.push(`Um valor de teor rec. é obrigatório. `);

    if (!valores.idUnidadeMedida && (valores.recursoMedidoSubstanciaContida || valores.recursoIndicadoSubstanciaContida || valores.recursoInferidoSubstanciaContida || valores.recursoMedidoIndicadoSubstanciaContida || valores.recursoMedidoIndicadoInferidoSubstanciaContida || valores.recursoIndicadoInferidoSubstanciaContida || valores.recursoMedidoIndicadoInferidoSubstanciaContida || valores.totalRecursoSubstanciaContida))
      erros.push(`Unidade de medida da substância contida é obrigatório. `);

    if (valores.idUnidadeMedida && (!valores.recursoMedidoSubstanciaContida && !valores.recursoIndicadoSubstanciaContida && !valores.recursoInferidoSubstanciaContida && !valores.recursoMedidoIndicadoSubstanciaContida && !valores.recursoMedidoIndicadoInferidoSubstanciaContida && !valores.recursoIndicadoInferidoSubstanciaContida && !valores.recursoMedidoIndicadoInferidoSubstanciaContida && !valores.totalRecursoSubstanciaContida))
      erros.push(` Um valor de Subs. cont. rec. é obrigatório. `);

    if (erros.length) {
      let message = '';
      [...new Set(erros)].forEach(e => message += e);
      return {
        [FORM_ERROR]: message
      }
    }
  }

  validarReserva(valores) {
    let erros = [];

    if (!valores.idUnidadeMedidaCalculo && (valores.reservaProvavel || valores.reservaProvada || valores.reservaProvadaProvavel || valores.totalReserva)) 
      erros.push(`Unidade de medida do cálculo é obrigatório. `);
    
    if (valores.idUnidadeMedidaCalculo && !valores.reservaProvavel && !valores.reservaProvada && !valores.reservaProvadaProvavel && !valores.totalReserva)
      erros.push(`Um valor de reserva é obrigatório. `);

    if(valores.idSubstancia) {
      if (!valores.idUnidadeMedidaTeor) 
        erros.push(`Unidade de medida do teor é obrigatório. `);
      
      else if (!valores.teorCorte && !valores.reservaProvavelTeor && !valores.reservaProvadaTeor && !valores.reservaProvadaProvavelTeor && !valores.totalReservaTeor)
        erros.push(`Um valor de teor reserva é obrigatório. `);
    }

    if(valores.idUnidadeMedidaTeor && !valores.idSubstancia)
      erros.push(`Substancia é obrigatória. `);
    
    else if((valores.teorCorte || valores.reservaProvavelTeor || valores.reservaProvadaTeor || valores.reservaProvadaProvavelTeor || valores.totalReservaTeor) && !valores.idSubstancia)
      erros.push(`Substancia é obrigatória. `);

    if (!valores.idUnidadeMedidaTeor && (valores.teorCorte || valores.reservaProvavelTeor || valores.reservaProvadaTeor || valores.reservaProvadaProvavelTeor || valores.totalReservaTeor))
      erros.push(`Unidade de medida do teor é obrigatório. `);

    if (valores.idUnidadeMedidaTeor && (!valores.teorCorte && !valores.reservaProvavelTeor && !valores.reservaProvadaTeor && !valores.reservaProvadaProvavelTeor && !valores.totalReservaTeor))
      erros.push(`Um valor de teor reserva é obrigatório. `);

    if (!valores.idUnidadeMedida && (valores.reservaProvavelSubstanciaContida || valores.reservaProvadaSubstanciaContida || valores.reservaProvadaProvavelSubstanciaContida || valores.totalReservaSubstanciaContida))
      erros.push(`Unidade de medida da substância contida é obrigatório. `);
    
    if (valores.idUnidadeMedida && (!valores.reservaProvavelSubstanciaContida && !valores.reservaProvadaSubstanciaContida && !valores.reservaProvadaProvavelSubstanciaContida && !valores.totalReservaSubstanciaContida))
      erros.push(`Um valor de substância contida reserva é obrigatório. `);

    if (erros.length) {
      let message = '';
      [...new Set(erros)].forEach(e => message += e);
      return {
        [FORM_ERROR]: message
      }
    }
  }

  render() {
    return (
      <Field name={"depositos"} subscription={{ value: true }}>
        {({ input: { value: depositos = {} } }) => {
          let list = resolverExibidos(depositos);
          // TODO corrigir implementação da validação está considerando a substância do depósito editado quando altera a substância principal
          // Mesmo tendo apenas um depósito a mensagem é exibida
          let validate = comporValidadores(
            validacoesDeposito,
            fonteReferenciaVazia,
            substanciaObrigatoriaDeposito,
            validacaoReserva,
          );

          return (
            <SubForm
            {...getPropsPermissao(this.props.permitirEdicao)}
            keepDirtyOnReinitialize
            tituloForm='Depósito'
            nome="depositos"
            onSubmit={this.onSubmit.bind(this)}
            mutators={{ setValue }}
            elementos={depositos}
            valoresIniciais={{
              data: dayjs().format("DD/MM/YYYY")
            }}
            colunas={this.colunasDeposito}
            campos={this.datafieldsDeposito}
            validar={(valores) => this.props.modoDefinitivo ? validate(valores) : undefined}
            formSubscription={{}}
            renderForm={({ formProps }) => {
              return <>
                <Row>
                  <Col md={12}>
                    <Card.Title>Depósito</Card.Title>
                  </Col>
                </Row>
                <Row>
                  <Col md={8}>
                    <Field
                      name="nome"
                      component={TextInputField}
                      label="Nome"
                      dica="Informar o nome do depósito (se existente) ou toponímia"
                      maxLength={255}
                      required={this.props.modoDefinitivo}
                      validate={validarSe(this.props.modoDefinitivo, campoObrigatorioComMsgGenerica("Nome"))}
                      disabled={!this.props.permitirEdicao}
                    />
                  </Col>
                  <Col md={4}>
                    <Field
                      component={DateTimeInputField}
                      name="data"
                      label="Data de cadastro da informação"
                      dica="Campo de preenchimento automático com a data de cadastramento da informação"
                      required={this.props.modoDefinitivo}
                      validate={validarSe(this.props.modoDefinitivo, campoObrigatorioComMsgGenerica("Data de cadastro da informação"))}
                      disabled={true}
                    />
                  </Col>
                </Row>
                <Row className="mt-3 mb-3">
                  <Col md={3}>
                    <Field
                      name="comprimento"
                      component={NumberInputField}
                      permitirNegativos={false}
                      casasInteiros={4}
                      label="Comprimento (m)"
                      dica="Informar o comprimento do corpo mineralizado"
                      disabled={!this.props.permitirEdicao}
                    />
                  </Col>
                  <Col md={3}>
                    <Field
                      disabled={!this.props.permitirEdicao}
                      name="largura"
                      component={NumberInputField}
                      permitirNegativos={false}
                      casasInteiros={4}
                      label="Largura (m)"
                      dica="Informar a largura ou espessura do corpo mineralizado"
                    />
                  </Col>
                  <Col md={3}>
                    <Field
                      disabled={!this.props.permitirEdicao}
                      name="idExpressaoGeografica"
                      component={DropDownListField}
                      label="Expressão do corpo mineralizado"
                      dica="Selecione na biblioteca a característica ou feição que melhor descreva a expressão do corpo mineralizado"
                      elementos={this.state.expressaoGeografica}
                    />
                  </Col>
                  <Col md={3}>
                    <Field
                      disabled={!this.props.permitirEdicao}
                      name="idFormaGeometrica"
                      component={DropDownListField}
                      label="Forma geométrica"
                      dica="Selecione da biblioteca a feição que melhor descreve espacialmente a forma do corpo mineralizado"
                      elementos={this.state.formaGeometrica}
                    />
                  </Col>
                  <Col md={12}>
                      <Field
                        component={TextAreaField}
                        name={`informacoesRelevantes`}
                        label="Informações relevantes"
                        dica="Caso necessário, detalhe o depósito"
                        maxLength={250}
                        disabled={!this.props.permitirEdicao}
                      />
                    </Col>
                </Row>
                <Field
                  name="listaSubstanciasDeposito"
                  component={HiddenField}
                />
                <Row className="mt-4 mb-4">
                  <Col md={12}>
                    <Substancia
                      hintSubstancia={"Selecione a substância a partir da biblioteca formada pelas informações cadastradas no formulário Dados do Recurso Mineral (acima)"}
                      removerTeor={true}
                      permitirEdicao={this.props.permitirEdicao}
                      referenciaForm={formProps.form}
                      substanciasPrincipais={this.props.substanciasPrincipais}
                      substanciasSecundarias={this.props.substanciasSecundarias}
                      substanciasDeposito={true}
                      preencherSubstanciasSubForm={this.preencherSubstanciasSubForm.bind(this)}
                      modoDefinitivo={this.props.modoDefinitivo}
                    />
                  </Col>
                </Row>
                <Card className="mt-3 mb-4">
                  <Card.Body>
                    <Row className="mb-3">
                      <Col md={12}>
                        <Field name={"calculoRecursoReserva"} subscription={{ value: true }}>
                          {({ input: { value: calculoRecursoReserva = {} } }) => {
                            return <SubForm
                              tituloForm="Cálculo de recursos e reservas"
                              nome="calculoRecursoReserva"
                              onSubmit={(alteracoesForm) => this.onSubmitSubform(alteracoesForm, formProps.form)}
                              colunas={this.colunasCalculoRecursoReserva}
                              campos={this.dataFieldsCalculoRecursoReserva}
                              mutators={{ setValue }}
                              elementos={calculoRecursoReserva}
                              valoresIniciais={{
                                'dadosInformadosRecurso': 'NAO' ,
                                'dadosInformadosReserva': 'NAO' 
                              }}
                              formSubscription={{}}
                              {...getPropsPermissao(this.props.permitirEdicao)}
                              renderForm={({ formProps }) => {
                                return <>
                                  <Row>
                                    <Col md={12}>
                                      <Card.Title>Cálculo de recursos e reservas</Card.Title>
                                    </Col>
                                  </Row>
                                  <Card className="mt-3 mb-3">
                                    <Card.Body>
                                      <Row>
                                        <Col md={12}>
                                          <Row>
                                            <Col md={12}>
                                              <Card.Title>
                                                Recurso
                                              </Card.Title>
                                            </Col>
                                          </Row>
                                          <Row>
                                            <Col md={12}>
                                            <Form.Label className="small">
                                            Dados informados <Hint textoAjuda="Confirmar se dados de recursos foram informados ou não" />
                                              </Form.Label>
                                              <Field name={"dadosInformadosRecurso"} subscription={{ value: true }}>
                                                {({ input: { value: dadosInformadosRecurso } }) => {
                                                  return <Field
                                                    disabled={!this.props.permitirEdicao}
                                                    component={RadioButtonSetField}
                                                    horizontal
                                                    name="dadosInformadosRecurso"
                                                    elementos={[{ id: "SIM", nome: "Sim" }, { id: "NAO", nome: "Não" }]}
                                                  />
                                                }}
                                              </Field>
                                            </Col>
                                          </Row>
                                            {this.state.exibirRecurso &&
                                              <Row className="mt-4">
                                                <Col md={12}>
                                                  <Field name={"dadosInformadosRecurso"} subscription={{ value: true }}>
                                                    {({ input: { value: dadosInformadosRecurso } }) =>
                                                      dadosInformadosRecurso === 'SIM' ?
                                                        <Card>
                                                          <Card.Body>
                                                            <Field name={"recurso"} subscription={{ value: true }}>
                                                              {({ input: { value: recurso = {} } }) => {
                                                                return <SubForm
                                                                  tituloForm={<Hint textoAjuda={'R. med: Recurso medido\nR. ind: Recurso indicado\nR. inf: Recurso inferido\nR. med + ind: Recurso medido + indicado\nR. ind + inf: Recurso indicado + inferido\nR. med + ind + inf: Recurso medido + indicado + inferido'} />}
                                                                  nome="recurso"
                                                                  onSubmit={(alteracoesForm) => this.onSubmitSubform(alteracoesForm, formProps.form, 'recurso')}
                                                                  colunas={this.colunasRecurso}
                                                                  campos={this.dataFieldsRecurso}
                                                                  mutators={{ setValue }}
                                                                  aoConfirmarVoltar={() => { }}
                                                                  elementos={recurso}
                                                                  exibirTabelaSemRegistro={true}
                                                                  formSubscription={{}}
                                                                  validar={(valores) => this.props.modoDefinitivo ? this.validacoesRecurso(valores) : undefined}
                                                                  renderForm={({ formProps }) => {
                                                                    return <Container fluid>
                                                                      <Container className="p-4 border mb-3">
                                                                        <Row>
                                                                          <Col md={{ span: 4, offset: 4 }}>
                                                                            <Field name={'idSubstancia'} subscription={{ value: true }}>
                                                                              {({ input: { value: idSubstancia } }) => {
                                                                                return <Field
                                                                                  disabled={!this.props.permitirEdicao}
                                                                                  component={DropDownListField}
                                                                                  name="idSubstancia"
                                                                                  label="Substância"
                                                                                  dica="Selecione a partir da biblioteca a substância que terá os recursos minerais cadastrados"
                                                                                  elementos={this.substancias}
                                                                                />
                                                                              }}</Field>
                                                                          </Col>
                                                                        </Row>
                                                                        <Row className="mt-3">
                                                                          <Col md={{ span: 4, offset: 4 }}>
                                                                            <Field
                                                                              disabled={!this.props.permitirEdicao}
                                                                              name="teorCorte"
                                                                              component={NumberInputField}
                                                                              permitirNegativos={false}
                                                                              label="Teor de corte da substância"
                                                                              dica="Informe o valor do Teor de corte da substância utilizado no cálculo do recurso"
                                                                              casasDecimais={3}
                                                                              casasInteiros={9}
                                                                            />
                                                                          </Col>
                                                                        </Row>
                                                                        <Row className='mt-3 mb-3'>
                                                                          <Col md={4}>
                                                                            <Field
                                                                              disabled={!this.props.permitirEdicao}
                                                                              component={DropDownListField}
                                                                              name="idUnidadeMedidaCalculo"
                                                                              label="Unidade de medida do cálculo"
                                                                              dica="Informe a unidade medida utilizada para o cálculo do recurso mineral"
                                                                              elementos={this.state.unidadesMedida}
                                                                            />
                                                                          </Col>
                                                                          <Col md={4}>
                                                                            <Field name={"idUnidadeMedidaTeor"} subscription={{ value: true }}>
                                                                              {({ input: { value: idUnidadeMedidaTeor } }) => {
                                                                                let tempArray = [];
                                                                                this.state.unidadesMedidaTeor.forEach(item => {
                                                                                  tempArray.push({ id: item.id, nome: item.teorMedio })
                                                                                })
                                                                                return <Field
                                                                                  disabled={!this.props.permitirEdicao}
                                                                                  component={DropDownListField}
                                                                                  name="idUnidadeMedidaTeor"
                                                                                  label="Unidade de medida do teor"
                                                                                  dica="Informe a unidade de medida do teor utilizada para o cálculo do recurso mineral"
                                                                                  elementos={tempArray}
                                                                                />
                                                                              }}</Field>

                                                                          </Col>
                                                                          <Col md={4}>
                                                                            <Field
                                                                              disabled={!this.props.permitirEdicao}
                                                                              component={DropDownListField}
                                                                              name="idUnidadeMedida"
                                                                              label="Unidade de medida da substância contida"
                                                                              dica="Informe a Unidade de medida da substância contida no recurso mineral"
                                                                              elementos={this.state.unidadesMedida}
                                                                            />
                                                                          </Col>
                                                                        </Row>
                                                                      </Container>
                                                                      <Container className="p-4" style={{ backgroundColor: '#dadada' }}>
                                                                        <Row className="mb-3">
                                                                          <Col md={4}>
                                                                            <Field
                                                                              disabled={!this.props.permitirEdicao}
                                                                              component={NumberInputField}
                                                                              permitirNegativos={false}
                                                                              name="recursoMedido"
                                                                              label="Recurso medido"
                                                                              dica="Informe o total de Recurso medido calculado"
                                                                              casasDecimais={3}
                                                                              casasInteiros={9}

                                                                            />
                                                                          </Col>
                                                                          <Col md={4}>
                                                                            <Field
                                                                              disabled={!this.props.permitirEdicao}
                                                                              component={NumberInputField}
                                                                              permitirNegativos={false}
                                                                              name="recursoMedidoTeor"
                                                                              label="Teor rec. medido"
                                                                              dica="Informe o total de Recurso medido calculado"
                                                                              casasDecimais={3}
                                                                              casasInteiros={9}

                                                                            />
                                                                          </Col>
                                                                          <Col md={4}>
                                                                            <Field
                                                                              disabled={!this.props.permitirEdicao}
                                                                              component={NumberInputField}
                                                                              permitirNegativos={false}
                                                                              name="recursoMedidoSubstanciaContida"
                                                                              label="Subs. cont. rec. medido"
                                                                              dica="Informe a quantidade total da Substância contida no Recurso medido calculado"
                                                                              casasDecimais={3}
                                                                              casasInteiros={9}

                                                                            />
                                                                          </Col>
                                                                        </Row>
                                                                      </Container>
                                                                      <Container className="p-4" style={{ backgroundColor: '#e8e8e8' }}>
                                                                        <Row className="mb-3">
                                                                          <Col md={4}>
                                                                            <Field
                                                                              disabled={!this.props.permitirEdicao}
                                                                              component={NumberInputField}
                                                                              permitirNegativos={false}
                                                                              name="recursoIndicado"
                                                                              label="Recurso indicado"
                                                                              dica="Informe o total de Recurso indicado calculado"
                                                                              casasDecimais={3}
                                                                              casasInteiros={9}

                                                                            />
                                                                          </Col>
                                                                          <Col md={4}>
                                                                            <Field
                                                                              disabled={!this.props.permitirEdicao}
                                                                              component={NumberInputField}
                                                                              permitirNegativos={false}
                                                                              name="recursoIndicadoTeor"
                                                                              label="Teor rec. indicado"
                                                                              dica="Informe o total de Recurso indicado calculado"
                                                                              casasDecimais={3}
                                                                              casasInteiros={9}

                                                                            />
                                                                          </Col>
                                                                          <Col md={4}>
                                                                            <Field
                                                                              disabled={!this.props.permitirEdicao}
                                                                              component={NumberInputField}
                                                                              permitirNegativos={false}
                                                                              name="recursoIndicadoSubstanciaContida"
                                                                              label="Subs. cont. rec. indicado"
                                                                              dica="Informe a quantidade total da Substância contida no Recurso indicado calculado"
                                                                              casasDecimais={3}
                                                                              casasInteiros={9}

                                                                            />
                                                                          </Col>
                                                                        </Row>
                                                                      </Container>
                                                                      <Container className="p-4" style={{ backgroundColor: '#dadada' }}>
                                                                        <Row className="mb-3">
                                                                          <Col md={4}>
                                                                            <Field
                                                                              disabled={!this.props.permitirEdicao}
                                                                              component={NumberInputField}
                                                                              permitirNegativos={false}
                                                                              name="recursoInferido"
                                                                              label="Recurso inferido"
                                                                              dica="Informe o total de Recurso inferido calculado"
                                                                              casasDecimais={3}
                                                                              casasInteiros={9}

                                                                            />
                                                                          </Col>
                                                                          <Col md={4}>
                                                                            <Field
                                                                              disabled={!this.props.permitirEdicao}
                                                                              component={NumberInputField}
                                                                              permitirNegativos={false}
                                                                              name="recursoInferidoTeor"
                                                                              label="Teor rec. inferido"
                                                                              dica="Informe o total de Recurso inferido calculado"
                                                                              casasDecimais={3}
                                                                              casasInteiros={9}

                                                                            />
                                                                          </Col>
                                                                          <Col md={4}>
                                                                            <Field
                                                                              disabled={!this.props.permitirEdicao}
                                                                              component={NumberInputField}
                                                                              permitirNegativos={false}
                                                                              name="recursoInferidoSubstanciaContida"
                                                                              label="Subs. cont. rec. inferido"
                                                                              dica="Informe a quantidade total da Substância contida no Recurso inferido calculado"
                                                                              casasDecimais={3}
                                                                              casasInteiros={9}

                                                                            />
                                                                          </Col>
                                                                        </Row>
                                                                      </Container>
                                                                      <Container className="p-4" style={{ backgroundColor: '#e8e8e8' }}>
                                                                        <Row className="mb-3">
                                                                          <Col md={4}>
                                                                            <Field
                                                                              disabled={!this.props.permitirEdicao}
                                                                              component={NumberInputField}
                                                                              permitirNegativos={false}
                                                                              name="recursoMedidoIndicado"
                                                                              label="Recurso medido + indicado"
                                                                              dica="Informe o total de Recurso medido + indicado calculado"

                                                                              casasDecimais={3}
                                                                              casasInteiros={9}

                                                                            />
                                                                          </Col>
                                                                          <Col md={4}>
                                                                            <Field
                                                                              disabled={!this.props.permitirEdicao}
                                                                              component={NumberInputField}
                                                                              permitirNegativos={false}
                                                                              name="recursoMedidoIndicadoTeor"
                                                                              label="Teor rec. medido + indicado"
                                                                              dica="Informe o total de Recurso medido + indicado calculado"
                                                                              casasDecimais={3}
                                                                              casasInteiros={9}

                                                                            />
                                                                          </Col>
                                                                          <Col md={4}>
                                                                            <Field
                                                                              disabled={!this.props.permitirEdicao}
                                                                              component={NumberInputField}
                                                                              permitirNegativos={false}
                                                                              name="recursoMedidoIndicadoSubstanciaContida"
                                                                              label="Subs. cont. rec. medido + indicado"
                                                                              dica="Informe a quantidade total da Substância contida no Recurso medido + indicado calculado"
                                                                              casasDecimais={3}
                                                                              casasInteiros={9}

                                                                            />
                                                                          </Col>
                                                                        </Row>
                                                                      </Container>
                                                                      <Container className="p-4" style={{ backgroundColor: '#dadada' }}>
                                                                        <Row className="mb-3">
                                                                          <Col md={4}>
                                                                            <Field
                                                                              disabled={!this.props.permitirEdicao}
                                                                              component={NumberInputField}
                                                                              permitirNegativos={false}
                                                                              name="recursoMedidoIndicadoInferido"
                                                                              label="Recurso medido + indicado + inferido"
                                                                              dica="Informe o total de Recurso medido + indicado + inferido calculado"
                                                                              casasDecimais={3}
                                                                              casasInteiros={9}

                                                                            />
                                                                          </Col>
                                                                          <Col md={4}>
                                                                            <Field
                                                                              disabled={!this.props.permitirEdicao}
                                                                              component={NumberInputField}
                                                                              permitirNegativos={false}
                                                                              name="recursoMedidoIndicadoInferidoTeor"
                                                                              label="Teor rec. medido + indicado + inferido"
                                                                              dica="Informe o total de Recurso medido + indicado + inferido calculado"
                                                                              casasDecimais={3}
                                                                              casasInteiros={9}

                                                                            />
                                                                          </Col>
                                                                          <Col md={4}>
                                                                            <Field
                                                                              disabled={!this.props.permitirEdicao}
                                                                              component={NumberInputField}
                                                                              permitirNegativos={false}
                                                                              name="recursoMedidoIndicadoInferidoSubstanciaContida"
                                                                              label="Subs. cont. rec. medido + indicado + inferido"
                                                                              dica="Informe a quantidade total da Substância contida no Recurso medido + indicado + inferido calculado"
                                                                              casasDecimais={3}
                                                                              casasInteiros={9}

                                                                            />
                                                                          </Col>
                                                                        </Row>
                                                                      </Container>
                                                                      <Container className="p-4" style={{ backgroundColor: '#e8e8e8' }}>
                                                                        <Row className="mb-3">
                                                                          <Col md={4}>
                                                                            <Field
                                                                              disabled={!this.props.permitirEdicao}
                                                                              component={NumberInputField}
                                                                              permitirNegativos={false}
                                                                              name="recursoIndicadoInferido"
                                                                              label="Recurso indicado + inferido"
                                                                              dica="Informe o total de Recurso indicado + inferido calculado"
                                                                              casasDecimais={3}
                                                                              casasInteiros={9}

                                                                            />
                                                                          </Col>
                                                                          <Col md={4}>
                                                                            <Field
                                                                              disabled={!this.props.permitirEdicao}
                                                                              component={NumberInputField}
                                                                              permitirNegativos={false}
                                                                              name="recursoIndicadoInferidoTeor"
                                                                              label="Teor rec. indicado + inferido"
                                                                              dica="Informe o total de Recurso indicado + inferido calculado"
                                                                              casasDecimais={3}
                                                                              casasInteiros={9}

                                                                            />
                                                                          </Col>
                                                                          <Col md={4}>
                                                                            <Field
                                                                              disabled={!this.props.permitirEdicao}
                                                                              component={NumberInputField}
                                                                              permitirNegativos={false}
                                                                              name="recursoIndicadoInferidoSubstanciaContida"
                                                                              label="Subs. cont. rec. indicado + inferido"
                                                                              dica="Informe a quantidade total da Substância contida no Recurso indicado + inferido calculado"
                                                                              casasDecimais={3}
                                                                              casasInteiros={9}

                                                                            />
                                                                          </Col>
                                                                        </Row>
                                                                      </Container>

                                                                      <Container className="p-4 mb-3" style={{ backgroundColor: '#dadada' }}>
                                                                        <Row className="mt-3 mb-3">
                                                                          <Col md={4}>
                                                                            <Field
                                                                              component={NumberInputField}
                                                                              permitirNegativos={false}
                                                                              name="totalRecurso"
                                                                              label="Recurso total não classificado"
                                                                              dica="Informe o total de Recurso total não classificado"
                                                                              casasDecimais={3}
                                                                              casasInteiros={9}
                                                                              disabled={!this.props.permitirEdicao}
                                                                            />
                                                                          </Col>
                                                                          <Col md={4}>
                                                                            <Field
                                                                              component={NumberInputField}
                                                                              permitirNegativos={false}
                                                                              name="totalRecursoTeor"
                                                                              label="Teor recurso total não classificado"
                                                                              dica="Informe o total de Recurso total não classificado calculado"
                                                                              casasDecimais={3}
                                                                              casasInteiros={9}
                                                                              disabled={!this.props.permitirEdicao}
                                                                            />
                                                                          </Col>
                                                                          <Col md={4}>
                                                                            <Field
                                                                              component={NumberInputField}
                                                                              permitirNegativos={false}
                                                                              name="totalRecursoSubstanciaContida"
                                                                              label="Subs. cont. rec. total não classificado"
                                                                              dica="Informe a quantidade total da Substância contida no Recurso total não classificado calculado"
                                                                              casasDecimais={3}
                                                                              casasInteiros={9}
                                                                              disabled={!this.props.permitirEdicao}
                                                                            />
                                                                          </Col>
                                                                        </Row>
                                                                      </Container>
                                                                    </Container>
                                                                  }}
                                                                />
                                                              }}</Field>
                                                            <Row>
                                                              <Col md={6} id="legendaRecurso">
                                                                <FormSpy subscription={{ values: true }}>
                                                                  {({ values }) => {

                                                                    let exibir = false;

                                                                    if (values && values.recurso) {
                                                                      if (resolverExibidos(values.recurso).filter(i => i != null).length)
                                                                        exibir = true;
                                                                    }

                                                                    return exibir ? <Container className="p-2 pr-4 mt-3" style={{ backgroundColor: '#e8e8e8', borderRadius: '5px' }}>
                                                                      <Row className="d-flex justify-content-center align-items-center">
                                                                        <Col md={1} className="p-0 d-flex justify-content-end">
                                                                          <FontAwesomeIcon size={70} color="blue" icon={faSquare} />
                                                                        </Col>
                                                                        <Col md={3} className="p-0 d-flex justify-content-start"><p className="m-0">Recurso</p></Col>
                                                                        <Col md={1} className="p-0 d-flex justify-content-end">
                                                                          <FontAwesomeIcon color="red" icon={faSquare} />
                                                                        </Col>
                                                                        <Col md={3} className="p-0 d-flex justify-content-start"><p className="m-0">Teor</p></Col>
                                                                        <Col md={1} className="p-0 d-flex justify-content-end">
                                                                          <FontAwesomeIcon size="100%" color="green" icon={faSquare} />
                                                                        </Col>
                                                                        <Col md={3} className="p-0 d-flex justify-content-start"><p className="m-0">Substância contida</p></Col>
                                                                      </Row>
                                                                    </Container>
                                                                      : <></>
                                                                  }}
                                                                </FormSpy>
                                                              </Col>
                                                            </Row>
                                                          </Card.Body>
                                                        </Card>

                                                        :
                                                        <Row>
                                                          <Col md={12}>
                                                            <Field
                                                              disabled={!this.props.permitirEdicao}
                                                              component={TextAreaField}
                                                              name="descricaoRecurso"
                                                              label="Descrição"
                                                              dica="Descreva de forma clara e sucinta as informações dos recursos existentes, se for o caso"
                                                              maxLength={255}
                                                            />
                                                          </Col>
                                                        </Row>
                                                    }
                                                  </Field>

                                                </Col>
                                              </Row>
                                            }
                                          </Col>
                                        </Row>
                                      </Card.Body>
                                    </Card>
                                    <Card className="mt-3 mb-3">
                                      <Card.Body>
                                        <Row>
                                          <Col md={12}>
                                            <Row>
                                              <Col md={12}>
                                                <Card.Title>
                                                  Reserva
                                                </Card.Title>
                                              </Col>
                                            </Row>
                                            <Row>
                                              <Col md={12}>
                                              <Form.Label className="small">
                                                Dados informados <Hint textoAjuda="Confirmar se dados de reservas foram informados ou não" />
                                              </Form.Label>
                                                <Field name={"dadosInformadosReserva"} subscription={{ value: true }}>
                                                  {({ input: { value: dadosInformadosReserva } }) => {
                                                    return <Field
                                                      disabled={!this.props.permitirEdicao}
                                                      component={RadioButtonSetField}
                                                      horizontal
                                                      name="dadosInformadosReserva"
                                                      elementos={[{ id: "SIM", nome: "Sim" }, { id: "NAO", nome: "Não" }]}
                                                    />
                                                  }}
                                                </Field>
                                              </Col>
                                            </Row>
                                            <Row className="mt-4">
                                              <Col md={12}>
                                                <Field name={"dadosInformadosReserva"} subscription={{ value: true }}>
                                                  {({ input: { value: dadosInformadosRecurso } }) =>
                                                    dadosInformadosRecurso === 'SIM' ?
                                                      <Card>
                                                        <Card.Body>
                                                          <Field name={"reserva"} subscription={{ value: true }}>
                                                            {({ input: { value: reserva = {} } }) => {
                                                              return <SubForm
                                                                tituloForm={<Hint textoAjuda={'R. provável: Reserva provável\nR. provada: Reserva provada\nR. provada + provável: Reserva provada + provável'} />}
                                                                nome="reserva"
                                                                onSubmit={(alteracoesForm) => this.onSubmitSubform(alteracoesForm, formProps.form, 'reserva')}
                                                                colunas={this.colunasReserva}
                                                                campos={this.dataFieldsReserva}
                                                                mutators={{ setValue }}
                                                                aoConfirmarVoltar={() => { }}
                                                                elementos={reserva}
                                                                exibirTabelaSemRegistro={true}
                                                                formSubscription={{}}
                                                                validar={(valores) => this.props.modoDefinitivo ? this.validarReserva(valores) : undefined}
                                                                renderForm={({ formProps }) => {
                                                                  return <Container fluid>
                                                                    <Container className="p-4 border mb-3">
                                                                      <Row>
                                                                        <Col md={{ span: 4, offset: 4 }}>
                                                                          <Field name={'idSubstancia'} subscription={{ value: true }}>
                                                                            {({ input: { value: idSubstancia } }) => {
                                                                              return <Field
                                                                                disabled={!this.props.permitirEdicao}
                                                                                component={DropDownListField}
                                                                                name="idSubstancia"
                                                                                label="Substância"
                                                                                dica="Selecione a partir da biblioteca a substância que terá as reservas minerais cadastrados"
                                                                                elementos={this.substancias}
                                                                              />
                                                                            }}</Field>
                                                                        </Col>

                                                                      </Row>
                                                                      <Row className="mt-3">
                                                                        <Col md={{ span: 4, offset: 4 }}>
                                                                          <Field
                                                                            disabled={!this.props.permitirEdicao}
                                                                            name="teorCorte"
                                                                            component={NumberInputField}
                                                                            permitirNegativos={false}
                                                                            label="Teor de corte da substância"
                                                                            dica="Informe o valor do Teor de corte da substância utilizado no cálculo da reserva"
                                                                            casasDecimais={3}
                                                                            casasInteiros={9}
                                                                          />
                                                                        </Col>
                                                                      </Row>
                                                                      <Row className='mt-3 mb-3'>
                                                                        <Col md={4}>
                                                                          <Field
                                                                            disabled={!this.props.permitirEdicao}
                                                                            component={DropDownListField}
                                                                            name="idUnidadeMedidaCalculo"
                                                                            label="Unidade de medida do cálculo"
                                                                            dica="Informe a unidade medida utilizada para o cálculo da reserva mineral"
                                                                            elementos={this.state.unidadesMedida}
                                                                          />
                                                                        </Col>
                                                                        <Col md={4}>
                                                                          <Field name={"idUnidadeMedidaTeor"} subscription={{ value: true }}>
                                                                            {({ input: { value: idUnidadeMedidaTeor } }) => {
                                                                              let tempArray = [];
                                                                              this.state.unidadesMedidaTeor.forEach(item => {
                                                                                tempArray.push({ id: item.id, nome: item.teorMedio })
                                                                              })
                                                                              return <Field
                                                                                disabled={!this.props.permitirEdicao}
                                                                                component={DropDownListField}
                                                                                name="idUnidadeMedidaTeor"
                                                                                label="Unidade de medida do teor"
                                                                                dica="Informe a unidade de medida do teor utilizada para o cálculo do recurso mineral"
                                                                                elementos={tempArray}
                                                                              />
                                                                            }}</Field>
                                                                        </Col>
                                                                        <Col md={4}>
                                                                          <Field
                                                                            disabled={!this.props.permitirEdicao}
                                                                            component={DropDownListField}
                                                                            name="idUnidadeMedida"
                                                                            label="Unidade de medida da substância contida"
                                                                            dica="Informe a Unidade de medida da substância contida no recurso mineral"
                                                                            elementos={this.state.unidadesMedida}
                                                                          />
                                                                        </Col>
                                                                      </Row>
                                                                    </Container>
                                                                    <Container className="p-4" style={{ backgroundColor: '#dadada' }}>
                                                                      <Row>
                                                                        <Col md={4}>
                                                                          <Field
                                                                            disabled={!this.props.permitirEdicao}
                                                                            component={NumberInputField}
                                                                            permitirNegativos={false}
                                                                            name="reservaProvavel"
                                                                            label="Reserva provável"
                                                                            dica="Informar a reserva provável (segundo critério do CBRR)"
                                                                            casasDecimais={3}
                                                                            casasInteiros={9}

                                                                          />
                                                                        </Col>
                                                                        <Col md={4}>
                                                                          <Field
                                                                            disabled={!this.props.permitirEdicao}
                                                                            component={NumberInputField}
                                                                            permitirNegativos={false}
                                                                            name="reservaProvavelTeor"
                                                                            label="Teor reserva provável"
                                                                            dica="Informar o teor da reserva provável"
                                                                            casasDecimais={3}
                                                                            casasInteiros={9}

                                                                          />
                                                                        </Col>
                                                                        <Col md={4}>
                                                                          <Field
                                                                            disabled={!this.props.permitirEdicao}
                                                                            component={NumberInputField}
                                                                            permitirNegativos={false}
                                                                            name="reservaProvavelSubstanciaContida"
                                                                            label="Substância contida reserva provável"
                                                                            dica="Informar a quantidade de substância contida na reserva provável"
                                                                            casasDecimais={3}
                                                                            casasInteiros={9}

                                                                          />
                                                                        </Col>
                                                                      </Row>
                                                                    </Container>
                                                                    <Container className="p-4" style={{ backgroundColor: '#e8e8e8' }}>
                                                                      <Row>
                                                                        <Col md={4}>
                                                                          <Field
                                                                            disabled={!this.props.permitirEdicao}
                                                                            component={NumberInputField}
                                                                            permitirNegativos={false}
                                                                            name="reservaProvada"
                                                                            label="Reserva provada"
                                                                            dica="Informar a reserva provada (segundo critério do CBRR)"
                                                                            casasDecimais={3}
                                                                            casasInteiros={9}
                                                                          />
                                                                        </Col>
                                                                        <Col md={4}>
                                                                          <Field
                                                                            disabled={!this.props.permitirEdicao}
                                                                            component={NumberInputField}
                                                                            permitirNegativos={false}
                                                                            name="reservaProvadaTeor"
                                                                            label="Teor reserva provada"
                                                                            dica="Informar o teor da reserva provada"
                                                                            casasDecimais={3}
                                                                            casasInteiros={9}

                                                                          />
                                                                        </Col>
                                                                        <Col md={4}>
                                                                          <Field
                                                                            disabled={!this.props.permitirEdicao}
                                                                            component={NumberInputField}
                                                                            permitirNegativos={false}
                                                                            name="reservaProvadaSubstanciaContida"
                                                                            label="Substância contida reserva provada"
                                                                            dica="Informar a quantidade de substância contida na reserva provada"
                                                                            casasDecimais={3}
                                                                            casasInteiros={9}

                                                                          />
                                                                        </Col>
                                                                      </Row>
                                                                    </Container>
                                                                    <Container className="p-4" style={{ backgroundColor: '#dadada' }}>
                                                                      <Row className="mt-3">
                                                                        <Col md={4}>
                                                                          <Field
                                                                            disabled={!this.props.permitirEdicao}
                                                                            component={NumberInputField}
                                                                            permitirNegativos={false}
                                                                            name="reservaProvadaProvavel"
                                                                            label="Reserva provada + provável"
                                                                            dica="Informar a reserva provada + provável (segundo critério do CBRR)"
                                                                            casasDecimais={3}
                                                                            casasInteiros={9}
                                                                          />
                                                                        </Col>
                                                                        <Col md={4}>
                                                                          <Field
                                                                            disabled={!this.props.permitirEdicao}
                                                                            component={NumberInputField}
                                                                            permitirNegativos={false}
                                                                            name="reservaProvadaProvavelTeor"
                                                                            label="Teor reserva provada + provável"
                                                                            dica="Informar o teor da reserva provada + provável"
                                                                            casasDecimais={3}
                                                                            casasInteiros={9}

                                                                          />
                                                                        </Col>
                                                                        <Col md={4}>
                                                                          <Field
                                                                            disabled={!this.props.permitirEdicao}
                                                                            component={NumberInputField}
                                                                            permitirNegativos={false}
                                                                            name="reservaProvadaProvavelSubstanciaContida"
                                                                            label="Substância contida reserva provada + provável"
                                                                            dica="Informar a quantidade de substância contida na reserva provada + provável"
                                                                            casasDecimais={3}
                                                                            casasInteiros={9}

                                                                          />
                                                                        </Col>
                                                                      </Row>
                                                                    </Container>
                                                                    <Container className="p-4 mb-3" style={{ backgroundColor: '#e8e8e8' }}>
                                                                      <Row className="mt-3 mb-3">
                                                                        <Col md={4}>
                                                                          <Field
                                                                            component={NumberInputField}
                                                                            permitirNegativos={false}
                                                                            name="totalReserva"
                                                                            label="Reserva total não classificado"
                                                                            dica="Informar reserva total (quando dados não foram reportados individualmente)"
                                                                            casasDecimais={3}
                                                                            casasInteiros={9}
                                                                          />
                                                                        </Col>
                                                                        <Col md={4}>
                                                                          <Field
                                                                            component={NumberInputField}
                                                                            permitirNegativos={false}
                                                                            name="totalReservaTeor"
                                                                            label="Teor reserva total não classificado"
                                                                            dica="Informar Teor de reserva total não classificada"
                                                                            casasDecimais={3}
                                                                            casasInteiros={9}
                                                                          />
                                                                        </Col>
                                                                        <Col md={4}>
                                                                          <Field
                                                                            component={NumberInputField}
                                                                            permitirNegativos={false}
                                                                            name="totalReservaSubstanciaContida"
                                                                            label="Subs. cont. Res. total não classificado"
                                                                            dica="Informar a reserva total de Substância contida total não classificada"
                                                                            casasDecimais={3}
                                                                            casasInteiros={9}
                                                                          />
                                                                        </Col>
                                                                      </Row>
                                                                    </Container>
                                                                  </Container>
                                                                }}
                                                              />
                                                            }}</Field>
                                                          <Row>
                                                            <Col md={6} id="legendaReserva">
                                                              <FormSpy subscription={{ values: true }}>
                                                                {({ values }) => {

                                                                  let exibir = false;

                                                                  if (values && values.reserva) {
                                                                    if (resolverExibidos(values.reserva).filter(i => i != null).length)
                                                                      exibir = true;
                                                                  }


                                                                  return exibir ? <Container className="p-2 mt-3 pr-4 pl-0" style={{ backgroundColor: '#e8e8e8', borderRadius: '5px' }}>
                                                                    <Row className="d-flex justify-content-center align-items-center">
                                                                      <Col md={1} className="p-0 d-flex justify-content-end">
                                                                        <FontAwesomeIcon size={70} color="blue" icon={faSquare} />
                                                                      </Col>
                                                                      <Col md={3} className="p-0 d-flex justify-content-start"><p className="m-0">Reserva</p></Col>
                                                                      <Col md={1} className="p-0 d-flex justify-content-end">
                                                                        <FontAwesomeIcon color="red" icon={faSquare} />
                                                                      </Col>
                                                                      <Col md={3} className="p-0 d-flex justify-content-start"><p className="m-0">Teor</p></Col>
                                                                      <Col md={1} className="p-0 d-flex justify-content-end">
                                                                        <FontAwesomeIcon size="100%" color="green" icon={faSquare} />
                                                                      </Col>
                                                                      <Col md={3} className="p-0 d-flex justify-content-start"><p className="m-0">Substância contida</p></Col>
                                                                    </Row>
                                                                  </Container> : <></>
                                                                }}
                                                              </FormSpy>
                                                            </Col>
                                                          </Row>
                                                        </Card.Body>
                                                      </Card>

                                                      :
                                                      <Row>
                                                        <Col md={12}>
                                                          <Field
                                                            disabled={!this.props.permitirEdicao}
                                                            component={TextAreaField}
                                                            name="descricaoReserva"
                                                            label="Descrição"
                                                            dica="Descreva de forma clara e sucinta as informações dos recursos existentes, se for o caso"
                                                            maxLength={255}
                                                          />
                                                        </Col>
                                                      </Row>
                                                  }
                                                </Field>

                                              </Col>
                                            </Row>
                                          </Col>
                                        </Row>
                                      </Card.Body>
                                    </Card>
                                    <Row className="mt-4 mb-4">
                                      <Col md={6}>
                                        <Field
                                          disabled={!this.props.permitirEdicao}
                                          name="idMetodoClassificacao"
                                          component={DropDownListField}
                                          label="Método de classificação do cálculo"
                                          dica="Informe o método de classificação utilizado no cálculo de recursos e/ou reservas"
                                          elementos={this.state.metodoClassificacaoCalculo}
                                        />
                                      </Col>
                                      <Col md={6}>
                                      <Field
                                        component={RadioField}
                                        disabled={!this.props.permitirEdicao}
                                        name="data"
                                        campo="data"
                                        colunas={2}
                                        max={this.props.dataCriacao}
                                        label="Data da publicação da informação"
                                        tipoCampo={DateTimeInputField}
                                        dica="Informe a data da publicação da informação, se aplicável"
                                      />
                                      </Col>
                                    </Row>
                                  </>
                                }}
                              />
                            }}
                          </Field>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                  <Row className="mt-4">
                    <Col md={12}>
                      <FonteReferencia
                        permitirEdicao={this.props.permitirEdicao}
                        formPai={formProps}
                        mutators={{ setValue }}
                        origem={'Deposito'}
                        modoDefinitivo={this.props.modoDefinitivo}
                      />
                    </Col>
                  </Row>
                </>
              }}
            />
          );
        }}
      </Field>
    );
  }

}


export default Deposito;
