import { PrimaryButton } from "../../../../../../components/Jqx/Button";
import PropTypes from "prop-types";

function BotaoReavaliacao({ aoReavaliar }) {
    return <PrimaryButton
        className="float-right m-1 ml-2 botao-reavaliar"
        onClick={() => aoReavaliar()}
        titulo="Reavaliar"
    />
}

BotaoReavaliacao.propTypes = {
    aoReavaliar: PropTypes.func,
}

export { BotaoReavaliacao };