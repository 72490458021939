import { useCallback } from "react";
import Service from "../../../service/Service";

export function useCarregarVisitasEstacao({ idEstacao, setVisitas }) {

    const carregarVisitasEstacao = useCallback(async () => {
        return Service(`/visitas/${idEstacao}`)
        .query()
        .then(({ data: { dados: visitasEstacao } }) => {
            setVisitas(visitasEstacao);
        })
        .catch((err) =>
            console.error(
                "Erro ao buscar visitas da estacao:",
                err
            )
        );
    }, [idEstacao, setVisitas]);

    return carregarVisitasEstacao;
}