import React, { useCallback } from "react";
import { TextInput } from "../../Jqx/TextInput";

const TextInputNumeroLote = ({ input, values, meta, onChange, onBlur, ...props }) => {
  const onChangeCallback = useCallback((evt) => {
    input.onChange(evt)
    onChange?.(evt)
  }, [input, onChange])

  const mensagemErro = meta.touched && !meta.valid && meta.error;  
  
  let valorInput =  values === "" ? values : (values||input.value ); 

  return (
    <>
      <TextInput
        {...props}
        // TODO: mudar pra onBlur
        onChange={onChangeCallback}
        valor={valorInput}
        mensagemErro={mensagemErro}
        onBlur={onBlur}
      />
    </>
  )
}

TextInputNumeroLote.propTypes = {
  ...TextInput.propTypes,
}

TextInputNumeroLote.defaultProps = {
  ...TextInput.defaultProps,
}

export default TextInputNumeroLote
