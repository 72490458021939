import { resolverExibidos } from "../components/SubForm/SubForm";

/**
 * Busca em um Subform os valores atualmente utilizados em determinado campo em pelo menos 
 * algum dos registros atuais (ignora excluídos)
 * @param {object} elementos - Objeto elementos do Subform (que contém novos, editadosPorID, etc)
 * @param {function} acessar - Função que recebe um elemento e retorna o valor do campo 
 * (number ou string ou array de number ou string apenas)
 * @param {bollean} valorEhArray - Opcional. Se o valor nesse campo é um array (ex: select múltiplo)
 */
const buscarUtilizados = (elementos, acessar, valorEhArray = false) => {
  if (!elementos) return [];
  const valoresUtilizados = (resolverExibidos(elementos) || [])
    .map(acessar) // Para cada elemento exibido, 'acessar' a propriedade desejada
  return valorEhArray ? valoresUtilizados.flat(1) : valoresUtilizados;
};


export { buscarUtilizados }