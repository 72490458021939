import React, { useCallback } from "react";
import PropTypes from "prop-types";
import Form from "react-bootstrap/Form";
import Hint from "../../hint/Hint";
import RadioButton from "../RadioButton/RadioButton";
import Erro from "../../Erro";

const WrappedRadioButton = ({ onClick, valor, ...props }) => {
  const onChecked = useCallback((evt) => {
    // This is needed because, for some reason, JqxRadioButton doesn't send the button value on events.
    onClick(valor)
  }, [onClick, valor])

  return (
    <RadioButton
      onClick={onChecked}
      valor={valor}
      {...props}
    />
  )
}

const HorizontalWrapper = ({ children }) => {
  return (
    <div className="d-flex" style={{ gap: "10px" }}>
      {children}
    </div>
  )
}

const RadioButtonSet = ({ nome, elementos = [], valor, label, dica, onClick, mensagemErro, required, extratorTitulo,
  extratorId, horizontal, ...props }) => {
  const elementosMapeados = elementos.map((el, idx) =>
    <WrappedRadioButton
      key={idx}
      groupName={nome}
      valor={el}
      checked={!!valor && extratorId(valor) === extratorId(el)}
      onClick={onClick}
      titulo={extratorTitulo(el)}
      {...props}
      autoFocus={idx === 0 && props?.autoFocus ? true : false}
    />
  )

  return (
    <>
      {label && <Form.Label className="small">
        {label}
        {required && <span style={{ color: "red" }}>*</span>}
        {dica && <Hint textoAjuda={dica} />}
      </Form.Label>}

      {horizontal ? <HorizontalWrapper>{elementosMapeados}</HorizontalWrapper> : elementosMapeados}

      <Erro mensagem={mensagemErro} />
    </>
  )
}

RadioButtonSet.propTypes = {
  valor: PropTypes.any,
  nome: PropTypes.string,
  label: PropTypes.string,
  dica: PropTypes.string,
  mensagemErro: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onClick: PropTypes.func,
  required: PropTypes.bool,
  horizontal: PropTypes.bool,

  elementos: PropTypes.array.isRequired,
  extratorTitulo: PropTypes.func.isRequired,
  extratorId: PropTypes.func.isRequired,
}

RadioButtonSet.defaultProps = {
  extratorTitulo: el => el.nome,
  extratorId: el => el.id,
  horizontal: false,
  elementos: [],
}

export default RadioButtonSet;
