import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Field, FormSpy } from "react-final-form";
import Card from "../../../components/card";
import { DropdownListField } from "../../../components/field/DropdownListField";
import { campoObrigatorioComMsgGenerica } from "../../../util/validadores";
import { TextAreaField } from "../../../components/field/TextAreaField";
import FonteReferencia from "./FonteReferencia";
import { setValue } from "../../../util/mutadores";
import Hint from "../../../components/hint/Hint";
import { IGNEA, METAMORFICA, SEDIMENTAR } from "../utils/contantes/constantesPetrografia";
import ClassificacaoInterpretacaoSedmentarMicroestruturas from "./ClassificacaoInterpretacaoSedmentarMicroestruturas";
import { forcarLimparComboBox, forcarValorCampo } from "../utils/gerais";
import { IGNEAS_METAMORFICAS } from "../utils/contantes/constantesComuns";
import { resolverExibidos } from "../../../components/SubForm/SubForm";

const ClassificacaoInterpretacaoPetrografica = ({
    formProps,
    permitirEdicao,
    bbClasseRocha,
    bbSubclasseRocha,
    bbPrefixo,
    bbRocha,
    bbComplemento,
    bbTiposMetamorfismo,
    bbFaciesMetamorfismo,
    bbMicroestruturas,
    bbMaturidade,
    bbMinerais = [],
    rochaAfloramento,
    valoresIniciais,
    prefixoNome,
    isIgneaMetamorfica,
    disabled,
    amostraRelacionadaComAfloramento
}) => {

    return (
        <>
            <div style={{ marginTop: '2.5rem' }}>
                <Card className="mt-3">
                    <Card.Body>
                        <Card.Title>
                            Classificação e interpretação petrográfica
                            {<span style={{ color: "red" }}>*</span>}
                        </Card.Title>
                        <Field name={'classificacaoInterpretacaoPetrografica'} subscription={{ value: true }}>
                            {({ input: { value: classificacaoInterpretacaoPetrografica = [] } }) => {

                                const ficha = formProps.form.getState().values.fichaPetrografica;
                                let bbClasseRochaFiltrada = [];
                                if (ficha === IGNEAS_METAMORFICAS.id) {
                                    bbClasseRochaFiltrada = bbClasseRocha.filter(item =>
                                        item.id === METAMORFICA || item.id === IGNEA
                                    );

                                } else {
                                    bbClasseRochaFiltrada = bbClasseRocha.filter(item =>
                                        item.id === SEDIMENTAR
                                    );
                                }

                                return (
                                    <>
                                        <Row className="mb-4">
                                            <Col md={6}>
                                                <Row>
                                                    <Col md={12}>
                                                        <Field
                                                            component={DropdownListField}
                                                            name={`${prefixoNome}.idClasseRocha`}
                                                            label="Classe da rocha"
                                                            dica="Selecione a classe da rocha presente na amostra."
                                                            elementos={bbClasseRochaFiltrada}
                                                            required
                                                            validate={campoObrigatorioComMsgGenerica("Classe da rocha")}
                                                            disabled={disabled}
                                                            onChange={() => {
                                                                forcarLimparComboBox('idSubClasseRocha');
                                                                formProps.form.mutators.setValue(`${prefixoNome}.idSubClasseRocha`, null);
                                                                // Limpa campo Rocha, prefixo e complemento quando Classe da rocha é alterado
                                                                formProps.form.mutators.setValue(`${prefixoNome}.idPrefixo`, null);
                                                                formProps.form.mutators.setValue(`${prefixoNome}.idRocha`, null);
                                                                formProps.form.mutators.setValue(`${prefixoNome}.idComplemento`, null);
                                                            }}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={12}>
                                                        <Field name={`${prefixoNome}.idSubClasseRocha`} subscription={{ value: true }}>
                                                            {({ input: { value: idSubClasseRocha } }) => {

                                                                let idTipoRocha = formProps.form.getState().values?.[prefixoNome]?.idClasseRocha;
                                                                let filtro1 = bbRocha.filter(r => r.idTipoRocha === idTipoRocha && r.rochaAtual === 'S');
                                                                let filtro2 = [...new Set(filtro1.map(i => i.idSubclasseRocha))];

                                                                let listaFiltrada = bbSubclasseRocha.filter((subClasse) => filtro2.find(i => i === subClasse.id));

                                                                if (idSubClasseRocha === "" || idSubClasseRocha === undefined || idSubClasseRocha === null) {
                                                                    forcarLimparComboBox(`idRocha`);
                                                                } else {
                                                                    let subClasse = listaFiltrada.find(r => r.id === idSubClasseRocha);
                                                                    if (!subClasse) {
                                                                        formProps.form.mutators.setValue(`${prefixoNome}.idSubClasseRocha`, null);
                                                                        forcarLimparComboBox(`idSubClasseRocha`);
                                                                    }
                                                                    else {
                                                                        forcarValorCampo(`idSubClasseRocha`, subClasse.nome);
                                                                    }
                                                                }

                                                                return (
                                                                    <Field
                                                                        component={DropdownListField}
                                                                        name={`${prefixoNome}.idSubClasseRocha`}
                                                                        label="Subclasse da rocha"
                                                                        dica="Selecione a subclasse da rocha presente na amostra."
                                                                        elementos={listaFiltrada}
                                                                        customClass={'idSubClasseRocha'}
                                                                        required
                                                                        validate={campoObrigatorioComMsgGenerica("Subclasse da rocha")}
                                                                        disabled={disabled}
                                                                        onChange={() => {
                                                                            // Limpa campo Rocha, prefixo e complemento quando Subclasseda rocha é alterado
                                                                            formProps.form.mutators.setValue(`${prefixoNome}.idPrefixo`, null);
                                                                            formProps.form.mutators.setValue(`${prefixoNome}.idRocha`, null);
                                                                            formProps.form.mutators.setValue(`${prefixoNome}.idComplemento`, null);
                                                                        }}
                                                                    />
                                                                )
                                                            }}
                                                        </Field>
                                                    </Col>
                                                </Row>

                                            </Col>

                                            <Col md={6}>
                                                <Card className="mt-4">
                                                    <Card.Body>
                                                        <Card.Subtitle>
                                                            Classificação de campo
                                                            <Hint textoAjuda={'Classificação da rocha segundo Descrição do Afloramento'} />
                                                        </Card.Subtitle>

                                                        <div style={{ color: 'red', fontSize: 12, textAlign: 'center' }}>
                                                            {rochaAfloramento?.nomeRocha}
                                                        </div>

                                                        {!amostraRelacionadaComAfloramento &&
                                                            <div style={{ color: 'blue', fontSize: 12, textAlign: 'left' }}>
                                                                A amostra não possui associação com uma atividade de afloramento.
                                                            </div>
                                                        }
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        </Row>


                                        <Card>
                                            <Card.Body>
                                                <Card.Subtitle>Nomeclatura de rocha</Card.Subtitle>

                                                <Row className="mt-4">
                                                    <Col md={4}>
                                                        <Field name={`${prefixoNome}.idPrefixo`} subscription={{ value: true }}>
                                                            {({ input: { value: idPrefixo } }) => {

                                                                if (idPrefixo === "" || idPrefixo === undefined || idPrefixo === null) {
                                                                    forcarValorCampo('idPrefixo', "Selecione...")
                                                                } else {
                                                                    let prefixo = bbPrefixo.find(item => item.id === idPrefixo);
                                                                    forcarValorCampo('idPrefixo', prefixo?.nome)
                                                                }
                                                                return (
                                                                    <Field
                                                                        component={DropdownListField}
                                                                        name={`${prefixoNome}.idPrefixo`}
                                                                        label="Prefixo"
                                                                        dica="Se for o caso, defina o prefixo de nomenclatura de sua rocha de acordo com o tipo."
                                                                        customClass={'idPrefixo'}
                                                                        elementos={bbPrefixo}
                                                                        disabled={disabled}
                                                                    />
                                                                )
                                                            }}
                                                        </Field>
                                                    </Col>

                                                    <Col md={4}>
                                                        <Field name={`${prefixoNome}.idRocha`} subscription={{ value: true }}>
                                                            {({ input: { value: idRocha } }) => {

                                                                let idClaseRocha = formProps.form.getState().values?.[prefixoNome]?.idClasseRocha;
                                                                let idSubClasseRocha = formProps.form.getState().values?.[prefixoNome]?.idSubClasseRocha;

                                                                let bbRochaFiltrada = [];

                                                                if (idClaseRocha && idSubClasseRocha) {
                                                                    bbRochaFiltrada = bbRocha.filter(rocha => rocha.idTipoRocha === idClaseRocha && rocha.idSubclasseRocha === idSubClasseRocha);
                                                                }

                                                                let tempArray = [];
                                                                bbRochaFiltrada?.forEach(item => {
                                                                    if (item?.rochaAtual === 'S' || item.id.toString() === idRocha.toString()) {
                                                                    tempArray.push(item);
                                                                    }
                                                                })
                                                                if (idRocha === "" || idRocha === undefined || idRocha === null) {
                                                                    forcarValorCampo('idRocha', "Selecione...")
                                                                } else {
                                                                    let rocha = bbRocha.find(item => item.id === idRocha);
                                                                    forcarValorCampo('idRocha', rocha?.nome)
                                                                }

                                                                return (
                                                                    <Field
                                                                        component={DropdownListField}
                                                                        name={`${prefixoNome}.idRocha`}
                                                                        label="Rocha"
                                                                        dica="Selecione o nome simples da rocha."
                                                                        elementos={tempArray}
                                                                        customClass={'idRocha'}
                                                                        required
                                                                        validate={campoObrigatorioComMsgGenerica("Rocha")}
                                                                        disabled={disabled}
                                                                    />
                                                                )
                                                            }}
                                                        </Field>

                                                    </Col>
                                                    <Col md={4}>
                                                        <Field name={`${prefixoNome}.idComplemento`} subscription={{ value: true }}>
                                                            {({ input: { value: idComplemento } }) => {

                                                                if (idComplemento === "" || idComplemento === undefined || idComplemento === null) {
                                                                    forcarValorCampo('idComplemento', "Selecione...")
                                                                } else {
                                                                    let complemento = bbComplemento.find(item => item.id === idComplemento);
                                                                    forcarValorCampo('idComplemento', complemento?.nome)
                                                                }
                                                                return (
                                                                    <Field
                                                                        component={DropdownListField}
                                                                        name={`${prefixoNome}.idComplemento`}
                                                                        label="Complemento"
                                                                        dica="Se for o caso, defina o complemento de nomenclatura de sua rocha de acordo com o tipo."
                                                                        customClass={'idComplemento'}
                                                                        elementos={bbComplemento}
                                                                        disabled={disabled}
                                                                    />
                                                                )
                                                            }}
                                                        </Field>
                                                    </Col>
                                                </Row>
                                                {isIgneaMetamorfica && (
                                                    <Row className="my-2">
                                                        <FormSpy subscription={{ values: true }}>
                                                            {({ values }) => {
                                                                const idClasseRocha = values?.[prefixoNome]?.idClasseRocha
                                                                let calculaDisabled = disabled
                                                                if (!calculaDisabled) {
                                                                    if (idClasseRocha !== METAMORFICA) {
                                                                        calculaDisabled = true
                                                                        forcarLimparComboBox(`idProtolito`);
                                                                    }
                                                                }
                                                                return (
                                                                    <Col md={4}>
                                                                        <Field
                                                                            component={DropdownListField}
                                                                            name={`${prefixoNome}.idProtolito`}
                                                                            label="Protólito"
                                                                            dica="Se for o caso, defina o nome simples da rocha que corresponde ao protólito interpretado."
                                                                            elementos={bbRocha}
                                                                            disabled={calculaDisabled}
                                                                            customClass={'idProtolito'}
                                                                        />
                                                                    </Col>
                                                                )
                                                            }}
                                                        </FormSpy>
                                                    </Row>
                                                )}
                                                <Row className="my-2">
                                                    <FormSpy subscription={{ values: true }}>
                                                        {({ values }) => {

                                                            const composicoesMineralogicas = values?.composicoesMineralogicas;
                                                            let composicoesMineralogicasTela = [];
                                                            if (composicoesMineralogicas) {
                                                                composicoesMineralogicasTela = resolverExibidos(composicoesMineralogicas);
                                                            }
                                                            const idsMinerais = [];

                                                            composicoesMineralogicasTela.forEach(composicao => {
                                                                if (composicao.compoeNomeRocha === 'S') {
                                                                    idsMinerais.push(composicao.idMineral);
                                                                }
                                                            });
                                                            const nomesMinerais = bbMinerais
                                                                .filter(mineral => idsMinerais.includes(mineral.id))
                                                                .map(mineral => mineral.nome);

                                                            const nomesMineraisString = nomesMinerais.join(' ');

                                                            const prefixo = bbPrefixo.find(v => v.id === values?.[prefixoNome]?.idPrefixo)
                                                            const rocha = bbRocha.find(v => v.id === values?.[prefixoNome]?.idRocha)
                                                            const complemento = bbComplemento.find(v => v.id === values?.[prefixoNome]?.idComplemento)

                                                            const nomesMineraisParte = nomesMineraisString ? nomesMineraisString + " " : "";
                                                            const prefixoParte = prefixo?.nome ? prefixo.nome + " " : "";
                                                            const rochaParte = rocha?.nome ? rocha.nome + " " : "";
                                                            const complementoParte = complemento?.nome ? complemento.nome + " " : "";

                                                            const valor = nomesMineraisParte + prefixoParte + rochaParte + complementoParte;

                                                            return (
                                                                <Col md={4}>
                                                                    <div style={{ color: 'blue', fontSize: 12, }}>
                                                                        {valor}
                                                                    </div>
                                                                </Col>
                                                            )
                                                        }}
                                                    </FormSpy>
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                        {isIgneaMetamorfica && (
                                            <Row className="my-2">
                                                <Col md={4}>
                                                    <Field
                                                        component={DropdownListField}
                                                        name={`${prefixoNome}.idTipoMetamorfismo`}
                                                        label="Tipo de Metamorfismo"
                                                        dica="Selecione o tipo de metamorfismo observado na amostra."
                                                        elementos={bbTiposMetamorfismo}
                                                        disabled={disabled}
                                                    />
                                                </Col>
                                                <Col md={4}>
                                                    <Field
                                                        component={DropdownListField}
                                                        name={`${prefixoNome}.idFacies`}
                                                        label="Fácies Metamórficas"
                                                        dica="Selecione o Fácies metamórfico observado na amostra."
                                                        elementos={bbFaciesMetamorfismo}
                                                        disabled={disabled}
                                                    />
                                                </Col>
                                            </Row>
                                        )}

                                        {!isIgneaMetamorfica && (
                                            <>
                                                <Row className="my-2">
                                                    <Col md={4}>
                                                        <Field
                                                            component={DropdownListField}
                                                            name={`${prefixoNome}.idMaturidadeTextural`}
                                                            label="Maturidade Textural"
                                                            dica="Selecione a maturidade textural."
                                                            elementos={bbMaturidade}
                                                            displayMember={"desMaturidade"}
                                                            disabled={disabled}
                                                        />
                                                    </Col>
                                                    <Col md={4}>
                                                        <Field
                                                            component={DropdownListField}
                                                            name={`${prefixoNome}.idMaturidadeComposicional`}
                                                            label="Maturidade Composicional"
                                                            dica="Selecione a maturidade composicional ."
                                                            elementos={bbMaturidade}
                                                            displayMember={"desMaturidade"}
                                                            disabled={disabled}
                                                        />
                                                    </Col>
                                                </Row>
                                                <ClassificacaoInterpretacaoSedmentarMicroestruturas
                                                    form={formProps.form}
                                                    permitirEdicao={permitirEdicao}
                                                    bbMicroestruturas={bbMicroestruturas}
                                                    prefixoNome={prefixoNome}
                                                    disabled={disabled}
                                                />
                                            </>
                                        )}

                                        <Row className="my-2">
                                            <Col md="12">
                                                <Field
                                                    component={TextAreaField}
                                                    name={`${prefixoNome}.descricaoDetalhadaComplementos`}
                                                    label="Descrição Detalhada/Complementos"
                                                    dica="Descreva a lâmina integrando os termos preenchidos durante a análise petrográfica. Inclua outras informações que considerar relevantes."
                                                    maxLength={2000}
                                                    required
                                                    validate={campoObrigatorioComMsgGenerica("Descrição Detalhada/Complementos")}
                                                    disabled={disabled}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="my-2">
                                            <Col md="12">
                                                <Field
                                                    component={TextAreaField}
                                                    name={`${prefixoNome}.interpretacao`}
                                                    label="Interpretação"
                                                    dica="Se for o caso execute a interpretação com relação à gênese da rocha ou de alguma feição estrutural, assembleia mineral, etc."
                                                    maxLength={2000}
                                                    disabled={disabled}
                                                />
                                            </Col>
                                        </Row>
                                        <FonteReferencia
                                            permitirEdicao={!disabled}
                                            formPai={formProps}
                                            mutators={{ setValue }}
                                            origem={'ClassificacaoInterpretacaoPetrografica'}
                                            modoDefinitivo={true}
                                        />
                                    </>
                                )
                            }}
                        </Field>
                    </Card.Body >
                </Card>
            </div>
        </>
    );
};

export default ClassificacaoInterpretacaoPetrografica;