import { PureComponent } from "react";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import geoquimicaService from "../../../../../service/GeoquimicaService";
import { Field } from "react-final-form";
import { DropdownListField } from "../../../../../components/field/DropdownListField";
import { ComboItemModelLike } from "../../../../../models/combo-item.model";
import { campoObrigatorioComMsgGenerica, comporValidadores } from "../../../../../util/validadores";

export interface ModoDepositoFieldProps {
    prefixoNome?: string;
    name?: string;
    required?: boolean;
}

export interface ModoDepositoFieldState {
    modoDepositoOptions: ComboItemModelLike[];
}

export class ModoDepositoField extends PureComponent<ModoDepositoFieldProps, ModoDepositoFieldState> {
    label = 'Modo deposito';
    validateField = comporValidadores(campoObrigatorioComMsgGenerica(this.label));

    private lifecycle = new Subject();

    constructor(props) {
        super(props);
        this.state = {
            modoDepositoOptions: [],
        };
    }

    componentDidMount() {
        geoquimicaService.listModoDepositoOptions().pipe(takeUntil(this.lifecycle)).subscribe(data => {
          this.setState({modoDepositoOptions: data});
        });
    }

    componentWillUnmount() {
        this.lifecycle.next();
        this.lifecycle.complete();
    }

    render() {
        const required = !!this.props.required;
        return (
            <>
                <Field
                    component={DropdownListField}
                    name={`${this.props.prefixoNome ?? ''}${this.props.name ?? 'id_modo_deposito'}`}
                    label={this.label}
                    required={required}
                    validate={required ? this.validateField : undefined}
                    elementos={this.state.modoDepositoOptions}
                />
            </>
        );
    }
}
