import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import swal from "sweetalert";
import PropTypes from "prop-types";
import { PrimaryButton } from "../Jqx/Button";
import { createControlColumn, LegacyTable } from "../Jqx/Table";
import { Field, Form, useForm } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { jqx } from "jqwidgets-scripts/jqwidgets-react-tsx/jqxgrid";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import converterVetorParaObjetoIndexado from "../../util/converterVetorParaObjetoIndexado";
import { camposForamEditados } from "../../util/formFoiEditado"
import isNumber from "lodash/isNumber";
import { setValue } from "../../util/mutadores";
import { flattenObject } from "../../util/flattenObject";
import { FORM_ERROR } from "final-form";
import { HiddenField } from "../field/HiddenField";
import NotificadorErrosForm from "../NotificadorErrosForm";
import { uniqueId } from "lodash";
import { scrollAoErro, swalConfirmarExclusao } from "../../util/validadores";
import Card from "../card";
import { TIPO_ATIVIDADE_GEOCRONOLOGIA, TIPO_ATIVIDADE_PETROGRAFIA } from "../../util/constantes";

/**
 * ----------------------------------------------------------------------------------------
 * Versão original do SubForm antes dele ser refatorado com o Table novo. Este SubForm usa
 * o LegacyTable. Ele é mais devagar do que o SubForm novo porque recria o source e as
 * colunas com mais frequência.
 * Nosso objetivo é substituir todas as instâncias deste LegacySubForm pelo SubForm novo.
 * Porém, atualmente o SubForm novo quebra em alguns lugares, então até que ele esteja
 * funcionando completamente, os componentes onde o SubForm novo não funcionam devem usar
 * este LegacySubForm.
 * 
 * Os componentes dentro do formulário do Recmin foram os primeiros a serem refatorados, e
 * eles já usam o SubForm novo. Se alterações no SubForm forem necessárias, pode ser
 * necessário replicar as alterações aqui, mas espero que este LegacySubform não precise
 * existir por muito tempo.
 * ----------------------------------------------------------------------------------------
 */

const DELAY_EXIBICAO_TABELA_PADRAO = 1500;

const IDX_NO_VETOR_ORIGINAL = Symbol("IDX_NO_VETOR_ORIGINAL");

let ULTIMO_ID_TEMPORARIO = -1;

const COLUNA_EXCLUIR = "idExibir";
const COLUNA_PODE_EXCLUIR = "podeExcluir";
const COLUNA_PODE_EDITAR = "podeEditar";
const COLUNA_EDITAR = "idEditar";
const COLUNA_VISUALIZAR = "idVisualizar";
const COLUNA_MOBILE = "modoEdicao";
const COLUNA_COMPATIBILIZACAO = "permitirCompatibilizacao"

// **********************************************************************

// Assume que há dois estados possíveis: editando ou visualizando. Não sendo possível ter as duas opções ao mesmo tempo
export const getPropsPermissao = permitirEdicao => ({
  permitirInsercao: permitirEdicao,
  exibirBotaoInsercao: permitirEdicao,
  permitirEdicao: permitirEdicao,
  permitirExclusao: permitirEdicao,
  permitirVisualizacao: !permitirEdicao,
});

const tratarNomeClasse = (nome) => {
  let tempNome = ""
  if (nome.includes('.')) {
    tempNome = nome.replace(/\./g, '-');
    return tempNome;
  } else {
    return nome;
  }
};

const podeExcluirEntidadePadrao = (entidade) => {
  if (!entidade) return true;
  if (entidade?.id <= 0) return true;
  if (undefined === entidade[COLUNA_PODE_EXCLUIR]) return true;
  return true === entidade[COLUNA_PODE_EXCLUIR];
};

const podeEditarEntidadePadrao = (entidade) => {
  if (entidade && entidade.modoEdicao === 'MOBILE') return false;
  if (!entidade) return true;
  if (entidade?.id <= 0) return true;
  if (undefined === entidade[COLUNA_PODE_EDITAR]) return true;
  return true === entidade[COLUNA_PODE_EDITAR];
};

const podeVisualizarEntidadePadrao = (entidade) => true; // Se habilitado, por padrão a visualização é sempre permitida

const podeAlterarModoEdicaoPadrao = (entidade) => {
  if (entidade && entidade.modoEdicao === 'MOBILE') return true;
};

// **** utils
export const SubFormPrefixContext = React.createContext("");

export const resolverExibidos = ({
  existentes = [],
  editadosPorId = {},
  idsExcluidos = [],
  novos = [],
}) => {
  let exibidos = [...existentes]; // comecamos com todos os elementos

  // Necessário pois quando um valor é excluido do form é setado seu valor como nulo na lista
  // Para verificar se pode tirar o trecho do código que define o valor nulo é necessário retestar o sistema
  // como um todo uma vez que não se sabe o real motivo de setar nulo em vez de simplesmente remover da lista
  let novosNaoNulos = novos.filter(n=>n);
  exibidos = exibidos
    .filter((el) => !idsExcluidos.includes(Number(el?.id))) // tira os excluidos
    .concat(novosNaoNulos) // inclui os novos
    .filter((el) => null !== el);

  for (const [id, val] of Object.entries(editadosPorId)) {
    let idComoNumero = id?.replace("id_", "");
    // eslint-disable-next-line
    let idxNoVetorOriginal = exibidos.findIndex((el) => el?.id == idComoNumero);
    if (idxNoVetorOriginal > -1) {
      exibidos[idxNoVetorOriginal] = val;
    }
  }
  return exibidos;
};

const normalizarObjetos = (values = []) => {

  let novosObj = []
  let keys = []

  if (values.length > 1) {
    values.map((novo = null) => {
      if (novo) {
        const objKeys = Object.keys(novo)
        if (objKeys.length > keys.length) keys = objKeys
      }
      return novo;
    })

    novosObj = values.map((novos = null) => {
      keys.forEach(({ key }) => {
        if (novos && !novos[key]) novos[key] = null;
      })
      return novos;
    })

    novosObj = novosObj.sort((a, b) => b?.id - a?.id);
    return novosObj;
  }
  return values;
}

const fecharForm = ({
  setFormOpen,
  onClose,
  setEstadoEdicao,
  scrollAoFechar,
  tableRef,
}) => {
  setFormOpen(false);
  setEstadoEdicao({
    estaEditando: false,
  });
  onClose && onClose();

  // Scroll na página para centralizar SubForm recém fechado
  if (scrollAoFechar) {
    const selector = tableRef?.current?._componentSelector;
    // ?.parentElement?.parentElement busca o Card que geralmente envolve um SubForm
    selector &&
      document
        .querySelector(selector)
        ?.parentElement?.parentElement?.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
  }
};

const abrirForm = ({ setFormOpen, onOpen }) => {
  setFormOpen(true);
  onOpen && onOpen();
};

// **********************************************************************
// Componentes



export const BotoesPadrao = ({ voltar, disabled, nome, uid, mostrarBotaoConfirmar, labelBotaoConfirmar, nomeClasse }) => {
  nomeClasse = tratarNomeClasse(nome);
  const form = useForm();
  return (
    <Row className={`subform-btns-padrao subform-btns-padrao-${nomeClasse}`}>
      <Col>
        {
          mostrarBotaoConfirmar && (
            <PrimaryButton
              className={`float-right m-2 subform-btn-confirmar subform-btn-confirmar-${nomeClasse}`}
              onClick={() => {
                form.submit();
                scrollAoErro(document.getElementById(uid));
              }}
              titulo={labelBotaoConfirmar}
              disabled={disabled}
            />
          )
        }
        <PrimaryButton
          className={`float-right m-2 subform-btn-voltar subform-btn-voltar-${nomeClasse}`}
          onClick={() => voltar(form)}
          titulo="Voltar"
          disabled={disabled}
        />
      </Col>
    </Row>
  );
};

const LegacySubForm = ({
  // Props básicas
  renderForm,
  campos,
  colunas,
  nome,

  elementos = {
    existentes: [],
    novos: [],
    editadosPorId: {},
    idsExcluidos: [],
  },
  valoresIniciais,

  // Eventos
  onSubmit,
  onOpen,
  onClose,
  validar,
  onBtnEditClick,
  onBtnDeleteClick,
  onBtnViewClick,
  onBtnChangeEditModeClick,
  exibirBotaoModoEdicao,
  aoCarregarExibidos,

  // Controle de layout/funcionalidades
  permitirInsercao,
  permitirEdicao,
  permitirExclusao,
  permitirVisualizacao,
  permitirAlterarModoEdicao,
  permitirCompatibilizacao,

  podeCompatibilizar,
  compatibilizar,

  exibirBotaoInsercao,
  exibirBotaoConfirmar,
  parametrosParaTableSource,
  ordenacaoPersonalizada,
  criarDecoradores = () => [],
  podeExcluirEntidade = podeExcluirEntidadePadrao,
  podeEditarEntidade = podeEditarEntidadePadrao,
  podeVisualizarEntidade = podeVisualizarEntidadePadrao,
  podeAlterarModoEdicao = podeAlterarModoEdicaoPadrao,
  tituloForm = "",
  tituloFormMenor = false,
  exibirAsterisco = false,
  formSubscription = undefined,
  keepDirtyOnReinitialize = false,
  delayParaExibicaoTabela = DELAY_EXIBICAO_TABELA_PADRAO,
  alternarBotoesPai = true,
  validarVoltar = true,
  naoValidarCamposVoltar = [],
  scrollAoFechar,
  tabIndexAdicionar = -1,
  exibirTabelaSemRegistro,
  labelBotaoConfirmar,
  confirmarExclusao,
  tableProps,
  nomeClasse = "",
  aoConfirmarVoltar = () => { },

  // Estado
  formularioAberto,
  exibirBotoesPadrao = true,
  fecharFormAoSalvar = true,

  //Somente para atividades associadas
  isAtividadeAssociada = false
}) => {
  const uid = useRef(uniqueId('subform-')).current; // id de elemento aplicado no DOM
  const prefixoSubForm = useContext(SubFormPrefixContext);
  const [tableSource, setTableSource] = useState({});
  const [tableColumns, setTableColumns] = useState({});
  const [dataFields, setDataFields] = useState({});

  const [elementosExibidos, setElementosExibidos] = useState([]);
  const [elementosExibidosPorId, setElementosExibidosPorId] = useState({});
  const [prontoParaExibir, setProntoParaExibir] = useState(false);

  const decoradores = useMemo(() => {
    return criarDecoradores(prefixoSubForm);
  }, [prefixoSubForm, criarDecoradores]);

  const mutadores = useMemo(() => {
    return { ...arrayMutators, setValue };
  }, []);

  // **********************************************************************
  // Ações do SubForm

  const tableRef = useRef();
  const [isFormOpen, setFormOpen] = useState(formularioAberto);
  const [prefixoAtual, setPrefixoAtual] = useState("");

  // Quando o valor de formularioAberto for alterado externamente, precisamos atualizar o estado interno :)
  useEffect(() => {
    setFormOpen(formularioAberto);
    if (formularioAberto) {
      abrirForm({ setFormOpen, onOpen });
    } else {
      fecharForm({ setFormOpen, setEstadoEdicao, onClose, tableRef });
    }
  }, [formularioAberto, onOpen, onClose, setFormOpen, tableRef]);

  const [estadoEdicao, setEstadoEdicao] = useState({
    estaEditando: false,
    estaVisualizando: false,
    id: null,
    ehTemporario: false,
  });

  const persistirDados = useCallback(
    async (novosElementos, alteracoesExtras = {}, dadosAdicionais = {}) => {
      const prefixo = nome;
      const { existentes, ...resto } = novosElementos;
      const alteracoes = {
        ...flattenObject(resto, prefixo),
        ...flattenObject(alteracoesExtras, prefixo),
      };

      onSubmit &&
        await onSubmit({
          novosElementos: novosElementos,
          alteracoesForm: alteracoes,
          exibidos: resolverExibidos(novosElementos),
          prefixoAtual: prefixo,
          dadosAdicionais,
        });
      console.log('alteracoesForm', {alteracoesForm: alteracoes})
    },
    [onSubmit, nome]
  );

  useEffect(() => {
    const quantosNovos = !!elementos.novos ? elementos.novos.length : 0;
    const prefixoNovo =
      estadoEdicao.estaEditando && !estadoEdicao.ehTemporario
        ? `${nome}.editadosPorId[id_${estadoEdicao?.id}]`
        : // ? `${nome}.editadosPorId[${estadoEdicao.id}L]`
        `${nome}.novos[${quantosNovos}]`;

    const prefixoNovoContextualizado = `${prefixoSubForm.length > 0 ? prefixoSubForm + "." : ""
      }${prefixoNovo}`;

    if (prefixoNovoContextualizado !== prefixoAtual) {
      setPrefixoAtual(prefixoNovoContextualizado);
    }
  }, [
    estadoEdicao.estaEditando,
    estadoEdicao.ehTemporario,
    estadoEdicao.id,
    prefixoSubForm,
    elementos,
    nome,
    prefixoAtual,
  ]);

  const excluirId = useCallback(
    async (id) => {
      id = parseInt(id);
      // Não excluir caso cancelar exclusão no modal
      if (confirmarExclusao) {
        const exclusaoConfirmada = await swalConfirmarExclusao();
        if (!exclusaoConfirmada) return id;
      }

      if (onBtnDeleteClick) {
        const event = new TableActionEvent(id);
        onBtnDeleteClick(event, tableRef);
        if (event.preventedDefault) {
          return id;
        }
      }

      let novosElementos, alteracoesExtras, elementoExcluido;

      if (id < 0) {
        // excluindo um registro novo. é só tirar de registros :)
        const novosRegistrosSemOExcluido = elementos.novos.filter((r) => {
          return null == r || r.id !== id;
        });

        elementoExcluido = elementos.novos.find((r) => id === r?.id);

        novosElementos = {
          ...elementos,
          novos: novosRegistrosSemOExcluido,
        };

        let idxDoExcluido = -1;
        for (let i = 0; i < elementos.novos.length; i++) {
          if (null !== elementos.novos[i] && elementos.novos[i].id === id) {
            idxDoExcluido = i;
            break;
          }
        }
        if (idxDoExcluido > -1) {
          const novosRegistrosComOExcluidoNulificado = [...elementos.novos];
          // TODO porque seta nulo e não remove da lista? Se remover vai quebrar algo do sistema?
          novosRegistrosComOExcluidoNulificado[idxDoExcluido] = null;
          alteracoesExtras = {
            novos: novosRegistrosComOExcluidoNulificado,
          };
        }
      } else {
        // excluindo um de verdade
        elementoExcluido = elementos?.existentes?.find((r) => id === r.id);
        novosElementos = {
          ...(elementos ?? {}),
          idsExcluidos: [...(elementos.idsExcluidos ?? []), Number(id)],
        };
      }

      await persistirDados(novosElementos, alteracoesExtras, {
        elementoExcluido,
      })
        .catch(err => console.error('Erro durante exclusão de registro de subformulário:', err));
    },
    [elementos, persistirDados, onBtnDeleteClick, confirmarExclusao]
  );

  const editarId = useCallback(
    (id, rowdata) => {

      if (onBtnEditClick) {
        const event = new TableActionEvent(id, rowdata);
        onBtnEditClick(event, tableRef);
        if (event.preventedDefault) {
          return;
        }
      }
      setEstadoEdicao({
        estaEditando: true,
        ehTemporario: id < 0,
        id,
      });

      abrirForm({ setFormOpen, onOpen });
    },
    [onOpen, setFormOpen, onBtnEditClick]
  );

  const visualizarId = useCallback((id, rowdata) => {
    if (onBtnViewClick) {
      const event = new TableActionEvent(id, rowdata);
      onBtnViewClick(event, tableRef);
      if (event.preventedDefault) {
        return;
      }
    }

    setEstadoEdicao({
      estaEditando: true,     // Necessário ser true para carregamento do elemento. Como o botão salvar é escondido, não há problemas
      estaVisualizando: true, // Pode ser utilizado para desabilitar campos através do parâm. "visualizado" enviado ao renderForm
      ehTemporario: id < 0,
      id,
    });

    abrirForm({ setFormOpen, onOpen });
  }, [onOpen, setFormOpen, onBtnViewClick]);

  const alterarModoEdicao = async (idAtividade) => {
    const result = await swal({
      title: `Essa opção irá desabilitar alterações dessa atividade no dispositivo móvel, deseja prosseguir mesmo assim?`,
      icon: "warning",
      buttons: { cancel: "Não, cancelar", confirm: { text: "Sim, desejo prosseguir!", className: "btn-danger" }, },
    })

    if (result) {
      const event = new TableActionEvent(idAtividade);
      onBtnChangeEditModeClick(event, tableRef);
    }
  }

  const abrirFormularioEmBranco = useCallback(() => {
    abrirForm({ setFormOpen, onOpen });
    // maybe clean the form?
  }, [onOpen, setFormOpen]);

  const enviarForm = useCallback(
    async (values) => {
      let novoRegistro, dadosAdicionais = { estadoEdicao };
      if (estadoEdicao.estaEditando) {
        if (estadoEdicao.ehTemporario) {
          const novos = elementos.novos.filter(
            (e) => null === e || parseInt(e.id) !== parseInt(estadoEdicao.id)
          );

          const novosNormalizados = normalizarObjetos([values, ...novos])
          const indiceNovoEditado = novosNormalizados.findIndex(n => parseInt(n.id) === parseInt(estadoEdicao.id));
          dadosAdicionais.registroEditado = `${nome}.novos[${parseInt(indiceNovoEditado)}]`;

          novoRegistro = {
            ...elementos,
            novos: novosNormalizados,
          };
        } else {
          dadosAdicionais.registroEditado = `${nome}.editadosPorId.id_${estadoEdicao.id}`;

          novoRegistro = {
            ...elementos,
            editadosPorId: {
              ...elementos.editadosPorId,
              // https://github.com/final-form/react-final-form/blob/master/docs/faq.md#why-cant-i-have-numeric-keys-in-an-object
              // [estadoEdicao.id + "L"]: values,
              ["id_" + estadoEdicao.id]: values,
            },
          };
        }
      } else {
        novoRegistro = {
          ...elementos,
          novos: [...(elementos?.novos ?? []), values],
        };
      }

      let erroAoPersistirDados = false;
      await persistirDados(novoRegistro, undefined, dadosAdicionais)
        .catch(err => {
          console.error('Erro ao persistir dados durante envio do form:', err)
          erroAoPersistirDados = true;
        });
      if (erroAoPersistirDados) return; // Não fechar form caso ocorra um erro ao persistir dados

      // setEstadoEdicao({
      //   estaEditando: false,
      //   ehTemporario: false,
      //   id: null,
      // });

      if (fecharFormAoSalvar === false) return;

      fecharForm({
        setFormOpen,
        onClose,
        setEstadoEdicao,
        scrollAoFechar,
        tableRef,
      });
    },
    [onClose, elementos, persistirDados, estadoEdicao, scrollAoFechar, nome, fecharFormAoSalvar]
  );

  const onBtnBackClick = useCallback(async (form) => {
    if (validarVoltar) {
      const result = await camposForamEditados(form, naoValidarCamposVoltar)
      console.log('não validar', form, form.getState(), form.getState().modified, form.getState().dirtyFields)
      if (result === null) return false;
    }
    aoConfirmarVoltar(form);
    fecharForm({ setFormOpen, onClose, setEstadoEdicao, scrollAoFechar, tableRef });
  }, [validarVoltar, aoConfirmarVoltar, onClose, scrollAoFechar, naoValidarCamposVoltar]);

  // **********************************************************************
  // Configuração do grid

  const ultimaAtualizacao = useRef(Date.now());

  useEffect(() => {
    const { existentes = [] } = elementos;

    let idx = 0;
    for (const el of existentes) {
      el[IDX_NO_VETOR_ORIGINAL] = idx++;
    }

    let exibidos = resolverExibidos(elementos);
    // Filtra associações para que não fiquem visíveis as de dados analíticos 
    if (isAtividadeAssociada) {
      let associacoesFiltradas = exibidos.filter((item)=> item.tipoAtividade !== TIPO_ATIVIDADE_GEOCRONOLOGIA.id && item.tipoAtividade !== TIPO_ATIVIDADE_PETROGRAFIA.id)
      setElementosExibidos(associacoesFiltradas);
      setElementosExibidosPorId(converterVetorParaObjetoIndexado(associacoesFiltradas));
      if (aoCarregarExibidos) {
        aoCarregarExibidos(associacoesFiltradas);
      }
    } else {
      setElementosExibidos(exibidos);
      setElementosExibidosPorId(converterVetorParaObjetoIndexado(exibidos));
      if (aoCarregarExibidos) {
        aoCarregarExibidos(exibidos);
      }
    }

    // Desenhar o jQXGrid no DOM aparentemente é muito custoso, e cada rerenderização
    // piora isso. Por isso assumimos um tempo (delayParaExibicaoTabela) para deixar
    // as rerenderizações necessárias acontecerem e só depois exibimos a tabela
    ultimaAtualizacao.current = Date.now();
    let mounted = true;

    const exibirTabela = () => {
      if (!prontoParaExibir) {
        if (Date.now() - ultimaAtualizacao.current > delayParaExibicaoTabela) {
          mounted && setProntoParaExibir(true);
        } else {
          setTimeout(exibirTabela, delayParaExibicaoTabela)
        }
      }
    }
    setTimeout(exibirTabela, delayParaExibicaoTabela);

    return () => { mounted = false; }; // evita tentar atualizar hook em um componente desmontado
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elementos, aoCarregarExibidos]);

  useEffect(() => {
    const novasColunas = [...colunas];

    if(permitirCompatibilizacao && !permitirVisualizacao) {
      novasColunas.unshift(
        createControlColumn({
          iconClasses: ["fas", "fa-sync", "text-primary"],
          dataField: COLUNA_COMPATIBILIZACAO,
          ref: tableRef,
          onClick: compatibilizar,
          botaoAtivo: podeCompatibilizar,
          hintBotao: 'Validação da associação do material geológico do afloramento com a amostra'
        })
      );
    }

    if (permitirAlterarModoEdicao) {
      novasColunas.unshift(
        createControlColumn({
          iconClasses: ["fas", "fa-mobile-alt", "text-warning"],
          dataField: COLUNA_MOBILE,
          ref: tableRef,
          onClick: (id, rowdata) => {
            let idClicado = rowdata?.rowData?.id;
            alterarModoEdicao(idClicado);
          },
          botaoAtivo: podeAlterarModoEdicao,
        })
      );
    }

    if (permitirExclusao) {
      novasColunas.unshift(
        createControlColumn({
          iconClasses: ["fas", "fa-trash", "text-danger"],
          dataField: COLUNA_EXCLUIR,
          ref: tableRef,
          onClick: excluirId,
          botaoAtivo: podeExcluirEntidade,
        })
      );
    }

    if (permitirEdicao) {
      novasColunas.unshift(
        createControlColumn({
          iconClasses: ["fas", "fa-edit", "text-warning"],
          dataField: COLUNA_EDITAR,
          ref: tableRef,
          onClick: editarId,
          botaoAtivo: podeEditarEntidade,
        })
      );
    }

    if (permitirVisualizacao) {
      novasColunas.unshift(
        createControlColumn({
          iconClasses: ["fas", "fa-eye", "text-primary"],
          dataField: COLUNA_VISUALIZAR,
          ref: tableRef,
          onClick: visualizarId,
          botaoAtivo: podeVisualizarEntidade,
        })
      );
    }



    if (permitirVisualizacao && !permitirEdicao && !permitirExclusao) {
      let ultimaColuna = novasColunas[novasColunas.length - 1];
      if (ultimaColuna.width && ultimaColuna.controladorWidth === undefined) {
        ultimaColuna.controladorWidth = true;
        let tempWidth = ultimaColuna.width;
        tempWidth.replace('%', '');
        tempWidth = parseInt(tempWidth);
        ultimaColuna.width = `${tempWidth + 3}%`;
        novasColunas[novasColunas.length - 1] = ultimaColuna;
        if (ultimaColuna.text === "Estimativa Percentual") {
          console.log(ultimaColuna)
        }
      }
    }


    // let totalPorcentagem = 0;
    // let ultimaColuna = novasColunas[novasColunas.length - 1];
    // if (ultimaColuna.controladorWidth === undefined) {
    //   ultimaColuna.controladorWidth = true;
    //   let widthUltimaColuna = parseInt(ultimaColuna?.width?.replace("%", ""));
    //   for (let i = 0; i < novasColunas.length; i++) {
    //     totalPorcentagem += parseInt(novasColunas[i]?.width?.replace("%", ""));
    //   }
    //   if (!permitirEdicao) {
    //     widthUltimaColuna += 9;
    //   }
    //   if (!permitirExclusao) {
    //     widthUltimaColuna += 9;
    //   }
    //   if (permitirVisualizacao && !permitirEdicao && !permitirExclusao) {
    //     widthUltimaColuna += 9;
    //   }
    //   widthUltimaColuna = widthUltimaColuna + 100 - totalPorcentagem;
    //   ultimaColuna.width = widthUltimaColuna;
    //   novasColunas[novasColunas.length - 1].width = `${widthUltimaColuna}%`;

    // }

    setTableColumns(novasColunas);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    colunas,
    permitirEdicao,
    permitirExclusao,
    permitirVisualizacao,
    permitirAlterarModoEdicao,
    permitirCompatibilizacao,
    editarId,
    excluirId,
    visualizarId,
    podeExcluirEntidade,
    podeEditarEntidade,
    podeVisualizarEntidade,
    podeAlterarModoEdicao,
  ]);

  useEffect(() => {
    const dataFields = [...campos];

    if (permitirVisualizacao) {
      dataFields.push({ name: COLUNA_VISUALIZAR, type: "number", map: "id" });
    }

    if (permitirEdicao) {
      dataFields.push({ name: COLUNA_EDITAR, type: "number", map: "id" });
    }

    if (permitirExclusao) {
      dataFields.push({ name: COLUNA_EXCLUIR, type: "number", map: "id" });
      dataFields.push({
        name: COLUNA_PODE_EXCLUIR,
        type: "boolean",
        map: "permitirExclusao",
      });
      dataFields.push({
        name: COLUNA_PODE_EDITAR,
        type: "boolean",
        map: "permitirEdicao",
      });
    }

    if (permitirAlterarModoEdicao) {
      dataFields.push({
        name: COLUNA_MOBILE,
        type: "string",
        map: "modoEdicao",
      });
    }

    if(permitirCompatibilizacao) {
      dataFields.push({
        name: COLUNA_COMPATIBILIZACAO,
        type: "boolean",
        map: "permitirCompatibilizacao",
      })
    }

    setDataFields(dataFields);
  }, [campos, permitirEdicao, permitirExclusao, permitirVisualizacao, permitirAlterarModoEdicao, prontoParaExibir, permitirCompatibilizacao]);

  useEffect(() => {
    async function fetchOrdenacao() {
      if (!isFormOpen) {
        setTableSource(
          new jqx.dataAdapter({
            width: '100%',
            datatype: "array",
            localdata: await ordenacaoPersonalizada(elementosExibidos),
            datafields: dataFields,
            ...parametrosParaTableSource,
            autoHeight: true
          })
        );
      }
    }
    fetchOrdenacao();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elementosExibidos, dataFields, parametrosParaTableSource, isFormOpen]);

  useEffect(() => {
    if (isFormOpen) {
      // Esconde os botões do SubForm pai
      if (alternarBotoesPai) {
        const subFormPaiElem = document.getElementById(uid).parentElement?.closest('.subform');

        if (subFormPaiElem) {
          const nomeSubFormPai = [...subFormPaiElem.classList].find(c => c.startsWith('subform-nome-'))?.replace('subform-nome-', '');
          const nomeSubFormPaiTratado = tratarNomeClasse(nomeSubFormPai);
          const btnsSubFormPaiElem = subFormPaiElem.querySelector(`.subform-btns-padrao-${nomeSubFormPaiTratado}`);

          if (btnsSubFormPaiElem) {
            btnsSubFormPaiElem.style.display = 'none';
          }
        }
      }
    } else {
      // Exibe os botões do SubForm pai se não houver outros SubForm filhos abertos
      if (alternarBotoesPai) {
        const subFormPaiElem = document.getElementById(uid).parentElement?.closest('.subform');

        if (subFormPaiElem) {
          const nomeSubFormPai = [...subFormPaiElem.classList].find(c => c.startsWith('subform-nome-'))?.replace('subform-nome-', '');
          const nomeSubFormPaiTratado = tratarNomeClasse(nomeSubFormPai);
          const qtdSubFormFilhosAbertos = subFormPaiElem.getElementsByClassName('subform-aberto').length

          if (qtdSubFormFilhosAbertos === 0) {
            const btnsSubFormPaiElem = subFormPaiElem.querySelector(`.subform-btns-padrao-${nomeSubFormPaiTratado}`);

            if (btnsSubFormPaiElem) {
              btnsSubFormPaiElem.style.display = '';
            }
          }
        }
      }
    }
  }, [uid, alternarBotoesPai, isFormOpen])

  // **********************************************************************
  // Render



  const renderFormInternal = useCallback((props) => {
    return (
      <SubFormPrefixContext.Provider value={prefixoAtual}>
        <>
          <NotificadorErrosForm />

          <Field
            name={FORM_ERROR}
            component={HiddenField}
          />

          {renderForm({
            formProps: props,
            prefixoNome: '',
            editando: estadoEdicao.estaEditando,
            visualizando: estadoEdicao.estaVisualizando,
          })}

          {exibirBotoesPadrao && (
            <BotoesPadrao
              voltar={onBtnBackClick}
              disabled={props.submitting}
              nome={nome}
              uid={uid}
              mostrarBotaoConfirmar={!estadoEdicao.estaVisualizando && exibirBotaoConfirmar}
              labelBotaoConfirmar={labelBotaoConfirmar}
              exibirBotoesPadrao={exibirBotoesPadrao}
            />
          )}
        </>
      </SubFormPrefixContext.Provider>
    )
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [renderForm, onBtnBackClick, estadoEdicao.estaEditando, prefixoAtual, nome, uid, labelBotaoConfirmar, exibirBotaoConfirmar, exibirBotoesPadrao]);

  // Renderiza o final-form se o SubForm estiver aberto, caso contrário renderiza o grid
  if (isFormOpen) {
    let initialValues = { ...valoresIniciais };

    if (estadoEdicao.estaEditando) {
      initialValues = {
        ...elementosExibidosPorId[estadoEdicao.id],
      };
    } else {
      if (!isNumber(initialValues.id)) {
        initialValues.id = ULTIMO_ID_TEMPORARIO--;
      }
    }

    return <div id={uid} className={`subform subform-nome-${nome} subform-aberto`}>
      <Form
        onSubmit={enviarForm}
        initialValues={initialValues}
        validate={validar}
        decorators={decoradores}
        mutators={mutadores}
        subscription={formSubscription}
        keepDirtyOnReinitialize={keepDirtyOnReinitialize}
      >
        {renderFormInternal}
      </Form>
    </div>
  }

  return (
    <div id={uid} className={`subform subform-nome-${nome} subform-fechado`}>
      <Row>
        <Col md={8} className="mb-4">
          {tituloFormMenor ? 
          <Card.Subtitle>
            {tituloForm}
            {exibirAsterisco && <span style={{ color: "red" }}>*</span>}  
          </Card.Subtitle> : 
          <Card.Title>
            {tituloForm}
            {exibirAsterisco && <span style={{ color: "red" }}>*</span>} 
          </Card.Title>}
        </Col>
        {exibirBotaoInsercao && (
          <Col md={4}>
            <PrimaryButton
              className={`float-right mb-2 subform-btn-adicionar subform-btn-adicionar-${nome}`}
              onClick={abrirFormularioEmBranco}
              titulo="Adicionar"
              disabled={!permitirInsercao}
              tabIndex={tabIndexAdicionar}
            />
          </Col>
        )}
      </Row>
      {prontoParaExibir
        // Não exibir a tabela se não houverem registros
        && (exibirTabelaSemRegistro || (!exibirTabelaSemRegistro && elementosExibidos && elementosExibidos.length > 0))
        && (
          <LegacyTable
            ref={tableRef}
            autoshowloadelement={false}
            columns={tableColumns}
            source={tableSource}
            className={`subform-table subform-table-${nome}`}
            {...tableProps}
          />
        )}
    </div>
  );
}

LegacySubForm.propTypes = {
  // Props básicas
  renderForm: PropTypes.func.isRequired,
  campos: PropTypes.array.isRequired,
  colunas: PropTypes.array.isRequired,
  nome: PropTypes.string.isRequired,

  // Controle de layout/funcionalidades
  keepDirtyOnReinitialize: PropTypes.bool,
  podeExcluirEntidade: PropTypes.func,
  podeEditarEntidade: PropTypes.func,
  podeVisualizarEntidade: PropTypes.func,
  criarDecoradores: PropTypes.func,
  valoresIniciais: PropTypes.object,
  permitirInsercao: PropTypes.bool,
  permitirEdicao: PropTypes.bool,
  permitirExclusao: PropTypes.bool,
  permitirVisualizacao: PropTypes.bool,
  permitirCompatibilizacao: PropTypes.bool,
  permitirAlterarModoEdicao: PropTypes.bool,
  exibirBotaoInsercao: PropTypes.bool,
  exibirBotaoConfirmar: PropTypes.bool,
  delayParaExibicaoTabela: PropTypes.number,
  alternarBotoesPai: PropTypes.bool,
  exibirTabelaSemRegistro: PropTypes.bool,
  scrollAoFechar: PropTypes.bool,
  formSubscription: PropTypes.object, // undefined ou FormState https://final-form.org/docs/final-form/types/FormState | https://codesandbox.io/s/32r824vxy1
  validarVoltar: PropTypes.bool,
  naoValidarCamposVoltar: PropTypes.array,
  labelBotaoConfirmar: PropTypes.string,
  confirmarExclusao: PropTypes.bool,
  tableProps: PropTypes.object,
  ordenacaoPersonalizada: PropTypes.func,
  podeCompatibilizar: PropTypes.func,
  compatibilizar: PropTypes.func,

  // Eventos
  onSubmit: PropTypes.func,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  validar: PropTypes.func,
  onBtnEditClick: PropTypes.func,
  onBtnDeleteClick: PropTypes.func,
  onBtnViewClick: PropTypes.func,
  onBtnChangeEditModeClick: PropTypes.func,
  aoCarregarExibidos: PropTypes.func,
  exibirBotaoModoEdicao: PropTypes.func,

  // Estado
  formularioAberto: PropTypes.bool,
};

LegacySubForm.defaultProps = {
  valoresIniciais: {},
  validar: () => { },
  parametrosParaTableSource: {},
  elementos: {
    existentes: [],
    novos: [],
    editadosPorId: {},
    idsExcluidos: [],
  },
  ordenacaoPersonalizada: (elementos) => elementos,
  exibirBotaoInsercao: true,
  exibirBotaoConfirmar: true,
  permitirInsercao: true,
  permitirEdicao: true,
  permitirExclusao: true,
  permitirVisualizacao: false,
  scrollAoFechar: true,
  formSubscription: undefined,
  alternarBotoesPai: true,
  delayParaExibicaoTabela: DELAY_EXIBICAO_TABELA_PADRAO,
  validarVoltar: true,
  naoValidarCamposVoltar: [],
  exibirTabelaSemRegistro: false,
  labelBotaoConfirmar: 'Confirmar',
  confirmarExclusao: true,

  formularioAberto: false,
}

export default LegacySubForm;

class TableActionEvent {
  constructor(value, rowdata) {
    this.preventedDefault = false;
    if (value) {
      this.value = value;
      this.rowdata = rowdata;
    }
  }

  preventDefault() {
    this.preventedDefault = true;
  }
}
