export const CLASSES_AMOSTRA_GEOCRONOLOGIA = [
  { id: 8, nome: "Minério" },
  { id: 14, nome: "Outros" },
  { id: 9, nome: "Rocha" },
  { id: 7, nome: "Solo" },
  { id: 2, nome: "Concentrado de bateia" },
  { id: 16, nome: "Crosta" },
  { id: 17, nome: "Overbank" },
  { id: 10, nome: "Sedimento de corrento" },
];

