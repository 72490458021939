const INDICE_ORIGINAL = Symbol("INDICE ORIGINAL NO VETOR TRANSFORMADO")

const converterVetorParaObjetoIndexado = (vetor = [], {extratorId = (e) => e.id} = {}) => {
  if (!Array.isArray(vetor)) {
    console.warn("Método chamado sem vetor! Valor recebido: " + JSON.stringify(vetor))
    return {}
  }
  return vetor.reduce((acc, cur, idx) => {
    if (null == cur) return null;
    const id = extratorId(cur)
    acc[id] = cur
    acc[id][INDICE_ORIGINAL] = idx
    return acc;
  }, {})
}

export default converterVetorParaObjetoIndexado;
export {INDICE_ORIGINAL}
