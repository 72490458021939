import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Field, useForm } from "react-final-form";
import { aoEnviarSubForm } from "../../../util/mutadores";
import Card from "../../../components/card";
import { TextAreaField } from "../../../components/field/TextAreaField";
import { SubForm } from "../../../components/SubForm";
import { DropdownListField } from "../../../components/field/DropdownListField";
import { FORM_ERROR } from "final-form";
import { getPropsPermissao, resolverExibidos } from "../../../components/SubForm/SubForm";
import { campoObrigatorioComMsgGenerica } from "../../../util/validadores";

const Microestruturas = ({
    permitirEdicao,
    form,
    bbMicroestruturas,
    disabled
}) => {

    const onSubmit = aoEnviarSubForm(form);

    const colunasMicroestruturas = [
        {
            text: 'Microestrutura',
            datafield: 'idMicroestrutura',
            width: '20%',
            cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
                const microestruturasId = parseInt(rowdata?.idMicroestrutura, 10);
                const objetoFiltrado = bbMicroestruturas.find(elemento => elemento.id === microestruturasId);
                const valor = objetoFiltrado ? objetoFiltrado.nome : '';
                return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${valor}</div>`;
            }
        },
    ]
    const camposMicroestruturas = [
        { name: "idMicroestrutura", type: "number", map: "idMicroestrutura" },
        { name: "informacoesRelevantes", type: "string", map: "informacoesRelevantes" },
    ];

    const validarMicroestruturasIguais = (valores) => {
        const itens = form.getState().values?.microestruturas;

        if (itens) {
            const composicoes = resolverExibidos(itens);
            let hasRegistrosIguais = false
            composicoes.push(valores)
            composicoes.forEach(item => {
                const idItem = item.id;
                const idMicroestrutura = item.idMicroestrutura;
                const informacoesRelevantes = item.informacoesRelevantes;

                let verificaRegistrosIguais = composicoes.find(item => (
                    item.idMicroestrutura === idMicroestrutura &&
                    item.informacoesRelevantes === informacoesRelevantes &&
                    item.id !== idItem
                ));
                if (verificaRegistrosIguais) {
                    hasRegistrosIguais = true
                }
            });



            if (hasRegistrosIguais) {
                return { [FORM_ERROR]: 'Não é possível cadastrar dois registros com os mesmos valores.' };
            }

        }
        return null;

    };
    const valorFormulario = useForm()
    return (
        <>
            <div style={{ marginTop: '2.5rem' }}>
                <Card className="mt-3">
                    <Card.Body>
                        <Card.Title>Microestrutura</Card.Title>
                        <Field name={'microestruturas'} subscription={{ value: true }}>
                            {({ input: { value: microestruturas = [] } }) => (
                                <SubForm
                                    nome={'microestruturas'}
                                    onSubmit={onSubmit}
                                    exibirTabelaSemRegistro={false}
                                    modoSubFormComGrid
                                    colunas={colunasMicroestruturas}
                                    elementos={microestruturas}
                                    campos={camposMicroestruturas}
                                    validar={(valores) => validarMicroestruturasIguais(valores)}
                                    {...getPropsPermissao(!disabled)}
                                    renderForm={({ formProps: { form } }) => {
                                        var valorAtual = form.getState().values?.idMicroestrutura
                                        var microestruturas = valorFormulario?.getState().values?.microestruturas
                                        var microestruturasTela = []
                                        if (microestruturas) {
                                            microestruturasTela = resolverExibidos(microestruturas)
                                        }
                                        var microestruturasFiltradas = bbMicroestruturas.filter(value => {
                                            return !microestruturasTela.some(testeItem => testeItem.idMicroestrutura === value.id && valorAtual !== value.id);
                                        });
                                        
                                        return (
                                            <>
                                                <Row className="my-3">
                                                    <Col md={12}>
                                                        <Field
                                                            component={DropdownListField}
                                                            name={"idMicroestrutura"}
                                                            label="Microestrutura"
                                                            dica="Selecionar uma ou mais microestruturas observadas na análise petrográfica. Iniciar pela principal/predominante."
                                                            elementos={microestruturasFiltradas}
                                                            displayMember={'nome'}
                                                            required
                                                            validate={campoObrigatorioComMsgGenerica("Microestrutura")}
                                                            disabled={disabled}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row className="my-3">
                                                    <Col md={12}>
                                                        <Field
                                                            component={TextAreaField}
                                                            name={'informacoesRelevantes'}
                                                            label="Informações relevantes"
                                                            dica="Informações complementares referente a composição mineralógica."
                                                            maxLength={2000}
                                                            disabled={disabled}
                                                        />
                                                    </Col>
                                                </Row>
                                            </>
                                        )
                                    }
                                    }
                                />
                            )}
                        </Field>
                    </Card.Body>
                </Card>
            </div>
        </>
    );
};

export default Microestruturas;