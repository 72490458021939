import Service, {
  AFLORA,
  BASEGEO,
  LITOESTATIGRAFIA,
  PETRO,
  RECMIN,
} from "../../../../../service/Service";
import { swalErro } from "../../../../../util/validadores";
import { Amostra } from "../types/amostra";

export const consultarClassesRocha = async () => {
  const response = await Service("/tipoRocha", AFLORA)
    .query()
    .catch((_error) => {
      swalErro({ title: "Erro ao consultar as classes de rocha" });
    });
  return response?.data?.dados || [];
};

export const consultarSubClassesRocha = async () => {
  const response = await Service("/subclasserocha", AFLORA)
    .query()
    .catch((_error) => {
      swalErro({ title: "Erro ao consultar as subclasses de rocha" });
    });

  return response?.data?.dados || [];
};

export const consultarPrefixosRocha = async () => {
  const response = await Service("/prefixos", BASEGEO)
    .query()
    .catch((_error) => {
      swalErro({ title: "Erro ao consultar os prefixos das rochas" });
    });

  return response?.data?.dados || [];
};

export const consultarComplementosRocha = async () => {
  const response = await Service("/rochas/complemento", RECMIN)
    .query()
    .catch((_error) => {
      swalErro({ title: "Erro ao consultar os sufixos das rochas" });
    });

  return response?.data?.dados || [];
};

export const consultarRochas = async () => {
  const response = await Service("/rochas/nomeadas", AFLORA)
    .query()
    .catch((_error) => {
      swalErro({ title: "Erro ao consultar as rochas" });
    });

  return response?.data?.dados || [];
};

export const consultarUnidadeLitoEstratigraficas = async ({
  coordenadas,
}: Amostra) => {
  const { idDatum, longitude, latitude, tipoEntrada, hemisferio } = coordenadas;
  const params = `longitude=${longitude}&latitude=${latitude}&tipoEntrada=${tipoEntrada}&hemisferio=${hemisferio}&idDatum=${idDatum}`;
  const response = await Service(
    `/unidadeLitoestratigrafica?${params}`,
    LITOESTATIGRAFIA
  )
    .query()
    .catch((_error) => {
      swalErro({ title: "Erro ao consultar as unidades estratigráficas" });
    });

  return response?.data?.dados || [];
};

export const consultarRochasAfloramento = async (
  idMaterialGeologico: number
) => {
  const response = await Service("/rochas-afloramento", AFLORA)
    .get(idMaterialGeologico)
    .catch((_error) => {
      swalErro({ title: "Erro ao consultar a associação de afloramento" });
    });
  return response?.data?.dados || null;
};

export const consultarRochaPetrografia = async (idAmostra: number) => {
  const response = await Service("/petrografias/amostra", PETRO)
    .get(idAmostra)
    .catch((_error) => {
      swalErro({ title: "Erro ao consultar a associação de petrografia" });
    });
  return response?.data?.dados || null;
};
