import axios from 'axios';
import { ACCESS_TOKEN_KEY } from "../reducers/authReducers";

const headerInterceptor = (config) => {
  config.headers['Access-Control-Allow-Origin'] = process.env.REACT_APP_API_URL;

  const authToken = localStorage.getItem(ACCESS_TOKEN_KEY);
  if (authToken) {
    config.headers['Authorization'] = 'Bearer ' + authToken
  }

  return config;
}

const defaultInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 40000,
});

axios.interceptors.request.use(headerInterceptor);
defaultInstance.interceptors.request.use(headerInterceptor);

export default defaultInstance;
