import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Field, Form } from "react-final-form";
import "./Login.css";
import { PasswordField } from "../../components/field/PasswordField";
import { TextInputField } from "../../components/field/TextInputField";
import { campoObrigatorioComMsgGenerica } from "../../util/validadores";
import { connect } from "react-redux";
import { efetuarLogin } from "../../reducers/authReducers";
import logo_basegeo_login from '../../assets/img/logoBaseGeoLogin.png';

class Login extends Component {

  constructor() {
    super();
    this.state = {
      btnHover: false
    }
  }

  render() {
    if (this.props.autenticado) {
      return (
        <Redirect
          to={{
            pathname: "/index",
            state: {
              from: this.props.location,
            },
          }}
        />
      );
    }

    const btnStyle = {
        border: 'none',
        fontSize: 14,
        width: '300px',
        height: '50px',
        borderRadius: '6px',
        color: 'white', 
        backgroundColor: '#0079c0',
    }

    const btnStyleHover = {
      border: 'none',
      fontSize: 14,
      width: '300px',
      height: '50px',
      borderRadius: '6px',
      color: 'white',
      opacity: 0.6, 
      backgroundColor: '#0079c0',
    }

    localStorage.removeItem('persist:storage_aflora')
    const erro =
      (this.props.location &&
        this.props.location.state &&
        this.props.location.state.erro) ||
      this.props.erro;
    return (
      <div className="bg">       
        <div className="login-card">
        <h1><img src={logo_basegeo_login}></img></h1>
          <Form
            className="login-card"
            subscription={{}}
            onSubmit={this.enviarFormulario.bind(this)}
            render={({ handleSubmit, form }) => {
              return (
                <>
                <form onSubmit={handleSubmit}>
                  <input type="hidden" name="redirectUrl" value={process.env.REACT_APP_PUBLIC_URL + "/oauth2/redirect"}/>
                  {/* Alerta de erro */}
                  {erro &&(       
                    <>
                      {<legend className="w-auto small" style={{margin: 0, fontSize: '10px'}}>
                        <span style={{color: "red"}}>{erro}</span>
                      </legend>}
                    </>)
                          }
                        <Field
                          component={TextInputField}
                          className={'login-card'}
                          name="usuario"
                          placeholder="nome.sobrenome"
                          height={30}
                          validate={campoObrigatorioComMsgGenerica("nome.sobrenome")}
                          autoFocus
                          maxLength={30}

                        />

                        <Field
                          component={PasswordField}
                          name="senha"
                          type="password"
                          placeholder="senha do computador institucional"
                          height={30}
                          validate={campoObrigatorioComMsgGenerica("senha do computador institucional")}
                          maxLength={30}
                        />

                    <div style={{ marginTop: '30px', position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <button 
                        onMouseEnter={() => this.setState({ btnHover: true })} onMouseLeave={() => this.setState({ btnHover: false })} 
                        style={this.state.btnHover ? btnStyleHover : btnStyle } onClick={() => form.submit()}>
                        ENTRAR
                      </button>
                    </div>

                  </form>
                  <div className="google">
                  <span style={{ color: "#A9A9A9", alignItems:"center" }}>ou</span>
                  </div>
                  <div className="google" style={{width: "100%"}}>
                    <button type="button" onClick={this.logarViaGoogle.bind(this)}
                      class="login-with-google-btn" >
                      Continuar com a conta do Google
                    </button>
                  </div>
                </>                   
                );
              }}
            />
        </div>
        
      
     </div>
    );
  }

  enviarFormulario(valores) {
    this.props.efetuarLogin(valores.usuario, valores.senha);
  }

  logarViaGoogle() {
    window.location =
    process.env.REACT_APP_API_URL + 
    "/oauth2/authorize/google?redirect_uri=" +
    process.env.REACT_APP_URL_LOGIN_CLIENT +
    "/oauth2-redirect"
  }
}

export default connect(
  (state) => ({
    autenticado: state.autenticacao.autenticado,
    erro: state.autenticacao.erro,
  }),
  (dispatch) => ({
    efetuarLogin(usuario, senha) {
      dispatch(efetuarLogin({ usuario, senha }));
    },
  })
)(Login);
