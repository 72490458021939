import React, { Component } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { Field } from "react-final-form";
import { DateTimeInputField } from "../../../../../components/field/DateTimeInputField";
import { DropdownListField } from "../../../../../components/field/DropdownListField";
import { TextInputField } from "../../../../../components/field/TextInputField";
import { TextAreaField } from "../../../../../components/field/TextAreaField"
import { SubForm } from "../../../../../components/SubForm";
import {
  GRAU_IMPORTANCIA_SUBSTANCIA_PRINCIPAL,
  GRAU_IMPORTANCIA_SUBSTANCIA_SECUNDARIA,
  MINA_GARIMPO
} from "../../../../../util/constantes";
import {
  campoObrigatorioComMsgGenerica,
  campoObrigatorioDefinitivoComMsgGenerica,
  validacoesMinaGarimpo,
  validarSe
} from "../../../../../util/validadores";
import FonteReferencia from "../FonteReferencia/FonteReferencia";
import { setValue } from "../../../../../util/mutadores";
import { getPropsPermissao, resolverExibidos } from "../../../../../components/SubForm/SubForm";
import dayjs from "dayjs";
import Substancia from "../substancia/Substancia";
import { resolverMultiRequestRecmin, resolverRequestRecmin } from "../../../../../util/ResolverRequestRecmin";
import { RadioField } from "../../../../../components/field/RadioField";

class MinaGarimpo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      statusEconomico: [],
      situacao: [],
      localizacaoMarco: [],
      importanciaSubstancia: [
        GRAU_IMPORTANCIA_SUBSTANCIA_PRINCIPAL,
        GRAU_IMPORTANCIA_SUBSTANCIA_SECUNDARIA,
      ],
      exibeCoordenada: [
        {
          id: "SIM",
          nome: "Sim",
        },
        {
          id: "NAO",
          nome: "Não",
        },
      ],
    };

    this.colunasMinaGarimpo = [
      { text: "Nome", datafield: "nome", width: "20%", },
      { text: "Titular", datafield: "titular", width: "20%", },
      { text: "Status econômico", datafield: "idStatusEconomico", width: "14%",
        cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
          let statosEconomicos = this.state.statusEconomico;
          let nome = "";
          statosEconomicos?.map(situacao => {
            if (situacao?.id?.toString() === rowdata?.idStatusEconomico?.toString()) {
              nome = situacao.nome;
            }
          });
          return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${nome}</div>`;
        } 
      },
      { text: "Situação", datafield: "idSituacao", width: "20%",
        cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
          let situacoes = this.state.situacao;
          let nome = "";
          situacoes?.map(situacao => {
            if (situacao?.id?.toString() === rowdata?.idSituacao?.toString()) {
              nome = situacao.nome;
            }
          });
          return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${nome}</div>`;
        } 
      },
      { text: "Data da publicação da informação", datafield: "data", width: "20%", },
    ];

    this.datafieldsMinaGarimpo = [
      { name: "nome", type: "string", map: "nome" },
      { name: "titular", type: "string", map: "titular" },
      { name: "idStatusEconomico", type: "string", map: "idStatusEconomico" },
      { name: "idSituacao", type: "string", map: "idSituacao" },
      { name: "data", type: "string", map: "data" },
    ];

    /** @type {React.RefObject<import("../../../../../components/Jqx/Table").Table>} */
    this.tableRef = React.createRef();
  }


  preencherSituacaoMineracao() {
    if (this.props.referenciaForm.getState().values.minasGarimpos) {
      let historico = resolverExibidos(this.props.referenciaForm.getState().values.minasGarimpos)
      let datas = [];
      historico?.map(registro => {
        datas.push(registro.data);
        return 0;
      })
      datas = datas?.map(data => {
        return dayjs(data, 'DD/MM/YYYY');
      })
      var minMax = require('dayjs/plugin/minMax');
      dayjs.extend(minMax);
      let maxData = dayjs?.max(datas)?.format('DD/MM/YYYY');
      this.props.referenciaForm.mutators.setValue(`idSituacaoMineracao`, dayjs?.max(datas)?.format('DD/MM/YYYY'));
      historico?.map(registro => {
        if (dayjs(registro.data, 'DD/MM/YYYY').isSame(dayjs(maxData, 'DD/MM/YYYY'))) {
          this.props.referenciaForm.mutators.setValue(`idSituacaoMineracao`, registro.idSituacao);
        }
      })
    }
  }

  onSubmit({ alteracoesForm }) {
    this.props.referenciaForm.batch(() => {
      for (const [key, val] of Object.entries(alteracoesForm)) {
        this.props.referenciaForm.mutators.setValue(`${key}`, val);
      }
    });

    this.preencherSituacaoMineracao();
    if (this.props.referenciaForm.getState().values.minasGarimpos) {
      let arrayMinaGarimpo = resolverExibidos(this.props.referenciaForm.getState().values.minasGarimpos);
      let arrayFonteReferencia = [];
      arrayMinaGarimpo?.map(minaGarimpo => {
        if (minaGarimpo.fontesReferencia) {
          let exibidos = resolverExibidos(minaGarimpo.fontesReferencia);
          exibidos?.map(item => {
            if (item.modoCadastro === "SIMPLIFICADO") {
              item.referencia = item.descricao
            }
            if (item.modoCadastro === "DETALHADO") {
              const autores = item.autores.split(",");
              if (autores.length === 1) item.referencia = `${autores[0].split(" ").pop()}. (${item.ano}). ${item.titulo}.`;
              if (autores.length === 2) item.referencia = `${autores[0].split(" ").pop()} e ${autores[1].split(" ").pop()}. (${item.ano}). ${item.titulo}.`;
              if (autores.length >= 3) item.referencia = `${autores[0].split(" ").pop()} et al. (${item.ano}). ${item.titulo}.`
            }
            if (item.modoCadastro === "BASE_PROJETOS" && item.idProjeto !== "") item.referencia = item.idProjeto;
            if (item.modoCadastro === "BASE_PROJETOS" && item.idProjeto === "") item.referencia = item.link;
          }
          );
          arrayFonteReferencia.push(exibidos);
        }
        return 0;
      }
      );
      this.props.atualizarTabelaFonteReferenciaRecursoMineral(arrayFonteReferencia.flat(), "MINA_GARIMPO");
    }
  }

  onSubmitSubForm({ alteracoesForm }, formProps, formulario) {


    formProps?.batch(() => {
      for (const [key, val] of Object.entries(alteracoesForm)) {
        formProps?.mutators?.setValue(`${key}`, val);
      }
    });
  }



  async componentDidMount() {
    const responses = await resolverMultiRequestRecmin({
      statusEconomico: { uri: "/statusEconomico" },
      situacao: { uri: "/situacao" },
      localizacaoMarco: { uri: "/localizacaoMarco" },
    });

    // Se a lista de localizações estava vazia antes, redesenhamos a tabela.
    const needsTableRedraw =
      this.state.localizacaoMarco.length === 0
      && responses.localizacaoMarco.length > 0;

    this.setState(responses);

    if (needsTableRedraw) {
      this.tableRef.current?.refreshItems();
    }
  }

  render() {
    return (
      <Field name={"minasGarimpos"} subscription={{ value: true }}>
        {({ input: { value: minasGarimpos = {} } }) =>
          <SubForm
            {...getPropsPermissao(this.props.permitirEdicao)}
            tableRef={this.tableRef}
            keepDirtyOnReinitialize
            tituloForm="Mina ou Garimpo"
            nome="minasGarimpos"
            exibirAsterisco={this.props.obrigatorio}
            onSubmit={this.onSubmit.bind(this)}
            colunas={this.colunasMinaGarimpo}
            campos={this.datafieldsMinaGarimpo}
            validar={(valores) => this.props.modoDefinitivo ? validacoesMinaGarimpo(valores, this.props.referenciaForm) : undefined}
            elementos={
              minasGarimpos
            }
            formSubscription={{}}
            renderForm={({ formProps }) => {
              return (
                <Field subscription={{ value: true }} name={'idStatusEconomico'}>
                  {({ input: { value: idStatusEconomico } }) => {

                  let titularObrigatorio = false;

                  if(idStatusEconomico === 1)
                    titularObrigatorio = true;
                  else
                    titularObrigatorio = false;

                  return <><Row>
                    <Col md={12}>
                      <Card.Title>Mina ou Garimpo
                        {this.props.obrigatorio ? <span style={{ color: "red" }}>*</span> : <></>}
                      </Card.Title>
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Col md={2}>
                      <Card.Subtitle>Histórico da situação</Card.Subtitle>
                    </Col>
                    <Col md={10}>
                      <hr />
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <Col md={4}>
                      <Field
                        disabled={!this.props.permitirEdicao}
                        name="nome"
                        component={TextInputField}
                        label="Nome"
                        dica="Informe o nome da Mina ou Garimpo"
                        required={this.props.modoDefinitivo}
                        maxLength={255}
                        initialValue=""
                        validate={validarSe(this.props.modoDefinitivo, campoObrigatorioComMsgGenerica("Nome"))}
                      />
                    </Col>
                    <Col md={4}>
                      <Field
                        disabled={!this.props.permitirEdicao}
                        name="idStatusEconomico"
                        component={DropdownListField}
                        label="Status econômico"
                        dica="Selecione da biblioteca o status econômico da Mina ou Garimpo"
                        elementos={this.state.statusEconomico}
                        required={this.props.modoDefinitivo}
                        initialValue=""
                        validate={validarSe(this.props.modoDefinitivo, campoObrigatorioComMsgGenerica(
                          "Status econômico"
                        ))}
                      />
                    </Col>
                    <Col md={4}>
                      <Field
                        disabled={!this.props.permitirEdicao}
                        name="idSituacao"
                        component={DropdownListField}
                        label="Situação"
                        dica="Selecione da biblioteca a situação da Mina ou Garimpo"
                        elementos={this.state.situacao}
                        required={this.props.modoDefinitivo}
                        initialValue=""
                        validate={validarSe(this.props.modoDefinitivo, campoObrigatorioComMsgGenerica("Situação"))}
                      />
                    </Col>
                  </Row>
                  
                  <Row className="mt-4">
                  <Col md={4}>
                    <Field
                        disabled={!this.props.permitirEdicao}
                        component={RadioField}
                        name="data"
                        campo="data"
                        colunas={3}
                        label="Data da publicação da informação"
                        modoDefinitivo={this.props.modoDefinitivo}
                        tipoCampo={DateTimeInputField}
                        max={this.props.dataCriacao}
                        dica="Informe, se possível a data em que a informação foi publicada"
                      />
                  </Col>
                    <Field name={"titular"} subscription={{ value: true }}>
                      {({ input: { value: titular } }) => {
                        return <>
                          {titularObrigatorio ? <Col md={4}>
                            <Field
                              disabled={!this.props.permitirEdicao}
                              name="titular"
                              component={TextInputField}
                              label="Titular"
                              dica="Informe o nome do titular da Mina ou Garimpo"
                              maxLength={255}
                              required={this.props.modoDefinitivo}
                              initialValue=""
                              validate={validarSe(this.props.modoDefinitivo, campoObrigatorioComMsgGenerica("Titular"))}
                            />
                          </Col> : <Col md={4}>
                            <Field
                              disabled={!this.props.permitirEdicao}
                              name="titular"
                              component={TextInputField}
                              label="Titular"
                              dica="Informe o nome do titular da Mina ou Garimpo"
                              maxLength={255}
                              initialValue=""
                            />
                          </Col>}
                        </>
                      }}</Field>

                    <Col md={4}>
                      <Field
                        disabled={!this.props.permitirEdicao}
                        name="idLocalizacaoMarco"
                        component={DropdownListField}
                        label="Localização do marco"
                        dica="Informe a localização do marco junto à ANM"
                        elementos={this.state.localizacaoMarco}
                        initialValue=""
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <Field
                        component={TextAreaField}
                        name={`informacoesRelevantes`}
                        label="Informações relevantes"
                        dica="Caso necesário, detalhe as informações observadas"
                        maxLength={250}
                        disabled={!this.props.permitirEdicao}
                      />
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <Col md={12}>
                      <Substancia
                        hintClasseUtilitaria={"Informe a qual classe utilitária pertence a substância, a partir da biblioteca. Baseia-se nas aplicações das substâncias."}
                        hintSubstancia={"Informe, a partir das substância cadastradas acima, a substância mineral produzida na Mina ou Garimpo"}
                        removerTeor={true}
                        permitirEdicao={this.props.permitirEdicao}
                        referenciaForm={formProps.form}
                        substanciasPrincipais={this.props.substanciasPrincipais}
                        substanciasSecundarias={this.props.substanciasSecundarias}
                        modoDefinitivo={this.props.modoDefinitivo}
                      />
                    </Col>
                  </Row>
                  <Row className="">
                    <Col md={12} className="mt-4">
                      <FonteReferencia
                        permitirEdicao={this.props.permitirEdicao}
                        formPai={formProps}
                        mutators={{ setValue }}
                        origem={MINA_GARIMPO}
                        modoDefinitivo={this.props.modoDefinitivo}
                      />
                    </Col>
                  </Row></>}}
                </Field>
              );
            }}
          />
        }
      </Field>
    );
  }
}

export default MinaGarimpo;
