import { defer, interval, Observable, of, OperatorFunction } from "rxjs";
import { map, mergeMap } from "rxjs/operators";
import { ComboItemModelLike } from "../models/combo-item.model";
import httpClient, { HttpClient, HttpProgressEvent } from "./HttpClient";
import cacheService, { ICacheService } from "./CacheService";
import { ActivityGeoquimicaModelLike } from "../models/activity-geoquimica.model";
import { AmostraSymbol } from "../views/estacoes/components/geoquimica/constants/amostras.constant";
import { ACCESS_TOKEN_KEY } from "../reducers/authReducers";
import jwt_decode from "jwt-decode";
import { SampleLabResult, SampleModelLike } from "../models/sample.model";
import {
  EtiquetaModelLike,
  EtiquetasCreateModelLike,
} from "../models/etiqueta-create.model";

interface AuthInfoLike {
  userId?: number;
  colectorCode?: string;
}

const apiUrl = process.env.REACT_APP_GEOQUIMICA_API_URL!;

const defaultLifetimeSeconds = 60 * 60;

class GeoquimicaService {
  idProjeto!: number;

  constructor(private http: HttpClient, private cacheService: ICacheService) {}

  generateNumeroCampo(amostraSymbol: AmostraSymbol): Observable<string> {
    return this.getColectorCode().pipe(
      map((colectorCode) => {
        const timestamp = Date.now();
        const numeroCampo = `${this.idProjeto}_${colectorCode}_${amostraSymbol}_${timestamp}`;
        return numeroCampo;
      })
    );
  }

  createActivity(model: ActivityGeoquimicaModelLike): Observable<any> {
    return this.getUserId().pipe(
      mergeMap((userId) => {
        model.id_usuario = userId;
        return this.http.post(`${apiUrl}/visitas/geoq`, model);
      })
    );
  }

  updateActivity(
    activityId: number,
    model: ActivityGeoquimicaModelLike
  ): Observable<any> {
    return this.http.put(`${apiUrl}/geoquimica/${activityId}`, model);
  }

  getActivityById(activityId: number): Observable<ActivityGeoquimicaModelLike> {
    return this.http
      .get<{ dados: ActivityGeoquimicaModelLike }>(
        `${apiUrl}/geoquimica/${activityId}`
      )
      .pipe(
        map((res) => {
          return res?.data?.dados;
        })
      );
  }

  uploadAmostraImage(file: File): Observable<string> {
    const formData = new FormData();
    formData.append("arquivo", file, file.name);
    return this.http
      .post<any>(`${apiUrl}/arquivos/armazenarTemporariamente`, formData)
      .pipe(
        map((res) => {
          return res.data;
        })
      );
  }

  getPointerLab(id_laboratorio: number): Observable<number> {
    return this.http
      .get<any>(
        `${apiUrl}/laboratorio/numero_lote?id_laboratorio=${id_laboratorio}`
      )
      .pipe(
        map((res) => {
          return res?.data?.ponteiro;
        })
      );
  }

  createEtiquetas(
    model: EtiquetasCreateModelLike
  ): Observable<EtiquetaModelLike[]> {
    return this.http
      .post<any>(`${apiUrl}/laboratorio/sequencial_lote`, {
        id_laboratorio: model.id_laboratorio,
        quantidade_etiquetas: model.amostras?.length ?? 0,
      })
      .pipe(
        mergeMap((res) => {
          const ponteiros: number[] = res.data?.ponteiros ?? [];
          const auxList: EtiquetaModelLike[] = new Array(
            model.amostras?.length
          );
          if (model.amostras?.length === ponteiros.length) {
            let item: SampleModelLike;
            let numero_etiqueta: string;
            let pointer: number;
            for (let i = model.amostras.length - 1; i > -1; --i) {
              item = model.amostras[i];
              pointer = ponteiros.pop()!;
              numero_etiqueta = `${model.numero_laboratorio}*${pointer}*${item.nu_peso_amostra}`;
              auxList[i] = {
                id_amostra: item.id_amostra,
                id_laboratorio: model.id_laboratorio,
                numero_laboratorio: model.numero_laboratorio,
                numero_lote: model.numero_lote,
                requisicao_analise: model.requisicao_analise,
                numero_etiqueta,
              };
            }
          }

          return this.http
            .post<any>(`${apiUrl}/laboratorio/amostra`, auxList)
            .pipe(
              map((res) => {
                return auxList;
              })
            );
        })
      );
  }

  listAmostras(filter?: {
    id_visita?: number;
    classe?: string;
    matriz?: string;
  }): Observable<SampleModelLike[]> {
    let params: any = {};
    if (filter) {
      Object.assign(params, filter);
    }
    return this.http
      .get<any>(`${apiUrl}/laboratorio/amostras`, { params })
      .pipe(
        map((res) => {
          return res.data?.dados ?? [];
        })
      );
  }

  uploadImportResultFile(file: File) {
    const formData = new FormData();
    formData.append("file", file, file.name);
    return this.http.post<any>(`${apiUrl}/laboratorio/importar`, formData, {
      observeProgress: true,
    });
  }

  saveImportResults(results: any[], status: string) {
    const auxValues = { status };
    results = results.map((item) => {
      item = Object.assign({}, item, auxValues);
      return item;
    });
    return this.http.post<any>(
      `${apiUrl}/laboratorio/importar/salvar`,
      results
    );
  }

  listSampleLabResults(id_amostra: number): Observable<SampleLabResult[]> {
    return this.http
      .get<any>(`${apiUrl}/laboratorio/amostra?id_amostra=${id_amostra}`)
      .pipe(
        map((res) => {
          const data: any[] = res.data?.resultados ?? [];
          return data;
        })
      );
  }

  finalizeSampleResults(id_amostra: number, status_amostra: string) {
    return this.http.post<any>(`${apiUrl}/laboratorio/statusamostra`, {
      id_amostra,
      status_amostra,
    });
  }

  listClasseAmostraOptions(): Observable<ComboItemModelLike[]> {
    return this._handleRequestCachedOptions(
      `${apiUrl}/biblioteca/classeamostra`
    );
  }

  listRochaRegionalOptions(): Observable<ComboItemModelLike[]> {
    return this._handleRequestCachedOptions(
      `${apiUrl}/biblioteca/rocharegional`
    );
  }

  listIdadeGeologicaOptions(): Observable<ComboItemModelLike[]> {
    return this._handleRequestCachedOptions(
      `${apiUrl}/biblioteca/idadegeologica`
    );
  }

  listPluviosidadeOptions(): Observable<ComboItemModelLike[]> {
    return this._handleRequestCachedOptions(
      `${apiUrl}/biblioteca/pluviosidade`
    );
  }

  listSituacaoTopograficaOptions(): Observable<ComboItemModelLike[]> {
    return this._handleRequestCachedOptions(
      `${apiUrl}/biblioteca/situacaotopografica`
    );
  }

  listRelevoOptions(): Observable<ComboItemModelLike[]> {
    return this._handleRequestCachedOptions(`${apiUrl}/biblioteca/relevo`);
  }

  listInfluenciaAntropica(): Observable<ComboItemModelLike[]> {
    return this._handleRequestCachedOptions(
      `${apiUrl}/biblioteca/influenciaantropica`
    );
  }

  listTipoVegetacaoOptions(): Observable<ComboItemModelLike[]> {
    return this._handleRequestCachedOptions(
      `${apiUrl}/biblioteca/tipovegetacao`
    );
  }

  listTipoAmostragemOptions(): Observable<ComboItemModelLike[]> {
    return this._handleRequestCachedOptions(
      `${apiUrl}/amostra/agua/tipoAmostragem`
    );
  }

  listFonteAmostraOptions(): Observable<ComboItemModelLike[]> {
    return this._handleRequestCachedOptions(
      `${apiUrl}/amostra/agua/fonteAmostra`
    );
  }

  listMaterialColetadoOptions(): Observable<ComboItemModelLike[]> {
    return this._handleRequestCachedOptions(
      `${apiUrl}/biblioteca/materialcoletado`
    );
  }

  listVelocidadeCorrenteOptions(): Observable<ComboItemModelLike[]> {
    return this._handleRequestCachedOptions(
      `${apiUrl}/amostra/agua/velocidadeCorrente`
    );
  }

  listNivelAguaOptions(): Observable<ComboItemModelLike[]> {
    return this._handleRequestCachedOptions(`${apiUrl}/biblioteca/nivelagua`);
  }

  listPreservacaoAmostraOptions(): Observable<ComboItemModelLike[]> {
    return this._handleRequestCachedOptions(
      `${apiUrl}/biblioteca/tipopreservacaoamostra`
    );
  }

  listAreaDrenagemOptions(): Observable<ComboItemModelLike[]> {
    return this._handleRequestCachedOptions(
      `${apiUrl}/biblioteca/areadrenagem`
    );
  }

  listTurbidezAguaOptions(): Observable<ComboItemModelLike[]> {
    return this._handleRequestCachedOptions(`${apiUrl}/biblioteca/turbidez`);
  }

  listPosicaoLeitoOptions(): Observable<ComboItemModelLike[]> {
    return this._handleRequestCachedOptions(
      `${apiUrl}/amostra/agua/posicaoLeito`
    );
  }

  listCorOptions(): Observable<ComboItemModelLike[]> {
    return this._handleRequestCachedOptions(`${apiUrl}/biblioteca/cores`);
  }

  listNaturezaAmostraOptions(): Observable<ComboItemModelLike[]> {
    return this._handleRequestCachedOptions(
      `${apiUrl}/amostra/agua/naturezaAmostra`
    );
  }

  listGrauArredondamentoOptions(): Observable<ComboItemModelLike[]> {
    return this._handleRequestCachedOptions(
      `${apiUrl}/biblioteca/arredondamento`
    );
  }

  listSituacaoAmostraOptions(): Observable<ComboItemModelLike[]> {
    return this._handleRequestCachedOptions(
      `${apiUrl}/biblioteca/situacaoamostra`
    );
  }

  listGrauIntemperismoOptions(): Observable<ComboItemModelLike[]> {
    return this._handleRequestCachedOptions(
      `${apiUrl}/biblioteca/grauintemperismo`
    );
  }

  listAmbienteCrostaOptions(): Observable<ComboItemModelLike[]> {
    return this._handleRequestCachedOptions(
      `${apiUrl}/biblioteca/ambientecrosta`
    );
  }

  listComposicaoCrostaOptions(): Observable<ComboItemModelLike[]> {
    return this._handleRequestCachedOptions(
      `${apiUrl}/biblioteca/composicaocrosta`
    );
  }

  listDecomposicaoCrostaOptions(): Observable<ComboItemModelLike[]> {
    return this._handleRequestCachedOptions(
      `${apiUrl}/biblioteca/decomposicaocrosta`
    );
  }

  listMatrizPredominanteOptions(): Observable<ComboItemModelLike[]> {
    return this._handleRequestCachedOptions(
      `${apiUrl}/biblioteca/matrizpredominante`
    );
  }

  listTipoAlteracaoOptions(): Observable<ComboItemModelLike[]> {
    return this._handleRequestCachedOptions(
      `${apiUrl}/biblioteca/tipoalteracao`
    );
  }

  listTipoMineralOptions(): Observable<ComboItemModelLike[]> {
    return this._handleRequestCachedOptions(`${apiUrl}/biblioteca/tipomineral`);
  }

  listModoDepositoOptions(): Observable<ComboItemModelLike[]> {
    return this._handleRequestCachedOptions(
      `${apiUrl}/biblioteca/mododeposito`
    );
  }

  listFormaDepositoOptions(): Observable<ComboItemModelLike[]> {
    return this._handleRequestCachedOptions(
      `${apiUrl}/biblioteca/formadeposito`
    );
  }

  listAmbienteBioticoOptions(): Observable<ComboItemModelLike[]> {
    return this._handleRequestCachedOptions(
      `${apiUrl}/biblioteca/ambientebiotico`
    );
  }

  listTexturaRochaOptions(): Observable<ComboItemModelLike[]> {
    return this._handleRequestCachedOptions(
      `${apiUrl}/biblioteca/texturarocha`
    );
  }

  listTipoSoloOptions(): Observable<ComboItemModelLike[]> {
    return this._handleRequestCachedOptions(`${apiUrl}/biblioteca/tiposolo`);
  }

  listHorizonteSoloOptions(): Observable<ComboItemModelLike[]> {
    return this._handleRequestCachedOptions(
      `${apiUrl}/biblioteca/horizontesolo`
    );
  }

  listLaboratorioOptions(): Observable<ComboItemModelLike[]> {
    return this._handleRequestCachedOptions(`${apiUrl}/laboratorio`);
  }

  private _handleRequestCachedOptions(
    path: string
  ): Observable<ComboItemModelLike[]> {
    return this.cacheService.getCached({
      path,
      lifetimeSeconds: defaultLifetimeSeconds,
      source: (path) => {
        return this.http.get<any>(path).pipe(this._handleComboItemsOperator());
      },
    });
  }

  private _handleComboItemsOperator(): OperatorFunction<
    any,
    ComboItemModelLike[]
  > {
    return map<any, ComboItemModelLike[]>((res) => {
      const data: any[] = res?.data?.dados ?? [];
      return data.map<ComboItemModelLike>((x) => {
        return {
          id:
            x.id ??
            x.id_decomposicao_crosta ??
            x.id_ambiente_crosta ??
            x.idLaboratorio,
          nome:
            x.name ??
            x.nome_pt ??
            x.nome ??
            x.descricao ??
            x.ds_decomposicao_crosta ??
            x.ds_ambiente_crosta ??
            x.nmLaboratorio,
          sigla: x.sigla ?? x.simbolo,
        };
      });
    });
  }

  private getColectorCode(): Observable<string> {
    return this.getDataFromAccessToken().pipe(
      map((authInfo) => authInfo?.colectorCode!)
    );
  }

  private getUserId(): Observable<number> {
    return this.getDataFromAccessToken().pipe(
      map((authInfo) => authInfo?.userId!)
    );
  }

  private getDataFromAccessToken(): Observable<AuthInfoLike> {
    return this.cacheService.getCached({
      path: "dataFromAccessToken",
      source: () => {
        return defer(() => {
          let authInfo!: AuthInfoLike;
          const token = localStorage.getItem(ACCESS_TOKEN_KEY);
          if (token) {
            const decoded = jwt_decode<any>(token);
            authInfo = {
              userId: decoded?.jti,
              colectorCode: decoded?.sigla,
            };
          }

          return of(authInfo);
        });
      },
    });
  }
}

export default new GeoquimicaService(httpClient, cacheService);
