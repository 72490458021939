import React, { useEffect, useRef, useState } from "react";
import Card from "../../../../components/card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { SubForm, LegacySubForm } from "../../../../components/SubForm";
import { Field } from "react-final-form";
import { DropdownListField } from "../../../../components/field/DropdownListField";
import { campoObrigatorioComMsgGenerica, validarSe } from "../../../../util/validadores";
import { TextAreaField } from "../../../../components/field/TextAreaField";
import { getPropsPermissao, resolverExibidos } from "../../../../components/SubForm/SubForm";
import { CounterField } from "../../../../components/field/CounterField";

const campos = [
  { name: "idTipo", type: "string", map: "idTipo" },
  { name: "idRocha", type: "string", map: "idRocha" },
]

const AlteracoesHidrotermais = ({
  nome,
  onSubmit,
  // elementos,
  valoresIniciais,
  permitirEdicao,
  formAtividade,
  rochas,
  rochasPorId,
  minerais = [],
  formasOcorrencia = [],
  tiposAlteracaoHidrotermal = [],
  intensidadesAlteracaoHidrotermal = [],
  modoDefinitivoSelecionado,
  hierarquias = [],
  altHidrotermaisComErro
}) => {
  // These refs are for remembering the previous values of each array that's a
  // dependency of the columns array. We only recalculate the columns array when
  // there's an actual change in the arrays.
  const rochasPorIdRef = useRef(rochasPorId);
  const tiposAlteracaoHidrotermalRef = useRef(tiposAlteracaoHidrotermal);
  const [colunasAlteracoesHidrotermais, setColunasAlteracoesHidrotermais] = useState(gerarColunasAlteracoesHidrotermais(rochasPorId, tiposAlteracaoHidrotermal));

  useEffect(() => {
    // Only update the columns when at least one of the dependencies has really changed.
    // Otherwise, avoid changing the columns because that causes a table rerender.
    // (The *correct* thing to do would be including the names in the objects themselves (add extra fields)
    // and always recalculate those names when dependencies change, completely removing the need for
    // a cellsrenderer.)
    // Compare the dependencies by value.
    if (JSON.stringify(rochasPorIdRef.current) !== JSON.stringify(rochasPorId)
        || JSON.stringify(tiposAlteracaoHidrotermalRef.current) !== JSON.stringify(tiposAlteracaoHidrotermal)
        ) {
      setColunasAlteracoesHidrotermais(gerarColunasAlteracoesHidrotermais(rochasPorId, tiposAlteracaoHidrotermal));
      rochasPorIdRef.current = rochasPorId;
      tiposAlteracaoHidrotermalRef.current = tiposAlteracaoHidrotermal;
    }
  }, [rochasPorId, tiposAlteracaoHidrotermal])

  const valoresIniciaisRef = useRef(valoresIniciais);

  function aoConfirmarVoltar(form) {
    let keys = Object.keys(form.getState().values);

    keys.forEach(key => {
      form.mutators.setValue(key, undefined);
    });

    form.mutators.setValue('id', -1 * (Math.floor(Math.random() * 100) + 1));
    valoresIniciaisRef.current = null;
    valoresIniciais = null;
  }

  return (
    <div style={{ marginTop: '2.5rem' }}>
      <Card className="mt-3">
        <Card.Body>
        {altHidrotermaisComErro && <span style={{ color: "red" }}>Existem alterações hidrotermais com campos obrigatórios não preenchidos</span>}
          <Card.Title>Alterações hidrotermais</Card.Title>
          <Field name={nome} subscription={{ value: true }}>
            {({ input: { value: alteracoesHidrotermais = {} } }) => (
            <LegacySubForm
                nome={nome}
                onSubmit={(params) => {
                  // Reset the initial values so that it doesn't get automatically
                  // recovered on the next render.
                  // It would be better to check if the submit was successful before
                  // resetting this ref, but it doesn't seem like that's possible.
                  valoresIniciaisRef.current = null;
                  onSubmit(params);
                }}
                elementos={alteracoesHidrotermais} // elementos
                campos={campos}
                colunas={colunasAlteracoesHidrotermais}
                valoresIniciais={valoresIniciaisRef.current || valoresIniciais}
                {...getPropsPermissao(permitirEdicao)}
                alternarBotoesPai={true}
                validarVoltar={true}
                aoConfirmarVoltar={aoConfirmarVoltar}
                renderForm={({ formProps: { form, values }, prefixoNome }) => {

                  // Update the ref of initial values. This code needs to run
                  // when any field changes.
                  if (valoresIniciaisRef.current !== values) {
                    valoresIniciaisRef.current = values;
                  }

                  let listaAlteracoesHidrotermais = resolverExibidos(alteracoesHidrotermais);
                  let idsRochasUtilizadas = [];
                  let idEditado = form.getState().values.id;

                  if(idEditado)
                    listaAlteracoesHidrotermais = listaAlteracoesHidrotermais.filter(i => i.id != idEditado);

                  listaAlteracoesHidrotermais.forEach(item => {
                    if(item && item.idRocha)
                      idsRochasUtilizadas.push(item.idRocha);
                  });

                  let rochasFiltradas = rochas.filter(r => !idsRochasUtilizadas.find(id => r.id === id));

                  return (
                    <>
                      <Row className="my-3">
                        <Col md={6}>
                          <Field
                            component={DropdownListField}
                            name={`${prefixoNome}idRocha`}
                            label="Rocha"
                            dica="Selecione uma ou mais rochas para a qual deseja informar a alteração hidrotermal"
                            elementos={rochasFiltradas}
                            required={modoDefinitivoSelecionado}
                            disabled={!permitirEdicao}
                            displayMember={'nomeExibicao'}
                            validate={validarSe(modoDefinitivoSelecionado, campoObrigatorioComMsgGenerica("Rochas"))}
                            multiple
                          />
                        </Col>
                        <Col md={6}>
                          <Field
                            component={DropdownListField}
                            name={`${prefixoNome}idsMinerais`}
                            label="Minerais de alteração"
                            dica="Selecione o mineral ou o conjunto de minerais característicos desta alteração hidrotermal."
                            elementos={minerais}
                            disabled={!permitirEdicao}
                            checkboxes={true}
                            multiple
                            required={modoDefinitivoSelecionado}
                            validate={validarSe(modoDefinitivoSelecionado, campoObrigatorioComMsgGenerica("Minerais de alteração"))}
                          />
                        </Col>
                      </Row>
                      <Row className="my-3">
                        <Col md={6}>
                          <Field
                            component={DropdownListField}
                            name={`${prefixoNome}idFormaOcorrencia`}
                            label="Forma ocorrência"
                            dica="Selecione a forma de ocorrência do halo de alteração hidrotermal observado"
                            elementos={formasOcorrencia}
                            disabled={!permitirEdicao}
                          />
                        </Col>
                        <Col md={6}>
                          <Field
                            name={`${prefixoNome}idTipo`}
                            subscription={{ value: true }}>
                              {({ input: { value: tipoAlteracao } }) => {
                                
                                let listaFiltrada = tiposAlteracaoHidrotermal.filter((t) => t.atual === 'S');

                                if(tipoAlteracao && tiposAlteracaoHidrotermal.length && !listaFiltrada.find((t) => t.id === tipoAlteracao)){
                                  const item = tiposAlteracaoHidrotermal.find((t) => t.id === tipoAlteracao);

                                  if(item && item.atual === 'N'){
                                    listaFiltrada.push(item);
                                    listaFiltrada = listaFiltrada.sort((a, b) => a.nome.localeCompare(b.nome))
                                  }
                                }

                                return <Field
                                  component={DropdownListField}
                                  name={`${prefixoNome}idTipo`}
                                  label="Tipo de alteração"
                                  dica="Selecione o tipo de alteração hidrotermal observado na rocha"
                                  elementos={listaFiltrada}
                                  disabled={!permitirEdicao}
                                />
                              }}
                          </Field>
                        </Col>
                      </Row>
                      <Row className="my-3">
                        <Col md={6}>
                          <Field
                            component={DropdownListField}
                            name={`${prefixoNome}idIntensidadeAlteracao`}
                            label="Intensidade da alteração"
                            dica="Selecione o grau de intensidade da alteração hidrotermal observada na rocha"
                            elementos={intensidadesAlteracaoHidrotermal}
                            disabled={!permitirEdicao}
                            required={modoDefinitivoSelecionado}
                            validate={validarSe(modoDefinitivoSelecionado, campoObrigatorioComMsgGenerica("Intensidade alteração"))}
                          />
                        </Col>
                        <Col md={6}>
                          <Field
                            component={CounterField}
                            name={`${prefixoNome}hierarquia`}
                            label="Hierarquia"
                            dica="Selecione a hierarquia da alteração em relação aos processos geológicos observados neste afloramento"
                            elementos={hierarquias}
                            disabled={!permitirEdicao}
                            value= { formAtividade.getState().values[`${prefixoNome}hierarquia`] }
                            onChange={(value) => {
                              formAtividade.mutators.setValue(`${prefixoNome}hierarquia`, value)
                            }}
                          />
                        </Col>
                      </Row>
                      <Row className="my-3">
                        <Col md={12}>
                          <Field
                            component={TextAreaField}
                            name={`${prefixoNome}informacoesRelevantes`}
                            label="Informações relevantes"
                            dica="Caso necesário, detalhe as alterações hidrotermais observadas"
                            maxLength={250}
                            disabled={!permitirEdicao}
                          />
                        </Col>
                      </Row>
                    </>
                  )
                }}
              />
            )}
          </Field>
        </Card.Body>
      </Card>
    </div>
  );
}

// Função que gera as colunas da tabela de alterações hidrotermais, extraída para que
// possamos instanciar as colunas já inicializadas da primeira vez, ao invés de
// usar um estado [] padrão. Isso evita um rerender da tabela.
function gerarColunasAlteracoesHidrotermais(rochasPorId, tiposAlteracaoHidrotermal) {
  return [
    {
      text: 'Tipo alteração', datafield: 'idTipo', width: '20%',
      cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
        // console.log(tiposAlteracaoHidrotermal, value)
        const nome = value ? tiposAlteracaoHidrotermal.find(g => g.id === parseInt(value)).nome : '';
        return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${nome}</div>`;
      }
    },
    {
      text: "Rocha", datafield: "idRocha", width: '74%',

      cellsrenderer: function (row, columnfield, value, defaulthtml, columnproperties, rowdata) {
        let nome = '';

        try{
          const idRocha = rowdata[columnfield];
          let rocha = rochasPorId[Number(idRocha)];
          nome = rocha ? rocha.nomeExibicao : ''
        }
        catch(err) {}

        return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${nome}</div>`;
      }
    },
  ];
}

export default AlteracoesHidrotermais;
