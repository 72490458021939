import { Col, Row } from "react-bootstrap";
import { Field } from "react-final-form";
import { DateTimeInputField } from "../../../../../../components/field/DateTimeInputField";
import { DropdownListField } from "../../../../../../components/field/DropdownListField";
import { TextInputField } from "../../../../../../components/field/TextInputField";
import { campoObrigatorioComMsgGenerica } from "../../../../../../util/validadores";

type Props = {
  amostraDeBibliografia: boolean | null;
  validarCamposBibliografia: (
    valor: string | number,
    nomeCampo?: string
  ) => string | undefined;
  validarCamposNaoBibliografia: (
    valor: string | number,
    nomeCampo?: string
  ) => string | undefined;
  prefixo: string;
  unidadesLitoEstratigrafica: [];
  disabled: boolean;
  coletores: { id: number; nome: string }[];
  isChefeProjeto: boolean;
  idUsuarioAtual: number;
  carregamentoGlobal: boolean;
};

export const CamposDaParteSuperior = ({
  amostraDeBibliografia,
  validarCamposBibliografia,
  validarCamposNaoBibliografia,
  prefixo,
  unidadesLitoEstratigrafica,
  disabled,
  coletores,
  isChefeProjeto,
  idUsuarioAtual,
  carregamentoGlobal
}: Props) => {
  return (
    <>
      {amostraDeBibliografia !== null && unidadesLitoEstratigrafica.length ? (
        <>
          <Row>
            <Col md={4}>
              <Field
                disabled={true}
                label="Nome da amostra"
                dica="Nome da amostra"
                name={prefixo + `nomeAmostra`}
                validate={(valor) =>
                  validarCamposNaoBibliografia(valor, "Nome da amostra")
                }
                maxLength={255}
                component={TextInputField}
              />
            </Col>
            <Col md={4}>
            {!carregamentoGlobal &&
              <Field
                label="Nome da amostra segundo bibliografia"
                dica="Nome da amostra segundo bibliografia"
                name={prefixo + "nomeAmostraBibliografia"}
                validate={(valor) =>
                  validarCamposBibliografia(
                    valor,
                    "Nome da amostra segundo bibliografia"
                  )
                }
                disabled={!amostraDeBibliografia || disabled}
                required={amostraDeBibliografia}
                maxLength={30}
                component={TextInputField}
              />
            }
            {carregamentoGlobal &&
              <Field
                label="Nome da amostra segundo bibliografia"
                dica="Nome da amostra segundo bibliografia"
                name={prefixo + "nomeAmostraBibliografia"}
                disabled={!amostraDeBibliografia || disabled}
                required={amostraDeBibliografia}
                maxLength={30}
                component={TextInputField}
              />
            }
            </Col>
            <Col md={4}>
              <Field
                label="Data da última atualização desta ficha"
                dica="Data da última atualização desta ficha"
                name={prefixo + "dataUltimaAtualizacao"}
                disabled={true}
                component={DateTimeInputField}
              />
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <Field
                label="Unidade litoestratigráfica"
                dica="Unidade Estratigráfica conforme fonte SGB"
                name={prefixo + "idUnidadeEstratigrafica"}
                component={DropdownListField}
                disabled={disabled}
                elementos={unidadesLitoEstratigrafica}
              />
            </Col>

            <Col md={4}>
            {!carregamentoGlobal &&
              <Field
                label="Unidade litoestratigráfica segundo bibliografia"
                dica="Unidade Estratigráfica conforme fonte bibliográfica"
                name={prefixo + "unidadeLitoestratigraficaBibliografia"}
                disabled={!amostraDeBibliografia || disabled}
                validate={(valor) =>
                  validarCamposBibliografia(
                    valor,
                    "Unidade litoestratigráfica segundo bibliografia"
                  )
                }
                maxLength={30}
                required={amostraDeBibliografia}
                component={TextInputField}
              />}
              {carregamentoGlobal &&
              <Field
                label="Unidade litoestratigráfica segundo bibliografia"
                dica="Unidade Estratigráfica conforme fonte bibliográfica"
                name={prefixo + "unidadeLitoestratigraficaBibliografia"}
                disabled={!amostraDeBibliografia || disabled}
                maxLength={30}
                required={amostraDeBibliografia}
                component={TextInputField}
              />}
            </Col>
            <Col md={4}>
            {!carregamentoGlobal && <Field
                component={DropdownListField}
                name="idResponsavel"
                label="Responsável"
                dica="Responsável pela análise geocronológica. "
                disabled={!isChefeProjeto || disabled}
                elementos={coletores}
                initialValue={idUsuarioAtual}
                required
                validate={campoObrigatorioComMsgGenerica("Responsável")}
            />}
              {carregamentoGlobal && <Field
                component={DropdownListField}
                name="idResponsavel"
                label="Responsável"
                dica="Responsável pela análise geocronológica. "
                disabled={!isChefeProjeto || disabled}
                elementos={coletores}
                initialValue={idUsuarioAtual}
                required
            />}
            </Col>
          </Row>
        </>
      ) : null}
    </>
  );
};
