import React from "react"
import { FormSpy } from "react-final-form"
import { useDispatch } from "react-redux";
import { mostrarNotificacao } from "../reducers/notificacaoReducer";

const resolverMensagensErro = (obj) => {
  return Object.values(obj)
    .reduce((acc, cur) => {
      const msgs = typeof cur === 'object' || Array.isArray(cur)
        ? resolverMensagensErro(cur)
        : [cur]
      return acc.concat(msgs)
    }, [])
}

const NotificadorErrosForm = ({
  dispararNotificacoesEnvioLimpo = true
}) => {
  const dispatch = useDispatch()
  const dispatchErrors = (errors = []) => {
    const errosResolvidos = resolverMensagensErro(errors)
    for (const error of errosResolvidos) {
      dispatch(mostrarNotificacao(error))
    }
  }
  return (
    <FormSpy
      onChange={(props) => {
        const erros = Object.values(props?.errors ?? {})

        if (erros.length > 0 && props.submitFailed) {
          if (dispararNotificacoesEnvioLimpo && props.pristine) {
            dispatchErrors(erros)
          } else if (!props.submitting && !props.dirtySinceLastSubmit) {
            dispatchErrors(erros)
          }
        }
      }}
      subscription={{
        errors: true,
        submitting: true,
        submitFailed: true,
        dirtySinceLastSubmit: true,
        pristine: true,
      }}
    />
  )
}

export default NotificadorErrosForm
