import React from "react";
import { Col, Row } from "react-bootstrap";
import { Field } from "react-final-form";
import { DropdownListField } from "../../../../../components/field/DropdownListField";
import { NumberInputField } from "../../../../../components/field/NumberInputField";
import CamposComuns from "./CamposComuns";
import { resolverMultiRequestRecmin } from "../../../../../util/ResolverRequestRecmin";

class Aguaa extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      carregamento: true,
      velocidadeCorrente: [],
      nivelAgua: [],
      areaDrenagem: [],
      turbidezAgua: [],
      corAgua: [],
      corSedimento: [],
      naturezaAmostra: [],
      preservacaoAmostra: []
    }
  };

  async componentDidMount() {
    // We should implement methods like this just for "amostras".
    const responses = await resolverMultiRequestRecmin({
      velocidadeCorrente: { uri: "/amostra/agua/velocidadeCorrente", moduleName: "amostras" },
      nivelAgua: { uri: "/biblioteca/nivelagua", moduleName: "amostras" },
      areaDrenagem: { uri: "/biblioteca/areadrenagem", moduleName: "amostras" },
      turbidezAgua: { uri: "/biblioteca/turbidez", moduleName: "amostras" },
      corAgua: { uri: "/biblioteca/amostras/cor", moduleName: "amostras" },
      corSedimento: { uri: "/biblioteca/amostras/cor", moduleName: "amostras" },
      naturezaAmostra: { uri: "/amostra/agua/naturezaAmostra", moduleName: "amostras" },
      preservacaoAmostra: { uri: "/biblioteca/tipopreservacaoamostra", moduleName: "amostras" },
      posicaoLeito: { uri: "/amostra/agua/posicaoLeito", moduleName: "amostras" },
    });

    this.setState(responses)

    // console.log(await resolverRequestAmostras("/amostra/agua/posicaoLeito"))
  }


  render() {
    return <>
      <CamposComuns
        materialColetadoPreenchido={true}
        duplicata={this.props.duplicata}
        permitirEdicao={this.props.permitirEdicao}
        labelCampo="Volume da amostra (l)"
        dicaCampo="Informe o volume coletado em litros"
        profundidadeObrigatoria={false}
        informacoesRelevantesObrigatorias={true}
        prefixo={"agua"}
        visualizar={this.props.visualizar}
      />

      <Row className="mt-3">
        <Col md={4}>
          <Field
            disabled={!this.props.permitirEdicao}
            name={`agua.larguraRio`}
            component={NumberInputField}
            label="Largura do rio (m)"
            dica="Informe a largura do rio em metros, se aplicável"
            casasDecimais={4}
            casasInteiros={4}
            permitirNegativos={false}
          />
        </Col>
        <Col md={4}>
          <Field
            disabled={!this.props.permitirEdicao}
            name="agua.profundidadeRio"
            component={NumberInputField}
            label="Profundidade do rio (m)"
            dica="Informe a profundidade do rio em metros,se aplicável"
            casasDecimais={4}
            casasInteiros={4}
            permitirNegativos={false}
          />
        </Col>
        <Col md={4}>
          <Field
            disabled={!this.props.permitirEdicao}
            name="agua.idVelocidadeCorrente"
            component={DropdownListField}
            label="Velocidade da corrente"
            dica="Selecione a velocidade da corrente, se aplicável"
            elementos={this.state.velocidadeCorrente}
          />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col md={4}>
          <Field
            disabled={!this.props.permitirEdicao}
            name="agua.idNivelAgua"
            component={DropdownListField}
            label="Nível da água"
            dica="Selecione o nível d'água da drenagem, se aplicável"
            elementos={this.state.nivelAgua}
          />
        </Col>
        <Col md={4}>
          <Field
            disabled={!this.props.permitirEdicao}
            name="agua.idAreaDrenagem"
            component={DropdownListField}
            label="Área de drenagem"
            dica="Selecione a área da bacia de drenagem, se aplicável"
            elementos={this.state.areaDrenagem}
          />
        </Col>
        <Col md={4}>
          <Field
            disabled={!this.props.permitirEdicao}
            name="agua.idTurbidezAgua"
            component={DropdownListField}
            label="Turbidez da água"
            dica="Selecione o nível de turbidez da água no momento da amostragem, se aplicável"
            elementos={this.state.turbidezAgua}
          />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col md={4}>
          <Field
            disabled={!this.props.permitirEdicao}
            name="agua.idPosicaoLeito"
            component={DropdownListField}
            label="Posição do leito"
            dica="Selecione a posição da amostra no leito do rio, se aplicável"
            elementos={this.state.posicaoLeito}
          />
        </Col>
        <Col md={4}>
          <Field
            disabled={!this.props.permitirEdicao}
            name="agua.idCorAgua"
            component={DropdownListField}
            label="Cor da água"
            dica="Selecione a cor da água, se aplicável"
            elementos={this.state.corAgua}
          />
        </Col>
        <Col md={4}>
          <Field
            disabled={!this.props.permitirEdicao}
            name="agua.idCorSedimento"
            component={DropdownListField}
            label="Cor do sedimento"
            dica="Selecione a cor do sedimento observado junto à água, se aplicável"
            elementos={this.state.corSedimento}
          />
        </Col>
      </Row>
      <Row className="mt-3 mb-3">
        <Col md={6}>
          <Field
            disabled={!this.props.permitirEdicao}
            name="agua.idNaturezaAmostra"
            component={DropdownListField}
            label="Natureza da amostra"
            dica="Selecione a natureza da amostra"
            elementos={this.state.naturezaAmostra}
          />
        </Col>
        <Col md={6}>
          <Field
            disabled={!this.props.permitirEdicao}
            name="agua.idPreservacaoAmostra"
            component={DropdownListField}
            label="Preservação da amostra"
            dica="Selecione o tipo de preservação aplicado à amostra, se apllicável"
            elementos={this.state.preservacaoAmostra}
          />
        </Col>
      </Row>
    </>
  }
}

export default Aguaa;
