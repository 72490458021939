import React, { useCallback, useEffect, useRef } from "react";
import Form from "react-bootstrap/Form";
import PropTypes from "prop-types";
import Erro from "../Erro";
import Hint from "../hint/Hint";
import JqxPasswordInput from "jqwidgets-scripts/jqwidgets-react-tsx/jqxpasswordinput";

const PasswordInput = (props) => {
  const {
    required, dica, label, valor,
    mensagemErro, onChange, placeholder,
    ...rest
  } = props;

  const ref = useRef(null);

  const placeHolder = placeholder ? placeholder : ''
  const onChangeCallback = useCallback((evt) => {
    onChange && onChange(evt)
  }, [onChange])

  const listener = (evt) => {
    onChangeCallback(evt)
  }

  const setBlur = useCallback((element, type = "add") => {
    switch (type) {
      case "remove": element.removeEventListener("blur", listener, false); break;
      default: element.addEventListener("blur", listener, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref])


  useEffect(() => {
    const id = ref.current._id;
    const element = document.getElementById(id);

    //Adiciona o evento de blur sempre que o componente é inicializado.
    ref && setBlur(element, "add");

    return () => {
      //Remove evento de blur do componente ao sair da pagina.
      ref && setBlur(element, "remove");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref])


  return <>
    {label && <Form.Label className="small">
      {label}
      {required && <span style={{ color: "red" }}>*</span>}
      {dica && <Hint textoAjuda={dica} />}
    </Form.Label>}
    <JqxPasswordInput
      ref={ref}
      width={"100%"}
      theme="bootstrap"
      onChange={onChangeCallback}
      placeHolder={placeHolder}
      value={valor}
      {...rest}
    />
    <Erro mensagem={mensagemErro} />
  </>;
}

PasswordInput.propTypes = {
  valor: PropTypes.string,
  label: PropTypes.string,
  mensagemErro: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  dica: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func,
};

PasswordInput.defaultProps = {
};

export { PasswordInput };
