import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types"
import Col from "react-bootstrap/Col";
import { Field } from "react-final-form";
import { RadioButtonSetField } from "../../../../components/field/RadioButtonSetField";
import {
  HEMISFERIO_LESTE,
  HEMISFERIO_NORTE,
  HEMISFERIO_OESTE,
  HEMISFERIO_SUL,
  TIPO_ENTRADA_COORDS_DECIMAL,
  TIPO_ENTRADA_COORDS_GRAUS,
  TIPO_ENTRADA_COORDS_UTM
} from "../../../../util/constantes";
import NumberInputField from "../../../../components/field/NumberInputField/NumberInputField";
import DropdownListField from "../../../../components/field/DropdownListField/DropdownListField";
import Row from "react-bootstrap/Row";
import { campoNumericoIntervaloDefinidoObrigatorio, campoObrigatorioComMsgGenerica, validarSe } from "../../../../util/validadores";

import './SeletorCoordenadasGeograficas.css';
import { getPropsPorHemisferio, getPropsPorHemisferioHorizontal } from "../../../../util/decoradores/decoradorSeletorCoordenadas";

const idDatumDefault = 4;

const hemisferios = [
  HEMISFERIO_SUL,
  HEMISFERIO_NORTE,
];
const hemisferioHorizontais = [
  HEMISFERIO_LESTE,
  HEMISFERIO_OESTE,
];

const tiposEntradaCoords = [
  TIPO_ENTRADA_COORDS_DECIMAL,
  TIPO_ENTRADA_COORDS_UTM,
  TIPO_ENTRADA_COORDS_GRAUS,
]

const getNomeCampo = (nome, { prefixo = '' } = {}) => {
  return prefixo ? `${prefixo}.${nome}` : nome
}
const CampoFormEh = ({ nome, valor, children }) => {
  return (
    <>
      <Field name={nome} subscription={{ value: true }}>
        {({ input: { value } }) => {
          return children(value === valor)
        }}
      </Field>
    </>
  )
}

const SeletorCoordenadasGeograficas = ({ eEdicao, prefixoCampos: prefixo, datums, permitirObrigatoriedade, eDefinicaoEstcao, permitirEdicao, ...props }) => {
 
  let datumInicial = useMemo(() => datums && datums.find((d) => d.id === idDatumDefault) ? idDatumDefault : undefined, [datums]);
  
  if(props.pontosGPSpendentes && props.pontosGPSpendentes.length && datums && datums.length){
    if(datums.find((d) => d.id === props.pontosGPSpendentes[0].idDatum))
      datumInicial = datums.find((d) => d.id === props.pontosGPSpendentes[0].idDatum).id;
  }
  
  const [escondeMensagemLongitude, setEscondeMensagemLongitude] = useState(true)
  const [valorGrauLongitude, setValorGrauLongitude] = useState(null)
  const [valorMinutoLongitude, setValorMinutoLongitude] = useState(null)
  const [valorSegundoLongitude, setValorSegundoLongitude] = useState(null)
  const mensagem = "O valor inserido é uma coordenada na borda e na conversão para UTM pode gerar múltiplos fusos, para melhor precisão será necessário atribuir o fuso manualmente."
  const classNameSelecao = "jqx-dropdownlist jqx-widget jqx-dropdownlist-state-normal jqx-rc-all jqx-fill-state-normal jqx-default jqx-dropdownlist-bootstrap jqx-widget-bootstrap jqx-dropdownlist-state-normal-bootstrap jqx-rc-all-bootstrap jqx-fill-state-normal-bootstrap jqx-default-bootstrap"

  useEffect(() => {
    if (Number.isInteger(valorGrauLongitude / 6) && valorMinutoLongitude === 0 && valorSegundoLongitude === 0)
      setEscondeMensagemLongitude(false)
    else
      setEscondeMensagemLongitude(true)

  }, [valorGrauLongitude, valorMinutoLongitude, valorSegundoLongitude])

  function esconderMensagem(element) {
    if (Number.isInteger(element / 6))
      setEscondeMensagemLongitude(false)
    else
      setEscondeMensagemLongitude(true)
  }

  function aoTrocarEntrada(element) {
    if (element?.id === TIPO_ENTRADA_COORDS_UTM.id) {
      setEscondeMensagemLongitude(true)
      setValorGrauLongitude(null)
      setValorMinutoLongitude(null)
      setValorSegundoLongitude(null)
    }
  }

  return (
    <>
      <Col md={12}>
        <Row>
          {eDefinicaoEstcao ? (
            <>
              <Col md={4} style={{ padding: 0, width: '25.6%' }}>
                <Row>
                  <Col md={7} className="seletor-coordenadas-hemisferio">
                    <Field
                      component={RadioButtonSetField}
                      name={getNomeCampo('hemisferio', { prefixo })}
                      elementos={hemisferios}
                      required={permitirObrigatoriedade}
                      disabled={!permitirEdicao}
                      readOnly={eEdicao}
                      validate={validarSe(permitirObrigatoriedade, campoObrigatorioComMsgGenerica("Hemisfério"))}
                      {...props}
                    />
                    <Field
                      component={RadioButtonSetField}
                      name={getNomeCampo('hemisferioHorizontal', { prefixo })}
                      elementos={hemisferioHorizontais}
                      required={permitirObrigatoriedade}
                      disabled={!permitirEdicao}
                      readOnly={eEdicao}
                      validate={validarSe(permitirObrigatoriedade, campoObrigatorioComMsgGenerica("Hemisfério"))}
                      {...props}
                    />
                  </Col>
                  <Col md={5}>
                    {/*Forma de entrada de coordenada geográfica*/}
                    <Field
                      component={RadioButtonSetField}
                      name={getNomeCampo('tipoEntrada', { prefixo })}
                      elementos={tiposEntradaCoords}
                      required={permitirObrigatoriedade}
                      disabled={!permitirEdicao}
                      readOnly={eEdicao}
                      initialValue={tiposEntradaCoords[0]}
                      validate={validarSe(permitirObrigatoriedade, campoObrigatorioComMsgGenerica("Tipo entrada"))}
                      componentstate={aoTrocarEntrada}
                    />
                  </Col>
                </Row>
              </Col>
              <Col md={8}>
                <Row>
                  <CampoFormEh
                    nome={getNomeCampo('tipoEntrada', { prefixo })}
                    valor={TIPO_ENTRADA_COORDS_DECIMAL.id}>
                    {exibir => exibir && (
                      <>
                        {/* https://en.wikipedia.org/wiki/Decimal_degrees */}
                        <Col>
                          <Field name={getNomeCampo('hemisferioHorizontal', { prefixo })} subscription={{ value: true }}>
                            {({ input: { value: hemisferioHorizontal } }) => {
                              const propsLongitude = getPropsPorHemisferioHorizontal(hemisferioHorizontal);
                              console.log('propsLongitude', hemisferioHorizontal, propsLongitude)

                              return (<>
                                <Field
                                  component={NumberInputField}
                                  name={getNomeCampo('longitude', { prefixo })}
                                  label="Longitude"
                                  dica="Longitude"
                                  casasDecimais={6}
                                  useOldInput={true}
                                  className="aflora-number-input aflora-field-longitude"
                                  // initialValue={0}
                                  readOnly={eEdicao}
                                  disabled={!permitirEdicao}
                                  required={permitirObrigatoriedade && exibir}
                                  validate={validarSe(permitirObrigatoriedade && exibir, campoObrigatorioComMsgGenerica("Longitude", propsLongitude.min, propsLongitude.max))}
                                  componentstate={esconderMensagem}
                                  usarOnchange={false}
                                  {...propsLongitude}
                                />
                                <span className="mensagem-erro" hidden={escondeMensagemLongitude}>{mensagem}</span>
                              </>);
                            }}
                          </Field>
                        </Col>
                        <Col>
                          <Field name={getNomeCampo('hemisferio', { prefixo })} subscription={{ value: true, submitting: true }}>
                            {({ input: { value: hemisferio } }) => {
                              const propsLatitude = getPropsPorHemisferio(hemisferio);
                              console.log('propsLatitude', hemisferio, propsLatitude, validarSe(permitirObrigatoriedade && exibir, campoNumericoIntervaloDefinidoObrigatorio("Latitude", propsLatitude.min, propsLatitude.max)))

                              return (
                                <Field
                                  component={NumberInputField}
                                  name={getNomeCampo('latitude', { prefixo })}
                                  label="Latitude"
                                  dica="Latitude"
                                  casasDecimais={6}
                                  useOldInput={true}
                                  className="aflora-number-input aflora-field-latitude"
                                  // initialValue={0}
                                  readOnly={eEdicao}
                                  disabled={!permitirEdicao}
                                  required={permitirObrigatoriedade && exibir}
                                  validate={validarSe(permitirObrigatoriedade && exibir, campoNumericoIntervaloDefinidoObrigatorio("Latitude", propsLatitude.min, propsLatitude.max))}
                                  usarOnchange={true}
                                  {...propsLatitude}
                                />
                              );
                            }}
                          </Field>
                        </Col>
                        <Col>
                          <Field
                            component={DropdownListField}
                            name={getNomeCampo('idDatum', { prefixo })}
                            className={`${classNameSelecao} aflora-selecao`}
                            label="Datum"
                            dica="Datum"
                            elementos={datums}
                            required={permitirObrigatoriedade && exibir}
                            disabled={!permitirEdicao}
                            initialValue={datumInicial}
                            validate={validarSe(permitirObrigatoriedade && exibir, campoObrigatorioComMsgGenerica("Datum"))}
                          />
                        </Col>
                      </>
                    )}
                  </CampoFormEh>

                  <CampoFormEh
                    nome={getNomeCampo('tipoEntrada', { prefixo })}
                    valor={TIPO_ENTRADA_COORDS_UTM.id}>
                    {exibir => exibir && (
                      <>
                        {/* https://www.maptools.com/tutorials/utm/details */}
                        {/* https://www.e-education.psu.edu/natureofgeoinfo/c2_p23.html */}
                        <Col>
                          <Field
                            component={NumberInputField}
                            name={getNomeCampo('x', { prefixo })}
                            label="X"
                            dica="X"
                            min={160000}
                            max={834000}
                            useOldInput={true}
                            readOnly={eEdicao}
                            disabled={!permitirEdicao}
                            required={permitirObrigatoriedade && exibir}
                            // validate={valorNumericoEntre({
                            //   min: 160000,
                            //   max: 834000,
                            // })}
                            validate={validarSe(permitirObrigatoriedade && exibir, campoObrigatorioComMsgGenerica("X"))}
                            casasDecimais={1}
                            usarOnchange={true}
                          />
                        </Col>
                        <Col>
                          <Field
                            component={NumberInputField}
                            name={getNomeCampo('y', { prefixo })}
                            label="Y"
                            dica="Y"
                            min={0}
                            max={10000000}
                            useOldInput={true}
                            readOnly={eEdicao}
                            disabled={!permitirEdicao}
                            required={permitirObrigatoriedade && exibir}
                            validate={validarSe(permitirObrigatoriedade && exibir, campoObrigatorioComMsgGenerica("Y"))}
                            casasDecimais={1}
                            usarOnchange={true}
                          />
                        </Col>
                        <Col>
                          <Field
                            component={NumberInputField}
                            name={getNomeCampo('fuso', { prefixo })}
                            label="Fuso"
                            dica="Fuso"
                            min={0}
                            max={60}
                            useOldInput={true}
                            readOnly={eEdicao}
                            disabled={!permitirEdicao}
                            required={permitirObrigatoriedade && exibir}
                            displayMember={'codigo'}
                            validate={validarSe(permitirObrigatoriedade && exibir, campoObrigatorioComMsgGenerica("Fuso"))}
                            permitirNegativos={false}
                            usarOnchange={true}
                          />
                        </Col>
                        <Col>
                          <Field
                            component={DropdownListField}
                            name={getNomeCampo('idDatum', { prefixo })}
                            label="Datum"
                            dica="Datum"
                            elementos={datums}
                            disabled={!permitirEdicao}
                            readOnly={eEdicao}
                            required={permitirObrigatoriedade && exibir}
                            initialValue={datumInicial}
                            validate={validarSe(permitirObrigatoriedade && exibir, campoObrigatorioComMsgGenerica("Datum"))}
                          />
                        </Col>
                      </>
                    )}
                  </CampoFormEh>

                  <CampoFormEh
                    nome={getNomeCampo('tipoEntrada', { prefixo })}
                    valor={TIPO_ENTRADA_COORDS_GRAUS.id}>
                    {exibir => exibir && (
                      <>
                        <Col md={3}>
                          <Field
                            component={NumberInputField}
                            name={getNomeCampo('grauLongitude', { prefixo })}
                            label="Grau longitude"
                            dica="Grau longitude"
                            min={-180}
                            max={180}
                            useOldInput={true}
                            readOnly={eEdicao}
                            disabled={!permitirEdicao}
                            required={permitirObrigatoriedade && exibir}
                            validate={validarSe(permitirObrigatoriedade && exibir, campoObrigatorioComMsgGenerica("Grau longitude"))}
                            // componentstate={setValorGrauLongitude}
                            usarOnchange={true}
                          />
                          <span className="mensagem-erro" hidden={escondeMensagemLongitude}>{mensagem}</span>
                        </Col>
                        <Col md={3}>
                          <Field
                            component={NumberInputField}
                            name={getNomeCampo('minutoLongitude', { prefixo })}
                            label="Minuto longitude"
                            dica="Minuto longitude"
                            min={0}
                            max={59}
                            readOnly={eEdicao}
                            useOldInput={true}
                            disabled={!permitirEdicao}
                            required={permitirObrigatoriedade && exibir}
                            validate={validarSe(permitirObrigatoriedade && exibir, campoObrigatorioComMsgGenerica("Minuto longitude"))}
                            // componentstate={setValorMinutoLongitude}
                            usarOnchange={true}
                          />
                        </Col>
                        <Col md={3}>
                          <Field
                            component={NumberInputField}
                            name={getNomeCampo('segundoLongitude', { prefixo })}
                            label="Segundo longitude"
                            dica="Segundo longitude"
                            casasDecimais={2}
                            min={0}
                            max={59.99}
                            useOldInput={true}
                            readOnly={eEdicao}
                            disabled={!permitirEdicao}
                            required={permitirObrigatoriedade && exibir}
                            validate={validarSe(permitirObrigatoriedade && exibir, campoObrigatorioComMsgGenerica("Segundo longitude"))}
                            // componentstate={setValorSegundoLongitude}
                            usarOnchange={true}
                          />
                        </Col>
                        <Col md={3}>
                          <Field
                            component={DropdownListField}
                            name={getNomeCampo('idDatum', { prefixo })}
                            label="Datum"
                            dica="Datum"
                            elementos={datums}
                            disabled={!permitirEdicao}
                            readOnly={eEdicao}
                            required={permitirObrigatoriedade && exibir}
                            initialValue={datumInicial}
                            validate={validarSe(permitirObrigatoriedade && exibir, campoObrigatorioComMsgGenerica("Datum"))}
                          />
                        </Col>
                        <Col md={3}>
                          <Field
                            component={NumberInputField}
                            name={getNomeCampo('grauLatitude', { prefixo })}
                            label="Grau latitude"
                            dica="Grau latitude"
                            min={-90}
                            max={90}
                            useOldInput={true}
                            disabled={!permitirEdicao}
                            readOnly={eEdicao}
                            required={permitirObrigatoriedade && exibir}
                            validate={validarSe(permitirObrigatoriedade && exibir, campoObrigatorioComMsgGenerica("Grau latitude"))}
                            usarOnchange={true}
                          />
                        </Col>
                        <Col md={3}>
                          <Field
                            component={NumberInputField}
                            name={getNomeCampo('minutoLatitude', { prefixo })}
                            label="Minuto latitude"
                            dica="Minuto latitude"
                            min={0}
                            max={59}
                            useOldInput={true}
                            disabled={!permitirEdicao}
                            readOnly={eEdicao}
                            required={permitirObrigatoriedade && exibir}
                            validate={validarSe(permitirObrigatoriedade && exibir, campoObrigatorioComMsgGenerica("Minuto latitude"))}
                            usarOnchange={true}
                          />
                        </Col>
                        <Col md={3}>
                          <Field
                            component={NumberInputField}
                            name={getNomeCampo('segundoLatitude', { prefixo })}
                            label="Segundo latitude"
                            dica="Segundo latitude"
                            casasDecimais={2}
                            min={0}
                            max={59.99}
                            useOldInput={true}
                            readOnly={eEdicao}
                            disabled={!permitirEdicao}
                            required={permitirObrigatoriedade && exibir}
                            validate={validarSe(permitirObrigatoriedade && exibir, campoObrigatorioComMsgGenerica("Segundo latitude"))}
                            usarOnchange={true}
                          />
                        </Col>
                      </>
                    )}
                  </CampoFormEh>
                </Row>
              </Col>
            </>
          ) : (
            <>
              <div style={{ padding: 0, width: '25.6%' }}>
                <Row>
                  <Col md={7} className="seletor-coordenadas-hemisferio">
                    <Field
                      component={RadioButtonSetField}
                      name={getNomeCampo('hemisferio', { prefixo })}
                      elementos={hemisferios}
                      required={permitirObrigatoriedade}
                      disabled={!permitirEdicao}
                      readOnly={eEdicao}
                      validate={validarSe(permitirObrigatoriedade, campoObrigatorioComMsgGenerica("Hemisfério"))}
                      {...props}
                    />
                    <Field
                      component={RadioButtonSetField}
                      name={getNomeCampo('hemisferioHorizontal', { prefixo })}
                      elementos={hemisferioHorizontais}
                      required={permitirObrigatoriedade}
                      disabled={!permitirEdicao}
                      readOnly={eEdicao}
                      validate={validarSe(permitirObrigatoriedade, campoObrigatorioComMsgGenerica("Hemisfério"))}
                      {...props}
                    />
                  </Col>
                  <Col md={5}>
                    {/*Forma de entrada de coordenada geográfica*/}
                    <Field
                      component={RadioButtonSetField}
                      name={getNomeCampo('tipoEntrada', { prefixo })}
                      elementos={tiposEntradaCoords}
                      required={permitirObrigatoriedade}
                      disabled={!permitirEdicao}
                      readOnly={eEdicao}
                      initialValue={tiposEntradaCoords[0]}
                      validate={validarSe(permitirObrigatoriedade, campoObrigatorioComMsgGenerica("Tipo entrada"))}
                      componentstate={aoTrocarEntrada}
                    />
                  </Col>
                </Row>
              </div>

              <Col style={{ padding: 0, width: '74%' }}>
                <Row>
                  <CampoFormEh
                    nome={getNomeCampo('tipoEntrada', { prefixo })}
                    valor={TIPO_ENTRADA_COORDS_DECIMAL.id}>
                    {exibir => exibir && (
                      <>
                        {/* https://en.wikipedia.org/wiki/Decimal_degrees */}
                        <Col>
                          <Field name={getNomeCampo('hemisferioHorizontal', { prefixo })} subscription={{ value: true }}>
                            {({ input: { value: hemisferioHorizontal } }) => {
                              const propsLongitude = getPropsPorHemisferioHorizontal(hemisferioHorizontal);
                              // console.log('propsLongitude', hemisferioHorizontal, propsLongitude)

                              return (<>
                                <Field
                                  component={NumberInputField}
                                  name={getNomeCampo('longitude', { prefixo })}
                                  label="Longitude"
                                  dica="Coordenada geográfica que especifica a posição leste-oeste"
                                  casasDecimais={6}
                                  useOldInput={true}
                                  className="aflora-number-input aflora-field-longitude"
                                  // initialValue={0}
                                  readOnly={eEdicao}
                                  disabled={!permitirEdicao}
                                  required={permitirObrigatoriedade && exibir}
                                  validate={validarSe(permitirObrigatoriedade && exibir, campoObrigatorioComMsgGenerica("Longitude", propsLongitude.min, propsLongitude.max))}
                                  componentstate={esconderMensagem}
                                  usarOnchange={false}
                                  {...propsLongitude}
                                />
                                <span className="mensagem-erro" hidden={escondeMensagemLongitude}>{mensagem}</span>
                              </>);
                            }}
                          </Field>
                        </Col>
                        <Col>
                          <Field name={getNomeCampo('hemisferio', { prefixo })} subscription={{ value: true, submitting: true }}>
                            {({ input: { value: hemisferio } }) => {
                              const propsLatitude = getPropsPorHemisferio(hemisferio);
                              // console.log('propsLatitude', hemisferio, propsLatitude, validarSe(permitirObrigatoriedade && exibir, campoNumericoIntervaloDefinidoObrigatorio("Latitude", propsLatitude.min, propsLatitude.max)))

                              return (
                                <Field
                                  component={NumberInputField}
                                  name={getNomeCampo('latitude', { prefixo })}
                                  label="Latitude"
                                  dica="Coordenada geográfica que especifica a posição norte-sul"
                                  casasDecimais={6}
                                  useOldInput={true}
                                  className="aflora-number-input aflora-field-latitude"
                                  // initialValue={0}
                                  readOnly={eEdicao}
                                  disabled={!permitirEdicao}
                                  required={permitirObrigatoriedade && exibir}
                                  validate={validarSe(permitirObrigatoriedade && exibir, campoNumericoIntervaloDefinidoObrigatorio("Latitude", propsLatitude.min, propsLatitude.max))}
                                  usarOnchange={false}
                                  {...propsLatitude}
                                />
                              );
                            }}
                          </Field>
                        </Col>
                        <Col>
                          <Field
                            component={DropdownListField}
                            name={getNomeCampo('idDatum', { prefixo })}
                            className={`${classNameSelecao} aflora-selecao`}
                            label="Datum"
                            dica="Escolha o Datum utilizado no momento de aquisição das coordenadas"
                            elementos={datums}
                            required={permitirObrigatoriedade && exibir}
                            disabled={!permitirEdicao}
                            initialValue={datumInicial}
                            validate={validarSe(permitirObrigatoriedade && exibir, campoObrigatorioComMsgGenerica("Datum"))}
                          />
                        </Col>
                      </>
                    )}
                  </CampoFormEh>

                  <CampoFormEh
                    nome={getNomeCampo('tipoEntrada', { prefixo })}
                    valor={TIPO_ENTRADA_COORDS_UTM.id}>
                    {exibir => exibir && (
                      <>
                        {/* https://www.maptools.com/tutorials/utm/details */}
                        {/* https://www.e-education.psu.edu/natureofgeoinfo/c2_p23.html */}
                        <Col>
                          <Field
                            component={NumberInputField}
                            name={getNomeCampo('x', { prefixo })}
                            label="X"
                            dica="X"
                            min={160000}
                            max={834000}
                            useOldInput={true}
                            readOnly={eEdicao}
                            disabled={!permitirEdicao}
                            required={permitirObrigatoriedade && exibir}
                            // validate={valorNumericoEntre({
                            //   min: 160000,
                            //   max: 834000,
                            // })}
                            validate={validarSe(permitirObrigatoriedade && exibir, campoObrigatorioComMsgGenerica("X"))}
                            casasDecimais={1}
                            usarOnchange={true}
                          />
                        </Col>
                        <Col>
                          <Field
                            component={NumberInputField}
                            name={getNomeCampo('y', { prefixo })}
                            label="Y"
                            dica="Y"
                            min={0}
                            max={10000000}
                            useOldInput={true}
                            readOnly={eEdicao}
                            disabled={!permitirEdicao}
                            required={permitirObrigatoriedade && exibir}
                            validate={validarSe(permitirObrigatoriedade && exibir, campoObrigatorioComMsgGenerica("Y"))}
                            casasDecimais={1}
                            usarOnchange={true}
                          />
                        </Col>
                        <Col>
                          <Field
                            component={NumberInputField}
                            name={getNomeCampo('fuso', { prefixo })}
                            label="Fuso"
                            dica="Fuso"
                            min={0}
                            max={60}
                            useOldInput={true}
                            readOnly={eEdicao}
                            disabled={!permitirEdicao}
                            required={permitirObrigatoriedade && exibir}
                            displayMember={'codigo'}
                            validate={validarSe(permitirObrigatoriedade && exibir, campoObrigatorioComMsgGenerica("Fuso"))}
                            permitirNegativos={false}
                            usarOnchange={true}
                          />
                        </Col>
                        <Col>
                          <Field
                            component={DropdownListField}
                            name={getNomeCampo('idDatum', { prefixo })}
                            label="Datum"
                            dica="Datum"
                            elementos={datums}
                            disabled={!permitirEdicao}
                            readOnly={eEdicao}
                            required={permitirObrigatoriedade && exibir}
                            initialValue={datumInicial}
                            validate={validarSe(permitirObrigatoriedade && exibir, campoObrigatorioComMsgGenerica("Datum"))}
                          />
                        </Col>
                      </>
                    )}
                  </CampoFormEh>

                  <CampoFormEh
                    nome={getNomeCampo('tipoEntrada', { prefixo })}
                    valor={TIPO_ENTRADA_COORDS_GRAUS.id}>
                    {exibir => exibir && (
                      <>
                        <Col md={3}>
                          <Field
                            component={NumberInputField}
                            name={getNomeCampo('grauLongitude', { prefixo })}
                            label="Grau longitude"
                            dica="Grau longitude"
                            min={-180}
                            max={180}
                            useOldInput={true}
                            readOnly={eEdicao}
                            disabled={!permitirEdicao}
                            required={permitirObrigatoriedade && exibir}
                            validate={validarSe(permitirObrigatoriedade && exibir, campoObrigatorioComMsgGenerica("Grau longitude"))}
                            // componentstate={setValorGrauLongitude}
                            usarOnchange={true}
                          />
                          <span className="mensagem-erro" hidden={escondeMensagemLongitude}>{mensagem}</span>
                        </Col>
                        <Col md={3}>
                          <Field
                            component={NumberInputField}
                            name={getNomeCampo('minutoLongitude', { prefixo })}
                            label="Minuto longitude"
                            dica="Minuto longitude"
                            min={0}
                            max={59}
                            useOldInput={true}
                            readOnly={eEdicao}
                            disabled={!permitirEdicao}
                            required={permitirObrigatoriedade && exibir}
                            validate={validarSe(permitirObrigatoriedade && exibir, campoObrigatorioComMsgGenerica("Minuto longitude"))}
                            // componentstate={setValorMinutoLongitude}
                            usarOnchange={true}
                          />
                        </Col>
                        <Col md={3}>
                          <Field
                            component={NumberInputField}
                            name={getNomeCampo('segundoLongitude', { prefixo })}
                            label="Segundo longitude"
                            dica="Segundo longitude"
                            casasDecimais={2}
                            min={0}
                            max={59.99}
                            useOldInput={true}
                            readOnly={eEdicao}
                            disabled={!permitirEdicao}
                            required={permitirObrigatoriedade && exibir}
                            validate={validarSe(permitirObrigatoriedade && exibir, campoObrigatorioComMsgGenerica("Segundo longitude"))}
                            // componentstate={setValorSegundoLongitude}
                            usarOnchange={true}
                          />
                        </Col>
                        <Col md={3}>
                          <Field
                            component={DropdownListField}
                            name={getNomeCampo('idDatum', { prefixo })}
                            label="Datum"
                            dica="Datum"
                            elementos={datums}
                            disabled={!permitirEdicao}
                            readOnly={eEdicao}
                            required={permitirObrigatoriedade && exibir}
                            initialValue={datumInicial}
                            validate={validarSe(permitirObrigatoriedade && exibir, campoObrigatorioComMsgGenerica("Datum"))}
                          />
                        </Col>
                        <Col md={3}>
                          <Field
                            component={NumberInputField}
                            name={getNomeCampo('grauLatitude', { prefixo })}
                            label="Grau latitude"
                            dica="Grau latitude"
                            min={-90}
                            max={90}
                            useOldInput={true}
                            disabled={!permitirEdicao}
                            readOnly={eEdicao}
                            required={permitirObrigatoriedade && exibir}
                            validate={validarSe(permitirObrigatoriedade && exibir, campoObrigatorioComMsgGenerica("Grau latitude"))}
                            usarOnchange={true}
                          />
                        </Col>
                        <Col md={3}>
                          <Field
                            component={NumberInputField}
                            name={getNomeCampo('minutoLatitude', { prefixo })}
                            label="Minuto latitude"
                            dica="Minuto latitude"
                            min={0}
                            max={59}
                            useOldInput={true}
                            disabled={!permitirEdicao}
                            readOnly={eEdicao}
                            required={permitirObrigatoriedade && exibir}
                            validate={validarSe(permitirObrigatoriedade && exibir, campoObrigatorioComMsgGenerica("Minuto latitude"))}
                            usarOnchange={true}
                          />
                        </Col>
                        <Col md={3}>
                          <Field
                            component={NumberInputField}
                            name={getNomeCampo('segundoLatitude', { prefixo })}
                            label="Segundo latitude"
                            dica="Segundo latitude"
                            casasDecimais={2}
                            min={0}
                            max={59.99}
                            useOldInput={true}
                            readOnly={eEdicao}
                            disabled={!permitirEdicao}
                            required={permitirObrigatoriedade && exibir}
                            validate={validarSe(permitirObrigatoriedade && exibir, campoObrigatorioComMsgGenerica("Segundo latitude"))}
                            usarOnchange={true}
                          />
                        </Col>
                      </>
                    )}
                  </CampoFormEh>
                </Row>
              </Col>
            </>
          )}
        </Row>
      </Col>
    </>
  )
}

SeletorCoordenadasGeograficas.propTypes = {
  prefixoCampos: PropTypes.string,
  datums: PropTypes.array,
  permitirObrigatoriedade: PropTypes.bool,
  permitirEdicao: PropTypes.bool,
}

SeletorCoordenadasGeograficas.defaultProps = {
  prefixoCampos: '',
  permitirObrigatoriedade: true,
  permitirEdicao: true,
}

export default SeletorCoordenadasGeograficas
