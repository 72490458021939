import React from "react"
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Field, useForm } from "react-final-form";
import { aoEnviarSubForm } from "../../../util/mutadores";
import Card from "../../../components/card";
import { TextAreaField } from "../../../components/field/TextAreaField";
import { SubForm } from "../../../components/SubForm";
import { DropdownListField } from "../../../components/field/DropdownListField";
import { campoObrigatorioComMsgGenerica } from "../../../util/validadores";
import { RadioButtonWithFieldSetField } from "../../../components/field/RadioButtonSetField";
import { NumberInputField } from "../../../components/field/NumberInputField";
import RadioFieldWithDropdown from "./RadioFieldWithDropdown";

import { FORM_ERROR } from "final-form";
import { getPropsPermissao, resolverExibidos } from "../../../components/SubForm/SubForm";
import { toInteger } from "lodash";

const ComposicaoMineralogica = ({
    permitirEdicao,
    bbMinerais,
    bbUnidadeMedida,
    somaTotalPercentuais,
    podeExcluir,
    disabled,
}) => {
    const form = useForm();
    const ajustarValoresAntesDoEnvio = (values) => {
        values?.exibidos.forEach(element => {
            if (!element?.numeroPercentual) {
                element.acessorio = true;
            }

        });
        return values;
    };
    const onSubmit = aoEnviarSubForm(form);

    const colunasComposicaoMineralogica = [
        {
            text: "Mineral", datafield: "idMineral", width: '19%',
            cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
                const microestruturasId = parseInt(rowdata?.idMineral, 10);
                const objetoFiltrado = bbMinerais.find(elemento => elemento.id === microestruturasId);
                const valor = objetoFiltrado ? objetoFiltrado.nome : '';
                return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${valor}</div>`;
            }
        },
        {
            text: "Compõe o nome da rocha", datafield: "compoeNomeRocha", width: '19%',
            cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
                let valor = ""

                if (value === 'N') {
                    valor = 'Não'
                } else {
                    valor = 'Sim'
                }

                return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${valor}</div>`;
            }
        },
        {
            text: "Percentual", datafield: "numeroPercentual", width: '19%',
            cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
                let valor = "";
                if (value === 0 || value === "0") {
                    valor = "Acessório";
                } else {
                    valor = value;
                }
                return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${valor}</div>`;
            }
        },
        {
            text: "Tamanho mínimo", datafield: "numeroTamanhoMinimo", width: '19%',
            cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
                const numeroTamanhoMinimo = bbUnidadeMedida.find(elemento => elemento.id === rowdata?.idUnidadeComprimento);
                const valorMinimo = value + (numeroTamanhoMinimo?.sigla ?? "");
                return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${valorMinimo}</div>`;
            }
        },
        {
            text: "Tamanho máximo", datafield: "numeroTamanhoMaximo", width: '19%',
            cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
                const numeroTamanhoMaximo = bbUnidadeMedida.find(elemento => elemento.id === rowdata?.idUnidadeComprimento);
                const valorMaximo = value + (numeroTamanhoMaximo?.sigla ?? "");
                return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${valorMaximo}</div>`;
            }
        },
    ]
    const camposComposicaoMineralogica = [
        { name: "idMineral", type: "string", map: "idMineral" },
        { name: "numeroPercentual", type: "number", map: "numeroPercentual" },
        { name: "numeroTamanhoMinimo", type: "string", map: "numeroTamanhoMinimo" },
        { name: "numeroTamanhoMaximo", type: "string", map: "numeroTamanhoMaximo" },
        { name: "acessorio", type: "boolean", map: "acessorio" },
        { name: "idUnidadeComprimento", type: "number", map: "idUnidadeComprimento" },
        { name: "compoeNomeRocha", type: "string", map: "compoeNomeRocha" },
    ]

    const elementosCompoeNomeRocha = [
        { nome: "Sim", id: "S" },
        { nome: "Não", id: "N" },
    ]

    const valoresIniciais = { compoeNomeRocha: "N" }

    const validarMineralRocha = (valores) => {
        const itens = form.getState().values?.composicoesMineralogicas;

        if (itens) {
            const composicoes = resolverExibidos(itens);

            const indexExistente = composicoes.findIndex(item => item.id === valores.id);

            if (indexExistente !== -1) {

                composicoes[indexExistente] = valores;
            } else {

                composicoes.push(valores);
            }

            if (composicoes && composicoes.length > 0) {
                const somaPercentuais = composicoes.reduce((total, item) => {

                    if (item?.numeroPercentual) {
                        return total + toInteger(item?.numeroPercentual);
                    }
                    return total;
                }, 0);

                if (somaPercentuais > 100) {
                    return { [FORM_ERROR]: 'A soma dos percentuais da Composição mineralógica está ultrapassando os 100%.' };
                }
            }
            return null;
        }
    };
    const valorFormulario = useForm()
    return (
        <>
            <div style={{ marginTop: '2.5rem' }}>
                <Card className="mt-3">
                    <Card.Body>
                        <Card.Title>Composição mineralógica</Card.Title>
                        {(somaTotalPercentuais != null && somaTotalPercentuais < 100) && (
                            <span className="erro-campo" style={{ color: 'blue', fontSize: '10px' }}>
                                A soma total dos percentuais da Composição mineralógica é de: {somaTotalPercentuais}%, mas deve ser 100%.
                                <br />
                            </span>
                        )}
                        {!podeExcluir && <span style={{ color: "red", fontSize: '10px' }}>
                            Para excluir a composição mineralogica é necessário excluir antes o diagrama petrográfico.
                            <br />
                        </span>}
                        <Field name={'composicoesMineralogicas'} subscription={{ value: true }}>
                            {({ input: { value: composicoesMineralogicas = [] } }) => (
                                <SubForm
                                    nome={'composicoesMineralogicas'}
                                    onSubmit={(values) => onSubmit(ajustarValoresAntesDoEnvio(values))}
                                    exibirTabelaSemRegistro={false}
                                    podeExcluirEntidade={podeExcluir}
                                    modoSubFormComGrid
                                    colunas={colunasComposicaoMineralogica}
                                    elementos={composicoesMineralogicas}
                                    campos={camposComposicaoMineralogica}
                                    validar={(valores) => validarMineralRocha(valores)}
                                    valoresIniciais={valoresIniciais}
                                    {...getPropsPermissao(!disabled)}
                                    renderForm={({ formProps: { form } }) => (
                                        <>

                                            <Field name={'composicoes'} subscription={{ value: true }}>
                                                {({ input: { value } }) => {
                                                    const numeroPercentual = form.getState().values?.numeroPercentual
                                                    let hasPercentual = false;
                                                    if (numeroPercentual !== 0 && numeroPercentual !== null && numeroPercentual !== undefined) {
                                                        hasPercentual = true;
                                                    }
                                                    if (!hasPercentual) {
                                                        form.mutators.setValue('compoeNomeRocha', 'N');
                                                    }

                                                    var valorAtual = form.getState().values?.idMineral
                                                    var composicoesTela = []
                                                    var composicoes = valorFormulario?.getState().values?.composicoesMineralogicas
                                                    if (composicoes) {
                                                        composicoesTela = resolverExibidos(composicoes)
                                                    }

                                                    var composicoesMineralogicasFiltradas = bbMinerais.filter(value => {
                                                        return !composicoesTela.some(testeItem => testeItem?.idMineral === value.id && valorAtual !== value.id);
                                                    });

                                                    return (
                                                        <>

                                                            <Row className="my-2">
                                                                <Col md={4}>
                                                                    <Field
                                                                        component={DropdownListField}
                                                                        name={"idMineral"}
                                                                        label="Mineral"
                                                                        dica="Selecione o(s) mineral(is) microscópico(s) identificado(s) na sua rocha"
                                                                        elementos={composicoesMineralogicasFiltradas}
                                                                        required
                                                                        validate={campoObrigatorioComMsgGenerica("Mineral")}
                                                                        disabled={disabled}
                                                                    />
                                                                </Col>
                                                                <Col md={4}>
                                                                    <Field
                                                                        name="numeroPercentual"
                                                                        component={NumberInputField}
                                                                        label="%"
                                                                        dica="Porcentagem do mineral microscópico identificado na sua rocha"
                                                                        permitirNegativos={false}
                                                                        casasInteiros={3}
                                                                        casasDecimais={0}
                                                                        valorMaximo={100}
                                                                        disabled={disabled}
                                                                        validate={campoObrigatorioComMsgGenerica("%")}
                                                                        required
                                                                    />
                                                                </Col>
                                                            </Row>
                                                            <Row className="my-2 mt-4">
                                                                {!hasPercentual && (
                                                                    <Col md={4}>
                                                                        <Field
                                                                            disabled={true}
                                                                            name="compoeNomeRocha"
                                                                            component={RadioButtonWithFieldSetField}
                                                                            label="Compõe nome da rocha?"
                                                                            dica="Escolha se o mineral a ser cadastrado fará parte do nome da Rocha"
                                                                            elementos={elementosCompoeNomeRocha}
                                                                            horizontal
                                                                            required
                                                                            validate={campoObrigatorioComMsgGenerica("Compõe nome da rocha?")}
                                                                        />
                                                                    </Col>
                                                                )}
                                                                {hasPercentual && (
                                                                    <Col md={4}>
                                                                        <Field
                                                                            disabled={disabled}
                                                                            name="compoeNomeRocha"
                                                                            component={RadioButtonWithFieldSetField}
                                                                            label="Compõe nome da rocha?"
                                                                            dica="Escolha se o mineral a ser cadastrado fará parte do nome da Rocha"
                                                                            elementos={elementosCompoeNomeRocha}
                                                                            horizontal
                                                                            required
                                                                            validate={campoObrigatorioComMsgGenerica("Compõe nome da rocha?")}
                                                                        />
                                                                    </Col>
                                                                )}
                                                                <Col md={4}>
                                                                    <Field name={'idUnidadeComprimento'} subscription={{ value: true }}>
                                                                        {({ input: { value: idUnidadeComprimento = [] } }) => {
                                                                            return (
                                                                                <Field
                                                                                    disabled={disabled}
                                                                                    name="idUnidadeComprimento"
                                                                                    component={RadioFieldWithDropdown}
                                                                                    bbUnidadeMedida={bbUnidadeMedida}
                                                                                    validate={(valor) => {
                                                                                        const valorMinimo = form.getState().values?.numeroTamanhoMinimo
                                                                                        const valorMaximo = form.getState().values?.numeroTamanhoMaximo
                                                                                        const valorTamanho = form.getState().values?.idUnidadeComprimento

                                                                                        if ((!!valorMinimo || !!valorMaximo || !!valorTamanho) && !(!!valorMinimo && !!valorMaximo && !!valorTamanho)) {
                                                                                            return "Unidade de medida, mínimo e máximo são obrigatórios.";
                                                                                        }
                                                                                        return
                                                                                    }
                                                                                    }
                                                                                />
                                                                            )
                                                                        }
                                                                        }
                                                                    </Field>
                                                                </Col>
                                                            </Row>
                                                        </>
                                                    )
                                                }}
                                            </Field >

                                            <Row className="my-3">
                                                <Col md={12}>
                                                    <Field
                                                        component={TextAreaField}
                                                        name={'informacoesRelevantes'}
                                                        label="Informações relevantes"
                                                        dica="Informações complementares referente a composição mineralógica."
                                                        maxLength={2000}
                                                        disabled={disabled}
                                                    />
                                                </Col>
                                            </Row>
                                        </>
                                    )}
                                />
                            )}
                        </Field>
                    </Card.Body>
                </Card>
            </div>
        </>
    );
};

export default ComposicaoMineralogica;