import { Col, Row } from "react-bootstrap";
import { Field } from "react-final-form";
import { TextAreaField } from "../../../../../../components/field/TextAreaField";

type Props = {
  prefixo: string;
  toponimiaDisabled: boolean;
  disabled: boolean;
};

export const CamposDaParteInferior = ({
  prefixo,
  toponimiaDisabled,
  disabled,
}: Props) => {
  return (
    <>
      <Row className="mt-3">
        <Col md={12}>
          <Field
            component={TextAreaField}
            disabled={toponimiaDisabled || disabled}
            name={prefixo + "toponimia"}
            label="Toponímia"
            dica="Se possível, insira a toponímia da área de coleta."
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Field
            disabled={disabled}
            component={TextAreaField}
            name={prefixo + "informacoesRelevantes"}
            label="Informações relevantes"
            dica="Insira informações complementares que considerar relevantes."
          />
        </Col>
      </Row>
    </>
  );
};
