import React, { useCallback } from "react";
import TextArea from "../../Jqx/TextArea/TextArea";

const TextAreaField = ({input, meta, ...props}) => {
  const onChange = useCallback((evt) => {
    input.onChange(evt)
  }, [input])

  const mensagemErro = meta.touched && !meta.valid && meta.error;

  return (
    <>
    <TextArea
      {...props}
      onChange={onChange}
      valor={input.value}
      mensagemErro={mensagemErro}
    />
    </>
  )
}

TextAreaField.propTypes = {
  ...TextArea.propTypes,
}

TextAreaField.defaultProps = {
  ...TextArea.defaultProps,
}

export default TextAreaField
