import React, { useEffect, useRef, useState } from "react";
import Card from "../../../../components/card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { SubForm } from "../../../../components/SubForm";
import { Field } from "react-final-form";
import { DropdownListField } from "../../../../components/field/DropdownListField";
import { campoObrigatorioComMsgGenerica, validarSe } from "../../../../util/validadores";
import { RadioButtonWithFieldSetField } from "../../../../components/field/RadioButtonSetField";
import { buscarUtilizados } from "../../../../util/buscarUtilizados";
import { getPropsPermissao } from "../../../../components/SubForm/SubForm";
import { TextAreaField } from "../../../../components/field/TextAreaField";

// true/false, 'true'/'false' e 1/0 causam comportamentos estranhos no componente (bug do JqxWidgets)
const elementosRochaDeEnclave = [
  { id: '1', nome: 'Sim' }, // Utilizar valores booleanos criam comportamentos estranhos no componente
  { id: '0', nome: 'Não' },
]

const campos = [
  { name: "idTipo", type: "string", map: "idTipo" },
  { name: "idsRochas", type: "array", map: "idsRochas" },
  { name: "idRochaHospedeira", type: "string", map: "idRochaHospedeira" },
]

const RochasEnclave = ({
  nome,
  onSubmit,
  // elementos,
  valoresIniciais,
  permitirEdicao,
  modoDefinitivoSelecionado,
  rochas,
  rochasPorId,
  tiposRochaEnclave = [],
  rochasEnclaveComErro
}) => {
  // These refs are for remembering the previous values of each array that's a
  // dependency of the columns array. We only recalculate the columns array when
  // there's an actual change in the arrays.
  const rochasPorIdRef = useRef(rochasPorId);
  const tiposRochaEnclaveRef = useRef(tiposRochaEnclave);
  const [colunasRochasEnclaves, setColunasRochasEnclaves] = useState(gerarColunasRochasEnclave(rochasPorId, tiposRochaEnclave));
  const [possuiIgnea, setPossuiIgnea] = useState(false);

  useEffect(() => {
    // Only update the columns when at least one of the dependencies has really changed.
    // Otherwise, avoid changing the columns because that causes a table rerender.
    // (The *correct* thing to do would be including the names in the objects themselves (add extra fields)
    // and always recalculate those names when dependencies change, completely removing the need for
    // a cellsrenderer.)
    // Compare the dependencies by value.
    if (JSON.stringify(rochasPorIdRef.current) !== JSON.stringify(rochasPorId)
      || JSON.stringify(tiposRochaEnclaveRef.current) !== JSON.stringify(tiposRochaEnclave)
    ) {
      setColunasRochasEnclaves(gerarColunasRochasEnclave(rochasPorId, tiposRochaEnclave));
      rochasPorIdRef.current = rochasPorId;
      tiposRochaEnclaveRef.current = tiposRochaEnclave;
    }
  }, [rochasPorId, tiposRochaEnclave])

  const valoresIniciaisRef = useRef(valoresIniciais);

  useEffect(() => {
    setPossuiIgnea(rochas.filter(r => r.idTipoRocha === 1).length > 0);
  }, [rochas]);

  return (
    <div style={{ marginTop: '2.5rem' }}>
      <Card className="mt-3">
        <Card.Body>
          { rochasEnclaveComErro && <span style={{ color: "red" }}>Existem rochas de enclave com campos obrigatórios não preenchidos</span>}
          <Card.Title>Rochas de enclave</Card.Title>

          <Row className="my-3">
            <Col md={6}>
              <Field
                component={RadioButtonWithFieldSetField}
                name={`rochaDeEnclave`}
                label={`Rocha de enclave?`}
                dica="Informe se a rocha é de enclave (corpos estranhos isolados dentro de rochas magmáticas)"
                elementos={elementosRochaDeEnclave}
                initialValue={elementosRochaDeEnclave[1].id}
                horizontal
                required
                disabled={!(possuiIgnea && permitirEdicao)}
                validate={campoObrigatorioComMsgGenerica(`Rocha de enclave`)}
              />
            </Col>
          </Row>

          <Field name={'rochaDeEnclave'} subscription={{ value: true }}>
            {({ input: { value } }) => {
              // Exibir subform apenas quando rochaDeEnclave for Sim
              if (value !== elementosRochaDeEnclave[0].id || !possuiIgnea) {
                return null;
              }
              return (
                <Field name={nome} subscription={{ value: true }}>
                  {({ input: { value: rochasEnclave = {} } }) => {
                    return <SubForm
                      nome={nome}
                      onSubmit={(params) => {
                        // Reset the initial values so that it doesn't get automatically
                        // recovered on the next render.
                        // It would be better to check if the submit was successful before
                        // resetting this ref, but it doesn't seem like that's possible.
                        valoresIniciaisRef.current = null;
                        onSubmit(params);
                      }}
                      elementos={rochasEnclave} // elementos
                      campos={campos}
                      colunas={colunasRochasEnclaves}
                      valoresIniciais={valoresIniciaisRef.current || valoresIniciais}
                      {...getPropsPermissao(permitirEdicao)}
                      permitirInsercao={permitirEdicao}
                      alternarBotoesPai={true}
                      validarVoltar={true}
                      renderForm={({ formProps: { form, values } }) => {
                        if (valoresIniciaisRef.current !== values) {
                          valoresIniciaisRef.current = values;
                        }

                        return (
                          <>
                            <Row className="my-3">
                              <Col md={4}>
                                <Field
                                  component={DropdownListField}
                                  name={`idsRochas`}
                                  label="Rochas de enclave"
                                  dica="Selecione uma ou mais rochas associadas ao enclave"
                                  elementos={rochas}
                                  required={modoDefinitivoSelecionado}
                                  disabled={!permitirEdicao}
                                  displayMember={'nomeExibicao'}
                                  validate={validarSe(modoDefinitivoSelecionado, campoObrigatorioComMsgGenerica("Rochas de enclave"))}
                                  checkboxes={true}
                                  multiple
                                />
                              </Col>
                              <Col md={4}>
                                <Field  name={'idRochaHospedeira'} subscription={{ value: true }}> 
                                {({ input: { value: idRochaHospedeira } }) => {

                                  let listaFiltrada = [];

                                  if(rochas && rochas.length){
                                    listaFiltrada = rochas.filter(r => r.idTipoRocha === 1);
                                  }

                                  return <Field
                                    component={DropdownListField}
                                    name={'idRochaHospedeira'}
                                    elementos={listaFiltrada}
                                    label="Rocha hospedeira"
                                    dica="Selecione a rocha hospedeira do enclave"
                                    required={modoDefinitivoSelecionado}
                                    validate={validarSe(modoDefinitivoSelecionado, campoObrigatorioComMsgGenerica("Rocha hospedeira"))}
                                    displayMember={'nomeExibicao'}
                                  />
                                }}
                                </Field>
                              </Col>
                              <Col md={4}>
                                <Field
                                  component={DropdownListField}
                                  name={`idTipo`}
                                  label={`Tipo de enclave`}
                                  dica={`Informe qual o tipo da rocha de enclave`}
                                  elementos={tiposRochaEnclave}
                                  horizontal
                                  required={modoDefinitivoSelecionado}
                                  disabled={!permitirEdicao}
                                  validate={validarSe(modoDefinitivoSelecionado, campoObrigatorioComMsgGenerica(`Tipo de enclave`))}
                                />
                              </Col>
                            </Row>
                            <Row className="my-3">
                            <Col md={12}>
                                <Field
                                component={TextAreaField}
                                label={'Informações Relevantes'}
                                name={`infoRelevantes`}
                                disabled={!permitirEdicao}
                                dica={`Caso necesário, detalhe as rochas de enclave observadas`}
                                maxLength={250}
                                />
                              </Col>
                            </Row>
                          </>
                        )
                      }}
                    />
                  }}
                </Field>
              )
            }}
          </Field>
        </Card.Body>
      </Card>
    </div>
  );
}

// Função que gera as colunas da tabela de rochas de enclave, extraída para que
// possamos instanciar as colunas já inicializadas da primeira vez, ao invés de
// usar um estado [] padrão. Isso evita um rerender da tabela.
function gerarColunasRochasEnclave(rochasPorId, tiposRochaEnclave) {
  return [
    {
      text: 'Tipo de enclave', datafield: 'idTipo', width: '20%',
      cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
        const nome = value ? tiposRochaEnclave.find(t => t.id === parseInt(value)).nome : '';
        return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${nome}</div>`;
      }
    },
    {
      text: "Rochas de enclave", datafield: "idsRochas", width: '37%',

      cellsrenderer: function (row, columnfield, value, defaulthtml, columnproperties, rowdata) {
        let nome = '';
        
        try {
          const idsRochas = (rowdata[columnfield] || '').split(',')
          nome = idsRochas
            .filter(it => !!it)
            .map(it => rochasPorId && rochasPorId[Number(it)] ? rochasPorId[Number(it)].nomeExibicao : '')
            .join(', ')
        }
        catch(err){}

        return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${nome}</div>`;
      }
    },
    {
      text: "Rocha hospedeira", datafield: "idRochaHospedeira", width: '37%',

      cellsrenderer: function (row, columnfield, value, defaulthtml, columnproperties, rowdata) {
        const hospedeiraId = rowdata[columnfield];
        let rocha = rochasPorId[Number(hospedeiraId)]; 
        let nome = rocha ? rocha.nomeExibicao : "";

        return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;">${nome}</div>`;
      }
    },
  ];
}

export default RochasEnclave;
