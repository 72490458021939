// Utilidades relacionadas a componentes do jQxWidgets

import { ACCESS_TOKEN_KEY } from "../reducers/authReducers";

/**
 * Função que adiciona o header de autenticação em um objeto jqXHR.
 * Feito para ser passado à propriedade beforeSend de um jqx.dataAdapter
 * que realizam requisições ao backend
 */
export const adicionarCabecalhoAuthAoJqXHR = (jqXHR, settings) => {
    jqXHR.setRequestHeader('Authorization', `Bearer ${localStorage.getItem(ACCESS_TOKEN_KEY)}`);
};