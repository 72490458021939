import { useState, useCallback, useEffect } from "react";
import Service, { BASEGEO, AMOSTRAS, GEOCRON } from "../../../../../service/Service";
import { decoradorSirgas2000, decoradorMapa } from "../../../../../util/decoradores/decoradorMapa";
import { resetarLatLongInvalidaAoTrocarHemisferio } from "../../../../../util/decoradores/decoradorSeletorCoordenadas";
import { Form } from "react-final-form";
import { setValue } from "../../../../../util/mutadores";
import AtividadeGeocron from "../../../../dadosAnaliticos/components/geocronologia/AtividadeGeocron";

const GeocronologiaAprovacao = ({ atividade }) => {
    const [amostraSelecionada, setAmostraSelecionada] = useState(null);
    const [idAmostra] = useState(atividade.idAmostra);
    const [idProjeto] = useState(atividade.idProjetoCorrente);
    
    const [bbSistemasIsotopicos, setBbSistemaIsotopico] = useState([]);
    const [bbTecnicaAnalitica, setBbTecnicaAnalitica] = useState([]);
    const [bbTipoIdade, setBbTipoIdade] = useState([]);
    const [bbMaterialAnalisado, setBbMaterialAnalisado] = useState([]);
    const [bbSigma, setBbSigma] = useState([]);
    const [bbInterpretacao, setBbInterpretacao] = useState([]);
    const [bbTipoIlustracao, setBbTipoIlustracao] = useState([]);
    const [bbExtencaoIlustracao, setBbExtencaoIlustracao] = useState([]);
    const [bbMinerais, setBbMinerais] = useState([]);
    
    const decoradores = [decoradorSirgas2000(atividade.idVisita), decoradorMapa(), resetarLatLongInvalidaAoTrocarHemisferio()];

    useEffect(() => {
        const fetchData = async () => {
          try {
            const [
              amostraPetrografiaResponse,
              bbSistemasIsotopicos,
              bbTecnicaAnalitica,
              bbTipoIdade,
              bbMaterialAnalisado,
              bbSigma,
              bbInterpretacao,
              tiposIlustracao,
              extencoesIlustracao,
              bbMinerais,
            ] = await Promise.all([
              Service('/cadastroAmostra/amostra', AMOSTRAS).get(idAmostra),
              Service(`/sistemas-isotopicos`, GEOCRON).query(),
              Service(`/tecnica-analitica`, GEOCRON).query(),
              Service(`/tipo-idade`, GEOCRON).query(),
              Service(`/material-analisado`, GEOCRON).query(),
              Service(`/sigma`, GEOCRON).query(),
              Service(`/interpretacao-autor`, GEOCRON).query(),
              Service("/tiposIlustracao").query(),
              Service("/extencoesIlustracao").query(),
              Service("/minerais", BASEGEO).query(),
            ]);
    
            setAmostraSelecionada(amostraPetrografiaResponse.data.dados);
            setBbSistemaIsotopico(bbSistemasIsotopicos?.data?.dados);
            setBbTecnicaAnalitica(bbTecnicaAnalitica?.data?.dados);
            setBbTipoIdade(bbTipoIdade?.data?.dados);
            setBbMaterialAnalisado(bbMaterialAnalisado?.data?.dados);
            setBbSigma(bbSigma?.data?.dados);
            setBbInterpretacao(bbInterpretacao?.data?.dados);
            setBbTipoIlustracao(tiposIlustracao?.data?.dados);
            setBbExtencaoIlustracao(extencoesIlustracao?.data?.dados);
            setBbMinerais(bbMinerais.data.dados || []);
          } catch (err) {
            console.log("Erro ao consultar bibliotecas: ", err);
          }
        };
    
        fetchData();
      }, [idAmostra]);

    const AtividadeCarregada = useCallback(({ referenciaForm }) => {
        if (!amostraSelecionada) {
            return <div>Carregando...</div>;
        }

        return (
            <AtividadeGeocron
                formProps={referenciaForm}
                amostraSelecionada={amostraSelecionada}
                idProjetoCorrente={idProjeto}
                bbMinerais={bbMinerais}
                bbExtencaoIlustracao={bbExtencaoIlustracao}
                bbInterpretacao={bbInterpretacao}
                bbMaterialAnalisado={bbMaterialAnalisado}
                bbSigma={bbSigma}
                bbSistemasIsotopicos={bbSistemasIsotopicos}
                bbTecnicaAnalitica={bbTecnicaAnalitica}
                bbTipoIdade={bbTipoIdade}
                bbTipoIlustracao={bbTipoIlustracao}
            />
        );
    }, [amostraSelecionada, bbExtencaoIlustracao, bbInterpretacao, bbMaterialAnalisado, bbMinerais, bbSigma, bbSistemasIsotopicos, bbTecnicaAnalitica, bbTipoIdade, bbTipoIlustracao, idProjeto]);

    return (
        <Form
            id="formAtividadeGeocronologias"
            onSubmit={() => { }}
            decorators={decoradores}
            initialValues={atividade}
            mutators={{ setValue }}
            render={(formProps) => {
                return (<AtividadeCarregada referenciaForm={formProps} />);
            }}
        />
    );
}

export { GeocronologiaAprovacao };
