import JsBarcode from "jsbarcode";
import { asapScheduler, Observable, zip } from "rxjs";
import { map, mergeMap, observeOn, subscribeOn } from "rxjs/operators";
import cacheService, { CacheService } from "./CacheService";
import logoImg from "../assets/img/geoquimica-logo-black.png";

export class EtiquetasService {
  constructor(private cacheService: CacheService) {}

  generateEtiquetaImage(barcode: string): Observable<HTMLImageElement> {
    return this.cacheService.getCached({
      path: "etiqueta/" + barcode,
      source: () => {
        return new Observable<HTMLImageElement>((subscriber) => {
          subscriber.add(
            zip(this.getLogoImage(), this.renderBarcodeImage(barcode))
              .pipe(
                map(([logoImage, barcodeImage]) => {
                  const canvas = document.createElement("canvas");
                  canvas.width = 372;
                  canvas.height = 140;

                  const ctx = canvas.getContext("2d")!;
                  ctx.imageSmoothingEnabled = true;
                  ctx.imageSmoothingQuality = "high";

                  let contentHeight = canvas.height * 0.01 * 60.71428;

                  let marginWidth = canvas.width * 0.01 * 4.05405;
                  let marginHeight = (canvas.height - contentHeight) / 2;

                  let marginBetween = canvas.width * 0.01 * 2.7027;

                  let logoContentWidth = canvas.width * 0.01 * 27.027;

                  let logoWidth = logoContentWidth * 0.01 * 54;
                  let logoHeight =
                    (logoImage.height / logoImage.width) * logoWidth;

                  let logoMarginWidth =
                    (logoContentWidth - logoWidth) / 2 + marginWidth;

                  let barcodeContentWidth =
                    canvas.width -
                    logoContentWidth -
                    marginWidth * 2 -
                    marginBetween;

                  let barcodeWidth: number, barcodeHeight: number;
                  if (
                    barcodeImage.width / barcodeImage.height >
                    barcodeContentWidth / contentHeight
                  ) {
                    barcodeWidth = barcodeContentWidth;
                    barcodeHeight =
                      (barcodeImage.height / barcodeImage.width) * barcodeWidth;
                  } else {
                    barcodeHeight = contentHeight;
                    barcodeWidth =
                      (barcodeImage.width / barcodeImage.height) *
                      barcodeHeight;
                  }

                  let barcodeMarginLeft =
                    logoContentWidth +
                    marginWidth +
                    marginBetween +
                    (barcodeContentWidth - barcodeWidth) / 2;

                  ctx.drawImage(
                    logoImage,
                    0,
                    0,
                    logoImage.width,
                    logoImage.height,
                    logoMarginWidth,
                    marginHeight,
                    logoWidth,
                    logoHeight
                  );

                  ctx.drawImage(
                    barcodeImage,
                    0,
                    0,
                    barcodeImage.width,
                    barcodeImage.height,
                    barcodeMarginLeft,
                    marginHeight,
                    barcodeWidth,
                    barcodeHeight
                  );

                  let logoTextFirstLineMarginTop =
                    logoHeight + marginHeight + 5;
                  let logoTextSecondLineMarginTop =
                    logoTextFirstLineMarginTop + 13;

                  ctx.font = "small-caps bold 10px OpenSans";

                  fillJustifyText(
                    ctx,
                    "SERVIÇO GEOLOGICO",
                    marginWidth,
                    logoTextFirstLineMarginTop,
                    logoContentWidth
                  );

                  fillJustifyText(
                    ctx,
                    "DO BRASIL - CPRM",
                    marginWidth,
                    logoTextSecondLineMarginTop,
                    logoContentWidth
                  );

                  const dataUrl = canvas.toDataURL("jpg", 100);
                  return dataUrl;
                }),
                mergeMap((dataUrl) => {
                  return this.renderImageByUrl(dataUrl);
                })
              )
              .subscribe({
                next: (image) => {
                  subscriber.next(image);
                },
                error: (error) => {
                  subscriber.error(error);
                },
                complete: () => {
                  subscriber.complete();
                },
              })
          );
        }).pipe(subscribeOn(asapScheduler));
      },
    });
  }

  private getLogoImage(): Observable<HTMLImageElement> {
    return this.cacheService.getCached({
      path: "geoquimica-logo-black.png",
      lifetimeSeconds: 60,
      source: () => {
        return this.renderImageByUrl(logoImg);
      },
    });
  }

  private renderBarcodeImage(barcode: string): Observable<HTMLImageElement> {
    return new Observable<HTMLImageElement>((subscriber) => {
      const barcodeImage = new Image();
      JsBarcode(barcodeImage, barcode, {
        margin: 0,
      });
      subscriber.next(barcodeImage);
      subscriber.complete();
    }).pipe(subscribeOn(asapScheduler), observeOn(asapScheduler));
  }

  private renderImageByUrl(url: string) {
    return new Observable<HTMLImageElement>((subscriber) => {
      const image = new Image();
      image.decoding = "async";
      image.onload = () => {
        subscriber.next(image);
        subscriber.complete();
      };

      image.onerror = (error) => {
        subscriber.error(error);
      };

      image.src = url;
    }).pipe(subscribeOn(asapScheduler));
  }
}

const FILL = 0; // const to indicate filltext render
const STROKE = 1;
const MEASURE = 2;
let renderType = FILL; // used internal to set fill or stroke text

let maxSpaceSize = 6; // Multiplier for max space size. If greater then no justificatoin applied
let minSpaceSize = 0.5; // Multiplier for minimum space size

function renderTextJustified(
  ctx: CanvasRenderingContext2D,
  text: string,
  x: number,
  y: number,
  width: number
) {
  let words,
    wordsWidth,
    count,
    spaces,
    spaceWidth,
    adjSpace,
    renderer,
    i,
    textAlign,
    useSize,
    totalWidth;
  textAlign = ctx.textAlign; // get current align settings
  ctx.textAlign = "left";
  wordsWidth = 0;
  words = text.split(" ").map((word) => {
    var w = ctx.measureText(word).width;
    wordsWidth += w;
    return {
      width: w,
      word: word,
    };
  });
  // count = num words, spaces = number spaces, spaceWidth normal space size
  // adjSpace new space size >= min size. useSize Resulting space size used to render
  count = words.length;
  spaces = count - 1;
  spaceWidth = ctx.measureText(" ").width;
  adjSpace = Math.max(spaceWidth * minSpaceSize, (width - wordsWidth) / spaces);
  useSize = adjSpace > spaceWidth * maxSpaceSize ? spaceWidth : adjSpace;
  totalWidth = wordsWidth + useSize * spaces;
  if (renderType === MEASURE) {
    // if measuring return size
    ctx.textAlign = textAlign;
    return totalWidth;
  }
  renderer =
    renderType === FILL ? ctx.fillText.bind(ctx) : ctx.strokeText.bind(ctx); // fill or stroke
  switch (textAlign) {
    case "right":
      x -= totalWidth;
      break;
    case "end":
      x += width - totalWidth;
      break;
    case "center":
      x -= totalWidth / 2;
      break;
  }
  if (useSize === spaceWidth) {
    // if space size unchanged
    renderer(text, x, y);
  } else {
    for (i = 0; i < count; i += 1) {
      renderer(words[i].word, x, y);
      x += words[i].width;
      x += useSize;
    }
  }
  ctx.textAlign = textAlign;
}
// Parse vet and set settings object.
function justifiedTextSettings(settings) {
  let min, max;
  const vetNumber = (num, defaultNum) => {
    num = num !== null && num !== null && !isNaN(num) ? num : defaultNum;
    if (num < 0) {
      num = defaultNum;
    }
    return num;
  };
  if (settings === undefined || settings === null) {
    return;
  }
  max = vetNumber(settings.maxSpaceSize, maxSpaceSize);
  min = vetNumber(settings.minSpaceSize, minSpaceSize);
  if (min > max) {
    return;
  }
  minSpaceSize = min;
  maxSpaceSize = max;
}
// define fill text
function fillJustifyText(
  ctx: CanvasRenderingContext2D,
  text: string,
  x: number,
  y: number,
  width: number,
  settings?: any
) {
  justifiedTextSettings(settings);
  renderType = FILL;
  renderTextJustified(ctx, text, x, y, width);
}
// define stroke text
function strokeJustifyText(
  ctx: CanvasRenderingContext2D,
  text: string,
  x: number,
  y: number,
  width: number,
  settings?: any
) {
  justifiedTextSettings(settings);
  renderType = STROKE;
  renderTextJustified(ctx, text, x, y, width);
}
// define measure text
function measureJustifiedText(
  ctx: CanvasRenderingContext2D,
  text: string,
  width: number,
  settings?: any
) {
  justifiedTextSettings(settings);
  renderType = MEASURE;
  return renderTextJustified(ctx, text, 0, 0, width);
}

export default new EtiquetasService(cacheService);
