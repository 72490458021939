import React, { useCallback } from "react";
import JqxButton from "jqwidgets-scripts/jqwidgets-react-tsx/jqxbuttons";
import "./style.css"
const GridAppendButton = (props) => {
    const { onClick, className } = props;
    const jqxProps = (({className,...data})=>data)(props);

    const handleKey = useCallback((e) => {
        if (e.charCode === 13 || e.charCode === 32) {
            onClick && onClick();
        }
    }, [onClick]);

    return (
        <div
            role="button"
            aria-pressed={false}
            tabIndex="0"
            onKeyPress={handleKey}
            className={className}
            style={{width:"100%"}}
        >
            <JqxButton {...jqxProps}  className={'br-button primary small'} onClick={onClick}>
                {props.children}
            </JqxButton>
        </div>
    )
}

export default React.memo(GridAppendButton);
