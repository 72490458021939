import React from "react"
import RadioButtonSet from "./RadioButtonSet";
import Hint from "../../hint/Hint";

const RadioButtonSetWithFieldSet = ({label, dica, required, ...props}) => {
  return (
    <>
      <fieldset className="border p-2">
        {label && <legend className="w-auto small" style={{marginBottom: 8, fontSize: '16px'}}>
          {label}
          {dica && <Hint textoAjuda={dica}/>}
          {required && <span style={{color: "red"}}>*</span>}
        </legend>}
        <RadioButtonSet
          {...props}
          required={required}
        />
      </fieldset>
    </>
  )
}

RadioButtonSetWithFieldSet.propTypes = {
  ...RadioButtonSet.propTypes,
}

RadioButtonSetWithFieldSet.defaultProps = {
  ...RadioButtonSet.defaultProps,
}

export default RadioButtonSetWithFieldSet;
