import React, { useState, useEffect, useMemo } from "react";
import { Col, Row } from "react-bootstrap";
import { Field, useFormState } from "react-final-form";
import { DropdownListField } from "../../../../../components/field/DropdownListField";
import { NumberInputField } from "../../../../../components/field/NumberInputField";
import { TextAreaField } from "../../../../../components/field/TextAreaField";
import { campoObrigatorioDefinitivoComMsgGenerica, comporValidadores } from "../../../../../util/validadores";
import { resolverRequestAmostras } from "../AmostrasUtils/AmostraRequestUtils";
import { validaProfundidadeMinima, validaProfundidadeMaxima } from "../validadores";
import { RadioButtonSetField } from "../../../../../components/field/RadioButtonSetField";
import { resolverExibidos } from "../../../../../components/SubForm/SubForm";
import { TIPO_ATIVIDADE_GEOCRONOLOGIA, TIPO_ATIVIDADE_PETROGRAFIA } from "../../../../../util/constantes";

const CamposComuns = (props) => {
  const [tipoAmostragem, setTipoAmostragem] = useState([]);
  const [fonteAmostra, setFonteAmostra] = useState([]);
  const [materialColetado, setMaterialColetado] = useState([]);
  const { values } = useFormState()

  const desabilitarOuHabilitarBibliografia = useMemo(() => {
    if (values?.associacoes) {
      const associacaoEmTela = resolverExibidos(values?.associacoes)
      //Remove dados analíticos da contagem de associações
      const tiposExcluidos = [TIPO_ATIVIDADE_PETROGRAFIA.id, TIPO_ATIVIDADE_GEOCRONOLOGIA.id];
      const count = associacaoEmTela.filter(atividade => !tiposExcluidos.includes(atividade.tipoAtividade)).length;

      if (count > 0) {
        return true
      }
      return false
    }
    return false
  }, [values])

  useEffect(() => {
    const fetchData = async () => {
      setTipoAmostragem(await resolverRequestAmostras("/amostra/agua/tipoAmostragem"));
      setFonteAmostra(await resolverRequestAmostras("/amostra/agua/fonteAmostra"));
      setMaterialColetado(await resolverRequestAmostras("/biblioteca/materialcoletado"));
    };
    fetchData();
  }, []);

  const OPTIONS = [
    { nome: "Sim", id: "S" },
    { nome: "Não", id: "N" },
  ];


  return (
    <Field subscription={{ value: true }} name={'modoCadastro'}>
      {({ input: { value: modoCadastro } }) => {
        const validar = modoCadastro === 'DEFINITIVO';

        return (
          <>
            <Row>
              <Col md={6}>
                <Field
                  disabled={!props.permitirEdicao}
                  name={`${props.prefixo}.idTipoAmostragem`}
                  component={DropdownListField}
                  label="Tipo de amostragem"
                  dica="Selecione o tipo de amostragem utilizado"
                  elementos={tipoAmostragem}
                  required={validar}
                  validate={campoObrigatorioDefinitivoComMsgGenerica("Tipo de amostragem")}
                />
              </Col>
              <Col md={6}>
                <Field
                  disabled={!props.permitirEdicao}
                  name={`${props.prefixo}.idFonteAmostra`}
                  component={DropdownListField}
                  label="Fonte da amostra"
                  dica="Selecione a fonte da amostra coletada"
                  elementos={fonteAmostra}
                  required={validar}
                  validate={campoObrigatorioDefinitivoComMsgGenerica("Fonte da amostra")}
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={6}>
                <Field name={`${props.prefixo}.materialColetado`} subscription={{ value: true }}>
                  {({ input: { value: materialColetadoValue } }) => {
                    let idInicial = null;
                    if (!materialColetadoValue && props.materialColetadoPreenchido) {
                      idInicial = 'ALUV';
                    }
                    return (
                      <Field
                        disabled={!props.permitirEdicao}
                        subscription={{ value: true }}
                        name={`${props.prefixo}.materialColetado`}
                        component={DropdownListField}
                        label="Material coletado"
                        dica="Selecione o tipo de material coletado para compor a amostra"
                        elementos={materialColetado}
                        initialValue={idInicial}
                      />
                    );
                  }}
                </Field>
              </Col>
              <Col md={6}>
                {props.prefixo === "agua" ? (
                  <Field
                    disabled={props.visualizar ? true : props.duplicata ? false : !props.permitirEdicao}
                    name={`${props.prefixo}.volumeAmostra`}
                    component={NumberInputField}
                    label={props.labelCampo ?? ""}
                    dica={props.dicaCampo ?? ""}
                    required={validar}
                    casasDecimais={4}
                    casasInteiros={4}
                    permitirNegativos={false}
                    validate={campoObrigatorioDefinitivoComMsgGenerica(`${props.labelCampo ?? ""}`)}
                  />
                ) : (
                  <Field
                    disabled={props.visualizar ? true : props.duplicata ? false : !props.permitirEdicao}
                    name={`${props.prefixo}.pesoAmostra`}
                    component={NumberInputField}
                    label={props.labelCampo ?? ""}
                    dica={props.dicaCampo ?? ""}
                    casasDecimais={4}
                    casasInteiros={4}
                    permitirNegativos={false}
                  />
                )}
              </Col>
            </Row>
            {props.profundidadeObrigatoria ? (
              <Row className="mt-3">
                <Col md={6}>
                  <Field
                    disabled={!props.permitirEdicao}
                    name={`${props.prefixo}.profundidadeMinima`}
                    component={NumberInputField}
                    label="Profundidade mínima (m)"
                    dica="Informe a profundidade mínima da coleta, se aplicável"
                    required={validar}
                    casasDecimais={4}
                    casasInteiros={4}
                    permitirNegativos={false}
                    validate={comporValidadores(campoObrigatorioDefinitivoComMsgGenerica("Profundidade mínima (m)"), validaProfundidadeMinima)}
                  />
                </Col>
                <Col md={6}>
                  <Field
                    disabled={!props.permitirEdicao}
                    name={`${props.prefixo}.profundidadeMaxima`}
                    component={NumberInputField}
                    label="Profundidade máxima (m)"
                    dica="Informe a profundidade máxima da coleta, se aplicável"
                    required={validar}
                    casasDecimais={4}
                    casasInteiros={4}
                    permitirNegativos={false}
                    validate={comporValidadores(campoObrigatorioDefinitivoComMsgGenerica("Profundidade máxima (m)"), validaProfundidadeMaxima)}
                  />
                </Col>
              </Row>
            ) : (
              <Row className="mt-3">
                <Col md={6}>
                  <Field
                    disabled={!props.permitirEdicao}
                    name={`${props.prefixo}.profundidadeMinima`}
                    component={NumberInputField}
                    permitirNegativos={false}
                    label="Profundidade mínima (m)"
                    dica="Informe a profundidade mínima da coleta, se aplicável"
                    casasDecimais={4}
                    casasInteiros={4}
                    validate={comporValidadores(validaProfundidadeMinima)}
                  />
                </Col>
                <Col md={6}>
                  <Field
                    disabled={!props.permitirEdicao}
                    name={`${props.prefixo}.profundidadeMaxima`}
                    component={NumberInputField}
                    permitirNegativos={false}
                    label="Profundidade máxima (m)"
                    dica="Informe a profundidade máxima da coleta, se aplicável"
                    casasDecimais={4}
                    casasInteiros={4}
                    validate={comporValidadores(validaProfundidadeMaxima)}
                  />
                </Col>
              </Row>
            )}
            <Row className="mt-3">
              <Col md={12}>
                <Field
                  maxLength={250}
                  disabled={!props.permitirEdicao}
                  name={`${props.prefixo}.observacoes`}
                  component={TextAreaField}
                  label="Informações relevantes"
                  dica="Descrever, com o maior detalhe possível, as características da amostra cadastrada e do seu local de coleta"
                  required={validar && props.informacoesRelevantesObrigatorias}
                  validate={props.informacoesRelevantesObrigatorias ? campoObrigatorioDefinitivoComMsgGenerica("Informações relevantes") : false}
                />
              </Col>
            </Row>
            {props.prefixo !== "agua" && props.prefixo !== "vegetacao" && (
              <>
                {desabilitarOuHabilitarBibliografia && <span style={{ color: "blue",marginTop:"20px" }}>Não é possível informar que a amostra é proveniente de bibliografia se houver associação com MG.</span>}
                <Row className="mt-1">
                  <Col md={4}>
                    <Field
                      name="statusBibliografia"
                      component={RadioButtonSetField}
                      label="Informações provenientes de bibliografia"
                      elementos={OPTIONS}
                      horizontal
                      required={true}
                      valorInicial={{ nome: "Não", id: "N" }}
                      disabled={desabilitarOuHabilitarBibliografia}
                    />
                  </Col>
                </Row>
              </>

            )}
          </>
        );
      }}
    </Field>
  );
};

export default CamposComuns;
