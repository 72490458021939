import React from "react";
import { Col, Row } from "react-bootstrap";
import { Field } from "react-final-form";
import { DropdownListField } from "../../../../../components/field/DropdownListField";
import { campoObrigatorioDefinitivoComMsgGenerica } from "../../../../../util/validadores";
import { resolverRequestAmostras } from "../AmostrasUtils/AmostraRequestUtils";
import CamposComuns from "./CamposComuns";

class Crosta extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      grauIntemperismo: [],
      processoGeomorfologico: [],
      composicaoCrosta: [],
      tipoCrosta: []
    }
  };

  async componentDidMount() {
    this.setState({
      grauIntemperismo: await resolverRequestAmostras("/biblioteca/grauintemperismo"),
      processoGeomorfologico: await resolverRequestAmostras("/biblioteca/ambientecrosta"),
      composicaoCrosta: await resolverRequestAmostras("/biblioteca/matrizpredominante"),
      tipoCrosta: await resolverRequestAmostras("/biblioteca/decomposicaocrosta"),
    })
  }


  render() {
    return <>
      <CamposComuns
        duplicata={this.props.duplicata}
        materialColetadoPreenchido={true}
        permitirEdicao={this.props.permitirEdicao}
        labelCampo="Peso da amostra (g)"
        dicaCampo="Informe o peso da amostra, em gramas"
        profundidadeObrigatoria={false}
        informacoesRelevantesObrigatorias={true}
        prefixo={'crosta'}
        visualizar={this.props.visualizar}
      />
      
      
      <Field subscription={{ value: true }} name={'modoCadastro'}>
      {({ input: { value: modoCadastro } }) => {

        const validar = modoCadastro === 'DEFINITIVO';

        return <>
                <Row className="mt-3">
        <Col md={4}>
          <Field
            name="crosta.idGrauIntemperismoCrosta"
            subscription={{ value: true }}>
            {({ input: { value: idGrau } }) => {
              let listaFiltrada = this.state.grauIntemperismo.filter(grau => grau.atual === 'S');

              if (idGrau && this.state.grauIntemperismo.length && !listaFiltrada.find((grau) => grau.id === idGrau)) {
                const item = this.state.grauIntemperismo.find((grau) => grau.id === idGrau);

                if (item && item.atual === 'N') {
                  listaFiltrada.push(item);
                  listaFiltrada = listaFiltrada.sort((a, b) => a.nome.localeCompare(b.nome))
                }
              }
              return <Field
                component={DropdownListField}
                name="crosta.idGrauIntemperismoCrosta"
                label="Grau de intemperismo"
                dica="Selecione o grau de intemperismo da amostra coletada"
                elementos={listaFiltrada}
                required={validar}
                disabled={!this.props.permitirEdicao}
                validate={campoObrigatorioDefinitivoComMsgGenerica("Grau de intemperismo")}
              />
            }}
          </Field>
        </Col>
        <Col md={4}>
          <Field
            disabled={!this.props.permitirEdicao}
            name="crosta.idAmbienteCrosta"
            component={DropdownListField}
            label="Processo geomorfológico"
            dica="Selecione o processo geomorfológico atuante na região"
            required={validar}
            elementos={this.state.processoGeomorfologico}
            validate={campoObrigatorioDefinitivoComMsgGenerica(
              "Processo geomorfológico"
            )}
          />
        </Col>
        <Col md={4}>
          <Field
            disabled={!this.props.permitirEdicao}
            name="crosta.idComposicaoCrosta"
            component={DropdownListField}
            label="Composição de crosta"
            dica="Selecione a composição predominante da crosta amostrada"
            required={validar}
            elementos={this.state.composicaoCrosta}
            validate={campoObrigatorioDefinitivoComMsgGenerica(
              "Composição de crosta"
            )}
          />
        </Col>
      </Row>
      <Row className="mt-3 mb-4">
        <Col md={12}>
          <Field
            disabled={!this.props.permitirEdicao}
            name="crosta.idDecomposicaoCrosta"
            component={DropdownListField}
            label="Tipo de crosta"
            dica="Selecione o tipo de crosta observado"
            required={validar}
            elementos={this.state.tipoCrosta}
            validate={campoObrigatorioDefinitivoComMsgGenerica(
              "Tipo de crosta"
            )}
          />
        </Col>
      </Row>
        </>
      }}
      </Field>
    </>
  }
}

export default Crosta;
