import React, { useCallback, useRef } from "react";
import PropTypes from "prop-types";
import FocusableButton from "./FocusableButton";
import ReactFileReader from "react-file-reader";

const FileButton = ({ titulo, handleFiles, fileTypes, disabled, ...props }) => {
  const ref = useRef(null);

  const handleClick = useCallback(() => {
    ref.current.clickInput()
  }, [ref])

  return (
    <>
      <ReactFileReader ref={ref} fileTypes={fileTypes} handleFiles={handleFiles} />
      <FocusableButton
        onClick={disabled ? null : handleClick}
        disabled={disabled}
        {...props}
      >
        {titulo}
      </FocusableButton>
    </>
  )
}

FileButton.propTypes = {
  titulo: PropTypes.string,
  onClick: PropTypes.func,
}

FileButton.defaultProps = {
}

export default React.memo(FileButton);
