import React, { Component } from 'react'
import "../footer/footer.css";
import FOOTER_SGIH_COMPLETA from '../../../assets/img/SGIH/img/FOOTER_SGIH_COMPLETA.png';

export default class Footer extends Component {

    render() {
        return (
            <div className="footer ">
                <img className="pb-2" src={FOOTER_SGIH_COMPLETA} alt=""/> 
            </div>
        )                    
    }
}
