import { useState, useEffect, useCallback } from "react"
import Form from "react-bootstrap/Form";
import Hint from "../../hint/Hint";
import Erro from "../../Erro";

const CounterField = ({ meta, input, disabled, ...props }) => {
  const [numero, setNumero] = useState();
  const onChange = useCallback((evt) => {
    input.onChange(evt)
  }, [input])
  const mensagemErro = meta.touched && !meta.valid && meta.error;
  useEffect(() => {
    setNumero(input.value)
  }, [input.value])

  const buttonLeftStyle = {
    height: '25px',
    width: '25px',
    background: 'none',
    borderTopLeftRadius: '5px',
    borderBottomLeftRadius: '5px',
    float: 'left',
    position: 'relative',
    top: '1px',
    border: '1px solid #555',
  }

  const buttonRightStyle = {
    height: '25px',
    width: '25px',
    background: 'none',
    borderTopRightRadius: '5px',
    borderBottomRightRadius: '5px',
    float: 'left',
    position: 'relative',
    top: '1px',
    border: '1px solid #555',
  }

  const inputStyle = {
    height: '25px',
    width: '150px',
    background: 'none',
    pointerEvents: 'none',
    textAlign: 'center',
    float: 'left',
    position: 'relative',
    top: '1px',
    border: '1px solid #555',
  }

  function adicionar() {
    if (numero === undefined || numero === '') {
      setNumero(1);
      onChange(1)
      return
    }

    onChange(numero + 1);
    setNumero(numero + 1);
  }

  function diminuir() {
    if (numero === 1) {
      setNumero('');
      onChange(null);
      return
    }

    onChange(numero - 1);
    setNumero(numero - 1);
  }
  return (
    <>
      {props.label && (
        <Form.Label className="small" >
          {props.label}
          {props.required && <span style={{ color: "red" }}>*</span>}
          {props.label && <Hint textoAjuda={props.dica} />}
        </Form.Label>
      )}
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div>
          <input style={buttonLeftStyle} type="button" onClick={diminuir} disabled={disabled || numero === undefined || numero === ''} value='-' />
          <input style={inputStyle} type="text" name="hierarquia" value={numero} />
          <input style={buttonRightStyle} type="button" onClick={adicionar} disabled={disabled || numero === 10} value='+' />
        </div>
        {props.required &&
          <Erro mensagem={mensagemErro} />
        }
      </div>
    </>
  )
}

export default CounterField