import React, { useCallback, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Form from "react-bootstrap/Form";
import Hint from "../../hint/Hint";
import Erro from "../../Erro";
import JqxTextArea from "jqwidgets-scripts/jqwidgets-react-tsx/jqxtextarea";

const TextArea = ({valor, label, dica, onChange, required, mensagemErro, ...props}) => {
  if (undefined === valor || null === valor) valor = '';

  const ref = useRef()
  useEffect(() => {
    if (ref.current) {
      ref.current.val(valor)
    }
  }, [valor])

  const onChangeCallback = useCallback((evt) => {
    const valor = evt.args.owner.input.value
    onChange && onChange(valor)
  }, [onChange])

  return (
    <>
      {label && <Form.Label className="small">
        {label}
        {required && <span style={{color: "red"}}>*</span>}
        {dica && <Hint textoAjuda={dica}/>}
      </Form.Label>}
      <JqxTextArea
        ref={ref}
        style={{fontSize: "13px"}}
        height={100}
        width="100%"
        theme="bootstrap"
        onChange={onChangeCallback}
        {...props}
      />
      <Erro mensagem={mensagemErro} />
    </>
  )
}

TextArea.propTypes = {
  valor: PropTypes.string,
  label: PropTypes.string,
  dica: PropTypes.string,
  mensagemErro: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  required: PropTypes.bool,

  onChange: PropTypes.func,
  rows: PropTypes.number,
}

TextArea.defaultProps = {
  rows: 3,
}

export default TextArea;
