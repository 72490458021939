import React from "react";
import Card from "../../../../components/card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Field, useField, useForm } from "react-final-form";
import {
  campoObrigatorioComMsgGenerica,
  validarSe,
} from "../../../../util/validadores";
import PontosGPSAfloramento from "../afloramento/PontosGPSAfloramento";
import { DropdownListField } from "../../../../components/field/DropdownListField";
import { TextInputField } from "../../../../components/field/TextInputField";
import { TextAreaField } from "../../../../components/field/TextAreaField";
import InformacoesCoordenadas from "../mapa/InformacoesCoordenadas";
import Ilustracoes2 from "../Ilustracoes2";
import {
  TIPO_CADASTRO_ATIVIDADE_DEFINITIVO,
  TIPO_CADASTRO_ATIVIDADE_RASCUNHO,
} from "../../../../util/constantes";
import { RadioButtonWithFieldSetField } from "../../../../components/field/RadioButtonSetField";

import { configMapaAfloramento } from "../../../../config/mapa";

const modosCadastroAtividade = [
  TIPO_CADASTRO_ATIVIDADE_RASCUNHO,
  TIPO_CADASTRO_ATIVIDADE_DEFINITIVO,
];

const EstaEmModoDefinitivo = ({ children, prefixoNome }) => {
  const modoCadastro = useField(`${prefixoNome}modoCadastro`, {
    subscription: { value: true },
  });
  return children(
    TIPO_CADASTRO_ATIVIDADE_DEFINITIVO.id === modoCadastro.input.value
  );
};

const AtividadeOutrasColetas = ({
  prefixoNome,
  permitirEdicao,
  editando,
  idEstacao,

  pontosAtribuidosAEstacao,
  coletores,
  metodosGeoposicionamento,
  categoriasLimiteArea,
  datums,

  classesAmostra,
  tiposIlustracao,
  extencoesIlustracao,
}) => {
  const form = useForm();

  const possiveisColetores = [];
  const idColetor = form.getState().values.idUsuarioColetor;
  for (let index = 0; index < coletores.length; index++) {
    var element = coletores[index];
    if (element.id === idColetor) {
      element = {
        ...element,
        itemDesabilitado: false,
      };
    }
    possiveisColetores.push(element);
  }

  return (
    <>
      <div>
        <Card>
          <Card.Body>
            <Row className="mb-3">
              <Col md={4}>
                <Field
                  component={RadioButtonWithFieldSetField}
                  name={`${prefixoNome}modoCadastro`}
                  label="Modo de cadastro"
                  elementos={modosCadastroAtividade}
                  horizontal
                  required
                  disabled={!permitirEdicao}
                  validate={campoObrigatorioComMsgGenerica("Modo de cadastro")}
                />
              </Col>
            </Row>
            <Row className="my-3">
              <Col>
                <Field
                  name={"idsPontosAssociados"}
                  subscription={{ value: true }}
                >
                  {({ input: { value: idsPontosAssociados = {} } }) => {
                    return (
                      <PontosGPSAfloramento
                        idEstacao={idEstacao}
                        pontosAtribuidosAEstacao={pontosAtribuidosAEstacao}
                        permitirEdicao={permitirEdicao}
                      />
                    );
                  }}
                </Field>
              </Col>
            </Row>
            <EstaEmModoDefinitivo prefixoNome={prefixoNome}>
              {(modoDefinitivoSelecionado) => {
                return (
                  <React.Fragment
                    key={
                      "modoDefinitivoSelecionado-" + modoDefinitivoSelecionado
                    }
                  >
                    <Row className="my-3">
                      <Col md={6} className="label-input-select">
                        <Field
                          component={DropdownListField}
                          name={`${prefixoNome}idClasseAmostra`}
                          label="Classe"
                          dica="Classe"
                          elementos={classesAmostra}
                          required={modoDefinitivoSelecionado}
                          disabled={!permitirEdicao}
                          validate={validarSe(
                            modoDefinitivoSelecionado,
                            campoObrigatorioComMsgGenerica("Classe")
                          )}
                        />
                      </Col>
                      <Col>
                        <Field
                          component={TextInputField}
                          name={`${prefixoNome}numeroAmostra`}
                          label="Número da amostra"
                          dica="Número da amostra"
                          required
                          disabled={true}
                        />
                      </Col>
                    </Row>
                    <Row className="my-3">
                      <Col>
                        <Field
                          component={DropdownListField}
                          name={`${prefixoNome}idUsuarioColetor`}
                          label="Coletor"
                          dica="Coletor"
                          elementos={possiveisColetores}
                          required
                          disabled={!permitirEdicao}
                          validate={campoObrigatorioComMsgGenerica("Coletor")}
                        />
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col>
                        <Field
                          component={TextAreaField}
                          name={`${prefixoNome}toponimia`}
                          label="Toponímia"
                          dica="Toponímia"
                          required
                          disabled={!permitirEdicao}
                          validate={campoObrigatorioComMsgGenerica("Toponímia")}
                          maxLength={2000}
                        />
                      </Col>
                      <Col>
                        <Field
                          component={TextAreaField}
                          name={`${prefixoNome}informacoesRelevantes`}
                          label="Informações relevantes"
                          dica="Informações relevantes"
                          required={modoDefinitivoSelecionado}
                          disabled={!permitirEdicao}
                          validate={validarSe(
                            modoDefinitivoSelecionado,
                            campoObrigatorioComMsgGenerica(
                              "Informações relevantes"
                            )
                          )}
                          maxLength={2000}
                        />
                      </Col>
                    </Row>
                  </React.Fragment>
                );
              }}
            </EstaEmModoDefinitivo>
          </Card.Body>
        </Card>

        <Row>
          <InformacoesCoordenadas
            configMapa={configMapaAfloramento}
            mapaAtividade={true}
            prefixoCampos={prefixoNome}
            datums={datums}
            permitirEdicao={permitirEdicao}
            pontosAtribuidosAEstacao={pontosAtribuidosAEstacao}
            coordenadasAtuais={form.getState().values.coordenadas}
            metodosGeoposicionamento={metodosGeoposicionamento}
            categoriasLimiteArea={categoriasLimiteArea}
            altitude={true}
          />
        </Row>

        <Card className="mt-4">
          <Card.Body>
            <Card.Title>Conteúdo Multimídia</Card.Title>
            <Ilustracoes2
              nome={"ilustracoes"}
              elementos={form.getState().values.ilustracoes || undefined}
              permitirEdicao={permitirEdicao}
              tiposIlustracao={tiposIlustracao}
              extencoesIlustracao={extencoesIlustracao}
              legendaObrigatoria={true}
            />
          </Card.Body>
        </Card>
      </div>
    </>
  );
};

export default AtividadeOutrasColetas;
