import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Field, useForm } from "react-final-form";
import { valorObrigatorioSeIndformarSelecionado } from "../../../util/validadores";
import Hint from "../../hint/Hint";
import { HiddenField } from "../HiddenField";

const RadioField = ({ input, ...props }) => {

  const form = useForm();
  const [informar, setInformar] = useState(form.getState().values?.[props.campo] ? form.getState().values?.[props.campo] === 'Nao informado' ? 'NAO' : 'SIM' : null);

  const handleChange = (event) => {
    setInformar(event.target.value)
    if (event.target.value === 'NAO') {

      form.mutators.setValue(props.campo, 'Nao informado');
    } else {

      form.mutators.setValue(props.campo, null);
    }
  }
  const radioStyle = {
    appearance: 'auto',
    width: '16px',
    height: '16px',
    accentColor: '#666',
    marginTop: '5px',
    cursor: 'pointer'

  };
  const lableStyle = {
    marginTop: '4px',
    marginLeft: '-3px'

  };

  let coluna1 = 3;
  let coluna2 = 9;
  let coluna3 = 1;
  let coluna4 = 11;

  if (props.colunas === 3) {
    coluna1 = 6;
    coluna2 = 6;
    coluna3 = 2;
    coluna4 = 10;
  }
  if (props.colunas === 2) {
    coluna1 = 4;
    coluna2 = 8;
    coluna3 = 1;
    coluna4 = 11;
  }

  return (<>
    <legend className="w-auto small" style={{ marginBottom: '6px', fontSize: '16px' }}>
      {props.label}
      {props.modoDefinitivo && <span style={{ color: "red" }}>*</span>}
      <Hint textoAjuda={props.dica} />
    </legend>
    <Row>
      <Col md={coluna1} style={{ paddingRight: '0px' }}>
        <Row>
          <Col md={1}>
            <input
              type="radio"
              disabled={props.disabled}
              value="NAO"
              checked={informar === 'NAO'}
              onChange={handleChange}
              style={radioStyle}
            />
          </Col>
          <Col md={10}>
            <p style={lableStyle}>Não informado</p>
          </Col>
        </Row>

      </Col>
      <Col md={coluna2}>
        <Field name={"radioName"} subscription={{ value: true }}>
          {({ input: { value: valorSelecionado = [] } }) => {
            return (<Row>
              <Col md={coluna3} style={{ paddingRight: '0px', paddingLeft: '0px' }}>
                <input
                  type="radio"
                  disabled={props.disabled}
                  value="SIM"
                  checked={informar === 'SIM'}
                  style={radioStyle}
                  onChange={handleChange}
                />
              </Col>
              {informar === "SIM" && (<Col md={coluna4} style={{ paddingLeft: '0px' }}>
                <Field
                  {...props}
                  component={props.tipoCampo}
                  name={props.campo}
                  disabled={props.disabled}
                  value={props.value}
                  validate={(valor) => {
                    if(!valor)
                      return `Um valor deve ser informado para o campo "${props.label}"`;
                  }}
                />
              </Col>)}
              {informar !== "SIM" && (<Col md={coluna4} style={{ paddingLeft: '0px' }}>
                <Field
                  component={HiddenField}
                  name={props.campo}
                  disabled
                />
                <Field
                  component={props.tipoCampo}
                  name={'campo'}
                  disabled
                />
              </Col>)}
            </Row>)
          }}
        </Field>

      </Col>
      <Col>
        {(informar !== "SIM" && informar !== "NAO" && props.modoDefinitivo) &&
          <Field
            name={`validar${props.campo}`}
            component={HiddenField}
            validate={() => {
              if (!form.getState().values?.[props.campo]) {
                return `O campo "${props.label}" é obrigatório.`;
              }
            }}
          />
        }
      </Col>
    </Row>
  </>
  )
}
export default RadioField
