import React, { Component } from "react";
import Card from "../../../../components/card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SeletorCoordenadasGeograficas from "./SeletorCoordenadasGeograficas";
import { Field } from "react-final-form";
import AppMapComponent from "../../../../components/mapa/map_component/AppMapComponent";
import LegendaMapa from "../../..//estacoes/components/mapa/LegendaMapa";
import { NumberInputField } from "../../../../components/field/NumberInputField";
import { TextAreaField } from "../../../../components/field/TextAreaField";
import { campoObrigatorioComMsgGenerica } from "../../../../util/validadores";
import { HiddenField } from "../../../../components/field/HiddenField";
import DropdownListField from "../../../../components/field/DropdownListField/DropdownListField";
import Service from "../../../../service/Service";
import { geoJSONParaArcGIS } from "../../../../util/mapa";

export default class InformacoesCoordenadas extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {}
    this.mapaRef = null;
    this.mapaCarregado = false;
    this.categoriaLocalizacao = null
    this.coordenadasAtuais = null;
    this.latAtual = null;
    this.lonAtual = null;
    this.pontosGPSCriados = false;
    this.geometriaVisita = {
      idProjeto: null,
      geometryFilter: null
    };

    this.aoCarregarMapa = this.aoCarregarMapa.bind(this);
    this.criarPontosMapaAtividades = this.criarPontosMapaAtividades.bind(this);
    this.criarPontoDaEstacao = this.criarPontoDaEstacao.bind(this);
  }


  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  aoCarregarMapa() {
    this.mapaCarregado = true;

    const coordenadas = this.coordenadasAtuais;

    if (this.props.mapaEstacao) {
      // Criar primeiro ponto com valores inicias de coordenadas
      this.criarPontoDaEstacao(coordenadas);
    } else if (this.props.mapaAtividade) {
      this.criarPontosMapaAtividades(coordenadas);
    }

    if (this.props.idProjeto && this.props.geometryFilter) {
      this.mapaRef?.filtrar(
        this.props.idProjeto,
        this.props.geometryFilter,
        false
      );
    }
  }

  // True se latitude e longitude forem números
  coordenadaValida(c) {
    return c && Number.isFinite(c.latitude) && Number.isFinite(c.longitude);
  }

  // Realiza cache da lat/long para não recriar o ponto sem necessidade (na mesma coordenada)
  // Sem verificar isso o ponto seria criado a cada renderização
  // True se a lat/long atual é diferente da anterior
  latLongAlterada(c = {}) {
    return c.latitude !== this.latAtual || c.longitude !== this.lonAtual;
  }

  // Exibir pontos associados a estação no mapa de atividades
  // e ponto que reflete valores dos campos de coordenada da atividade
  async criarPontosMapaAtividades() {
    const coordenadas = this.coordenadasAtuais;
    const coordenadaValida = this.coordenadaValida(coordenadas);
    const deveAtualizarPontoAtividade =
      coordenadaValida && this.latLongAlterada(coordenadas);

    if (this.pontosGPSCriados && !deveAtualizarPontoAtividade) return;

    // Montar pontos GPS associados a estação
    const pontos = (this.props.pontosAtribuidosAEstacao ?? []).map((p) => ({
      latitude: p.latitude,
      longitude: p.longitude,
      latitudeWGS84: p.latitudeWGS84,
      longitudeWGS84: p.longitudeWGS84,
      id: p.id,
    }));

    // Adicionar ponto que reflete a atividade
    if (deveAtualizarPontoAtividade) {
      pontos.push({
        latitude: coordenadas.latitude,
        longitude: coordenadas.longitude,
        tipoSimbolo: "activity",
      });
      // Cache para latLongAlterada
      this.latAtual = coordenadas.latitude;
      this.lonAtual = coordenadas.longitude;
    }

    console.log("Criando", pontos.length, "pontos no mapa de atividades");
    this.mapaRef?.limparPontos();

    //Cria poligono no mapa de atividades
    if (this?.idVisita) {
      if (!this.geometriaVisita.idProjeto) { // Recupera a geometria da subarea/area da visita uma vez
        await Service("/visitas/geometriaVisita/" + this.idVisita).query()
          .then((resp) => {
            this.geometriaVisita = {
              idProjeto: resp.data?.idProjeto,
              geometryFilter: geoJSONParaArcGIS(resp.data.geoJSON)
            }
          })
          .catch((err) => {
            console.error("Falha ao obter geometria da visita", err);
          })
      }
      if (this.geometriaVisita?.idProjeto && this.geometriaVisita?.geometryFilter) {
        this.mapaRef?.filtrar(this.geometriaVisita?.idProjeto, this.geometriaVisita?.geometryFilter);
      }
    }
    this?.mapaRef?.adicionarPontosGPS(pontos);
    this.pontosGPSCriados = true;
  }

  // Limpa mapa e gera ponto na lat/long atual (valores do campo)
  // Utilizado em mapa de estação e mapa de atividade
  criarPontoDaEstacao() {
    const coordenadas = this.coordenadasAtuais;
    if (
      !this.mapaRef ||
      !this.mapaCarregado ||
      !this.coordenadaValida(coordenadas)
    )
      return;

    const { latitude, longitude } = coordenadas;

    if (this.latLongAlterada(coordenadas)) {
      console.log(
        "Criando ponto representando estação em:",
        latitude,
        longitude
      );
      // Cache para latLongAlterada
      this.latAtual = coordenadas.latitude;
      this.lonAtual = coordenadas.longitude;

      // Limpa o ponto anterior e cria o ponto na coordenada atual
      this.mapaRef?.limparPontos();
      this.mapaRef?.adicionarPontosGPS([
        {
          latitude: coordenadas.latitude,
          longitude: coordenadas.longitude,
          tipoSimbolo: "station",
        },
      ]);
    }
  }

  render() {
    const {
      // objetos
      datums,
      permitirEdicao,
      prefixoCampos = "",
      configMapa,
      mapaEstacao,
      mapaAtividade,
      metodosGeoposicionamento,
      altitude,
      habilitarMetodosGeoposicionamento,
      habilitarAltitude,
      form,
      exibirLegendaEstacao,
      exibirLegendaEstacaoHistorica,
      exibirLegendaEstacaoEditada,
      exibirLegendaAtividade,
      exibirLegendaAmostra,
      exibirLegendaPontoGPS,
      exibirLegendaGeometriaProjeto,
      categoriasLimiteArea,
      naoExibirEmCol
    } = this.props;
    // FIXME: Deve haver um jeito melhor de atualizar o mapa com as coordenadas atuais (sem ser dentro do render)
    // Recupera as coordenadas atuais e atualiza ponto no mapa
    let container = (child) => {
      if(naoExibirEmCol)
        return <> {child} </>
      
      return <Col md={12} className={this.props.customPaddingAndMargin ?? ""}>{child}</Col>
     }

    return container(<>
          <Card.Body>
            <Card.Title>Localização</Card.Title>

            <SeletorCoordenadasGeograficas
              eEdicao={this.props.eEdicao}
              datums={datums}
              prefixoCampos={`${prefixoCampos}coordenadas`}
              permitirEdicao={permitirEdicao}
            />

            {/* Armazena coordenadas atuais e o mapa caso carregado */}
            <Field
              name={`${prefixoCampos}coordenadas`}
              subscription={{ value: true }}
            >
              {(fieldsState) => {
                this.coordenadasAtuais = fieldsState?.input?.value;
                this.categoriaLocalizacao = fieldsState?.input?.value?.categoriaLocalizacao;
                if (
                  this.coordenadasAtuais &&
                  !this.coordenadaValida(this.coordenadasAtuais)
                ) {
                  // Utilizar coordenadas SIRGAS 2000 caso lat/long estejam indisponíveis
                  this.coordenadasAtuais = {
                    ...this.coordenadasAtuais,
                    latitude: this.coordenadasAtuais.latitudeSIRGAS2000,
                    longitude: this.coordenadasAtuais.longitudeSIRGAS2000,
                  };
                }

                if (this.mapaCarregado) {
                  if (mapaEstacao) {
                    this.criarPontoDaEstacao();
                  } else if (mapaAtividade) {
                    this.criarPontosMapaAtividades();
                  }
                }
                return null;
              }}
            </Field>

            <Field
              name={`${prefixoCampos}idVisita`}
              subscription={{ value: true }}
            >
              {(fieldsState) => {
                if (fieldsState?.input?.value)
                  this.idVisita = fieldsState?.input?.value;
                return null;
              }}
            </Field>

            <Field
              name={`${prefixoCampos}id_visita`}
              subscription={{ value: true }}
            >
              {(fieldsState) => {
                if (fieldsState?.input?.value)
                  this.idVisita = fieldsState?.input?.value;
                return null;
              }}
            </Field>

            <Row className="mt-3">
              {altitude && (
                <Col className="label-input-text">
                  <Field
                    component={NumberInputField}
                    name={`${prefixoCampos}altitude`}
                    label="Altitude (m)"
                    dica="Altura/Desnível em relação ao nível do mar (metros)"
                    disabled={this.props.eEdicao ? true : habilitarAltitude ? false : !permitirEdicao}
                    casasInteiros={4}
                    useOldInput={false}
                  />
                </Col>
              )}

              {metodosGeoposicionamento && (
                <Col>
                  <Field name={`${prefixoCampos}idMetodoGeoposicionamento`} subscription={{ value: true }}>
                    {({ input: { value: metodoGeoposicionamento } }) => {

                      if (!metodoGeoposicionamento && form && form.mutators)
                        form?.mutators?.setValue('idMetodoGeoposicionamento', 3);

                        let listaFiltrada = metodosGeoposicionamento.filter((m) => m.atual === 'S');
                        const item = metodosGeoposicionamento.find((m) => m.id === metodoGeoposicionamento);
                        if(item && item.atual === 'N'){
                          listaFiltrada.push(item);
                          listaFiltrada = listaFiltrada.sort((a, b) => a.nome.localeCompare(b.nome));
                        }  

                      return <Field
                        name={`${prefixoCampos}idMetodoGeoposicionamento`}
                        component={DropdownListField}
                        label="Método de geoposicionamento"
                        dica="Refere-se à precisão do equipamento utilizado no posicionamento"
                        elementos={listaFiltrada}
                        required
                        disabled={this.props.eEdicao ? true : habilitarMetodosGeoposicionamento ? false : !permitirEdicao}
                        validate={campoObrigatorioComMsgGenerica("Método de geoposicionamento")}
                      />
                    }}
                  </Field>
                </Col>
              )}

              <Col>
                <Field
                  component={NumberInputField}
                  name={`${prefixoCampos}coordenadas.longitudeSIRGAS2000`}
                  label="Longitude SIRGAS2000"
                  dica="Coordenada geográfica em SIRGAS2000"
                  casasInteiros={2}
                  casasDecimais={6}
                  required
                  disabled={true}
                  useOldInput={true}
                />
              </Col>
              <Col>
                <Field
                  component={NumberInputField}
                  name={`${prefixoCampos}coordenadas.latitudeSIRGAS2000`}
                  label="Latitude SIRGAS2000"
                  dica="Coordenada geográfica em SIRGAS2000"
                  casasInteiros={2}
                  casasDecimais={6}
                  required
                  disabled={true}
                  useOldInput={true}
                />
              </Col>
            </Row>

          </Card.Body>
          <Card.Body>
            <LegendaMapa
                exibirEstacao={exibirLegendaEstacao}
                exibirEstacaoHistorica={exibirLegendaEstacaoHistorica}
                exibirEstacaoEditada={exibirLegendaEstacaoEditada}
                exibirAtividade={exibirLegendaAtividade}
                exibirAmostra={exibirLegendaAmostra}
                exibirPontoGPS={exibirLegendaPontoGPS}
                exibirGeometriaProjeto={exibirLegendaGeometriaProjeto}
            />
            <Row className={!mapaAtividade ? "" : "row-mapa-atividades"}>
              <Col
                md={mapaAtividade ? 9 : 12}
                className={!mapaAtividade ? "" : "col-mapa-atividades"}
              >
                <AppMapComponent
                  config={configMapa}
                  ref={(instance) => (this.mapaRef = instance)}
                  onCarregarMapa={() => {
                    this.aoCarregarMapa();
                  }}
                  debugMensagens={!!process.env.REACT_APP_MAP_DEBUG}
                />
              </Col>
              {mapaAtividade && (
                <Col md={3}>
                  <Row>
                    <Col md={12}>
                      <Field
                        component={DropdownListField}
                        name="coordenadas.categoriaLocalizacao"
                        label="Categoria da localização"
                        dica="Localização da atividade em relação à área/subárea do projeto"
                        elementos={categoriasLimiteArea}
                        disabled={true} // Selecionado automaticamente pelo decorator
                      />
                      <Field
                        component={HiddenField}
                        name="coordenada.dentroArea"
                      />
                    </Col>
                    <Field
                      name="coordenada.dentroArea"
                      subscription={{ value: true }}
                    >
                      {({ input: { value } }) => {
                        // Renderizar outros dois campos apenas se for fora da área
                        if (value === '') {
                          value = this.categoriaLocalizacao === 1
                        }
                        return (
                          !value && (
                            <React.Fragment key={value}>
                              <Col md={12}>
                                <Field
                                  component={TextAreaField}
                                  name="coordenadas.justificativa"
                                  label="Justificativa para localização fora da área"
                                  dica="Justifique porque o ponto se encontra fora da área/subárea do projeto"
                                  maxLength={2000}
                                  required={true}
                                  validate={campoObrigatorioComMsgGenerica(
                                    "Justificativa para localização fora da área"
                                  )}
                                  disabled={!permitirEdicao}
                                />
                              </Col>
                            </React.Fragment>
                          )
                        );
                      }}
                    </Field>
                  </Row>
                </Col>
              )}
            </Row>
          </Card.Body> 
      </>);
      
  }
}
