import React from 'react';
import './App.css';
import Erro from "../../views/notificacao/Erro";
import { Provider } from "react-redux";
import { persistor, store } from "../../store";
import Rotas from "../navegacao/Rotas";
import { PersistGate } from 'redux-persist/integration/react'

const App = () => {
  return (
    <Erro self={this}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Rotas/>
        </PersistGate>
      </Provider>
    </Erro>
  );
}

export default App;
