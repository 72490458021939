import React, { useCallback, useRef, useState } from "react";
import Card from "../../../../components/card";
import Rochas from "./rochas/Rochas";
import Intemperismos from "./Intemperismos";
import Ilustracoes2 from "../Ilustracoes2";
import Metamorfismos from "./Metamorfismos";
import AlteracoesHidrotermais from "./AlteracoesHidrotermais";
import EstruturasPrimarias from "./EstruturasPrimarias";
import EstruturasTectonicas from "./EstruturasTectonicas";
import RochasEnclave from "./RochasEnclave";
import RelacoesContato from "./RelacoesContato";
import { Field, useForm } from "react-final-form";
import { resolverExibidos } from "../../../../components/SubForm/SubForm";
import converterVetorParaObjetoIndexado from "../../../../util/converterVetorParaObjetoIndexado";
import { aoEnviarSubForm } from "../../../../util/mutadores";

const DadosAfloramento = ({
  maturidadesComposicionais = [],
  maturidadesTexturais = [],
  esfericidades = [],
  granulacoes = [],
  arredondamentos = [],
  relacoesArcaboucoMatriz = [],
  naturezasMatriz = [],
  texturas = [],
  indicesCor = [],
  naturezasComposicaoSedimentar = [],
  granulometrias = [],
  selecoes = [],
  rochasNomeadas = [],
  minerais = [],
  prefixos = [],
  complementos = [],
  geometrias = [],
  cores = [],
  classesRocha = [],
  subClassesRocha = [],

  grausIntemperismos = [],
  tiposIlustracao = [],
  extencoesIlustracao = [],
  tiposRelacaoContato = [],
  tiposMetamorfismo = [],
  hierarquias = [],
  faciesMetamorfismo = [],
  formasOcorrencia = [],
  tiposAlteracaoHidrotermal = [],
  intensidadesAlteracaoHidrotermal = [],
  tiposEstrutura = [],
  tiposNotacao = [],
  tiposEstruturaTectonica = [],
  tiposRochaEnclave = [],

  mineraisPorId,
  rochasNomeadasPorId,
  prefixosPorId,
  complementosPorId,

  permitirEdicao,
  aoAbrirSubForm,
  aoFecharSubForm,
  valoresIniciais,
  modoDefinitivoSelecionado,
  visitas,
  visitaSelecionada,

  rochasComErro,
  estPrimariasComErro,
  estTectonicasComErro,
  metamorfismosComErro,
  altHidrotermaisComErro,
  rochasEnclaveComErro,
  relacaoContatoComErro,
  intemperismosComErro,
  atividadesVisita,
}) => {
  const form = useForm()

  const onSubmit = aoEnviarSubForm(form);

  const [formularioConteudoMultimidia, setFormularioConteudoMultimidia] = useState(false)
  const [formularioRochasAberto, setFormularioRochasAberto] = useState(false)
  
  function aoAbrirSubFormularioRochas() {
    if (formularioRochasAberto) {
      return
    }

    setFormularioRochasAberto(true)
  }

  function aoFecharSubFormularioRochas() {
    if (!formularioRochasAberto) {
      return
    }
    
    setFormularioRochasAberto(false)
  }

  function aoAbrirSubFormularioConteudoMultimidia() {
    if (formularioConteudoMultimidia) {
      return
    }
    setFormularioConteudoMultimidia(true)
  }

  function aoFecharSubFormularioConteudoMultimidia() {
    if (!formularioConteudoMultimidia) {
      return
    }
    setFormularioConteudoMultimidia(false)
  }

  return (
    <Field name={'rochas'} subscription={{ value: true }}>
      {({ input: { value: rochasElementos = {} } }) => {
        // Disponibilizar rochas exibidas para preencher coluna rochas nos SubForms que possuem campo Rochas
        const rochas = resolverExibidos(rochasElementos || {})

        if (rochas && rochas.length) {
          rochas.forEach(rocha => {
            rocha.nomeExibicao = rocha.nomeRocha && rocha.numeroMaterialGeologico
              ? `${rocha.nomeRocha} - ${rocha.numeroMaterialGeologico}`
              : rocha.nomeRocha || rocha.numeroMaterialGeologico;
          });
        }

        const rochasPorId = converterVetorParaObjetoIndexado(rochas);

        const materiaisGeologicos = rochas.map((rocha) => ({
          id: rocha?.id,
          nome: rocha?.nomeExibicao,
        }));

        return (
          <>
            <Rochas
              aoAbrirSubForm={() => aoAbrirSubFormularioRochas()}
              aoFecharSubForm={() => aoFecharSubFormularioRochas()}
              formularioAberto={formularioRochasAberto}
              nome={'rochas'}
              multimidiaAberto={formularioConteudoMultimidia}
              onSubmit={onSubmit}
              valoresIniciais={valoresIniciais}
              permitirEdicao={permitirEdicao}
              referenciaForm={form}
              maturidadesComposicionais={maturidadesComposicionais}
              maturidadesTexturais={maturidadesTexturais}
              esfericidades={esfericidades}
              granulacoes={granulacoes}
              arredondamentos={arredondamentos}
              relacoesArcaboucoMatriz={relacoesArcaboucoMatriz}
              naturezasMatriz={naturezasMatriz}
              texturas={texturas}
              indicesCor={indicesCor}
              naturezasComposicaoSedimentar={naturezasComposicaoSedimentar}
              granulometrias={granulometrias}
              selecoes={selecoes}
              minerais={minerais}
              rochasNomeadas={rochasNomeadas}
              classesRocha={classesRocha}
              subClassesRocha={subClassesRocha}
              formAtividade={form}
              prefixos={prefixos}
              complementos={complementos}
              geometrias={geometrias}
              cores={cores}
              mineraisPorId={mineraisPorId}
              rochasNomeadasPorId={rochasNomeadasPorId}
              prefixosPorId={prefixosPorId}
              complementosPorId={complementosPorId}
              visitaSelecionada={visitaSelecionada}
              modoDefinitivoSelecionado={modoDefinitivoSelecionado}
              rochasComErro={rochasComErro}
              atividadesVisita={atividadesVisita}
              grausIntemperismos={grausIntemperismos}
              formasOcorrencia={formasOcorrencia}
              tiposAlteracaoHidrotermal={tiposAlteracaoHidrotermal}
              intensidadesAlteracaoHidrotermal={intensidadesAlteracaoHidrotermal}
              hierarquias={hierarquias}
            />
            <EstruturasPrimarias
              nome={'estruturasPrimarias'}
              onSubmit={onSubmit}
              valoresIniciais={valoresIniciais}
              permitirEdicao={permitirEdicao}
              formAtividade={form}
              modoDefinitivoSelecionado={modoDefinitivoSelecionado}
              rochas={rochas}
              rochasPorId={rochasPorId}
              tiposEstrutura={tiposEstrutura}
              tiposNotacao={tiposNotacao}
              estPrimariasComErro={estPrimariasComErro}
            />
            <EstruturasTectonicas
              nome={'estruturasTectonicas'}
              onSubmit={onSubmit}
              formAtividade={form}
              valoresIniciais={valoresIniciais}
              permitirEdicao={permitirEdicao}
              modoDefinitivoSelecionado={modoDefinitivoSelecionado}
              rochas={rochas}
              rochasPorId={rochasPorId}
              tiposEstruturaTectonica={tiposEstruturaTectonica}
              hierarquias={hierarquias}
              tiposNotacao={tiposNotacao}
              estTectonicasComErro={estTectonicasComErro}
            />
            <Metamorfismos
              nome={'metamorfismos'}
              onSubmit={onSubmit}
              valoresIniciais={valoresIniciais}
              permitirEdicao={permitirEdicao}
              formAtividade={form}
              rochas={rochas}
              modoDefinitivoSelecionado={modoDefinitivoSelecionado}
              rochasPorId={rochasPorId}
              tiposMetamorfismo={tiposMetamorfismo}
              hierarquias={hierarquias}
              faciesMetamorfismo={faciesMetamorfismo}
              metamorfismosComErro={metamorfismosComErro}
            />
            <AlteracoesHidrotermais
              nome={'alteracoesHidrotermais'}
              onSubmit={onSubmit}
              valoresIniciais={valoresIniciais}
              permitirEdicao={permitirEdicao}
              formAtividade={form}
              rochas={rochas}
              rochasPorId={rochasPorId}
              modoDefinitivoSelecionado={modoDefinitivoSelecionado}
              minerais={minerais}
              formasOcorrencia={formasOcorrencia}
              tiposAlteracaoHidrotermal={tiposAlteracaoHidrotermal}
              intensidadesAlteracaoHidrotermal={intensidadesAlteracaoHidrotermal}
              hierarquias={hierarquias}
              altHidrotermaisComErro={altHidrotermaisComErro}
            />
            <RochasEnclave
              nome={'rochasEnclave'}
              onSubmit={onSubmit}
              valoresIniciais={valoresIniciais}
              permitirEdicao={permitirEdicao}
              formAtividade={form}
              rochas={rochas}
              rochasPorId={rochasPorId}
              modoDefinitivoSelecionado={modoDefinitivoSelecionado}
              tiposRochaEnclave={tiposRochaEnclave}
              rochasEnclaveComErro={rochasEnclaveComErro}
            />
            <RelacoesContato
              nome={'relacoesContato'}
              onSubmit={onSubmit}
              valoresIniciais={valoresIniciais}
              permitirEdicao={permitirEdicao}
              formAtividade={form}
              rochas={rochas}
              modoDefinitivoSelecionado={modoDefinitivoSelecionado}
              rochasPorId={rochasPorId}
              tiposRelacaoContato={tiposRelacaoContato}
              relacaoContatoComErro={relacaoContatoComErro}
            />
            <Intemperismos
              nome={'intemperismos'}
              onSubmit={onSubmit}
              valoresIniciais={valoresIniciais}
              permitirEdicao={permitirEdicao}
              formAtividade={form}
              rochas={rochas}
              modoDefinitivoSelecionado={modoDefinitivoSelecionado}
              rochasPorId={rochasPorId}
              grausIntemperismos={grausIntemperismos}
              intemperismosComErro={intemperismosComErro}
            />
            <div style={{ marginTop: '2.5rem' }}>
              <Card>
                <Card.Body>
                  <Ilustracoes2
                    nome={'ilustracoes'}
                    formularioAberto={formularioConteudoMultimidia}
                    aoAbrirSubForm={() => aoAbrirSubFormularioConteudoMultimidia()}
                    aoFecharSubForm={() => aoFecharSubFormularioConteudoMultimidia()}
                    formularioRochasAberto={formularioRochasAberto}
                    permitirEdicao={permitirEdicao}
                    tiposIlustracao={tiposIlustracao}
                    visitas={visitas}
                    visitaSelecionada={visitaSelecionada}
                    legendaObrigatoria={true}
                    permitirVisualizacao={!permitirEdicao}
                    extencoesIlustracao={extencoesIlustracao}
                    materiaisGeologicos={materiaisGeologicos}
                    showMaterialGeologicoField={true}
                  />
                </Card.Body>
              </Card>
            </div>
          </>
        );
      }}
    </Field>
  )
}

DadosAfloramento.propTypes = {}

export default DadosAfloramento
