import React, { Component } from "react";
import Card from "../../../components/card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Field } from "react-final-form";
import TextInputField from "../../../components/field/TextInputField/TextInputField";
import DateTimeInputField from "../../../components/field/DateTimeInputField/DateTimeInputField";
import TextAreaField from "../../../components/field/TextAreaField/TextAreaField";
import { campoObrigatorioComMsgGenerica } from "../../../util/validadores";

export default class Estacao extends Component {
  render() {
    const {
      permitirEdicao,
    } = this.props;

    return (
      <Card className="card-mb2">
        <Card.Body>
          <Card.Title>Informações gerais</Card.Title>
          <Row>
            <Col>
              <Row className="mt-3">
                <Col>
                  <Field
                    component={TextInputField}
                    name="nome"
                    label="Código da estação"
                    dica="Código da estação do Serviço Geológico do Brasil no formato SGB-UF-NNNN"
                    disabled={true}
                    required
                    maxLength={100}
                  />
                </Col>
                <Col className='text-input'>
                  <Field
                    component={TextInputField}
                    name="apelido"
                    label="Nome da estação"
                    dica="Nome fantasia da Estação, utilizado para facilitar a memória da informação"
                    disabled={!permitirEdicao}
                    autoFocus
                    maxLength={100}
                  />
                </Col>
                <Col>
                  <Field
                    component={DateTimeInputField}
                    name="dataPrimeiroCadastro"
                    label="Data do primeiro cadastro"
                    dica="Data em que as informações foram inseridas pela primeira vez nessa estação"
                    required
                    disabled={!permitirEdicao || this.props.eEdicao}
                    validate={campoObrigatorioComMsgGenerica("Data do primeiro cadastro")}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="mt-3">

          </Row>
          <Row className="mt-3">
            <Col>
              <Field
                component={TextAreaField}
                name="informacoesRelevantes"
                label="Informações relevantes"
                dica="Inserir toda e qualquer informação que seja importante e não esteja contemplada nas demais opções de preenchimento"
                disabled={!permitirEdicao}
                maxLength={2000}
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>
    );
  }
}
