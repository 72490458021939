const SELECIONAR_VISITA = "selecionar_visita";

export const selecionarVisita = (visita = null) => (
    {
    type: SELECIONAR_VISITA,
    payload: visita
}
)

export const removerVisita = () => ({
    type: SELECIONAR_VISITA,
    payload: {}
})

const initialState = {
    visita: {}
}

export const visitaReducer = (state = initialState, action) => {
    switch (action.type) {
        case SELECIONAR_VISITA:
            state.visita = action.payload;
            return state;
        default: return state;
    }
};